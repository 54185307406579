import React, {FC, useMemo, useState} from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { FilterActionButton, FilterActionButtonsWrapper, FilterContainerWrapper, FilterItem, FilterList, TotalCount } from './style'
import { ReactSVG } from 'react-svg'
import { totalCountSelector } from '../../../store/slices/findVehiclesSlice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchPagination from '../../../pages/ClassicSearchPage/components/SearchPagination';
import CustomPagination from '../CustomPagination';
import { langPrefix } from '../../../utils/functions';

interface filterItem {
    key: string,
    value: string
}
interface SearchFilterProps {
    total: number;
    filter?: boolean;
  }
  
    const SearchFilterContainer: FC<SearchFilterProps> = ({ total, filter = false }) => {

    const history = useHistory();
    const location = useLocation();
    const params = useMemo(() => new URLSearchParams(location.search), [location]);

    const totalCount = total;
    const { t } = useTranslation()


    const filters = useMemo(() => {
        const arr: filterItem[] = []
        for (const key of params.keys()) {
            params.get(key)?.split(",").forEach((value) => {
                if(key != "itemscount" && key != "page"){
                    if(key == "vehiclepriceto") { value = 'To: '+value+"$";}
                    if(key == "vehiclepricefrom") { value = 'From: '+value+"$";}
                    if(key == "vehicleodometrfrom") { value = 'From: '+value+"mi";}
                    if(key == "vehicleodometrto") { value = 'To: '+value+"mi";}
                    arr.push({
                        key,
                        value,
                    })
                }
            })
        }
        return arr
    }, [location.search])

    const clearFilter = () => {
        history.push({
            pathname: history.location.pathname,
            search: "",
        });
    };


    const handleChangeFilter = (key: string, val: string) => {
        const arr = filters.filter(f => f.key == key && f.value != val).map(k => k.value)
        if(arr.length > 0){
            params.set(key, arr.toString())
        }else{
            params.delete(key)
        }

        history.push({
          pathname: history.location.pathname,
          search: params.toString(),
        });
    };

    const [showAllFilters, setShowAllFilters] = useState(false);

    const toggleShowAllFilters = () => {
        setShowAllFilters(!showAllFilters);
    };

    function formatValue(value: any): any {
        return value
            .replace(/_/g, ' ')
            .split(' ')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

  return (
    <FilterContainerWrapper>
            <FilterList>
            {filter && (
                <>
                {filters.length !== 0 && <button className="clearbtn" onClick={() => clearFilter()}><ReactSVG src='/icons/trash.svg' className='svg-wrapper' /></button>}
                {filters.slice(0, showAllFilters ? filters.length : 10).map((item, i) => (
                    <FilterItem key={item.value}>
                        {formatValue(item.value)}
                        <button onClick={() => handleChangeFilter(item.key, item.value)}>
                            <ReactSVG src='/icons/close.svg' className='svg-wrapper' />
                        </button>
                    </FilterItem>
                ))}
                {filters.length > 10 && (
               <FilterItem className='moreButton' onClick={toggleShowAllFilters}>
               {showAllFilters ? (
                   <>
                       <span>{t('filters_less')}</span>
                       <ReactSVG src='/icons/minus.svg' className='svg-wrapper' />
                   </>
               ) : (
                   <>
                       <span>{t('filters_more')} {filters.length}</span>
                       <ReactSVG src='/icons/add.svg' className='svg-wrapper' />
                   </>
               )}
           </FilterItem>
            )}
            </>
        )}
            </FilterList>
       
        <FilterActionButtonsWrapper>
            <SearchPagination total={totalCount}/>

            <TotalCount>{t('Totals')}: {totalCount}</TotalCount>
            {(history.location.pathname.includes("/classicsearch") || history.location.pathname.includes("/findvehicles")) && (
                <>
                    <FilterActionButton to={langPrefix("/classicsearch")} isActive={history.location.pathname.includes("/classicsearch")}>
                        <ReactSVG src='/icons/grid-8.svg' className='svg-wrapper'/>
                    </FilterActionButton>
                    <FilterActionButton to={langPrefix("/findvehicles")} isActive={history.location.pathname.includes("/findvehicles")}>
                        <ReactSVG src='/icons/grid-row.svg' className='svg-wrapper'/>
                    </FilterActionButton>
                </>
            )}
        </FilterActionButtonsWrapper>
    </FilterContainerWrapper>
  )
}

export default SearchFilterContainer