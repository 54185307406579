/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { FC, memo, useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import useSound from 'use-sound';
import { Col, Row, Card, Typography, Divider, Skeleton, Image, Modal, Button, Spin } from 'antd';
import * as signalR from '@microsoft/signalr';
import { LeftOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import NextLotsList from './NextLotsList';
import {
  returnLane,
  currentPanelLaneInfoSelector,
  currentLaneStatusSelector,
  wsChangeBid,
  getAuctionDashboardLanesList,
  wsUpdateCurrentLot,
  wsUpdateLaneStatus,
  wsUpdateActualLots,
  wsAddLaneMaxBidTime,
  wsAddLaneAdditionalBidTime,
  musicSelector,
  soundSelector,
  changeSound,
  changeMusic,
  monsterBidMusicSelector,
  otherBidMusicSelector,
  bidMusicSelector,
  openLanesListSelector,
  changeBidMusic,
  changeOtherBidMusic,
  changeMonsterBidMusic,
  changeAuctionMusic,
  auctionMusicSelector,
} from '../../../store/slices/auctionSlice';
import { getTimeZone } from '../../../utils/functions';
import LotPanel from './LotPanel';
import { userSelector, toggleModalAuth } from '../../../store/slices/authSlice';
import { showNotification } from '../../../utils/notify';
import { SCard, NextLotContainer, SkeletonWrapper } from './style';
import { getQuickViewLotData, changeOpenQuickView } from '../../../store/slices/productSlice';

const { Text } = Typography;
interface Props {
  panelId: number;
  panelOrder: number;
  laneId: number;
  closeUp: boolean;
  onClose: () => void;
}

const Dashboard: FC<Props> = ({ panelId, panelOrder, laneId, closeUp, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory<{ product: number }>();
  const loc = useLocation();
  const locparams = useMemo(() => new URLSearchParams(loc.search), [loc]);

  const currentPanelLaneInfo = useSelector(currentPanelLaneInfoSelector(laneId));
  const currentLaneStatus = useSelector(currentLaneStatusSelector(laneId));
  const user = useSelector(userSelector);
  const music = useSelector(musicSelector(laneId));
  const sound = useSelector(soundSelector(laneId));
  const monsterBidMusic = useSelector(monsterBidMusicSelector(laneId));
  const otherBidMusic = useSelector(otherBidMusicSelector(laneId));
  const bidMusic = useSelector(bidMusicSelector(laneId));
  const openLanesList = useSelector(openLanesListSelector);
  const auctionMusic = useSelector(auctionMusicSelector(laneId));

  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  const [exitAuction, setExitAuction] = useState<boolean>(false);

  useEffect(() => {
    const protocol = new signalR.JsonHubProtocol();
    const options = {
      accessTokenFactory: () => user?.access_token || '',
    };
    const connect = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_SIGNALR}/auction/`, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  }, [user]);

  useEffect(() => {
    const id = history.location.state?.product;
    if (id) {
      dispatch(changeOpenQuickView(id));
      dispatch(getQuickViewLotData(+id));
    }
  }, [dispatch, history.location.state]);

  // WS
  // ***START***
  const successfullyConnectedHandler = (msg: string) => {
    // console.log('**********successfullyConnectedHandler');
    if (msg) {
      invoke(connection, 'JoinLane', laneId);
      dispatch(wsUpdateLaneStatus(laneId, 'OnLoad'));
    }
  };

  const actualLaneLotsHandler = (params: any) => {
    // console.log('**********actualLaneLotsHandler');
    // console.log('********looking for params', params);

    if (params?.length) {
      dispatch(wsUpdateActualLots(laneId, params));
    }
  };

  const laneStatusHandler = (msg: string) => {
    // console.log('**********laneStatusHandler step 1', msg);
    if (msg === 'NotExist') showNotification('error', 'Auction not exist');
    if (msg && msg !== '' && msg !== currentLaneStatus) {
      dispatch(wsUpdateLaneStatus(laneId, msg));
    }
  };

  const laneBidTimeHandler = (params: any) => {
    // console.log('**********laneBidTimeHandler');

    if (params.auctionTime) {
      dispatch(wsAddLaneMaxBidTime(laneId, params.auctionTime));
    }
    if (params.additionalBidTime) {
      dispatch(wsAddLaneAdditionalBidTime(laneId, params.additionalBidTime));
    }
  };

  const currentLotHandler = (params: any) => {
    // console.log('**********currentLotHandler step 2', params, new Date());

    dispatch(wsUpdateCurrentLot(laneId, params));
  };

  const changeBidValueHandler = (params: any) => {
    // console.log('**********changeBidValueHandler step 3', params);

    dispatch(wsChangeBid(laneId, params));
  };

  const alreadyConnectedHandler = (msg: string) => {
    // console.log('**********alreadyConnectedHandler');

    handleCloseModalAndReturn();
    showNotification('error', 'Important personal message', 'You have already connected to this lane from another place.');
  };
  // ***END***

  const invoke = (connect: signalR.HubConnection | null, method: string, ...args: any[]) => {
    if (connect) {
      connect
        .invoke(method, ...args)
        .then((data) => {})
        .catch((err: any) => {
          dispatch(toggleModalAuth(true));
        });
    }
    return null;
  };
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('SuccessfullyConnected', successfullyConnectedHandler);
          connection.on(`ActualLaneLots${laneId}`, actualLaneLotsHandler);
          connection.on(`LaneStatus${laneId}`, laneStatusHandler);
          connection.on(`LaneBidTime${laneId}`, laneBidTimeHandler);
          // connection.on(`BiddersCount${laneId}`, biddersCountHandler);
          connection.on(`CurrentLot${laneId}`, currentLotHandler);
          connection.on(`ChangeBidValue${laneId}`, changeBidValueHandler);
          // connection.on('CurrentBalance', currentBalanceHandler);
          connection.on(`AlreadyConnected${laneId}`, alreadyConnectedHandler);
        })
        .catch((error) => {});
    }
  }, [connection, laneId]);

  const handleReturnToList = useCallback(async () => {
    await connection?.stop();
    currentLaneStatus === 'ended' && dispatch(getAuctionDashboardLanesList());
    dispatch(returnLane(panelId, laneId));
  }, [connection, currentLaneStatus, dispatch, panelId, laneId]);

  const handleCloseModalAndReturn = useCallback(async () => {
    handleReturnToList();

    const lanes = locparams
      .get('laneId')
      ?.split(',')
      .filter((item) => +item !== laneId);
    lanes?.length ? locparams.set('laneId', lanes?.join(',')) : locparams.delete('laneId');
    history.push({
      pathname: '/auctiondashboard',
      search: locparams.toString(),
    });
  }, [connection, currentLaneStatus, dispatch, panelId, laneId, locparams, loc]);

  useEffect(() => {
    history.listen((location) => {
      if (location.pathname !== '/auctiondashboard') {
        handleReturnToList();
      }
    });
  }, [history, handleReturnToList]);

  const handleMakeBid = useCallback(
    (bidValue, lotId, method) => {
      invoke(connection, method, {
        LaneId: laneId,
        LotId: lotId,
        BidValue: bidValue,
      });
    },
    [connection, laneId],
  );

  const handleRedirectToCalendar = useCallback(async () => {
    await connection?.stop();
    await dispatch(returnLane(panelId, laneId));
    history.push('/auctioncalendar');
  }, [connection, dispatch, panelId, history, laneId]);

  const handleClose = useCallback(async () => {
    await connection?.stop();
    onClose();
    const lanes =
      locparams
        .get('laneId')
        ?.split(',')
        .filter((item) => +item !== laneId) || [];
    locparams.set('laneId', lanes?.join(','));
    history.push({
      pathname: '/auctiondashboard',
      search: locparams.toString(),
    });
  }, [connection, onClose, locparams]);

  const handleChangeMusic = () => {
    dispatch(changeMusic(laneId));
  };
  const handleChangeSound = () => {
    dispatch(changeSound(laneId));
  };

  const [monsterBidPlay, { stop: monsterBidStop }] = useSound('/audio/monster_bid.mp3');
  const [otherBidPlay, { stop: otherBidStop }] = useSound('/audio/other_users_bid.mp3');
  const [bidPlay, { stop: bidStop }] = useSound('/audio/my_bid.mp3');
  const [auctionPlay, { stop: auctionStop, isPlaying: auctionisPlaying }] = useSound('/audio/auction.mp3');

  useEffect(() => {
    const lanes = locparams.get('laneId')?.includes(laneId.toString());
    if (!lanes) {
      auctionStop();
      bidStop();
      otherBidStop();
      monsterBidStop();
    }
  }, [locparams, laneId]);

  useEffect(() => {
    if (sound) {
      if (bidMusic) {
        bidPlay();
        dispatch(changeBidMusic(laneId, false));
      }
      if (otherBidMusic) {
        otherBidPlay();
        dispatch(changeOtherBidMusic(laneId, false));
      }
      if (monsterBidMusic) {
        monsterBidPlay();
        dispatch(changeMonsterBidMusic(laneId, false));
      }
    }
  }, [laneId, dispatch, sound, bidMusic, otherBidMusic, monsterBidMusic, bidPlay, otherBidPlay, monsterBidPlay]);

  useEffect(() => {
    if (!Object.keys(openLanesList).find((item) => ['auction', 'waiting'].includes(openLanesList[item].status))) {
      auctionStop();
      bidStop();
      otherBidStop();
      monsterBidStop();
    }
  }, [openLanesList, auctionStop, bidStop, monsterBidStop, otherBidStop]);

  useEffect(() => {
    !auctionMusic && auctionStop();
  }, [auctionMusic]);
  useEffect(() => {
    !bidMusic && bidStop();
  }, [bidMusic]);
  useEffect(() => {
    !otherBidMusic && otherBidStop();
  }, [otherBidMusic]);
  useEffect(() => {
    !monsterBidMusic && monsterBidStop();
  }, [monsterBidMusic]);

  useEffect(
    () =>
      history.listen((location) => {
        auctionStop();
        bidStop();
        otherBidStop();
        monsterBidStop();
      }),
    [auctionStop, bidStop, history, monsterBidStop, otherBidStop],
  );

  useEffect(() => {
    const lanes = locparams.get('laneId')?.includes(laneId.toString());
    music && auctionMusic && lanes ? !auctionisPlaying && auctionPlay() : auctionStop();
  }, [music, auctionMusic, auctionisPlaying, auctionPlay, auctionStop]);

  useEffect(() => {
    if (Object.keys(openLanesList).find((key) => openLanesList[key].status === 'auction')) {
      dispatch(changeAuctionMusic(laneId, true));
    } else {
      dispatch(changeAuctionMusic(laneId, false));
    }
  }, [openLanesList, laneId, dispatch]);

  const handleRenderView = useCallback(() => {
    if (currentLaneStatus && ['auction', 'waiting'].includes(currentLaneStatus)) {
      return (
        <SCard
          title={
            <>
              <LeftOutlined onClick={() => setExitAuction(true)} />
              <Divider type="vertical" />
              <Text>{`${currentPanelLaneInfo?.country} ${currentPanelLaneInfo?.city}`}</Text>
              <Divider type="vertical" />
              <Text>{`${currentPanelLaneInfo?.laneActivationPeriods.map((item) => `${dayjs(item.startTime).format('HH:mm')} (${getTimeZone()})`)}`}</Text>
              <Divider type="vertical" />
              <Text>Line: {currentPanelLaneInfo?.laneNumber}</Text>
              <Divider type="vertical" />
              <Text>Lots count: {currentPanelLaneInfo?.lotsCount}</Text>
            </>
          }
          extra={
            <>
              <Button onClick={handleChangeMusic} icon={music ? <CheckCircleFilled /> : <CloseCircleFilled />}>
                Music
              </Button>
              <Button onClick={handleChangeSound} icon={sound ? <CheckCircleFilled /> : <CloseCircleFilled />}>
                Sound
              </Button>
              {closeUp && (
                <Button onClick={handleClose} icon={<CloseCircleFilled />}>
                  Close
                </Button>
              )}
            </>
          }
        >
          <Row gutter={7} justify="center">
            <Col xs={12}>
              <LotPanel
                laneId={laneId}
                panelId={panelId}
                makeBid={handleMakeBid}
                currentLaneStatus={currentLaneStatus}
                time={currentPanelLaneInfo?.laneActivationPeriods[0].startTime}
              />
            </Col>
            <Col xs={12}>
              <NextLotsList currentLaneStatus={currentLaneStatus} laneId={laneId} makeBid={handleMakeBid} />
            </Col>
          </Row>
          {!!exitAuction && (
            <Modal
              title={`Close Panel #${panelOrder} confirm`}
              visible={!!exitAuction}
              centered
              onCancel={() => setExitAuction(false)}
              onOk={handleCloseModalAndReturn}
            >
              Dou You really want exit?
            </Modal>
          )}
        </SCard>
      );
    }
    if (currentLaneStatus === 'ended' || currentLaneStatus === 'notexist') {
      return (
        <Card extra={closeUp && <Button onClick={onClose} icon={<CloseCircleFilled />} />} title="Auction Ended">
          <Row justify="center">
            <Image src="img/finish_flags.png" alt="Lot image" preview={false} width="10%" />
          </Row>
          <Row justify="center">
            <Button onClick={handleRedirectToCalendar}>Auction Calendar</Button>
            <Button onClick={handleCloseModalAndReturn}>Close</Button>
          </Row>
        </Card>
      );
    }
    return (
      <SkeletonWrapper gutter={8} justify="center">
        <Col xs={12}>
          <Spin tip="Loading..." indicator={<img src="img/automobileTypesList/Cabriolet.svg" alt="Loading icon" />}>
            <Row justify="center">
              <Image src="img/noImg.jpg" alt="Lot image" preview={false} width="100%" />
            </Row>
            <Row justify="space-between">
              <Skeleton paragraph={{ rows: 20 }} />
            </Row>
          </Spin>
        </Col>
        <Col xs={12}>
          <Spin tip="Loading..." indicator={<img src="img/automobileTypesList/Cabriolet.svg" alt="Loading icon" />}>
            <NextLotContainer index={1}>
              <Row>
                <Skeleton avatar={{ shape: 'square', size: 'large' }} />
              </Row>
            </NextLotContainer>
            <NextLotContainer index={2}>
              <Row>
                <Skeleton avatar={{ shape: 'square', size: 'large' }} />
              </Row>
            </NextLotContainer>
            <NextLotContainer index={3}>
              <Row>
                <Skeleton avatar={{ shape: 'square', size: 'large' }} />
              </Row>
            </NextLotContainer>
          </Spin>
        </Col>
      </SkeletonWrapper>
    );
  }, [currentLaneStatus, currentPanelLaneInfo, closeUp, laneId, panelId, exitAuction, panelOrder, onClose, music, sound]);

  return handleRenderView();
};

export default memo(Dashboard);
