import React, { FC, memo } from 'react';
import YouTube, { Options } from 'react-youtube';

const opts: Options = {
  width: '100%',
  host: 'https://www.youtube.com',
};

interface Props {
  videoId: string;
  height?: string;
  autoplay?: boolean;
}

const YoutubeVideo: FC<Props> = ({ videoId, height = '100%', autoplay }) => (
  <YouTube
    videoId={videoId}
    opts={{
      ...opts,
      height,
      playerVars: {
        autoplay: autoplay ? 1 : 0,
      },
    }}
  />
);

export default memo(YoutubeVideo);
