export type OdometrFilter = {
  id: number;
  value: string;
  from: string | null;
  to: string | null;
};

export const odometrValues: OdometrFilter[] = [
  {
    id: 1,
    value: 'Less than 25,000',
    from: null,
    to: '25000',
  },
  {
    id: 2,
    value: '25,000 to 50,000',
    from: '25000',
    to: '50000',
  },
  {
    id: 3,
    value: '50,000 to 75,000',
    from: '50000',
    to: '75000',
  },
  {
    id: 4,
    value: '75,000 to 100,000',
    from: '75000',
    to: '100000',
  },
  {
    id: 5,
    value: '100,000 to 150,000',
    from: '100000',
    to: '150000',
  },
  {
    id: 6,
    value: '150,000 to 200,000',
    from: '150000',
    to: '200000',
  },
  {
    id: 7,
    value: '200,000 to 250,000',
    from: '200000',
    to: '250000',
  },
  {
    id: 7,
    value: '250,000 to 300,000',
    from: '250000',
    to: '300000',
  },
  {
    id: 7,
    value: '300,000 to 350,000',
    from: '300000',
    to: '350000',
  },
  {
    id: 7,
    value: '350,000 to 400,000',
    from: '350000',
    to: '400000',
  },
  {
    id: 7,
    value: '400,000 to 450,000',
    from: '400000',
    to: '450000',
  },
  {
    id: 7,
    value: '450,000 to 500,000',
    from: '450000',
    to: '500000',
  },
  {
    id: 7,
    value: 'More than 500,000',
    from: '500000',
    to: null,
  },
];

export const contactCountries = [
  {
    id: 'GE',
    value: 'Georgia',
    address: {
      address: 'Tbilisi, Beliashvili St 43',
      lat: 33.8657314057444,
      lng: -118.20572347564462,
    },
    phone: '(+ 995 32) 255 08 80',
    email: 'info@lionauctions.com',
    fb: 'https://www.facebook.com/lionautoauction',
    instagramm: 'https://www.instagram.com/lionautoauction',
    ln: 'https://linkedin.com/company/lionautoauction',
    youtube: 'https://www.youtube.com/channel/UCYorH6PDb7ZTGg0fv4Sfh0A?view_as=subscriber',
    promoVideo: 'https://www.youtube.com/watch?v=ceIhTxBA22E',
    pageId: '1522204114700049',
    appId: '392918625678440',
  },
  {
    id: 'AM',
    value: 'Armenia',
    address: {
      address: 'Yerevan, 1/2 Tsitsernakaberd Hwy',
      lat: 40.17945505956296,
      lng: 44.490003063553104,
    },
    phone: '+374 43 39 10 10',
    email: 'info@lionauctions.com',
    fb: 'https://www.facebook.com/lionautoauction',
    instagramm: 'https://www.instagram.com/lionautoauction',
    ln: 'https://linkedin.com/company/lionautoauction',
    youtube: 'https://www.youtube.com/channel/UCYorH6PDb7ZTGg0fv4Sfh0A?view_as=subscriber',
    promoVideo: 'https://www.youtube.com/watch?v=ceIhTxBA22E',
    pageId: '103903278721711',
    appId: '392918625678440',
  },
  {
    id: 'UA',
    value: 'Ukraine',
    address: {
      address: 'Kiev, Dniprovska Embankment 14n',
      lat: 50.402029581710146,
      lng: 30.61340127572521,
    },
    phone: '+380 44 299 0505',
    email: 'info@lionauctions.com',
    fb: 'https://www.facebook.com/lionautoauction',
    instagramm: 'https://www.instagram.com/lionautoauction',
    ln: 'https://linkedin.com/company/lionautoauction',
    youtube: 'https://www.youtube.com/channel/UCYorH6PDb7ZTGg0fv4Sfh0A?view_as=subscriber',
    promoVideo: 'https://www.youtube.com/watch?v=ceIhTxBA22E',
    pageId: '1522204114700049',
    appId: '392918625678440',
  },
  {
    id: 'US_CA',
    value: 'USA (CA)',
    address: {
      address: 'CA, 18221 S Susana Rd, Unit 2 Compton',
      lat: 33.8657689902562,
      lng: -118.20566993763796,
    },
    phone: '+1 (562) 470 6990',
    email: 'info@lionauctions.com',
    fb: 'https://www.facebook.com/lionautoauction',
    instagramm: 'https://www.instagram.com/lionautoauction',
    ln: 'https://linkedin.com/company/lionautoauction',
    youtube: 'https://www.youtube.com/channel/UCYorH6PDb7ZTGg0fv4Sfh0A?view_as=subscriber',
    promoVideo: 'https://www.youtube.com/watch?v=ceIhTxBA22E',
    pageId: '1522204114700049',
    appId: '392918625678440',
  },
  {
    id: 'US_NJ',
    value: 'USA (NJ)',
    address: {
      address: 'NJ, 07840 60 Asbury Rd. Hackettstown',
      lat: 33.8657689902562,
      lng: -118.20566993763796,
    },
    phone: '+1 908 979 9300',
    email: 'info@lionauctions.com',
    fb: 'https://www.facebook.com/lionautoauction',
    instagramm: 'https://www.instagram.com/lionautoauction',
    ln: 'https://linkedin.com/company/lionautoauction',
    youtube: 'https://www.youtube.com/channel/UCYorH6PDb7ZTGg0fv4Sfh0A?view_as=subscriber',
    promoVideo: 'https://www.youtube.com/watch?v=ceIhTxBA22E',
    pageId: '1522204114700049',
    appId: '392918625678440',
  },
  {
    id: 'KG',
    value: 'Kyrgyztan',
    address: {
      address: 'Bishkek, Toktogula str. 125/1 Avangard B.C.',
      lat: 42.87263361467531,
      lng: 74.59763585945043,
    },
    phone: '+996 507 088 222',
    email: 'info@lionauctions.com',
    fb: 'https://www.facebook.com/lionautoauction',
    instagramm: 'https://www.instagram.com/lionautoauction',
    ln: 'https://linkedin.com/company/lionautoauction',
    youtube: 'https://www.youtube.com/channel/UCYorH6PDb7ZTGg0fv4Sfh0A?view_as=subscriber',
    promoVideo: 'https://www.youtube.com/watch?v=ceIhTxBA22E',
    pageId: '1522204114700049',
    appId: '392918625678440',
  },
];

export const advancedSearchBanners = [
  {
    id: 0,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'Lion Auctions',
    body: '<a href="https://www.lionauctions.com"><img src="img/banners/advance/advance_1.png"/></a>',
  },
  {
    id: 1,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'LionTrans',
    body: '<a href="https://liontrans.com" target="_blank"><img src="img/banners/advance/advance_2.png"/></a>',
  },
  {
    id: 2,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'Lion Auctions',
    body: '<a href="https://www.lionauctions.com"><img src="img/banners/advance/advance_3.png"/></a>',
  },
  {
    id: 3,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'MotorLandUSA',
    body: '<a href="https://motorlandusa.com" target="_blank" rel="nofollow noopener noreferrer"><img src="https://motorlandusa.com/theme/img/logo/logo.svg"/></a>',
  },
];

export const classicSearchBanners = [
  {
    id: 0,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'Lion Auctions',
    body: '<a href="https://liontrans.com"><img src="img/banners/classic/classic_1.png"/></a>',
  },
  {
    id: 1,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'LionTrans',
    body: '<a href="https://liontrans.com" target="_blank"><img src="img/banners/classic/classic_1.png"/></a>',
  },
  {
    id: 3,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'LionTrans',
    body: '<a href="https://liontrans.com" target="_blank"><img src="img/banners/classic/classic_1.png"/></a>',
  },
  {
    id: 4,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'Lion Auctions',
    body: '<a href="https://www.lionauctions.com"><img src="img/banners/classic/classic_1.png"/></a>',
  },
  {
    id: 5,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'searchClaNotFoundLot',
    body: '<a href="https://www.lionauctions.com"><img src="img/banners/classic/classic_1.png"/></a>',
  },
];

export const permanentBanners = [
  {
    id: 1,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'searchClaNotFoundLot',
    body: '<a href="https://www.lionauctions.com"><img src="img/banners/classic/searchClaNotFoundLot.png"/></a>',
  },
  {
    id: 2,
    isDeleted: false,
    languageKeyName: 'en',
    title: 'DataSourceBanner',
    body: '<a href="https://www.lionauctions.com"><img width="100%" src="img/liontrans.png"/></a>',
  },
];

export const bodyMenuList = [
  {
    link: '/classicsearch?bodystyle=Sedan',
    title: 'Sedan',
  },
  {
    link: '/classicsearch?bodystyle=Jeep',
    title: 'Jeep',
  },
  {
    link: '/classicsearch?bodystyle=Hatchback',
    title: 'Hatchback',
  },
  {
    link: '/classicsearch?bodystyle=Coupe',
    title: 'Coupe',
  },
  {
    link: '/classicsearch?bodystyle=Pickup',
    title: 'Pickup',
  },
  {
    link: '/classicsearch?bodystyle=Microbus',
    title: 'Microbus',
  },
  {
    link: '/classicsearch?bodystyle=Mini',
    title: 'Mini',
  },
  {
    link: '/classicsearch?bodystyle=Cabriolet',
    title: 'Cabriolet',
  },
  {
    link: '/classicsearch?bodystyle=Minivan',
    title: 'Minivan',
  },
  {
    link: '/classicsearch?bodystyle=Universal',
    title: 'Universal',
  },
];

export const categoryMenuList = [
  {
    id: '22',
    link: '/classicsearch?featuredtype=Business+Class',
    title: 'Business Class',
  },
  {
    id: '16',
    link: '/classicsearch?featuredtype=City+Car',
    title: 'City Car',
  },
  {
    id: '21',
    link: '/classicsearch?featuredtype=Ecology',
    title: 'Ecology',
  },
  {
    id: '17',
    link: '/classicsearch?featuredtype=Economy+Car',
    title: 'Economy Car',
  },
  {
    id: '5',
    link: '/classicsearch?featuredtype=Engine+Start+Program',
    title: 'Engine Start Program',
  },
  {
    id: '19',
    link: '/classicsearch?featuredtype=Family+Car',
    title: 'Family Car',
  },
  {
    id: '13',
    link: '/classicsearch?featuredtype=Hybrid+Vehicles',
    title: 'Hybrid Vehicles',
  },
  {
    id: '1',
    link: '/classicsearch?featuredtype=Run+and+Drive',
    title: 'Run and Drive',
  },
  {
    id: '23',
    link: '/classicsearch?featuredtype=Sport+Car',
    title: 'Sport Car',
  },
  {
    id: '18',
    link: '/classicsearch?featuredtype=SUV+and+Off+Road',
    title: 'SUV and Off Road',
  },
  {
    id: '20',
    link: '/classicsearch?featuredtype=Week+Offer',
    title: 'Week Offer',
  },
];

export const conditionMenuList = [
  {
    link: '/classicsearch?defect=New',
    title: 'New',
  },
  {
    link: '/classicsearch?defect=Used',
    title: 'Used',
  },
  {
    link: '/classicsearch?defect=Damaged',
    title: 'Damaged',
  },
];
