import React from 'react'
import { useTranslation } from 'react-i18next'
import LotCardDescription, { LotCardRow, LotCardText } from '../../../shared/components/LotDescriptionCard'

const VehicleBodyCard = ({vehicle}: any) => {

  const { t } = useTranslation()

  return (
    <LotCardDescription title={t("electric_description_vehicle_body")}>
        <>
            <LotCardRow>
                <LotCardText>{t("lengthWidthHeight")}</LotCardText>
                <LotCardText>{vehicle?.vehicleBody?.lengthWidthHeight}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("type")}</LotCardText>
                <LotCardText>{vehicle?.vehicleBody?.type}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("roof_rails")}</LotCardText>
                <LotCardText>{vehicle?.vehicleBody?.roofRails ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("clearence")}</LotCardText>
                <LotCardText>{vehicle?.vehicleBody?.clearence}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("weight")}</LotCardText>
                <LotCardText>{vehicle?.vehicleBody?.weight}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("doorSeats")}</LotCardText>
                <LotCardText>{vehicle?.vehicleBody?.doorSeats}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("rims")}</LotCardText>
                <LotCardText>{vehicle?.vehicleBody?.rims}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("wheels")}</LotCardText>
                <LotCardText>{vehicle?.vehicleBody?.wheels}</LotCardText>
            </LotCardRow>
        </>
    </LotCardDescription>
  )
}

export default VehicleBodyCard