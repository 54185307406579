import React, { FC, memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Modal } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AuctionsTodayTable from '../../shared/components/AuctionsTodayTable';
import { deletePanel } from '../../store/slices/auctionSlice';
import Dashboard from '../../shared/components/Auction/Dashboard';

interface Props {
  panelId: number;
  panelOrder: number;
  totalPanels: number;
  laneId?: number | null;
  totalCountAuctions: number;
}

export const ScCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

const Panel: FC<Props> = ({ panelId, panelOrder, totalPanels, laneId, totalCountAuctions }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [closePanel, setclosePanel] = useState<boolean>(false);

  const handleDelete = () => {
    setclosePanel(true);
    dispatch(deletePanel(panelId, laneId));
  };

  const handleOpenModalClose = () => {
    setclosePanel(true);
  };

  const handleCloseModalClose = () => {
    setclosePanel(false);
  };

  return (
    <Col xs={24} lg={12}>
      {!laneId ? (
        <ScCard
          title={`${t('nav_main_menu_auctions_today')} (${totalCountAuctions})`}
          bordered={false}
          extra={totalPanels !== 1 && <CloseCircleFilled onClick={handleOpenModalClose}>Close</CloseCircleFilled>}
        >
          <AuctionsTodayTable panelId={panelId} />
          {!!closePanel && (
            <Modal title={`Close Panel #${panelOrder} confirm`} visible={!!closePanel} centered onCancel={handleCloseModalClose} onOk={handleDelete}>
              Dou You really want exit?
            </Modal>
          )}
        </ScCard>
      ) : (
        <Dashboard panelId={panelId} panelOrder={panelOrder} laneId={laneId} closeUp={totalPanels !== 1} onClose={handleDelete} />
      )}
    </Col>
  );
};
export default memo(Panel);
