/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk, RootState } from '../store';
import api from '../../core/api';
import { AccountSettings, UserContactInfo, FormsFileItem } from '../../interfaces/Profile';
import { axiosInstanceIdentity } from '../../core/axios-identity';
import { axiosInstance } from '../../core/axios';
import { showNotification } from '../../utils/notify';
import { DictionaryItem } from '../../interfaces/Dictionary';
import i18n from '../../i18n';

export interface InitState {
  formsFiles: FormsFileItem[];
  formsFilesLoading: boolean;
  userLanguageDictionary: DictionaryItem[];
  accountSettings: AccountSettings | null;
  bidderList: DictionaryItem[];
  userContactInfo: UserContactInfo | null;
  uploadFile: boolean;
}

export const initialState: InitState = {
  formsFiles: [],
  formsFilesLoading: false,
  userLanguageDictionary: [],
  accountSettings: null,
  bidderList: [],
  userContactInfo: null,
  uploadFile: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    formsFilesRequest: (state) => {
      state.formsFilesLoading = true;
    },
    formsFilesSuccess: (state, action) => {
      state.formsFiles = action.payload;
      state.formsFilesLoading = false;
    },
    formsFilesFailure: (state) => {
      state.formsFilesLoading = true;
    },
    userLanguageDictionarySuccess: (state, action) => {
      state.userLanguageDictionary = action.payload;
    },
    accountSettingsSuccess: (state, action) => {
      state.accountSettings = action.payload;
    },
    bidderListSuccess: (state, action) => {
      state.bidderList = action.payload;
    },
    userContactInfoSuccess: (state, action) => {
      state.userContactInfo = action.payload.userInfo;
    },
    uploadFileRequest: (state) => {
      state.uploadFile = true;
    },
    uploadFileDone: (state) => {
      state.uploadFile = false;
    },
  },
});

export const {
  formsFilesRequest,
  formsFilesSuccess,
  formsFilesFailure,
  userLanguageDictionarySuccess,
  accountSettingsSuccess,
  bidderListSuccess,
  userContactInfoSuccess,
  uploadFileRequest,
  uploadFileDone,
} = profileSlice.actions;

export const getFormsFiles = (): AppThunk => async (dispatch) => {
  try {
    dispatch(formsFilesRequest());
    const res = await axiosInstance.get(api.GetFormsFiles);
    dispatch(formsFilesSuccess(res.data));
  } catch (error: any) {
    dispatch(formsFilesFailure());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getUserLanguageDictionary = (): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstanceIdentity.get(api.GetUserLanguageDictionary);
    dispatch(userLanguageDictionarySuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const changeLanguage = (lunguageId: number): AppThunk => async (dispatch) => {
  try {
    await axiosInstanceIdentity.post(api.ChangeLanguage, null, {
      params: {
        lunguageId,
      },
    });
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getAccountSettings = (): AppThunk => async (dispatch) => {
  try {
    dispatch(uploadFileRequest());
    const res = await axiosInstanceIdentity.get(api.GetAccountSettings);
    dispatch(accountSettingsSuccess(res.data));
    dispatch(uploadFileDone());
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
    dispatch(uploadFileDone());
  }
};

export const getBidderList = (): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstanceIdentity.get(api.GetBidderList);
    dispatch(bidderListSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getUserContactInfo = (): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstanceIdentity.get(api.GetUserContactInfo);
    dispatch(userContactInfoSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const changePassword = (currentPassword: string, newPassword: string): AppThunk => async (dispatch) => {
  try {
    await axiosInstanceIdentity.post(api.ChangePassword, {
      currentPassword,
      newPassword,
    });
    showNotification('Success', i18n.t("profile_passchange_noti"));
  } catch (error: any) {
    showNotification('error', i18n.t(error.response.data.errorMessage));
  }
};

export const editUserContactInfo = (
  city: string,
  country: string,
  email: string,
  firstName: string,
  lastName: string,
  lionTranceAccount: string,
  phoneNumber: string,
  dialCode: string,
  physicalAddress: string,
  stateProvince: string,
  vehicleTitlesIssuedTo: string,
  zip: string,
): AppThunk => async (dispatch) => {
  try {
    await axiosInstanceIdentity.post(api.EditUserContactInfo, {
      addressLine: physicalAddress || '',
      confirmEmail: email || '',
      state: stateProvince || '',
      city,
      country,
      email,
      firstName,
      lastName,
      lionTranceAccount,
      phoneNumber,
      dialCode,
      physicalAddress,
      stateProvince: stateProvince || '',
      vehicleTitlesIssuedTo,
      zip,
      MailingAddress: '',
    });
    showNotification('Success', 'Saved');
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const downloadPdf = (title: string, apiUrl: string): AppThunk => async (dispatch, state) => {
  try {
    await axios({
      url: `${process.env.REACT_APP_API_URL}api/${apiUrl}`, // your url
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: `Bearer ${state().auth.user?.access_token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title}.pdf`); // or any other extension
      document.body.appendChild(link);
      link.click();
    });
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const addNewLicenseFile = (fileToSend: { id: null | string; fileName: string; originalName: string; url: string }): AppThunk => async (dispatch) => {
  try {
    dispatch(uploadFileRequest());
    await axiosInstanceIdentity.post(api.AddNewLicenseFile, fileToSend);
    await dispatch(getAccountSettings());
    showNotification('Success', 'Success');
    dispatch(uploadFileDone());
  } catch (error: any) {
    dispatch(uploadFileDone());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const DeleteUserLicenseFile = (licenseid: number): AppThunk => async (dispatch) => {
  try {
    await axiosInstanceIdentity.post(api.DeleteUserLicenseFile, null, {
      params: { licenseFileId: licenseid },
    });
  } catch (error: any) {
    showNotification('error', error.response?.data?.errorMessage || 'An error occurred');
  }
};

// SELECTORS
export const formsFilesSelector = (state: RootState) => state.profile.formsFiles;
export const formsFilesLoadingSelector = (state: RootState) => state.profile.formsFilesLoading;
export const userLanguageDictionarySelector = (state: RootState) => state.profile.userLanguageDictionary;
export const accountSettingsSelector = (state: RootState) => state.profile.accountSettings;
export const bidderListSelector = (state: RootState) => state.profile.bidderList;
export const userContactInfoSelector = (state: RootState) => state.profile.userContactInfo;
export const uploadFileSelector = (state: RootState) => state.profile.uploadFile;

export default profileSlice.reducer;
