import React, { FC, memo } from 'react';
import { Tooltip, Tag } from 'antd';
import { Badge } from '../../../interfaces/Dictionary';

interface Props {
  badge: Badge;
  className?: string;
}

const BadgeWithTooltip: FC<Props> = ({ badge, className }) => {
  const getColorBadgeTag = (name: string) => {
    if (name === 'Run and Drive') return 'green';
    if (name === 'Not started') return 'red';
    return 'geekblue';
  };

  return (
    <Tooltip title={`${badge.name} | ${badge.description}`}>
      <Tag color={getColorBadgeTag(badge.name)} className={className}>
        {`${badge.name}`}
      </Tag>
    </Tooltip>
  );
};

export default memo(BadgeWithTooltip);
