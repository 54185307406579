import { Collapse, Typography } from 'antd';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import Search from 'antd/lib/input/Search';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { faqQuestionsSelector, getAllFaqQuestions } from '../../store/slices/faqSlice';
import { ScTitle } from '../style';
import ContentWrapper from '../../shared/components/ContentWrapper';
import { PageTitle } from '../../shared/sharedComponent';
import Meta from '../../shared/components/Meta';

const { Panel } = Collapse;
const { Text } = Typography;

const ScSearch = styled(Search)`
  margin: 32px 0 16px;
`;

const ScCollapse = styled(Collapse)`
  .ant-collapse-header {
    font-size: 15px;
  }
`;

const HelpPage: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filterKeyword, setFilterKeyword] = useState('');
  const [cookies] = useCookies(['language']);

  useEffect(() => {
    dispatch(getAllFaqQuestions(cookies.language));
  }, [cookies.language, dispatch]);

  const handleChangeKeyword = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterKeyword(event.target.value);
  };

  const faqQuestions = useSelector(faqQuestionsSelector);

  return (
    <><Meta
      title={t("meta_help_title")}
      description={t("meta_help_desc")} /><ContentWrapper styles={{ marginTop: 0, paddingBottom: '2.5rem' }}>
        <PageTitle>{t('nav_main_menu_help')}</PageTitle>
        <ScSearch onChange={handleChangeKeyword} placeholder={t("search_keyword")} allowClear enterButton />
        <ScCollapse>
          {faqQuestions
            .filter((item) => item.question.toLowerCase().includes(filterKeyword.toLowerCase()))
            .map((item) => (
              <Panel header={item.question} key={item.id}>
                <Text>{parse(item.answer)}</Text>
              </Panel>
            ))}
        </ScCollapse>
      </ContentWrapper></>
  );
};

export default HelpPage;
