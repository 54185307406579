import styled from "styled-components";

export const DepositWrapper = styled.div`
    padding: 1.5rem 2.5rem;
    @media(max-width: 1400px){
        padding: 1.5rem 1rem;
    }
`

export const DepositHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem; 
    .ant-btn{
        font-family: 'main';
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1.125rem;
        height: auto;
        padding: 0.75rem 1rem;
        margin-left: 0.5rem;
    }
    @media(max-width: 767px){
        .ant-btn{
            padding: 0.5rem 1rem;
            span:not(.anticon){
                display: none;
            }
        }
    }
    @media(max-width: 400px){
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`


export const AboutDepositButton = styled.button`
    font-family: 'main';
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #1B153D;
    border: 1px solid #CACACA;
    border-radius: 4px;
    padding: 0.75rem 1rem;
    @media(max-width: 767px){
        padding: 0.5rem 1rem;
    }
`

export const AmountWrapper = styled.div`
    position: relative;
    margin-right: 1.5rem;
    @media(max-width: 767px){
        margin-right: auto;
    }
    
    @media(max-width: 400px){
        width: 100%;
    }
`

export const AmountHeader = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    .text-wrapper{
        display: flex;
        flex-direction: column;
        margin-right: 0.75rem;
    }
    span{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: #9095A6;
    }
    .price{
        margin-top: 0.5rem;
        span{
            font-family: 'main';
            font-weight: 500;
            font-size: 1rem;
            line-height: 0.875rem;
            color: #1B153D;
        }
    }
    .svg-wrapper{
        transform: rotate(0);
        transition: 0.2s;
        &, & div{
            width: 1.5rem;
            height: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #F0F0F0;
            border-radius: 50%;
        }
        svg{
            width: 0.75rem;
            height: 0.75rem;
        }
        &.upsidedown{
            transform: rotate(180deg);
        }
    }
    @media(max-width: 767px){
        .price{
            margin-top: 0.25rem;
        }
        .text-wrapper{
            span{
                font-size: 0.625rem;
                line-height: 0.875rem;
            }
        }
    }
`

export const AmountBody = styled.div`
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    position: absolute;
    right: 0;
    top: 100%;
    min-width: 20rem;
    padding: 1rem;
    z-index: 9;
    ul{
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    li{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        span{
            color: #9095A6;
        }
        span:last-child{
            color: #1B153D;
            padding-left: 0.5rem;
        }
    }
    @media(max-width: 767px){
        position: static;
        border: none;
        padding: 0;
    }
`

export const PopupWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    padding: 6.25rem 1rem 1rem;
    width: 100%;
    height: 100%;
    z-index: 99999;
    @media(max-width: 767px){
        padding: 0;
        top: 4.25rem;
        height: calc( 100dvh - 4.25rem );
        overflow-y: auto;
    }
`

export const PopupConent = styled.div`
    background: #fff;
    padding: 2.5rem;
    border-radius: 8px;
    position: relative;
    max-width: 50%;
    min-width: 30rem;
      margin-right: auto;
  margin-left: auto;
    & > button{
        position: absolute;
        right: 1.325rem;
        top: 1.325rem;
        cursor: pointer;
    }
    @media(max-width: 767px){
        padding: 1.5rem 1rem;
        margin: 0;
        max-width: 100%;
        min-width: auto;
        border-radius: 0px 0px 8px 8px ;
    }
`

export const PopupChildrenWrapper = styled.div``

export const AboutDepositContent = styled.div`
    h3{
        font-family: 'main';
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: #1B153D;
        margin-bottom: 2rem;
    }
    ol{
        padding: 0;
        list-style-position: inside;
    }
    li{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.5rem;
        color: #1B153D;
    }
`

export const AmountPopupWrapper = styled.div`
    h3{
        font-family: 'main';
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: #1B153D;
        margin-bottom: 2rem;
    }
`

export const DepositCardWrapper = styled.div`
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 0px 50px #E8E8E8;
    border-radius: 8px;
    padding: 1rem 0.75rem;
    ul{
        padding: 0;
    }
    li{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        display: flex;
        justify-content: space-between;
        span{
            color: #9095A6;
        }
        p{
            color: #1B153D;
            margin-left: 0.5rem;
        }
    }
`

export const DepositCardFooter = styled.div`
    border-top: 1px solid #EAEAEA;
    padding-top: 0.75rem;   
    display: flex;
    justify-content: flex-end;
    .ant-btn{
        font-family: "main";
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.125rem;
        padding: 0.5rem 0.75rem;
        margin-left: 0.75rem;
    }
`

export const DepositCardList = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.75rem;
    width: 100%;
`

export const PaginationWrapper = styled.div`
    margin-top: 1rem;
`