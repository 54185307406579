import React, { Component } from 'react';
import ThreeSixty from 'react-360-view'

const basePath = "https://fastly-production.24c.in/webin/360"
const Test360 = () => {
  return (
    <div>
        <ThreeSixty
            amount={75}
            imagePath={basePath}
            fileName="output_{index}.jpeg"
        />

    </div>
  )
}

export default Test360