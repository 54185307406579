import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const InputWrapper = styled.div`
  display: grid;
  gap: 10px;
  width: 100%;
  .input-group {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const Title = styled.h2`
  color: #141719;
  font-family: 'main';
  &:lang(ka) {
    font-family: 'arial-caps';
  }
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3.25rem;
  margin-bottom: 2rem;
  @media (max-width: 991px) {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }
`;

export const Input = styled.input`
  height: 48px;
  width: 100%;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: #1b153d;
  padding: 0px 12px;

  background: #ffffff;
  border: 1px solid #d1d5d6;
  box-shadow: 0px 4px 30px #efefef;
  border-radius: 8px;

  &::placeholder {
    color: #566063;
    font-family: main;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;

export const Textarea = styled.textarea`
  border-radius: 0.25rem;
  border: 1px solid #a5aaac;
  background: #fff;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
  height: 11.5rem;
  width: 100%;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: #1b153d;
  padding: 0.75rem 1rem;
  resize: none;

  height: 100px;
  background: #ffffff;
  border: 1px solid #d1d5d6;
  box-shadow: 0px 4px 30px #efefef;
  border-radius: 8px;

  &::placeholder {
    color: #566063;
    font-family: main;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  background: #ce2424;
  color: #fff;
  text-align: center;
  font-family: main;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  .svg-wrapper {
    &,
    div,
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
`;
