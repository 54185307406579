import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ServiceCardVariant } from ".";

interface ServiceCardWrapperProps {
    variant?: ServiceCardVariant
}

export const ServiceCardWrapper = styled.div<ServiceCardWrapperProps>`
    background-color: ${p => p.variant ? "#17191C" : "#FFFFFF"};;
    border-radius: 0.5rem;
    padding: 2rem 1.25rem 1.25rem;
    display: flex;
    flex-direction: column;
    h3{
        font-family: 'main';
        &:lang(ka){
            font-family: 'arial-caps';
        }
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: ${p => p.variant  ? "#FFFFFF" : "#080808"};
        margin: 0;
    }
    ${p => !p.variant && css`
        border: 1px solid #F5F5F5;
        box-shadow: 0px 0px 50px #E8E8E8;
    `}
    @media (max-width: 767px) {
        h3{
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.25rem;
        }
        ${p => p.variant == ServiceCardVariant.DARK && css`
            min-width: 80vw;
        `}
        ${p => p.variant == ServiceCardVariant.DARK_DESKTOP && css`
            padding: 1.25rem;
            justify-content: center;
            background-color: #FFFFFF;
            border: 1px solid #F5F5F5;
            box-shadow: 0px 0px 50px #E8E8E8;
            h3{
                color: #080808;
            }
        `}
    }
`

export const ServiceCardHeader = styled.div`
    @media (max-width: 767px) {
        display: flex;
        align-items: center;
        gap: 1.25rem;
    }
`

interface ServiceCardDescriptionWrapperProps {
    showMore: boolean;
    variant?: ServiceCardVariant
}

export const ServiceCardDescriptionWrapper = styled.div<ServiceCardDescriptionWrapperProps>`
    display: flex;
    flex-direction: column;
    padding-top: 1.125rem;
    gap: 1.125rem;
    p{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: ${p => p.variant  ? "#FFFFFF" : "#1B153D"};
        margin-bottom: auto;
    }
    @media (max-width: 767px) {
        display: none;
        p{
            font-weight: 300;
            font-size: 0.75rem;
            line-height: 1.5rem;
            color: ${p => p.variant == ServiceCardVariant.DARK  ? "#FFFFFF" : "#080808"};
            margin: 0;
        }
        
        ${p => p.variant == ServiceCardVariant.DARK && css`
            display: flex;
            max-height: 5.75rem;
            overflow: hidden;
        `}
        
        ${p => p.showMore && css`
            display: flex;
            max-height: 100%;
        `}
    }
`

export const IconWrapper = styled.div`
    width: 3.25rem;
    height: 3.25rem;
    margin-bottom: 1rem;
    @media (max-width: 767px) {
        margin: 0;
        &, div, svg{
            width: 2rem;
            height: 2rem;
        }
    }
`

export const ShowMoreIconWrapper = styled.button`
    display: none;
    @media (max-width: 767px) {
        margin-left: auto;
        display: block;
    }
`

export const ReadMoreLink = styled.button`
    display: none;
    font-family: 'main';
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #FFFFFF;
    background: #CE2424;
    border-radius: 6px;
    width: 100%;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    border-top: 3rem solid #17191C;
    margin-top: auto;
    &:is(:hover, :active){
        color: #FFFFFF;
    }
    @media (max-width: 767px) {
        display: flex;
    }
`