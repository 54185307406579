/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Checkbox, Col, Form, Input, Row, Select, Space, Upload, Radio, Steps } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { NamePath } from 'antd/lib/form/interface';
import { productGeneralDictionariesByLanguageSelector } from '../../store/slices/dictionarySlice';
import { cityStates } from '../../countries';
import { register } from '../../store/slices/authSlice';
import { RegisterData } from '../../interfaces/User';
import { ContentWrapperCardStyle, ImgWrapper, FormWrapper, StepShow, UploadWrapper } from './style';
import { ScTitle } from '../style';
import { BtnsContainer } from '../../shared/components/Header/style';
import { showNotification } from '../../utils/notify';
import { disableAutocomplete, getBase64 } from '../../utils/functions';
import CustomPhoneInput, { CustomPhoneInputVariant } from '../../shared/components/CustomPhoneInput';
import parse from 'html-react-parser';
import { Cookies, useCookies } from 'react-cookie';
import FormList from 'antd/lib/form/FormList';
import Meta from '../../shared/components/Meta';

const { Option } = Select;
const { Dragger } = Upload;
const { Step } = Steps;

const SignUpPage: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies] = useCookies(['language']);

  const productGeneralDictionariesByLanguage = useSelector(productGeneralDictionariesByLanguageSelector);

  const [dialCode, setDialCode] = useState('');

  const handleChangePhoneInput = (value: string, data: any) => {

    setDialCode(data.dialCode);
  };

  const canonicalTag = document.querySelector('link[rel="canonical"]');
  if (canonicalTag) {
    canonicalTag.parentNode?.removeChild(canonicalTag);
  }

  const metaTag = document.querySelector('meta[name="robots"]');
  if (metaTag) {
    metaTag.setAttribute('content', 'follow, noindex, max-snippet:-1, max-video-preview:-1, max-image-preview:large');
  }

 interface FileType {
    id: string;
    originalName: string;
    fileName: string;
    type: string;
    url: string;
  }
  const [fileList, setFileList] = useState<FileType[]>([]);

  const handleChange = (info: any) => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj).then((base64Img) => {
        const uploadedFile: FileType = {
          id: `upload-${new Date().getTime()}`,
          originalName: info.file.name,
          fileName: info.file.name,
          type: info.file.type,
          url: base64Img as string,
        };
        setFileList([...fileList, uploadedFile]);
      }).catch(error => {
        console.error('Error:', error);
      });
    } else if (info.file.status === 'removed') {
      const updatedFileList = fileList.filter(file => file.fileName !== info.file.name);
      setFileList(updatedFileList);
    }
  };

  const [USAData, setUSAData] = useState<any>(null)

  useEffect(() => {
    fetch("/minify-usa.json")
    .then( r => r.json())
    .then( r => setUSAData(r))
  }, [])

  useEffect(() => {
    disableAutocomplete();
  }, []);

  const onFinish = useCallback(
    (values: RegisterData) => {
      dispatch(
        register({
          ...values,
          idOrLicenseImages: fileList,
          phoneNumber: values.phoneNumber.replace(dialCode, ''),
          dialCode,
          countryCode: productGeneralDictionariesByLanguage.countriesList?.find((item) => item.value === values.country)?.id || 0,
          language: cookies.language,
        }),
      );
      localStorage.setItem("needRedirect", 'true');
    },
    [dialCode, dispatch, productGeneralDictionariesByLanguage.countriesList, fileList],
  );

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };


  const getCityList = useCallback(() => {
    if (form.getFieldValue('country') === 'United States') {
      return (
        USAData?.states
        .find((state: any) => state.name == form.getFieldValue('stateProvince') )?.cities
        ?.map( (city: any) => city.name ) || []
      );
    }
    return (
      (cityStates as any)[form.getFieldValue('country')]
        ?.split('|')
        .filter((item: string) => item !== '')
        .map((item: string) => item.replace(/&apos;/g, "'"))
        .sort() || []
    );
  }, [form, USAData]);

  const [type, settype] = useState('person');

  const onChange = useCallback(
    (value) => {
      if (value[0]?.name[0] === 'type') {
        settype(value[0]?.value);
      }
      if (value[0]?.name[0] === 'country') {
        form.setFieldsValue({
          stateProvince: undefined,
          city: undefined,
        });
        disableAutocomplete();
      }
      if (value[0]?.name[0] === 'stateProvince') {
        form.setFieldsValue({
          city: undefined,
        });
        disableAutocomplete();
      }
    },
    [form],
  );

  const layout = {
    layout: "vertical"
  }

  const [current, setCurrent] = React.useState(0);

  const handleGoToStep = (step: number) => {
    if (step === 1) {
      form.validateFields().then((values) => {
        setCurrent(step);
      });
    } else {
      setCurrent(step);
    }
  };

  const steps = [
    {
      step: 0,
      title: 'First',
      content: (
        <Space direction="vertical">
          <Form.Item name="type" rules={[{ required: true, message: t('noti_required') }]} label={t('reg_per_choose_type')}>
            <Radio.Group>
              <Radio value="person">{t('reg_per_person')}</Radio>
              <Radio value="company">{t('reg_per_company')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues: any, currentValues: any) => prevValues.type !== currentValues.type}>
            {({ getFieldValue }) =>
              getFieldValue('type') === 'company' ? (
                <>
                  <Form.Item name="companyName" label={t('reg_comp_choose_type1')} rules={[{ required: true, message: t('noti_required') }]}>
                    <Input placeholder={t("reg_comp_placeholder_choose_type1")} autoComplete="new-password" />
                  </Form.Item>
                  <Form.Item name="companyIdNumber" label={t('reg_comp_choose_type2')} rules={[{ required: true, message: t('noti_required') }]}>
                    <Input placeholder={t("reg_comp_placeholder_choose_type2")} autoComplete="new-password" />
                  </Form.Item>
                  <Form.Item name="address" label={t('reg_comp_choose_type3')} rules={[{ required: true, message: t('noti_required') }]}>
                    <Input placeholder={t("reg_comp_placeholder_choose_type3")} autoComplete="new-password" />
                  </Form.Item>
                  <Form.Item name="firstName" label={t('reg_comp_choose_type4')} rules={[{ required: true, message: t('noti_required') }]}>
                    <Input placeholder={t("reg_comp_placeholder_choose_type4")} autoComplete="new-password" />
                  </Form.Item>
                  <Form.Item name="lastName" label={t('reg_comp_choose_type5')} rules={[{ required: true, message: t('noti_required') }]}>
                    <Input placeholder={t("reg_comp_placeholder_choose_type5")} autoComplete="new-password" />
                  </Form.Item>
                </>
              ) : null
            }
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true, message: t('noti_required') }]} label={t('reg_per_email')}>
            <Input placeholder={t("reg_per_placeholder_email")} autoComplete="new-password" />
          </Form.Item>
          <Form.Item name="phoneNumber" rules={[{ required: true, message: t('noti_required')}]} label={t('reg_per_phone_number')}>
            <CustomPhoneInput onChange={handleChangePhoneInput} country={'ge'}/>
          </Form.Item>
          <Form.Item name="country" rules={[{ required: true, message: t('noti_required') }]} label={t('reg_comp_choose_type6')}>
            <Select
              showSearch
              placeholder={t('placeholder_choose')}
              optionFilterProp="children"
              filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {productGeneralDictionariesByLanguage.countriesList?.map((item) => (
                <Option key={item.id} value={item.value}>
                  {item.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues: any, currentValues: any) => prevValues.country !== currentValues.country}>
            {({ getFieldValue }) =>
              getFieldValue('country') === 'United States' ? (
                <Form.Item name="stateProvince" label={t('reg_comp_choose_type7')} rules={[{ required: true, message: t('noti_required') }]}>
                  <Select showSearch placeholder={t('placeholder_choose')}>
                    {USAData?.states?.map((item: any) => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null
            }
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues: any, currentValues: any) =>
              currentValues.country === 'United States'
                ? prevValues.stateProvince !== currentValues.stateProvince
                : prevValues.country !== currentValues.country
            }
          >
            {({ getFieldValue }) =>
              (getFieldValue('country') === 'United States' && getFieldValue('stateProvince')) || getFieldValue('country') ? (
                <Form.Item name="city" label={t('reg_comp_choose_type8')} rules={[{ required: true, message: t('noti_required') }]}>
                  <Select showSearch placeholder={t('placeholder_choose')}>
                    {getCityList().map((item: string) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            name="agreedToTermsAndConditions"
            valuePropName="checked"
            rules={[
              {
                required: true,
                transform: (value) => value || undefined, // Those two lines
                type: 'boolean', // Do the magic
                message: t("reg_check_terms"),
              },
            ]}
          >
            <Checkbox>{parse(t('reg_per_check_box1'))}</Checkbox>
          </Form.Item>
          <Form.Item name="needContactWithRepresentative" valuePropName="checked">
            <Checkbox>{t('reg_per_check_box2')}</Checkbox>
          </Form.Item>
          <Form.Item name="noticeNewBlog" valuePropName="checked">
            <Checkbox>{t('reg_per_check_box3')}</Checkbox>
          </Form.Item>
          <Form.Item name="noticeNewCarBySaveSearch" valuePropName="checked">
            <Checkbox>{t('reg_per_check_box4')}</Checkbox>
          </Form.Item>
          <Form.Item shouldUpdate>
            {({ getFieldsError }) => (
              <BtnsContainer>
                <Button type="primary" onClick={() => handleGoToStep(1)}>
                  {t('Next')}
                </Button>
              </BtnsContainer>
            )}
          </Form.Item>
        </Space>
      ),
    },
    {
      step: 1,
      title: 'Second',
      content: (
        <Form.Item noStyle shouldUpdate={(prevValues: any, currentValues: any) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) =>
            getFieldValue('type') === 'company' ? (
              <UploadWrapper>
                <Form.Item name="idOrLicenseImages" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                  <Dragger name="files" onChange={handleChange} customRequest={({ onSuccess }) => onSuccess("ok") }>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('reg_comp_upload_license_side_text')}</p>
                    <p className="ant-upload-text">{t('reg_comp_your_files_here')}</p>
                    <p className="ant-upload-text">{t('reg_comp__license_file_block')}</p>
                    <p className="ant-upload-hint">{t('reg_comp__license_file_block_size')}</p>
                  </Dragger>
                </Form.Item>
                <Form.Item noStyle>
                  <BtnsContainer>
                    <Button type="primary" htmlType="submit">
                      {t('Submit')}
                    </Button>
                  </BtnsContainer>
                </Form.Item>
              </UploadWrapper>
            ) : null
          }
        </Form.Item>
      ),
    },
  ];

  function toArray<T>(candidate?: T | T[] | false): T[] {
    if (candidate === undefined || candidate === false) return [];
    return Array.isArray(candidate) ? candidate : [candidate];
  }

  function toNamePathStr(name: NamePath) {
    const namePath = toArray(name);
    return namePath
      .join('_')
      .replace(/([A-Z])/g, ' $1')
      .toUpperCase();
  }

  const getPersonFields = () => (
    <>
      <Form.Item name="type" rules={[{ required: true, message: t('noti_required') }]} label={t('reg_per_choose_type')}>
        <Radio.Group>
          <Radio value="person">{t('reg_per_person')}</Radio>
          <Radio value="company">{t('reg_per_company')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="firstName" rules={[{ required: true, message: t('noti_required') }]} label={t('reg_per_first_name')}>
        <Input placeholder={t("reg_per_placeholder_first_name")} autoComplete="new-password" />
      </Form.Item>
      <Form.Item name="lastName" rules={[{ required: true, message: t('noti_required') }]} label={t('reg_per_last_name')}>
        <Input placeholder={t("reg_per_placeholder_last_name")} autoComplete="new-password" />
      </Form.Item>
      <Form.Item name="email" rules={[{ required: true, message: t('noti_required') }]} label={t('reg_per_email')}>
        <Input placeholder={t("reg_per_placeholder_email")} autoComplete="new-password" />
      </Form.Item>
      <Form.Item name="phoneNumber" rules={[{ required: true, message: t('noti_required') }]} label={t('reg_per_phone_number')}>
        <CustomPhoneInput onChange={handleChangePhoneInput} country={'ge'}/>
      </Form.Item>
      <Form.Item name="country" rules={[{ required: true, message: t('noti_required') }]} label={t('reg_per_country')}>
        <Select
          showSearch
          placeholder={t('placeholder_choose')}
          optionFilterProp="children"
          filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {productGeneralDictionariesByLanguage.countriesList?.map((item) => (
            <Option key={item.id} value={item.value}>
              {item.value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prevValues: any, currentValues: any) => prevValues.country !== currentValues.country}>
        {({ getFieldValue }) =>
          getFieldValue('country') === 'United States' ? (
            <Form.Item name="stateProvince" label={t("reg_comp_choose_type7")} rules={[{ required: true, message: t('noti_required') }]}>
              <Select showSearch placeholder={t('placeholder_choose')}>
                {USAData?.states?.map((item: any) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues: any, currentValues: any) =>
          currentValues.country === 'United States' ? prevValues.stateProvince !== currentValues.stateProvince : prevValues.country !== currentValues.country
        }
      >
        {({ getFieldValue }) =>
          (getFieldValue('country') === 'United States' && getFieldValue('stateProvince')) || getFieldValue('country') ? (
            <Form.Item name="city" label={t("reg_comp_choose_type8")} rules={[{ required: true, message: t('noti_required') }]}>
              <Select showSearch placeholder={t('placeholder_choose')}>
                {getCityList().map((item: string) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Form.Item
        name="agreedToTermsAndConditions"
        valuePropName="checked"
        rules={[
          {
            required: true,
            transform: (value) => value || undefined, // Those two lines
            type: 'boolean', // Do the magic
            message: t("reg_check_terms"),
          },
        ]}
      >
        <Checkbox>{parse(t('reg_per_check_box1'))}</Checkbox>
      </Form.Item>
      <Form.Item name="needContactWithRepresentative" valuePropName="checked">
        <Checkbox>{t('reg_per_check_box2')}</Checkbox>
      </Form.Item>
      <Form.Item name="noticeNewBlog" valuePropName="checked">
        <Checkbox>{t('reg_per_check_box3')}</Checkbox>
      </Form.Item>
      <Form.Item name="noticeNewCarBySaveSearch" valuePropName="checked">
        <Checkbox>{t('reg_per_check_box4')}</Checkbox>
      </Form.Item>
      <Form.Item>
        <BtnsContainer>
          <Button type="primary" htmlType="submit">
            {t('Submit')}
          </Button>
        </BtnsContainer>
      </Form.Item>
    </>
  );


  return (
    <><Meta
      title={t("meta_signup_title")}
      description={t("meta_signup_desc")} />
      <ContentWrapperCardStyle>
        <Row justify="center">
          <Col xs={0} lg={12}>
            <ImgWrapper
              url={type === 'person' ? 'img/banners/register_3.webp' : 'img/banners/register_2.webp'}
              text={type === 'person' ? t('reg_per_bannertext') : t('reg_comp_bannertext')} />
          </Col>
          <Col xs={24} lg={12}>
            <FormWrapper>
              <Form
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
                onFieldsChange={onChange}
                initialValues={{
                  type: 'person'
                }}
                {...layout}
                onFinishFailed={(errorInfo) => errorInfo.errorFields.map((item) => showNotification('error', t(String(item.name)), item.errors[0]))}
              >
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Col>
                    <ScTitle level={1}>{t('reg_per_become_member')}</ScTitle>
                  </Col>
                  <Form.Item noStyle shouldUpdate={(prevValues: any, currentValues: any) => prevValues.type !== currentValues.type}>
                    {({ getFieldValue }) => getFieldValue('type') === 'company' ? (
                      <>
                        <Steps current={current} onChange={handleGoToStep}>
                          {steps.map((item) => (
                            <Step key={item.title} />
                          ))}
                        </Steps>
                        {steps.map((item) => (
                          <StepShow hidden={item.step !== current} key={item.title} className="steps-content ">
                            {item.content}
                          </StepShow>
                        ))}
                      </>
                    ) : (
                      getPersonFields()
                    )}
                  </Form.Item>
                </Space>
              </Form>
            </FormWrapper>
          </Col>
        </Row>
      </ContentWrapperCardStyle></>
  );
};

export default memo(SignUpPage);
