/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, Route, Switch } from 'react-router';
import { Row, Button, Radio, Space, Col, TablePaginationConfig, Modal, InputNumber, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined, EyeFilled, HeartFilled, StarFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getBidsByStatusForUser, buyerStayOnPreviousBid, buyerNewBid, buyerApprove, myOffersListSelector, myOffersListTotalCountSelector } from '../../store/slices/bidsSlice';
import { BidByStatusInformation, BidsType } from '../../interfaces/Product';
import LocationForTable from '../../shared/components/LocationForTable';
import { getLinkForLotPage, getOdoValue, getPrice, langPrefix } from '../../utils/functions';
import MyOfferContainer from './containers/MyOfferContainer';
import BidsContainer from './containers/BidsContainer';
import NoPreviewImage from '../../shared/components/NoPreviewImage';
import { addProduct, changeOpenQuickView, deleteProduct, getQuickViewLotData, userWatchListSelector } from '../../store/slices/productSlice';
import { toggleModalAuth, userSelector } from '../../store/slices/authSlice';
import { CenterAlign } from '../FindVehiclesPage/style';
import BadgeWithTooltip from '../../shared/components/BadgeWithTooltip';
import { ScCountdown } from '../LotPage/SaleInformationCard';
import DateForTable from '../../shared/components/DateForTable';
import Price from '../../shared/components/Price';
import { currencySelector, odometrIsKmSelector } from '../../store/slices/initSlice';
import TablesMenuDrawer from '../../shared/components/TablesMenuDrawer';
import ColorSquare from '../../shared/components/AdvanceSearch/ColorSquare';
import QuickOverview from '../../shared/components/AdvanceSearch/QuickOverviewButton';
import WatchlistButton from '../../shared/components/AdvanceSearch/WatchlistButton';
import ImageColumn from '../../shared/components/AdvanceSearch/ImageColumn';
import Odometer from '../../shared/components/AdvanceSearch/Odometer';
import { ScButton } from '../../shared/components/AdvanceSearch/style';
import { Vehicle } from '../../interfaces/FindVehicles';
import VehicleNameColumn from "../../shared/components/AdvanceSearch/VehicleNameColumn"
import VehicleInfoColumn from "../../shared/components/AdvanceSearch/VehicleInfoColumn"
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import LotMobileList from '../../shared/components/LotMobileList';

const { confirm } = Modal;
const { Text } = Typography;

const BidsPage: FC = () => {
  const { t } = useTranslation();
  const { md } = useBreakpoint()
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [tableSettings, setTableSettings] = useState<{
    Page?: number;
    PageItemsCount?: number;
    SortField?: string;
    SortOrder: string;
  }>({
    Page: 1,
    PageItemsCount: 10,
    SortField: '',
    SortOrder: 'desc',
  });

  const getType = (string: string) => {
    if (string === 'notwinning') return 'NotWinning';
    if (string === 'myoffers') return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const myOffersList = useSelector(myOffersListSelector);
  const myOffersListTotalCount = useSelector(myOffersListTotalCountSelector);

  const user = useSelector(userSelector);
  const userWatchList = useSelector(userWatchListSelector);
  const odometrIsKm = useSelector(odometrIsKmSelector);

  const [bidsType, setbidsType] = useState<BidsType>('All');
  const [newPrice, setNewPrice] = useState<number | undefined>();
  const [openNewPriceModal, setOpenNewPriceModal] = useState<string | false>(false);

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const path = pathSegments[pathSegments.includes('en') || pathSegments.includes('ru') ? 4 : 3];
    const bidsType = getType(path) as BidsType;
  
    if (path) {
      setbidsType(bidsType);
    } else {
      history.push(langPrefix('/buyer/bids/myoffers'));
    }
  }, [history, location]);

  useEffect(() => {
    dispatch(getBidsByStatusForUser(tableSettings.SortOrder, bidsType, tableSettings.Page, tableSettings.PageItemsCount, tableSettings.SortField));
  }, [dispatch, bidsType, tableSettings]);

  const handleBidNow = useCallback(
    (lotNumber, make, model, year) => () => {
      history.push(getLinkForLotPage(lotNumber, make, model, year));
    },
    [history],
  );

  const currency = useSelector(currencySelector);

  const handleOpenStayOnMine = useCallback(
    (currentBid, lotNumber) => () => {
      confirm({
        title: <>Are you sure to stay on mine? -{getPrice(currency, currentBid)}</>,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(
            buyerStayOnPreviousBid(lotNumber, tableSettings.SortOrder, bidsType, tableSettings.Page, tableSettings.PageItemsCount, tableSettings.SortField),
          );
        },
        okText: 'Stay on Mine',
        cancelText: t('Close'),
      });
    },
    [tableSettings, bidsType, dispatch, currency],
  );

  const handleOpenNewPrice = useCallback(
    (lotNumber, currentBid) => () => {
      setOpenNewPriceModal(lotNumber);
      setNewPrice(currentBid);
    },
    [],
  );

  const handleCloseNewPriceModal = () => {
    setOpenNewPriceModal(false);
  };

  const handleSetNewPrice = (value: number | string) => {
    setNewPrice(+value);
  };

  const handleSubmitNewPriceModal = useCallback(() => {
    confirm({
      title: <>{t('offer_newprice')} -{getPrice(currency, newPrice || 0)}</>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        newPrice &&
          openNewPriceModal &&
          dispatch(
            buyerNewBid(
              +newPrice,
              openNewPriceModal,
              tableSettings.SortOrder,
              bidsType,
              tableSettings.Page,
              tableSettings.PageItemsCount,
              tableSettings.SortField,
            ),
          );
        setOpenNewPriceModal(false);
      },
      okText: t('Accept'),
      cancelText: t('Close'),
    });
  }, [dispatch, newPrice, tableSettings, bidsType, openNewPriceModal, currency]);

  const handleOpenAccept = useCallback(
    (sellerOffer, lotNumber) => () => {
      confirm({
        title: <>{t('offer_accept')} -{getPrice(currency, sellerOffer || 0)}</>,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(buyerApprove(lotNumber, tableSettings.SortOrder, bidsType, tableSettings.Page, tableSettings.PageItemsCount, tableSettings.SortField));
        },
        okText: t('Accept'),
        cancelText: t('Close'),
      });
    },
    [tableSettings, bidsType, dispatch, currency],
  );

  const handleOpenQuickView = useCallback(
    (id) => {
      dispatch(changeOpenQuickView(id));
      dispatch(getQuickViewLotData(id));
    },
    [dispatch],
  );

  const getTimeLeftInfo = (saleDateUtc: string) => {
    if (!saleDateUtc) return 'Futured';
    if (new Date(saleDateUtc).getTime() - Date.now() <= 0) return 'Live';
    return (
      <>
        <DateForTable date={saleDateUtc} />
        <ScCountdown title="" value={saleDateUtc} format="DD[D] HH[H] mm[M] ss[S]" onFinish={() => {}} />
      </>
    );
  };

  const columns = [
    {
      title: '',
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      render: (lotNumber: string) => (
        <Space direction="vertical" size="small">
          <Tooltip title="Add to favorite">
            <WatchlistButton id={+lotNumber} />
          </Tooltip>
          
        </Space>
      ),
    },
    {
      title: t("image"),
      dataIndex: 'mainImage',
      key: 'mainImage',
      render: (mainImage: string, lot: BidByStatusInformation) => (
        <ImageColumn to={`../lot/${lot.lotNumber}`} mainImage={mainImage}/>
      ),
    },
    {
      title: t("table_col_vehicle_name"),
      dataIndex: 'VehicleName',
      key: 'VehicleName',
      render: (year: string,vehicle: Vehicle) => (
        <VehicleNameColumn vehicle={vehicle} />
      ),
    },
    {
      title: t("table_col_vehicle_info"),
      dataIndex: 'VehicleInfo',
      key: 'VehicleInfo',
      render: (color: string, vehicle: Vehicle ) => (
        <VehicleInfoColumn vehicle={vehicle} />
      ),
    },
    {
      title: t("table_col_vin"),
      dataIndex: 'vin',
      key: 'vin',
    },
    {
      title: 'Keys',
      dataIndex: 'keys',
      key: 'keys',
      render: (keys: string) => (keys ? 'Yes' : 'No'),
    },
    {
      title: t("table_col_location"),
      dataIndex: 'locationId',
      key: 'locationId',
      render: (locationId: number, vehicle: BidByStatusInformation) => <LocationForTable locationId={locationId} vehicle={vehicle} />,
    },
    // {
    //   title: 'Sale Date',
    //   dataIndex: 'saleDate',
    //   key: 'saleDate',
    //   render: (saleDate: string) => getTimeLeftInfo(saleDate),
    // },
    // {
    //   title: 'Damage',
    //   dataIndex: 'damage',
    //   key: 'damage',
    // },
    {
      title: t("buyer_bids_seller_offer"),
      dataIndex: 'sellerOffer',
      key: 'sellerOffer',
      render: (sellerOffer: number, vehicle: BidByStatusInformation) => <Price price={bidsType === 'myoffers' ? vehicle.amount : sellerOffer || 0} />,
    },
    // {
    //   title: 'Current Bid',
    //   dataIndex: 'currentBid',
    //   key: 'currentBid',
    //   render: (currentBid: number) => <Price price={currentBid} />,
    // },
    // {
    //   title: 'Max Bid',
    //   dataIndex: 'maxBid',
    //   key: 'maxBid',
    //   render: (maxBid: number) => <Price price={maxBid} />,
    // },
    {
      title: t("table_col_buyer_actions"),
      dataIndex: 'bidStatus',
      key: 'bidStatus',
      render: (bidStatus: string, item: BidByStatusInformation) => (
        <Space direction="vertical">
          {bidStatus === 'Winning' || bidStatus === 'Outbid' || bidsType === 'myoffers' ? (
            <ScButton size="large" type="primary" onClick={handleBidNow(item.lotNumber, item.make, item.model, item.year)} icon={<StarFilled />}>
              Bid Now
            </ScButton>
          ) : (
            <>
              <Button size="large" type="primary" disabled={bidStatus === 'OnSellerApprove'} onClick={handleOpenStayOnMine(item.currentBid, item.lotNumber)}>
                Stay on Mine
              </Button>
              <Button size="large" type="primary" disabled={bidStatus === 'OnSellerApprove'} onClick={handleOpenNewPrice(item.lotNumber, item.currentBid)}>
                New Price
              </Button>
              <Button size="large" type="primary" disabled={bidStatus === 'OnSellerApprove'} onClick={handleOpenAccept(item.sellerOffer, item.lotNumber)}>
                Accept
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  // {
  //   title: 'Action',
  //   dataIndex: 'bidStatus',
  //   key: 'bidStatus',
  //   render: (bidStatus: string, item: BidByStatusInformation) => (
  //     <Space direction="vertical">
  //       {
  //         bidStatus === 'Winning' ? <Button size="large" type="primary" onClick={handleBidNow(item.lotNumber, item.make, item.model, item.year)} icon={<StarFilled />}>Bid Now</Button> : (
  //           <>
  //             <Button size="large" type="primary" disabled={bidStatus === 'OnSellerApprove'} onClick={handleOpenStayOnMine(item.currentBid, item.lotNumber)}>Stay on Mine</Button>
  //             <Button size="large" type="primary" disabled={bidStatus === 'OnSellerApprove'} onClick={handleOpenNewPrice(item.lotNumber, item.currentBid)}>New Price</Button>
  //             <Button size="large" type="primary" disabled={bidStatus === 'OnSellerApprove'} onClick={handleOpenAccept(item.sellerOffer, item.lotNumber)}>Accept</Button>
  //           </>)
  //       }
  //     </Space>
  //   ),
  // },

  const handleTableChange = useCallback(
    (pg: TablePaginationConfig, fltr: any, sort: any) => {
      setTableSettings({
        Page: pg.current,
        PageItemsCount: pg.pageSize,
        SortField: sort.field,
        SortOrder: sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc',
      });
      dispatch(
        getBidsByStatusForUser(
          sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc',
          bidsType,
          pg.current,
          pg.pageSize,
          sort.field,
        ),
      );
    },
    [dispatch, bidsType],
  );

  const handleRedirect = useCallback(
    ({ target }) => {
      setbidsType(target.value);
      history.push(langPrefix(`/buyer/bids/${target.value}`.toLowerCase()));
    },
    [history],
  );

  const handleChangePagination = (page: number, pageSize?: number | undefined) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    
    setTableSettings({
      ...tableSettings,
      Page: page || 1,
      PageItemsCount: pageSize || 10,
    })
  };

  return (
    <Row>
      <TablesMenuDrawer>
        <Radio.Group onChange={handleRedirect} value={bidsType}>
          <Space direction="vertical">
            {/* <Radio value="All">{t('nav_user_menu_buyer_bids_status_all_bids')}</Radio>
            <Radio value="Current">{t('nav_user_menu_buyer_bids_status_current_bids')}</Radio>
            <Radio value="Winning">{t('nav_user_menu_buyer_bids_status_winning')}</Radio>
            <Radio value="NotWinning">{t('nav_user_menu_buyer_bids_status_not_winning')}</Radio>
            <Radio value="Open">{t('nav_user_menu_buyer_bids_status_open_items')}</Radio> */}
            <Radio value="myoffers">{t('nav_user_menu_buyer_bids_status_my_offers')}</Radio>
          </Space>
        </Radio.Group>
      </TablesMenuDrawer>
      <Col xs={24} lg={20}>
        <Switch>
          <Route exact path={langPrefix("/buyer/bids/myoffers")}>
              {md 
                ? 
                  <MyOfferContainer tableChange={handleTableChange} columns={columns} />
                :
                <LotMobileList 
                  statusKey={"actionType"} 
                  tableSettings={tableSettings} 
                  handleChangePagination={handleChangePagination} 
                  lotsList={myOffersList} 
                  lotsListTotalCount={myOffersListTotalCount}
                  footer={(lot: any) => (
                    <Space direction="horizontal" style={{justifyContent: "space-between", width: "100%"}} >
                      <Price price={bidsType === 'myoffers' ? lot.amount : lot.sellerOffer || 0} />

                      {lot.bidStatus === 'Winning' || lot.bidStatus === 'Outbid' || bidsType === 'myoffers' ? (
                        <ScButton size="large" type="primary" onClick={handleBidNow(lot.lotNumber, lot.make, lot.model, lot.year)} icon={<StarFilled />}>
                          Bid Now
                        </ScButton>
                      ) : (
                        <>
                          <Button size="large" type="primary" disabled={lot.bidStatus === 'OnSellerApprove'} onClick={handleOpenStayOnMine(lot.currentBid, lot.lotNumber)}>
                            Stay on Mine
                          </Button>
                          <Button size="large" type="primary" disabled={lot.bidStatus === 'OnSellerApprove'} onClick={handleOpenNewPrice(lot.lotNumber, lot.currentBid)}>
                            New Price
                          </Button>
                          <Button size="large" type="primary" disabled={lot.bidStatus === 'OnSellerApprove'} onClick={handleOpenAccept(lot.sellerOffer, lot.lotNumber)}>
                            Accept
                          </Button>
                        </>
                      )}
                    </Space>
                  )}
                />
              }
          </Route>
          <Route path={langPrefix("/buyer/bids/")}>
            <BidsContainer tableChange={handleTableChange} columns={columns} />
          </Route>
        </Switch>
      </Col>
      {!!openNewPriceModal && (
        <Modal title="New Price" visible={!!openNewPriceModal} centered onCancel={handleCloseNewPriceModal} onOk={handleSubmitNewPriceModal}>
          <InputNumber
            defaultValue={newPrice}
            formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={handleSetNewPrice}
          />
        </Modal>
      )}
    </Row>
  );
};

export default memo(BidsPage);
