import React, { FC, memo, useCallback, useEffect, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import { Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarDashboardLane, LaneActivationPeriod } from '../../../interfaces/Auction';
import TimerButton from './TimerButton';
import { getTimeZone } from '../../../utils/functions';
import CustomTable from '../CustomTable';
import {
  getAuctionDashboardLotsList,
  auctionDashboardLanesListLoadingSelector,
  auctionDashboardLanesListCurrentSelector,
  auctionPanelsSelector,
  openLanesSelector,
  auctionDashboardLanesListSelector,
  setAuctionDashboardLanesListCurrent,
  auctionDetailsForCalendarSelector,
  setAuctionDetailsForCalendarSuccess,
  getAuctionDetailsForCalendar,
} from '../../../store/slices/auctionSlice';
import AuctionDetailsModal from '../AuctionDetailsModal';

interface Props {
  panelId?: number;
  main?: boolean;
}

const AuctionsTodayTable: FC<Props> = ({ panelId, main = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);

  const auctionPanels = useSelector(auctionPanelsSelector);
  const auctionDashboardLanesList = useSelector(auctionDashboardLanesListSelector);
  const auctionDashboardLanesListCurrent = useSelector(auctionDashboardLanesListCurrentSelector);
  const auctionDashboardLanesListLoading = useSelector(auctionDashboardLanesListLoadingSelector);
  const openLanes = useSelector(openLanesSelector);
  const auctionDetailsForCalendar = useSelector(auctionDetailsForCalendarSelector);

  const [isModalVisible, setIsModalVisible] = useState<number | false>(false);

  const handleOpenAuction = useCallback(
    (laneId: number) => () => {
      dispatch(getAuctionDashboardLotsList(laneId, panelId || +Object.keys(auctionPanels)[0]));
      const lanes =
        params
          .get('laneids')
          ?.split(',')
          .map((item) => +item) || [];
      lanes.indexOf(laneId) === -1 && lanes.push(laneId);
      params.set('laneids', lanes.join(','));
      history.push({
        pathname: './auctiondashboard',
        search: params.toString(),
      });
    },
    [auctionPanels, dispatch, history, panelId, params],
  );

  const handleViewSaleList = useCallback(
    (number: number) => {
      history.push({
        pathname: '/auctionsalelist',
        search: `?laneids=${number}`,
      });
    },
    [history],
  );

  const handleOpenModalAuction = (laneId: number) => {
    setIsModalVisible(laneId);
    dispatch(getAuctionDetailsForCalendar(laneId));
  };

  const columns = [
    {
      title: t('auction_lane'),
      dataIndex: 'laneNumber',
      key: 'laneNumber',
      width: 50,
    },
    {
      title: t('auction_auction'),
      dataIndex: 'auctionName',
      key: 'auctionName',
      width: 150,
    },
    {
      title: t('auction_location'),
      dataIndex: 'country',
      key: 'country',
      width: 150,
      render: (country: string, lane: CalendarDashboardLane) => (
        <Button onClick={() => handleOpenModalAuction(lane.auctionId)}>{`${country}, ${lane.city}`}</Button>
      ),
    },
    {
      title: t('auction_lots'),
      dataIndex: 'laneId',
      key: 'laneId',
      width: 100,
      render: (laneId: number, lane: CalendarDashboardLane) => (
        <Row>
          <Button onClick={() => handleViewSaleList(laneId)}>View {lane.lotsCount} lots</Button>
        </Row>
      ),
    },
    {
      title: t('auction_time'),
      dataIndex: 'laneActivationPeriods',
      key: 'laneActivationPeriods',
      width: 100,
      render: (laneActivationPeriods: LaneActivationPeriod[]) =>
        laneActivationPeriods?.map((time) => `${dayjs(time.startTime).format('HH:mm')} (${getTimeZone()})`),
    },
    {
      title: t('auction_starts_in'),
      dataIndex: 'laneActivationPeriods',
      key: 'laneActivationPeriods',
      width: 100,
      render: (laneActivationPeriods: LaneActivationPeriod[], lane: CalendarDashboardLane) =>
        laneActivationPeriods?.map((time) => <TimerButton key={`start${lane.laneId}`} time={time} onClick={handleOpenAuction(lane.laneId)} />),
    },
  ];

  useEffect(() => {
    let currentLines: CalendarDashboardLane[] = [];
    if (openLanes.length) {
      currentLines = auctionDashboardLanesList.filter((item) => !openLanes.includes(item.laneId));
    } else {
      currentLines = [...auctionDashboardLanesList];
    }
    dispatch(setAuctionDashboardLanesListCurrent(currentLines));
  }, [auctionDashboardLanesList, dispatch, openLanes]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    dispatch(setAuctionDetailsForCalendarSuccess(null));
  }, [dispatch]);

  return (
    <>
      <CustomTable
        data={auctionDashboardLanesListCurrent.map((item) => ({
          ...item,
          key: item.laneId,
        }))}
        columns={columns}
        pagination={auctionDashboardLanesListCurrent.length > 10}
        totalCount={auctionDashboardLanesListCurrent.length}
        isLoading={auctionDashboardLanesListLoading}
        scroll={main ? { y: 360, x: 'max-content' } : { x: 'max-content' }}
        locale={{
          emptyText: main && !auctionDashboardLanesListCurrent.length && <img width="50%" src="img/auctions_today_empty.gif" alt="No data" />,
        }}
        noScrollToTop
      />
      {auctionDetailsForCalendar && (
        <AuctionDetailsModal
          auctionDetailsForCalendar={auctionDetailsForCalendar}
          isOpen={!!isModalVisible}
          onCancel={handleCancel}
          onViewSaleList={() => !!isModalVisible && handleViewSaleList(isModalVisible)}
        />
      )}
    </>
  );
};

export default memo(AuctionsTodayTable);
