import { Card, Col, Divider, Space, Button, Menu } from 'antd';
import styled from 'styled-components';
import { LightGreyBackground } from '../../shared/shared-styles';
import { LotCardBody, LotCardHeader, LotCardWrapper } from '../../shared/components/LotDescriptionCard';

export const ImgContainerCol = styled(Col)`
  text-align: center;
`;

export const SpaceForShare = styled(Space)`
  align-items: flex-start;
`;

export const StyledHeaderWithBadge = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  h3 {
    margin: 0;
  }
  h4{
    flex-shrink: 0;
    margin: 0;
    margin-right: 1.25rem;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    margin-bottom: 0.875rem;
    h4{
      font-size: 18px;
      margin-right: 1rem;
    }
  }
`;

export const LotActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: 1.25rem;
  .ant-btn{
    border: 1px solid #CACACA;
    border-radius: 8px;
    font-family: 'main';
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #1B153D;
    &:not(:last-child){
      margin-right: 0.75rem;
    }
  }
    
  @media (max-width: 767px) {
    margin-left: 0;
    margin-top: 1.5rem;
    width: 100%;
    justify-content: flex-start;
    overflow-x: auto;
  }
`;

interface ScMenuProps {
  onClick: any
}

export const ScMenu = styled(Menu)<ScMenuProps>`
  text-align: center;
  display: flex;
  border: 1px solid #CACACA;
  border-radius: 8px;
  img {
    width: 24px;
    height: 24px;
  }
`;

interface ScCardProps {
  isLotCardOpen: boolean
}

export const ScCard = styled(Card)<ScCardProps>`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 0px 40px #FBFBFB;
  border-radius: 8px;

  .ant-card-head {
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 14px;
    background: #F6F6F6;
    border: 1px solid #EAEAEA;
    border-radius: 8px 8px 0px 0px;
  }
  .ant-card-body {
    padding: 0.25rem 1.25rem;
  }
  .ant-card-body{
    .ant-row {
      gap: 0.75rem;
      padding: 1.25rem 0;
      &:not(:last-child){
        border-bottom: 1px solid #EAEAEA;
      }
    }
  }
  .ant-btn-primary{
    font-family: 'main';
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #FFFFFF;
    padding: 0.75rem 3rem;
  }
  .ant-col{
    font-family: 'main';
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #9095A6;
    &:last-child{
      font-weight: 500;
      color: #1B153D;
    }
  }
  .product-price{
    font-family: 'main';
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #CE2424;
    margin-bottom: 0.75rem;
    text-align: right;
  }
  .checkbox-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
    gap: 0.5rem;
  }
  .ant-checkbox-wrapper{
    margin-left: 0;
  }

  @media (max-width: 767px) {
    .ant-card-body{
      display: ${p => p.isLotCardOpen ? "block": "none"};
    }
    .ant-card-head{
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`;

export const ServiceOrderButton = styled(Button)`
  display: flex;
  margin-left: auto;
`

export const ScDivider = styled(Divider)`
  margin: 10px 0;
`;

export const GalleryLoading = styled.div`
  position: absolute;
  width: 100%;
  z-index: 5;
  img {
    max-height: 100%;
  }
  .ant-spin {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Price = styled.div`
  span {
    font-weight: 700;
    font-size: 18px;
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CopyToClipboard = styled(Button)`
  height: 64px;
  width: 64px;
  border-radius: 0;
  svg {
    font-size: 32px;
  }
`;

export const SelectsWrapper = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 1rem;
  .ant-select{
    width: 100%;
  }
`

export const CommentWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
`

export const Container = styled.div`
  max-width: 90rem;
  margin: 0 auto;
  width: 100%;
  padding: 0 2.5rem;
  @media (max-width: 1200px) {
    padding: 0 1rem;
  }
  
  @media (max-width: 767px){
    .ant-tabs{
      background: #FFFFFF;
      border: 1px solid #EAEAEA;
      box-shadow: 0px 0px 40px #FBFBFB;
      border-bottom: none;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      padding: 0.75rem;
      padding-bottom: 0;
    }
  }
`

export const CardsWrapper = styled.div`
  max-width: 90rem;
  margin: 0 auto;
  width: 100%;
  padding: 2.5rem;
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "slider slider sale" 
    "lot lot service"
    "feature feature calculator"
  ;
  grid-template-columns: 1fr 1fr 1fr;
  & ${LotCardWrapper}:nth-child(1){
    grid-area: lot;
  }
  & ${LotCardWrapper}:nth-child(2){
    grid-area: sale;
  }
  & ${LotCardWrapper}:nth-child(3){
    grid-area: feature;
  }
  & ${LotCardWrapper}:nth-child(4){
    grid-area: service;
  }
  & ${LotCardWrapper}:nth-child(5){
    grid-area: calculator;
  }
  & ${LotCardWrapper}:nth-child(6){
    grid-area: leasing;
  }

  & ${Container}{
    padding: revert;
    grid-area: slider;
  }

  .ant-row{
    padding: 1.25rem 0;
    .ant-col{
      font-family: 'main';
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: #1B153D;
      &:nth-child(1){
        color: #9095A6;
      }
    }
  }

  .product-price{
    font-family: 'main';
    font-weight: 600;
    font-size: 1rem;
    line-height: 0.875rem;
    color: #CE2424;
  }

  .checkbox-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-top: 1.25rem;
    input{
      margin-right: 0.5rem;
    }
  }
  .ant-checkbox-wrapper{
    margin-left: 0;
  }

  #leasing{
    padding: 1.25rem 0;
    .ant-row{
      padding: 0;
    }
    .ant-form-item{
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 1rem 2.5rem;
    grid-template-columns: 1fr;
    grid-template-areas:
      "slider"
      "sale"
      "lot"
      "feature"
      "service"
      "calculator"
  }

  @media (max-width: 767px){
    & ${LotCardWrapper}:nth-child(1){
      background: #FFFFFF;
      border: 1px solid #EAEAEA;
      box-shadow: 0px 0px 40px #FBFBFB;
      border-top: none;
      border-radius: 0px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 0.75rem;
      padding-top: 0;
      ${LotCardHeader}{
        display: none;
      }
      ${LotCardBody}{
        display: block;
        padding: 0;
      }
    }
  }
`

export const CalculatorSelectWrapper = styled.div`
  padding: 1.25rem 0;
`

export const  LotCardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    font-family: 'main';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #1B153D;
  }
  button{
    display: none;
    div, svg{
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    path{
      stroke: #1B153D;
    }
  }
  @media (max-width: 767px) {
    span{
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    button{
      display: block;
    }
  }
` 

interface CustomActionButtonProps {
  isActive?: boolean
}

export const CustomActionButton = styled(Button)<CustomActionButtonProps>`
  path{
    fill: ${p => p.isActive ? "#CE2424" : "#1B153D"}
  }

  &.ant-btn {
    border: ${p => p.isActive ? '1px solid #ff0303b3' : '1px solid #CACACA'};
  }

  & span {
    color: ${p => p.isActive ? '#CE2424' : '#1B153D'};
  }

`