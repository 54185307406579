import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <LeftOutlined style={{ color: 'black', fontSize: '24px' }} />
    </div>
  );
};

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <RightOutlined style={{ color: 'black', fontSize: '24px' }} />
    </div>
  );
};

export { CustomPrevArrow, CustomNextArrow };
