import React, { FC, memo, useEffect } from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

const NotFoundPage: FC = () => {
  const { t } = useTranslation();

  const canonicalTag = document.querySelector('link[rel="canonical"]');
  if (canonicalTag) {
    canonicalTag.parentNode?.removeChild(canonicalTag);
  }

  const metaTag = document.querySelector('meta[name="robots"]');
  if (metaTag) {
    metaTag.setAttribute('content', 'follow, noindex, max-snippet:-1, max-video-preview:-1, max-image-preview:large');
  }

  return <Result icon={<img src="img/not_found.png" alt="Not found" />} title={t("page_notfound")} />;
};

export default memo(NotFoundPage);
