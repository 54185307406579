import { Button, Collapse, DatePicker, Form, Input, InputNumber, Radio, Row, Space } from 'antd';
import dayjs from 'dayjs';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SellerOneLotPageData, UpdateAssignmentMaintenanceFormData } from '../../../interfaces/Product';
import { updateAssignmentMaintenance } from '../../../store/slices/productSlice';

const { Panel } = Collapse;

interface Props {
  lotData: SellerOneLotPageData;
}

const AssignmentMaintenance: FC<Props> = ({ lotData }) => {
  const dispatch = useDispatch();
  const {
    claimNumber,
    insured,
    odometer,
    legalSaleDate,
    productSellModel,
    reserveAmount,
    sellerLossCode,
    sellerReference,
    owner,
    ownerMobilePhoneNumber,
    policyNumber,
  } = lotData.assignmentMaintenance;

  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values: UpdateAssignmentMaintenanceFormData) => {
      dispatch(
        updateAssignmentMaintenance({
          ...lotData.assignmentMaintenance,
          ...values,
        }),
      );
    },
    [dispatch, lotData],
  );

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  useEffect(() => {
    form.setFieldsValue({
      claimNumber: claimNumber || '',
      insured: insured || '',
      legalSaleDate: dayjs(legalSaleDate) || undefined,
      odometer: odometer || 0,
      owner: owner || '',
      ownerMobilePhoneNumber: ownerMobilePhoneNumber || '',
      policyNumber: policyNumber || '',
      productSellModel: productSellModel || 2,
      reserveAmount: reserveAmount || '',
      sellerLossCode: sellerLossCode || '',
      sellerReference: sellerReference || '',
    });
  }, [
    form,
    claimNumber,
    odometer,
    legalSaleDate,
    productSellModel,
    reserveAmount,
    sellerLossCode,
    sellerReference,
    insured,
    owner,
    ownerMobilePhoneNumber,
    policyNumber,
  ]);

  return (
    <Form form={form} {...formItemLayout} name="assignmentMaintenance" onFinish={onFinish} scrollToFirstError>
      <Collapse onChange={() => {}}>
        <Panel header="Seller information" key="1">
          {/* <Form.Item name="adjuster" label="Adjuster">
            <Select placeholder="Select Seller" disabled />
          </Form.Item>
          <Form.Item name="claimNumber" label="Claim #" rules={[{ required: true, message: 'Please enter a valid Claim #' }]}>
            <Input maxLength={20} placeholder="Type Text" />
          </Form.Item>
          <Form.Item name="sellerReference" label="Seller Reference #">
            <Input maxLength={20} placeholder="Type" />
          </Form.Item>
          <Form.Item name="policyNumber" label="Policy #">
            <Input maxLength={20} placeholder="Type" />
          </Form.Item>
          <Form.Item name="sellerLossCode" label="Seller Loss Code">
            <Input maxLength={20} placeholder="Type" />
          </Form.Item> */}
          <Form.Item name="insured" label="Auction" rules={[{ required: true, message: 'Please enter a valid Auction' }]}>
            <Input maxLength={20} placeholder="Type Text" />
          </Form.Item>
          <Form.Item name="owner" label="Owner" rules={[{ required: true, message: 'Please enter a valid Owner' }]}>
            <Input maxLength={20} placeholder="Type Text" />
          </Form.Item>
          <Form.Item name="ownerMobilePhoneNumber" label="Owner Mobile Phone Number">
            <Input maxLength={11} placeholder="Type" />
          </Form.Item>
        </Panel>
        <Panel header="Critical Dates" key="4">
          <Form.Item name="legalSaleDate" label="Legal Sale Date">
            <DatePicker disabled format="MM/DD/YYYY" />
          </Form.Item>
        </Panel>
        <Panel header="Title information" key="6">
          <Form.Item name="odometer" label="Odometer" rules={[{ required: true, message: 'Please enter a valid Odometer' }]}>
            <InputNumber maxLength={6} placeholder="Type up to 6 numbers" />
          </Form.Item>
        </Panel>
        <Panel header="Auction information" key="5">
          <Form.Item name="productSellModel" label="Minimum Price">
            <Radio.Group>
              <Radio value={1}>Yes</Radio>
              <Radio value={2}>BTBA</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.productSellModel !== currentValues.productSellModel}>
            {({ getFieldValue }) =>
              getFieldValue('productSellModel') === 1 ? (
                <Form.Item name="reserveAmount" label="Minimum Price">
                  <InputNumber placeholder="$0.0" />
                </Form.Item>
              ) : null
            }
          </Form.Item>
        </Panel>
      </Collapse>
      <Row justify="end">
        <Space>
          <Form.Item>
            <Button type="default">Cancel</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Row>
    </Form>
  );
};

export default memo(AssignmentMaintenance);
