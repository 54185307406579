import React, { FC, memo } from 'react';
import CompareResultContainer from './containers/CompareResultContainer';
import CompareBannerContainer from './containers/CompareBannerContainer';

const CompareVehiclesPage: FC = () => (
  <>
    <CompareBannerContainer />
    <CompareResultContainer />
  </>
);

export default memo(CompareVehiclesPage);
