import React, { FC, memo, useCallback, useState, useEffect } from 'react';
import { Col, InputNumber, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import {
  currentLaneMaxBidTimeSelector,
  currentLaneAdditionalBidTimeSelector,
  currentLotActualSelector,
  updateTimeCurrentLot,
} from '../../../store/slices/auctionSlice';
import { Flag, ButtonFWidth } from './style';
import { getBidStep } from '../../../utils/functions';
import { SuccessCl, ErrorCl } from '../../shared-styles';

const { Text } = Typography;

interface Props {
  startingBid: number;
  laneId: number;
  makeBid: (value: number, lotId: number, method: string) => void;
}

const TimerBidSection: FC<Props> = ({ makeBid, laneId, startingBid }) => {
  const dispatch = useDispatch();

  const currentLotActual = useSelector(currentLotActualSelector(laneId));
  const currentPanelMaxBidTime = useSelector(currentLaneMaxBidTimeSelector(laneId));
  const currentPanelAdditionalBidTime = useSelector(currentLaneAdditionalBidTimeSelector(laneId));

  const [key, setKey] = useState(0);
  const [maxTime, setmaxTime] = useState(0);

  const [bid, setBid] = useState<number>(+(currentLotActual?.userMaxBidValue || 0));

  useEffect(() => {
    currentLotActual?.timeType === 'MainTime' ? setmaxTime(currentPanelMaxBidTime) : setmaxTime(currentPanelAdditionalBidTime);
  }, [currentLotActual, currentPanelMaxBidTime, currentPanelAdditionalBidTime]);

  useEffect(() => {
    setBid(currentLotActual?.userMaxBidValue || 0);
  }, [currentLotActual?.userMaxBidValue]);

  const [status, setStatus] = useState<string>('');
  const [bidValue, setBidValue] = useState<number>(0);
  const [timeType, setTimeType] = useState<string>('');

  useEffect(() => {
    setStatus(currentLotActual?.status);
    setTimeType(currentLotActual?.timeType);
    setBidValue(currentLotActual?.bidValue);
  }, [currentLotActual]);

  useEffect(() => {
    setKey(Math.random());
  }, [status, bidValue, timeType, currentLotActual?.time]);

  const getIncrease = useCallback(() => {
    if (currentLotActual) {
      const { userMaxBidValue, isWinning } = currentLotActual;
      let result = 10;
      let value = bidValue;
      if (isWinning) {
        value = userMaxBidValue;
      }
      result = getBidStep(value);
      return result;
    }
    return undefined;
  }, [bidValue, currentLotActual]);

  const handleMakeBid = useCallback(() => {
    currentLotActual?.status === 'CurrentLot' && makeBid(bid, currentLotActual.lotId, 'MakeCurrentLotBid');
    currentLotActual?.status !== 'CurrentLot' && makeBid(bid, currentLotActual.lotId, 'MakeBid');
    dispatch(updateTimeCurrentLot(currentLotActual.lotId, laneId));
  }, [dispatch, bid, makeBid, currentLotActual, laneId]);

  const bidderCountry = useCallback(() => {
    if (!currentLotActual?.country) return '';
    return currentLotActual?.country.replace(' ', '-').toLowerCase();
  }, [currentLotActual]);

  // if (!currentLotActual) return null;

  return (
    <>
      <Col span={12}>
        <CountdownCircleTimer
          key={key}
          isPlaying={currentLotActual?.status === 'CurrentLot' && currentLotActual.playTimer}
          duration={currentLotActual?.status !== 'CurrentLot' ? 100 : maxTime}
          initialRemainingTime={currentLotActual?.status !== 'CurrentLot' ? 100 : currentLotActual?.time}
          size={100}
          strokeWidth={10}
          rotation="counterclockwise"
          colors={[[currentLotActual?.isWinning ? SuccessCl : ErrorCl, 0.33]]}
        >
          <div>
            <div>
              ${currentLotActual?.bidValue || 0}
              {bidderCountry() && <Flag Country={bidderCountry()} />}
            </div>
            {startingBid !== +currentLotActual?.userMaxBidValue && (
              <div style={{ fontSize: '10px', textAlign: 'center' }}>{currentLotActual?.isWinning ? 'Winning' : 'Bid'}</div>
            )}
          </div>
        </CountdownCircleTimer>
      </Col>
      <Col span={12}>
        {currentLotActual?.status === 'CurrentLot' || currentLotActual?.status === 'OnSale' ? (
          <>
            <Row>
              <Text>All Bids in USD</Text>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <InputNumber
                  min={currentLotActual?.userMaxBidValue}
                  value={bid}
                  step={getIncrease()}
                  onChange={setBid}
                  formatter={(value?: string | number) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Col>
              <Col span={12}>
                <ButtonFWidth type="primary" onClick={handleMakeBid}>
                  Bid
                </ButtonFWidth>
              </Col>
            </Row>
          </>
        ) : (
          <Text>{currentLotActual?.status.replace(/([a-z])([A-Z])/g, '$1 $2')}</Text>
        )}
      </Col>
    </>
  );
};

export default memo(TimerBidSection);
