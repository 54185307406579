import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { currencySelector } from '../../../store/slices/initSlice';

interface Props {
  price: number | null;
  [key: string]: any;
}

const Price: FC<Props> = ({ price, ...props }) => {
  const currency = useSelector(currencySelector);
  if (currency.type === 'usd') return <span {...props}>{` $ ${(price || 0).toFixed(2)}`}</span>;
  return <span {...props}>{` ${((price || 0) * currency.value).toFixed(2)} GEL`}</span>;
};

export default Price;
