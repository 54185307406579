import React, { useState } from 'react'
import { AmountBody, AmountHeader, AmountPopupWrapper, AmountWrapper } from './style'
import { ReactSVG } from 'react-svg'
import Price from '../../shared/components/Price'
import { useTranslation } from 'react-i18next'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Popup from './Popup'

const Amount = ({payments}: any) => {
    const [isAmountDropdownVisible, setIsAmountDropdownVisible] = useState(false);
    const { t } = useTranslation()
    const { md } = useBreakpoint()
    
    const amountBody = () => (
        <AmountBody>
            <ul>
                <li>
                    <span>{t('deposit_current_deposit')}:</span> 
                    <Price price={payments.depositAmount} />
                </li>
                <li>
                    <span>{t('deposit_max_bidding_power')}:</span> 
                    <Price price={payments.maxBiddingPower} />
                </li>
                <li>
                    <span>{t('deposit_current_bidding_power')}:</span> 
                    <Price price={payments.biddingPower} />
                </li>
                <li>
                    <span>{t('deposit_bonus_amount')}:</span> 
                    <Price price={payments.bonusAmount} />
                </li>
                <li>
                    <span>{t('deposit_dealer_amount')}:</span> 
                    <Price price={payments.balanceAmount} />
                </li>
                <li>
                    <span>{t('deposit_issued_amount')}:</span> 
                    <Price price={payments.issuedAmount} />
                </li>
            </ul>
        </AmountBody>
    )

  return (
    <AmountWrapper>
        <AmountHeader onClick={() => setIsAmountDropdownVisible(!isAmountDropdownVisible)}>
        <div className="text-wrapper">
            <span>{t('deposit_current_deposit')}</span>
            <div className='price'><Price price={payments.depositAmount} /></div>
        </div>
        <ReactSVG src='/icons/arrow-up.svg' className={`svg-wrapper ${isAmountDropdownVisible && "upsidedown"}`}/>
        </AmountHeader>
        {md ? 
            <>
                {isAmountDropdownVisible && amountBody()}
            </>
        : <>
            {isAmountDropdownVisible && <Popup onClose={() => setIsAmountDropdownVisible(false)}>
                <AmountPopupWrapper>
                    <h3>Amount</h3>
                    {amountBody()}
                </AmountPopupWrapper>
            </Popup>}
        </>}

  </AmountWrapper>
  )
}

export default Amount