/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import axios, { Canceler } from 'axios';
import { AppThunk, RootState } from '../store';
import { axiosInstance } from '../../core/axios';
import api from '../../core/api';
import { BidByStatusInformation, BidsType } from '../../interfaces/Product';
import { axiosSignalr } from '../../core/axios-signalr';
import { getPublicOneLotPageData, updateLotsListSuccess } from './productSlice';
import { showNotification } from '../../utils/notify';
import { getApiForLots } from '../../utils/functions';
import i18n from '../../i18n';

const { CancelToken } = axios;
let cancel: Canceler;

export interface InitState {
  bidsByStatusList: BidByStatusInformation[];
  bidsByStatusListTotalCount: number;
  bidsByStatusListIsLoading: boolean;

  myOffersList: BidByStatusInformation[];
  myOffersListTotalCount: number;
  myOffersListIsLoading: boolean;
}

export const initialState: InitState = {
  bidsByStatusList: [],
  bidsByStatusListTotalCount: 0,
  bidsByStatusListIsLoading: false,
  myOffersList: [],
  myOffersListTotalCount: 0,
  myOffersListIsLoading: false,
};

export const bidsSlice = createSlice({
  name: 'bids',
  initialState,
  reducers: {
    getBidsByStatusForUserRequest: (state) => {
      state.bidsByStatusList = [];
      state.bidsByStatusListIsLoading = true;
    },
    getBidsByStatusForUserSuccess: (state, action) => {
      state.bidsByStatusListIsLoading = false;
      state.bidsByStatusList = action.payload.data;
      state.bidsByStatusListTotalCount = action.payload.totalCount;
    },
    getBidsByStatusForUserFailuare: (state) => {
      state.bidsByStatusListIsLoading = false;
    },
    getMyOffersForUserRequest: (state) => {
      state.myOffersList = [];
      state.myOffersListIsLoading = true;
    },
    getMyOffersForUserSuccess: (state, action) => {
      state.myOffersListIsLoading = false;
      state.myOffersList = action.payload.data;
      state.myOffersListTotalCount = action.payload.totalCount;
    },
    getMyOffersForUserFailuare: (state) => {
      state.myOffersListIsLoading = false;
    },
  },
});

export const {
  getBidsByStatusForUserRequest,
  getBidsByStatusForUserSuccess,
  getBidsByStatusForUserFailuare,
  getMyOffersForUserRequest,
  getMyOffersForUserSuccess,
  getMyOffersForUserFailuare,
} = bidsSlice.actions;

export const getBidsByStatusForUser = (
  SortOrder: string,
  bidsType: BidsType | 'Won' | 'Lost',
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
): AppThunk => async (dispatch) => {
  try {
    let res;
    if (cancel !== undefined) {
      cancel();
    }
    if (bidsType === 'myoffers') {
      dispatch(getMyOffersForUserRequest());
      res = await axiosInstance.get(api.GetProductOffersByProductId);
      dispatch(getMyOffersForUserSuccess(res));
    } else {
      dispatch(getBidsByStatusForUserRequest());
      res = await axiosInstance.get(api.GetBidsByStatusForUser, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
        params: {
          Page,
          PageItemsCount,
          SortField,
          SortOrder,
          bidsType,
        },
      });
      dispatch(getBidsByStatusForUserSuccess(res.data));
    }
  } catch (error: any) {
    if (bidsType === 'myoffers') {
      dispatch(getMyOffersForUserFailuare());
    } else {
      dispatch(getBidsByStatusForUserFailuare());
    }
    error.response && showNotification('error', error.response.data.errorMessage);
  }
};

export const buyerStayOnPreviousBid = (
  productId: string,
  SortOrder: string,
  bidsType: BidsType,
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
): AppThunk => async (dispatch) => {
  try {
    await axiosSignalr.post(api.BuyerStayOnPreviousBid, null, {
      params: {
        productId,
      },
    });
    const list = await axiosInstance.get(api.GetBidsByStatusForUser, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        bidsType,
      },
    });
    dispatch(getBidsByStatusForUserSuccess(list.data));
    showNotification('success', 'Stay on Mine');
  } catch (error: any) {
    dispatch(getBidsByStatusForUserFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const buyerApprove = (
  productId: string,
  SortOrder: string,
  bidsType: BidsType,
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
): AppThunk => async (dispatch) => {
  try {
    await axiosSignalr.post(api.BuyerApprove, null, {
      params: {
        productId,
      },
    });
    const list = await axiosInstance.get(api.GetBidsByStatusForUser, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        bidsType,
      },
    });
    dispatch(getBidsByStatusForUserSuccess(list.data));
    showNotification('success', i18n.t('approved'));
  } catch (error: any) {
    dispatch(getBidsByStatusForUserFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const buyerNewBid = (
  bid: number,
  productId: string,
  SortOrder: string,
  bidsType: BidsType,
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
): AppThunk => async (dispatch) => {
  dispatch(getBidsByStatusForUserRequest());
  try {
    await axiosSignalr.post(api.BuyerNewBid, {
      bid,
      productId,
    });
    const list = await axiosInstance.get(api.GetBidsByStatusForUser, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        bidsType,
      },
    });
    dispatch(getBidsByStatusForUserSuccess(list.data));
    showNotification('success', i18n.t('price_saved'));
  } catch (error: any) {
    dispatch(getBidsByStatusForUserFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const sellerNewPrice = (
  bid: number,
  productId: string,
  SortOrder: string,
  type: string,
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
  DaysInYardLessThan?: string,
  DaysInYardGreaterThan?: string,
  Country?: string,
  AssignmentType?: string,
  VehicleType?: string,
  Make?: string,
  Model?: string,
): AppThunk => async (dispatch) => {
  dispatch(getBidsByStatusForUserRequest());

  try {
    await axiosSignalr.post(api.SellerNewPrice, {
      bid,
      productId,
    });

    const list = await axiosInstance.get(getApiForLots(type), {
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        DaysInYardLessThan,
        DaysInYardGreaterThan,
        Country,
        AssignmentType,
        VehicleType,
        Make,
        Model,
      },
    });
    dispatch(updateLotsListSuccess(list.data));
    showNotification('success', i18n.t('price_saved'));
  } catch (error: any) {
    dispatch(getBidsByStatusForUserFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const sellerDecline = (
  productId: string,
  SortOrder: string,
  type: string,
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
  DaysInYardLessThan?: string,
  DaysInYardGreaterThan?: string,
  Country?: string,
  AssignmentType?: string,
  VehicleType?: string,
  Make?: string,
  Model?: string,
): AppThunk => async (dispatch) => {
  dispatch(getBidsByStatusForUserRequest());
  try {
    await axiosSignalr.post(api.SellerDecline, null, {
      params: {
        productId,
      },
    });

    const list = await axiosInstance.get(getApiForLots(type), {
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        DaysInYardLessThan,
        DaysInYardGreaterThan,
        Country,
        AssignmentType,
        VehicleType,
        Make,
        Model,
      },
    });
    dispatch(updateLotsListSuccess(list.data));
    showNotification('success', i18n.t('declined'));
  } catch (error: any) {
    dispatch(getBidsByStatusForUserFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const sellerApprove = (
  productId: string,
  SortOrder: string,
  type: string,
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
  DaysInYardLessThan?: string,
  DaysInYardGreaterThan?: string,
  Country?: string,
  AssignmentType?: string,
  VehicleType?: string,
  Make?: string,
  Model?: string,
): AppThunk => async (dispatch) => {
  try {
    await axiosSignalr.post(api.SellerApprove, null, {
      params: {
        productId,
      },
    });
    const list = await axiosInstance.get(getApiForLots(type), {
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        DaysInYardLessThan,
        DaysInYardGreaterThan,
        Country,
        AssignmentType,
        VehicleType,
        Make,
        Model,
      },
    });
    dispatch(updateLotsListSuccess(list.data));
    showNotification('success',  i18n.t('approved'));
  } catch (error: any) {
    dispatch(getBidsByStatusForUserFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const makePreliminaryBid = (ProductId: number, BidValue: number): AppThunk => async (dispatch) => {
  try {
    await axiosSignalr.get(api.MakePreliminaryBid, {
      params: {
        ProductId,
        BidValue,
      },
    });
    dispatch(getPublicOneLotPageData(ProductId));
    showNotification('success', i18n.t('bid_is_made'));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const makeTimerBid = (ProductId: number, BidValue: number): AppThunk => async (dispatch) => {
  try {
    await axiosSignalr.get(api.MakeTimerBid, {
      params: {
        ProductId,
        BidValue,
      },
    });
    dispatch(getPublicOneLotPageData(ProductId));
    showNotification('success', 'Timer Bid is made');
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const makeOffer = (ProductId: number, BidValue: number): AppThunk => async (dispatch) => {
  try {
    await axiosSignalr.get(api.MakeOffer, {
      params: {
        ProductId,
        BidValue,
      },
    });
    dispatch(getPublicOneLotPageData(ProductId));
    showNotification('success', i18n.t('bidPlaced', { BidValue }));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const cancelOffer = (
  productId: number,
  SortOrder: string,
  type: string,
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
  DaysInYardLessThan?: string,
  DaysInYardGreaterThan?: string,
  Country?: string,
  AssignmentType?: string,
  VehicleType?: string,
  Make?: string,
  Model?: string,
): AppThunk => async (dispatch) => {
  dispatch(getBidsByStatusForUserRequest());
  try {
    await axiosSignalr.get(api.CancelOffer, {
      params: {
        productId,
      },
    });

    const list = await axiosInstance.get(getApiForLots(type), {
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        DaysInYardLessThan,
        DaysInYardGreaterThan,
        Country,
        AssignmentType,
        VehicleType,
        Make,
        Model,
      },
    });
    dispatch(updateLotsListSuccess(list.data));
    showNotification('success', i18n.t('declined'));
  } catch (error: any) {
    dispatch(getBidsByStatusForUserFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const approveOffer = (
  productId: number,
  SortOrder: string,
  type: string,
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
  DaysInYardLessThan?: string,
  DaysInYardGreaterThan?: string,
  Country?: string,
  AssignmentType?: string,
  VehicleType?: string,
  Make?: string,
  Model?: string,
): AppThunk => async (dispatch) => {
  try {
    await axiosSignalr.get(api.ApproveOffer, {
      params: {
        productId,
      },
    });

    const list = await axiosInstance.get(getApiForLots(type), {
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        DaysInYardLessThan,
        DaysInYardGreaterThan,
        Country,
        AssignmentType,
        VehicleType,
        Make,
        Model,
      },
    });
    dispatch(updateLotsListSuccess(list.data));
    showNotification('success', i18n.t('approved'));
  } catch (error: any) {
    dispatch(getBidsByStatusForUserFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

// SELECTORS
export const bidsByStatusListSelector = (state: RootState) => state.bids.bidsByStatusList;
export const bidsByStatusListTotalCountSelector = (state: RootState) => state.bids.bidsByStatusListTotalCount;
export const bidsByStatusListIsLoadingSelector = (state: RootState) => state.bids.bidsByStatusListIsLoading;

export const myOffersListSelector = (state: RootState) => state.bids.myOffersList;
export const myOffersListTotalCountSelector = (state: RootState) => state.bids.myOffersListTotalCount;
export const myOffersListIsLoadingSelector = (state: RootState) => state.bids.myOffersListIsLoading;

export default bidsSlice.reducer;
