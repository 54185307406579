import React from 'react'
import { useTranslation } from 'react-i18next'
import LotCardDescription, { LotCardRow, LotCardText } from '../../../shared/components/LotDescriptionCard'

const DriverSupportCard = ({vehicle}: any) => {

  const { t } = useTranslation()

  return (

    <LotCardDescription title={t("electric_description_driver_support")}>
        <>
            <LotCardRow>
                <LotCardText>{t("rear_view_camera")}</LotCardText>
                <LotCardText>{vehicle?.driverSupport?.rearViewCamera ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("panoramic_camera")}</LotCardText>
                <LotCardText>{vehicle?.driverSupport?.panoramicCamera ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("anti_lock_barking_system")}</LotCardText>
                <LotCardText>{vehicle?.driverSupport?.abs ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("ebdXcbc")}</LotCardText>
                <LotCardText>{vehicle?.driverSupport?.EBDxCBC ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("bas")}</LotCardText>
                <LotCardText>{vehicle?.driverSupport?.breakAssistSystem ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("tcs")}</LotCardText>
                <LotCardText>{vehicle?.driverSupport?.tcs ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("esp")}</LotCardText>
                <LotCardText>{vehicle?.driverSupport?.esp ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("epb")}</LotCardText>
                <LotCardText>{vehicle?.driverSupport?.epb ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("hsa")}</LotCardText>
                <LotCardText>{vehicle?.driverSupport?.hsa ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("tpms")}</LotCardText>
                <LotCardText>{vehicle?.driverSupport?.tpms ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("parking_sensor")}</LotCardText>
                <LotCardText>
                    {vehicle?.driverSupport?.parkingSensorsFront ? t("yes"): t("no")}/
                    {vehicle?.driverSupport?.parkingSensorsRear ? t("yes"): t("no")}
                </LotCardText>
            </LotCardRow>
        </>
    </LotCardDescription>
  )
}

export default DriverSupportCard