import React, { FC, useEffect, memo, useCallback, useState, useMemo } from 'react';
import { Collapse, DatePicker, RadioChangeEvent, Select, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import baselineFiberNew from '@iconify-icons/ic/baseline-fiber-new';
import baselineStars from '@iconify-icons/ic/baseline-stars';
import roundAddLocation from '@iconify-icons/ic/round-add-location';
import roundPlace from '@iconify-icons/ic/round-place';
import roundWarning from '@iconify-icons/ic/round-warning';
import carCog from '@iconify-icons/mdi/car-cog';
import bankIcon from '@iconify-icons/mdi/bank';
import pistonIcon from '@iconify-icons/mdi/piston';
import carShiftPattern from '@iconify-icons/mdi/car-shift-pattern';
import engineIcon from '@iconify-icons/mdi/engine';
import gasStation from '@iconify-icons/mdi/gas-station';
import carMultiple from '@iconify-icons/mdi/car-multiple';
import baselineSpeed from '@iconify-icons/ic/baseline-speed';
import baselineDateRange from '@iconify-icons/ic/baseline-date-range';
import baselineMonetizationOn from '@iconify-icons/ic/baseline-monetization-on';
import baselineCheckBox from '@iconify-icons/ic/baseline-check-box';
import roundFormatPaint from '@iconify-icons/ic/round-format-paint';
import { InlineIcon } from '@iconify/react';
import {
  productDefaultGeneralDictionariesSelector,
  makeModelsByVehicleTypeSelector,
  getMakeModelsByVehicleType,
  getDefaultProductGeneralDictionaries,
  productGeneralDictionariesByLanguageSelector,
} from '../../../store/slices/dictionarySlice';
import { DateWrapper, StyledCollapse, StyledSpace, YearFilterItem, YearFilterWrapper } from '../style';
import FilterRadioGroup from '../../../shared/components/FilterRadioGroup';
import FilterTree, { SearchVariant } from '../../../shared/components/FilterTree';
import { getDataForFilter, getYearPeriod, getBodyStyles, getDamages, getLocations } from '../../../utils/functions';
import { allAuctionsAddressesAsDictionarySelector } from '../../../store/slices/auctionSlice';
import { transitAndYardDefinitionSelector } from '../../../store/slices/productSlice';
import { DictionaryItem, MakeModelsByVehicleType } from '../../../interfaces/Dictionary';
import { getAllPopularVehicles, popularVehiclesSelector } from '../../../store/slices/initSlice';
import { PopularVehicleBtn, ScFormItem, ScSelect, ScSlider } from '../../../shared/components/MainBanner/style';
import CollapsePanelExtra from '../components/CollapsePanelExtra';
import ColorSquare from '../../../shared/components/AdvanceSearch/ColorSquare';
import moment from 'moment';
import { CloseCircleFilled } from '@ant-design/icons';
import PriceSlider from '../../../shared/components/PriceSlider';

const FilterCollapse: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const productGeneralDictionariesByLanguage = useSelector(productGeneralDictionariesByLanguageSelector);
  const productDefaultGeneralDictionaries = useSelector(productDefaultGeneralDictionariesSelector);
  const transitAndYardDefinition = useSelector(transitAndYardDefinitionSelector);
  const allAuctionsAddressesAsDictionary = useSelector(allAuctionsAddressesAsDictionarySelector);
  const popularVehicles = useSelector(popularVehiclesSelector);
  const makeModelsByVehicleType = useSelector(makeModelsByVehicleTypeSelector);

  const [dictionaryForTransitAndYardDefinition, setDictionaryForTransitAndYardDefinition] = useState<{ id: string; value: string }[]>([]);
  const [popularMake, setPopularMake] = useState<string[]>([]);
  const [vehicleType] = useState<string | null>(params.get('vehicletype') || 'Automobile');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [modelList, setModelList] = useState<any[]>([]);
  const { Option } = Select;
  const [defect, setdefect] = useState(params.get('defect'));
  const [featuredtype, setfeaturedtype] = useState(params.get('featuredtype'));
  const [bodystyle, setbodystyle] = useState(params.get('bodystyle'));
  const [vehiclemake, setvehiclemake] = useState(params.get('vehiclemake'));
  const [vehiclemodel, setvehiclemodel] = useState(params.get('vehiclemodel'));
  const [vehicleyear, setvehicleyear] = useState(params.get('vehicleyear'));
  const [yearfrom, setyearfrom] = useState(params.get('yearfrom'));
  const [yearto, setyearto] = useState(params.get('yearto'));
  const [auctiontype, setauctiontype] = useState(params.get('auctiontype'));
  const [customs, setcustoms] = useState(params.get('customs'));
  const [carlocation, setcarlocation] = useState(params.get('carlocation'));
  const [transmission, settransmission] = useState(params.get('transmission'));
  const [fueltype, setfueltype] = useState(params.get('fueltype'));
  const [color, setcolor] = useState(params.get('color'));
  const [newlyaddedlots, setnewlyaddedlots] = useState(params.get('newlyaddedlots'));
  const [drivetrain, setdrivetrain] = useState(params.get('drivetrain'));
  const [enginetype, setenginetype] = useState(params.get('enginetype'));
  const [cylinder, setcylinder] = useState(params.get('cylinder'));
  const [auctionsAddress, setauctionsAddress] = useState(params.get('auctionsaddress'));
  const [source, setsource] = useState(params.get('source'));
  const [selectedMakes, setSelectedMakes] = useState<string[]>([]);

  useEffect(() => {
    setdefect(params.get('defect'));
    setfeaturedtype(params.get('featuredtype'));
    setbodystyle(params.get('bodystyle'));
    setvehiclemake(params.get('vehiclemake'));
    setvehiclemodel(params.get('vehiclemodel'));
    setvehicleyear(params.get('vehicleyear'));
    setyearfrom(params.get('yearfrom'));
    setyearto(params.get('yearto'));
    setauctiontype(params.get('auctiontype'));
    setcustoms(params.get('customs'));
    setcarlocation(params.get('carlocation'));
    settransmission(params.get('transmission'));
    setfueltype(params.get('fueltype'));
    setcolor(params.get('color'));
    setnewlyaddedlots(params.get('newlyaddedlots'));
    setdrivetrain(params.get('drivetrain'));
    setenginetype(params.get('enginetype'));
    setcylinder(params.get('cylinder'));
    setauctionsAddress(params.get('auctionsaddress'));
    setsource(params.get('source'));
  }, [search]);

  useEffect(() => {
    dispatch(getAllPopularVehicles());
  }, [dispatch]);

  useEffect(() => {
    const unique = Array.from(new Set(popularVehicles.map((item) => item.make)));
    setPopularMake(unique);
  }, [popularVehicles]);

  useEffect(() => {
    const vehicletype = productGeneralDictionariesByLanguage.vehicleTypesList?.find((item) => item.value === vehicleType);
    vehicletype?.id && dispatch(getMakeModelsByVehicleType(vehicletype?.id));
  }, [dispatch, productGeneralDictionariesByLanguage, vehicleType]);

  useEffect(() => {
    setDictionaryForTransitAndYardDefinition(
      transitAndYardDefinition.map((item) => ({
        id: `${item.objectId}_${item.locationType}`,
        value: `${item.countryName} (${item.objectName})`,
      }))
    );
  }, [transitAndYardDefinition]);

  useEffect(() => {
    dispatch(getDefaultProductGeneralDictionaries());
  }, [dispatch]);

  const changeOdometr = (event: [number, number]) => {
    const min = (+event[0] / 500000) * 100;
    const max = (event[1] / 500000) * 100;
    const point1 = `0 ${100 - min}%, `;
    const point2 = `100% ${100 - max}%, `;
    const point3 = '100% 100%, ';
    const point4 = '0 100% ';

    return `polygon(${point1}${point2}${point3}${point4})`;
  };

  const getOdoVal = useCallback(() => {
    const from = params.get('vehicleodometrfrom');
    const to = params.get('vehicleodometrto');
    return [from ? +from : 0, to ? +to : 500000];
  }, [params]);

  const [currentOdometr, setCurrentOdometr] = useState<[number, number]>(getOdoVal() as [number, number]);

  const [currentOdometrClip, setCurrentOdometrClip] = useState<string>(changeOdometr([0, 500000]));

  useEffect(() => {
    const odoVal = getOdoVal() as [number, number];
    setCurrentOdometr(odoVal);
    setCurrentOdometrClip(changeOdometr(odoVal));
  }, [getOdoVal, params]);

  const getPriceVal = useCallback(() => {
    const from = params.get('vehiclepricefrom');
    const to = params.get('vehiclepriceto');
    return [from ? +from : 0, to ? +to : 50000];
  }, [params]);


  const [currentPrice, setCurrentPrice] = useState<[number, number]>(getPriceVal() as [number, number]);

  const handleChange = (event: [number, number]) => {
    setCurrentPrice(event);
    const ChangeParam = new URLSearchParams(history.location.search);
    ChangeParam.set('vehiclepricefrom', event[0].toString());
    ChangeParam.set('vehiclepriceto', event[1].toString());

    history.push({ search: ChangeParam.toString() });
  };
  
  const handleChangeOdometr = (event: [number, number]) => {
    setCurrentOdometr(event);
    setCurrentOdometrClip(changeOdometr(event));
  };

  const updateModelList = useCallback(
    (makes: string[]) => {
      const newList = makes.map((make: string) => makeModelsByVehicleType.find((item) => item.make.replace(/\s/g, '_') === make)).filter((item) => item);

      const treeDataFiltered = (newList as MakeModelsByVehicleType[]).filter((make) => make.model.length || make.modelGroups.length);
      const treeData = treeDataFiltered.map((make: MakeModelsByVehicleType) => ({
        title: make.make,
        value: make.make.replace(/\s/g, '_').toLowerCase(),
        key: make.make.replace(/\s/g, '_'),
        checkable: true,
        selectable: true,
        children: getModels(make),
      }));
      setModelList(treeData || []);
    },
    [makeModelsByVehicleType]
  );

  useEffect(() => {
    const makes = vehiclemake?.split(',');
    if (makes && makeModelsByVehicleType) {
      setSelectedMakes(makes);
      updateModelList && updateModelList(makes);
    }
    else { setSelectedMakes([]); }
  }, [makeModelsByVehicleType, vehiclemake, updateModelList]);

  const getMakeForFilter = (array: MakeModelsByVehicleType[]) =>
    array.map((make) => ({
      key: make.make.replace(/\s/g, '_').toLowerCase(),
      title: make.make,
      value: make.make.replace(/\s/g, '_').toLowerCase(),
    }));

  const PopularVehicleButton = ({ onClick, icon, selected, children }: { onClick: () => void, icon: React.ReactNode, selected: boolean, children: React.ReactNode }) => {
      return (
        <PopularVehicleBtn selected={selected} onClick={onClick}>
          {icon} {children}
        </PopularVehicleBtn>
      );
    };

  const getColorDataForFilter = (array: DictionaryItem[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getItems = (result: any, object: any) => {
      if (object.value) {
        result.push({
          title: object.lanValue,
          key: object.value.toLowerCase(),
          // icon: <ColorSquare color={object.value.toLowerCase()} />,
        });
        return result;
      }
      if (Array.isArray(object.nodes)) {
        const nodes = object.nodes.reduce(getItems, []);
        if (nodes.length) result.push({ ...object, nodes });
      }
      return result;
    };
    return array.reduce(getItems, []);
  };

  const handleChangeFilter = (field: string, val: string) => {
    val ? params.set(field, val) : params.delete(field);
    params.set('page', '1');
    history.push({
      pathname: history.location.pathname,
      search: params.toString(),
    });
    switch (field) {
      case 'defect':
        setdefect(val);
        break;
      case 'featuredtype':
        setfeaturedtype(val);
        break;
      case 'bodystyle':
        setbodystyle(val);
        break;
      case 'vehiclemake':
        setvehiclemake(val);
        break;
      case 'vehiclemodel':
        setvehiclemodel(val);
        break;
      case 'vehicleyear':
        setvehicleyear(val);
        break;
      case 'yearfrom':
        setyearfrom(val);
        break;
      case 'yearto':
        setyearto(val);
        break;
      case 'auctiontype':
        setauctiontype(val);
        break;
      case 'transmission':
        settransmission(val);
        break;
      case 'fueltype':
        setfueltype(val);
        break;
      case 'color':
        setcolor(val);
        break;
      case 'drivetrain':
        setdrivetrain(val);
        break;
      case 'enginetype':
        setenginetype(val);
        break;
      case 'cylinder':
        setcylinder(val);
        break;
      case 'auctionsaddress':
        setauctionsAddress(val);
        break;
      case 'source':
        setsource(val);
        break;
      case 'customs':
        setcustoms(val);
        break;
      case 'carlocation':
        setcarlocation(val);
        break;
      case 'newlyaddedlots':
        setnewlyaddedlots(val);
        break;
      default:
        break;
    }
  };

  const handleChangeOdometrToUrl = (data: [number, number]) => {
    handleChangeFilter('vehicleodometrfrom', `${data[0]}`);
    handleChangeFilter('vehicleodometrto', `${data[1]}`);
  };

  const handleCheckRadio = ({ target }: RadioChangeEvent) => {
    const { name, value } = target;
    let newVal = value;
    if (name === 'carlocation') newVal = dictionaryForTransitAndYardDefinition.find((item) => item.id === value)?.value;
    // if (name === 'customs') newVal = value === 'Cleared' ? 'Before' : 'Cleared';
    handleChangeFilter(name as string, newVal);
  };

  const handleCheckCheckbox = (field: string, val: string) => {
    handleChangeFilter(field, val);
  };

  const getModels = (make: MakeModelsByVehicleType) => {
    if (make.modelGroups && make.modelGroups.length) {
      return make.modelGroups.map((model: { modelGroup: string; models: string[] }) => ({
        title: model.modelGroup,
        value: `${make.make}/${model.modelGroup}`.replace(/\s/g, '_').toLowerCase(),
        key: `${make.make}/${model.modelGroup}`.replace(/\s/g, '_').toLowerCase(),
        children: model.models.map((item: string) => ({
          title: item,
          value: `${make.make}/${model.modelGroup}/${item}`.replace(/\s/g, '_').toLowerCase(),
          key: `${make.make}/${model.modelGroup}/${item}`.replace(/\s/g, '_').toLowerCase(),
        })),
      }));
    }
    return make.model.map((model: string) => ({
      title: model,
      value: `${make.make}/${model}`.replace(/\s/g, '_'),
      key: `${make.make}/${model}`.replace(/\s/g, '_'),
    }));
  };

  const handleSetMake = (make: string, vm: string | null) => {
    const makes = vm?.split(',') || [];
    const index = makes.indexOf(make);
    let updatedMakes = [];
    if (index < 0) {
      makes.push(make); // Add the make if not already selected
      updatedMakes = [...selectedMakes, make]; // Update selectedMakes state
    } else {
      makes.splice(index, 1); // Remove the make if already selected
      updatedMakes = selectedMakes.filter(item => item !== make); // Update selectedMakes state
    }
    const value = makes.join();
    if (value) {
      handleChangeFilter('vehiclemake', value);
    } else {
      handleChangeFilter('vehiclemake', '');
    }
    updateModelList(makes);
    setSelectedMakes(updatedMakes); // Update the state
  };
  

  const getHeaderForFilter = (icon: object, title: string) => (
    <StyledSpace>
      <InlineIcon icon={icon} width="16" />
      {title}
    </StyledSpace>
  );

  const handleClear = (event: React.MouseEvent<HTMLElement, MouseEvent>, field: string) => {
    event.stopPropagation();
    if (field === 'vehicleodometrfrom') {
      handleChangeFilter('vehicleodometrfrom', '');
      handleChangeFilter('vehicleodometrto', '');
    } else {
      handleChangeFilter(field, '');
    }
  };

  const getIsChanged = (fieldValue: string | null | [number, number], odo?: boolean) => {
    if (odo && JSON.stringify(fieldValue) === JSON.stringify([0, 500000])) return false;
    return !!fieldValue;
  };

  function disabledDate(current: { year: () => number; }) {
      return current && (current.year() < 1920 || current.year() > 2024);
  }

  interface YearOption {
    key: string;
    title: string;
  }

  const getYearPeriod = (): YearOption[] => {
    const years: YearOption[] = [];
    const nowYear = new Date().getFullYear();
    for (let i = nowYear - 100; i <= nowYear; i++) {
      years.push({ key: `${i}`, title: `${i}` });
    }
    return years.sort((a, b) => +b.key - +a.key);
  };

  const [yearToOptions, setYearToOptions] = useState<YearOption[]>(getYearPeriod());

  const handleYearFromChange = (value: any) => {
    setyearfrom(value);
    if (!value) {
      setYearToOptions(getYearPeriod());
      return;
    }
    const selectedYear = parseInt(value);
    const yearToOptions = getYearPeriod().filter(item => parseInt(item.key) >= selectedYear);
    setYearToOptions(yearToOptions);
  };
  
  const tooltipFormatter = (value: any) => {
    return `${value} mi`;
  };

  return (
    <StyledCollapse className="first-search-step" expandIconPosition="end" defaultActiveKey={['featuredtype', 'bodystyle', 'vehiclemake']}>
      {/* Newly Added Lots */}
      <Collapse.Panel key="newlyaddedlots" header={getHeaderForFilter(baselineFiberNew, t('lot_newly_added_lots'))}>
        <FilterRadioGroup
          currentValue={newlyaddedlots}
          values={[
            { id: 1, value: '1', title: t('lot_newly_added_lots_24_hours')},
            { id: 1, value: '7', title: t('lot_newly_added_lots_7_days') },
          ]}
          onChange={handleCheckRadio}
          height={60}
          name="newlyaddedlots"
        />
      </Collapse.Panel>
      {/* Damage */}
      <Collapse.Panel key="defect" header={getHeaderForFilter(roundWarning, t('lot_defect_type'))} extra={false}>
        <FilterTree
          items={getDamages(productGeneralDictionariesByLanguage.defectStylesList)}
          onCheck={handleCheckCheckbox}
          checkedKeys={defect?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="defect"
        />
      </Collapse.Panel>
      {/* Featured Items */}
      <Collapse.Panel key="featuredtype" header={getHeaderForFilter(baselineStars, t('lot_featured_lots'))} extra={false}>
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.badgeTypesList || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={featuredtype?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="featuredtype"
        />
      </Collapse.Panel>
      {/* Body Style */}
      <Collapse.Panel key="bodystyle" header={getHeaderForFilter(carMultiple, t('lot_body_style'))} extra={false}>
        <FilterTree
          items={getBodyStyles(productGeneralDictionariesByLanguage.automobileTypesList)}
          onCheck={handleCheckCheckbox}
          checkedKeys={bodystyle?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="bodystyle"
        />
      </Collapse.Panel>
      {/* Make */}
      <Collapse.Panel key="vehiclemake" header={getHeaderForFilter(baselineCheckBox, t('lot_make'))} extra={false}>
        <FilterTree
          items={getMakeForFilter(makeModelsByVehicleType || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={vehiclemake?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="vehiclemake"
        >
          <>
          {popularMake.map((item) => (
                  <PopularVehicleButton
                    onClick={() => handleSetMake(item, vehiclemake)}
                    icon={<i className={`car-${item.toLowerCase()}`} />}
                    key={item}
                    selected={selectedMakes.includes(item)}
                    children={undefined}
                  />
                ))}
          </>
        </FilterTree>
      </Collapse.Panel>
      {/* Model */}
      {modelList.length && (
        <Collapse.Panel key="vehiclemodel" header={getHeaderForFilter(baselineCheckBox, t('lot_serial_model'))} extra={false}>
          <FilterTree
            items={modelList}
            onCheck={handleCheckCheckbox}
            checkedKeys={vehiclemodel?.split(',')}
            searchVariant={SearchVariant.SECONDARY}
            name="vehiclemodel"
          />
        </Collapse.Panel>
      )}
      {/* Year */}
      <Collapse.Panel key="vehicleprice" header={getHeaderForFilter(baselineMonetizationOn, t('search_filter_price'))} extra={false}>
          <PriceSlider min={0} max={50000} value={currentPrice} onChange={handleChange} showInputs={false} />
        </Collapse.Panel>
      <Collapse.Panel key="vehicleyear" header={getHeaderForFilter(baselineDateRange, t('lot_year'))} extra={false}>
      <YearFilterWrapper>
      <YearFilterItem>
        <ScFormItem name="yearfrom">
          <ScSelect
            showArrow
            allowClear
            clearIcon={
              <CloseCircleFilled onMouseDown={(e) => handleChangeFilter('yearfrom', '')} />
            }
            showSearch
            maxTagCount="responsive"
            placeholder='1924'
            defaultValue={yearfrom}
            onChange={(value: any) => {
              if (value) {
                handleYearFromChange(value)
                const year = value;
                handleChangeFilter('yearfrom', year);
              }
            }}
          >
            {getYearPeriod().map((item) => (
              <Option value={item.title} key={item.key}>
                {item.title}
              </Option>
            ))}
          </ScSelect>
        </ScFormItem>
      </YearFilterItem>

      <YearFilterItem>
        <ScFormItem name="yearto">
          <ScSelect
            showArrow
            allowClear
            clearIcon={
              <CloseCircleFilled onMouseDown={(e) => handleChangeFilter('yearto', '')} />
            }
            showSearch
            maxTagCount="responsive"
            placeholder='2024'
            defaultValue={yearto}
            onChange={(value: any) => {
              if (value) {
                const year = value;
                handleChangeFilter('yearto', year);
              }
            }}
            options={yearToOptions.map(item => ({ value: item.title, key: item.key }))}
          >
            {yearToOptions.map((item) => (
              <Option value={item.title} key={item.key}>
                {item.title}
              </Option>
            ))}
          </ScSelect>
        </ScFormItem>
      </YearFilterItem>
      </YearFilterWrapper>
        {/* <FilterTree items={getYearPeriod()} onCheck={handleCheckCheckbox} checkedKeys={vehicleyear?.split(',')} searchVariant={SearchVariant.SECONDARY} name="vehicleyear" /> */}
      </Collapse.Panel>
      {/* Auctiontype */}
      <Collapse.Panel key="auctiontype" header={getHeaderForFilter(baselineDateRange, t('lot_auction_type'))} extra={false}>
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.auctionTypesList || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={auctiontype?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="auctiontype"
        />
      </Collapse.Panel>
      {/* Odometer */}
      <Collapse.Panel key="vehicleodometrfrom" header={getHeaderForFilter(baselineSpeed, t('lot_odometer'))} extra={false}>
        <ScSlider
          value={currentOdometr}
          min={0}
          max={500000}
          step={25000}
          range
          tooltipVisible
          currentClip={currentOdometrClip}
          tooltipPlacement="bottom"
          getTooltipPopupContainer={(triggerNode: { parentElement: any; }) => triggerNode.parentElement || triggerNode}
          onAfterChange={handleChangeOdometrToUrl}
          onChange={handleChangeOdometr}
          tooltipFormatter={tooltipFormatter}
          className="mi-slider"
        />
      </Collapse.Panel>
      {/* Customs */}
      <Collapse.Panel key="customs" header={getHeaderForFilter(baselineFiberNew, t('lot_customs'))} extra={false}>
        <FilterRadioGroup
          currentValue={customs}
          values={(productGeneralDictionariesByLanguage.customsTypesList || []).map(item => ({
              id: item.id,
              value: item.value.toLowerCase(),
              title: item.lanValue,
          }))}
          onChange={handleCheckRadio}
          height={70}
          name="customs"
        />
      </Collapse.Panel>
      {/* Car Location */}
      <Collapse.Panel key="carlocation" header={getHeaderForFilter(roundAddLocation, t('lot_location'))} extra={false}>
        <FilterTree
          items={getLocations(transitAndYardDefinition)}
          onCheck={handleCheckCheckbox}
          checkedKeys={carlocation?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="carlocation"
        />
      </Collapse.Panel>
{/* 
      <Collapse.Panel key="carlocation" header={getHeaderForFilter(roundAddLocation, t('lot_location'))} extra={false}>
        <FilterRadioGroup
          currentValue={dictionaryForTransitAndYardDefinition.find((item) => item.value === carlocation)?.id || null}
          values={(dictionaryForTransitAndYardDefinition || []).map(item => ({
            id: item.id,
            value: item.value,
            title: item.value,
        }))}
          onChange={handleCheckRadio}
          height={200}
          location
          name="carlocation"
        />
      </Collapse.Panel> */}
      {/* Transmission */}
      <Collapse.Panel key="transmission" header={getHeaderForFilter(carShiftPattern, t('lot_transmission'))} extra={false}>
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.transmissionTypesList || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={transmission?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="transmission"
        />
      </Collapse.Panel>
      {/* Fuel type */}
      <Collapse.Panel key="fueltype" header={getHeaderForFilter(gasStation, t('lot_fuel_type'))} extra={false}>
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.fuelTypesList || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={fueltype?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="fueltype"
        />
      </Collapse.Panel>
      {/* Color */}
      <Collapse.Panel key="color" header={getHeaderForFilter(roundFormatPaint, t('lot_color'))} extra={false}>
        <FilterTree
          items={getColorDataForFilter(productGeneralDictionariesByLanguage.colorsList || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={color?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="color"
        />
      </Collapse.Panel>
      {/* Vehicle Type */}
      {/* <FilterPanel
        field="vehicletype"
        title='Vehicle Type'
        icon={baselineDirectionsCar}
        fieldValue={params.get('vehicletype')}
      >
        <FilterTree
          items={
            getDataForFilter(
              productDefaultGeneralDictionaries.vehicleTypesList || [],
            )
          }
          onCheck={handleCheckCheckbox}
          checkedKeys={params.get('vehicletype')?.split(',')}
          name="vehicletype"
        />
      </Collapse.Panel> */}
      {/* Drive Train */}
      <Collapse.Panel key="drivetrain" header={getHeaderForFilter(carCog, t('lot_drive'))} extra={false}>
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.driveTrainTypesList || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={drivetrain?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="drivetrain"
        />
      </Collapse.Panel>
      {/* Engine type */}
      <Collapse.Panel key="enginetype" header={getHeaderForFilter(engineIcon, t('lot_engine_type'))} extra={false}>
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.engineTypesList || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={enginetype?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="enginetype"
        />
      </Collapse.Panel>
      {/* Cylinder */}
      <Collapse.Panel key="cylinder" header={getHeaderForFilter(pistonIcon, t('lot_cylinders'))} extra={false}>
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.cylinderTypesList || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={cylinder?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="cylinder"
        />
      </Collapse.Panel>
      {/* Auction */}
      <Collapse.Panel key="auctionsaddress" header={getHeaderForFilter(roundPlace, t('lot_auction'))} extra={false}>
        <FilterTree
          items={getDataForFilter(allAuctionsAddressesAsDictionary || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={auctionsAddress?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="auctionsaddress"
        />
      </Collapse.Panel>
      {/* Source */}
      <Collapse.Panel key="source" header={getHeaderForFilter(bankIcon, t('lot_source'))} extra={false}>
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.sourceTypesList || [])}
          onCheck={handleCheckCheckbox}
          checkedKeys={source?.split(',')}
          searchVariant={SearchVariant.SECONDARY}
          name="source"
        />
      </Collapse.Panel>
    </StyledCollapse>
  );
};

export default memo(FilterCollapse);
