import styled from 'styled-components';

export const SellerWrapper = styled.div`
  max-width: 42.125rem;
  width: 100%;
  margin: 0 auto;
  padding-block: 2rem;
  @media(max-width: 767px){
    max-width: 100%;
    padding: 0 1.5rem 2rem;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #ededed;
  margin-bottom: 1.5rem;
  @media(max-width: 767px){
    flex-direction: column;
    padding-bottom: 0rem;
    border: none;
  }
`;

export const Title = styled.h1`
  font-family: 'main';
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.125rem;
  color: #1b153d;
  margin: 0;
  @media(max-width: 767px){
    color: #1B153D;
    font-size: 1rem;
    font-family: main;
    font-weight: 600;
    line-height: 1.375rem;
  }
`;

export const RateByStarWrapper = styled.div``;

export const SellerInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr;
  gap: 1rem;
  @media(max-width: 767px){
    grid-template-columns: 1fr;
  }
`;

export const SellerImageWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  @media(max-width: 767px){
    width: 9.375rem;
    height: 9.375rem;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1.125rem;
      margin-right: auto;
  margin-left: auto;
  } 
`;

export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  p {
    font-family: 'main';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #92949a;
    margin: 0;
  }
  @media(max-width: 767px){
    p{
      color: #6E7281;
      text-align: center;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
`;

export const ContactInformationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3.75rem;
  @media(max-width: 767px){
    flex-direction: column;
    justify-content: center;
    margin-top: 0.5rem;
    order: 3;
    padding: 0.875rem 0rem;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
    justify-content: space-around;
    gap: 0.5rem;
  }
`;

export const ContactInformationItem = styled.div`
  font-family: 'main';
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #6e7281;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1.5rem;
  .svg-wrapper {
    div {
      width: 1.125rem;
      height: 1.125rem;
    }
    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
    path {
      stroke: #ce2424;
    }
  }
  
  @media(max-width: 767px){
   margin: 0; 
   gap: 0.5rem;
   font-weight: 500;
   .svg-wrapper {
      div, svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
`;

export const SkillList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: auto;
  @media(max-width: 767px){
    justify-content: center;
    order: 2;
    padding: 1rem 1rem 1.5rem;
  }
`;

export const SkillItem = styled.div`
  background: rgba(202, 202, 202, 0.1);
  border: 1px solid #cacaca;
  border-radius: 100px;
  padding: 0.875rem 1rem;
  font-family: 'main';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #1b153d;
  @media(max-width: 767px){
    padding: 0.5rem 0.75rem;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  aspect-ratio: 2.24;
  border-radius: 16px;
  margin-top: 3.5rem;
  overflow: hidden;
  background-image: url("/img/Loading_icon.gif");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border: 1px solid #ededed;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media(max-width: 767px){
    margin-top: 1.25rem;
  }
`;

export const ReviewWrapper = styled.div``;

export const SellerStarRateWrapper = styled.div`
  font-family: 'main';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #1B153D;
  display: flex;
  align-items: center;
  gap: 0.325rem;
  path{
    stroke: #FDC500;
    fill: #FDC500;
  }
`

export const LeaveMessageWrapper = styled.div`
  margin-top: 3rem;
  max-width: 32rem;
    margin-right: auto;
  margin-left: auto;
`

export const TabWrapper = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2.5rem;
  list-style-type: none;
  padding: 0;
  margin-top: 4rem;
`

export const Tab = styled.li`
  color: #566063;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem; 
  cursor: pointer;
  &.active{
    color: #15181A;
    font-weight: 700;
    border-bottom: 1px solid #15181A;
    padding-bottom: 1rem;
  }
`