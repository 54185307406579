import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const DealerCardWrapper = styled.div`
  padding: 0.75rem;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1.125rem;
  @media(max-width: 767px){
    padding: 0.5rem;
    gap: 0.75rem;
    .ant-rate{
      font-size: 16px;
    }
  }
`;

export const DealerPhoto = styled.div`
  max-width: 6rem;
  aspect-ratio: 1;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    overflow: hidden;
  }
  @media(max-width: 767px){
    width: 7rem;
  }
`;

export const DealerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  flex-shrink: 1;
  @media(max-width: 767px){
    gap: 0.5rem;
  }
`;

export const Title = styled(Link)`
  font-family: 'main';
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #1b153d;
  margin: 0;
  @media(max-width: 767px){
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

export const ContactInfoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  @media(max-width: 767px){
    gap: 0.5rem;
  }
`;

export const ContactItem = styled.div`
  font-family: 'main';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #6e7281;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  .svg-wrapper {
    div {
      width: 1.125rem;
      height: 1.125rem;
    }
    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
  .location-wrapper{
    path{
        &{
          stroke: #6E7281;
          fill: #6E7281;
        }
      }
  }
  @media(max-width: 767px){
    gap: 0.5rem;
    font-size: 0.625rem;
    line-height: 1rem;
  }
`;

export const DealerStarRateWrapper = styled.div`
  font-family: 'main';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #1B153D;
  display: flex;
  align-items: center;
  gap: 0.325rem;
  path{
    stroke: #FDC500;
    fill: #FDC500;
  }
`