import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from 'antd';


interface ActionButtonProps {
    isActive: boolean
}

export const ActionButton = styled.button<ActionButtonProps>`
    width: 1rem;
    height: 1rem;
    padding: 0;
    path{
        transition: 0.2s;
        fill: ${p => p.isActive ? "#CE2424" : "#8F8F8F"};
    }
    &:hover{
        path{
            fill: #CE2424;
        }
    }
    @media(max-width: 767px){
        &:hover{
            path{
                fill: ${p => p.isActive ? "#CE2424" : "#8F8F8F"};
            }
        }
    }
`

export const ImageLink = styled(Link)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    img{
        width: auto;
        border-radius: 4px;
    }
`

export const OdometerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`

export const ScCheckSquareFilled = styled.div`
  border-radius: 4px;
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${(props) => props.color};
`;

export const ScButton = styled(Button)`
    font-family: 'main';
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    background: #CE2424;
    border-radius: 4px;
    height: auto;
`

export const CellContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    justify-content: space-between;
    .ant-tag{
        font-family: 'main';
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 0.875rem;
        padding: 0.25rem;
        border-radius: 4px;
    }
`

export enum CellContentTextVariant {
    "DANGER"
}

interface CellContentText {
    variant?: CellContentTextVariant;
    fw?: 400 | 500;
}

export const CellContentText = styled.span<CellContentText>`
    font-family: 'main';
    font-weight: ${p => p.fw ?? 500};
    font-size: 0.75rem;
    color: #000000;
    ${p => p.variant == CellContentTextVariant.DANGER && css`
        color: #CE2424;
    `}
`

export const VehicleInfoWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    align-items: center;
`