import styled from "styled-components";

export const PageTitle = styled.h1`
  font-family: 'main';
  &:lang(ka){
      font-family: 'arial-caps';
  }
  font-weight: 800;
  font-size: 2rem;
  
  line-height: 3.75rem;
  color: #080808;
  margin: 0;
  padding: 1.75rem 0 1.5rem;
  position: relative;
  width: fit-content;
  &::after{
    content: "";
    width: 100%;
    height: 0.5rem;
    background: #CE2424;
    border-radius: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(0, calc( 100% - 0.5rem))
  }
  @media (max-width: 767px){
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1B153D;
    padding: 1rem 0 1rem;
    &::after{
      width: 50px;
      height: 2px;
      transform: translate(0, calc( 100% - 2px))
    }
  }
`;