import styled  from 'styled-components';

export const BannerWrapper = styled.div`
    height: auto;
    max-height: calc(100vh - 25rem);
    min-height: 35rem;
    width: 100%;
    aspect-ratio: 2.7;
    overflow: hidden;
    position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 37, 42, 0.46);
  }
  @media (max-width: 767px){
    height: 6.25rem;
  }
`;

export const Container = styled.div`
  max-width: 63.5rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.625rem;
`;

export const PageTitle = styled.h1`
  font-family: 'main';
  &:lang(ka){
      font-family: 'arial-caps';
  }
  font-weight: 800;
  font-size: 2rem;
  line-height: 3.75rem;
  color: #080808;
  margin: 2.75rem 0 0;
  position: relative;
  width: fit-content;
  &::after{
    content: "";
    width: 100%;
    height: 0.5rem;
    background: #CE2424;
    border-radius: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(0, calc( 100% + 1rem))
  }
  @media (max-width: 767px){
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1B153D;
    margin: 1.5rem 0 2.5rem;
    &::after{
      width: 50px;
      height: 2px;
    }
  }
`;

export const DescriptionSectionWrapper = styled.section`
    margin-top: 3.25rem;
`

export const Description = styled.p`
  font-family: 'main';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #1B153D;
  margin-bottom: 1.5rem;
  b{
      color: #CE2424
  }
  @media (max-width: 767px){
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
  }
`

export const HistoryWallWrapper = styled.div`
    margin: 5rem 0 6rem;
    @media(max-width: 767px){
      margin: 1.5rem 0rem;
    }
`

export const MapWrapper = styled.div`
  max-width: 100rem;
  margin: 0 auto;
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
  position: relative;
  .svg-wrapper{
    width: 1220px;
    height: 720px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    /* height: 375px; */
    & > svg{
      width: 1220px;
      height: 850px;
    }
  }
`

interface PinWrapperProps {
  left: number, 
  top: number, 
}

export const PinWrapper = styled.div<PinWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  position: absolute;
  left: ${p => p.left}px;
  top: ${p => p.top}px;
  span{
    border: 1px solid rgba(226, 225, 228, 1);
    background-color: #fff;
    color: #000;
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: main;
    font-weight: 600;
    padding: 0.125rem 0.325rem;
    border-radius: 0.5rem;
    position: relative;
    z-index: 8;
    transition: 0.2s;
  }
  .pin-icon{
    div, svg{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &:hover{
    z-index: 9;
    span{
      background-color: #CE2424;
      color: #fff;
      border-color: #CE2424;
      cursor: pointer;
    }
  }
`

export const CountryPopup = styled.div`
  padding: 1.5rem 1.875rem;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 0px 50px #E8E8E8;
  border-radius: 8px;
  min-width: 15rem;
  position: absolute;
  right: 2rem;
  top: 15%;
  z-index: 9;
  max-height: 450px;
  overflow-y: auto;
  h2{
    font-family: 'main';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: #CE2424;
    &::after{
      content: "";
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background: #CE2424;
      border-radius: 4px;
      margin-top: 0.5rem;
    }
  }
  nav{
    display: flex;
    flex-direction: column;
  }
  a{
    font-family: 'main';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.875rem;
    color: #1B153D;
    display: flex;
    flex-direction: column;
    &:not(:last-child){
      margin-bottom: 1rem;
    }
    span{
      padding-left: 1rem;
      font-size: 0.75rem;
      line-height: 0.875rem;
      max-width: 300px;
    }
  }
  @media(max-width: 991px){
    position: relative;
    right: 0;
    top: 0;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 0px 50px #E8E8E8;
    border-radius: 8px;
    padding: 0.75rem;
    width: calc( 100% - 2rem );
    margin: 0 auto;
    margin-bottom: -1rem;
    z-index: 1;
    h2{
      font-size: 1rem;
      line-height: 1.875rem;
      color: #E42346;
    }
    nav{
      flex-direction: row;
      flex-wrap: wrap;
    }
    a{
      font-size: 0.875rem;
      line-height: 1.875rem;
      &:not(:last-child){
        margin-bottom: 0.5rem;
      }
    }
  }
`

export const CityMapWrapper = styled.div`
  width: 100%;
  height: 500px;
`

export const TeamWrapper = styled(Container)`
  margin-top: 4rem;
  max-width: 40rem;
`

export const TeamTitle = styled.h2`
  font-family: 'main';
  &:lang(ka){
      font-family: 'arial-caps';
  }
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 3.75rem;
  text-align: center;
  color: #1B153D;
  margin-bottom: 3rem;
  @media (max-width: 580px){
    font-size: 1.325rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
  }
`

export const TeamSliderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 991px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 580px){
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem;
  }
`

export const PartnerWrapper = styled.div`
  background: #F5F5F5;
  margin-top: 5rem;
  padding: 3rem 1rem;
  @media (max-width: 767px){
    padding: 1.5rem 1.5rem;
  }
`

export const PartnerSliderWrapper = styled(Container)`
  max-width: 74rem;
  position: relative;
  .partner-next-arrow, .partner-prev-arrow{
    cursor: pointer;
    & > div{
      display: block;
      width: 2.5rem;
      height: 2.5rem;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      path{
        stroke: #1B153D;
      }
    }
  }
  .partner-prev-arrow{
    position: absolute;
    left: -3rem;
    top: 50%;
    transform: translate(0, -50%);
  }
  .partner-next-arrow{
    position: absolute;
    right: -3rem;
    top: 50%;
    transform: translate(100%, -50%) rotate(180deg);
  }
  @media (max-width: 1400px) {
    max-width: unset;
    padding: 0 3rem;
    .slick-list{
      height: 4rem;
    }
    .partner-prev-arrow{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-100%, -50%);
    }
    .partner-next-arrow{
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-100%,50%) rotate(180deg);
    }
  }
  @media (max-width: 991px){
    padding: 0 1.5rem;
    .slick-slide{
      overflow: hidden;
      img{
        display: block;
        width: 100%;
        height: 4rem;
        object-fit: contain;
        object-position: center;
      }
    }
    .partner-next-arrow, .partner-prev-arrow{
      & > div{
        width: 1.5rem;
        height: 1.5rem;
        svg{
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  @media (max-width: 767px){
    padding: 0;
    .partner-next-arrow, .partner-prev-arrow{
      display: none;
    }
  }
`

export const PartnerCard = styled.div`
  width: auto;
  padding: 0 2.25rem;
  @media (max-width: 991px){
    padding: 0 0.875rem;
  }
`