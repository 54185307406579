import React, { FC, memo, useCallback, useRef, useState } from 'react';
import { Modal, Form, Input, Button, Checkbox, Select } from 'antd';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { useCookies } from 'react-cookie';
import { productGeneralDictionariesByLanguageSelector } from '../../store/slices/dictionarySlice';
import { axiosInstance } from '../../core/axios';
import api from '../../core/api';
import { showNotification } from '../../utils/notify';
import { FormWrapper, InputGroup, RegisterDealerBanner, RegisterDealerWrapper, SubmitButton } from './style';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CustomPhoneInput, { CustomPhoneInputVariant } from '../../shared/components/CustomPhoneInput';
import Meta from '../../shared/components/Meta';

const { Option } = Select;

interface Props {
  visible: boolean;
  onCancel: (date: Date) => void;
}

const RegisterDealer: FC<Props> = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const [isDealerModalOpen, setIsDealerModalOpen] = useState(false);
  const history = useHistory();
  const submittingRef = useRef(false);
  const productGeneralDictionariesByLanguage = useSelector(productGeneralDictionariesByLanguageSelector);

  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    if (submittingRef.current) return;
    submittingRef.current = true;
    form.validateFields().then(async (values) => {
      try {
        await axiosInstance.post(api.AddNewUser, {
          ...values,
          phoneNumber: values.phoneNumber,
        });
        setIsDealerModalOpen(true);
        // showNotification('success', 'Success');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        showNotification('error', error);
      }
    }).catch(() => {
      submittingRef.current = false;
    });
  }, [form, onCancel]);

  const handleOk = () => {
    setIsDealerModalOpen(false);
    history.push({
      pathname: '/',
    });
  };

  return (
    <><Meta
      title={t("meta_dealerreg_title")}
      description={t("meta_dealerreg_desc")} /><RegisterDealerWrapper>
        <RegisterDealerBanner src="/img/banners/dealer-registration.webp" />
        <FormWrapper>
          <Form
            form={form}
            name="control-hooks"
            initialValues={{
              firstName: '',
              lastName: '',
              phoneNumber: '',
              country: undefined,
              toBuy: true,
              toSell: true,
              agreedToTermsAndConditions: false,
              needContactWithRepresentative: false,
            }}
          >
            <h2>{t('dealer_search_title')}!</h2>
            <Form.Item name="firstName" rules={[{ required: true, message: t('noti_required') }]}>
              <InputGroup>
                <label>{t('dealer_search_name')}</label>
                <Input autoComplete="new-password" placeholder={t('dealer_search_name')} />
              </InputGroup>
            </Form.Item>
            <Form.Item name="lastName" rules={[{ required: false }]}>
              <InputGroup>
                <label>
                  {t('dealer_search_email')} <span>({t('dealer_search_optional')})</span>
                </label>
                <Input autoComplete="new-password" placeholder={t('dealer_search_email')} />
              </InputGroup>
            </Form.Item>
            <Form.Item name="phoneNumber" rules={[{ required: true, message: t('noti_required') }]}>
              <InputGroup>
                <label>{t('dealer_search_phone')}</label>
                <CustomPhoneInput
                  value='995'
                  placeholder={t('dealer_search_phone')}
                  variant={CustomPhoneInputVariant.PRIMARY} />
              </InputGroup>
            </Form.Item>
            <Form.Item name="country" rules={[{ required: false }]}>
              <InputGroup>
                <label>
                  {t('dealer_search_country')} <span>({t('dealer_search_optional')})</span>
                </label>
                <Select
                  onChange={(value) => form.setFieldsValue({ country: value })}
                  showSearch
                  placeholder={t('dealer_search_country')}
                  optionFilterProp="children"
                  filterOption={(input, option: any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {productGeneralDictionariesByLanguage.countriesList?.map((item) => (
                    <Option key={item.id} value={item.value}>
                      {item.lanValue}
                    </Option>
                  ))}
                </Select>
              </InputGroup>
            </Form.Item>
            <SubmitButton onClick={handleSubmit}>{t('dealer_search_submit')}</SubmitButton>
          </Form>
        </FormWrapper>
        <Modal
          title={t('dealer_search_title')}
          open={isDealerModalOpen}
          footer={[
            <Button type="primary" onClick={handleOk}>
              {t('dealer_search_modal_ok')}
            </Button>,
          ]}
        >
          <p>{t('dealer_search_modal_text')}</p>
        </Modal>
      </RegisterDealerWrapper></>
  );
};

export default memo(RegisterDealer);
