/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showNotification } from '../../utils/notify';
import { AppThunk, RootState } from '../store';
import api from '../../core/api';
import { axiosInstance } from '../../core/axios';
import { Payments, Deposits } from '../../interfaces/Payments';
import i18n from '../../i18n';

export interface InitState {
  currentUserBalanceAmount: number;
  userPaymentsDueList: Payments[];
  userPaymentsDueListTotalCount: number;
  userPaymentsDueListIsLoading: boolean;
  usersPaymentsListForUser: Payments[];
  usersPaymentsListForUserTotalCount: number;
  usersPaymentsListForUserIsLoading: boolean;
  userDepositsList: Deposits[];
  userDepositsListTotalCount: number;
  userDepositsListIsLoading: boolean;
  payments: {
    balanceAmount: number;
    maxBiddingPower: number;
    biddingPower: number;
    bonusAmount: number;
    depositAmount: number;
    issuedAmount: number;
    membershipPackage: string;
  };
  sellerFee: number;
  fineTypes: {
    id: number;
    isDeleted: number;
    name: string;
  }[];
  feeTableData: any[];
  feeTableDataIsLoading: boolean;
}

export const initialState: InitState = {
  currentUserBalanceAmount: 0,
  userPaymentsDueList: [],
  userPaymentsDueListTotalCount: 0,
  userPaymentsDueListIsLoading: false,
  usersPaymentsListForUser: [],
  usersPaymentsListForUserTotalCount: 0,
  usersPaymentsListForUserIsLoading: false,
  userDepositsList: [],
  userDepositsListTotalCount: 0,
  userDepositsListIsLoading: false,
  payments: {
    balanceAmount: 0,
    maxBiddingPower: 0,
    biddingPower: 0,
    bonusAmount: 0,
    depositAmount: 0,
    issuedAmount: 0,
    membershipPackage: '',
  },
  sellerFee: 0,
  fineTypes: [],
  feeTableData: [],
  feeTableDataIsLoading: false,
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setCurrentUserBalanceAmountSuccess: (state, action) => {
      state.currentUserBalanceAmount = action.payload;
    },
    setUserPaymentsDueListRequest: (state) => {
      state.userPaymentsDueList = [];
      state.userPaymentsDueListIsLoading = true;
    },
    setUserPaymentsDueListSuccess: (state, action) => {
      state.userPaymentsDueListIsLoading = false;
      state.userPaymentsDueList = action.payload.data;
      state.userPaymentsDueListTotalCount = action.payload.totalCount;
    },
    setUserPaymentsDueListFailuare: (state) => {
      state.userPaymentsDueListIsLoading = false;
    },
    setUserPaymentsListForUserRequest: (state) => {
      state.usersPaymentsListForUser = [];
      state.usersPaymentsListForUserIsLoading = true;
    },
    setUserPaymentsListForUserSuccess: (state, action) => {
      state.usersPaymentsListForUserIsLoading = false;
      state.usersPaymentsListForUser = action.payload.data;
      state.usersPaymentsListForUserTotalCount = action.payload.totalCount;
    },
    setUserPaymentsListForUserFailuare: (state) => {
      state.usersPaymentsListForUserIsLoading = false;
    },
    setUserDepositsListRequest: (state) => {
      state.userDepositsListIsLoading = true;
    },
    setUserDepositsListSuccess: (state, action) => {
      state.userDepositsListIsLoading = false;
      state.userDepositsList = action.payload.data;
      state.userDepositsListTotalCount = action.payload.totalCount;
    },
    setUserDepositsListFailuare: (state) => {
      state.userDepositsListIsLoading = false;
    },
    setPaymentsSuccess: (state, action) => {
      state.payments.balanceAmount = action.payload.balanceAmount;
      state.payments.maxBiddingPower = action.payload.maxBiddingPower;
      state.payments.biddingPower = action.payload.biddingPower;
      state.payments.bonusAmount = action.payload.bonusAmount;
      state.payments.depositAmount = action.payload.depositAmount;
      state.payments.issuedAmount = action.payload.issuedAmount;
      state.payments.membershipPackage = action.payload.membershipPackage;
    },
    setSellerFeeSuccess: (state, action) => {
      state.sellerFee = action.payload.sellerFee;
    },
    setFeeTableDataSuccess: (state, action) => {
      state.feeTableData = action.payload;
      state.feeTableDataIsLoading = false;
    },
    setFeeTableDataRequest: (state) => {
      state.feeTableDataIsLoading = true;
    },
    setFeeTableDataFailure: (state) => {
      state.feeTableDataIsLoading = false;
    },
    setFineTypes: (state, action) => {
      state.fineTypes = action.payload;
    },
    changeDepositsLoadingStatus: (state) => {
      state.userDepositsListIsLoading = !state.userDepositsListIsLoading;
      state.userPaymentsDueListIsLoading = !state.userPaymentsDueListIsLoading;
    },
  },
});

export const {
  setCurrentUserBalanceAmountSuccess,
  setUserPaymentsDueListRequest,
  setUserPaymentsDueListSuccess,
  setUserPaymentsDueListFailuare,
  setUserPaymentsListForUserRequest,
  setUserPaymentsListForUserSuccess,
  setUserPaymentsListForUserFailuare,
  setUserDepositsListRequest,
  setUserDepositsListSuccess,
  setUserDepositsListFailuare,
  setPaymentsSuccess,
  setSellerFeeSuccess,
  setFeeTableDataSuccess,
  setFeeTableDataRequest,
  setFeeTableDataFailure,
  setFineTypes,
  changeDepositsLoadingStatus,
} = paymentsSlice.actions;

export const getCurrentUserBalanceAmount = (): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstance.get(api.GetCurrentUserBalanceAmount);
    dispatch(setCurrentUserBalanceAmountSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getPayments = (): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstance.get(api.GetPayments);
    dispatch(setPaymentsSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getSellerFee = (): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstance.get(api.GetSellerFee);
    dispatch(setSellerFeeSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getUserPaymentsDueList = (
  type: string,
  IssuedDateLastDaysCount: number,
  SortOrder: string,
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
  TableSearch?: string,
): AppThunk => async (dispatch) => {
  let url = api.GetUserPaymentsDueList;
  if (type === 'paymentshistory') url = api.GetUserHistoryPaymentsList;
  try {
    dispatch(setUserPaymentsDueListRequest());
    const res = await axiosInstance.get(url, {
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        IssuedDateLastDaysCount,
        TableSearch,
      },
    });
    dispatch(setUserPaymentsDueListSuccess(res.data));
  } catch (error: any) {
    dispatch(setUserPaymentsDueListFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getUserDepositsList = (SortOrder: string, Page?: number, PageItemsCount?: number, SortField?: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setUserDepositsListRequest());
    const res = await axiosInstance.get(api.GetUserDepositsList, {
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
      },
    });
    dispatch(setUserDepositsListSuccess(res.data));
  } catch (error: any) {
    dispatch(setUserDepositsListFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const GetPaymentOrderUrl = (userPaymentId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(changeDepositsLoadingStatus());
    const url = await axiosInstance.get(api.GetPaymentOrderUrl, {
      params: {
        userPaymentId,
      },
    });
    setTimeout(() => {
      dispatch(changeDepositsLoadingStatus());
      window.location.href = url.data;
    }, 500);
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
    dispatch(changeDepositsLoadingStatus());
  }
};

export const GetUsersPaymentsListForUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setUserPaymentsListForUserRequest());
    const res = await axiosInstance.get(api.GetUsersPaymentsListForUser);
    dispatch(setUserPaymentsListForUserSuccess(res.data));
  } catch (error: any) {
    dispatch(setUserPaymentsListForUserFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const createMoneyWithdrawalPaymentAsUser = (AmountOfMoney: number): AppThunk => async (dispatch) => {
  try {
    await axiosInstance.post(
      api.CreateMoneyWithdrawalPaymentAsUser,
      {},
      {
        params: {
          AmountOfMoney,
        },
      },
    );
    showNotification('success', '', i18n.t("payment_withdrawal_money"));
    dispatch(GetUsersPaymentsListForUser());
  } catch (error: any) {
    showNotification('error', i18n.t(error.response.data.errorMessage));
  }
};

export const createDepositPayment = (amount: number, SortOrder: string, Page?: number, PageItemsCount?: number, SortField?: string): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setUserDepositsListRequest());
    await axiosInstance.post(api.CreateDepositPayment, {
      amount,
    });
    const res = await axiosInstance.get(api.GetUserDepositsList, {
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
      },
    });
    dispatch(setUserDepositsListSuccess(res.data));
    showNotification('success', i18n.t('Created'));
  } catch (error: any) {
    dispatch(setUserDepositsListFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getFeeTableData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setFeeTableDataRequest());
    const fineTypes = await axiosInstance.get(api.GetFineTypes);
    const feeTableData = await axiosInstance.get(api.GetFeesByRange);
    const ranges = feeTableData.data.reduce((r: any, a: any) => {
      r[a.feeRangeId] = r[a.feeRangeId] || [];
      r[a.feeRangeId].push(a);
      return r;
    }, Object.create(null));
    const tableData = Object.values(ranges).map((value: any) => {
      const sortedByType = value.sort((a: any, b: any) => a.fineTypeId - b.fineTypeId);
      const newObject: any = {
        range: `${value[0].fromAmount} - ${value[0].toAmount}`,
      };
      sortedByType.forEach((el: any) => {
        if (newObject[el.fineTypeName]) {
          newObject[el.fineTypeName] = `${newObject[el.fineTypeName]} / ${el.fee}`;
        } else {
          newObject[el.fineTypeName] = el.fee;
        }
      });
      return newObject;
    });
    dispatch(setFineTypes(fineTypes.data));
    dispatch(setFeeTableDataSuccess(tableData));
  } catch (error: any) {
    dispatch(setFeeTableDataFailure());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const createVehicleCheckServiceOrder = (
  productId: string,
  electronicReportRequested: boolean,
  photoReportRequested: boolean,
  videoReportRequested: boolean,
  PhoneNumber: string,
  FirstName: string,
  LastName: string,
): AppThunk => async (dispatch) => {
  try {
    await axiosInstance.post(api.CreateVehicleCheckServiceOrder, {
      productId,
      electronicReportRequested,
      photoReportRequested,
      videoReportRequested,
      PhoneNumber,
      FirstName,
      LastName,
    });
    showNotification('success', i18n.t("payment_check_service_text1"), i18n.t("payment_check_service_text2"));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const downloadPdfBase64 = (title: string, apiUrl: string): AppThunk => async (dispatch, state) => {
  try {
    dispatch(changeDepositsLoadingStatus());
    await axios({
      url: `${process.env.REACT_APP_API_URL}api/${apiUrl}`, // your url
      method: 'GET',
      headers: {
        Authorization: `Bearer ${state().auth.user?.access_token}`,
      },
    }).then((response) => {
      const linkSource = `data:application/pdf;base64,${response.data.data}`;
      const downloadLink = document.createElement('a');
      const fileName = `${title}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
    dispatch(changeDepositsLoadingStatus());
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
    dispatch(changeDepositsLoadingStatus());
  }
};

// SELECTORS
export const currentUserBalanceAmountSelector = (state: RootState) => state.payments.currentUserBalanceAmount;
export const userPaymentsDueListSelector = (state: RootState) => state.payments.userPaymentsDueList;
export const userPaymentsDueListTotalCountSelector = (state: RootState) => state.payments.userPaymentsDueListTotalCount;
export const userPaymentsDueListIsLoadingSelector = (state: RootState) => state.payments.userPaymentsDueListIsLoading;
export const usersPaymentsListForUserSelector = (state: RootState) => state.payments.usersPaymentsListForUser;
export const usersPaymentsListForUserTotalCountSelector = (state: RootState) => state.payments.usersPaymentsListForUserTotalCount;
export const usersPaymentsListForUserIsLoadingSelector = (state: RootState) => state.payments.usersPaymentsListForUserIsLoading;
export const userDepositsListSelector = (state: RootState) => state.payments.userDepositsList;
export const userDepositsListTotalCountSelector = (state: RootState) => state.payments.userDepositsListTotalCount;
export const userDepositsListIsLoadingSelector = (state: RootState) => state.payments.userDepositsListIsLoading;
export const paymentsSelector = (state: RootState) => state.payments.payments;
export const sellerFeeSelector = (state: RootState) => state.payments.sellerFee;
export const fineTypesSelector = (state: RootState) => state.payments.fineTypes;

export const feeTableDataSelector = (state: RootState) => state.payments.feeTableData;
export const feeTableDataIsLoadingSelector = (state: RootState) => state.payments.feeTableDataIsLoading;

export default paymentsSlice.reducer;
