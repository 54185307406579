import { Col, Row, Modal, InputNumber } from 'antd';
import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Price from '../../shared/components/Price';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (value: number) => void;
  biddingPower: number;
  depositAmount: number;
}

const AddDepositModal: FC<Props> = ({ isOpen, onCancel, onSubmit, biddingPower, depositAmount }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(0);

  const handleClose = useCallback(() => {
    setValue(0);
    onCancel();
  }, [onCancel]);
  const handleSubmit = useCallback(() => {
    onSubmit(value);
    handleClose();
  }, [value, handleClose, onSubmit]);
  const handleSetValue = useCallback((val: number | string) => {
    setValue(+val);
  }, []);

  return (
    <Modal
      title={t('enlargeYourBiddingPower')}
      visible={!!isOpen}
      centered
      onCancel={handleClose}
      onOk={handleSubmit}
      okText={t("deposit_nextstep")}
      cancelText={t('Close')}
      destroyOnClose
    >
      <Row gutter={[20, 20]} justify="space-between">
        <Col>{t('deposit_max_bidding_power')}:</Col>
        <Col>{biddingPower && <Price price={biddingPower || 0} />}</Col>
      </Row>
      <Row gutter={[20, 20]} justify="space-between">
        <Col>{t('deposit_current_deposit')}:</Col>
        <Col>{depositAmount && <Price price={depositAmount || 0} />}</Col>
      </Row>
      <Row gutter={[20, 20]} justify="space-between">
        <Col>{t("deposit_amount_text")}</Col>
        <Col>
          <InputNumber
            min={0}
            defaultValue={value}
            formatter={(val?: string | number) => `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            parser={(val: any) => val.replace(/\$\s?|(,*)/g, '')}
            onChange={handleSetValue}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(AddDepositModal);
