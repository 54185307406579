import React from 'react';
import i18n from '../../../i18n';

interface ArticleSchemaProps {
  title: string;
  description: string;
  url: string;
  img: string;
  datePublished: string;
  dateModified: string;
}

interface ProductSchemaProps {
  name: string;
  description: string;
  lot: string;
  image: string;
  url: string;
  price: number;
  currency: string;
}

export const OrganizationSchema: React.FC = () => (
  <script type="application/ld+json">
    {JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Lion Trans',
      url: 'https://liontrans.com',
      logo: 'https://liontrans.com/img/logo/lionauction_header.webp?v=1',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '+995 32 255 08 80',
          contactType: 'customer service',
        },
        {
          '@type': 'ContactPoint',
          email: 'info@lionauctions.com',
          contactType: 'customer service',
        },
      ],
      address: {
        '@type': 'PostalAddress',
        streetAddress: '43 str. A.Beliashvili,Georgia, Tbilisi',
        addressLocality: 'Tbilisi',
        addressRegion: 'Tbilisi',
        postalCode: '0108',
        addressCountry: 'Georgia',
      },
    })}
  </script>
);

export const WebsiteSchema: React.FC = () => (
  <script type="application/ld+json">
    {JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'Lion Trans',
      url: 'https://liontrans.com',
      description: i18n.t('About_us_desc_1'),
      publisher: {
        '@type': 'Organization',
        name: 'Lion Trans',
        logo: 'https://liontrans.com/img/logo/lionauction_header.webp?v=1',
      },
    })}
  </script>
);

export const AboutPageSchema: React.FC = () => (
  <script type="application/ld+json">
    {JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'AboutPage',
      name: 'Lion Trans',
      alternateName: 'Lion Auto Auction',
      description: i18n.t('About_us_desc_1'),
      specialty: i18n.t('About_us_desc_2'),
      about: i18n.t('About_us_desc_3'),
      publisher: {
        '@type': 'Organization',
        name: 'Lion Trans',
        logo: 'https://liontrans.com/img/logo/lionauction_header.webp?v=1',
        telephone: '+995 32 255 08 80',
        email: 'info@lionauctions.com',
        sameAs: ['https://facebook.com/lionautoauction', 'https://linkedin.com/company/lion-trans/'],
        url: 'https://liontrans.com',
        image: '/img/banners/main_banner_2_2.webp?v=3',
        description: i18n.t('About_us_desc_4'),
        address: {
          '@type': 'PostalAddress',
          streetAddress: '43 str. A.Beliashvili,Georgia, Tbilisi',
          addressLocality: 'Tbilisi',
          addressRegion: 'Tbilisi',
          postalCode: '0108',
          addressCountry: 'Georgia',
        },
      },
    })}
  </script>
);

export const ContactPageSchema: React.FC = () => (
  <script type="application/ld+json">
    {JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'ContactPage',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': 'https://liontrans.com/',
      },
      publisher: {
        '@type': 'LocalBusiness',
        '@id': 'https://liontrans.com/',
        name: 'Lion Trans',
        logo: 'https://liontrans.com/img/logo/lionauction_header.webp?v=1',
        telephone: '+995 32 255 08 80',
        email: 'info@lionauctions.com',
        sameAs: ['https://facebook.com/lionautoauction', 'https://linkedin.com/company/lion-trans/'],
        url: 'https://liontrans.com',
        image: '/img/banners/main_banner_2_2.webp?v=3',
        description: i18n.t('About_us_desc_4'),
        address: {
          '@type': 'PostalAddress',
          streetAddress: '43 str. A.Beliashvili,Georgia, Tbilisi',
          addressLocality: 'Tbilisi',
          addressRegion: 'Tbilisi',
          postalCode: '0108',
          addressCountry: 'Georgia',
        },
      },
    })}
  </script>
);

export const ArticleSchema: React.FC<ArticleSchemaProps> = ({ title, description, url, img, datePublished, dateModified }) => (
  <script type="application/ld+json">
    {JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': url,
      },
      headline: title,
      datePublished: datePublished,
      dateModified: dateModified,
      author: {
        '@type': 'Person',
        name: 'Lion Trans',
      },
      publisher: {
        '@type': 'Organization',
        name: 'Lion Trans',
        logo: 'https://liontrans.com/img/logo/lionauction_header.webp?v=1',
      },
      description: description,
      image: {
        '@type': 'ImageObject',
        url: img,
        height: 800,
        width: 1200,
      },
    })}
  </script>
);

export const ProductSchema: React.FC<ProductSchemaProps> = ({ name, description, lot, image, url, price, currency }) => (
  <script type="application/ld+json">
    {JSON.stringify({
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: name,
      description: description,
      lot: lot,
      image: image,
      url: url,
      offers: {
        '@type': 'Offer',
        price: price,
        priceCurrency: currency,
      },
    })}
  </script>
);
