type Language = {
  [key: string]: {
    id: number;
    abr: string;
    lng: string;
  };
};

export const abbrToLangMapping: Language = {
  en: {
    id: 1,
    abr: 'en',
    lng: 'English',
  },
  ru: {
    id: 2,
    abr: 'ru',
    lng: 'Russian',
  },
  ka: {
    id: 3,
    abr: 'ka',
    lng: 'Georgian',
  },
};
