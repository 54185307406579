/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { axiosInstance } from '../../core/axios';
import api from '../../core/api';
import { MakeModelsByVehicleType, ProductGeneralDictionariesByLanguage } from '../../interfaces/Dictionary';
import { showNotification } from '../../utils/notify';
import i18n from '../../i18n';

export interface InitState {
  productGeneralDictionariesByLanguage: ProductGeneralDictionariesByLanguage;
  productDefaultGeneralDictionaries: ProductGeneralDictionariesByLanguage;
  makeModelsByVehicleType: MakeModelsByVehicleType[];
  transportInfo: {
    bodyStyle: string;
    cylinders: string;
    drive: string;
    engine: string;
    fuel: string;
    make: string;
    model: string;
    newDataAdded: boolean;
    transmission: string;
    year: string;
  } | null;
}

export const initialState: InitState = {
  productGeneralDictionariesByLanguage: {
    assignmentTypesList: null,
    auctionTypesList: null,
    automobileTypesList: [],
    badgeTypesList: null,
    bodyStyleTypesList: null,
    colorsList: null,
    countriesList: null,
    currencyTypesList: null,
    customsTypesList: null,
    cylinderTypesList: null,
    defectStylesList: [],
    damageTypesList: null,
    drivabilityRatingTypesList: null,
    driveTrainTypesList: null,
    engineTypesList: null,
    fineTypesList: null,
    fuelTypesList: null,
    lossTypesList: null,
    odometerBrandsList: null,
    ownershipDocTypesList: null,
    portsList: null,
    productLocationTypesList: null,
    productSellModelsList: null,
    sellersList: null,
    sourceTypesList: null,
    statusTypesList: null,
    trailerSoldOptionsList: null,
    transmissionTypesList: null,
    vehicleTypesList: null,
    yardsList: null,
  },
  productDefaultGeneralDictionaries: {
    assignmentTypesList: null,
    auctionTypesList: null,
    automobileTypesList: [],
    badgeTypesList: null,
    bodyStyleTypesList: null,
    colorsList: null,
    countriesList: null,
    currencyTypesList: null,
    customsTypesList: null,
    cylinderTypesList: null,
    defectStylesList: [],
    damageTypesList: null,
    drivabilityRatingTypesList: null,
    driveTrainTypesList: null,
    engineTypesList: null,
    fineTypesList: null,
    fuelTypesList: null,
    lossTypesList: null,
    odometerBrandsList: null,
    ownershipDocTypesList: null,
    portsList: null,
    productLocationTypesList: null,
    productSellModelsList: null,
    sellersList: null,
    sourceTypesList: null,
    statusTypesList: null,
    trailerSoldOptionsList: null,
    transmissionTypesList: null,
    vehicleTypesList: null,
    yardsList: null,
  },
  makeModelsByVehicleType: [],
  transportInfo: null,
};

export const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {
    setProductGeneralDictionariesByLanguage: (state, action) => {
      state.productGeneralDictionariesByLanguage = action.payload;
    },
    setDefaultProductGeneralDictionaries: (state, action) => {
      state.productDefaultGeneralDictionaries = action.payload;
    },
    makeModelsByVehicleTypeSuccess: (state, action) => {
      state.makeModelsByVehicleType = action.payload;
    },
    transportInfoRequest: (state) => {
      state.transportInfo = null;
    },
    transportInfoSuccess: (state, action) => {
      state.transportInfo = action.payload;
    },
  },
});

export const { setProductGeneralDictionariesByLanguage,setDefaultProductGeneralDictionaries, makeModelsByVehicleTypeSuccess, transportInfoSuccess, transportInfoRequest } = dictionarySlice.actions;

export const clearTransportInfo = (): AppThunk => async (dispatch) => {
  dispatch(transportInfoSuccess(null));
};

export const getProductGeneralDictionariesByLanguage = (language: 'en' | 'ru' | 'ka'): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstance.get(api.GetProductGeneralDictionariesByLanguage, {
      params: {
        language,
      },
    });
    dispatch(setProductGeneralDictionariesByLanguage(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data?.errorMessage || 'Error');
  }
};

export const getDefaultProductGeneralDictionaries = (): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstance.get(api.GetProductGeneralDictionariesByLanguage, {
      params: {
        language: 'en',
      },
    });
    dispatch(setDefaultProductGeneralDictionaries(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data?.errorMessage || 'Error');
  }
};

export const getMakeModelsByVehicleType = (vehicleType?: number): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstance.get(api.GetMakeModelsByVehicleType, {
      params: {
        vehicleType,
      },
    });
    dispatch(makeModelsByVehicleTypeSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getTransportInfo = (vinNumber: string): AppThunk => async (dispatch) => {
  dispatch(transportInfoRequest());
  try {
    const res = await axiosInstance.get(api.GetTransportInfo, {
      params: {
        vinNumber,
      },
    });
    // res.data.model = ''; // ToDo: This is huck for clear model
    dispatch(transportInfoSuccess(res.data));
  } catch (error: any) {
    showNotification('error', i18n.t(error.response.data.errorMessage));
  }
};

// SELECTORS
export const productGeneralDictionariesByLanguageSelector = (state: RootState) => state.dictionary.productGeneralDictionariesByLanguage;
export const productDefaultGeneralDictionariesSelector = (state: RootState) => state.dictionary.productDefaultGeneralDictionaries;
export const makeModelsByVehicleTypeSelector = (state: RootState) => state.dictionary.makeModelsByVehicleType || [];
export const transportInfoSelector = (state: RootState) => state.dictionary.transportInfo;

export default dictionarySlice.reducer;
