import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

const shine = keyframes`
  to {
    background-position-x: -200%;
  }
`
const loadingCss = css`
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s ${shine} linear infinite;
`

export const SearchCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 0px 50px #E8E8E8;
  padding: 0.75rem;
  border-radius: 8px;
`

interface SearchCardImageProps {
  isLoading?: boolean
}

export const SearchCardImage = styled.div<SearchCardImageProps>`
  width: 6rem;
  aspect-ratio: 1;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  span{
    font-family: 'main';
    font-weight: 600;
    font-size: 0.5rem;
    line-height: 0.875rem;
    position: absolute;
    left: 4px;
    top: 4px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 2px;
    padding: 2px;
  }

  ${p => p.isLoading && loadingCss}
`

export const SearchContentBody = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

interface SearchCardContentProps {
  isLoading?: boolean
}

export const SearchCardContent = styled.div<SearchCardContentProps>`
  h3{
    font-family: 'main';
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #1B153D;
  }
  h4{
    font-family: 'main';
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.875rem;
    color: #1B153D;
  }
  p{
    font-family: 'main';
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.875rem;
    color: #9095A6;
    margin-top: 0.5rem;
  }

  ${p => p.isLoading && css`
      ${loadingCss}
      width: 100%;
      height: 100%;
  `}
`

export const SearchCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
  border-top: 1px solid #EAEAEA;
  padding-top: 0.5rem;
  ul{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0;
    margin: 0;
    li{
      font-family: 'main';
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: #1B153D;
      display: flex;
      align-items: center;
      gap: 0.375rem;
      span{
        font-family: 'main';
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: #CE2424;
      }
    }
  }
  & > div{
    display: flex;
    gap: 0.5rem;
    button{
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 1px solid #CACACA;
      display: flex;
      align-items: center;
      justify-content: center;
      div, svg{
        width: 0.625rem;
        height: 0.625rem;
      }
    } 
  }
`