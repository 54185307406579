import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const CardWrapper = styled(Link)`
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 0px 40px #FBFBFB;
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: 0.2s;
    &:hover{
        background: #FFFFFF;
        border: 1px solid #F5F5F5;
        box-shadow: 0px 0px 50px #DFDFDF;
        border-radius: 8px;
    }
    @media (max-width: 1200px){
        padding: 0.75rem;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
`

export const CardContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.125rem;
    & > span{
        font-family: 'main';
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.25rem;
        letter-spacing: -0.24px;
        color: #CE2424;
    }
    @media (max-width: 1200px){
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1rem;
        span{
            margin-top: auto;
            display: block;
            font-size: 1rem;
            line-height: 0.875rem;
        }
    }
`

export const CardTitleWrapper = styled.div`
    h3{
        font-family: 'main';
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: #1B153D;
    }
    p{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.5rem;
        color: #9095A6;
    }
    @media (max-width: 1200px){
        h3{
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 0.875rem;
            color: #1B153D;
        }
        p{
            font-family: 'main';
            font-weight: 400;
            font-size: 0.625rem;
            line-height: 0.875rem;
            color: #9095A6;
        }
    }
`

interface CardImageWrapperProps {
    imgSize?: number
}

export const CardImageWrapper = styled.div<CardImageWrapperProps>`
    height: 100%;
    display: flex;
    align-items: center;
    img{
        width: 100%;
        object-fit: contain;
        object-position: center;
        height: auto;
    }
    @media (max-width: 1200px){
        width: 7.5rem;
        height: 5rem;
        margin-right: 1rem;
        img{
            width: 100%;
            height: 100%;
        }
    }
`