import React from 'react'
import { MenuContainer, MenuGoup, MenuWrapper } from './style'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { langPrefix } from '../../../utils/functions'

const FindVehicleMenu = () => {
  const {t} = useTranslation()

  const stopBubbling = (e: any) => {
    e.preventDefault()
    e.stopPropagation();
  }
  return (
    <MenuWrapper onClick={stopBubbling}>
      <MenuContainer>

        <MenuGoup>
          <span>{t("find_vehicle_nav_by_condition")}</span>
          <nav>
            <Link to={langPrefix("/classicsearch?defect=New")}>{t("find_vehicle_nav_new")}</Link>
            <Link to={langPrefix("/classicsearch?defect=Used")}>{t("find_vehicle_nav_used")}</Link>
            <Link to={langPrefix("/classicsearch?defect=Damaged")}>{t("find_vehicle_nav_damaged")}</Link>
          </nav>
        </MenuGoup>

        <MenuGoup>
          <span>{t("find_vehicle_nav_by_category")}</span>
          <nav>
            <Link to={langPrefix("/classicsearch?featuredtype=Business+Class")}>{t("find_vehicle_nav_bussiness")}</Link>
            <Link to={langPrefix("/classicsearch?featuredtype=Sport+Car")}>{t("find_vehicle_nav_sport")}</Link>
            <Link to={langPrefix("/classicsearch?featuredtype=Family+Car")}>{t("find_vehicle_nav_family")}</Link>
            <Link to={langPrefix("/classicsearch?featuredtype=Ecology")}>{t("find_vehicle_nav_ecology")}</Link>
            <Link to={langPrefix("/classicsearch?featuredtype=Week+Offer")}>{t("find_vehicle_nav_week")}</Link>
            <Link to={langPrefix("/classicsearch?featuredtype=Run+and+Drive")}>{t("find_vehicle_nav_run_and_drive")}</Link>
            <Link to={langPrefix("/classicsearch?featuredtype=Engine+Start+Program")}>{t("find_vehicle_nav_engine_start")}</Link>
            <Link to={langPrefix("/classicsearch?featuredtype=City+Car")}>{t("find_vehicle_nav_city")}</Link>
            <Link to={langPrefix("/classicsearch?featuredtype=SUV+and+Off+Road")}>{t("find_vehicle_nav_suv_and_off")}</Link>
            <Link to={langPrefix("/classicsearch?featuredtype=Hybrid+Vehicles")}>{t("find_vehicle_nav_hybrid")}</Link>
            <Link to={langPrefix("/classicsearch?featuredtype=Economy+Car")}>{t("find_vehicle_nav_economy")}</Link>
          </nav>
        </MenuGoup>

        <MenuGoup>
          <span>{t("find_vehicle_nav_by_body")}</span>
          <nav>
              <Link to={langPrefix("/classicsearch?bodystyle=Sedan")}>{t("find_vehicle_nav_by_sedan")}</Link>
              <Link to={langPrefix("/classicsearch?bodystyle=Minivan")}>{t("find_vehicle_nav_by_minivan")}</Link>
              <Link to={langPrefix("/classicsearch?bodystyle=Hatchback")}>{t("find_vehicle_nav_by_hatchback")}</Link>
              <Link to={langPrefix("/classicsearch?bodystyle=Jeep")}>{t("find_vehicle_nav_by_jeep")}</Link>
              <Link to={langPrefix("/classicsearch?bodystyle=Universal")}>{t("find_vehicle_nav_by_universal")}</Link>
              <Link to={langPrefix("/classicsearch?bodystyle=Pickup")}>{t("find_vehicle_nav_by_pickup")}</Link>
              <Link to={langPrefix("/classicsearch?bodystyle=Coupe")}>{t("find_vehicle_nav_by_coupe")}</Link>
              <Link to={langPrefix("/classicsearch?bodystyle=Cabriolet")}>{t("find_vehicle_nav_by_cabriolet")}</Link>
              <Link to={langPrefix("/classicsearch?bodystyle=Mini")}>{t("find_vehicle_nav_by_mini")}</Link>
              <Link to={langPrefix("/classicsearch?bodystyle=Microbus")}>{t("find_vehicle_nav_by_microbus")}</Link>
          </nav>
        </MenuGoup>

      </MenuContainer>
    </MenuWrapper>
  )
}

export default FindVehicleMenu