import { Form, Input, Select, Col, Row, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SelectValue } from 'antd/lib/select';
import {
  productDefaultGeneralDictionariesSelector,
  makeModelsByVehicleTypeSelector,
  getTransportInfo,
  transportInfoSelector,
  getMakeModelsByVehicleType,
  getDefaultProductGeneralDictionaries,
  productGeneralDictionariesByLanguageSelector,
} from '../../store/slices/dictionarySlice';
import { AddVehicleItemForm, AddVehicleItemToSend } from '../../interfaces/Product';
import { getYearPeriod } from '../../utils/functions';
import { VinWrapper, NextButton, DecodeBtn, VinNumber, FormInputsWrapper } from './style';

const { Option } = Select;

interface Props {
  nextPrev: (n: number) => void;
  saveFormData: (step: string, values: AddVehicleItemToSend) => void;
  data: {
    vin: string;
    vehicleType: number;
    make: string;
    model: string;
    year: number;
    engine: number;
    fuel: number;
    drive: number;
    cylinders: number;
    transmission: number;
    bodyStyle: number;
  };
  form: any;
}

const { TextArea } = Input;

const VehicleInformationForm: FC<Props> = ({ nextPrev, saveFormData, data, form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productDefaultGeneralDictionaries = useSelector(productDefaultGeneralDictionariesSelector);
  const productGeneralDictionariesByLanguage = useSelector(productGeneralDictionariesByLanguageSelector);
  const makeModelsByVehicleType = useSelector(makeModelsByVehicleTypeSelector);
  const transportInfo = useSelector(transportInfoSelector);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    dispatch(getDefaultProductGeneralDictionaries());
    dispatch(getMakeModelsByVehicleType(2));
  }, [dispatch]);

  useEffect(() => {
    transportInfo &&
      form.setFieldsValue({
        ...transportInfo,
        vin: data?.vin || form.getFieldValue('vin'),
        bodyStyle: productGeneralDictionariesByLanguage.bodyStyleTypesList?.find((item) => item.value === transportInfo.bodyStyle)?.id,
        cylinders: productGeneralDictionariesByLanguage.cylinderTypesList?.find((item) => item.value === transportInfo.cylinders)?.id,
        drive: productGeneralDictionariesByLanguage.driveTrainTypesList?.find((item) => item.value === transportInfo.drive)?.id,
        engine: productGeneralDictionariesByLanguage.engineTypesList?.find((item) => item.value === transportInfo.engine)?.id,
        fuel: productGeneralDictionariesByLanguage.fuelTypesList?.find((item) => item.value === transportInfo.fuel)?.id,
        transmission: productGeneralDictionariesByLanguage.transmissionTypesList?.find((item) => item.value === transportInfo.transmission)?.id,
      });
    transportInfo && setIsDisabled(false);
  }, [transportInfo, form, productGeneralDictionariesByLanguage, data]);

  const onFinish = useCallback(
    (values: AddVehicleItemForm) => {
      saveFormData('vehicleInformation', {
        ...values,
        transmissionTypeId: values.transmission,
        engineTypeId: values.engine,
        fuelTypeId: values.fuel,
        driveTrainTypeId: values.drive,
        bodyStyleTypeId: values.bodyStyle,
        cylinderTypeId: values.cylinders,
      });
      nextPrev(1);
    },
    [saveFormData, nextPrev],
  );

  const handleGetMakeModels = useCallback(
    (value: SelectValue) => {
      value && dispatch(getMakeModelsByVehicleType(+value));
    },
    [dispatch],
  );

  const handleDecodeVin = useCallback(() => {
    dispatch(getTransportInfo(form.getFieldValue('vin')));
  }, [dispatch, form]);

  useEffect(() => {
    if (!data?.vin) form.resetFields();
  }, [data, form]);

  useEffect(() => {
    data &&
      form.setFieldsValue({
        vin: data.vin,
        vehicleType: data.vehicleType,
        make: data.make,
        model: data.model,
        year: data.year,
        engine: data.engine,
        fuel: data.fuel,
        drive: data.drive,
        cylinders: data.cylinders,
        transmission: data.transmission,
        bodyStyle: data.bodyStyle,
      });
  }, [form, productGeneralDictionariesByLanguage, data]);

  // const layout = {
  //   labelCol: { span: 5 },
  //   wrapperCol: { span: 19 },
  // };

  const onChange = useCallback(
    (value) => {
      if (value[0]?.name[0] === 'make') {
        form.setFieldsValue({
          model: undefined,
        });
      }

      if (
        form.isFieldsTouched(['vin', 'make', 'year', 'engine', 'fuel', 'drive', 'cylinders', 'transmission', 'bodyStyle'], true) &&
        !form.getFieldsError().filter(({ errors }) => errors.length).length
      ) {
        setIsDisabled(false);
      }
    },
    [form],
  );

  return (
    <>
      <FormInputsWrapper>
        <VinWrapper>
          <VinNumber name="vin" rules={[{ required: true, message: t("noti_required") }]}>
            <Input placeholder={t('lot_vin')} />
          </VinNumber>
          <DecodeBtn type="primary" onClick={handleDecodeVin}>
            {t('lot_decode')}
          </DecodeBtn>
        </VinWrapper>
        <Form.Item name="vehicleType" rules={[{ required: true, message: t("noti_required") }]}>
          <Select
            placeholder={t('lot_vehicle_type')}
            showSearch
            optionFilterProp="children"
            onChange={handleGetMakeModels}
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {productGeneralDictionariesByLanguage.vehicleTypesList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.lanValue}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="make" rules={[{ required: true, message: t("noti_required") }]}>
          <Select
            placeholder={t('lot_make')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {makeModelsByVehicleType.map((item) => (
              <Option key={item.make} value={item.make}>
                {item.make}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues: AddVehicleItemForm, currentValues: AddVehicleItemForm) => prevValues.make !== currentValues.make}>
          {({ getFieldValue }) => (
            <Form.Item
              name="model"
              // rules={[{ required: !makeModelsByVehicleType.length, message: t("noti_required") }]}
              rules={[{ required: true, message: t("noti_required") }]} // ToDo: This is huck for clear model
            >
              <Select
                placeholder={t('lot_model')}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {makeModelsByVehicleType
                  .find((item) => item.make === getFieldValue('make'))
                  ?.model.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item name="year" rules={[{ required: true, message: t("noti_required") }]}>
          <Select
            placeholder={t('lot_year')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {getYearPeriod().map((item) => (
              <Option key={item.key} value={item.title}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="engine" rules={[{ required: true, message: t("noti_required") }]}>
          <Select
            placeholder={t('lot_engine_type')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {productGeneralDictionariesByLanguage.engineTypesList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="fuel" rules={[{ required: true, message: t("noti_required") }]}>
          <Select
            placeholder={t('lot_fuel_type')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {productGeneralDictionariesByLanguage.fuelTypesList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.lanValue}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="drive" rules={[{ required: true, message: t("noti_required") }]}>
          <Select
            placeholder={t('lot_drive')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {productGeneralDictionariesByLanguage.driveTrainTypesList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.lanValue}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="cylinders" rules={[{ required: true, message: t("noti_required") }]}>
          <Select
            placeholder={t('lot_cylinders')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {productGeneralDictionariesByLanguage.cylinderTypesList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="transmission" rules={[{ required: true, message: t("noti_required") }]}>
          <Select
            placeholder={t('lot_transmission')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {productGeneralDictionariesByLanguage.transmissionTypesList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.lanValue}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="bodyStyle" rules={[{ required: true, message: t("noti_required") }]}>
          <Select
            placeholder={t('lot_body_style')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {productGeneralDictionariesByLanguage.bodyStyleTypesList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.lanValue}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="buyNowPrice" rules={[{ required: true, message: t("noti_required") }]}>
          <InputNumber style={{ width: '100%' }} type='number' placeholder={t("lot_buy_it_now_price")} prefix="$"  />
        </Form.Item>

        <Form.Item name="comments" className='textarea-wrapper'>
          <TextArea placeholder={t('lot_comment')} maxLength={100} />
        </Form.Item>

        {/* <Form.Item shouldUpdate>
          <NextButton type="primary" id="nextBtn" htmlType="submit" disabled={isDisabled}>
            {t('Next')}
          </NextButton>
        </Form.Item> */}
      </FormInputsWrapper>
    </>
  );
};

export default memo(VehicleInformationForm);
