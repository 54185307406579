import React, { FC, memo, useEffect } from 'react';
import { Button, Card, Col, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { dashboardLotsLoadingSelector, dashboardLotsSelector, getDashboardLots } from '../../store/slices/productSlice';
import TableFooter from '../../shared/components/TableFooter';
import { MyLotItem } from '../../interfaces/Product';
import { getLinkForLotPage, langPrefix } from '../../utils/functions';
import DashboardPageLayout from '../../shared/components/DashboardPageLayout';
import Price from '../../shared/components/Price';

const DashboardSellerPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboardLots = useSelector(dashboardLotsSelector);
  const dashboardLotsLoading = useSelector(dashboardLotsLoadingSelector);

  useEffect(() => {
    dispatch(getDashboardLots('seller'));
  }, [dispatch]);

  return (
    <DashboardPageLayout>
      <Row justify="center" gutter={[20, 20]}>
        <Col xs={24} md={12}>
          <Card title={t('nav_user_menu_seller_my_lots_on_approval')}>
            <Table
              loading={dashboardLotsLoading}
              dataSource={dashboardLots.bidApprovalItemsForSellerList.map((item) => ({ ...item, key: item.id }))}
              columns={[
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
                },
                {
                  title: 'Lot#',
                  dataIndex: 'lotNumber',
                  key: 'lotNumber',
                  render: (lotNumber: string, item: MyLotItem) => <Link to={getLinkForLotPage(lotNumber, item.make, item.model, item.year)}>{lotNumber}</Link>,
                },
                {
                  title: t("table_col_location"),
                  dataIndex: 'location',
                  key: 'location',
                },
              ]}
              pagination={false}
              footer={() => (
                <TableFooter total={dashboardLots.bidApprovalItemsForSellerListTotal}>
                  <Link to={langPrefix("/seller/bidapproval")}>
                    <Button size="large" type="button">
                      {t('View_All')}
                    </Button>
                  </Link>
                </TableFooter>
              )}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title={t('nav_user_menu_seller_my_lots_on_sale_list')}>
            <Table
              loading={dashboardLotsLoading}
              dataSource={dashboardLots.lotsOnSaleList.map((item) => ({
                ...item,
                key: item.id,
              }))}
              columns={[
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
                },
                {
                  title: 'Lot#',
                  dataIndex: 'lotNumber',
                  key: 'lotNumber',
                  render: (lotNumber: string, item: MyLotItem) => <Link to={getLinkForLotPage(lotNumber, item.make, item.model, item.year)}>{lotNumber}</Link>,
                },
                {
                  title: t("table_col_location"),
                  dataIndex: 'location',
                  key: 'location',
                },
                {
                  title: 'Status',
                  dataIndex: 'status',
                  key: 'status',
                },
              ]}
              pagination={false}
              footer={() => (
                <TableFooter total={dashboardLots.lotsOnSaleListTotal}>
                  <Link to={langPrefix("/seller/onsalelist")}>
                    <Button size="large" type="button">
                      {t('View_All')}
                    </Button>
                  </Link>
                </TableFooter>
              )}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title={t('nav_user_menu_seller_my_lots_lots_on_check')}>
            <Table
              loading={dashboardLotsLoading}
              dataSource={dashboardLots.lotsOnCheckList.map((item) => ({
                ...item,
                key: item.id,
              }))}
              columns={[
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
                },
                {
                  title: 'Lot#',
                  dataIndex: 'lotNumber',
                  key: 'lotNumber',
                  render: (lotNumber: string, item: MyLotItem) => <Link to={getLinkForLotPage(lotNumber, item.make, item.model, item.year)}>{lotNumber}</Link>,
                },
                {
                  title: t("table_col_location"),
                  dataIndex: 'location',
                  key: 'location',
                },
              ]}
              pagination={false}
              footer={() => (
                <TableFooter total={dashboardLots.lotsOnCheckListTotal}>
                  <Link to={langPrefix("/seller/lotsoncheck")}>
                    <Button size="large" type="button">
                      {t('View_All')}
                    </Button>
                  </Link>
                </TableFooter>
              )}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title={t('nav_user_menu_seller_my_lots_sold_lots')}>
            <Table
              loading={dashboardLotsLoading}
              dataSource={dashboardLots.soldLotsList.map((item) => ({
                ...item,
                key: item.id,
              }))}
              columns={[
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
                },
                {
                  title: 'Lot#',
                  dataIndex: 'lotNumber',
                  key: 'lotNumber',
                  render: (lotNumber: string, item: MyLotItem) => <Link to={getLinkForLotPage(lotNumber, item.make, item.model, item.year)}>{lotNumber}</Link>,
                },
                {
                  title: t("table_col_location"),
                  dataIndex: 'location',
                  key: 'location',
                },
                {
                  title: 'Action',
                  dataIndex: 'actionType',
                  key: 'actionType',
                },
                {
                  title: 'Sale Price',
                  dataIndex: 'salePrice',
                  key: 'salePrice',
                  render: (salePrice: number) => <Price price={salePrice} />,
                },
              ]}
              pagination={false}
              footer={() => (
                <TableFooter total={dashboardLots.soldLotsListTotal}>
                  <Link to={langPrefix("/seller/soldlots")}>
                    <Button size="large" type="button">
                      {t('View_All')}
                    </Button>
                  </Link>
                </TableFooter>
              )}
            />
          </Card>
        </Col>
      </Row>
    </DashboardPageLayout>
  );
};

export default memo(DashboardSellerPage);
