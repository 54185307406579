import styled from 'styled-components';

export const CardTypeCardWrapper = styled.div`
  width: 100%;
  padding-bottom: 109%;
  aspect-ratio: 1.09;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TitleWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 0px 0px 0.5rem 0.5rem;
  background: rgba(0,0,0,0.5);
  height: 100%;
  display: flex;
  align-items: flex-end;
  h3 {
    font-family: 'main';
    &:lang(ka){
      font-family: 'arial-caps';
    }
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #ffffff;
    padding: 1.125rem;
    margin: 0;
  }
  @media(max-width: 767px){
    h3{
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
`;
