import { Row, Col, Skeleton, Image } from 'antd';
import React, { FC, memo } from 'react';
import { ImgContainerCol } from './style';

const IndexSkeleton: FC = () => {
  const layout = {
    xs: 12,
  };

  return (
    <Row justify="center" align="middle">
      <ImgContainerCol xs={8}>
        <Image src="error" fallback="img/noImg.jpg" alt="Lot image" />
      </ImgContainerCol>
      <Col xs={14}>
        <Row gutter={16}>
          <Col {...layout}>
            <Skeleton />
          </Col>
          <Col {...layout}>
            <Skeleton />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...layout}>
            <Skeleton />
          </Col>
          <Col {...layout}>
            <Skeleton />
          </Col>
        </Row>
        <Row gutter={16}>
          <Skeleton />
        </Row>
      </Col>
    </Row>
  );
};

export default memo(IndexSkeleton);
