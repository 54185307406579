import React, { FC, memo } from 'react';
import { Radio, Space } from 'antd';
import { Scrollbars } from 'rc-scrollbars';
import { RadioChangeEvent } from 'antd/lib/radio';
import styled from 'styled-components';

interface Props {
  values: { id: string | number; value: string; title: string }[];
  onChange: (value: RadioChangeEvent) => void;
  height: number;
  name?: string;
  currentValue?: string | null;
  location?: boolean;
}

const SсScrollbars = styled(Scrollbars)`
  height: ${(props: { height: number }) => `${props.height}px !important`};
`;

const SсRadio = styled(Radio)`
  white-space: break-spaces;
  font-family: 'main';
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #1B153D;
  .ant-radio{
    .ant-radio-inner{
      background: #F9FAFA;
      border-color: #D1D5D6;
      &::after{
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #CE2424;
        margin: 0;
      }
    }

    & + span{
      padding-right: 12px;
      padding-left: 12px;
    }

    &.ant-radio-checked{
      .ant-radio-inner{
        border-color: #CE2424;
        &::after{

        }
      }
    }
  }
  input:checked, input:hover{
    ::after{
      background-color: red;
    }
  }
`;

const FilterRadioGroup: FC<Props> = ({ currentValue, values, name, onChange, height, location = false }) => (
  <SсScrollbars height={height}>
    <Radio.Group onChange={onChange} value={currentValue} name={name}>
      <Space direction="vertical">
        {values.map((item) => (
          <SсRadio className='ss' key={location ? item.id : item.value} value={location ? item.id : item.value}>
            {item.title}
          </SсRadio>
        ))}
      </Space>
    </Radio.Group>
  </SсScrollbars>
);

export default memo(FilterRadioGroup);
