import React, { FC, memo, useEffect, useState } from 'react';
import { Row, Col, Typography, Button, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { useCookies } from 'react-cookie';
import { ArrowLeftOutlined, CalendarFilled, CopyOutlined, ShareAltOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { currentBlogSelector, getBlogById, allBlogsSelector, getAllBlogs } from '../../../store/slices/blogSlice';
import { BlogBanner, BlogContainer, BlogContent, DateContainer } from '../style';
import ListBlogItem from './ListBlogItem';
import YoutubeModal from '../../../shared/components/Modals/YoutubeModal';
import BlogImage from '../../../shared/components/Blog/BlogImage';
import { SpaceForShare, CopyToClipboard } from '../../LotPage/style';
import { showNotification } from '../../../utils/notify';
import { langPrefix } from '../../../utils/functions';
import { ArticleSchema } from '../../../shared/components/Schema';
import { disposeEmitNodes } from 'typescript';
import i18n from '../../../i18n';

const { Text } = Typography;

const BlogPage: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [cookies] = useCookies(['language']);
  const { lg, md } = useBreakpoint();

  useEffect(() => {
    dispatch(getBlogById(id));
  }, [dispatch, id, i18n.language]);

  useEffect(() => {
    dispatch(getAllBlogs(cookies.language));
  }, [i18n.language, dispatch]);

  const currentBlog = useSelector(currentBlogSelector);
  const allBlogs = useSelector(allBlogsSelector);

  if(currentBlog?.id == 0) { window.location.href = langPrefix('/'); }
  const shareLink = "https://liontrans.com" + window.location.pathname;

  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [showOpenYoutubeModal, setShowOpenYoutubeModal] = useState<boolean>(false);
  const [showOpenImg, setShowOpenImg] = useState<boolean>(false);

  const handleToggleYoutubeModal = () => {
    setShowOpenYoutubeModal((prev) => !prev);
  };
  const handleToogleImg = () => {
    setShowOpenImg((prev) => !prev);
  };

  const toogleShareModal = () => setOpenShareModal((prev) => !prev);

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(shareLink);
      showNotification('success', 'Copied to clipboard');
      toogleShareModal();
    } catch {
      showNotification('error', 'Something went wrong');
    }
  };
  const blogURL = langPrefix(`/blog/${currentBlog?.id}/${currentBlog?.header}`);

  return (
    <>
      {currentBlog && (
        <>
          <ArticleSchema title={currentBlog.header} description={currentBlog.shortDescription} url={`https://liontrans.com${blogURL}`} img={currentBlog.imageUrl || "https://liontrans.com/img/banners/main_banner_2_2.webp?v=2"} datePublished={currentBlog.creationTime} dateModified={currentBlog.creationTime} />
          <Helmet>
            <title>{currentBlog.header} - Liontrans</title>
            <meta property="og:type" content="blog" />
            <meta property="og:site_name" content="Lion Trans" />
            <meta property="og:updated_time" content={currentBlog.creationTime} />
            <meta property="og:title" content={currentBlog.header} />
          <meta property="og:description" content={currentBlog.shortDescription} />
          <meta property="og:image" content={currentBlog.imageUrl || "https://liontrans.com/img/banners/main_banner_2_2.webp?v=2"} />
            <meta property="og:image:secure_url" content={currentBlog.imageUrl || "https://liontrans.com/img/banners/main_banner_2_2.webp?v=2"} />
            <meta property="og:image:width" content="700" />
            <meta property="og:image:height" content="390" />
            <meta property="og:image:alt" content={currentBlog.header} />
            <meta property="og:image:type" content="image/png" />
            <meta property="article:publisher" content="https://facebook.com/lionautoauction" />
            <meta property="article:section" content="Blog" />
            <meta property="article:published_time" content={currentBlog.creationTime} />
            <meta property="article:modified_time" content={currentBlog.creationTime} />
          </Helmet>
          <BlogImage
            image={id != "57" ? currentBlog.imageUrl : md ? currentBlog.imageUrl : "/img/banners/electric-cars-banner-mobile.webp"}
            isVideo={!!currentBlog.videoUrl}
            height="500px"
            className={id == "57" ? "electric-cars-banner": ""}
            action={currentBlog.videoUrl ? handleToggleYoutubeModal : handleToogleImg}
          >
            <BlogBanner justify="center">
              <Col xs={20}>
                <Row justify="space-between">
                  <Col xs={24} className='back-btn'>
                    <Link to={langPrefix("/blog")}>
                      <Button type="link" icon={<ArrowLeftOutlined />}>
                        Back
                      </Button>
                    </Link>
                  </Col>
                  <Col className='header'>{currentBlog.header}</Col>
                  <Col>
                    <DateContainer className='calendar'>
                      <CalendarFilled />
                      <Text>{dayjs(currentBlog.creationTime).format('D/M/YYYY')}</Text>
                    </DateContainer>
                  </Col>
                </Row>
              </Col>
            </BlogBanner>
          </BlogImage>
          <BlogContainer justify="center">
            <Col xs={23}>
              <Row gutter={[0, 0]}>
                <Col>
                <BlogContent>
                  <Button onClick={toogleShareModal} icon={<ShareAltOutlined />} style={{marginBottom: '1rem'}}>
                    Share
                  </Button>
                  {currentBlog.body && <Text>{parse(currentBlog.body)}</Text>}
                  </BlogContent>
                </Col>
                <Col xs={24}>
                  <ListBlogItem data={allBlogs} />
                </Col>
              </Row>
            </Col>
          </BlogContainer>
        </>
      )}
      {!!showOpenYoutubeModal && (
        <YoutubeModal
          videoId={currentBlog?.videoUrl?.split('v=')[1] || ''}
          visible={showOpenYoutubeModal}
          onClose={handleToggleYoutubeModal}
          width={800}
          autoplay
        />
      )}
      {!!showOpenImg && (
        <Modal visible={!!showOpenImg} onCancel={handleToogleImg} footer={null} zIndex={10000} width="100%" title={currentBlog?.header}>
          <img alt="Article" src={currentBlog?.imageUrl || ''} width="100%" />
        </Modal>
      )}
      {!!openShareModal && (
        <Modal title={shareLink} visible={!!openShareModal} centered footer={null} onCancel={toogleShareModal}>
          <SpaceForShare>
            <FacebookShareButton url={shareLink}>
              <FacebookIcon size="64px" />
            </FacebookShareButton>
            <TwitterShareButton url={shareLink}>
              <TwitterIcon size="64px" />
            </TwitterShareButton>
            <ViberShareButton url={shareLink}>
              <ViberIcon size="64px" />
            </ViberShareButton>
            <WhatsappShareButton url={shareLink}>
              <WhatsappIcon size="64px" />
            </WhatsappShareButton>
            <CopyToClipboard type="default" icon={<CopyOutlined />} onClick={copyToClipboard} />
          </SpaceForShare>
        </Modal>
      )}
    </>
  );
};

export default memo(BlogPage);
