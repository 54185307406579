import styled from 'styled-components';
import { Wrapper } from '../../shared/components/ContentWrapper';
import { White, LightShadow } from '../../shared/shared-styles';

export const ContentWrapperCardStyle = styled(Wrapper)`
width: 95%;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px ${LightShadow};
  border-radius: 8px;
  .ant-form-item-control{
    max-width: 100%;
  }
  @media(max-width: 767px){
    margin: 0.75rem auto 0;
    width: 95%;
  }
`;

export const ImgWrapper = styled.div<{ url: string; text: string }>`
  font-size: 16px;
  color: ${White};
  font-weight: 900;
  width: 100%;
  min-height: 1000px;
  height: 100%;
  background-image: ${(props) => `url(${props.url})`};
  background-size: cover;
  background-position: center;
  border-radius: 8px 0 0 8px;
  &:before {
    position: absolute;
    left: 40px;
    top: 40px;
    content: '${(props) => props.text}';
  }
`;

export const FormWrapper = styled.div`
  padding: 32px 24px;
  .ant-form-item {
    margin-bottom: 0.75rem;
  }

`;

export const StepShow = styled.div`
  display: ${(props: { hidden: boolean }) => props.hidden && 'none'};
`;

export const UploadWrapper = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
`;
