import styled from "styled-components";

export const SearchWrapper = styled.div`
    background: #F9FAFA;
    border: 1px solid #E1E4E5;
    border-radius: 4px;
    padding: 0.125rem 0.375rem;
    display: flex;
    align-items: center;
    gap: 0.375rem;
    width: 100%;
    .svg-wrapper{
        width: 1.125rem;
        height: 1.125rem;
        svg{
            width: 100%;
            height: 100%;
        }
        path{
            stroke: #A6ABAD;
        }
    }
    input{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #A6ABAD;
        width: 100%;
        border: none;
        background-color: transparent;
        &:focus{
            outline: none;
        }
    }
`