import React from 'react'
import { useTranslation } from 'react-i18next'
import LotCardDescription, { LotCardRow, LotCardText } from '../../../shared/components/LotDescriptionCard'

const LightingCard = ({vehicle}: any) => {

  const { t } = useTranslation()

  return (
    <LotCardDescription title={t("electric_description_lighting")}>
        <>
            <LotCardRow>
                <LotCardText>{t("forLights")}</LotCardText>
                <LotCardText>{vehicle?.lighting?.forLights}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("low_beam_lights")}</LotCardText>
                <LotCardText>{vehicle?.lighting?.lowBeamLights}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("high_beam_lights")}</LotCardText>
                <LotCardText>{vehicle?.lighting?.highBeamLights}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("adaptive_headlights")}</LotCardText>
                <LotCardText>{vehicle?.lighting?.adaptiveHeadlights ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("led_daytime_light")}</LotCardText>
                <LotCardText>{vehicle?.lighting?.ledDaytimeLight ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
        </>
    </LotCardDescription>
  )
}

export default LightingCard