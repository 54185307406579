import React from 'react'
import { useTranslation } from 'react-i18next'
import LotCardDescription, { LotCardRow, LotCardText } from '../../../shared/components/LotDescriptionCard'

const MultimediaCard = ({vehicle}: any) => {

  const { t } = useTranslation()

  return (
    <LotCardDescription title={t("electric_description_multimedia")}>
        <>
            <LotCardRow>
                <LotCardText>{t("bluetooth")}</LotCardText>
                <LotCardText>{vehicle?.multiMedia?.bluetooth ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("LCD_display_panel")}</LotCardText>
                <LotCardText>{vehicle?.multiMedia?.lcdDisplayPanel ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("external_audio_interface")}</LotCardText>
                <LotCardText>{vehicle?.multiMedia?.externalAudioInterface ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("navigation")}</LotCardText>
                <LotCardText>{vehicle?.multiMedia?.navigation ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("voice_control")}</LotCardText>
                <LotCardText>{vehicle?.multiMedia?.voiceControl ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("LCD_touch_screen")}</LotCardText>
                <LotCardText>{vehicle?.multiMedia?.lcdTouchScreen ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
        </>
    </LotCardDescription>
  )
}

export default MultimediaCard