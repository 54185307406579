import { Card } from 'antd';
import React, { FC, memo } from 'react';
import { ServiceOrder } from '../../../interfaces/Product';

interface Props {
  serviceOrder: ServiceOrder[];
}

const ServiceOrderList: FC<Props> = ({ serviceOrder }) => <Card title="ServiceOrder" />;

export default memo(ServiceOrderList);
