import React, {MouseEvent} from 'react'
import { ActionButton } from './style'
import { HeartFilled } from '@ant-design/icons'
import useWatchList from '../../../hooks/useWatchList'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../store/slices/authSlice'
import { userWatchListSelector } from '../../../store/slices/productSlice'

const WatchlistButton = ({onClick, id}: any) => {
  const { handleChangeWatchList  } = useWatchList()
  const user = useSelector(userSelector);
  const userWatchList = useSelector(userWatchListSelector);

  const onActionButtonClick = (e: MouseEvent<HTMLElement>) => {
    handleChangeWatchList(id)
    onClick && onClick(e)
  }

  const isActive =  userWatchList.some(productId => productId == id)

  
  if(user?.id_token){
    return (
      <ActionButton onClick={onActionButtonClick} isActive={isActive} >
        <HeartFilled />
      </ActionButton>
    )
  }else{
    return null
  }
}

export default WatchlistButton