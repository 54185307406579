import React from 'react';
import { ContactInfoMobile, ContactInfoSocial, ContactInfoWrapper } from './style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

const ContactInfo = () => {
  const { t } = useTranslation();
  return (
    <ContactInfoWrapper>
      <ContactInfoMobile>
      <div className="location">
          <h4>{t("contact_footer_ge")}</h4>
          <p>
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.13193 12.3449C1.23486 13.6418 2.17972 14.7035 3.39961 14.7972C5.17107 14.9331 6.82892 14.9331 8.60038 14.7972C9.82029 14.7035 10.7652 13.6418 10.868 12.3449C11.0997 9.42637 11.0997 6.57337 10.868 3.65477C10.7652 2.35791 9.82029 1.29617 8.60039 1.20255C6.82892 1.0666 5.17107 1.0666 3.3996 1.20255C2.17972 1.29617 1.23486 2.35791 1.13193 3.65477C0.900297 6.57337 0.900297 9.42637 1.13193 12.3449Z"
                stroke="#141719"
                stroke-width="1.71"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M5.04326 12.2192H6.95736" stroke="#141719" stroke-width="1.71" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            (+995 32) 255 08 80
          </p>
        </div>
        <div className="location">
          <h4>{t("contact_footer_us")}</h4>
          <p>
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.13193 12.3449C1.23486 13.6418 2.17972 14.7035 3.39961 14.7972C5.17107 14.9331 6.82892 14.9331 8.60038 14.7972C9.82029 14.7035 10.7652 13.6418 10.868 12.3449C11.0997 9.42637 11.0997 6.57337 10.868 3.65477C10.7652 2.35791 9.82029 1.29617 8.60039 1.20255C6.82892 1.0666 5.17107 1.0666 3.3996 1.20255C2.17972 1.29617 1.23486 2.35791 1.13193 3.65477C0.900297 6.57337 0.900297 9.42637 1.13193 12.3449Z"
                stroke="#141719"
                stroke-width="1.71"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M5.04326 12.2192H6.95736" stroke="#141719" stroke-width="1.71" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            +1 (908) 583 6911
          </p>
        </div>
        <div className="location">
          <h4>{t("contact_footer_eu")}</h4>
          <p>
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.13193 12.3449C1.23486 13.6418 2.17972 14.7035 3.39961 14.7972C5.17107 14.9331 6.82892 14.9331 8.60038 14.7972C9.82029 14.7035 10.7652 13.6418 10.868 12.3449C11.0997 9.42637 11.0997 6.57337 10.868 3.65477C10.7652 2.35791 9.82029 1.29617 8.60039 1.20255C6.82892 1.0666 5.17107 1.0666 3.3996 1.20255C2.17972 1.29617 1.23486 2.35791 1.13193 3.65477C0.900297 6.57337 0.900297 9.42637 1.13193 12.3449Z"
                stroke="#141719"
                stroke-width="1.71"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M5.04326 12.2192H6.95736" stroke="#141719" stroke-width="1.71" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            +1 (562) 470 6990
          </p>
        </div>
        <div className="location">
          <h4>{t("contact_footer_ukr")}</h4>
          <p>
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.13193 12.3449C1.23486 13.6418 2.17972 14.7035 3.39961 14.7972C5.17107 14.9331 6.82892 14.9331 8.60038 14.7972C9.82029 14.7035 10.7652 13.6418 10.868 12.3449C11.0997 9.42637 11.0997 6.57337 10.868 3.65477C10.7652 2.35791 9.82029 1.29617 8.60039 1.20255C6.82892 1.0666 5.17107 1.0666 3.3996 1.20255C2.17972 1.29617 1.23486 2.35791 1.13193 3.65477C0.900297 6.57337 0.900297 9.42637 1.13193 12.3449Z"
                stroke="#141719"
                stroke-width="1.71"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M5.04326 12.2192H6.95736" stroke="#141719" stroke-width="1.71" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            +380 (66) 480 0133
          </p>
        </div>
      </ContactInfoMobile>
      <ContactInfoSocial>
        <div className="social">
          <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.73096 2.91362C3.17887 4.31328 4.68699 5.60949 6.34453 6.75202C7.34329 7.44045 8.65685 7.44044 9.6556 6.752C11.3132 5.60945 12.8213 4.31324 14.2692 2.91357"
              stroke="#141719"
              stroke-width="1.71"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.39262 1.35733C3.61607 1.43152 2.89212 1.72617 2.33882 2.19326C1.78553 2.66033 1.43553 3.27227 1.34589 3.9293C1.21343 4.92894 1.10083 5.9538 1.10083 7.00016C1.10083 8.04651 1.21343 9.07231 1.34589 10.071C1.43553 10.728 1.78553 11.34 2.33882 11.807C2.89212 12.2742 3.61607 12.5688 4.39262 12.643C5.56605 12.7495 6.77149 12.8392 8.00012 12.8392C9.22874 12.8392 10.4342 12.7495 11.6088 12.643C12.3853 12.5688 13.1092 12.2742 13.6625 11.807C14.2158 11.34 14.5658 10.728 14.6555 10.071C14.7857 9.07138 14.8994 8.04651 14.8994 7.00016C14.8994 5.9538 14.7868 4.928 14.6543 3.9293C14.5647 3.27227 14.2147 2.66033 13.6615 2.19326C13.1081 1.72617 12.3841 1.43152 11.6076 1.35733C10.4353 1.25082 9.22984 1.16113 8.00012 1.16113C6.77039 1.16113 5.56605 1.25082 4.39262 1.35733Z"
              stroke="#141719"
              stroke-width="1.71"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>info@lionauctions.com</p>
        </div>
        <div className="social">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.99992 14.6668H9.99992C13.3333 14.6668 14.6666 13.3335 14.6666 10.0002V6.00016C14.6666 2.66683 13.3333 1.3335 9.99992 1.3335H5.99992C2.66659 1.3335 1.33325 2.66683 1.33325 6.00016V10.0002C1.33325 13.3335 2.66659 14.6668 5.99992 14.6668Z"
              stroke="#141719"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.00008 10.3332C9.28875 10.3332 10.3334 9.2885 10.3334 7.99984C10.3334 6.71117 9.28875 5.6665 8.00008 5.6665C6.71142 5.6665 5.66675 6.71117 5.66675 7.99984C5.66675 9.2885 6.71142 10.3332 8.00008 10.3332Z"
              stroke="#141719"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M11.7573 4.66683H11.765" stroke="#141719" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <a href='https://www.instagram.com/lionautoauction/' target="_blank" rel="nofollow noopener noreferrer">instagram</a>
        </div>
        <div className="social">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.19339 14V7.61845H7.55064L7.90499 5.1302H5.19339V3.5421C5.19339 2.8215 5.41293 2.33178 6.55047 2.33178H8V0.105876C7.29844 0.0388271 6.59341 0.00613158 5.88798 0.00793187C5.40789 -0.0238535 4.92596 0.0401403 4.476 0.195424C4.02603 0.350708 3.61892 0.593522 3.28322 0.906832C2.94753 1.22014 2.69138 1.59636 2.53275 2.0091C2.37412 2.42184 2.31685 2.8611 2.36495 3.29607V5.1302H0V7.61845H2.36495V14H5.19339Z"
              fill="#141719"
            />
          </svg>
          <a href='https://www.facebook.com/lionautoauction' target="_blank" rel="nofollow noopener noreferrer">Facebook</a>
        </div>
      </ContactInfoSocial>
    </ContactInfoWrapper>
  );
};

export default ContactInfo;
