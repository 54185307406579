import React, { useEffect, useState } from 'react';
import { Rate } from 'antd';
import {
  ContactInformationItem,
  ContactInformationWrapper,
  DealerImageWrapper,
  DealerInfoWrapper,
  DealerStarRateWrapper,
  DealerWrapper,
  Head,
  InformationWrapper,
  RateByStarWrapper,
  SkillItem,
  SkillList,
  Title,
  VideoWrapper,
} from './style';
import { ReactSVG } from 'react-svg';
import ReviewSection from '../../shared/components/ReviewSection'; 
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { StarFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addDealerReviews, currentDealerSelector, dealerListSelector, dealerTokenSelector, getDealerDetailsById, getDealerRate, getDealerReviews, getDealers } from '../../store/slices/dealerSlice';
import { useParams } from 'react-router-dom';
import DealerSearch, { hideDealers } from '../../shared/components/MainSearch/DealerSearch';
import { useCookies } from 'react-cookie';
import i18n from '../../i18n';

const DealerProfileImage = ({src, alt}: {src?: string, alt: string}) => {
  return (
    <DealerImageWrapper>
      {src 
      ? <img src={`${process.env.REACT_APP_DEALER_IMAGE}${src}`} alt={alt} /> 
      : <img src={`/img/person.png`} alt={alt} />}
    </DealerImageWrapper>
  )
}


const DealerProfile = () => {
  const [cookies] = useCookies(['language']);
  const { md } = useBreakpoint()
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>();

  const dealerList = useSelector(dealerListSelector)
  const [dealer, setDealer] = useState<any>(null)
  const [reviews, setReviews] = useState<any[]>([])
  const [rate, setRate] = useState(0)

  const getDealerReview = async (dealer: any) => {
    if(!dealer) return
    const dealerRate: any = await dispatch(getDealerRate(dealer.dealerId))
    setRate(dealerRate)

    const reviewsList: any = await dispatch(getDealerReviews(dealer.dealerId))

    setReviews(reviewsList)
    
  }

  const onAddReview = async (values: any) => {
    const data = {
      dealerId: dealer.dealerId,
      ...values,
    }
    await dispatch(addDealerReviews(data))
    getDealerReview(dealer)
  }

  useEffect(() => {
    dispatch(getDealers())
  }, [dispatch])

  useEffect(() => {
    const dealers = dealerList.filter((dealer) => dealer?.dealerName.toLocaleLowerCase() == id.toLocaleLowerCase() )
    const currDealer = dealers[0]
    setReviews([])
    if(currDealer && !hideDealers.some(id => currDealer.dealerId == id) ){
      setDealer(currDealer)
      getDealerReview(currDealer)
    }
    
  }, [id, dealerList])

  return (
    <DealerWrapper>
      <DealerSearch />
        {
          dealer 
          ? <>
              {!md ? <DealerProfileImage src={dealer?.image} alt={dealer?.dealerName} /> : false} 
              <Head>
              <Title>
                  {i18n.language === "ka" && dealer?.dealerNameGeo
                    ? dealer?.dealerNameGeo
                    : i18n.language === "ru" && dealer?.dealerNameRus
                      ? dealer?.dealerNameRus
                      : dealer?.dealerName}
                </Title>

                  {md 
                  ? <RateByStarWrapper>
                    <Rate defaultValue={rate} disabled key={rate}/> 
                  </RateByStarWrapper>
                  : <DealerStarRateWrapper>
                    {rate}
                    <StarFilled />
                  </DealerStarRateWrapper>}
              </Head>
              <DealerInfoWrapper>
                {md && <DealerProfileImage src={dealer?.image} alt={dealer?.dealerName} />}

                <InformationWrapper>
                  <p>
                    {i18n.language === "ka" && dealer?.descriptionGeo
                      ? dealer?.descriptionGeo
                      : i18n.language === "ru" && dealer?.descriptionRus
                        ? dealer?.descriptionRus
                        : i18n.language === "en" && dealer?.descriptionEng
                          ? dealer?.descriptionEng
                          : dealer?.description}
                  </p>
                  <ContactInformationWrapper>
                    {dealer?.emailAddress && <ContactInformationItem>
                      <ReactSVG src="/icons/sms-stroke.svg" className="svg-wrapper" />
                      {dealer?.emailAddress}
                    </ContactInformationItem>}
                    {dealer?.phone && <ContactInformationItem>
                      <ReactSVG src="/icons/call-stroke.svg" className="svg-wrapper" />
                      {dealer?.phone}
                    </ContactInformationItem>}
                    <ContactInformationItem>
                      <ReactSVG src="/icons/location.svg" className="svg-wrapper location-wrapper" />
                      {dealer?.country}, {dealer?.city}
                    </ContactInformationItem>
                  </ContactInformationWrapper>
                  {/* <SkillList className='ssss'>
                    {dealer?.secondSkill && <SkillItem>{dealer?.secondSkill}</SkillItem>}
                    {dealer?.thirdSkill && <SkillItem>{dealer?.thirdSkill}</SkillItem>}
                  </SkillList> */}
                </InformationWrapper>
              </DealerInfoWrapper>
              {dealer?.videoURL && <VideoWrapper>
                <iframe width="100%" height="100%" src={dealer?.videoURL} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
              </VideoWrapper>}
              <ReviewSection reviews={reviews} onAddReview={onAddReview}/>
          </>
          : false
        }
    </DealerWrapper>
  );
};

export default DealerProfile;
