import React from 'react'
import { CardWrapper, ContentWrapper, HeaderWrapper, ImageWrapper, InfoWrapper, TitleWrapper } from './style'
import Price from '../../../shared/components/Price'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { langPrefix } from '../../../utils/functions'

const ElectricCarsCard = ({vehicle}: any) => {
    const {t} = useTranslation()
    
  return (
    <CardWrapper to={langPrefix(`/electric-cars-detaile/${vehicle.id}`)}>
        <HeaderWrapper>
            <TitleWrapper>
                <h3>{vehicle.title}</h3>
                <p>Georgia (Tbilisi Show Room)</p>
            </TitleWrapper>
            <Price price={vehicle.price} />
        </HeaderWrapper>
        <ImageWrapper>
            <img src={vehicle.mainImage} alt={vehicle.title} />
        </ImageWrapper>
        <ContentWrapper>
            
            <InfoWrapper>
                <div>
                    <span>
                        <strong>{vehicle?.main?.range}</strong>
                    </span>
                    <span>{t("range")}</span>
                </div>
                <div>
                    <span>
                        <strong>{vehicle?.engine?.maxSpeed}</strong>
                    </span>
                    <span>{t("maximum_speed")}</span>
                </div>
                {vehicle?.engine?.acceleration && <div>
                    <span>
                        <strong>{vehicle?.engine?.acceleration}</strong>
                    </span>
                    <span>{t("acceleration")}</span>
                </div>}
            </InfoWrapper>

            <ul>
                <li>
                    {t("battery_capacity")}
                    <strong>{vehicle?.main?.battaryCapacity}</strong>
                </li>
                <li>
                    {t("wheel_drive")}
                    <strong>{vehicle?.main?.wheelDrive}</strong>
                </li>
                <li>
                    {t("engine_power")}
                    <strong>{vehicle?.main?.enginePower}</strong>
                </li>
                <li>
                    {t("fast_charging")}
                    <strong>{vehicle?.engine?.fastCharging ? t("yes"): t("no")}</strong>
                </li>
                <li>
                    {t("type")}
                    <strong>{vehicle?.vehicleBody?.type}</strong>
                </li>
                <li>
                    {t("doorSeats")}
                    <strong>{vehicle?.vehicleBody?.doorSeats}</strong>
                </li>
            </ul>

            <Price price={vehicle.price} />

            <Link to={langPrefix(`/electric-cars-detaile/${vehicle.id}`)}>{t("lot_service_order_btn")}</Link>
        </ContentWrapper>
    </CardWrapper>
  )
}

export default ElectricCarsCard