import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { AlertList, ContactItem, ContactList, ContactListGroup, ContactWrapper, InfoWrapper, TrackingAside, TrackingWrapper } from './style';
import Alert, { AlertTypes } from '../../shared/components/Alert';
import ApiForSite from './ApiForSite';
import SubscribeSms from './SubscribeSms';
import TrackingInformation from './TrackingInformation';
import Gallery from './Gallery';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ExecuteSearchQueryByVinCode, SearchByVinNumber, currentVehicleByVinSelector } from '../../store/slices/findVehiclesSlice';
import { isVincodeSearchAutorizedSelector } from '../../store/slices/initSlice';
import { isAuthenticatedSelector } from '../../store/slices/authSlice';
import TrackButton from './TrackButton';
import ContactForm from '../../shared/components/ContactForm';
import Map from '../../pages/ContactPage';
import { ReactSVG } from 'react-svg';
const Tracking = () => {
  const { vin } = useParams<{ vin: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contactList = [
      {
          "mobile": "(+ 995 32) 255 08 80",
          "location": "43 str. A.Beliashvili,Georgia, Tbilisi",
          "email": "info@lionauctions.com",
          "map": "https://maps.app.goo.gl/2U6NXCwkokWuyKkN8"
      },
      {
          "mobile": "(+ 995 32) 255 08 80",
          "location": "3 Kokaia Alley, Poti 4400 Georgia, Poti",
          "email": "info@lionauctions.com",
          "map": "https://maps.app.goo.gl/mDW8YjC277n5ZyTH8"
      },
      {
          "mobile": "+1 (562) 470 6990",
          "location": "USA, CA, 18221 S Susana Rd, Unit 2 Compton",
          "email": "info@lionauctions.com",
          "map": "https://maps.app.goo.gl/7qGNunGQMydVUcMq9"
      },
      {
          "mobile": "+1 (908) 583 6911",
          "location": "USA, NJ, 1250W Elizabeth Ave, Linden, NJ 07036",
          "email": "info@lionauctions.com",
          "map": "https://maps.app.goo.gl/3GLeLuxpFkW51nsP9"
      }
  ];
  interface contactListItem {
    mobile: string;
    location: string;
    email: string;
    map: string;
  }
  const currentVehicleByVinWithoutCheck = useSelector(currentVehicleByVinSelector);
  const [currentVehicleByVin, setCurrentVehicleByVin] = useState<any>(null);
  const isVincodeSearchAutorized = useSelector(isVincodeSearchAutorizedSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const getParamValue = useCallback((field: string) => params.get(field)?.replace(/%2C/g, ',') || undefined, [params]);

  const searchVin = async () => {
    const data: any = await dispatch(ExecuteSearchQueryByVinCode(getParamValue('queryId'), getParamValue('otpCode')));
    if (typeof data == 'object') {
      setCurrentVehicleByVin(data.resultData.queryResultData);
    }
  };

  useEffect(() => {
    setCurrentVehicleByVin(currentVehicleByVinWithoutCheck);
  }, [currentVehicleByVinWithoutCheck]);

  useEffect(() => {
    // if(vin == "JTHKD5BH3D2130702") return
    if (isVincodeSearchAutorized || isAuthenticated) {
      dispatch(SearchByVinNumber(vin));
    } else {
      searchVin();
    }
  }, [vin, location.search, dispatch, isAuthenticated]);

  return (
    <>
      <TrackingWrapper>
        {currentVehicleByVin && currentVehicleByVin.id === 0 ? (
          <>
            <InfoWrapper>
              <AlertList>
                <Alert type={AlertTypes.INFO} text={t('track_notfound')} />
              </AlertList>
              <ContactListGroup>
                {contactList.map((item: contactListItem, index: number) => (
                  <ContactList key={index}>
                    <ContactItem as={'a'} href={`mailto:${item.email}`}>
                      <ReactSVG src="/icons/sms.svg" className="svg-wrapper" />
                      {item.email}
                    </ContactItem>
                    <ContactItem>
                      <ReactSVG src="/icons/call.svg" className="svg-wrapper" />
                      {item.mobile}
                    </ContactItem>
                    <ContactItem as={'a'} href={item.map} target="_blank" rel="nofollow noopener noreferrer">
                      <ReactSVG src="/icons/location.svg" className="svg-wrapper" />
                      {t(item.location)}
                    </ContactItem>
                  </ContactList>
                ))}
              </ContactListGroup>
            </InfoWrapper>
            <ContactWrapper>
              <ContactForm />
            </ContactWrapper>
          </>
        ) : (
          currentVehicleByVin && (
            <>
              <Gallery photos={currentVehicleByVin.photos} currentVehicleByVin={currentVehicleByVin} />
              <TrackingAside>
                <TrackingInformation vehicle={currentVehicleByVin} />
                <TrackButton />
                {/* <SubscribeSms /> */}
                {/* <ApiForSite /> */}
                <iframe
                  src={`https://api1.ezparts.ge/transfer_data/main_iframe/${currentVehicleByVin.vin || vin}/container/`}
                  width="100%"
                  frameBorder={0}
                  className="iframe"
                ></iframe>
              </TrackingAside>
            </>
          )
        )}
      </TrackingWrapper>

      {/* {currentVehicleByVin && <iframe src={`https://lion-trans.com/checkcontainer?container=${currentVehicleByVin?.containerNumber || ""}`} width="100%" height={900} frameBorder={0}></iframe>} */}
    </>
  );
};

export default Tracking;
