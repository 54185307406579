import { Col, Row, Calendar, Button, Modal, Table, Space } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  auctionDetailsForCalendarSelector,
  calendarDashboardLanesListSelector,
  getAuctionDetailsForCalendar,
  getCalendarDashboardLanesList,
  setAuctionDetailsForCalendarSuccess,
  getAuctionDashboardLotsList,
  auctionPanelsSelector,
} from '../../store/slices/auctionSlice';
import { CalendarDashboardLane, LaneActivationPeriod } from '../../interfaces/Auction';
import AuctionDetailsModal from '../../shared/components/AuctionDetailsModal';
import { ScList } from './style';
import { ErrorBgCl } from '../../shared/shared-styles';
import { ScTitle } from '../style';
import ContentWrapper from '../../shared/components/ContentWrapper';
import TimerButton from '../../shared/components/AuctionsTodayTable/TimerButton';

const AuctionCalendarPage: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { lg } = useBreakpoint();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);

  const [isModalVisible, setIsModalVisible] = useState<number | false>(false);
  const [currentAuction, setCurrentAuction] = useState<CalendarDashboardLane | null>(null);

  useEffect(() => {
    dispatch(getCalendarDashboardLanesList());
  }, [dispatch]);

  const calendarDashboardLanesList = useSelector(calendarDashboardLanesListSelector);
  const auctionDetailsForCalendar = useSelector(auctionDetailsForCalendarSelector);
  const auctionPanels = useSelector(auctionPanelsSelector);

  const getListData = useCallback(
    (value: moment.Moment) => {
      const array = calendarDashboardLanesList.filter((auction: CalendarDashboardLane) =>
        auction.laneActivationPeriods.find((date: LaneActivationPeriod) => date.startTime.split('T')[0] === value.format('YYYY-MM-DD')),
      );
      return array || [];
    },
    [calendarDashboardLanesList],
  );

  const handleClickAuctionItem = useCallback(
    (auction: CalendarDashboardLane) => () => {
      setIsModalVisible(auction.auctionId);
      setCurrentAuction(auction);
      dispatch(getAuctionDetailsForCalendar(auction.auctionId));
    },
    [dispatch],
  );

  const auctionItemRender = (auction: CalendarDashboardLane, date: LaneActivationPeriod, onClick: () => void) => (
    <li key={auction.laneId}>
      <Button size="large" type="default" onClick={onClick}>
        {`${auction.country} ${auction.city} - ${dayjs(date.startTime).format('HH:mm')}`}
      </Button>
    </li>
  );

  const dateCellRender = (value: moment.Moment) => {
    const listData = getListData(value);
    if (!lg) return null;
    return (
      <ScList className="events">
        {listData.map((auction: CalendarDashboardLane) =>
          auction.laneActivationPeriods
            .filter((item) => new Date(item.startTime).setHours(0, 0, 0, 0) === new Date(value.toDate()).setHours(0, 0, 0, 0))
            .map((date: LaneActivationPeriod) => date.dayOfWeek === value.day() && auctionItemRender(auction, date, handleClickAuctionItem(auction))),
        )}
      </ScList>
    );
  };

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    dispatch(setAuctionDetailsForCalendarSuccess(null));
  }, [dispatch]);

  const handleViewSaleList = useCallback(() => {
    currentAuction &&
      history.push({
        pathname: '/auctionsalelist',
        search: `?laneids=${currentAuction.laneId}`,
      });
    setIsModalVisible(false);
    dispatch(setAuctionDetailsForCalendarSuccess(null));
  }, [dispatch, currentAuction, history]);

  useEffect(() => {
    if (!lg) {
      const dates: string[] = calendarDashboardLanesList.map((item) => item.laneActivationPeriods.map((i) => i.startTime.split('T')[0])).flat();
      const dAr = Array.from(new Set(dates)) || [];
      dAr.forEach((item) => {
        const days = document.getElementsByTagName('td');
        Array.from(days).forEach((element) => {
          if (element.getAttribute('title') === item) {
            element.style.backgroundColor = ErrorBgCl;
          }
        });
      });
    } else {
      const days = document.getElementsByTagName('td');
      Array.from(days).forEach((element) => {
        element.style.backgroundColor = 'transparent';
      });
    }
  }, [calendarDashboardLanesList, lg]);

  const [auctions, setAuctions] = useState<CalendarDashboardLane[]>([]);
  const [weekDay, setWeekDay] = useState<number | undefined>();

  const handleSelect = useCallback(
    (data) => {
      const lines = calendarDashboardLanesList.filter((item) => {
        const linePeriod = item.laneActivationPeriods.find((i) => i.startTime.includes(data.format('YYYY-MM-DD')));
        return linePeriod || false;
      });
      setAuctions(lines);
      setWeekDay(data.weekday());
    },
    [calendarDashboardLanesList],
  );

  const closeModal = () => setAuctions([]);

  const handleOpenAuction = useCallback(
    (lane: number) => () => {
      dispatch(getAuctionDashboardLotsList(lane, +Object.keys(auctionPanels)[0]));
      params.set('laneId', `${lane}`);
      history.push({
        pathname: './auctiondashboard',
        search: params.toString(),
      });
    },
    [auctionPanels, dispatch, history, params],
  );

  const getAuctionStartTime = useCallback(
    (laneActivationPeriods: LaneActivationPeriod[]) => laneActivationPeriods?.find((item) => item.dayOfWeek === weekDay),
    [weekDay],
  );

  const columns = [
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Lots Count',
      dataIndex: 'lotsCount',
      key: 'lotsCount',
    },
    {
      title: t("table_col_buyer_actions"),
      dataIndex: 'laneActivationPeriods',
      render: (laneActivationPeriods: LaneActivationPeriod[], lane: CalendarDashboardLane) => (
        <Space direction="vertical" size="small">
          <Button type="primary" onClick={handleViewSaleList}>
            {' '}
            View Sale list
          </Button>
          <TimerButton key={`start${lane.laneId}`} time={getAuctionStartTime(laneActivationPeriods)} onClick={handleOpenAuction(lane.laneId)} />
        </Space>
      ),
    },
  ];

  return (
    <ContentWrapper>
      <Row justify="center">
        <ScTitle level={1}>{t('nav_main_menu_auctions_calendar')}</ScTitle>
      </Row>
      <Row gutter={[20, 20]} justify="center">
        <Col xs={20}>
          <Calendar dateCellRender={dateCellRender} fullscreen={lg} onSelect={!lg ? handleSelect : () => {}} />
        </Col>
      </Row>
      {auctionDetailsForCalendar && currentAuction && (
        <AuctionDetailsModal
          auctionDetailsForCalendar={auctionDetailsForCalendar}
          isOpen={!!isModalVisible}
          onCancel={handleCancel}
          onViewSaleList={handleViewSaleList}
          auction={currentAuction}
          handleOpenAuction={handleOpenAuction(currentAuction?.laneId)}
        />
      )}
      {auctions.length && (
        <Modal visible={!!auctions.length} onCancel={closeModal} footer={null} width="100%">
          <Table
            dataSource={auctions.map((item) => ({
              ...item,
              key: item.auctionId,
            }))}
            columns={columns}
          />
        </Modal>
      )}
    </ContentWrapper>
  );
};

export default memo(AuctionCalendarPage);
