import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleModalAuth } from '../../../store/slices/authSlice';
import userManager from '../../../utils/userManager';

interface Props {
  visible: boolean;
  onCancel?: () => void;
  onOk?: () => void;
}

const AuthModal: FC<Props> = ({ visible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleGoToLogin = async () => {
    const currentLocation = history.location.pathname;
    localStorage.setItem('authFrom', currentLocation);
    await userManager.signinRedirect();
  };

  const handleGoToSignup = useCallback(() => {
    dispatch(toggleModalAuth(false));
    history.push('/signup');
  }, [dispatch, history]);

  return (
    <Modal
      visible={visible}
      title={t('Please Log in or Sign up')}
      onCancel={() => dispatch(toggleModalAuth(false))}
      footer={[
        <Button key="login" onClick={handleGoToLogin}>
          {t('Go to Login')}
        </Button>,
        <Button key="signup" type="primary" onClick={handleGoToSignup}>
          {t('Sign up to become a member')}
        </Button>,
      ]}
    >
      <p>{t('Sign up Desc')}</p>
    </Modal>
  );
};

export default memo(AuthModal);
