import { Row, Space, Input, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { FC, memo } from 'react';
import { ScCard } from './style';
import LotDescriptionCard from '../../shared/components/LotDescriptionCard';
import { useDispatch } from 'react-redux';
import { sendEmail } from '../../store/slices/authSlice';
import { message } from 'antd'

interface Props {
  lotNumber: string;
}

const LotPage: FC<Props> = ({lotNumber}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch()

  const onFinish = async (values: any) => {
    await dispatch(sendEmail(
      `Lot ${lotNumber}`,
      `fullname: ${values.firstName} 
       number: ${values.phoneNumber}
       comment: ${values.comment}
      `
    ))
    form.resetFields(["firstName", "comment", "phoneNumber"])
    messageApi.open({
      type: 'success',
      content: 'Email Sent Successfully',
      duration: 1,
    });
  }
  return (
    <LotDescriptionCard
      title={`${t('lot_request_leasing')}`} 
    >
      <Form
        form={form}
        name="leasing"
        onFinish={onFinish}
        style={{marginTop: "1rem"}}
      >
        {contextHolder}
        <Form.Item name="firstName" rules={[{ required: true, message: t('noti_required') }]}>
          <Input placeholder={t('reg_per_first_name')} />
        </Form.Item>
        <Form.Item name="comment" rules={[{ required: true, message: t('noti_required') }]}>
          <Input placeholder={t('reg_per_private_comment')} />
        </Form.Item>
        <Form.Item name="phoneNumber" rules={[{ required: true, message: t('noti_required') }]}>
          <Input placeholder={t('reg_per_phone_number')} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('lot_request_leasing_btn')}
          </Button>
        </Form.Item>
      </Form>
    </LotDescriptionCard>
  );
};

export default memo(LotPage);
