import styled from 'styled-components';
import { Steps, Col, Button, Row, Form, Upload } from 'antd';
import { Primary, LightShadow, BorderGrey, LightGreyBackground, Accent } from '../../shared/shared-styles';

export const ScCol = styled(Col)`
  border: 0.5px solid ${BorderGrey};
  box-shadow: 0px 2px 8px ${LightShadow};
  padding: 32px 24px;
  border-radius: 4px;
`;

export const ScColFinish = styled(Col)`
  border: 0.5px solid ${BorderGrey};
  box-shadow: 0px 2px 8px ${LightShadow};
  border-radius: 4px;
`;

export const ScColSteps = styled(ScCol)`
  margin: 48px 0 16px;
`;

export const ScSteps = styled(Steps)`
  .ant-steps-item-icon {
    border-radius: 2px;
  }
`;

export const ScTitleFinish = styled.div`
  padding: 32px 24px 16px;
`;

export const NextButton = styled(Button)`
  width: 150px;
`;

export const FinishContainer = styled.div`
  background: ${LightGreyBackground};
  padding: 24px;
  margin-bottom: 16px;
  h4 {
    color: ${Primary} !important;
  }
`;

export const ScRowBaseline = styled(Row)`
  align-items: baseline;
`;

export const ScRowBottomButtons = styled(Row)`
  margin-top: 50px;
`;

export const ScRowFinishButtons = styled(Row)`
  margin: 50px;
`;

export const DecodeBtn = styled(Button)`
  padding: 0.25rem 0.75rem;
  height: auto;
  font-family: 'main';
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 1rem;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  @media (max-width: 991px){
    top: 0.45rem;
  }
`;

export const VinWrapper = styled(Form.Item)`
  margin-bottom: 0;
  position: relative;
`;

export const VinNumber = styled(Form.Item)`
  width: 100%;
`;

export const ImgWrapper = styled.div`
  cursor: move;
`;

export const ScUpload = styled(Upload)`
  cursor: move;
  .ant-upload-list-picture-card-container:first-child {
    border: 2px solid ${Accent};
  }
`;

export const AddVehicleWrapper = styled.div`
  background: #F5F5F5;
  padding: 2rem 0;
  @media (max-width: 1200px){
    padding: 0;
  }
`

export const AddVehiclePageTitle = styled.h1`
font-family: 'main';
font-weight: 600;
font-size: 1.5rem;
line-height: 1.5rem;
color: #000000;
`

export const Container = styled.div`
  max-width: 78.125rem;
  width: 100%;
  padding: 2.5rem 3.75rem 6.75rem;
    margin-right: auto;
  margin-left: auto;
  background: #FFFFFF;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #F5F5F5;
  box-shadow: 0px 4px 4px #F1F1F1;
  border-radius: 20px;
  @media (max-width: 1200px){
    padding: 1.5rem 0.875rem 3rem;
  }
`

export const ContainerTitle = styled.h3`
  font-family: 'main';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #CE2424;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
  @media (max-width: 991px){
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
`

export const FormInputsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem 2.5rem;
  .ant-input{
    padding: 0.75rem 1rem; 
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .textarea-wrapper{
    grid-column: 1 / span 3;
    textarea{
      height: 6.75rem;
      resize: none;
    }
  }
  .ant-form-item{
    margin: 0;
  }
  .flag-dropdown{
    background: #F4F3F3;
    border: 1px solid #D9D9D9;
  }
  input.form-control{
    height: 3rem;
  }
  @media (max-width: 1200px){
    gap: 1rem;
  }
  @media (max-width: 991px){
    .ant-select-selection-placeholder{
      font-family: 'main';
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: #566063;
    }
    .ant-input{
      padding: 0.5rem 1rem;
    }
    .ant-input-number-input, .ant-input {
      &::placeholder{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #566063;
      }
    }
    input.form-control{
      height: 2.5rem;
    }
  }
  @media (max-width: 767px){
    grid-template-columns: repeat(1, 1fr);
    .textarea-wrapper{
      grid-column: unset;
    }
  }
`

export const UploadFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6.25rem;
  margin-bottom: 3.5rem;
  @media (max-width: 991px){
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

export const SubmitButton = styled(Button)`
  font-family: 'main';
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #FFFFFF;
  padding: 1rem 6rem;
  border-radius: 6px;
  height: auto;
  margin-left: auto;
`