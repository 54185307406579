import React, { MouseEvent } from 'react';
import { ActionButton } from './style'
import { SyncOutlined } from '@ant-design/icons'
import useCompare from '../../../hooks/useCompare'

const ComperaButton = ({onClick, id}: any) => {
  const { compareIds, handleAddToCompareList  } = useCompare()

  const onActionButtonClick = (e: MouseEvent<HTMLElement>) => {
    handleAddToCompareList(id)
    onClick(e)
  }

  const isActive = compareIds.some(productId => productId == id)

  return (
    <ActionButton onClick={onActionButtonClick} isActive={isActive}>
      <SyncOutlined />
    </ActionButton>
  )
}

export default ComperaButton