/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, useEffect, useState } from 'react';
import { TableLocale } from 'antd/lib/table/interface';
import TableFooter from '../TableFooter';
import { PaginationWrapper, ScTable } from './style';
import CustomPagination from '../CustomPagination';
import SearchFilterContainer from '../SearchFilterContainer';
import SearchPagination from '../../../pages/ClassicSearchPage/components/SearchPagination';

interface Props {
  data: any;
  columns: any;
  totalCount: number;
  isLoading: boolean;
  currentPage?: number;
  pageSize?: number;
  sortField?: string;
  sortOrder?: string;
  selectedRowKeys?: any;
  onChangeTable?: (pg: any, fltr: any, sort: any) => void;
  onSelectChange?: (selectedRow: (string | number)[] | undefined) => void;
  onPaginationChange?: (page: number, pageSize?: number) => void;
  pagination?: boolean;
  selectable?: boolean;
  children?: JSX.Element;
  scroll?: {
    x?: string | number | true | undefined;
    y?: string | number | undefined;
  };
  locale?: TableLocale;
  noScrollToTop?: boolean;
  filter?: boolean;
}

const CustomTable: FC<Props> = ({
  data,
  columns,
  isLoading,
  totalCount,
  selectedRowKeys,
  onSelectChange,
  onChangeTable,
  pagination,
  selectable,
  children,
  currentPage,
  pageSize,
  sortField,
  sortOrder,
  scroll,
  locale,
  noScrollToTop,
  onPaginationChange,
  filter,
}) => {
  const [tableSettings, setTableSettings] = useState<{
    Page: number;
    PageItemsCount: number;
    SortField: string;
    SortOrder: string;
  }>({
    Page: currentPage || 1,
    PageItemsCount: pageSize || 10,
    SortField: sortField || '',
    SortOrder: sortOrder || 'Desc',
  });

  useEffect(() => {
    setTableSettings({
      Page: currentPage || 1,
      PageItemsCount: pageSize || 10,
      SortField: sortField || '',
      SortOrder: sortOrder || 'Desc',
    })
  }, [currentPage, pageSize])
  

  const handleTableChange = (pg: any, fltr: any, sort: any) => {
    onChangeTable && onChangeTable(pg, fltr, sort);
    setTableSettings({
      Page: pg.current,
      PageItemsCount: pg.pageSize,
      SortField: sort.field,
      SortOrder: sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc',
    });
    if (!noScrollToTop) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };


  return (
    <>
      <SearchFilterContainer total={totalCount} filter={filter} />
      <ScTable
        rowClassName={(record, index) => `${index} fixedHeight90`}
        dataSource={data}
        columns={columns}
        rowSelection={
          selectable
            ? {
              selectedRowKeys,
              onChange: onSelectChange,
            }
            : undefined
        }
        onChange={handleTableChange}
        pagination={false}
        loading={isLoading}
        footer={() => children || <TableFooter total={totalCount} />}
        scroll={scroll}
        locale={locale}
      />
      <PaginationWrapper>
        <SearchPagination total={totalCount} />
      </PaginationWrapper>
    </>
  );
};
export default memo(CustomTable);
