/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { Canceler } from 'axios';
import { AppThunk, RootState } from '../store';
import { axiosInstance } from '../../core/axios';
import api from '../../core/api';
import { Vehicle } from '../../interfaces/FindVehicles';
import { showNotification } from '../../utils/notify';

const { CancelToken } = axios;
let cancel: Canceler;

export interface InitState {
  findVehicles: Vehicle[];
  totalCount: number;
  isLoading: boolean;
  error: string;
  currentVehicleByVin: any
}

export const initialState: InitState = {
  findVehicles: [],
  totalCount: 0,
  isLoading: false,
  error: '',
  currentVehicleByVin: null
};

export const findVehiclesSlice = createSlice({
  name: 'findVehicles',
  initialState,
  reducers: {
    findVehiclesRequest: (state) => {
      state.isLoading = true;
    },
    findVehiclesSuccess: (state, action) => {
      state.isLoading = false;
      state.findVehicles = action.payload.data;
      state.totalCount = action.payload.totalCount;
    },
    findVehiclesFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    searchByVinNumberSuccess: (state, action) => {
      state.currentVehicleByVin = action.payload;
    },
  },
});

export const { findVehiclesRequest, findVehiclesSuccess, findVehiclesFailure, searchByVinNumberSuccess } = findVehiclesSlice.actions;

export const getFindVehicles = (
  isWatchList: boolean,
  Page: number,
  PageItemsCount: number,
  SortField: string,
  SortOrder: string,

  searchString?: string,
  DaysFromCreateLessThanOrEqual?: number,
  FeaturedType?: string,
  YardLocationId?: string,
  Make?: string,
  Model?: string,
  Color?: string,
  Year?: string,
  YearFrom?: string,
  YearTo?: string,
  AuctionType?: string,
  VehicleType?: string,
  Location?: string,
  OdometerGreaterThanOrEqual?: string,
  OdometerLessThan?: string,
  BuyNowMinPrice?: string,
  BuyNowMaxPrice?: string,
  Damage?: string,
  BodyStyle?: string,
  FuelType?: string,
  EngineType?: string,
  Transmission?: string,
  DriveTrain?: string,
  Cylinder?: string,
  Source?: string,
  LaneIDs?: string,
  Customs?: string,
  Defect?: string,
  AutomobileType?: string,
): AppThunk => async (dispatch) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }
    dispatch(findVehiclesRequest());
    let url = '';
    if (isWatchList) url = api.GetUserWatchList;
    // else if (laneIDs) url = api.SearchLotsByLane;
    else url = api.Search;
    const res = await axiosInstance.get(url, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        searchString,
        DaysFromCreateLessThanOrEqual,
        FeaturedType,
        YardLocationId,
        Make,
        Model,
        Color,
        Year,
        YearFrom,
        YearTo,
        AuctionType,
        VehicleType,
        Location,
        OdometerGreaterThanOrEqual,
        OdometerLessThan,
        BuyNowMinPrice,
        BuyNowMaxPrice,
        Damage,
        BodyStyle,
        FuelType,
        EngineType,
        Transmission,
        DriveTrain,
        Cylinder,
        Source,
        LaneIDs,
        clearancePassed: Customs,
        Defect,
        AutomobileType,
      },
    });
    dispatch(findVehiclesSuccess(res.data));
  } catch (error: any) {
    dispatch(findVehiclesFailure(error));
    error.response && showNotification('error', error.response.data.errorMessage);
  }
};

export const SearchByVinNumber = (vinNumber: string): AppThunk => async (dispatch) => {
  const res = await axiosInstance.get(api.SearchByVinNumber, {
    params: {
      vinNumber: vinNumber
    }
  })
  dispatch(searchByVinNumberSuccess(res.data))
}

export const CreateSearchQueryByVinCode = (vinNumber: string, phone: string, firstName?: string, lastName?: string): AppThunk => async (dispatch) => {
  const res = await axiosInstance.post(api.CreateSearchQueryByVinCode, {
    vinCode: vinNumber,
    phoneNumber: phone,
    firstName,
    lastName,
  })

  return res.data
}

export const ExecuteSearchQueryByVinCode = (queryId: any, otpCode: any): AppThunk => async (dispatch) => {
  const res = await axiosInstance.get(api.ExecuteSearchQueryByVinCode, {
    params: {
      queryId: queryId,
      otpCode: otpCode
    }
  })

  return res.data
}

// SELECTORS
export const findVehiclesSelector = (state: RootState) => state.findVehicles.findVehicles;
export const totalCountSelector = (state: RootState) => state.findVehicles.totalCount;
export const isLoadingSelector = (state: RootState) => state.findVehicles.isLoading;
export const currentVehicleByVinSelector = (state: RootState) => state.findVehicles.currentVehicleByVin;

export default findVehiclesSlice.reducer;
