import { Menu, Row, Col } from 'antd';
import React, { FC, memo, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { SсMenu } from './style';
import { langPrefix } from '../../utils/functions';

interface Props {
  children: JSX.Element;
}

const ProfilePage: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [currentKey, setCurrentKey] = useState('/contactinformation');

  useEffect(() => {
    setCurrentKey(location.pathname);
  }, [dispatch, location]);

  const handleRedirect = useCallback(
    (e) => {
      setCurrentKey(e.key);
      history.push(e.key);
    },
    [history],
  );

  return (
    <Row justify="space-around">
      <Col xs={20}>
        <SсMenu mode="horizontal" selectedKeys={[currentKey]} onClick={handleRedirect}>
          <Menu.Item key={langPrefix("/contactinformation")}>{t('nav_user_menu_account_contact_information')}</Menu.Item>
          <Menu.Item key={langPrefix("/accountsettings")}>{t('nav_user_menu_account_account_settings')}</Menu.Item>
          <Menu.Item key={langPrefix("/forms")}>{t('nav_user_menu_account_forms')}</Menu.Item>
        </SсMenu>
      </Col>
      <Col xs={20}>{children}</Col>
    </Row>
  );
};

export default memo(ProfilePage);
