import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AdvanceSearchBtn,
  PersonalSearchForm,
  PersonalSearchWrapper,
  ScFormItem,
  SearcBtn,
  SearchActionsWrapper,
  StyledSelect,
  StyledTreeSelect,
  PopularVehicleBtn,
} from './style';
import { getBodyStyles, getYearRange } from '../../../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMakeModelsByVehicleType,
  makeModelsByVehicleTypeSelector,
  productGeneralDictionariesByLanguageSelector,
} from '../../../../store/slices/dictionarySlice';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Form, Select, TreeSelect } from 'antd';
import { useHistory, useLocation } from 'react-router';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { allAuctionsAddressesAsDictionarySelector, getAllAuctionsAddressesAsDictionary } from '../../../../store/slices/auctionSlice';
import { userSelector } from '../../../../store/slices/authSlice';
import { getAllPopularVehicles, popularVehiclesSelector } from '../../../../store/slices/initSlice';
import { getSearchDashboardFilter, searchDashboardFilterSelector } from '../../../../store/slices/dashboardSlice';
import { MakeModelsByVehicleType } from '../../../../interfaces/Dictionary';
import i18n from '../../../../i18n';

const { SHOW_CHILD } = TreeSelect;
const { Option } = Select;

const PersonalSearch = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const { md } = useBreakpoint();

  const [formValues, setFormValues] = useState({});
  const [modelOpenKeys, setmodelOpenKeys] = useState<any[]>([]);
  const [modelValueKey, setmodelValueKey] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getMakeModelsByVehicleType(2));
    dispatch(getAllAuctionsAddressesAsDictionary());
    dispatch(getAllPopularVehicles());
  }, [dispatch, i18n.language]);

  const makeModelsByVehicleType = useSelector(makeModelsByVehicleTypeSelector);
  const allAuctionsAddressesAsDictionary = useSelector(allAuctionsAddressesAsDictionarySelector);
  const user = useSelector(userSelector);
  const productGeneralDictionariesByLanguage = useSelector(productGeneralDictionariesByLanguageSelector);
  const popularVehicles = useSelector(popularVehiclesSelector);
  const searchDashboardFilter = useSelector(searchDashboardFilterSelector);

  const handleOpenSearch = useCallback(
    (filter: string) => () => {
      const filterObject = JSON.parse(filter);
      Object.keys(filterObject).map((key) => {
        params.set(key, filterObject[key].replace(/%20/g, ' '));
        return null;
      });
      history.push({
        pathname: '/findvehicles',
        search: params.toString(),
      });
    },
    [history, params]
  );

  useEffect(() => {
    user && dispatch(getSearchDashboardFilter());
  }, [dispatch, user]);

  const handleSubmitForm = (path: string) => () => {
    const values = form.getFieldsValue();
    const makes: string[] = values.make ? [...values.make] : [];
    values.vehicletype && params.set('vehicletype', values.vehicleType);
    values.defect && params.set('defect', values.defect);
    values.bodystyle && params.set('bodystyle', values.bodystyle.join(','));
    values.make?.length && params.set('vehiclemake', makes.join(','));
    modelValueKey?.length && params.set('vehiclemodel', modelValueKey.join(','));
    values.yearfrom && params.set('yearfrom', values.yearfrom);
    values.yearto && params.set('yearto', values.yearto);


    // params.set('vehicleodometrfrom', values.odometr[0]);
    // params.set('vehicleodometrto', values.odometr[1]);

    values.auctionsAddress && params.set('auctionsaddress', (values.auctionsAddress || []).join(','));
    history.push({
      pathname: path,
      search: params.toString(),
    });
  };

  const [modelList, setModelList] = useState<MakeModelsByVehicleType[]>([]);

  const getModels = (make: MakeModelsByVehicleType) => {
    if (make.modelGroups.length) {
      return make.modelGroups.map((model: { modelGroup: string; models: string[] }) => ({
        title: model.modelGroup,
        value: `${make.make}/${model.modelGroup}`.replace(/\s/g, '_'),
        key: `${make.make}/${model.modelGroup}`.replace(/\s/g, '_'),
        children: model.models.map((item: string) => ({
          title: item,
          value: `${make.make}/${model.modelGroup}/${item}`.replace(/\s/g, '_'),
          key: `${make.make}/${model.modelGroup}/${item}`.replace(/\s/g, '_'),
        })),
      }));
    }
    return make.model.map((model: string) => ({
      title: model,
      value: `${make.make}/${model}`.replace(/\s/g, '_'),
      key: `${make.make}/${model}`.replace(/\s/g, '_'),
    }));
  };

  useEffect(() => {
    const makes = form.getFieldValue('make');
    if (makes && makeModelsByVehicleType) {
      setSelectedMakes(makes);
      setmodelOpenKeys([makes[0]]);
    }
    const newList = makes?.map((make: string) => makeModelsByVehicleType.find((item) => item.make.replace(/\s/g, '_') === make));
    const treeDataFiltered = newList?.filter((make: MakeModelsByVehicleType) => make.model.length || make.modelGroups.length);
    const treeData = treeDataFiltered?.map((make: MakeModelsByVehicleType) => ({
      title: make.make,
      value: make.make.replace(/\s/g, '_'),
      key: make.make.replace(/\s/g, '_'),
      checkable: true,
      selectable: true,
      children: getModels(make),
    }));
    setModelList(treeData || []);
  }, [form, formValues, makeModelsByVehicleType]);

  const [popularMake, setPopularMake] = useState<string[]>([]);
  const [selectedMakes, setSelectedMakes] = useState<string[]>([]);

  useEffect(() => {
    const unique = Array.from(new Set(popularVehicles.map((item) => item.make)));
    setPopularMake(unique);
  }, [popularVehicles]);

  const handleSetMake = useCallback(
    (make: string) => {
      setFormValues(make);
      const makes: string[] = form.getFieldValue('make') || [];
      const model: string[] = form.getFieldValue('model') || [];
      let modelArr: string[] = [];
      const updatedSelectedMakes = [...selectedMakes];
      if (makes.indexOf(make) === -1) {
        makes.push(make);
        updatedSelectedMakes.push(make);
      } else {
        makes.splice(makes.indexOf(make), 1);
        modelArr = model.filter((item) => !item.includes(make));
        const index = updatedSelectedMakes.indexOf(make);
        if (index !== -1) {
          updatedSelectedMakes.splice(index, 1);
        }
      }
      form.setFieldsValue({
        make: makes,
        model: modelArr,
      });
      setSelectedMakes(updatedSelectedMakes);
    },
    [form, selectedMakes, setSelectedMakes],
  );

  const generateArrayOfYears = useCallback((type: string) => {
    const yearFrom = form.getFieldValue('yearfrom');
    const yearTo = form.getFieldValue('yearto');
    let max;
    let min;
    if (type === 'from') {
      max = yearTo || new Date().getFullYear();
      min = new Date().getFullYear() - 100;
    } else {
      max = new Date().getFullYear();
      min = yearFrom || new Date().getFullYear() - 100;
    }
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }, []);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const updateOpenKeys = (prev: any[], key: any) => {
    const index = prev.indexOf(key);
    const newList = [...prev];
    if (index > -1) {
      newList.splice(index, 1);
    } else {
      newList?.push(key);
    }
    return newList;
  };

  const handleChangeExpandedKeys = (value: any, option: any) => {
    !!option.children && setmodelOpenKeys((prev) => updateOpenKeys(prev, option.key));
  };

  const PopularVehicleButton = ({ onClick, icon, selected, children }: { onClick: () => void, icon: React.ReactNode, selected: boolean, children: React.ReactNode }) => {
    return (
      <PopularVehicleBtn selected={selected} onClick={onClick}>
        {icon} {children}
      </PopularVehicleBtn>
    );
  };

  const handleChangeValue = (value: any) => {
    const make = value.find((item: any) => !item.includes('/'));
    setmodelValueKey(value.filter((item: any) => item !== make));
  };

  const adressList = allAuctionsAddressesAsDictionary.map(item => ({
    title: item.lanValue,
    value: item.value,
    key: item.value
  }));

  return (
    <PersonalSearchWrapper>
      <PersonalSearchForm
        form={form}
        onValuesChange={setFormValues}
        initialValues={{
          vehicleType: 'Automobile',
          defect: '',
          make: undefined,
        }}
      >
        <ScFormItem name="bodystyle" noStyle>
          <StyledTreeSelect
            showArrow
            allowClear
            dropdownStyle={md && { zIndex: 5 }}
            treeNodeFilterProp='title'
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('filter_bodystyles')}
            showSearch
            treeData={getBodyStyles(productGeneralDictionariesByLanguage.automobileTypesList)}
            maxTagCount="responsive"
          />
        </ScFormItem>

        <ScFormItem shouldUpdate name="make">
          <StyledTreeSelect
            showArrow
            allowClear
            dropdownStyle={md && { zIndex: 5 }}
            treeNodeFilterProp='title'
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('filter_make')}
            showSearch
            treeData={makeModelsByVehicleType?.map(item => ({
              id: item.make.replace(/\s/g, '_'),
              value: item.make.replace(/\s/g, '_'),
              title: item.make
            }))}
            maxTagCount="responsive"
            dropdownRender={(menu: any) => (
              <>
                {popularMake.map((item) => (
                  <PopularVehicleButton
                    onClick={() => handleSetMake(item)}
                    icon={<i className={`car-${item.toLowerCase()}`} />}
                    key={item}
                    selected={selectedMakes.includes(item)}
                    children={undefined}
                  />
                ))}
                {menu}
              </>
            )}
          />
        </ScFormItem>

        <ScFormItem shouldUpdate noStyle>
          <StyledTreeSelect
            showArrow
            allowClear
            dropdownStyle={md && { zIndex: 5 }}
            treeNodeFilterProp='title'
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('filter_seria_model')}
            showSearch
            treeData={modelList}
            maxTagCount="responsive"
            treeExpandedKeys={modelOpenKeys}
            onTreeExpand={setmodelOpenKeys}
            value={modelValueKey}
            onChange={handleChangeValue}
          />
        </ScFormItem>

        <ScFormItem name="yearfrom" noStyle>
          <StyledSelect allowClear dropdownStyle={md && { zIndex: 5 }} placeholder={t('Min')}>
            {generateArrayOfYears('from')?.map((item) => (
              <Option value={item} key={item}>
                {item}
              </Option>
            ))}
          </StyledSelect>
        </ScFormItem>

        <ScFormItem name="yearto" noStyle>
          <StyledSelect allowClear dropdownStyle={md && { zIndex: 5 }} placeholder={t('Max')}>
            {generateArrayOfYears('to')?.map((item) => (
              <Option value={item} key={item}>
                {item}
              </Option>
            ))}
          </StyledSelect>
        </ScFormItem>

        <ScFormItem name="auctionsaddress" noStyle>
          <StyledTreeSelect
            showArrow
            allowClear
            dropdownStyle={md && { zIndex: 5 }}
            treeNodeFilterProp='title'
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('Auction')}
            showSearch
            treeData={adressList}
            maxTagCount="responsive"
          />
        </ScFormItem>

      </PersonalSearchForm>
      <SearchActionsWrapper>
        <AdvanceSearchBtn onClick={handleSubmitForm('findvehicles')}>
          <ReactSVG src="/icons/setting.svg" />
          <span>{t('search_advanced')}</span>
        </AdvanceSearchBtn>
        <SearcBtn onClick={handleSubmitForm('classicsearch')}>{t('search_search')}</SearcBtn>
      </SearchActionsWrapper>
    </PersonalSearchWrapper>
  );
};

export default PersonalSearch;
