import React, { FC, memo } from 'react';
import { Typography, Col } from 'antd';
import { InlineIcon } from '@iconify/react';
import { CaptionItem } from './style';

const { Text } = Typography;

interface Props {
  icon: object;
  value: string;
}

const Caption: FC<Props> = ({ icon, value }) => (
  <Col xs={8}>
    <CaptionItem>
      <InlineIcon icon={icon} width="20" />
      <Text>{value}</Text>
    </CaptionItem>
  </Col>
);
export default memo(Caption);
