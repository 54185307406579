import React, { FC, memo, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { Table, TablePaginationConfig, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import TableFooter from '../../shared/components/TableFooter';
import { getNotifications, notifyLoadingSelector, notifyTotalCountSelector, notifyListSelector } from '../../store/slices/notifySlice';

const columns = [
  {
    title: 'Creation Time',
    dataIndex: 'creationTime',
    key: 'creationTime',
    render: (creationTime: string) => dayjs(creationTime).format('DD/MM/YYYY hh:mm'),
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
  },
];

const NotifictionPage: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications(1, 10));
  }, [dispatch]);

  const notifyLoading = useSelector(notifyLoadingSelector);
  const notifyTotalCount = useSelector(notifyTotalCountSelector);
  const notifyList = useSelector(notifyListSelector);

  const handleTableChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (pg: TablePaginationConfig, fltr: any, sort: any) => {
      dispatch(getNotifications(pg.current || 1, pg.pageSize || 10));
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    [dispatch],
  );

  return (
    <Row justify="center">
      <Col xs={20}>
        <Table
          rowClassName={(record, index) => `${index} fixedHeight90`}
          dataSource={notifyList.map((item) => ({
            ...item,
            key: item.creationTime,
          }))}
          columns={columns}
          onChange={handleTableChange}
          loading={notifyLoading}
          footer={() => <TableFooter total={notifyTotalCount} />}
          pagination={{
            showSizeChanger: true,
            total: notifyTotalCount,
            position: ['topRight', 'bottomRight'],
          }}
        />
      </Col>
    </Row>
  );
};
export default memo(NotifictionPage);
