import React, { FC, memo } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userRoleSelector } from '../store/slices/authSlice';

interface Props extends RouteProps {
  forRole: string | string[];
  redirectPath: string;
}

const RestrictedRoute: FC<Props> = ({ forRole, redirectPath, ...rest }) => {
  const location = useLocation();
  const userRole = useSelector(userRoleSelector);

  return forRole.includes(userRole) ? (
    <Route {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: redirectPath,
        state: { from: location.pathname },
      }}
    />
  );
};

export default memo(RestrictedRoute);
