import React, { FC, memo } from 'react';
import { Image } from 'antd';

interface Props {
  imageSrc: string;
}

const NoPreviewImage: FC<Props> = ({ imageSrc }) => <Image width={100} preview={false} src={imageSrc || 'error'} fallback="img/noImg.jpg" alt="Lot" />;

export default memo(NoPreviewImage);
