import React from 'react'
import { useTranslation } from 'react-i18next'
import LotCardDescription, { LotCardRow, LotCardText } from '../../../shared/components/LotDescriptionCard'

const SecurityCard = ({vehicle}: any) => {

  const { t } = useTranslation()

  return (
    <LotCardDescription title={t("electric_description_security")}>
        <>
            <LotCardRow>
                <LotCardText>{t("driver_airbag")}</LotCardText>
                <LotCardText>{vehicle?.security?.driverAirbag ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("passenger_airbag")}</LotCardText>
                <LotCardText>{vehicle?.security?.passengerAirbag ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("isofix_child_seat_fittings")}</LotCardText>
                <LotCardText>{vehicle?.security?.isofixChildSeatFittings ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("rear_door_child_safety_lock")}</LotCardText>
                <LotCardText>{vehicle?.security?.rearDoorChildSafetyLock ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("front_rear_side_airbags")}</LotCardText>
                <LotCardText>{vehicle?.security?.frontRearSideAirbags ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
        </>
    </LotCardDescription>
  )
}

export default SecurityCard