import React, { FC, memo } from 'react';
import { Typography, Row, Col } from 'antd';
import { BlogItem } from '../../../interfaces/Blog';
import RecomendedBlogCard from '../components/RecomendedBlogCard';
import { RecomendedListTitle } from '../style';
import i18n from '../../../i18n';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CustomPrevArrow, CustomNextArrow } from "../components/CustomArrows";

const { Text } = Typography;

interface Props {
  data: BlogItem[];
}

const ListBlogItem: FC<Props> = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 865,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <RecomendedListTitle>
        <Text strong>{i18n.t('blog_recc_articles')}</Text>
      </RecomendedListTitle>
      <Slider className='blogWrap' {...settings}>
        {data.map((blogItem) => (
          <div key={blogItem.id}>
            <RecomendedBlogCard blogItem={blogItem} />
          </div>
        ))}
      </Slider>
    </>
  );
};

export default memo(ListBlogItem);
