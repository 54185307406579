import { SearchOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Nav, NavLink, SubHeaderWrapper, NavLinkVariant } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticatedSelector, openModalAuthSelector, toggleModalAuth } from '../../../../store/slices/authSlice';
import { useTranslation } from 'react-i18next';
import userManager from '../../../../utils/userManager';
import FindVehicleMenu from '../FindVehicleMenu';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import MobaileMenu from '../MobailMenu';
import AuthModal from '../../Modals/AuthModal';
import TrackingSearch from '../TrackingSearch';
import { Link, useHistory  } from 'react-router-dom';
import { langPrefix } from '../../../../utils/functions';

interface SubHeaderProps {
  onFindVehicleMenuClose?: () => void;
  children?: JSX.Element;
}

const SubHeader = ({onFindVehicleMenuClose, children}: SubHeaderProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const { md } = useBreakpoint();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const openModalAuth = useSelector(openModalAuthSelector);

  const handleSignIn = useCallback(async () => {
    const currentLocation = history.location.pathname;
    localStorage.setItem('authFrom', currentLocation);
    await userManager.signinRedirect();
  }, []);

  const [isFindVehicleVisible, setisFindVehicleVisible] = useState(false)

  useEffect(() => {

    setisFindVehicleVisible(false)
  }, [location.pathname, location.search])

  const dispatch = useDispatch()

  const handleSellVehicle = () => {
    if(md){
      dispatch(toggleModalAuth(true))
    }else{
      handleSignIn()
    }
  }
  return (
    <>
      <SubHeaderWrapper>

        <Nav>
            <NavLink className='nav-link'
              to={langPrefix('/aboutus')}
              variant={location.pathname.includes('/aboutus') ? NavLinkVariant.ACTIVE : NavLinkVariant.PRIMARY}
            >
              {t("nav_main_menu_about_us")}
              {md && <div className="dropdown-menu">
                <NavLink
                  to={langPrefix('/services')}
                  variant={location.pathname.includes('/services') ? NavLinkVariant.ACTIVE : NavLinkVariant.PRIMARY}
                >
                  {t("nav_main_menu_sell_vehicle_services")}
                </NavLink>
              </div> }
            </NavLink>

            {!md && <NavLink
            to={langPrefix('/services')}
            variant={location.pathname.includes('/services') ? NavLinkVariant.ACTIVE : NavLinkVariant.PRIMARY}
          >{t("nav_main_menu_sell_vehicle_services")}</NavLink>}

          <NavLink
            to={langPrefix('/sellers')}
            variant={location.pathname.includes('/sellers') ? NavLinkVariant.ACTIVE : NavLinkVariant.PRIMARY}
          >{t("nav_main_menu_find_sellers")}</NavLink>
          {/* <NavLink to={'/findvehicles'}>{t('nav_main_menu_find_vehicles')}</NavLink> */}

          <NavLink
            as={!md ? 'span' : Link}
            to={langPrefix('/classicsearch')}
            variant={isFindVehicleVisible ? NavLinkVariant.ACTIVE: NavLinkVariant.PRIMARY}
            onClick={() => !md && setisFindVehicleVisible(!isFindVehicleVisible)}
            onMouseOver={() => md && setisFindVehicleVisible(true)}
            onMouseLeave={() => md && setisFindVehicleVisible(false)}
          >
            {t('nav_main_menu_find_vehicles')}
            {/* <ReactSVG src='/icons/arrow-up.svg' className='svg-wrapper'/> */}

            {isFindVehicleVisible && md && <FindVehicleMenu />}
          </NavLink>

          {isAuthenticated
          ? <NavLink
              to={langPrefix('/addvehicle')}
              variant={location.pathname.includes('/addvehicle') ? NavLinkVariant.ACTIVE : NavLinkVariant.PRIMARY}
              >{t('nav_main_menu_sell_vehicle')}</NavLink>
          : <NavLink as={'span'} onClick={handleSellVehicle}>{t('nav_main_menu_sell_vehicle')}</NavLink>}

          {openModalAuth && md && <AuthModal visible={!!openModalAuth} />}

          <NavLink
            to={langPrefix('/electric-cars')}
            variant={location.pathname.includes('/electric-cars') ? NavLinkVariant.ACTIVE : NavLinkVariant.PRIMARY}
          >{t("nav_main_menu_sell_vehicle_electric")}</NavLink>

          <NavLink
            to={langPrefix('/contact')}
            variant={location.pathname.includes('/contact') ? NavLinkVariant.ACTIVE : NavLinkVariant.PRIMARY}
          >{t("nav_main_menu_contact")}</NavLink>


        </Nav>

        {children}

        {md && <TrackingSearch />}
      </SubHeaderWrapper>

      {isFindVehicleVisible && !md && <MobaileMenu
        onCloseClick={onFindVehicleMenuClose}
        onPrevPageClick={() => setisFindVehicleVisible(false)}>
        <FindVehicleMenu />
      </MobaileMenu> }
    </>
  );
};

export default SubHeader;
