import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { langPrefix } from '../../utils/functions';
import { useCookies } from 'react-cookie';

const LogoutPage: React.FC = () => {
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['language']);

  const canonicalTag = document.querySelector('link[rel="canonical"]');
  if (canonicalTag) {
    canonicalTag.parentNode?.removeChild(canonicalTag);
  }

  const metaTag = document.querySelector('meta[name="robots"]');
  if (metaTag) {
    metaTag.setAttribute('content', 'follow, noindex, max-snippet:-1, max-video-preview:-1, max-image-preview:large');
  }

  useEffect(() => {

    history.push(langPrefix('/'));
  }, [history]);

  return (
    <div>
      <h1>Logging Out...</h1>
    </div>
  );
};

export default LogoutPage;
