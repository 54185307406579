import styled, { css } from "styled-components";

export enum MobileFilterHeaderButtonVariant {
  CLOSE,
  PRIMARY
}

interface MobileFilterHeaderProps {
  buttonVariant?: MobileFilterHeaderButtonVariant
}

export const MobileFilterHeaderWrapper = styled.div<MobileFilterHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 1rem 0;
  margin-bottom: 1rem;
  span{
    font-family: 'main';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1B153D;
  }
  button{
    width: 2.5rem;
    height: 2.5rem;
    background: #F0F0F0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    div, svg{
      width: 1rem;
      height: 1rem; 
    }
    ${p => p.buttonVariant == MobileFilterHeaderButtonVariant.CLOSE && css`
      background: none;
      path{
        fill: #1B153D;
        stroke: #1B153D;
      }
      div, svg{
        width: 1.5rem;
        height: 1.5rem; 
      }
    `}
  }
`