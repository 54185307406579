import React, { useEffect, FC, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import { checkAuth } from '../store/slices/authSlice';
import { RootState } from '../store/store';
import RestrictedRoute from './RestrictedRoute';

import MyLotPage from './MyLotPage';
import SellerLotsPage from './SellerLotsPage';
import SavedSearchesPage from './SavedSearchesPage';
import AddVehiclePage from './AddVehiclePage';
import PaymentsPage from './PaymentsPage';
import DepositsPage from './DepositsPage';
import FeesPage from './FeesPage';
import MoneyWithdrawalPage from './MoneyWithdrawalPage';
import BidsPage from './BidsPage';
import BuyerLotsPage from './BuyerLotsPage';
import FindVehiclesPage from './FindVehiclesPage';
import HomePage from './HomePage';
import DashboardSellerPage from './DashboardSellerPage';
import DashboardBuyerPage from './DashboardBuyerPage';
import { StyledContainer } from './style';
import NotifictionPage from './NotifictionPage';
import ContactInformation from './ProfilePage/ContactInformation';
import AccountSettings from './ProfilePage/AccountSettings';
import Forms from './ProfilePage/Forms';
import NotFoundPage from './NotFoundPage';

const PrivateComponent: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const pathLang = location.pathname.split('/')[1];
  const lang = (pathLang === 'en' || pathLang === 'ru') ? '/'+pathLang : '';
  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  const { isAuthenticated, checkAuthError } = useSelector((state: RootState) => state.auth);

  if (location.pathname === `${lang}`) {
    return <HomePage />;
  }

  if (!isAuthenticated && !checkAuthError) {
    return null;
  }

  if (!isAuthenticated && checkAuthError && location.pathname !== `${lang}`) {
    if (location.pathname.startsWith(`${lang}/seller`) || location.pathname.startsWith(`${lang}/buyer`)) {
      return <Redirect to={`${lang}`} />;
    }
    window.location.replace(`${lang}/404`);
  }


  return (
    <StyledContainer>
      <Switch>
        {/* common user routes */}
        <Route exact path={`${lang}/paymentsdue`} component={PaymentsPage} />
        <Route exact path={`${lang}/paymentshistory`} component={PaymentsPage} />
        <Route exact path={`${lang}/deposits`} component={DepositsPage} />
        <Route exact path={`${lang}/moneywithdrawal`} component={MoneyWithdrawalPage} />
        {/* <Route exact path={`${lang}/fees`} component={FeesPage} /> */}
        <Route exact path={`${lang}/savedsearches`} component={SavedSearchesPage} />
        <Route exact path={`${lang}/addvehicle`} component={AddVehiclePage} />
        <Route exact path={`${lang}/mywatchlist`} component={FindVehiclesPage} />
        <Route exact path={`${lang}/notifications`} component={NotifictionPage} />

        {/* seller routes */}
        <RestrictedRoute forRole="seller" redirectPath={`${lang}`} path={`${lang}/seller`} component={SellerLotsPage} />
        <RestrictedRoute forRole="seller" redirectPath={`${lang}`} exact path={`${lang}/mylot/:id`} component={MyLotPage} />
        <RestrictedRoute forRole="seller" redirectPath={`${lang}`} exact path={`${lang}/dashboardseller`} component={DashboardSellerPage} />

        {/* common buyer routes */}
        <RestrictedRoute forRole="buyer" redirectPath={`${lang}`} path={`${lang}/buyer`} component={BidsPage} />
        <RestrictedRoute forRole="buyer" redirectPath={`${lang}`} exact path={`${lang}/wonlots`} component={BuyerLotsPage} />
        <RestrictedRoute forRole="buyer" redirectPath={`${lang}`} exact path={`${lang}/lostlots`} component={BuyerLotsPage} />
        <RestrictedRoute forRole="buyer" redirectPath={`${lang}`} exact path={`${lang}/dashboardbuyer`} component={DashboardBuyerPage} />

        <Route exact path={`${lang}/contactinformation`} component={ContactInformation} />
        <Route exact path={`${lang}/accountsettings`} component={AccountSettings} />
        <Route exact path={`${lang}/forms`} component={Forms} />
        <Route exact path={`${lang}/404`} component={NotFoundPage} /> 
        <Route exact path={`${lang}/*`}>
        {() => {
            window.location.replace(`${lang}/404`);
            return null;
          }}
        </Route> 
      </Switch>
    </StyledContainer>
  );
};

export default memo(PrivateComponent);
