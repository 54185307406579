import React, { FC, memo } from 'react';
import Banner from './Banner';
import { Container } from './style';
import AutoAuctionSection from './AutoAuctionSection';
import TransSection from './TransSection';
import TerminalPotiSection from './TerminalPotiSection';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../shared/sharedComponent';
import { AboutPageSchema } from '../../shared/components/Schema';
import Meta from '../../shared/components/Meta';

const ServicesPage: FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <Meta
        title={t("meta_services_title")}
        description={t("meta_services_desc")}
      />
    <AboutPageSchema/>
      <Banner />
      <Container>
        <PageTitle>{t("services")}</PageTitle>
      </Container>
      <AutoAuctionSection />
      <TransSection />
      <TerminalPotiSection />
    </>
  );
};

export default memo(ServicesPage);
