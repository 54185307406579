import React, {useEffect, useState} from 'react'
import { ContentWrapper, ImageWrapper, InfoWrapper, Title, Wrapper, WriteMessageWrapper } from './style'
import ReviewSection from '../../shared/components/ReviewSection'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { addDealerReviews, dealerListSelector, getDealerRate, getDealerReviews, getDealers } from '../../store/slices/dealerSlice';

const OperatedCompaniesDetailePage = () => {
    const [cookies] = useCookies(['language']);
    const dispatch = useDispatch()
    const { id } = useParams<{ id: string }>();
  
    const dealerList = useSelector(dealerListSelector)
    const [dealer, setDealer] = useState<any>(null)
    const [reviews, setReviews] = useState<any[]>([])
    const [rate, setRate] = useState(0)

    const getDealerReview = async () => {
        if(!dealer) return
        const dealerRate: any = await dispatch(getDealerRate(dealer.dealerId))
        setRate(dealerRate)
    
        const reviewsList: any = await dispatch(getDealerReviews(dealer.dealerId))
    
        setReviews(reviewsList)
        
      }

    const onAddReview = async (values: any) => {
        const data = {
          dealerId: dealer.dealerId,
          ...values,
        }
        await dispatch(addDealerReviews(data))
        getDealerReview()
      }
    

    useEffect(() => {
        dispatch(getDealers(true))
    }, [dispatch])

    useEffect(() => {
        getDealerReview()
    }, [dealer])

    
    useEffect(() => {
        const dealers = dealerList.filter((dealer) => dealer.dealerId == id)
        const currDealer = dealers[0]
        if(currDealer){
            setDealer(currDealer)
        }
    }, [id, dealerList])

  return (
    <>
        <Title>Operated by <span>Lion Trans</span></Title>
        {dealer && <Wrapper>
            <ImageWrapper>
                {dealer.image 
                ? <img src={`${process.env.REACT_APP_DEALER_IMAGE}${dealer.image}`} /> 
                : <img src={`/img/person.png`} />}
            </ImageWrapper>
            <InfoWrapper>
                <h3>{dealer.dealerName}</h3>
                <ul>
                    <li>
                        Country
                        <span>{dealer.country}</span>
                    </li>
                    <li>
                        City
                        <span>{dealer.city}</span>
                    </li>
                    <li>
                        Address
                        <span>{dealer.address}</span>
                    </li>
                    <li>
                        Email
                        <span>{dealer.emailAddress}</span>
                    </li>
                </ul>
            </InfoWrapper>
            <ContentWrapper>
                <h4>{dealer.dealerName}</h4>
                <p>{dealer.description}</p>
            </ContentWrapper>
            <WriteMessageWrapper>
                <ReviewSection reviews={reviews} onAddReview={onAddReview} />
            </WriteMessageWrapper>
        </Wrapper>}
    </>
  )
}

export default OperatedCompaniesDetailePage