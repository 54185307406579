import styled from "styled-components"

export const PaymentsWrapper = styled.div`
    padding: 2.5rem;
    @media(max-width: 1400px){
        padding: 1.5rem 1rem;
    }
`

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 1.5rem;
    @media(max-width: 991px){
        justify-content: flex-start;
        margin-bottom: 0.875rem;
        flex-direction: column;
    }
`

export const HeaderTitleGroup = styled.div`
    display: flex;
    flex-direction: column;
    @media(max-width: 991px){
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    @media(max-width: 767px){
        display: none;
    }
`

export const HeaderTitle = styled.h3`
    font-family: 'main';
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: #080808;
    margin-bottom: 3rem;
` 

export const HeaderWarning = styled.div`
    font-family: 'main';
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #9095A6;
    display: flex;
    align-items: center;
    svg{
        margin-right: 0.5rem;   
    }
    path{
        stroke: #9095A6;
    }
`

export const FundsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.325rem;
    margin-right: 1.5rem;
    flex-shrink: 0;

    span:first-child{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: #9095A6;
    }
    span:last-child{
        font-family: 'main';
        font-weight: 500;
        font-size: 1rem;
        line-height: 0.875rem;
        color: #1B153D;
    }
    @media(max-width: 767px){
        margin-right: 0.5rem;
    }
    @media(max-width: 580px){
        flex: 1 1 100%;
    }
`

export const HeaderActionGroup = styled.div`
    display: flex;
    align-items: center;
    button{
        background: #CE2424;
        border-radius: 4px;
        font-family: 'main';
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #FFFFFF;
        padding: 0.75rem;
    }
    .ant-select{
        margin-right: 1rem;
    }
    @media(max-width: 991px){
        width: 100%;
    }
    @media(max-width: 767px){
        .ant-select{
            margin-right: 0.5rem;
        }
        input{
            max-width: 9rem;
            margin-right: 0;
        }
    }
    @media(max-width: 580px){
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        .ant-select{
            width: 100%;
        }
    }
`

export const PaymentCardsList = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
`