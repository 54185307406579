/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, useState } from 'react';
import { Button, Col, Card, Drawer } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import MobileFilterHeader from '../MobileFilterHeader';

interface Props {
  children: JSX.Element;
}

const TablesMenuDrawer: FC<Props> = ({ children }) => {
  const { lg } = useBreakpoint();
  const [visible, setvisible] = useState(false);

  const showDrawer = () => {
    setvisible(true);
  };

  const onClose = () => {
    setvisible(false);
  };

  return (
    <>
      {!lg && (
        <Col xs={24}>
          <MobileFilterHeader onButtonHandle={showDrawer} />
          {/* <Button type="primary" onClick={showDrawer}>
            Menu
          </Button> */}
        </Col>
      )}
      <Col xs={0} lg={4}>
        {!lg && (
          <Drawer placement="left" closable={false} onClose={onClose} visible={visible}>
            {children}
          </Drawer>
        )}
        {lg && <Card>{children}</Card>}
      </Col>
    </>
  );
};

export default memo(TablesMenuDrawer);
