import React from 'react'
import { MoneyWithdravalWrapper } from './style'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs';
import Price from '../../shared/components/Price';
import { Payments } from '../../interfaces/Payments';

interface MoneyWithdravalProps {
    payment: any;
}

const MoneyWithdravalCard = ({payment}: MoneyWithdravalProps) => {
    const createDate = dayjs(payment.paymentIssued);
    const paidDate = dayjs(payment.paidDate);
    const { t } = useTranslation()
  return (
    <MoneyWithdravalWrapper>
        <ul>
            <li>
                <span>Payment</span>
                <p>{payment.paymentNumber}</p>
            </li>

            {payment.paymentIssued && (<li>
                <span>Created Date</span>
                <p>{createDate.format('DD/MM/YYYY')}</p>
            </li>)}

            {payment.paidDate && (<li>
                <span>Paid Date</span>
               <p>{paidDate?.format('HH:mm A')}</p>
            </li>)}

            <li>
                <span>Sum</span>
                <p> <Price price={payment.sum} /> </p>
            </li>
            <li>
                <span>Status</span>
                <p>{payment.status}</p>
            </li>
        </ul>
    </MoneyWithdravalWrapper>
  )
}

export default MoneyWithdravalCard