/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo } from 'react';
import { TablePaginationConfig } from 'antd';
import { useSelector } from 'react-redux';
import { myOffersListSelector, myOffersListTotalCountSelector, myOffersListIsLoadingSelector } from '../../../store/slices/bidsSlice';
import CustomTable from '../../../shared/components/CustomTable';

interface Props {
  tableChange: (pg: TablePaginationConfig, fltr: any, sort: any) => void;
  columns: any[];
}
const BidsPage: FC<Props> = ({ tableChange, columns }) => {
  const myOffersList = useSelector(myOffersListSelector);
  const myOffersListTotalCount = useSelector(myOffersListTotalCountSelector);
  const myOffersListIsLoading = useSelector(myOffersListIsLoadingSelector);

  return (
    <CustomTable
      data={myOffersList.map((item) => ({ ...item, key: item.productId }))}
      columns={columns}
      totalCount={myOffersListTotalCount}
      isLoading={myOffersListIsLoading}
      onChangeTable={tableChange}
      scroll={{ x: 'max-content' }}
    />
  );
};

export default memo(BidsPage);
