import React from 'react'
import ColorSquare from './ColorSquare'
import Odometer from './Odometer'
import { VehicleInfoWrapper } from './style'
import { Vehicle } from '../../../interfaces/FindVehicles'
import { getOdoValue } from '../../../utils/functions'
import { useSelector } from 'react-redux'
import { odometrIsKmSelector } from '../../../store/slices/initSlice'

interface VehicleInfoProps {
    vehicle: Vehicle
}

const VehicleInfo = ({vehicle}: VehicleInfoProps) => {
    const odometrIsKm = useSelector(odometrIsKmSelector);

  return (
    <VehicleInfoWrapper>
        {vehicle.color && <ColorSquare color={vehicle.color?.toLowerCase()} />}
        {!!vehicle.odometer && <Odometer 
        value={getOdoValue(vehicle.odometer, odometrIsKm, vehicle.odometerBrand).split(" ")[0]}
        /> }
    </VehicleInfoWrapper>
  )
}

export default VehicleInfo