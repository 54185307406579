import { Typography, Row, Col, Input, Form, Space, Button, Divider, Select } from 'antd';
import React, { FC, memo, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getUserContactInfo, userContactInfoSelector, editUserContactInfo } from '../../store/slices/profileSlice';
import { productGeneralDictionariesByLanguageSelector } from '../../store/slices/dictionarySlice';
import { stateCity, cityStates, USAStates } from '../../countries';
import { UserContactInfo } from '../../interfaces/Profile';
import { CardWrapper } from './style';
import { ScTitle } from '../style';
import { disableAutocomplete } from '../../utils/functions';
import ProfilePage from '.';

const { Text } = Typography;
const { Option } = Select;

const layoutForm = {
  labelCol: {
    xs: 24,
    lg: 8,
  },
  wrapperCol: {
    xs: 24,
    lg: 16,
  },
};

const layout = {
  xs: 24,
  lg: 12,
};

const ContactInformation: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getUserContactInfo());
  }, [dispatch]);

  const [dialCode, setDialCode] = useState('');

  useEffect(() => {
    disableAutocomplete();
  }, []);

  const handleChangePhoneInput = (value: string, data: { dialCode: string }) => {
    setDialCode(data.dialCode);
  };

  const onFinish = useCallback(
    (values: UserContactInfo) => {
      dispatch(
        editUserContactInfo(
          values.city,
          values.country,
          values.email,
          values.firstName,
          values.lastName,
          values.lionTranceAccount,
          values.phoneNumber.replace(dialCode, ''),
          dialCode,
          '',
          values.stateProvince,
          '',
          values.zip,
        ),
      );
    },
    [dialCode, dispatch],
  );

  const userContactInfo = useSelector(userContactInfoSelector);
  const productGeneralDictionariesByLanguage = useSelector(productGeneralDictionariesByLanguageSelector);

  const getCityList = useCallback(() => {
    if (form.getFieldValue('country') === 'United States' && form.getFieldValue('stateProvince')) {
      return (stateCity as { [key: string]: string })[form.getFieldValue('stateProvince')]
        .split('|')
        .filter((item: string) => item !== '')
        .map((item: string) => item.replace(/&apos;/g, "'"))
        .sort();
    }
    if (form.getFieldValue('country') === 'United States') return [];
    return (cityStates as { [key: string]: string })[form.getFieldValue('country')]
      .split('|')
      .filter((item: string) => item !== '')
      .map((item: string) => item.replace(/&apos;/g, "'"))
      .sort();
  }, [form]);

  const onChange = useCallback(
    (value) => {
      if (value[0]?.name[0] === 'country') {
        form.setFieldsValue({
          stateProvince: undefined,
          city: undefined,
        });
        disableAutocomplete();
      }
      if (value[0]?.name[0] === 'stateProvince') {
        form.setFieldsValue({
          city: undefined,
        });
        disableAutocomplete();
      }
    },
    [form],
  );

  if (!userContactInfo) return null;

  return (
    <ProfilePage>
      <CardWrapper>
        <ScTitle level={2}>{t('user_contact_information')}</ScTitle>
        <Text>{t('user_contact_information_text')}</Text>
        <Divider />
        <Form
          form={form}
          name="profile"
          onFinish={onFinish}
          onFieldsChange={onChange}
          scrollToFirstError
          {...layoutForm}
          initialValues={{
            city: userContactInfo?.city,
            country: userContactInfo?.country,
            email: userContactInfo?.email,
            firstName: userContactInfo?.firstName,
            lastName: userContactInfo?.lastName,
            lionTranceAccount: userContactInfo?.lionTranceAccount,
            phoneNumber: `995${userContactInfo?.phoneNumber}`,
            physicalAddress: '',
            stateProvince: userContactInfo?.stateProvince,
            vehicleTitlesIssuedTo: '',
            zip: userContactInfo?.zip,
          }}
        >
          <Row>
            <Col {...layout}>
              <Form.Item name="firstName" label={t('reg_per_first_name')} rules={[{ required: true, message: 'Required' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="lastName" label={t('reg_per_last_name')} rules={[{ required: true, message: 'Required' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="email" label={t('reg_per_email')} rules={[{ required: true, message: 'Required' }]}>
                <Input />
              </Form.Item>
              {/* <Form.Item name="vehicleTitlesIssuedTo" label={t('user_vehicle_titles_issued_to')}>
                <Text>{userContactInfo?.vehicleTitlesIssuedTo}</Text>
              </Form.Item>
              <Form.Item name="physicalAddress" label={t('user_physical_adress')}>
                <Text>{userContactInfo?.physicalAddress}</Text>
              </Form.Item> */}
              <Form.Item name="lionTranceAccount" label={t('user_lion_auto_auction_account')}>
                <Text>{userContactInfo?.lionTranceAccount}</Text>
              </Form.Item>
            </Col>
            <Col {...layout}>
              <Form.Item name="country" label={t('reg_comp_choose_type6')} rules={[{ required: true, message: 'Required' }]}>
                <Select
                  placeholder="Country"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {productGeneralDictionariesByLanguage.countriesList?.map((item) => (
                    <Option key={item.id} value={item.value}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item noStyle shouldUpdate={(prevValues: UserContactInfo, currentValues: UserContactInfo) => prevValues.country !== currentValues.country}>
                {({ getFieldValue }) =>
                  getFieldValue('country') === 'United States' ? (
                    <Form.Item name="stateProvince" label={t('reg_comp_choose_type7')} rules={[{ required: true, message: 'Required' }]}>
                      <Select showSearch placeholder="State (Only for the USA)">
                        {USAStates?.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues: UserContactInfo, currentValues: UserContactInfo) =>
                  currentValues.country === 'United States'
                    ? prevValues.stateProvince !== currentValues.stateProvince
                    : prevValues.country !== currentValues.country
                }
              >
                {({ getFieldValue }) =>
                  (getFieldValue('country') === 'United States' && getFieldValue('stateProvince')) || getFieldValue('country') ? (
                    <Form.Item name="city" label={t('reg_comp_choose_type8')} rules={[{ required: true, message: 'Required' }]}>
                      <Select showSearch placeholder="City">
                        {getCityList().map((item: string) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item name="zip" label={t('user_zip_postal_code')} rules={[{ required: true, message: 'Required' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="phoneNumber" label={t('reg_per_phone_number')} rules={[{ required: true, message: 'Required' }]}>
                <PhoneInput onChange={handleChangePhoneInput} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Space>
              <Button type="primary" htmlType="submit">
                {t('Save')}
              </Button>
              <Button danger>{t('Cancel')}</Button>
            </Space>
          </Row>
        </Form>
      </CardWrapper>
    </ProfilePage>
  );
};

export default memo(ContactInformation);
