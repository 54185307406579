/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import api from '../../core/api';
import { axiosInstance } from '../../core/axios';
import axios from 'axios';
import { useSelector } from 'react-redux';

type Dealer = any

export interface InitState {
  token: null | string,
  dealerList: Dealer[];
  currentDealer: null | Dealer
}

export const initialState: InitState = {
  token: null,
  dealerList: [],
  currentDealer: null
};

export const dealerSlice = createSlice({
  name: 'dealer',
  initialState,
  reducers: {
    setDealerList: (state, action) => {
      state.dealerList = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setCurrentDealer: (state, action) => {
      state.currentDealer = action.payload
      }
  },
});

export const { setDealerList, setToken, setCurrentDealer } = dealerSlice.actions;

export const getDealerToken = (): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstance.post("/Dealer/GetToken", {
      "username": "LT-API-USER",
      "password": "1-a@Adz@p#xj@I0/asd|$has|1aq3p12p7o943askf"
    });

    dispatch(setToken(res.data.result.token))
  } catch (error: any) {
    console.error(error)
  }
};

export const getDealerDetailsById = (dealerId: string | number): AppThunk => async (dispatch, state) => {
  try {
    dispatch(setDealerList([]))
    dispatch(setCurrentDealer(null))
    const token = state().dealer.token;
    if(!token) return 
    const res = await axiosInstance.post("/Dealer/GetDealerDetails", {
      "dealerId": dealerId,
      "token": token,
    })

    const data = res.data.result
    dispatch(setDealerList(data))
    data && dispatch(setCurrentDealer(data[0]))
  } catch (error) {
    console.error(error)
  }
}

export const getDealers = (status?: boolean): AppThunk => async (dispatch) => {
  try{
    dispatch(setDealerList([]))
    dispatch(setCurrentDealer(null))
    const res = await axiosInstance.get(`https://apidealer.payauto.de/api/Dealers/GetDealerMainResponse?status=${status || false}`)
    dispatch(setDealerList(res.data))
  } catch (error) {
    console.error(error)
  }
}

export const getDealersById = (id?: number | string): AppThunk => async () => {
  try{
    const res = await axiosInstance.get(`https://apidealer.payauto.de/api/Dealers/GetDealerMainResponseById?dealerId=${id}`)
    return res
  } catch (error) {
    console.error(error)
  }
}

export const getDealerReviews = (id: number): AppThunk => async (dispatch) => {
  try{
    const res = await axiosInstance.get("/Review/GetDealerReviews", {
      params: {
        dealerId: id
      }
    })

    return res.data
  } catch (error) {
    console.error(error)
  }
}

export const getDealerRate = (id: number): AppThunk => async (dispatch) => {
  try{
    const res = await axiosInstance.get("/Review/GetDealerRate", {
      params: {
        dealerId: id
      }
    })

    return res.data
  } catch (error) {
    console.error(error)
  }
}

export const addDealerReviews = (data: any): AppThunk => async (dispatch) => {
  try{
    const res = await axiosInstance.post("/Review/AddDealerReview", data)
  } catch (error) {
    console.error(error)
  }
}

export const getSaleExpertReviews = (id: string): AppThunk => async (dispatch) => {
  try{
    const res = await axiosInstance.get("/Review/GetSaleExpertReviews", {
      params: {
        SaleExpertId: id
      }
    })

    return res.data
  } catch (error) {
    console.error(error)
  }
}

export const getSaleExpertRate = (id: string): AppThunk => async (dispatch) => {
  try{
    const res = await axiosInstance.get("/Review/GetSaleExpertRate", {
      params: {
        SaleExpertId: id
      }
    })

    return res.data
  } catch (error) {
    console.error(error)
  }
}

export const addSaleExpertReview = (data: any): AppThunk => async (dispatch) => {
  try{
    const res = await axiosInstance.post("/Review/AddSaleExpertReview", data)
  } catch (error) {
    console.error(error)
  }
}

export const getSuggestedReviewInfo = (id: string): AppThunk => async (dispatch) => {
  try{
    const res = await axiosInstance.get("/Review/GetSuggestedReviewInfo", {
      params: {
        oneTimeCode: id
      }
    })

    return res.data
  } catch (error) {
    console.error(error)
  }
}

export const updateSuggestedReview = (data: any): AppThunk => async (dispatch) => {
  try{
    const res = await axiosInstance.post("/Review/UpdateSuggestedReview", data)
  } catch (error) {
    console.error(error)
  }
}

// SELECTORS
export const dealerListSelector = (state: RootState) => state.dealer.dealerList;
export const dealerTokenSelector = (state: RootState) => state.dealer.token;
export const currentDealerSelector = (state: RootState) => state.dealer.currentDealer;

export default dealerSlice.reducer;