import React, { useEffect } from 'react'
import { MobileFilterHeader, MobileFilterHeaderButtonVariant, MobileFilterWrapper } from '../style'
import { ReactSVG } from 'react-svg'

interface MobileFilterProps {
    children: JSX.Element,
    onClose: () => void
}

const MobileFilter = ({children, onClose}: MobileFilterProps) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflow = "hidden"

        return () => {
            document.body.style.overflow = "initial"
        }
    }, [])

  return (
    <MobileFilterWrapper>
      <MobileFilterHeader buttonVariant={MobileFilterHeaderButtonVariant.CLOSE}>
        <span>Filter</span>
        <button onClick={onClose}>
          <ReactSVG src="/icons/close.svg"/>
        </button>
      </MobileFilterHeader>
      {children}
  </MobileFilterWrapper>
  )
}

export default MobileFilter