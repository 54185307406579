import { Button, Form, Select, TreeSelect } from 'antd';
import styled from 'styled-components';

export const PersonalSearchWrapper = styled.div`
  padding: 3.75rem 3.75rem 2.5rem;
  .ant-select-selection-placeholder{
    font-family: 'main';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #566063;
  }
  @media(max-width: 1200px){
    padding: 2.75rem 1.5rem 2rem;
  }
  @media(max-width: 767px){
    padding: 1.25rem 1rem 1.5rem;
    .ant-select-selection-placeholder{
      font-size: 0.75rem;
      line-height: 2rem !important;
    }
    .ant-select-multiple .ant-select-selector{
      padding: 0;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
      height: 32px;
    }
  }
`;

export const PersonalSearchForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  @media(max-width: 1200px){
    gap: 1rem;
  }
  @media(max-width: 767px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width: 580px){
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledTreeSelect = styled(TreeSelect)`
  width: 100%;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const ScFormItem = styled(Form.Item)`
  height: 40px;
  margin: 0;
`;

export const SearchActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3.75rem;
  @media(max-width: 1200px){
    margin-top: 2rem;
  }
  @media(max-width: 580px){
    justify-content: center;
  }
`;

export const AdvanceSearchBtn = styled.button`
  font-family: 'main';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #1b153d;
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  & > div{
    margin-right: 1rem;
  }
  @media(max-width: 767px){
    display: none;
  }
`;

export const SearcBtn = styled.button`
  font-family: 'main';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #ffffff;
  background: #CE2424;
  border-radius: 8px;
  padding: 0.875rem 2.5rem;
`;

export const PopularVehicleBtn = styled(Button)`
  margin: 8px 2px 8px 2px;
  ${({ selected }) => selected && `
    border-color: var(--primary);
  `}
`;