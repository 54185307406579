import React from 'react'
import { ContentWrapper, ImageWrapper, TeamCardWrapper } from './style'
import { useTranslation } from 'react-i18next'

interface TeamCardProps {
  person: Person
}

export interface Person {
  id: number,
  name: string,
  position: string,
  image: string
}

const TeamCard = ({person}: TeamCardProps) => {
  const { t } = useTranslation()
  return (
    <TeamCardWrapper>
        <ImageWrapper>
            <img src={person.image} alt={t(person.name)} />
        </ImageWrapper>
        <ContentWrapper>
            <h3>{t(person.name)}</h3>
            <p>{t(person.position)}</p>
        </ContentWrapper>
    </TeamCardWrapper>
  )
}

export default TeamCard