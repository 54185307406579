import styled, { css } from 'styled-components';
import { ServiceCardWrapper } from './components/ServiceCard/style';

export const BannerWrapper = styled.div`
    height: auto;
    max-height: calc(100vh - 25rem);
    min-height: 35rem;
    width: 100%;
    aspect-ratio: 2.7;
    overflow: hidden;
    position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 37, 42, 0.46);
  }
  @media (max-width: 767px) {
    height: 6.25rem;
  }
`;

export const Container = styled.div`
  max-width: 100%;
  width: 100%;
    margin-right: auto;
  margin-left: auto;
  padding: 0 0.625rem;
`;

export const PageTitle = styled.h1`
  font-family: 'main';
  &:lang(ka){
      font-family: 'arial-caps';
  }
  font-weight: 800;
  font-size: 2rem;
  line-height: 3.75rem;
  color: #080808;
  margin: 2.75rem 0 0;
  position: relative;
  width: fit-content;
  &::after{
    content: "";
    width: 100%;
    height: 0.5rem;
    background: #CE2424;
    border-radius: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(0, calc( 100% + 1rem))
  }
  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1B153D;
    margin-top: 1.5rem;
    &::after{
      height: 2px;
    }
  }
`;

export const Section = styled.section``;

export const SectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`

export const SectionTitle = styled.h2`
  font-family: 'main';
  &:lang(ka){
      font-family: 'arial-caps';
  }
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #080808;
  margin: 0;
  margin-bottom: 2.5rem;
  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #1B153D;
    margin-bottom: 1.5rem;
  }
`

export const ServiceCardList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const ServiceCardListTrans = styled(ServiceCardList)`
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
`

export const AutoAuctionSectionWrapper = styled(Section)`
  margin: 5rem 0 0;
  @media (max-width: 767px) {
    margin: 3.5rem 0 0;
  }
`

export const TransSectionWrapper = styled(Section)`
  background: #080808;
  margin-top: 6.5rem;
  padding: 2.125rem 0 2rem;
  & ${SectionTitle}{
    color: #FFFFFF;
  }
`;

export const TerminalPotiSectionWrapper = styled(Section)`
  margin: 4.25rem 0 2.25rem;
  & ${SectionContainer}{
    padding: 2.5rem 2.5rem 3.75rem;
    border: 1px solid #EAEAEA;
    border-radius: 0.5rem;
    @media (max-width: 1200px) {
      padding: 1.5rem 1rem 2.5rem;
    }
    @media (max-width: 767px) {
      border: none;
      padding: 0 1rem;
    }
  }
  & ${ServiceCardList}{
    padding: 0 0.5rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.75rem;
    @media (max-width: 1200px) {
      gap: 1.5rem;
      padding: 0;
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;
    }
  }
  & ${ServiceCardWrapper} {
    padding: 2.25rem 1.25rem 1.75rem;
    h3{
      margin-top: 1.5rem;
    }
    p{
      margin-top: 2.25rem;
    }
    @media (max-width: 767px) {
      padding: 1.25rem;
      h3{
        margin: 0;
      }
      p{
        margin: 0;
      }
    }
  }
`