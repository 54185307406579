import React, { FC, useEffect, memo, useCallback, useState, useMemo } from 'react';
import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { getFindVehicles } from '../../store/slices/findVehiclesSlice';
import { getTransitAndYardDefinition, transitAndYardDefinitionSelector, userWatchListSelector } from '../../store/slices/productSlice';
import { getAllAuctionsAddressesAsDictionary } from '../../store/slices/auctionSlice';
import FilterCollapse from './containers/FilterCollapse';
import SearchTable from './containers/SearchTable';
import ActionContainer from './containers/ActionContainer';
import { FindVehiclesWrapper, ScDrawer, SearchContentWrapper, SidebarWrapper } from './style';
import SearchFilterContainer from '../../shared/components/SearchFilterContainer';
import { ReactSVG } from 'react-svg';
import MobailSearch from './containers/MobailSearch';
import MobileFilter from './components/MobileFilter';
import MobileFilterHeader from '../../shared/components/MobileFilterHeader';
import i18n from '../../i18n';
import Meta from '../../shared/components/Meta';

const FindVehiclesPage: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const { lg } = useBreakpoint();

  const userWatchList = useSelector(userWatchListSelector);
  const transitAndYardDefinition = useSelector(transitAndYardDefinitionSelector);
  const pathParts = location.pathname.split('/');
const watchListPath = location.pathname.includes('/en') || location.pathname.includes('/ru')
  ? pathParts[2]
  : pathParts[1];
  const [isWatchList] = useState<boolean>(watchListPath === 'mywatchlist');

  const [searchString, setSearchString] = useState(location.pathname.includes('/en') || location.pathname.includes('/ru') ? null : location.pathname.split('/')[2]);
  const [dictionaryForTransitAndYardDefinition, setDictionaryForTransitAndYardDefinition] = useState<{ id: string; value: string }[]>([]);

  const getParamValue = useCallback((field: string) => params.get(field)?.replace(/%2C/g, ',') || undefined, [params]);

  useEffect(() => {
    setSearchString(location.pathname.includes('/en') || location.pathname.includes('/ru') ? null : location.pathname.split('/')[2]);
  }, [location]);

  const getModelForRequest = useCallback(() => {
    const vehiclemodel = getParamValue('vehiclemodel')?.split(',');
    const models: string[] = [];

    vehiclemodel?.map((item) => {
      const data = item.split('/');
      const seria = data[1]?.replace(/_/g, ' ');
      const model = data[2]?.replace(/_/g, ' ');

      seria && models.push(seria);
      model && models.push(model);
      return null;
    });
    const d = new Set(models);
    return d.size ? [...d].join(',') : undefined;
  }, [getParamValue]);

  // const parseProps = (val?: string) => {
  //   if (val) {
  //     const newVal = val.split(',').map((item) => {
  //       const style = item.split('_');
  //       if (style.length > 1) return style[1];
  //       return style[0];
  //     });
  //     return newVal.join();
  //   }
  //   return undefined;
  // };

  const parsePropsDamageStyle = (val?: string) => {
    const Defect: string[] = [];
    const DamageStyle: string[] = [];
    if (val) {
      val.split(',').forEach((item) => {
        const style = item.split('_');
        if (style.length > 1) {
          DamageStyle.push(style[1]);
        } else {
          !Defect.includes(style[0]) && Defect.push(style[0]);
        }
      });
      return {
        Defect: Defect.join(),
        DamageStyle: DamageStyle.join(),
      };
    }
    return undefined;
  };

  const parsePropsBodyStyle = (val?: string) => {
    const AutomobileType: string[] = [];
    const BodyStyle: string[] = [];
    if (val) {
      val.split(',').forEach((item) => {
        const style = item.split('_');
        if (style.length > 1) {
          BodyStyle.push(style[1]);
        } else {
          !AutomobileType.includes(style[0]) && AutomobileType.push(style[0]);
        }
      });
      return {
        AutomobileType: AutomobileType.join(),
        BodyStyle: BodyStyle.join(),
      };
    }
    return undefined;
  };


  function findObjectIdsByNames(names: string): any {
    if (!names) return;
    const modelNames = names.split(',').map(name => {
      return name.split('(')[1]?.trim().slice(0, -1);
    });

    const objectIds: number[] = [];

    modelNames.forEach(modelName => {
      const foundCountry = transitAndYardDefinition.find(country => country.models.some((model: { objectName: string; }) => model.objectName === modelName));
      if (foundCountry) {
        const foundModel = foundCountry.models.find((model: { objectName: string; }) => model.objectName === modelName);
        if (foundModel) {
          objectIds.push(foundModel.objectId);
        }
      }
    });
    return objectIds.join(',');
  }

  const updateFindVehicles = useCallback(() => {
    const Page = params.get('page');
    const PageItemCount = params.get('itemscount');
    const SortField = params.get('sortField');
    const SortOrder = params.get('sortOrder');

    const carLocationIds = findObjectIdsByNames(getParamValue('carlocation') || '');
    dispatch(
      getFindVehicles(
        isWatchList, // isWatchList
        Page ? +Page : 1, // Page
        PageItemCount ? +PageItemCount : 20, // PageItemsCount
        SortField || 'lotNumber', // SortField
        SortOrder || 'DESC', // SortOrder
        searchString, // searchString
        (getParamValue('newlyaddedlots') && (getParamValue('newlyaddedlots') === 'Last 24 Hours' ? 1 : 7)) || undefined, // DaysFromCreateLessThanOrEqual
        getParamValue('featuredtype'), // FeaturedType
        carLocationIds, // YardLocationId
        getParamValue('vehiclemake')?.replace(/_/g, ' '), // Make
        getModelForRequest(), // Model
        getParamValue('color'), // Color
        getParamValue('vehicleyear'), // Year
        getParamValue('yearfrom'), // Year From
        getParamValue('yearto'), // Year To
        getParamValue('auctiontype'), // auctiontype
        getParamValue('vehicletype'), // VehicleType
        getParamValue('auctionsaddress'), // Location
        getParamValue('vehicleodometrfrom'), // OdometerGreaterThanOrEqual
        getParamValue('vehicleodometrto'), // OdometerLessThan
        getParamValue('vehiclepricefrom'), // Price From
        getParamValue('vehiclepriceto'), // Price To
        parsePropsDamageStyle(getParamValue('defect'))?.DamageStyle, // DamageStyle
        parsePropsBodyStyle(getParamValue('bodystyle'))?.BodyStyle, // BodyStyle
        getParamValue('fueltype'), // FuelType
        getParamValue('enginetype'), // EngineType
        getParamValue('transmission'), // Transmission
        getParamValue('drivetrain'), // DriveTrain
        getParamValue('cylinder'), // Cylinder
        getParamValue('source'), // Source
        getParamValue('laneids'), // LaneIDs
        getParamValue('customs'), // Customs
        parsePropsDamageStyle(getParamValue('defect'))?.Defect, // Defect
        parsePropsBodyStyle(getParamValue('bodystyle'))?.AutomobileType, // AutomobileType
      ),
    );
  }, [params, dictionaryForTransitAndYardDefinition, dispatch, isWatchList, searchString, getParamValue, getModelForRequest]);

  useEffect(() => {
    updateFindVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, searchString, transitAndYardDefinition, updateFindVehicles]);

  useEffect(() => {
    isWatchList && updateFindVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWatchList, transitAndYardDefinition, updateFindVehicles]);

  useEffect(() => {
    dispatch(getTransitAndYardDefinition());
    dispatch(getAllAuctionsAddressesAsDictionary());
  }, [dispatch, i18n.language]);


  useEffect(() => {
    setDictionaryForTransitAndYardDefinition(
      transitAndYardDefinition.map((item) => ({
        id: `${item.objectId}_${item.locationType}`,
        value: `${item.countryName} (${item.objectName})`,
      })),
    );
  }, [transitAndYardDefinition]);

  const [visible, setvisible] = useState(false);

  const showDrawer = () => {
    setvisible(true);
  };

  const onClose = () => {
    setvisible(false);
  };

  const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false)

  useEffect(() => {
    if(lg === false && !isWatchList){
      history.push({
        pathname: "/classicsearch",
      });
    }
  }, [lg])

  if(lg){
    return (
      <><Meta
        title={i18n.t("meta_carsearch_title")}
        description={i18n.t("meta_carsearch_desc")} /><FindVehiclesWrapper>
          <SidebarWrapper>
            <FilterCollapse />
          </SidebarWrapper>
          <SearchContentWrapper>
            {/* <SearchFilterContainer /> */}
            <SearchTable />
          </SearchContentWrapper>
        </FindVehiclesWrapper></>
    )
  }else{
    return (
    <FindVehiclesWrapper>
      <MobileFilterHeader onButtonHandle={() => setIsMobileFilterVisible(true)} />
      {isMobileFilterVisible && <MobileFilter onClose={() => setIsMobileFilterVisible(false)}>
        <FilterCollapse />
      </MobileFilter>}
      {/* <SearchFilterContainer /> */}
      <MobailSearch />
    </FindVehiclesWrapper>
    );
  }
};

export default memo(FindVehiclesPage);
