import { Tabs } from 'antd';
import React, { FC, memo } from 'react';
import Notes from '../components/Notes';
import ServiceOrder from '../components/ServiceOrder';
import Overview from '../components/Overview';
import AssignmentMaintenance from '../components/AssignmentMaintenance';
import { SellerOneLotPageData } from '../../../interfaces/Product';

const { TabPane } = Tabs;

interface Props {
  sellerOneLotPageData: SellerOneLotPageData;
}
const MenuContainer: FC<Props> = ({ sellerOneLotPageData }) => {
  if (!sellerOneLotPageData) return null;

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Overview" key="1">
        <Overview lotData={sellerOneLotPageData} />
      </TabPane>
      <TabPane tab="Assignment Maintenance" key="2">
        <AssignmentMaintenance lotData={sellerOneLotPageData} />
      </TabPane>
      <TabPane tab="Service Order" key="3">
        <ServiceOrder serviceOrder={sellerOneLotPageData.serviceOrder} />
      </TabPane>
      <TabPane tab="Notes" key="4">
        <Notes notes={sellerOneLotPageData.notes} lotId={sellerOneLotPageData.assignmentMaintenance.lotId} />
      </TabPane>
    </Tabs>
  );
};

export default memo(MenuContainer);
