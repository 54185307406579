import React, { FC } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 2rem auto 0;
  width: 83%;
`;

const ContentWrapper = ({ children, styles }: any) => <Wrapper style={styles ? ({...styles}) : {}}>{children}</Wrapper>;

export default ContentWrapper;
