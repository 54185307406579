import React, { FC, memo, forwardRef, ForwardRefRenderFunction, useRef } from 'react';
import { Row } from 'antd';
import SearchPagination from '../components/SearchPagination';
import ResultLotsList from '../components/ResultLotsList';

interface ResultContainerProps {
  total: number;
}

const ResultContainer: ForwardRefRenderFunction<HTMLDivElement, ResultContainerProps> = ({ total }, ref) => {
  return (
    <>
      <ResultLotsList ref={ref} />
      <SearchPagination total={total} />
    </>
  );
};

export default memo(forwardRef(ResultContainer));
