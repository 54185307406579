import React, { FC, memo, useEffect, useState } from 'react';
import { Button } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import styled from 'styled-components';
import { LaneActivationPeriod } from '../../../interfaces/Auction';
import { BlinkJoin } from './style';
import { checkIsLive } from '../../../utils/functions';

interface Props {
  time: LaneActivationPeriod | undefined;
  onClick: () => void;
}

export const ScCountdown = styled(Countdown)`
  .ant-statistic-content {
    font-size: 13px;
  }
`;

const TimerButton: FC<Props> = ({ time, onClick }) => {
  const [isJoin, setIsJoin] = useState(false);

  useEffect(() => {
    time && setIsJoin(checkIsLive(time.startTime));
  }, [time]);

  if (!time) return null;

  return (
    <Button type="primary" disabled={!isJoin} onClick={onClick} size="large">
      {isJoin ? (
        <BlinkJoin>Join to Live</BlinkJoin>
      ) : (
        <ScCountdown
          title=""
          value={new Date(
            new Date().setHours(new Date(time.startTime).getHours(), new Date(time.startTime).getMinutes(), new Date(time.startTime).getSeconds()),
          ).toISOString()}
          format="HH[H] mm[M] ss[S]"
        />
      )}
    </Button>
  );
};

export default memo(TimerButton);
