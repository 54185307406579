import { Row, Col, Button } from 'antd';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import {
  auctionPanelsSelector,
  getAuctionDashboardLanesList,
  setAuctionPanels,
  resetAuctionPanels,
  auctionDashboardLanesListSelector,
  openLanesSelector,
  setAuctionDashboardLanesListCurrent,
  getAuctionDashboardLotsList,
  openLanesUpdate,
} from '../../store/slices/auctionSlice';
import { StyledContainer } from '../style';
import { CalendarDashboardLane } from '../../interfaces/Auction';
import Panel from './Panel';

const AuctionDashboardPage: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);

  const auctionPanels = useSelector(auctionPanelsSelector);
  const auctionDashboardLanesList = useSelector(auctionDashboardLanesListSelector);
  const openLanes = useSelector(openLanesSelector);

  useEffect(() => {
    dispatch(getAuctionDashboardLanesList());
    return () => {
      dispatch(resetAuctionPanels());
    };
  }, [dispatch]);

  const handleAddAuction = useCallback(() => {
    const panels = JSON.parse(JSON.stringify(auctionPanels));
    panels[new Date().getTime()] = null;
    dispatch(setAuctionPanels(panels));
  }, [dispatch, auctionPanels]);

  // If lane in url but not open
  useEffect(() => {
    const lanes =
      params
        .get('laneId')
        ?.split(',')
        .map((item) => +item) || [];
    const newPanels = JSON.parse(JSON.stringify(auctionPanels));
    if (lanes.length && !openLanes.length && auctionDashboardLanesList.length) {
      dispatch(openLanesUpdate(Array.from(new Set(lanes))));
      lanes.map((item, index) => {
        const firstKey = Object.keys(auctionPanels)[0];
        const currentLane = auctionDashboardLanesList.find((lane) => lane.laneId === item);
        if (index === 0 && firstKey) {
          newPanels[firstKey] = currentLane?.laneId;
          dispatch(getAuctionDashboardLotsList(item, +firstKey));
        } else {
          const key = new Date().getTime();
          newPanels[key] = currentLane?.laneId;
          dispatch(getAuctionDashboardLotsList(item, key));
        }
        return null;
      });
    }
  }, [dispatch, openLanes, params, auctionPanels, auctionDashboardLanesList]);

  useEffect(() => {
    let currentLines: CalendarDashboardLane[] = [];
    if (openLanes.length) {
      currentLines = auctionDashboardLanesList.filter((item) => !openLanes.includes(item.laneId));
    } else {
      currentLines = [...auctionDashboardLanesList];
    }
    dispatch(setAuctionDashboardLanesListCurrent(currentLines));
  }, [auctionDashboardLanesList, dispatch, openLanes]);

  return (
    <StyledContainer>
      <Row justify="center">
        <Col xs={24} lg={24}>
          <Button type="primary" onClick={handleAddAuction} block>
            {t('auction_add_panel')}
          </Button>
        </Col>
      </Row>
      <Row justify="center" gutter={[16, 16]}>
        {Object.keys(auctionPanels).map((panel, index) => (
          <Panel
            key={panel}
            panelId={+panel}
            panelOrder={index + 1}
            laneId={auctionPanels[panel]}
            totalPanels={Object.keys(auctionPanels).length}
            totalCountAuctions={auctionDashboardLanesList.length}
          />
        ))}
      </Row>
    </StyledContainer>
  );
};

export default memo(AuctionDashboardPage);
