import React from 'react'
import { ImageLink } from './style'
import { Image } from 'antd';

const ImageColumn = ({to, mainImage}: any) => {
  return (
    <ImageLink to={to}>
        <Image height={60} preview={false} src={mainImage || 'error'} fallback="img/noImg.jpg" alt="Lot image" />
    </ImageLink>
  )
}

export default ImageColumn