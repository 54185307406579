import React from 'react';
import 'slick-carousel/slick/slick.css';
import { useHistory } from 'react-router';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { SliderItemContent, SliderItemDesc, SliderItemTitle, SliderItemWrapper, SliderWrapper } from './style';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import './custom-slick.css';
import { langPrefix } from '../../../utils/functions';

const MainSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { t } = useTranslation()
  const { md } = useBreakpoint()

  return (
    <SliderWrapper>
        {/* <SliderItemWrapper onClick={onSliderClick}>
          <SliderItemContent>
            <SliderItemTitle>
              {parse(t("main_banner_title"))}
            </SliderItemTitle>
            <SliderItemDesc>
              {t('main_banner_description')}
            </SliderItemDesc>
          </SliderItemContent>
          <img src="/img/banners/iaa-alilance.png?v=11" alt="" />
          <video autoPlay={true} muted loop>
            <source src="/DRONE.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </SliderItemWrapper> */}
      <Slider {...settings} >
        <SliderItemWrapper className='alliance'>
        <SliderItemContent as={"a"} href={langPrefix("/services")}>
            <SliderItemDesc>
            {parse(t("main_banner_description"))}
            </SliderItemDesc>
          </SliderItemContent>
          {md
          ? <img src="/img/banners/main_banner_1_2.webp?v=3" alt="" />
          : <img src="/img/banners/main_banner_mobile_1.webp?v=3" alt="" />}
        </SliderItemWrapper>
        <SliderItemWrapper className='moige' as={"a"} href={langPrefix("/blog/62/%E1%83%9A%E1%83%90%E1%83%98%E1%83%9D%E1%83%9C-%E1%83%A2%E1%83%A0%E1%83%90%E1%83%9C%E1%83%A1%E1%83%98-IAA-%E1%83%A1-")}>
          {md
          ? <img src="/img/banners/main_banner_2_2.webp?v=3" alt="" />
          : <img src="/img/banners/main_banner_mobile_2_1.webp?v=3" alt="" />}
        </SliderItemWrapper>
      </Slider>
    </SliderWrapper>
  );
};

export default MainSlider;
