export const White = '#ffffff';
export const Red = '#d71f0f';
export const Accent = '#fdc500';
export const BannerBgCl = '#ffe7c7';
export const Primary = '#212121';
export const DarkPrimary = '#313233';
export const DarkPrimaryShadow = 'rgba(54, 55, 56, 0.96)';
export const Grey = '#999999';
export const Yellow = '#fdc500';
export const LightGrey = '#eeeeee';
export const LightGreyBackground = '#f6f6f6';
export const BorderGrey = '#dddddd';
export const LightBlue = '#55acee';
export const LightBlueBackground = '#eef5ff';
export const DarkShadow = 'rgba(0, 0, 0, 0.75)';
export const Shadow = 'rgba(0, 0, 0, 0.5)';
export const LightShadow = 'rgba(0, 0, 0, 0.15)';
export const LightWhiteShadow = 'rgba(255, 255, 255, 0.85)';
export const Opacity50 = 'rgba(255, 255, 255, 0.5)';
export const LightBorder = 'rgba(255, 255, 255, 0.1)';
export const SuccessBgCl = 'rgba(36, 162, 107, 0.2)';
export const ErrorBgCl = 'rgba(255, 77, 79, 0.2)';
export const ErrorCl = '#d71f0f';
export const SuccessCl = '#24a26b';

export const firstSliderCl = '#9ec34e';
export const thirdSliderCl = '#ec8b24';
