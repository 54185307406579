import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

const shine = keyframes`
  to {
    background-position-x: -200%;
  }
`
const loadingCss = css`
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s ${shine} linear infinite;
`

export const LotCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 0px 50px #E8E8E8;
  padding: 0.75rem;
  border-radius: 8px;
`

interface LotCardImageProps {
  isLoading?: boolean
}

export const LotCardImage = styled.div<LotCardImageProps>`
  width: 6rem;
  aspect-ratio: 1;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ul{
    position: absolute;
    left: 4px;
    bottom: 4px;
    display: flex;
    gap: 0.25rem;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  li{
    font-family: 'main';
    font-weight: 600;
    font-size: 0.5rem;
    line-height: 0.875rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    padding: 2px;
    color: #9095A6;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    padding: 0.125rem 0.25rem;
    strong{
      color: #1B153D;
    }
  }

  ${p => p.isLoading && loadingCss}
`

export const SearchContentBody = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

interface LotCardContentProps {
  isLoading?: boolean
}

export const LotCardContent = styled.div<LotCardContentProps>`
  display: flex;
  flex-direction: column;
  h3{
    font-family: 'main';
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #1B153D;
  }
  h4{
    font-family: 'main';
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.875rem;
    color: #1B153D;
  }
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 1rem;
      li{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.625rem;
        line-height: 0.875rem;
        color: #9095A6;
        margin-top: 0.25rem;
        strong{
          font-family: 'main';
          font-weight: 400;
          font-size: 0.625rem;
          line-height: 0.75rem;
          color: #1B153D;
        }
      }
  }

  ${p => p.isLoading && css`
      ${loadingCss}
      width: 100%;
      height: 100%;
  `}
`

export const Bagdes = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`

export const VinBagde = styled.span`
  font-family: 'main';
  font-weight: 500;
  font-size: 10px;
  line-height: 0.875rem;
  color: #1B153D;
  background: rgba(202, 202, 202, 0.1);
  border: 1px solid #CACACA;
  border-radius: 4px;
  padding: 0 0.25rem;
  height: 1rem;
`

export const PaymentCardWrapper = styled.div`
  margin-top: 1.25rem;
  ul{
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
  li{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  h3{
    font-family: 'main';
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.875rem;
    color: #1B153D;
    margin: 0;
  }
  strong{
    font-family: 'main';
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #1B153D;
  }
  span{
    font-family: 'main';
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #CE2424;
  }
`

export const LotCardFooter = styled.div`
  border-top: 1px solid #EAEAEA;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
`