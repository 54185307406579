import { Row, Button, Space } from 'antd';
import React, { FC, memo, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import {
  getSearchDashboardFilter,
  searchDashboardFilterSelector,
  deleteDashboardFilter,
  searchDashboardFilterLoadingSelector,
} from '../../store/slices/dashboardSlice';
import { SearchDashboardFilterItem } from '../../interfaces/Dashboard';
import TableFooter from '../../shared/components/TableFooter';
import CustomTable from '../../shared/components/CustomTable';

const SavedSearchesPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);

  useEffect(() => {
    dispatch(getSearchDashboardFilter());
  }, [dispatch]);

  const searchDashboardFilter = useSelector(searchDashboardFilterSelector);
  const searchDashboardFilterLoading = useSelector(searchDashboardFilterLoadingSelector);

  const handleStartNewSearch = useCallback(() => {
    params.set('vehicletype', 'Automobile');
    history.push({
      pathname: '/findvehicles',
      search: params.toString(),
    });
  }, [history, params]);

  const handleOpenSearch = useCallback(
    (filter: string) => () => {
      const filterObject = JSON.parse(filter);
      Object.keys(filterObject).map((key) => {
        params.set(key, filterObject[key].replace(/%20/g, ' '));
        return null;
      });

      history.push({
        pathname: '/findvehicles',
        search: params.toString(),
      });
    },
    [history, params],
  );

  const handleDeleteSearch = useCallback(
    (id: number) => () => {
      dispatch(deleteDashboardFilter(id));
    },
    [dispatch],
  );

  const columns = [
    { title: 'Name', dataIndex: 'templateName', key: 'templateName' },
    {
      title: 'Action',
      render: (id: number, filter: SearchDashboardFilterItem) => (
        <Space>
          <Button size="small" onClick={handleDeleteSearch(filter.id)} icon={<DeleteOutlined />}>
            Delete
          </Button>
          <Button size="small" type="primary" onClick={handleOpenSearch(filter.filter)}>
            Search
          </Button>
        </Space>
      ),
    },
  ];

  const renderFooter = () => (
    <TableFooter total={searchDashboardFilter.length}>
      <Button size="large" type="default" danger onClick={handleStartNewSearch}>
        Start New Search
      </Button>
    </TableFooter>
  );

  return (
    <Row justify="center">
      <CustomTable data={searchDashboardFilter} columns={columns} totalCount={searchDashboardFilter.length} isLoading={searchDashboardFilterLoading}>
        {renderFooter()}
      </CustomTable>
    </Row>
  );
};

export default memo(SavedSearchesPage);
