/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, ChangeEvent, memo, Key } from 'react';
import { Input, Tree } from 'antd';
import styled from 'styled-components';
import { Scrollbars } from 'rc-scrollbars';
import { MakeModel } from '../../../interfaces/FindVehicles';
import { SearchWrapper } from './style';
import { ReactSVG } from 'react-svg';
import i18n from '../../../i18n';

const { Search } = Input;

export enum SearchVariant {
  PRIMARY,
  SECONDARY
}

interface Props {
  items: MakeModel[];
  onCheck: (name: string, values: string) => void;
  name: string;
  checkedKeys?: string[];
  children?: JSX.Element;
  searchVariant?: SearchVariant
}

const SсScrollbars = styled(Scrollbars)`
  height: 150px !important;
  margin-top: 8px;
`;

const FilterTree: FC<Props> = ({ items, onCheck, name, checkedKeys, children, searchVariant}) => {
  const [searchValue, setSearchValue] = useState('');
  const [modelOpenKeys, setmodelOpenKeys] = useState<any[]>([]);

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const filter = (array: MakeModel[], text: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getNodes = (result: MakeModel[], object: any) => {
      if (object.title.toLowerCase().includes(text.toLowerCase())) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.children)) {
        const groups = object.children.reduce(getNodes, []);
        if (groups.length) result.push({ ...object, groups });
      }
      return result;
    };

    return array.reduce(getNodes, []);
  };

  const handleCheck = (checked: Key[] | { checked: Key[]; halfChecked: Key[] }) => {
    onCheck(name, (checked as string[]).join(','));
  };

  const updateOpenKeys = (prev: any[], key: any) => {
    const index = prev.indexOf(key);
    const newList = [...prev];
    if (index > -1) {
      newList.splice(index, 1);
    } else {
      newList?.push(key);
    }
    return newList;
  };

  const handleChangeExpandedKeys = (value: any, option: any) => {
    !!option.node.children && setmodelOpenKeys((prev) => updateOpenKeys(prev, option.node.key));
  };

  const RenderSearch = () => {
    const props = {
      placeholder: i18n.t("lot_filter"),
      onChange: handleChangeSearchValue
    }
    switch(searchVariant){
      case SearchVariant.SECONDARY:
        return <SearchWrapper>
          <ReactSVG src="/icons/search-normal.svg" className='svg-wrapper'/>
          <input type="text" {...props} />
        </SearchWrapper>
      default:
        return <Search  {...props} />
    }
  }

  return (
    <>
      {RenderSearch()}
      {children}
      <SсScrollbars>
        <Tree
          showIcon
          checkedKeys={checkedKeys}
          onCheck={handleCheck}
          checkable
          selectable={false}
          treeData={filter(items, searchValue)}
          expandedKeys={modelOpenKeys}
          onSelect={handleChangeExpandedKeys}
          onExpand={setmodelOpenKeys}
        />
      </SсScrollbars>
    </>
  );
};

export default memo(FilterTree);
