/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { axiosInstance } from '../../core/axios';
import api from '../../core/api';
import { FaqItem } from '../../interfaces/Faq';
import { showNotification } from '../../utils/notify';

export interface InitState {
  faqQuestions: FaqItem[];
}

export const initialState: InitState = {
  faqQuestions: [],
};

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    faqQuestionsSuccess: (state, action) => {
      state.faqQuestions = action.payload;
    },
  },
});

export const { faqQuestionsSuccess } = faqSlice.actions;

export const getAllFaqQuestions = (language: 'en' | 'ru' | 'ka'): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstance.get(api.GetAllFaqQuestions, {
      params: {
        language,
      },
    });
    dispatch(faqQuestionsSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

// SELECTORS
export const faqQuestionsSelector = (state: RootState) => state.faq.faqQuestions;

export default faqSlice.reducer;
