import React, { FC, memo, useCallback, useState, useEffect } from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { currentLotSelector, currentLotActualSelector } from '../../../store/slices/auctionSlice';

const { Text } = Typography;

interface Props {
  laneId: number;
  setShowShadow: (val: boolean) => void;
}

const SellModel: FC<Props> = ({ laneId, setShowShadow }) => {
  const [sellModal, setSellModal] = useState('');

  const currentLot = useSelector(currentLotSelector(laneId));
  const currentLotActual = useSelector(currentLotActualSelector(laneId));

  const getSellModal = useCallback(() => {
    if (currentLot && currentLotActual) {
      if (currentLot.sellModel === 'BTBA') return 'On Approval';
      if (currentLotActual.bidValue >= currentLot.minimumPrice) return 'Pure sale';
      return 'Minimum Bid';
    }
    return '';
  }, [currentLot, currentLotActual]);

  useEffect(() => {
    if (sellModal === 'Minimum Bid' && getSellModal() === 'Pure sale') {
      setShowShadow(true);
      setTimeout(() => setShowShadow(false), 4000);
    }
    currentLotActual?.bidValue && setSellModal(getSellModal());
  }, [currentLotActual, getSellModal, sellModal, setShowShadow]);

  return <Text>{getSellModal()}</Text>;
};

export default memo(SellModel);
