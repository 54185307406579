/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { axiosInstance } from '../../core/axios';
import api from '../../core/api';
import history from '../../core/history';
import {
  TransitAndYardDefinitionItem,
  PublicOneLotPageData,
  SellerOneLotPageData,
  MyLotItem,
  AddVehicleItemToSend,
  UpdateAssignmentMaintenanceFormData,
  BidByStatusInformation,
} from '../../interfaces/Product';
import { clearTransportInfo } from './dictionarySlice';
import { getApiForLots } from '../../utils/functions';
import { showNotification } from '../../utils/notify';
import i18n from '../../i18n';

export interface InitState {
  auctionTypes: {
    id: number;
    title: string;
    count: number;
  }[];
  auctionTypesError: string;
  isAuctionTypesLoading: boolean;
  publicOneLotPageData: PublicOneLotPageData | null;
  publicOneLotPageDataLoading: boolean;
  quickViewLotData: PublicOneLotPageData | null;
  quickViewLotDataLoading: boolean;
  allPrices: {
    comment: string;
    id: number;
    location: string;
    price: number;
  }[];
  transitAndYardDefinition: TransitAndYardDefinitionItem[];
  sellerOneLotPageData: SellerOneLotPageData | null;

  lotsList: MyLotItem[];
  lotsListLoading: boolean;
  lotsListTotalCount: number;
  userWatchList: number[];

  dashboardLots: {
    lotsOnSaleList: MyLotItem[];
    bidApprovalItemsForSellerList: MyLotItem[];
    soldLotsList: MyLotItem[];
    lotsOnCheckList: MyLotItem[];
    openItemsList: BidByStatusInformation[];
    currentBidsList: BidByStatusInformation[];
    paymentsDueList: MyLotItem[];
    userWatchListList: MyLotItem[];
    lotsOnSaleListTotal: number;
    bidApprovalItemsForSellerListTotal: number;
    soldLotsListTotal: number;
    lotsOnCheckListTotal: number;
    openItemsListTotal: number;
    currentBidsListTotal: number;
    paymentsDueListTotal: number;
    userWatchListListTotal: number;
    searchDashboardFilter: MyLotItem[];
  };
  dashboardLotsLoading: boolean;
  openModalQuickView: false | number;
}

export const initialState: InitState = {
  auctionTypes: [],
  auctionTypesError: '',
  isAuctionTypesLoading: false,
  publicOneLotPageData: null,
  publicOneLotPageDataLoading: false,
  quickViewLotData: null,
  quickViewLotDataLoading: false,
  allPrices: [],
  transitAndYardDefinition: [],
  sellerOneLotPageData: null,

  lotsList: [],
  lotsListLoading: false,
  lotsListTotalCount: 1,
  userWatchList: [],
  dashboardLots: {
    lotsOnSaleList: [],
    bidApprovalItemsForSellerList: [],
    soldLotsList: [],
    lotsOnCheckList: [],
    openItemsList: [],
    currentBidsList: [],
    paymentsDueList: [],
    userWatchListList: [],
    searchDashboardFilter: [],
    lotsOnSaleListTotal: 0,
    bidApprovalItemsForSellerListTotal: 0,
    soldLotsListTotal: 0,
    lotsOnCheckListTotal: 0,
    openItemsListTotal: 0,
    currentBidsListTotal: 0,
    paymentsDueListTotal: 0,
    userWatchListListTotal: 0,
  },
  dashboardLotsLoading: false,
  openModalQuickView: false,
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    auctionTypesRequest: (state) => {
      state.isAuctionTypesLoading = true;
    },
    auctionTypesSuccess: (state, action) => {
      state.isAuctionTypesLoading = false;
      state.auctionTypes = action.payload;
    },
    auctionTypesFailure: (state, action: PayloadAction<string>) => {
      state.isAuctionTypesLoading = false;
      state.auctionTypesError = action.payload;
    },
    transitAndYardDefinitionSuccess: (state, action) => {
      state.transitAndYardDefinition = action.payload;
    },
    publicOneLotPageDataRequest: (state) => {
      state.publicOneLotPageData = null;
      state.publicOneLotPageDataLoading = true;
    },
    publicOneLotPageDataSuccess: (state, action) => {
      state.publicOneLotPageData = action.payload;
      state.publicOneLotPageDataLoading = false;
    },
    publicOneLotPageDataFailure: (state) => {
      state.publicOneLotPageDataLoading = false;
    },
    quickViewLotDataRequest: (state) => {
      state.quickViewLotData = null;
      state.quickViewLotDataLoading = true;
    },
    quickViewLotDataSuccess: (state, action) => {
      state.quickViewLotData = action.payload;
      state.quickViewLotDataLoading = false;
    },
    quickViewLotDataFailure: (state) => {
      state.quickViewLotDataLoading = false;
    },
    allPricesRequest: (state) => {
      state.allPrices = [];
    },
    allPricesSuccess: (state, action) => {
      state.allPrices = action.payload;
    },
    sellerOneLotPageDataSuccess: (state, action) => {
      state.sellerOneLotPageData = action.payload;
    },
    setLotsListRequest: (state) => {
      state.lotsList = [];
      state.lotsListLoading = true;
    },
    setLotsListSuccess: (state, action) => {
      state.lotsList = action.payload.data || action.payload;
      state.lotsListTotalCount = action.payload.totalCount || action.payload.length;
      state.lotsListLoading = false;
    },
    setLotsListFailure: (state) => {
      state.lotsListLoading = false;
    },
    userWatchListRequest: (state) => {
      state.userWatchList = [];
    },
    userWatchListSuccess: (state, action) => {
      state.userWatchList = action.payload;
    },
    dashboardLotsRequest: (state) => {
      state.dashboardLotsLoading = true;
    },
    dashboardLotsSuccess: (state, action) => {
      state.dashboardLotsLoading = false;
      state.dashboardLots = action.payload;
    },
    dashboardLotsFailure: (state) => {
      state.dashboardLotsLoading = false;
    },
    setIsOpenQuickView: (state, action) => {
      state.openModalQuickView = action.payload;
    },
  },
});

export const {
  auctionTypesRequest,
  auctionTypesSuccess,
  auctionTypesFailure,
  transitAndYardDefinitionSuccess,
  publicOneLotPageDataSuccess,
  publicOneLotPageDataRequest,
  publicOneLotPageDataFailure,
  quickViewLotDataSuccess,
  quickViewLotDataRequest,
  quickViewLotDataFailure,
  allPricesSuccess,
  allPricesRequest,
  sellerOneLotPageDataSuccess,
  setLotsListRequest,
  setLotsListSuccess,
  setLotsListFailure,
  userWatchListRequest,
  userWatchListSuccess,
  dashboardLotsRequest,
  dashboardLotsSuccess,
  dashboardLotsFailure,
  setIsOpenQuickView,
} = productSlice.actions;

export const changeOpenQuickView = (val: boolean | number): AppThunk => async (dispatch) => {
  dispatch(setIsOpenQuickView(val));
  // if (!val) history.replace({ state: undefined });
};

export const clearUserWatchList = (): AppThunk => async (dispatch) => {
  dispatch(userWatchListRequest());
};

export const updateLotsListSuccess = (data: MyLotItem[]): AppThunk => async (dispatch) => {
  dispatch(setLotsListSuccess(data));
};

export const getAuctionTypes = (): AppThunk => async (dispatch) => {
  dispatch(auctionTypesRequest());
  try {
    const res = await axiosInstance.get(api.GetAuctionTypeStatisticWithId);
    dispatch(auctionTypesSuccess(res.data));
  } catch (error: any) {
    dispatch(auctionTypesFailure(error.message));
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getTransitAndYardDefinition = (): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstance.get(api.GetTransitAndYardDefinition);
    dispatch(transitAndYardDefinitionSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getSellerOneLotPageData = (lotId: number): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstance.get(api.GetSellerOneLotPageData, {
      params: {
        lotId,
      },
    });
    dispatch(sellerOneLotPageDataSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getPublicOneLotPageData = (lotId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(publicOneLotPageDataRequest());
    const res = await axiosInstance.get(api.GetPublicOneLotPageData, {
      params: {
        lotId,
      },
    });
    dispatch(publicOneLotPageDataSuccess(res.data));
  } catch (error: any) {
    dispatch(publicOneLotPageDataFailure());
    // showNotification('error', error.response.data.errorMessage);
  }
};

export const getQuickViewLotData = (lotId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(quickViewLotDataRequest());
    const res = await axiosInstance.get(api.GetPublicOneLotPageData, {
      params: {
        lotId,
      },
    });
    dispatch(quickViewLotDataSuccess(res.data));
  } catch (error: any) {
    dispatch(quickViewLotDataFailure());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getAllPrices = (): AppThunk => async (dispatch) => {
  try {
    dispatch(allPricesRequest());
    const res = await axiosInstance.get(api.GetAllPrices);
    dispatch(allPricesSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getLots = (
  SortOrder: string,
  type: string,
  Page?: number,
  PageItemsCount?: number,
  SortField?: string,
  DaysInYardLessThan?: string,
  DaysInYardGreaterThan?: string,
  Country?: string,
  AssignmentType?: string,
  VehicleType?: string,
  Make?: string,
  Model?: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLotsListRequest());
    const res = await axiosInstance.get(getApiForLots(type), {
      params: {
        Page,
        PageItemsCount,
        SortField,
        SortOrder,
        DaysInYardLessThan,
        DaysInYardGreaterThan,
        Country,
        AssignmentType,
        VehicleType,
        Make,
        Model,
      },
    });
    dispatch(setLotsListSuccess(res.data));
  } catch (error: any) {
    dispatch(setLotsListFailure());
    showNotification('error', error.response?.data.errorMessage || 'Error');
  }
};

export const createNewProduct = (data: AddVehicleItemToSend): AppThunk => async (dispatch) => {
  try {
    const resp = await axiosInstance.post(api.CreateNewProduct, data);
    history.push('/seller/lotsoncheck');
    dispatch(clearTransportInfo());
    showNotification('success', '', i18n.t("lot_addlot_message"), 'bottomRight');
  } catch (error: any) {
    showNotification('error', error.response.data?.OwnerMobilePhoneNumber || error.response.data.errorMessage);
  }
};

export const updateAssignmentMaintenance = (data: UpdateAssignmentMaintenanceFormData): AppThunk => async (dispatch) => {
  try {
    await axiosInstance.post(api.UpdateAssignmentMaintenance, data);
    dispatch(clearTransportInfo());
    showNotification('success', '', 'Updated');
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const createNewLotNote = (lotId: number, noteText: string): AppThunk => async (dispatch) => {
  try {
    await axiosInstance.post(api.CreateNewLotNote, { lotId, noteText });
    dispatch(clearTransportInfo());
    showNotification('success', '', 'Added');
  } catch (error: any) {
    showNotification('error', 'Sorry, try again later');
  }
};

export const deleteProduct = (productId: number): AppThunk => async (dispatch) => {
  try {
    await axiosInstance.post(api.DeleteProduct, null, {
      params: { productId },
    });
    dispatch(getUserWatchListProductsId());
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const addProduct = (productId: number): AppThunk => async (dispatch) => {
  try {
    await axiosInstance.post(api.AddProduct, null, { params: { productId } });
    dispatch(getUserWatchListProductsId());
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getUserWatchListProductsId = (): AppThunk => async (dispatch) => {
  try {
    // dispatch(userWatchListRequest());
    const res = await axiosInstance.get(api.GetUserWatchListProductsId);
    dispatch(userWatchListSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getDashboardLots = (type: string): AppThunk => async (dispatch, state) => {
  try {
    dispatch(dashboardLotsRequest());
    if (type === 'seller') {
      const params = {
        Page: 1,
        PageItemsCount: 5,
        SortField: '',
        SortOrder: 'Desc',
      };
      const lotsOnCheck = await axiosInstance.get(api.GetLotsOnCheck, {
        params,
      });
      const lotsOnSale = await axiosInstance.get(api.GetLotsOnSale, { params });
      const soldLots = await axiosInstance.get(api.GetSoldLots, { params });
      const bidApprovalItemsForSeller = await axiosInstance.get(api.GetBidApprovalItemsForSeller, { params });

      dispatch(
        dashboardLotsSuccess({
          ...state().product.dashboardLots,
          lotsOnSaleList: lotsOnSale.data.data,
          bidApprovalItemsForSellerList: bidApprovalItemsForSeller.data.data,
          soldLotsList: soldLots.data.data,
          lotsOnCheckList: lotsOnCheck.data.data,
          lotsOnSaleListTotal: lotsOnSale.data.totalCount,
          bidApprovalItemsForSellerListTotal: bidApprovalItemsForSeller.data.totalCount,
          soldLotsListTotal: soldLots.data.totalCount,
          lotsOnCheckListTotal: lotsOnCheck.data.totalCount,
        }),
      );
    } else {
      const params = {
        Page: 1,
        PageItemsCount: 4,
        SortField: '',
        SortOrder: 'Desc',
      };
      const openItems = await axiosInstance.get(api.GetBidsByStatusForUser, {
        params: { ...params, bidsType: 'Open' },
      });
      const currentBids = await axiosInstance.get(api.GetBidsByStatusForUser, {
        params: { ...params, bidsType: 'Current' },
      });
      const paymentsDue = await axiosInstance.get(api.GetUserPaymentsDueList, {
        params: { ...params, IssuedDateLastDaysCount: '30' },
      });
      const userWatchList = await axiosInstance.get(api.GetUserWatchList, {
        params,
      });
      const searchDashboardFilter = await axiosInstance.get(api.GetSearchDashboardFilter);

      dispatch(
        dashboardLotsSuccess({
          ...state().product.dashboardLots,
          openItemsList: openItems.data.data,
          currentBidsList: currentBids.data.data,
          paymentsDueList: paymentsDue.data.data,
          userWatchListList: userWatchList.data.data,
          searchDashboardFilter: searchDashboardFilter.data,
          openItemsListTotal: openItems.data.totalCount,
          currentBidsListTotal: currentBids.data.totalCount,
          paymentsDueListTotal: paymentsDue.data.totalCount,
          userWatchListListTotal: userWatchList.data.totalCount,
        }),
      );
    }
  } catch (error: any) {
    dispatch(dashboardLotsFailure());
    showNotification('error', 'Something went wrong');
  }
};

// SELECTORS
export const auctionTypesSelector = (state: RootState) => state.product.auctionTypes;

export const transitAndYardDefinitionSelector = (state: RootState) => state.product.transitAndYardDefinition;

export const allPricesSelector = (state: RootState) => state.product.allPrices;
export const publicOneLotPageDataSelector = (state: RootState) => state.product.publicOneLotPageData;
export const publicOneLotPageDataLoadingSelector = (state: RootState) => state.product.publicOneLotPageDataLoading;
export const quickViewLotDataSelector = (state: RootState) => state.product.quickViewLotData;
export const quickViewLotDataLoadingSelector = (state: RootState) => state.product.quickViewLotDataLoading;
export const sellerOneLotPageDataSelector = (state: RootState) => state.product.sellerOneLotPageData;
export const lotsListSelector = (state: RootState) => state.product.lotsList;
export const lotsListLoadingSelector = (state: RootState) => state.product.lotsListLoading;
export const lotsListTotalCountSelector = (state: RootState) => state.product.lotsListTotalCount;
export const userWatchListSelector = (state: RootState) => state.product.userWatchList;
export const dashboardLotsSelector = (state: RootState) => state.product.dashboardLots;
export const dashboardLotsLoadingSelector = (state: RootState) => state.product.dashboardLotsLoading;
export const openModalQuickViewSelector = (state: RootState) => state.product.openModalQuickView;

export default productSlice.reducer;
