import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import React, { FC, memo } from 'react';
import { ScTitle } from '../style';
import ContentWrapper from '../../shared/components/ContentWrapper';

const { Text } = Typography;

const TermsOfServicePage: FC = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <ScTitle level={1}>{parse(t('termsOfServiceTitle'))}</ScTitle>
      <ScTitle level={5}>
        {t('termsOfServiceUpdate')}: {t('termsOfServiceUpdateDate')}
      </ScTitle>
      <Text>
        <p>{parse(t('termsOfServiceParagraph_1_1'))}</p>
        <p>{parse(t('termsOfServiceParagraph_1_2'))}</p>
        <p>{parse(t('termsOfServiceParagraph_1_3'))}</p>
        <ScTitle level={5}>{parse(t('termsOfServiceTitle_2'))}</ScTitle>
        <p>{parse(t('termsOfServiceParagraph_2_1'))}</p>
        <p>{parse(t('termsOfServiceParagraph_2_2'))}</p>
        <ScTitle level={5}>{parse(t('termsOfServiceTitle_3'))}</ScTitle>
        <p>{parse(t('termsOfServiceParagraph_3_1'))}</p>
        <ScTitle level={5}>{parse(t('termsOfServiceTitle_4'))}</ScTitle>
        <p>{parse(t('termsOfServiceParagraph_4_1'))}</p>
        <ScTitle level={5}>{parse(t('termsOfServiceTitle_5'))}</ScTitle>
        <p>{parse(t('termsOfServiceParagraph_5_1'))}</p>
        <ScTitle level={5}>{parse(t('termsOfServiceTitle_6'))}</ScTitle>
        <p>{parse(t('termsOfServiceParagraph_6_1'))}</p>
        <ScTitle level={5}>{parse(t('termsOfServiceTitle_7'))}</ScTitle>
        <p>{parse(t('termsOfServiceParagraph_7_1'))}</p>
        <ScTitle level={5}>{parse(t('termsOfServiceTitle_8'))}</ScTitle>
        <p>{parse(t('termsOfServiceParagraph_8_1'))}</p>
        <p>{parse(t('termsOfServiceParagraph_8_2'))}</p>
        <p>{parse(t('termsOfServiceParagraph_8_3'))}</p>
        <p>{parse(t('termsOfServiceParagraph_8_4'))}</p>
        <ScTitle level={5}>{parse(t('termsOfServiceTitle_9'))}</ScTitle>
        <p>{parse(t('termsOfServiceParagraph_9_1'))}</p>
        <ol>
          <li>{parse(t('termsOfServiceParagraph_9_1_1'))}</li>
          <li>{parse(t('termsOfServiceParagraph_9_1_2'))}</li>
          <li>{parse(t('termsOfServiceParagraph_9_1_3'))}</li>
          <li>{parse(t('termsOfServiceParagraph_9_1_4'))}</li>
          <li>{parse(t('termsOfServiceParagraph_9_1_5'))}</li>
          <li>{parse(t('termsOfServiceParagraph_9_1_6'))}</li>
          <li>{parse(t('termsOfServiceParagraph_9_1_7'))}</li>
          <li>{parse(t('termsOfServiceParagraph_9_1_8'))}</li>
        </ol>
        <ScTitle level={5}>{parse(t('termsOfServiceTitle_10'))}</ScTitle>
        <p>{parse(t('termsOfServiceParagraph_10_1'))}</p>
      </Text>
    </ContentWrapper>
  );
};

export default memo(TermsOfServicePage);
