import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addToCompare, compareIdsSelector, delFromCompare } from '../store/slices/compareSlice';
import { isAuthenticatedSelector } from '../store/slices/authSlice';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { showNotification } from '../utils/notify';

const useCompare = () => {
  const dispatch = useDispatch();
  const compareIds = useSelector(compareIdsSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const { t } = useTranslation();

  const handleAddToCompareList = (lotId: number) => {
      if (!isAuthenticated) {
          Modal.info({
            icon: <ExclamationCircleOutlined />,
            content: t("auth_required"),
            okText: t("Close"),
          });
      } else {
          const compareList = [...compareIds];
          if (compareIds.find((item) => item === +lotId)) {
              dispatch(delFromCompare(+lotId));
              showNotification('success', t("lot_delcompare_notification"));
          } else {
              compareList.push(+lotId);
              dispatch(addToCompare(compareList));
              showNotification('success', t("lot_addcompare_notification"));
          }
      }
  }

  return {
      handleAddToCompareList,
      compareIds
  }
}


export default useCompare