import { Space, Typography } from 'antd';
import React, { FC, memo } from 'react';
import dayjs from 'dayjs';

const { Text } = Typography;

interface Props {
  date: string;
}

const DateForTable: FC<Props> = ({ date }) => {
  const datejs = dayjs(date);
  return (
    <Space direction="vertical" align="center">
      <Text>{datejs.format('DD/MM/YYYY')}</Text>
      <Text>{datejs.format('HH:mm A')}</Text>
    </Space>
  );
};
export default memo(DateForTable);
