import styled from "styled-components";

export const SellerWrapper = styled.div``

export const SliderWrapper = styled.div``

export const AdvantagesSection = styled.div`
    border-top: 1px solid #F3F2F2;
    border-bottom: 1px solid #F3F2F2;
    background: #17191C;
    padding: 3.5rem 0 5rem;
    margin-top: 5rem;
    h2{
        color: #FFF;
        text-align: center;
        font-family: 'main';
        &:lang(ka){
            font-family: 'arial-caps';
        }
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.375rem;
    }
    ul{
        padding: 0;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 2rem;
        margin-top: 5rem;
        max-width: 90rem;
        margin-right: auto;
        margin-left: auto;
        padding: 0 1rem;
    }
    li{
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-wrapper{
            max-width: 6.875rem;
            width: 100%;
            aspect-ratio: 1;
            border: 1px solid #ffffff;
            border-radius: 50%;
            margin-bottom: 1.5rem;
            transition: 0.3s ease;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding: 1rem;
            filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(336deg) brightness(108%) contrast(101%);

        }
        .svg-wrapper{
            max-width: 6.875rem;
            width: 100%;
            margin: 0 auto;
            svg{
                width: 100%;
                height: 100%;
            }
        }
        > span{
            color: #FFF;
            text-align: center;
            font-family: 'main';
            &:lang(ka){
                font-family: 'arial-caps';
            }
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.75rem;
            cursor: default;
        }
    }

    li:hover .img-wrapper {
        border-color: #CE2424;
        background: #ffffff14;
        transform: scale(1.1);
        box-shadow: 0 0 10px rgb(255 255 255 / 20%);
    }
    @media(max-width: 1200px){
        ul{
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media(max-width: 768px){
        margin-top: 2.5rem;
        padding: 2.5rem 0 3.25rem;
        h2{
            font-size: 1.375rem;
            line-height: 1.375rem;
        }
        ul{
            grid-template-columns: repeat(2, 1fr);
            gap: 3rem 0.75rem;
            margin-top: 3.75rem;
        }
        li{
            .svg-wrapper{
                max-width: 5rem;
            }
            > span{
                font-size: 0.875rem;
                line-height: 1.5rem;
            }
        }
    }
`

export const SelletContactWrapper = styled.div`
    display: grid;
    grid-template-columns: 32rem 1fr;
    gap: 1rem;
    align-items: center;
    margin: 2.5rem auto 3.75rem;
    max-width: 90rem;
    padding: 0 1rem;

    @media(max-width: 991px){
        grid-template-columns: 25rem 1fr;
    }
    @media(max-width: 767px){
        all: unset;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        border-radius: 0;
        margin: 2.5rem 0 3.75rem;
    }
`

export const SellectCardsSection = styled.div`
    border-radius: 0.5rem;
    border: 1px solid #F5F5F5;
    background: #FFF;
    box-shadow: 0px 0px 50px 0px #E8E8E8;
    padding: 2.5rem 1rem;
    overflow: hidden;
    .slick-slider{
        margin: 0 -1rem;
    }
    .slick-slide{
        padding: 0 0.5rem;
    }
    h2{
        color: #000;
        font-family: 'main';
        &:lang(ka){
            font-family: 'arial-caps';
        }
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.375rem;
        margin-bottom: 2.5rem;
        padding: 0 2.25rem;
    }

    @media(max-width: 1200px){
     padding: 1.5rem 0;
     h2{
        padding: 0 1rem;
     }
    }
    @media(max-width: 767px){
        padding: 1.5rem;
        margin: 0 -1rem 2rem;
        h2{
            padding: 0;
        }
    }
`

export const SellerCardList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 31rem;
    overflow-y: auto;
    @media(max-width: 1200px){
        padding: 0 1rem;
    }
`

export const LeaveMessageWrapper = styled.div`
    max-width: 32rem;
    width: 100%;
      margin-right: auto;
  margin-left: auto;
`