import { Link } from "react-router-dom"
import styled from "styled-components"

export const ForDealerBodyWrapper = styled.div`
  width: 200px;
  border-radius: 0.75rem;
  top: 100%;
  z-index: 1;
  @media(max-width: 767px){
    width: 100%;
  }
`

export const ForDealerBodyList = styled.nav`
  display: flex; 
  flex-direction: column;
  list-style-type: none;
  padding: 1rem 0;
  a{
    border: 1px solid rgba(151, 151, 151, 0.13);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    max-width: 100%;
    width: calc( 100% - 1.5rem );
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
    &:not(:last-child){
      margin-bottom: 0.75rem;
    }
      .icon{
        margin-right: 0.75rem;
        &, & div, svg{
          width: 1rem;
          height: 1rem;
        }
      }
      .setting-icon{
        margin-right: 0.75rem;
        &, & div, svg{
          width: 1rem;
          height: 1rem;
        }
        path{
          fill: #CE2424;
        }
      }
    }
    @media(max-width: 767px){
        a{
          max-width: 100%;
          background-color: #fff;
        }
        .icon, .setting-icon{
          &, & div, svg{
            width: 1.5rem;
            height: 1.5rem;
          }
        }
    }
`

export const ForDealerBodyName = styled.span`
    font-family: 'main';
    font-weight: 500;
    font-size: 0.875rem;
    color: #292D32;
    @media(max-width: 767px){
        font-size: 1rem;
    }
`