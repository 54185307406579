import styled, { css } from 'styled-components';
import { Dropdown, Row, Form, Button, Select, TreeSelect, Slider, Input, Card, Pagination } from 'antd';
import Title from 'antd/lib/typography/Title';
import { White, Accent, Grey, Primary, DarkShadow, LightGreyBackground, firstSliderCl, thirdSliderCl, LightShadow } from '../../shared/shared-styles';
import { CloseCircleFilled } from '@ant-design/icons';

export const ClassicSearchPageWrapper = styled.div`
  position: relative;
`

export const StyledMainContainer = styled.div`
  padding: 0 50px;
  position: relative;
  z-index: 1;
  &:before,
  &:after {
    position: absolute;
    left: 0;
    min-height: 300px;
    top: 0;
    content: '';
    z-index: -1;
    width: 100%;
    background: ${Accent};
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  }
  &:after {
    min-height: 220px;
    background: ${Primary};
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  }
`;

export const PopularFiltersWrapper = styled.div`
  width: 100%;
  background: #F7F7F7;
  border: 1px solid #EAEAEA;
  padding: 1.75rem 2.5rem;
  .slick-slider{
    margin: 0 -0.5rem;
  }
  @media(max-width: 991px){
    margin-top: 1rem;
  }
  @media(max-width: 767px){
    padding: 0.75rem 1rem;
  }
`

export const StyledBanner = styled(Row)`
  box-shadow: 0 10px 30px 0 ${LightShadow};
  background: ${White};
  display: block;
`;

export const ScSelect = styled(Select)`
  .ant-select-selector {
    color: ${Grey} !important;
    border-radius: 4px !important;
  }
  .ant-select-selection-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ant-select-selection-item {
    align-self: center;
  }
  .ant-select-selection-placeholder {
    align-self: center;
  }
`;

export const ScTreeSelect = styled(TreeSelect)`
  width: 100%;
  .ant-select-selector {
    color: ${Grey} !important;
    border-radius: 4px !important;
  }
`;

export const ScFormItem = styled(Form.Item)`
  margin: 0;
`;

export const ScFormItemOdo = styled(Form.Item)`
  align-items: center;
`;

export const ClearButton = styled(CloseCircleFilled)`
    font-size: 12px;
    path {
    fill: #bfbfbf !important; /* Apply fill color */
  }

`;

export const FirstRow = styled.div`
  padding: 30px;
`;

export const SecondRow = styled.div`
  padding: 30px;
  background: ${LightGreyBackground};
`;

export const CardListWrapper = styled.div`
  width: 100%;
  padding: 1rem 2.5rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(20.5rem,0.25fr));
  gap: 1rem;
  @media(max-width: 1400px){
    grid-template-columns: repeat(auto-fit,minmax(18rem,0.35fr));
  }
  @media(max-width: 991px){
    grid-template-columns: repeat(auto-fit,minmax(18rem,0.5fr));
    padding: 1rem 1rem 2rem;
  }
  @media(max-width: 767px){
    grid-template-columns: repeat(1,1fr);
    padding: 0rem 1rem 2rem;
  }
`

export const PopularSearchBtn = styled(Button)`
  width: 196px !important;
  margin: 0 0.5rem;
  height: 106px;
  display: block !important;

  font-family: 'main';
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #1B153D;

  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  img{
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
  }
  &.ant-btn:focus {
    border: 1px solid #CACACA!important;
  }
  &.ant-btn:hover {
    border: 1px solid var(--primary)!important;
  }
  @media(max-width: 767px){
    min-width: 100px !important;
    width: fit-content;
    height: 44px;
    img{
      width: 1.25rem;
      height: 1.25rem;
      margin: 0;
      margin-right: 0.25rem;
    }
    div{
      flex-direction: row;
    }
  }
`;

export const ScSlider = styled(Slider)`
  margin-bottom: 40px;
  .ant-tooltip-inner {
    background-color: transparent;
    color: ${Primary};
    box-shadow: none;
    padding: 0;
    &:after {
      content: ' mi';
    }
  }
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-slider-handle {
    width: 20px;
    height: 20px;
    top: 20px;
    border-radius: 0 50% 50% 50%;
    transform: rotate(45deg) !important;
    margin-left: -10px;
  }
  .ant-slider-track {
    clip-path: ${(p: { currentClip: string }) => p.currentClip};
    background: linear-gradient(to right, ${firstSliderCl} 0%, ${Accent} 50%, ${thirdSliderCl} 100%);
    height: 20px;
  }
  .ant-tooltip-placement-bottom {
    padding: 0;
  }
  .ant-slider-track ~ .ant-slider-step > div:nth-child(2) > div > div {
    ${(props) =>
    Array.isArray(props.value) &&
      props.value[1] - props.value[0] < 50000 &&
      css`
        top: -40px !important;
        height: 33px;
        padding: 5px;
        background-color: ${DarkShadow};
        border-radius: 4px;
        .ant-tooltip-inner {
          color: ${White};
        }
        .ant-tooltip-arrow {
          display: block;
          transform: rotate(180deg);
          top: 33px;
          left: 37%;
          .ant-tooltip-arrow-content {
            width: 10px;
            height: 10px;
          }
        }
      `}
  }
  .ant-slider-rail {
    height: 20px;
    clip-path: polygon(0 95%, 100% 0, 100% 100%, 0 100%);
  }
`;

export const TitleSecond = styled.span`
  font-size: 16px;
`;

export const TitleMain = styled(Title)`
  display: grid;
  padding: 5px 20px;
  border-left: 2px solid ${Accent};
  margin: 0 0 20px !important;
`;

export const FilterSection = styled(Row)`
  padding: 80px 30px 20px;
`;

export const PaginationRow = styled(FilterSection)`
  padding-top: 0;
`;

export const ScInputGroup = styled(Input.Group)`
  display: flex !important;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const ScDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 19px;
  border-radius: 4px;
  margin: 8px;
  justify-content: space-between;
  background: ${White};
  .hide-icon{
    display: none;
  }
`;

export const AdditionalFilterFields = styled(Row)`
  background: ${LightGreyBackground};
`;

export const DropdownFields = styled.div`
  padding: 20px;
  background: ${White};
  border-radius: 2px;
  box-shadow: 0 10px 30px 0 ${LightShadow};
`;

export const DropdownOdo = styled(Button)`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  color: ${Grey};
  padding: 3px 8px !important;
  .anticon{
    margin-left: 0.5rem;
  }
`;

export const ClassicSerchAdvertising = styled(Card)`
  width: 400px;
  margin: 10px;
  border-radius: 4px;
  @media (max-width: 520px) {
    width: 300px;
  }
  height: calc(100% - 40px);
  .ant-card-body {
    position: relative;
    height: 100%;
    img {
      padding: 10px;
      width: 100%;
      height: 100%;
    }
  }
  .ant-card-body {
    padding: 0;
  }
`;


export const BannerFindFormContainer = styled.div`
  max-width: 65.5rem;
  width: 100%;
    margin-right: auto;
  margin-left: auto;
  padding: 0 2.5rem 3rem;
  .ant-select-selection-placeholder{
    font-family: 'main';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #566063;
  }
  
  .ant-select-arrow{
    color: #222222;
  }

  .ant-dropdown-trigger{
    height: 48px;
    border: 1px solid #A5AAAC;
    border-radius: 4px;
    margin: 0;
  }

  .anticon-down, .anticon-down{
    font-size: 8px !important;
    margin-right: 6px;
  }

  .ant-dropdown-trigger{
    padding-right: 0.5rem;
  }

  .ant-space-item{
    font-family: 'main';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #566063;    
  }

  .ant-btn{
    font-family: 'main';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #566063;
    border-color: #A5AAAC;
    height: 48px;
    path{
      fill: #222222;
    }
  }

  .ant-select{
    .ant-select-selector{
      height: 48px;
      border: 1px solid #A5AAAC;
      border-radius: 4px;
    }
  }

  @media(max-width: 1200px){
    padding: 0 1rem 1rem;
    .ant-select .ant-select-selector, .ant-btn, .ant-dropdown-trigger{
      height: 42px;
      padding: 0 1rem;
    }
    .ant-btn{
      span{
        margin-left: 1rem;
      }
    }
    .ant-select-selection-placeholder, .ant-btn, .ant-space-item{
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.25rem;
      padding: 0;
    }
  }
`

export const AdditionalBannerFindFormWrapper = styled.div`
  max-width: 65.5rem;
  width: 100%;
  display: grid;
  gap: 1.75rem 1rem;
    margin-right: auto;
  margin-left: auto;
  padding: 2.5rem 0;
  @media(max-width: 991px){
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    padding: 1rem 0;
  }
`

export const FormWrapper = styled(Form)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.75rem 1rem;
  /* border-bottom: 1px solid #EAEAEA; */
  padding: 2.25rem 0;
  @media(max-width: 1200px){
    gap: 1rem;
    padding: 1rem 0;
  } 
  @media(max-width: 991px){
    grid-template-columns: repeat(1, 1fr);
  }
`

export const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media(max-width: 991px){
    flex-direction: column;
  }
`

export const AdditionalBannerTitle = styled.h1`
  font-family: 'main';
  &:lang(ka){
    font-family: 'arial-caps';
  }
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
  margin-top: 1.5rem;
  color: #1B153D;
  position: relative;
  &::after{
    content: "";
    position: absolute;
    left: 0;
    top: -1.5rem;
    width: 100%;
    height: 1px;
    display: block;
    background-color: #EAEAEA;
  }
  @media(max-width: 991px){
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.25rem;
    &:after{
      top: -1rem;
    }
  }
`

export const SubmitButton  = styled.button`
  font-family: 'main';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #FFFFFF;
  background: #CE2424;
  padding: 0.875rem 2.5rem;
  border-radius: 8px;
  margin-left: 1.5rem;
  @media(max-width: 991px){
    font-weight: 500;
    min-width: 12.5rem;
    background: #CE2424;
    border-radius: 6px;
    margin-left: 0;
    margin-top: 1.5rem;
  }
`

export const ToggleAdditionalFilter = styled.button`
  font-family: 'main';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #1B153D;
  display: flex;
  align-items: center;
  .svg-wrapper{
    margin-right: 0.5rem;
  }
  .upside-down{
    transform: rotate(180deg);
    div, svg{
      width: 1rem;
      height: 1rem;
    }
  }
  @media(max-width: 991px){
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`

export const PaginationWrapper = styled.div`
  margin: 0rem 0 3.75rem;
  width: 100%;
  padding: 1rem 2.5rem 0;
`

export const MobileFilterWrapper = styled.div`
  position: absolute;
  height: calc( 100dvh - 4rem);
  padding-bottom: 1rem;
  background: #fff;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  overflow-y: auto;
`

export const YearFilterWrapper = styled.div`
  display: flex;
`

export const YearFilterItem = styled.div`
  flex: 1;
  margin-right: 0px;

  &:first-child {
    margin-right: 10px;
  }
`