import React from 'react'
import { CellContentText, CellContentWrapper } from './style'
import { Vehicle } from '../../../interfaces/FindVehicles'
import { useTranslation } from 'react-i18next'
interface ConditionColumnWrapperProps {
    vehicle: Vehicle
}

const ConditionColumnWrapper = ({vehicle}: ConditionColumnWrapperProps) => {
  const { t } = useTranslation();
  return (
    <CellContentWrapper>
        {
            vehicle.primaryDamageType && (vehicle.primaryDamageType.value != "New"  
            ? <CellContentText fw={400}>{t("lot_primary_damage")}: {vehicle.primaryDamageType.value}</CellContentText>
            : <CellContentText fw={400}>{vehicle.primaryDamageType.value}</CellContentText>)
        }
        {
            vehicle.secondaryDamageType 
            && vehicle.secondaryDamageType.value != t("lot_defect_type_New")
            && <CellContentText fw={400}>{t("lot_secondary_damage")}: {vehicle.secondaryDamageType.value}</CellContentText>
        }
        <CellContentText fw={400}>{vehicle.keys ? t("lot_haskeys") : t("lot_hasnotkeys")}</CellContentText>
    </CellContentWrapper>
  )
}

export default ConditionColumnWrapper