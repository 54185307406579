import React from 'react'
import { Bagdes, PaymentCardWrapper, PaymentsCardContent, PaymentsCardFooter, PaymentsCardImage, PaymentsCardWrapper, SearchContentBody, VinBagde } from './style'
import { Vehicle } from '../../../interfaces/FindVehicles'
import { getLinkForLotPage, getLocation, getOdoValue } from '../../../utils/functions'
import { odometrIsKmSelector } from '../../../store/slices/initSlice'
import BadgeWithTooltip from '../../../shared/components/BadgeWithTooltip'
import { useSelector } from 'react-redux'
import { Payments } from '../../../interfaces/Payments'
import Price from '../../../shared/components/Price'
import { useTranslation } from 'react-i18next'

interface PaymentsCardProps {
    vehicle: any;
    handleDownloadPdf: Function
}

export const PaymentsCardSkeleton = () => {
    return (
        <PaymentsCardWrapper>
            <SearchContentBody as={"div"}>
                <PaymentsCardImage isLoading={true}/>
                <PaymentsCardContent isLoading={true} />
            </SearchContentBody>
        </PaymentsCardWrapper>
    )
}

const PaymentsCard = ({vehicle, handleDownloadPdf}: PaymentsCardProps) => {
    const { name, locationType } = getLocation(vehicle.locationId, vehicle);
    const odometrIsKm = useSelector(odometrIsKmSelector);

    const odometerValue = getOdoValue(vehicle.odometer, odometrIsKm, vehicle.odometerBrand).split(" ")[0]

    const { t } = useTranslation()
  return (
    <PaymentsCardWrapper>
        <SearchContentBody to={getLinkForLotPage(
        vehicle.lotNumber,
        vehicle.make,
        vehicle.model,
        vehicle.year,
      )}>
            <PaymentsCardImage>
                <ul>
                    <li>ID <strong>{vehicle.id}</strong></li>
                    <li>Lot <strong>{vehicle.lotNumber}</strong></li>
                </ul>
                <img src={vehicle.mainImage} alt="" />
            </PaymentsCardImage>
            <PaymentsCardContent>
                <h3>{vehicle.make} {vehicle.model} {vehicle.year}</h3>
                <h4>{name} {locationType}</h4>
                <p>{odometerValue} ({odometrIsKm ? "Km" : "Mi"}) {vehicle.odometerBrand}</p>
                <Bagdes>
                    {vehicle?.badges.map((badge: any) => (
                        <BadgeWithTooltip badge={badge} key={`${badge.name}_${vehicle.lotId}`} className="fifth-search-step" />
                    ))}
                    <VinBagde>{vehicle.vin}</VinBagde>
                </Bagdes>
            </PaymentsCardContent>
        </SearchContentBody>
        <PaymentCardWrapper>
            <ul>
                <li>
                    <h3>Payment status</h3>
                    <strong>{vehicle.paymentStatus}</strong>
                </li>
                <li>
                    <h3>Bid</h3>
                    <Price price={vehicle.bid} />
                </li>
                <li>
                    <h3>Due Amount</h3>
                    <Price price={vehicle.dueAmount} />
                </li>
            </ul>
        </PaymentCardWrapper>
        <PaymentsCardFooter>
            <h3>Invoice</h3>
            <Price price={vehicle.invoice} />
            <button onClick={handleDownloadPdf(vehicle.id, vehicle.id)}>{t('downloadInvoice')}</button>
        </PaymentsCardFooter>
    </PaymentsCardWrapper>
  )
}

export default PaymentsCard