import React, { FC, memo } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import YoutubeVideo from '../YoutubeVideo';

interface Props {
  visible: boolean;
  onClose: () => void;
  videoId: string;
  width?: number;
  autoplay?: boolean;
}

export const VideoContainer = styled.div`
  div {
    height: 430px;
  }
`;

const YoutubeModal: FC<Props> = ({ visible, onClose, videoId, width, autoplay }) => (
  <Modal visible={!!visible} title="Video" onCancel={onClose} cancelText="Close" footer={null} zIndex={10000} width={width}>
    <VideoContainer>
      <YoutubeVideo videoId={videoId} autoplay={autoplay} />
    </VideoContainer>
  </Modal>
);

export default memo(YoutubeModal);
