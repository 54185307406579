import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Space, Button, InputNumber, Modal, Typography, Checkbox, CheckboxProps } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import api from '../../core/api';
import { axiosInstance } from '../../core/axios';
import { axiosSignalr } from '../../core/axios-signalr';
import { CalendarDashboardLane } from '../../interfaces/Auction';
import { PublicOneLotPageData } from '../../interfaces/Product';
import { BlinkJoin } from '../../shared/components/AuctionsTodayTable/style';
import Price from '../../shared/components/Price';
import { auctionPanelsSelector, getAuctionDashboardLotsList } from '../../store/slices/auctionSlice';
import { toggleModalAuth, userSelector } from '../../store/slices/authSlice';
import { makeOffer, makePreliminaryBid } from '../../store/slices/bidsSlice';
import { getPublicOneLotPageData } from '../../store/slices/productSlice';
import { downloadPdf, getUserContactInfo } from '../../store/slices/profileSlice';
import { getBidStep } from '../../utils/functions';
import { showNotification } from '../../utils/notify';
import { ScDivider } from './style';
import { parse } from 'path';
import tparse from 'html-react-parser';
import { CustCol } from '../../shared/components/LotDescriptionCard/style';

const { Text } = Typography;
interface Props {
  data: PublicOneLotPageData;
  productId: number;
}

const BidActionButtons: FC<Props> = ({ data, productId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);

  const [state, setstate] = useState<number>(data.bidInformation.nextMinimalBid);
  const [openSuccessBuyNowModal, setOpenSuccessBuyNowModal] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<number | undefined>();
  const [isMakeOffer, setIsMakeOffer] = useState(false);
  const [offer, setOffer] = useState(0);

  const user = useSelector(userSelector);
  const auctionPanels = useSelector(auctionPanelsSelector);

  
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const setTermsCheck: CheckboxProps['onChange'] = (e) => {
    setIsTermsChecked(e.target.checked);
  };

  useEffect(() => {
    setstate(data.bidInformation.nextMinimalBid);
    user && dispatch(getUserContactInfo());
  }, [dispatch, data, user]);

  const handleSubmitBuyNowModal = useCallback(async () => {
    try {
      const res = await axiosSignalr.get(api.BuyNow, {
        params: {
          productId,
        },
      });
      setOpenSuccessBuyNowModal(true);
      dispatch(getPublicOneLotPageData(productId));
      res && setInvoiceId(res.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showNotification('error', error.response.data.errorMessage);
    }
  }, [dispatch, productId]);

  const handleDownloadInvoice = useCallback(() => {
    invoiceId && dispatch(downloadPdf('INVOICE', `${api.GetDataForProductInvoices}?paymentsIdList=${invoiceId}`));
  }, [dispatch, invoiceId]);

  const handleSubmitOffer = useCallback(() => {
    if (!isTermsChecked) {
      showNotification('error', t('offer_check_termsandconditions_noti'));
      return;
    }
    if (offer < data.bidInformation.buyNowPrice * 0.5) {
      showNotification('error', t('offer_bid_low'));
    } else {
      dispatch(makeOffer(productId, offer));
      setIsMakeOffer(false);
    }
  }, [data, offer, dispatch, productId, isTermsChecked]);

  const confirm = () => {
    if (user?.id_token) {
      Modal.confirm({
        title: t('lot_buy_it_now_submit'),
        icon: <ExclamationCircleOutlined />,
        okText: t('Submit'),
        cancelText: t('Cancel'),
        onOk: handleSubmitBuyNowModal,
      });
    } else {
      dispatch(toggleModalAuth(true));
    }
  };

  const handleBidNow = useCallback(() => {
    if (user?.id_token) dispatch(makePreliminaryBid(productId, state));
    else {
      dispatch(toggleModalAuth(true));
    }
  }, [user?.id_token, dispatch, productId, state]);

  const checkIsLive = (time: string, leftTime: number) => {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const newDate = new Date().setHours(hours, minutes, seconds);
    return new Date(newDate).getTime() - Date.now() <= leftTime;
  };

  const handleOpenLiveAuction = useCallback(async () => {
    if (data.auctionIsRun) {
      try {
        const res = await axiosInstance.get(api.GetAuctionDashboardLanesList, {
          params: {
            timeOffset: new Date().getTimezoneOffset() / -60,
          },
        });
        const lane = res.data.today.find((item: CalendarDashboardLane) => item.laneId === data.activeAuction.lane);
        dispatch(getAuctionDashboardLotsList(lane.laneId, +Object.keys(auctionPanels)[0]));
        params.set('laneId', `${lane.laneId}`);
        history.push({
          pathname: '/auctiondashboard',
          search: params.toString(),
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        showNotification('error', error.response.data.errorMessage);
      }
    } else {
      history.push(`/lot/${productId}/${data.title.replace(/\s/g, '-')}`);
    }
  }, [auctionPanels, data, dispatch, history, productId, params]);

  const getBidContent = useCallback(
    () => {
      if (data.auctionIsRun && data.saleInformation.saleDate && checkIsLive(data.saleInformation.saleDate, 36000)) {
        return (
          <>
            <Row justify="center">
              <Button type="primary" onClick={handleOpenLiveAuction} size="large">
                <BlinkJoin>{t('auction_join_to_live')}</BlinkJoin>
              </Button>
            </Row>
            <Row justify="center">
              <Text type="danger">Prebid finished Lot is at auction</Text>
            </Row>
          </>
        );
      }
      if (!isMakeOffer) {
        return (
          <>
            {data.bidInformation.isBuyNow && (
              <>
              {user && <Row justify="space-between">
                <CustCol>{t('lot_make_offer')}</CustCol>
                  <Col>
                    <Space direction="vertical" align='end'>
                      <Price price={data.bidInformation.lastOfferAmount} className={"product-price"}/>
                        <Button type="primary" onClick={() => setIsMakeOffer(true)}>
                        {t('lot_make_offer')}
                      </Button>
                    </Space>
                </Col>
              </Row>}
              <Row justify="space-between" align={'middle'}>
                <CustCol>{t('buy_now')}</CustCol>
                <Col>
                  <Space direction="vertical" align='end'>
                    <Price price={data.bidInformation.buyNowPrice} className={"product-price"}/>
                    <Button type="primary" onClick={confirm}>
                      {t('lot_buy_it_now')}
                    </Button>
                  </Space>
                </Col>
              </Row>
              </>
            )}
          </>
        );
      }
      return (
        <>
          <ScDivider />
          <Row justify="center">
            <Text>
            {t("lot_offer_info_text")}
            </Text>
            <Col>
              <InputNumber
                value={offer}
                formatter={(value?: string | number) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                parser={(value: any) => +value.replace(/\$\s?|(,*)/g, '')}
                onChange={setOffer}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Checkbox
              onChange={setTermsCheck}
            >
              {tparse(t('offer_check_termsandconditions'))}
            </Checkbox>
          </Row>
          <Row justify="space-between">
            <Button type="primary" onClick={() => setIsMakeOffer(false)}>
              {t('Cancel')}
            </Button>
            <Button type="primary" onClick={handleSubmitOffer}>
              {t('lot_submit_offer')}
            </Button>
          </Row>
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, handleBidNow, handleSubmitOffer, isMakeOffer, offer, state, t, user],
  );

  return (
    <>
      {!data.bidInformation.isSold ? (
        <>
          {getBidContent()}
        </>
      ) : (
        <>
          <Row>LOT SOLD</Row>
          <Row>
            BROWSE OUR CURRENT INVENTORY OF
            <b>{data.title.toLocaleUpperCase()}</b>
            THIS VEHICLE IS BEING SOLD AS "AS IS - WHERE IS". ALL BIDS ARE BINDING AND ALL SALES ARE FINAL.
          </Row>
          <Link to={`/findvehicles/${data.title}?vehicletype=Automobile`}>
            <Button type="primary"> Find More Like This</Button>
          </Link>
        </>
      )}

      {!!openSuccessBuyNowModal && (
        <Modal
          title="Congratulations!"
          visible={!!openSuccessBuyNowModal}
          centered
          onCancel={() => setOpenSuccessBuyNowModal(false)}
          cancelText="<Go back to the Lot Page"
          onOk={handleDownloadInvoice}
          okText="Bill Details"
        >
          <p>You have just purchased this lot!</p>
          <p>
            <b>What's next:</b>
          </p>
          <ol>
            <li>You need to transfer the total amount of sum for the car, including taxes and fees to the Lion Auto Auction bank account.</li>
            <li>The sum is ${data.bidInformation.buyNowPrice} USD. Click here to know details</li>
            <li>After you payed the bill, fill the report blank on our system here:</li>
          </ol>
          <Link to="/paymentsdue">
            <Button type="default">Go To Payed</Button>
          </Link>
        </Modal>
      )}
    </>
  );
};
export default memo(BidActionButtons);
