import React, { FC, memo } from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';
import { ScTitle } from '../../../pages/style';

export const ScContent = styled(Content)`
  padding: 24px;
`;

interface Props {
  children: JSX.Element;
}

const DashboardPageLayout: FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <ScContent>
      <Row>
        <ScTitle>{t('nav_user_menu_dashboard')}</ScTitle>
      </Row>
      {children}
    </ScContent>
  );
};

export default memo(DashboardPageLayout);
