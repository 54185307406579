import { Link } from "react-router-dom";
import styled from "styled-components";
import { CardContent, CardImageWrapper, CardWrapper } from "../GalleryCard/style";

export const GallerySectionWrapper = styled.section`
    max-width: 90rem;
    padding: 5rem 1rem;
      margin-right: auto;
  margin-left: auto;
    @media (max-width: 991px){
        padding-top: 2.5rem;
    }
    @media (max-width: 767px){
        padding-top: 1rem;
    }
`

export const GalleryCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: 31.25rem 1fr;
    gap: 1rem;
    @media (max-width: 1200px){
        grid-template-columns: 1fr;
    }
`

export const GalleryMainImage = styled.div`
    ${CardWrapper} {
        height: 100%;
    }
    @media (max-width: 1200px){
        max-width: 35rem;
        margin: 0 auto;
        ${CardWrapper}{
            flex-direction: column;
        }
        ${CardContent}{
            flex-direction: row;
            align-items: center;
            span{
                margin-top: unset;
            }
        }
        ${CardImageWrapper}{
            width: 100%;
            height: auto;
        }
    }
`

export const GalleryCardList = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    @media (max-width: 1200px){
        grid-template-columns: repeat(1, 1fr);
    }
`

export const ShowMoreButton = styled(Link)`
    width: 15rem;
    background: #CE2424;
    border-radius: 8px;
    font-family: 'main';
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    padding: 0.875rem 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 2rem;
    &:hover{
        color: #fff
    }
`