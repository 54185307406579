import { StarFilled, StarOutlined, StarTwoTone } from '@ant-design/icons';
import { Rate } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { ContactInfoList, ContactItem, DealerCardWrapper, DealerContent, DealerPhoto, DealerStarRateWrapper, Title } from './style';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useDispatch } from 'react-redux';
import { getDealerRate } from '../../../../store/slices/dealerSlice';
import { useCookies } from 'react-cookie';
import i18n from '../../../../i18n';
import { langPrefix } from '../../../../utils/functions';

interface DealerCardProps {
  dealer: any
}

const DealerCard = ({dealer}: DealerCardProps) => {
  const [cookies] = useCookies(['language']);
  const { md } = useBreakpoint()
  const dispatch = useDispatch()

  const [rate, setRate] = useState(0)

  const dealerRate = async () =>{
    const dealerRate: any = await dispatch(getDealerRate(dealer.dealerId))
    setRate(dealerRate)
  }

  useEffect(() => {
    dealerRate()
  }, [])
  
  const link = langPrefix("/dealer/"+dealer?.dealerName);

  return (
    <DealerCardWrapper>
      <DealerPhoto>
        <Link to={link}>
          {dealer?.image 
          ? <img src={`${process.env.REACT_APP_DEALER_IMAGE}${dealer?.image}`} alt="profile name" />
          : <img src={`/img/person.png`} alt="profile name" />}
        </Link>
      </DealerPhoto>
      <DealerContent>
        <Title to={link}>{i18n.language === "ka" && dealer?.dealerNameGeo
                    ? dealer?.dealerNameGeo
                    : i18n.language === "ru" && dealer?.dealerNameRus
                      ? dealer?.dealerNameRus
                      : dealer?.dealerName}</Title>
        {!md && <DealerStarRateWrapper>
          {rate}
          <StarFilled />
        </DealerStarRateWrapper>}
        <ContactInfoList>
          <ContactItem>
            <ReactSVG src="/icons/sms-stroke.svg" className="svg-wrapper" />
            {dealer?.emailAddress}
          </ContactItem>
          <ContactItem>
            <ReactSVG src="/icons/call-stroke.svg" className="svg-wrapper" />
            +{dealer?.phone}
          </ContactItem>
          <ContactItem>
            <ReactSVG src="/icons/location.svg" className="svg-wrapper location-wrapper" />
            {dealer?.country}, {dealer?.city}
          </ContactItem>
        </ContactInfoList>
        {md && <Rate allowHalf defaultValue={rate} disabled key={rate}/>}
      </DealerContent>
    </DealerCardWrapper>
  );
};

export default DealerCard;
