import React from 'react';
import { Route, Switch } from 'react-router-dom';
import mainRoutes from './mainRoutes';

const languageRoutes = [
    {
        path: '/:lang(en|ru)',
        component: ({ match }) => (
            <Switch>
                {mainRoutes.map((route) => (
                    <Route
                        key={route.path}
                        path={`/${match.params.lang}${route.path}`}
                        component={route.component}
                        exact={!!route.exact}
                    />
                ))}
            </Switch>
        )
    },
    {
        path: '/',
        component: () => (
            <Switch>
                {mainRoutes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        component={route.component}
                        exact={!!route.exact}
                    />
                ))}
            </Switch>
        )
    }
];

export default languageRoutes;
