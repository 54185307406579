import styled, { css } from 'styled-components';

export const MainSearchWrapper = styled.div`
  max-width: 77.125rem;
  margin: -6.5rem auto 0;
  position: relative;
  z-index: 1;
  padding: 0 1.5rem;
  @media(max-width: 767px){
    margin-top: -3.5rem;
    padding: 0 0.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  gap: 1px;
`;

export enum HeaderButtonVariant {
  ACTIVE,
  PRIMARY,
}

interface HeaderButtonProps {
  variant?: HeaderButtonVariant;
}

export const HeaderButton = styled.button<HeaderButtonProps>`
  font-family: 'main';
  &:lang(ka){
    font-family: 'arial-caps';
  }
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  min-width: 10.5rem;
  border-radius: 0.5rem 0.5rem 0px 0px;
  padding: 1rem;
  @media(max-width: 1200px){
    padding: 0.75rem 1rem;
    min-width: 8rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  ${(p) => {
    switch (p.variant) {
      case HeaderButtonVariant.ACTIVE:
        return css`
          background: #CE2424;
          color: #ffffff;
          @media(max-width: 767px){
            background: #030303;
            border: 1px solid #FFFFFF;
          }
        `;
      default:
        return css`
          background: #ffffff;
          color: #212121;
        `;
    }
  }}
`;

export const SearchWrapper = styled.div`
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f5f5f5;
  box-shadow: 0 0.25rem 0.25rem #f1f1f1;
  border-radius: 1.125rem;
`;
