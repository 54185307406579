import React from 'react'
import { useTranslation } from 'react-i18next'
import LotCardDescription, { LotCardRow, LotCardText } from '../../../shared/components/LotDescriptionCard'

const ComfortCard = ({vehicle}: any) => {

  const { t } = useTranslation()

  return (
    <LotCardDescription title={t("electric_description_comfort")}>
        <>
            <LotCardRow>
                <LotCardText>{t("electric_tailgate")}</LotCardText>
                <LotCardText>{vehicle?.comfort?.electricTailGate ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("remote_locking")}</LotCardText>
                <LotCardText>{vehicle?.comfort?.remoteLocking ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("central_locking")}</LotCardText>
                <LotCardText>{vehicle?.comfort?.centralLocking ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("ac")}</LotCardText>
                <LotCardText>{vehicle?.comfort?.ac ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("keyless_entry")}</LotCardText>
                <LotCardText>{vehicle?.comfort?.keylessEntry ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("remote_control_key")}</LotCardText>
                <LotCardText>{vehicle?.comfort?.remoteControlKey ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("wireless_phone_charger")}</LotCardText>
                <LotCardText>{vehicle?.comfort?.wirelessPhoneCharger ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("cruise_control")}</LotCardText>
                <LotCardText>{vehicle?.comfort?.cruiseControl ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
        </>
    </LotCardDescription>
  )
}

export default ComfortCard