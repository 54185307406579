import React, {useCallback} from 'react'
import { CompareCardImage, CompareCardWrapper, CompareContent } from '../style'
import { ReactSVG } from 'react-svg'
import { Vehicle } from '../../../interfaces/FindVehicles';
import Odometer from '../../../shared/components/AdvanceSearch/Odometer';
import { getLinkForLotPage, getOdoValue } from '../../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { odometrIsKmSelector } from '../../../store/slices/initSlice';
import { compareIdsSelector, compareItemsLoadingSelector, delFromCompare } from '../../../store/slices/compareSlice';
import { useTranslation } from 'react-i18next';

interface CompareCardProps {
    item: Vehicle;
  }

const CompareCard = ({item}: CompareCardProps) => {
    const odometrIsKm = useSelector(odometrIsKmSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const compareIds = useSelector(compareIdsSelector);
    const compareItemsLoading = useSelector(compareItemsLoadingSelector);
  
    const handleDeleteFromCompareList = useCallback(
      (id: number) => {
        if (compareIds.find((compareId) => compareId === +id)) {
          dispatch(delFromCompare(+id));
        }
      },
      [dispatch, compareIds],
    );

  return (
    <CompareCardWrapper>
        <button onClick={() => handleDeleteFromCompareList(item.id)}>
            <ReactSVG src='/icons/trash.svg' />
        </button>
        <CompareCardImage>
        <a href={getLinkForLotPage(item.id, item.make, item.model, item.year)} target="_blank"><img src={item.mainImage} alt="" /></a>
        </CompareCardImage>
        <CompareContent>
        <a href={getLinkForLotPage(item.id, item.make, item.model, item.year)} target="_blank"><h3>{item.year} {item.make} {item.model}</h3></a>
            <p>{item.automobileTypes.map((item) => item.value).join(", ")}</p>
            <ul>
                <li>
                    {t("compare_veh_customs")}
                    <strong>{item.clearancePassed ? t("compare_veh_cleared") : t("compare_veh_before")}</strong>
                </li>
                <li>
                {t("compare_veh_odometer")}
                    <strong>
                        {getOdoValue(item.odometer, odometrIsKm, item.odometerBrand).split(" ")[0]}
                        {odometrIsKm ? "(Km)" : "(Mi)"}
                    </strong>
                </li>
                <li>
                {t("compare_veh_highlight")}
                    <strong>{item.highlights}</strong>
                </li>
                <li>
                    VIN
                    <strong>{item.vin}</strong>
                </li>
                <li>
                {t("compare_veh_bodystyle")}
                    <strong>{item.bodyStyle}</strong>
                </li>
                <li>
                {t("compare_veh_color")}
                    <strong>{item.color}</strong>
                </li>
                <li>
                {t("compare_veh_keys")}
                    <strong>{item.keys ? t("compare_veh_keys_available"): t("compare_veh_keys_notavailable")}</strong>
                </li>
                <li>
                {t("compare_veh_transmission")}
                    <strong>{item.transmission}</strong>
                </li>
                <li>
                {t("compare_veh_fuel")}
                    <strong>{item.fuelType}</strong>
                </li>
                <li>
                {t("compare_veh_cylinder")}
                    <strong>{item.cylinder}</strong>
                </li>
                <li>
                {t("compare_veh_drive")}
                    <strong>{item.driveTrain}</strong>
                </li>
                <li>
                {t("compare_veh_engine")}
                    <strong>{item.engineType}</strong>
                </li>
            </ul>
        </CompareContent>
    </CompareCardWrapper>
  )
}

export default CompareCard