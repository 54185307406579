import { createSlice } from '@reduxjs/toolkit';
// import { showNotification } from '../../utils/notify';
import { AppThunk, RootState } from '../store';
// import { axiosInstance } from '../../core/axios';
// import api from '../../core/api';
// import {
//   AuctionDashboardLot,
//   AuctionDetailsForCalendar,
//   CalendarDashboardLane,
//   AuctionActivePanel,
//   ActualLot,
// } from '../../interfaces/Auction';
import { DictionaryItem } from '../../interfaces/Dictionary';

export interface InitState {
  // allAuctionsAddressesAsDictionary: DictionaryItem[];
  // calendarDashboardLanesList: CalendarDashboardLane[];
  // auctionDashboardLanesList: CalendarDashboardLane[];
  // auctionDashboardLanesListCurrent: CalendarDashboardLane[];
  // openLanes: number[],
  // auctionDashboardLanesListLoading: boolean;
  // auctionDetailsForCalendar: AuctionDetailsForCalendar | null;
  // auctionDashboardLotsList: AuctionDashboardLot[];
  // // {panelid: laneId}
  // auctionPanels: { [panelId: string]: number | null; }
  openLot: any;
  openLotAdditionalInfo: any;
  maxBidTime: any;
  additionalBidTime: any;
}

export const initialState: InitState = {
  // allAuctionsAddressesAsDictionary: [],
  // auctionDetailsForCalendar: null,
  // auctionDashboardLotsList: [],
  // calendarDashboardLanesList: [],
  // auctionDashboardLanesList: [],
  // auctionDashboardLanesListCurrent: [],
  // openLanes: [],
  // auctionDashboardLanesListLoading: false,
  // auctionPanels: {
  //   [new Date().getTime()]: null,
  // },
  openLot: {},
  openLotAdditionalInfo: {},
  maxBidTime: null,
  additionalBidTime: null,
};

export const lotSlice = createSlice({
  name: 'lot',
  initialState,
  reducers: {
    // allAuctionsAddressesAsDictionarySuccess: (state, action) => {
    //   state.allAuctionsAddressesAsDictionary = action.payload;
    // },
    // auctionDashboardLotsListSuccess: (state, action) => {
    //   const { panelId, lots, laneId } = action.payload;
    //   state.auctionPanels[panelId] = laneId;
    //   state.openLanesList[laneId] = {
    //     lotsList: lots,
    //     actualLots: {},
    //     nextLots: [],
    //     currentLotId: null,
    //     status: '',
    //     maxBidTime: 0,
    //     additionalBidTime: 0,
    //     music: true,
    //     sound: true,
    //     monsterBidMusic: false,
    //     otherBidMusic: false,
    //     bidMusic: false,
    //     auctionMusic: false,
    //   };
    // },
    // calendarDashboardLanesListSuccess: (state, action) => {
    //   state.calendarDashboardLanesList = action.payload;
    // },
    // auctionDashboardLanesListRequest: (state) => {
    //   state.auctionDashboardLanesList = [];
    //   state.auctionDashboardLanesListLoading = true;
    // },
    // auctionDashboardLanesListSuccess: (state, action) => {
    //   state.auctionDashboardLanesList = action.payload;
    //   state.auctionDashboardLanesListLoading = false;
    // },
    // auctionDashboardLanesListFailure: (state, action) => {
    //   state.auctionDashboardLanesList = [];
    //   state.auctionDashboardLanesListLoading = false;
    // },
    // auctionDashboardLanesListCurrentSuccess: (state, action) => {
    //   state.auctionDashboardLanesListCurrent = action.payload;
    // },
    // auctionDetailsForCalendarSuccess: (state, action) => {
    //   state.auctionDetailsForCalendar = action.payload;
    // },
    // auctionPanelsSuccess: (state, action) => {
    //   state.auctionPanels = action.payload;
    // },
    updateCurrentLot: (state, action) => {
      state.openLot = {
        laneId: action.payload.index,
        currentLotId: action.payload.lotId,
        status: 'CurrentLot',
        maxBidTime: 0,
        additionalBidTime: 0,
        music: true,
        sound: true,
        monsterBidMusic: false,
        otherBidMusic: false,
        bidMusic: false,
        auctionMusic: false,
        bidValue: action.payload.bidValue,
        userMaxBidValue: action.payload.userMaxBidValue,
        isWinning: action.payload.isWinning,
      };
    },
    updateActualLot: (state, action) => {
      // console.log('>><<<action payload>>><<<', action.payload);

      state.openLotAdditionalInfo = {
        laneId: action.payload.index,
        currentLotId: action.payload.lotId,
        status: action.payload.status,
        time: action.payload.time,
        timeType: action.payload.timeType,
        playTimer: action.payload.playTimer,
      };

      // const { index, lotIndex, data } = action.payload;
    },

    addLotMaxBidTime: (state, action) => {
      const { index, time } = action.payload;
      state.maxBidTime = time || 25;
    },
    addLotAdditionalBidTime: (state, action) => {
      const { index, time } = action.payload;
      state.additionalBidTime = time || 5;
    },

    // auctionDashboardLotsListSuccess: (state, action) => {
    //   index: lane lotid: current.lotId
    // },

    // updateLaneStatus: (state, action) => {
    //   const { index, status } = action.payload;
    //   console.log(index, status);
    //   state.openLanesList[index].status = status;
    // },
    // returnLaneSuccess: (state, action) => {
    //   state.auctionPanels[action.payload.panelId] = null;
    //   state.openLanes = action.payload.openLanes;
    //   delete state.openLanesList[action.payload.laneId];
    // },
    // deletePanelSuccess: (state, action) => {
    //   delete state.auctionPanels[action.payload.panelId];
    //   state.openLanes = action.payload.openLanes;
    //   delete state.openLanesList[action.payload.laneId];
    // },
    // updateActualLots: (state, action) => {
    //   const { index, lots, nextLots } = action.payload;
    //   state.openLanesList[index].actualLots = lots;
    //   state.openLanesList[index].nextLots = nextLots;
    // },
    // changeMonsterBidMusicSuccess: (state, action) => {
    //   const { laneId, monsterBidMusic } = action.payload;
    //   state.openLanesList[laneId].monsterBidMusic = monsterBidMusic;
    // },
    // changeOtherBidMusicSuccess: (state, action) => {
    //   const { laneId, otherBidMusic } = action.payload;
    //   state.openLanesList[laneId].otherBidMusic = otherBidMusic;
    // },
    // changeBidMusicSuccess: (state, action) => {
    //   const { laneId, bidMusic } = action.payload;
    //   state.openLanesList[laneId].bidMusic = bidMusic;
    // },
    // changeAuctionMusicSuccess: (state, action) => {
    //   console.log('action payload', action.payload);
    //   const { laneId, auctionMusic } = action.payload;
    //   state.openLanesList[laneId].auctionMusic = auctionMusic;
    // },
    // changeMusicSuccess: (state, action) => {
    //   const { laneId, music } = action.payload;
    //   state.openLanesList[laneId].music = music;
    // },
    // changeSoundSuccess: (state, action) => {
    //   const { laneId, sound } = action.payload;
    //   state.openLanesList[laneId].sound = sound;
    // },

    // changeBidValue: (state, action) => {
    //   const { index, lotIndex, data } = action.payload;
    //   state.openLanesList[index].lotsList[lotIndex] = data;
    // },
    // openLanesUpdate: (state, action) => {
    //   state.openLanes = action.payload;
    // },
  },
});

export const {
  // allAuctionsAddressesAsDictionarySuccess,
  // auctionDashboardLotsListSuccess,
  // calendarDashboardLanesListSuccess,
  // auctionDashboardLanesListCurrentSuccess,
  // auctionDashboardLanesListRequest,
  // auctionDashboardLanesListSuccess,
  // auctionDashboardLanesListFailure,
  // auctionDetailsForCalendarSuccess,
  // auctionPanelsSuccess,
  updateCurrentLot,
  // updateLaneStatus,
  // returnLaneSuccess,
  // deletePanelSuccess,
  // updateActualLots,
  updateActualLot,
  // changeMonsterBidMusicSuccess,
  // changeOtherBidMusicSuccess,
  // changeBidMusicSuccess,
  // changeAuctionMusicSuccess,
  addLotMaxBidTime,
  addLotAdditionalBidTime,
  // changeBidValue,
  // changeMusicSuccess,
  // changeSoundSuccess,
  // openLanesUpdate,
} = lotSlice.actions;

// export const wsUpdateLotStatus = (
//   laneId: number,
//   status: string,
// ): AppThunk => async (dispatch, state) => {
//   let value = 'load';
//   if (status === 'OnRun') {
//     value = 'auction';
//   } else if (status === 'OnWait') {
//     value = 'waiting';
//   } else if (status === 'Finished') {
//     value = 'ended';
//   } else if (status === 'NotExist') {
//     value = 'notexist';
//   } else if (status === 'AlreadyConnected') {
//     value = 'same';
//   } else if (status === 'OnStartError' || status === 'OnEndError' || status === 'OnCloseError') {
//     value = 'error';
//   } else if (status === 'OnLoad') {
//     value = 'load';
//   } else if (status === 'OnStop') {
//     value = 'stop';
//   }
//   dispatch(updateLaneStatus({ index: laneId, status: value }));
// };

// export const setAuctionDashboardLanesListCurrent = (
//   currentLines: CalendarDashboardLane[],
// ): AppThunk => async (dispatch, state) => {
//   dispatch(
//     auctionDashboardLanesListCurrentSuccess(currentLines),
//   );
// };

// export const setAuctionDetailsForCalendarSuccess = (
//   currentLines: CalendarDashboardLane[] | null,
// ): AppThunk => async (dispatch, state) => {
//   dispatch(
//     auctionDetailsForCalendarSuccess(currentLines),
//   );
// };

// export const setAuctionPanels = (
//   panels: { [panelId: string]: number | null; },
// ): AppThunk => async (dispatch, state) => {
//   dispatch(
//     auctionPanelsSuccess(panels),
//   );
// };

// export const resetAuctionPanels = (
// ): AppThunk => async (dispatch, state) => {
//   const first = Object.keys(state().auction.auctionPanels)[0];
//   const panels = { [first]: state().auction.auctionPanels[first] };
//   dispatch(openLanesUpdate([]));
//   dispatch(
//     auctionPanelsSuccess(panels),
//   );
// };

// export const returnLane = (
//   panelId: number,
//   laneId: number | undefined | null,
// ): AppThunk => async (dispatch, state) => {
//   const openLanes = state().auction.openLanes.
//     filter((line) => line !== laneId) || [];
//   dispatch(returnLaneSuccess({ panelId, laneId, openLanes }));
// };

// export const deletePanel = (
//   panelId: number,
//   laneId: number | undefined | null,
// ): AppThunk => async (dispatch, state) => {
//   const openLanes = state().auction.openLanes.
//     filter((line) => line !== laneId) || [];
//   dispatch(deletePanelSuccess({ panelId, laneId, openLanes }));
// };

// export const changeMonsterBidMusic = (
//   laneId: number,
//   isOn: boolean,
// ): AppThunk => async (dispatch) => {
//   dispatch(changeMonsterBidMusicSuccess({
//     laneId,
//     monsterBidMusic: isOn,
//   }));
// };

// export const changeOtherBidMusic = (
//   laneId: number,
//   isOn: boolean,
// ): AppThunk => async (dispatch) => {
//   dispatch(changeOtherBidMusicSuccess({
//     laneId,
//     otherBidMusic: isOn,
//   }));
// };

// export const changeBidMusic = (
//   laneId: number,
//   isOn: boolean,
// ): AppThunk => async (dispatch) => {
//   dispatch(changeBidMusicSuccess({
//     laneId,
//     bidMusic: isOn,
//   }));
// };

// export const changeAuctionMusic = (
//   laneId: number,
//   isOn: boolean,
// ): AppThunk => async (dispatch) => {
//   dispatch(changeAuctionMusicSuccess({
//     laneId,
//     auctionMusic: isOn,
//   }));
// };

// export const changeMusic = (
//   laneId: number,
// ): AppThunk => async (dispatch, state) => {
//   dispatch(changeMusicSuccess({
//     laneId,
//     music: !state().auction.openLanesList[laneId].music,
//   }));
// };

// export const changeSound = (
//   laneId: number,
// ): AppThunk => async (dispatch, state) => {
//   dispatch(changeSoundSuccess({
//     laneId,
//     sound: !state().auction.openLanesList[laneId].sound,
//   }));
// };

export const wsUpdateLotInfo = (laneId: number, lots: ActualLot[]): AppThunk => async (dispatch, state) => {
  // console.log('Look STEP 1', laneId, lots);
  // const startIndex = lots.findIndex((item) => item.status === 'OnSale');
  // const actLots = lots.slice(startIndex > 0 ? startIndex - 1 : startIndex);

  // const nextLots = actLots.map((item) => item.lotId).splice(1);
  // const actualLots = actLots.reduce(
  //   (
  //     obj: { [key: string]: ActualLot },
  //     item: ActualLot,
  //   ) => Object.assign(obj, { [item.lotId]: item }), {});
  // dispatch(updateActualLots({ index: laneId, lots: actualLots, nextLots }));
  const current = lots.find((item: ActualLot) => item.status === 'CurrentLot');
  // console.log('CURRENT LOT', current);

  dispatch(
    updateCurrentLot({
      index: laneId,
      lotId: current?.lotId,
      bidValue: current?.bidValue,
      userMaxBidValue: current?.userMaxBidValue,
      isWinning: current?.isWinning,
    }),
  );

  // if (current) {
  //   dispatch(updateCurrentLot({
  //     index: laneId,
  //     lotId: current.lotId,
  //   }));
  // } else {
  //   dispatch(updateCurrentLot({
  //     index: laneId,
  //     lotId: actLots[0].lotId,
  //   }));
  // }
};

export const wsAddLotMaxBidTime = (laneId: number, time: number): AppThunk => async (dispatch) => {
  dispatch(addLotMaxBidTime({ index: laneId, time }));
};

export const wsAddLotAdditionalBidTime = (laneId: number, time: number): AppThunk => async (dispatch) => {
  dispatch(addLotAdditionalBidTime({ index: laneId, time }));
};

export const wsUpdateCurrentLot = (laneId: number, params: any): AppThunk => async (dispatch, state) => {
  const { lotId, bid, time, status, timeType } = params;

  // console.log('STEP 3333', laneId, params);

  // console.log('lotId, bid, time, status, timeType', laneId, bid, time, status, timeType);

  dispatch(
    updateActualLot({
      index: laneId,
      lotIndex: lotId,
      bidValue: bid,
      time,
      status,
      timeType,
      playTimer: true,
    }),
  );

  //   const { lotId, bid, time, status, timeType } = params;
  //   const { currentLotId, actualLots, nextLots } =
  //   state().auction.openLanesList[laneId];
  //   if (currentLotId === null || currentLotId !== lotId) {
  //     dispatch(updateCurrentLot({ index: laneId, lotId }));
  //     const newActualLots = JSON.parse(JSON.stringify(actualLots));
  //     currentLotId && delete newActualLots[currentLotId];
  //     dispatch(updateActualLots({
  //       index: laneId,
  //       lots: newActualLots,
  //       nextLots: nextLots.filter((item) => item !== lotId),
  //     }));
  //   } else {
  //     const actualLot = actualLots[lotId];
  //     if (actualLot &&
  //       (
  //         time > actualLot.time ||
  //         (time && actualLot.time === undefined) ||
  //         time === 0 || actualLot.playTimer === false
  //       )
  //     ) {
  //       dispatch(updateActualLot({
  //         index: laneId, lotIndex: lotId, data: {
  //           ...actualLot,
  //           bidValue: bid,
  //           time,
  //           status,
  //           timeType,
  //           playTimer: true,
  //         },
  //       }));
  //     }
  //   }
};

// export const wsChangeBid = (
//   laneId: number,
//   params: any,
// ): AppThunk => async (dispatch, state) => {
//   const { lotId } = params;
//   const actualLot = state().auction.openLanesList[laneId].actualLots[lotId];
//   if (actualLot) {
//     dispatch(updateActualLot({
//       index: laneId, lotIndex: lotId, data: {
//         ...actualLot,
//         ...params,
//         playTimer: false,
//       },
//     }));
//   }

//   // Sounds
//   if (params.isYourBid && actualLot) {
//     if (params.bidValue > actualLot.userMaxBidValue) {
//       if (state().auction.openLanesList[laneId].monsterBidMusic) {
//         dispatch(changeMonsterBidMusic(laneId, false));
//       }
//       setTimeout(() => {
//         dispatch(changeMonsterBidMusic(laneId, true));
//       });
//     } else {
//       if (state().auction.openLanesList[laneId].bidMusic) {
//         dispatch(changeBidMusic(laneId, false));
//       }
//       setTimeout(() => {
//         dispatch(changeBidMusic(laneId, true));
//       });
//     }
//   } else {
//     if (state().auction.openLanesList[laneId].otherBidMusic) {
//       dispatch(changeOtherBidMusic(laneId, false));
//     }
//     setTimeout(() => {
//       dispatch(changeOtherBidMusic(laneId, true));
//     });
//   }
// };

// export const getAllAuctionsAddressesAsDictionary = (): AppThunk => async (
//   dispatch,
// ) => {
//   try {
//     const res = await axiosInstance
//       .get(api.GetAllAuctionsAddressesAsDictionary);
//     dispatch(allAuctionsAddressesAsDictionarySuccess(res.data));
//   } catch (error: any) {
//     showNotification('error', error.response.data.errorMessage);
//   }
// };

// export const getAuctionDashboardLotsList = (
//   laneId: number,
//   panelId: number,
// ): AppThunk => async (dispatch, state) => {
//   try {
//     const res = await axiosInstance.get(api.GetAuctionDashboardLotsList, {
//       params: {
//         laneId,
//       },
//     });
//     const newOpenLanes = [...state().auction.openLanes];
//     newOpenLanes.indexOf(laneId) === -1 && newOpenLanes.push(laneId);
//     dispatch(openLanesUpdate(newOpenLanes || []));

//     dispatch(auctionDashboardLotsListSuccess({
//       lots: res.data,
//       panelId,
//       laneId,
//     }));
//   } catch (error: any) {
//     showNotification('error', error.response.data.errorMessage);
//   }
// };

// export const getCalendarDashboardLanesList = (): AppThunk => async (
//   dispatch,
// ) => {
//   try {
//     const res = await axiosInstance.get(api.GetCalendarDashboardLanesList);
//     dispatch(calendarDashboardLanesListSuccess(res.data));
//   } catch (error: any) {
//     showNotification('error', error.response.data.errorMessage);
//   }
// };

// export const getAuctionDashboardLanesList = (): AppThunk => async (
//   dispatch,
// ) => {
//   try {
//     dispatch(auctionDashboardLanesListRequest());
//     const res = await axiosInstance.get(api.GetAuctionDashboardLanesList);
//     dispatch(auctionDashboardLanesListSuccess(res.data.today));
//   } catch (error: any) {
//     dispatch(auctionDashboardLanesListFailure(error.message));
//     showNotification('error', error.response.data.errorMessage);
//   }
// };

// export const getAuctionDetailsForCalendar = (
//   auctionId: number,
// ): AppThunk => async (dispatch) => {
//   try {
//     const res = await axiosInstance.get(api.GetAuctionDetailsForCalendar, {
//       params: {
//         auctionId,
//       },
//     });
//     dispatch(auctionDetailsForCalendarSuccess(res.data));
//   } catch (error: any) {
//     showNotification('error', error.response.data.errorMessage);
//   }
// };

// export const updateTimeCurrentLot = (
//   lotId: number,
//   laneId: number,
// ): AppThunk => async (dispatch, state) => {
//   const { actualLots } =
//     state().auction.openLanesList[laneId];
//   const actualLot = actualLots[lotId];
//   if (actualLot) {
//     dispatch(updateActualLot({
//       index: laneId, lotIndex: lotId, data: {
//         ...actualLot,
//         playTimer: false,
//       },
//     }));
//   }
// };

// SELECTORS
// export const allAuctionsAddressesAsDictionarySelector =
//   (state: RootState) => state.auction.allAuctionsAddressesAsDictionary;
// export const auctionDashboardLotsListSelector =
//   (state: RootState) => state.auction.auctionDashboardLotsList;
// export const calendarDashboardLanesListSelector =
//   (state: RootState) => state.auction.calendarDashboardLanesList;
// export const auctionDashboardLanesListSelector =
//   (state: RootState) => state.auction.auctionDashboardLanesList;
// export const auctionDashboardLanesListLoadingSelector =
//   (state: RootState) => state.auction.auctionDashboardLanesListLoading;
// export const auctionDashboardLanesListCurrentSelector =
//   (state: RootState) => state.auction.auctionDashboardLanesListCurrent;
// export const auctionDetailsForCalendarSelector =
//   (state: RootState) => state.auction.auctionDetailsForCalendar;
// export const auctionPanelsSelector =
//   (state: RootState) => state.auction.auctionPanels;
// export const currentPanelsSelector = (panelId: number) =>
//   (state: RootState) => state.auction.auctionPanels[panelId];
// export const currentPanelLaneInfoSelector = (laneId: number) =>
//   (state: RootState) => state.auction.auctionDashboardLanesList.
//     find((lane) => lane.laneId === laneId);
// export const currentLaneStatusSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].status;
// export const currentLaneMaxBidTimeSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].maxBidTime || 1;

// export const currentLaneAdditionalBidTimeSelector = (laneId: number) =>
//   (state: RootState) => state.auction.
//     openLanesList[laneId].additionalBidTime || 1;
// export const currentLotActualSelector = (laneId: any) =>
//   (state: RootState) => state.auction.openLanesList[laneId].
//     actualLots[state.auction.openLanesList[laneId].currentLotId || 0];
// export const currentLotSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].lotsList.
//     find((lot) =>
//       state.auction.openLanesList[laneId].currentLotId === lot.id,
//     );
// export const lotDataSelector = (laneId: number, lotId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].lotsList.
//     find((lot) => lotId === lot.id);
// export const lotActualDataSelector = (laneId: number, lotId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].actualLots[lotId];
// export const actualLotsSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId]?.actualLots;
// export const nextLotsSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId]?.nextLots;
// export const monsterBidMusicSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].monsterBidMusic && !!Object.keys(state.auction.openLanesList).find((item) => ['auction', 'waiting'].includes(state.auction.openLanesList[item].status));
// export const otherBidMusicSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].otherBidMusic && !!Object.keys(state.auction.openLanesList).find((item) => ['auction', 'waiting'].includes(state.auction.openLanesList[item].status));
// export const bidMusicSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].bidMusic && !!Object.keys(state.auction.openLanesList).find((item) => ['auction', 'waiting'].includes(state.auction.openLanesList[item].status));
// export const auctionMusicSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].auctionMusic && !!Object.keys(state.auction.openLanesList).find((item) => ['auction', 'waiting'].includes(state.auction.openLanesList[item].status));
// export const musicSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].music;
// export const soundSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId].sound;
// export const openLanesSelector =
//   (state: RootState) => state.auction.openLanes;
// export const openLaneSelector = (laneId: number) =>
//   (state: RootState) => state.auction.openLanesList[laneId];
// export const openLanesListSelector =
//   (state: RootState) => state.auction.openLanesList;

export const currentLotActual = (state: RootState) => state.lot.openLot;

export const currentLotAdditionalInfo = (state: RootState) => state.lot.openLotAdditionalInfo;

export const currentLotMaxBidTimeSelector = (state: RootState) => state.lot.maxBidTime || 1;

export const currentLotAdditionalBidTimeSelector = (state: RootState) => state.lot.additionalBidTime || 1;

export default lotSlice.reducer;
