import { notification } from 'antd';

export const showNotification = (type: string, message = 'Error', description = '', position = 'topRight') => {
  const args = {
    message,
    description,
    placement: position
  };
  type === 'error' ? notification.error(args) : notification.success(args);
};
