import styled from "styled-components";

export const Wrapper = styled.div`
    border-radius: 1.25rem;
    background: #FFF;
    box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.16);
    padding: 1.75rem 1rem 1.5rem;
`

export const Title = styled.h2`
    color: #5F6368;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
`

export const CalculatorFormWrapper = styled.form`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem 1.5rem;
    > :nth-child(1){
        grid-column: 1 / span 2;
    }
    > :nth-child(4){
        grid-column: 1 / span 2;
    }
    .ant-btn-primary{
        width: 100%;
        grid-column: 1 / span 2;
    }
`

export const InputGroupWraper = styled.div`
    display: flex;
    flex-direction: column;
    label{
        color: #CE2424;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: left;
    }
`

export const CalculateFormFooter = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    border-top : 1px solid rgba(165, 165, 165, 0.50);
    margin-top: 1rem;
    grid-column: 1 / span 2;
    span:nth-child(1){
        color: #5F6368;
        font-size: 1rem;
        font-weight: 400;
    }
    span:nth-child(2){
        color: #CE2424;
        font-size: 1.125rem;
        font-weight: 700;
    }
`