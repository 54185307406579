import styled from "styled-components"

export const HistoryWallWrapper = styled.div`
    max-width: 80.25rem;
    width: 100%;
      margin-right: auto;
  margin-left: auto;
    
    @media(max-width: 1400px){
        max-width: 100%;
        overflow-x: auto;
    }   
`

export const HistoryWallBanner = styled.div`
    width: 100%;
    height: 5.375rem;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(206, 36, 36, 0.79);
    }
    @media(max-width: 1400px){
        width: calc( 30rem * 3 + 2rem);
    }
`

export const TopGroupWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2,45%);
    justify-content: flex-start;
    gap: 0rem;
    transform: translate(0, 2.25rem);
    position: relative;
    z-index: 1;
    padding-left: 10rem;
    @media(max-width: 1400px){
        display: flex;
    }
`

export const BottomGroupWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 26.25rem);
    gap: 1rem;
    transform: translate(0, -2.25rem);
    position: relative;
    z-index: 1;
    @media(max-width: 1400px){
        display: flex;
    }
`

export const HistoryCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    &.reversed{
        flex-direction: column-reverse;
    }
    @media(max-width: 1400px){
        width: 30rem;
    }
`

export const HistoryCardDate = styled.span`
    font-family: 'main';
    font-weight: 800;
    font-size: 4rem;
    line-height: 1.5rem;
    color: #FFFFFF;
    text-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    padding: 0 0.25rem;
`

export const HistoryCardContent = styled.div`
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
`

export const HistoryCardImgWrapper = styled.div`
    width: 10.375rem;
    aspect-ratio: 2.37;
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    flex-shrink: 0;
    img{
        width: 100%;
        height: 100%;
    }
    &::after{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, 0.8);
    }
    @media(max-width: 767px){
        width: 7.5rem;
        aspect-ratio: unset;
        height: 5rem;
    }
`

export const HistoryCardTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    h2{
        font-family: 'main';
        &:lang(ka){
            font-family: 'arial-caps';
        }
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1rem;
        color: #1B153D;
        margin-bottom: 0.25rem;
    }
    p{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #1B153D;
        margin: 0;
    }
    @media(max-width: 767px){
        p{
            font-size: 0.625rem;
        }
    }
`