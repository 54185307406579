import React, { useState } from 'react'
import { PopupChildrenWrapper, PopupConent, PopupWrapper } from './style'
import { ReactSVG } from 'react-svg'

interface PopupProps {
    children: JSX.Element;
    onClose: () => void;
}

const Popup = ({children, onClose}: PopupProps) => {
    return (
    <PopupWrapper>
        <PopupConent>
            <button onClick={onClose}>
                <ReactSVG src='/icons/close.svg' />
            </button>
            <PopupChildrenWrapper>
                {children}
            </PopupChildrenWrapper>
        </PopupConent>
    </PopupWrapper>
  )
}

export default Popup