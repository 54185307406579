import React from 'react'
import { useTranslation } from 'react-i18next'
import LotCardDescription, { LotCardRow, LotCardText } from '../../../shared/components/LotDescriptionCard'

const VehicleEngineCard = ({vehicle}: any) => {

  const { t } = useTranslation()

  return (
    <LotCardDescription title={t("electric_description_engine")}>
    <>
        <LotCardRow>
            <LotCardText>{t("maximum_speed")}</LotCardText>
            <LotCardText>{vehicle?.engine?.maxSpeed}</LotCardText>
        </LotCardRow>
        <LotCardRow>
            <LotCardText>{t("number_electric_motors")}</LotCardText>
            <LotCardText>{vehicle?.engine?.electricMotorsAmount}</LotCardText>
        </LotCardRow>
        <LotCardRow>
            <LotCardText>{t("fast_charging")}</LotCardText>
            <LotCardText>{vehicle?.engine?.fastCharging ? t("yes"): t("no")}</LotCardText>
        </LotCardRow>
        <LotCardRow>
            <LotCardText>{t("torque")}</LotCardText>
            <LotCardText>{vehicle?.engine?.torque}</LotCardText>
        </LotCardRow>
        <LotCardRow>
            <LotCardText>{t("acceleration")}</LotCardText>
            <LotCardText>{vehicle?.engine?.acceleration}</LotCardText>
        </LotCardRow>
        
    </>
</LotCardDescription>
  )
}

export default VehicleEngineCard