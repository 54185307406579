import React, { useEffect } from 'react';
import { NewCarsCardWrapper, NewCarsList, NewsCarSection, SectionTitle, SliderButtonWrapper } from './style';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { featuredProductsLoadingSelector, featuredProductsSelector, getAllFeaturedProducts } from '../../../store/slices/initSlice';
import { productGeneralDictionariesByLanguageSelector } from '../../../store/slices/dictionarySlice';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { ReactSVG } from 'react-svg';
import NewCarsCard, { NewCarsCardLoadingSkeleton } from '../../../pages/HomePage/NewCarsCard';
import useWindowDimensions from '../../../hooks/useWindowDomensions';

function NextArrow(props: any) {
  const { isNextArrow, onClick } = props;
  return (
    <SliderButtonWrapper onClick={onClick} isNextArrow={isNextArrow}>
      <ReactSVG src='/icons/arrow-left.svg' className='svg-wrapper'/>
    </SliderButtonWrapper>
  );
}

function PrevArrow(props: any) {
  const { isPrevArrow, onClick } = props;
  return (
    <SliderButtonWrapper onClick={onClick} isPrevArrow={isPrevArrow}>
      <ReactSVG src='/icons/arrow-left.svg' className='svg-wrapper'/>
    </SliderButtonWrapper>
  );
}

const VehicleSection = ({ vehicles, title, isLoading }: any) => {
  const { width } = useWindowDimensions();

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: Math.min(vehicles.length,4),
    slidesToScroll: Math.min(vehicles.length,4),
    nextArrow: <NextArrow isNextArrow={true} />,
    prevArrow: <PrevArrow isPrevArrow={true} />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: Math.min(vehicles.length,3),
          slidesToScroll: Math.min(vehicles.length,3),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(vehicles.length,2),
          slidesToScroll: Math.min(vehicles.length,2),
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: Math.min(vehicles.length,1),
          slidesToScroll: Math.min(vehicles.length,1),
        },
      },
    ]
  };

  return (
    <NewsCarSection>
      <SectionTitle>{title}</SectionTitle>
      {isLoading ? <NewCarsList isLoading={true}>
        {Array(4).fill("").map((_,index) => (
            <NewCarsCardLoadingSkeleton key={index}/>
           ))}
      </NewCarsList>
      : (vehicles.length >= 4 || width <= 768 ) ? <Slider {...settings}>
          {vehicles.map((product: any) => (
              <NewCarsCardWrapper key={product.id}>
                  <NewCarsCard  product={product} />
              </NewCarsCardWrapper>
          ))}
      </Slider> : <NewCarsList isLoading={false}>
        {vehicles.map((product: any) => (
          <NewCarsCardWrapper key={product.id}>
            <NewCarsCard product={product} />
          </NewCarsCardWrapper>
        ))}
      </NewCarsList>}
    </NewsCarSection>
  );
};

export default VehicleSection;
