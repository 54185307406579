import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { PopularSearchBtn, TitleSecond } from '../style';
import { categoryMenuList } from '../../../utils/constants';
import { ScSlider } from '../../../shared/components/FeaturedProductCard/style';

const { Text, Title } = Typography;

const PopularFilters: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);

  const [state, setstate] = useState<string[]>([]);

  useEffect(() => {
    const types = params.get('featuredtype')?.split(',') || [];

    types && setstate(types);
  }, [params]);

  const handleChangeFilter = useCallback(
    (value: string) => {
      let newState = [...state];
      if (newState.includes(value)) {
        newState = newState.filter((item) => item !== value);
      } else {
        newState.push(value);
      }
      setstate(newState);
      if (newState.length) params.set('featuredtype', newState.join());
      else params.delete('featuredtype');
      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      });
    },
    [history, params, state],
  );

  const settings = {
    className: "slider variable-width",
    infinite: true,
    centerMode: true,
    slidesToShow: categoryMenuList.length - 1,
    slidesToScroll: 1,
    variableWidth: true
  };

  return (
    <div className="third-classic-search-step">
      <ScSlider {...settings}> 
        {categoryMenuList.map((item) => (
          <PopularSearchBtn key={item.id} onClick={() => handleChangeFilter(item.title.toLowerCase())} type={state.includes(item.title.toLowerCase()) ? 'primary' : 'default'}>
            <div>
              <img src={`img/featured/${item.id}.svg`} width={42} alt={t(item.title)} />
              <Text strong>{t(item.title)}</Text>
              {/* <Text type="secondary">description</Text> */}
            </div>
          </PopularSearchBtn>
        ))}
      </ScSlider>
    </div>
  );
};

export default memo(PopularFilters);
