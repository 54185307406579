import { Card, Image } from 'antd';
import React, { FC, memo } from 'react';
import GalleryTabs from '../../LotPage/GalleryTabs';

interface Props {
  images: string[];
  productDamageImages: { id: number; url: string }[];
  title: string;
}

const LotImageGallery: FC<Props> = ({ images, productDamageImages, title }) => (
  <Card title={title}>
    {images.length || productDamageImages.length ? (
      <GalleryTabs mainImages={images || []} damageImages={productDamageImages || []} />
    ) : (
      <Image width={200} src="error" fallback="img/noImg.jpg" alt="No image" />
    )}
  </Card>
);

export default memo(LotImageGallery);
