import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';
import { dashboardLotsLoadingSelector, dashboardLotsSelector, getDashboardLots } from '../../store/slices/productSlice';
import TableFooter from '../../shared/components/TableFooter';
import { BidByStatusInformation, MyLotItem } from '../../interfaces/Product';
import { getLinkForLotPage, langPrefix } from '../../utils/functions';
import DashboardPageLayout from '../../shared/components/DashboardPageLayout';
import Price from '../../shared/components/Price';

const DashboardBuyerPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboardLots = useSelector(dashboardLotsSelector);
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const dashboardLotsLoading = useSelector(dashboardLotsLoadingSelector);

  useEffect(() => {
    dispatch(getDashboardLots('buyer'));
  }, [dispatch]);

  const handleOpenSearch = useCallback(
    (filter: string) => () => {
      const filterObject = JSON.parse(filter);
      Object.keys(filterObject).map((key) => {
        params.set(key, filterObject[key].replace(/%20/g, ' '));
        return null;
      });

      history.push({
        pathname: '/findvehicles',
        search: params.toString(),
      });
    },
    [history, params],
  );

  const handleRedirect = (pathname: string) => {
    history.push(pathname);
  };

  return (
    <DashboardPageLayout>
      <Row justify="center" gutter={[20, 20]}>
        <Col xs={24} md={12}>
          <Card title={t('nav_user_menu_buyer_favorites_list')}>
            <Table
              loading={dashboardLotsLoading}
              dataSource={dashboardLots.userWatchListList.map((item) => ({
                ...item,
                key: item.id,
              }))}
              columns={[
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
                },
                {
                  title: 'Current Bid',
                  dataIndex: 'currentBid',
                  key: 'currentBid',
                  render: (currentBid: number) => <Price price={currentBid} />,
                },
                {
                  title: 'Lot#',
                  dataIndex: 'lotNumber',
                  key: 'lotNumber',
                  render: (lotNumber: string, item: MyLotItem) => <Link to={getLinkForLotPage(lotNumber, item.make, item.model, item.year)}>{lotNumber}</Link>,
                },
                {
                  title: 'Bid Now',
                  dataIndex: 'lotNumber',
                  key: 'lotNumber',
                  render: (lotNumber: string) => (
                    <Link to={langPrefix(`/lot/${lotNumber}`)}>
                      <Button size="large" type="primary" icon={<StarFilled />}>
                        Bid Now
                      </Button>
                      ,
                    </Link>
                  ),
                },
              ]}
              pagination={false}
              footer={() => (
                <TableFooter total={dashboardLots.userWatchListListTotal}>
                  <Link to={langPrefix("/mywatchlist")}>
                    <Button size="large" type="button">
                      {t('View_All')}
                    </Button>
                  </Link>
                </TableFooter>
              )}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title={t('nav_user_menu_buyer_saved_searches_list')}>
            <Table
              loading={dashboardLotsLoading}
              dataSource={dashboardLots.searchDashboardFilter
                .slice(-4)
                .reverse()
                .map((item) => ({ ...item, key: item.id }))}
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'templateName',
                  key: 'templateName',
                },
                {
                  title: 'Search',
                  dataIndex: 'filter',
                  key: 'filter',
                  render: (filter: string) => (
                    <Button size="large" type="primary" onClick={handleOpenSearch(filter)}>
                      Search
                    </Button>
                  ),
                },
              ]}
              pagination={false}
              footer={() => (
                <TableFooter total={dashboardLots.searchDashboardFilter.length}>
                  <Link to={langPrefix("/savedsearches")}>
                    <Button size="large" type="button">
                      {t('View_All')}
                    </Button>
                  </Link>
                </TableFooter>
              )}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title={t('nav_user_menu_buyer_bids_status_open_items')}>
            <Table
              loading={dashboardLotsLoading}
              dataSource={dashboardLots.openItemsList.map((item) => ({
                ...item,
                key: item.productId,
              }))}
              columns={[
                {
                  title: 'Make',
                  dataIndex: 'make',
                  key: 'make',
                },
                {
                  title: 'Model',
                  dataIndex: 'model',
                  key: 'model',
                },
                {
                  title: 'Year',
                  dataIndex: 'year',
                  key: 'year',
                },
                {
                  title: 'Current Bid',
                  dataIndex: 'currentBid',
                  key: 'currentBid',
                  render: (currentBid: number) => <Price price={currentBid} />,
                },
                {
                  title: 'Lot#',
                  dataIndex: 'lotNumber',
                  key: 'lotNumber',
                  render: (lotNumber: string, item: BidByStatusInformation) => (
                    <Link to={getLinkForLotPage(lotNumber, item.make, item.model, item.year)}>{lotNumber}</Link>
                  ),
                },
                {
                  title: 'Bid Status',
                  dataIndex: 'bidStatus',
                  key: 'bidStatus',
                },
                {
                  title: 'Increase bid',
                  dataIndex: 'lotNumber',
                  key: 'lotNumber',
                  render: (lotNumber: string, item: BidByStatusInformation) => (
                    <Link to={langPrefix(`/lot/${lotNumber}/${item.make}-${item.model}-${item.year}`)}>
                      <Button size="large" type="primary">
                        Increase Bid
                      </Button>
                      ,
                    </Link>
                  ),
                },
              ]}
              pagination={false}
              footer={() => (
                <TableFooter total={dashboardLots.openItemsListTotal}>
                  <Button size="large" type="button" onClick={() => handleRedirect(langPrefix('/buyer/bids/open'))}>
                    {t('View_All')}
                  </Button>
                </TableFooter>
              )}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title={t('nav_user_menu_buyer_bids_status_current_bids')}>
            <Table
              loading={dashboardLotsLoading}
              dataSource={dashboardLots.currentBidsList.map((item) => ({
                ...item,
                key: item.productId,
              }))}
              columns={[
                {
                  title: 'Make',
                  dataIndex: 'make',
                  key: 'make',
                },
                {
                  title: 'Model',
                  dataIndex: 'model',
                  key: 'model',
                },
                {
                  title: 'Year',
                  dataIndex: 'year',
                  key: 'year',
                },
                {
                  title: 'Current Bid',
                  dataIndex: 'currentBid',
                  key: 'currentBid',
                  render: (currentBid: number) => <Price price={currentBid} />,
                },
                {
                  title: 'Lot#',
                  dataIndex: 'lotNumber',
                  key: 'lotNumber',
                  render: (lotNumber: string, item: BidByStatusInformation) => (
                    <Link to={getLinkForLotPage(lotNumber, item.make, item.model, item.year)}>{lotNumber}</Link>
                  ),
                },
                {
                  title: 'Bid Status',
                  dataIndex: 'bidStatus',
                  key: 'bidStatus',
                },
                {
                  title: 'Increase bid',
                  dataIndex: 'lotNumber',
                  key: 'lotNumber',
                  render: (lotNumber: string) => (
                    <Link to={langPrefix(`/lot/${lotNumber}`)}>
                      <Button size="large" type="primary">
                        Increase Bid
                      </Button>
                      ,
                    </Link>
                  ),
                },
              ]}
              pagination={false}
              footer={() => (
                <TableFooter total={dashboardLots.currentBidsListTotal}>
                  <Button size="large" type="button" onClick={() => handleRedirect(langPrefix('/buyer/bids/current'))}>
                    {t('View_All')}
                  </Button>
                </TableFooter>
              )}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title={t('nav_user_menu_payments_due')}>
            <Table
              loading={dashboardLotsLoading}
              dataSource={dashboardLots.paymentsDueList.map((item) => ({
                ...item,
                key: item.id,
              }))}
              columns={[
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
                },
                {
                  title: t("table_col_location"),
                  dataIndex: 'location',
                  key: 'location',
                },
                {
                  title: 'Pay Now',
                  dataIndex: 'id',
                  key: 'id',
                  render: () => (
                    <Button size="large" type="primary" onClick={() => handleRedirect(langPrefix('/paymentsdue'))}>
                      Pay Now
                    </Button>
                  ),
                },
              ]}
              pagination={false}
              footer={() => (
                <TableFooter total={dashboardLots.paymentsDueListTotal}>
                  <Link to={langPrefix("/paymentsdue")}>
                    <Button size="large" type="button">
                      {t('View_All')}
                    </Button>
                  </Link>
                </TableFooter>
              )}
            />
          </Card>
        </Col>
      </Row>
    </DashboardPageLayout>
  );
};

export default memo(DashboardBuyerPage);
