import React, { useState, useEffect } from 'react';
import { CountryPopup, MapContainer, MapWrapper, PageTitle } from './style';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker } from '@react-google-maps/api';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const pinsEn = require('./offices/en.json');
const pinsKa = require('./offices/ka.json');
const pinsRu = require('./offices/ru.json');

interface MapProps {
  onCityHandle: (c: string) => void;
}

const pinsObj: any = {
  ka: pinsKa,
  en: pinsEn,
  ru: pinsRu,
};

const defaultCenter = {
  lat: 20,
  lng: 0.0,
};

let ZOOM_SIZE = 3;

const offices = [
  {
    name: 'Tbilisi',
    lat: 41.76247,
    lng: 44.78045,
    country: 'Georgia',
  },
  {
    name: 'Baku',
    lat: 40.3999102,
    lng: 49.860287,
    country: 'Azerbaijan',
  },
  {
    name: 'New Jersey',
    lat: 40.63271,
    lng: -74.24983,
    country: 'USA',
  },
  {
    name: 'California',
    lat: 33.866,
    lng: -118.2057,
    country: 'USA',
  },
  {
    name: 'Erevan',
    lat: 40.1785,
    lng: 44.48904,
    country: 'Armenia',
  },
  {
    name: 'Trud',
    lat: 42.23192,
    lng: 24.72735,
    country: 'Bulgaria',
  },
  {
    name: 'Bishkek',
    lat: 42.87274,
    lng: 74.59765,
    country: 'Kyrgyzstan',
  },
  {
    name: 'Vilnius',
    lat: 54.7184,
    lng: 25.29957,
    country: 'Lithuania',
  },
  {
    name: 'Kiev',
    lat: 50.450671,
    lng: 30.523055,
    country: 'Ukraine',
  },
  {
    name: 'Warszawa',
    lat: 52.2532,
    lng: 21.03701,
    country: 'Poland',
  },
  {
    name: 'Rotterdam',
    lat: 51.916883,
    lng: 4.47456,
    country: 'Netherlands',
  },
  {
    name: 'Guangzhou',
    lat: 23.16365,
    lng: 113.25622,
    country: 'China',
  },
  {
    name: 'Dubai',
    lat: 25.26302,
    lng: 55.30489,
    country: 'United Arab Emirates',
  },
  {
    name: 'Bremerhaven',
    lat: 53.52636,
    lng: 8.58289,
    country: 'Germany',
  },
];

const styleArray = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#D8D8D8',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'on' }, { color: '#6e7281' }],
  },
  {
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#EFEFEF',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

const Map = ({ onCityHandle }: MapProps) => {
  const { md } = useBreakpoint();
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [googleLoaded, setGoogleLoaded] = useState(false);

  const { i18n } = useTranslation();
  const pins: any = pinsObj[i18n.language] || pinsEn;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key='+process.env.REACT_APP_GOOGLE_MAP_KEY;
    script.async = true;
    script.onload = () => {
      setGoogleLoaded(true);
      initializeMap();
    };
    script.onerror = () => {
      alert('Failed to load Google Maps.');
    };
    document.body.appendChild(script);
  }, [window.google]);

  const closePopup = () => {
    setSelectedCountry(null);
  };
  const initializeMap = () => {
    const mapElement = document.getElementById('map-canvas') as HTMLElement;
    const mapInstance = new window.google.maps.Map(mapElement, {
      zoom: !md ? 2 : ZOOM_SIZE,
      maxZoom: !md ? 2 : ZOOM_SIZE,
      minZoom: !md ? 2 : ZOOM_SIZE,
      center: defaultCenter,
      disableDefaultUI: true,
      styles: styleArray,
    });

    offices.forEach((office) => {
      const marker = new window.google.maps.Marker({
        position: { lat: office.lat, lng: office.lng },
        map: mapInstance,
        title: office.name + ', ' + office.country,
        icon: {
          url:
            'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjc0NjcgNS42MzMxN0MxMy4wNDY3IDIuNTUzMTcgMTAuMzYwMSAxLjE2NjUgOC4wMDAwNiAxLjE2NjVDOC4wMDAwNiAxLjE2NjUgOC4wMDAwNiAxLjE2NjUgNy45OTM0IDEuMTY2NUM1LjY0MDA2IDEuMTY2NSAyLjk0NjczIDIuNTQ2NSAyLjI0NjczIDUuNjI2NUMxLjQ2NjczIDkuMDY2NSAzLjU3MzQgMTEuOTc5OCA1LjQ4MDA2IDEzLjgxMzJDNi4xODY3MyAxNC40OTMyIDcuMDkzNCAxNC44MzMyIDguMDAwMDYgMTQuODMzMkM4LjkwNjczIDE0LjgzMzIgOS44MTM0IDE0LjQ5MzIgMTAuNTEzNCAxMy44MTMyQzEyLjQyMDEgMTEuOTc5OCAxNC41MjY3IDkuMDczMTcgMTMuNzQ2NyA1LjYzMzE3Wk04LjAwMDA2IDguOTczMTdDNi44NDAwNiA4Ljk3MzE3IDUuOTAwMDYgOC4wMzMxNyA1LjkwMDA2IDYuODczMTdDNS45MDAwNiA1LjcxMzE3IDYuODQwMDYgNC43NzMxNyA4LjAwMDA2IDQuNzczMTdDOS4xNjAwNiA0Ljc3MzE3IDEwLjEwMDEgNS43MTMxNyAxMC4xMDAxIDYuODczMTdDMTAuMTAwMSA4LjAzMzE3IDkuMTYwMDYgOC45NzMxNyA4LjAwMDA2IDguOTczMTdaIiBmaWxsPSIjQ0UyNDI0Ii8+Cjwvc3ZnPgo=',
          scaledSize: new window.google.maps.Size(32, 32),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(16, 32),
        },
      });

      marker.addListener('click', () => {
        const selectedCountry = pins.find((c: any) => c.def_title == office.country);
        if (selectedCountry) {
          setSelectedCountry(selectedCountry);
        } else {
          setSelectedCountry(null);
        }
      });
    });
  };

  return (
    <MapWrapper>
      {selectedCountry && (
        <CountryPopup>
          <h2>{selectedCountry?.title}</h2>
          <span onClick={closePopup} className="close">
            X
          </span>
          <nav>
            {selectedCountry.offieces.map((office: any) => (
              <a key={office.name} onClick={() => onCityHandle(office)}>
                {office.name}
                <span>{office.address}</span>
                <span>{office.phone}</span>
              </a>
            ))}
          </nav>
        </CountryPopup>
      )}
      <MapContainer>
        <>
        
          {googleLoaded && (
            <GoogleMap
              id="map-canvas"
              mapContainerStyle={{ width: '100%', height: '100%' }}
              zoom={!md ? 2 : ZOOM_SIZE}
              center={defaultCenter}
              options={{
                styles: styleArray,
                disableDefaultUI: true,
                zoomControl: false,
                scrollwheel: false,
              }}
              onLoad={initializeMap}
            />
          )}
        </>
      </MapContainer>
    </MapWrapper>
  );
};

export default Map;
