import React, { FC, memo } from 'react';
import { getLocation } from '../../../utils/functions';
import { Vehicle } from '../../../interfaces/FindVehicles';
import { MyLotItem, BidByStatusInformation } from '../../../interfaces/Product';
import { Payments } from '../../../interfaces/Payments';

interface Props {
  locationId: number;
  vehicle: Vehicle | MyLotItem | BidByStatusInformation | Payments;
}

const LocationForTable: FC<Props> = ({ locationId, vehicle }) => {
  const { name, locationType } = getLocation(locationId, vehicle);
  return (
    <div>
      <div>{name}</div>
      <div>{locationType}</div>
    </div>
  );
};
export default memo(LocationForTable);
