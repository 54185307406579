import React, { useState } from 'react'
import { IconWrapper, ReadMoreLink, ServiceCardDescriptionWrapper, ServiceCardHeader, ServiceCardWrapper, ShowMoreIconWrapper } from './style'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

export enum ServiceCardVariant {
  DARK = 1,
  DARK_DESKTOP
}

export interface ServiceModal {
  title: string;
  icon: string;
  description: string[]
}

interface ServiceCardProps {
  variant?: ServiceCardVariant;
  service: ServiceModal
}


const ServiceCard = ({variant, service}: ServiceCardProps) => {
  const { t } = useTranslation()
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false)

  const DescriptionButtons = () => {
    if(isDescriptionVisible){
      return (
        <ShowMoreIconWrapper onClick={() => setIsDescriptionVisible(false)}>
            <ReactSVG src={'/icons/minus.svg'}/>
        </ShowMoreIconWrapper>
      )
    }else{
      return (
        <ShowMoreIconWrapper onClick={() => setIsDescriptionVisible(true)}>
            <ReactSVG src={'/icons/add.svg'}/>
        </ShowMoreIconWrapper>
      )
    }
  }

  return (
    <ServiceCardWrapper variant={variant}>
      <ServiceCardHeader>
        <IconWrapper>
            <ReactSVG src={service.icon}/>
        </IconWrapper>
        <h3>{t(service.title)}</h3>
        {variant != ServiceCardVariant.DARK && <DescriptionButtons />}
      </ServiceCardHeader>
      <ServiceCardDescriptionWrapper showMore={isDescriptionVisible} variant={variant}>
        {service.description.map((key: string, index: number) => (
          <p key={index}>{t(key)}</p>
        ))}
      </ServiceCardDescriptionWrapper>
      {variant == ServiceCardVariant.DARK && <ReadMoreLink onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}>
        {isDescriptionVisible ? "Read Less": "Read More"}
      </ReadMoreLink>}
    </ServiceCardWrapper>
  )
}

export default ServiceCard