import React from 'react';
import { Slider, InputNumber } from 'antd';
import 'antd/dist/antd.css';
import { CollapsePrice } from './style';

interface PriceSliderProps {
  min: number;
  max: number;
  value: [number, number];
  onChange: (value: [number, number]) => void;
  showInputs?: boolean; // New prop to determine whether to show inputs or not
}

const PriceSlider: React.FC<PriceSliderProps> = ({ min, max, value, onChange, showInputs = true }) => {
  const [sliderValue, setSliderValue] = React.useState<[number, number]>(value);

  const handleChange = (newValue: [number, number]) => {
    setSliderValue(newValue);
    onChange(newValue);
  };

  React.useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const formatValue = (val: number) => `${val}$`;

  return (
    <>
      {showInputs && (
        <>
        <div style={{ display: 'grid', gridTemplateColumns: '0.35fr 0.7fr 0.35fr', gap: '10px', alignItems: 'center' }} >
          <InputNumber
            min={min}
            max={max}
            controls={false}
            value={sliderValue[0]}
            onChange={(val) => handleChange([val || min, sliderValue[1]])}
            formatter={formatValue}
            parser={(val: string | undefined) => (val ? val.replace(/\$\s?|(,*)/g, '') : min)}
          />
          <Slider range min={min} max={max} value={sliderValue} onChange={handleChange} step={1} />
          <InputNumber
            min={min}
            max={max}
            controls={false}
            value={sliderValue[1]}
            onChange={(val) => handleChange([sliderValue[0], val || max])}
            formatter={formatValue}
            parser={(val: string | undefined) => (val ? val.replace(/\$\s?|(,*)/g, '') : max)}
          />
          </div>
        </>
      )}
      {!showInputs && <CollapsePrice><Slider range min={min} max={max} value={sliderValue} onChange={handleChange} step={1} tipFormatter={formatValue} tooltipPlacement="bottom" getTooltipPopupContainer={(triggerNode: { parentElement: any; }) => triggerNode.parentElement || triggerNode} tooltipVisible /></CollapsePrice>}
    </>
  );
};

export default PriceSlider;
