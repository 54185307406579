import React from 'react'
import { SelectsWrapper } from './style'
import SelectCountries from './SelectCountries'
import SelectCurrency from './SelectCurrency'
import SelectLanguage from './SelectLanguage'

const Settings = () => {
  return (
    <SelectsWrapper>
        {/* <SelectCountries /> */}
        <SelectCurrency />
        <SelectLanguage />
    </SelectsWrapper>
  )
}

export default Settings