import React, { FC, memo, useCallback, useState, useEffect } from 'react';
import { Button, Col, InputNumber, Space, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { getBidStep } from '../../../utils/functions';
import { lotActualDataSelector } from '../../../store/slices/auctionSlice';
import { ScParagraph, BidWrapper } from './style';
import Price from '../Price';

const { Text } = Typography;

interface Props {
  lotId: number;
  laneId: number;
  makeBid: (value: number, lotId: number, method: string) => void;
  startingBid: number;
}

const NextLotBidSection: FC<Props> = ({ lotId, makeBid, laneId, startingBid }) => {
  const lotActualData = useSelector(lotActualDataSelector(laneId, lotId));

  const [bid, setBid] = useState(startingBid || 10);
  const [step, setStep] = useState(10);

  useEffect(() => {
    setStep(getBidStep(bid));
  }, [bid]);

  useEffect(() => {
    lotActualData && setBid(lotActualData.userMaxBidValue);
  }, [lotActualData]);

  const handleMakeBid = useCallback(() => {
    makeBid(bid, lotId, 'MakeBid');
  }, [bid, makeBid, lotId]);

  return (
    <BidWrapper>
      <Col xs={14}>
        <Text strong type={lotActualData?.isWinning && lotActualData?.isYourBid ? 'success' : 'danger'}>
          <ScParagraph>Current Bid:</ScParagraph>
          <Price price={lotActualData?.bidValue || 0} />
        </Text>
      </Col>
      <Col xs={10}>
        <Space>
          <InputNumber
            min={lotActualData?.userMaxBidValue || 10}
            value={bid}
            step={step}
            onChange={setBid}
            formatter={(value?: string | number) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
          <Button type="primary" onClick={handleMakeBid}>
            Max Bid
          </Button>
        </Space>
      </Col>
    </BidWrapper>
  );
};
export default memo(NextLotBidSection);
