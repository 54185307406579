import React, { FC, memo, useEffect } from 'react';
import { MainPageWrapper } from './style';
import MainSlider from '../../shared/components/MainSlider';
import MainSearch from '../../shared/components/MainSearch';
import CarTypesSection from './CarTypesSection';
import ContactUsBanner from './ContactBanner';
import NewCarsSection from './NewCarsSection';
import Contact from '../../shared/components/Contact';
import VehicleSection from '../../shared/components/VehicleSection';
import { useDispatch, useSelector } from 'react-redux';
import { productGeneralDictionariesByLanguageSelector } from '../../store/slices/dictionarySlice';
import { featuredProductsLoadingSelector, featuredProductsSelector, getAllFeaturedProducts } from '../../store/slices/initSlice';
import { useTranslation } from 'react-i18next';
import { OrganizationSchema, WebsiteSchema } from '../../shared/components/Schema';
import Meta from '../../shared/components/Meta';
import PromoPopup from '../../shared/components/PromoPopup';

const HomePage: FC = () => {
  const dispatch = useDispatch()
  const { t} = useTranslation()
  useEffect(() => {
    dispatch(getAllFeaturedProducts());
  }, [dispatch]);

  const featuredProducts = useSelector(featuredProductsSelector);
  const featuredProductsLoading = useSelector(featuredProductsLoadingSelector);
  const productGeneralDictionariesByLanguage =
    useSelector(productGeneralDictionariesByLanguageSelector);

  return (
  <>
  <Meta
        title={t("meta_main_title")}
        description={t("meta_main_desc")}
      />
    <WebsiteSchema/>
    <OrganizationSchema/>
    {/* <PromoPopup /> */}
    <MainSlider />
    <MainSearch />
    <MainPageWrapper>
      <CarTypesSection />
      <ContactUsBanner />
      {/* <NewCarsSection /> */}
      <VehicleSection vehicles={featuredProducts} title={t("new_cars")} isLoading={featuredProductsLoading} />
      {/* <iframe src="https://lion-trans.com/checkcontainer" width="100%" height={900} frameBorder={0}></iframe> */}
      <Contact /> 
    </MainPageWrapper>
  </>)
};

export default memo(HomePage);
