import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FindVehiclesPage from './pages/FindVehiclesPage';
import AuctionCalendarPage from './pages/AuctionCalendarPage';
import AuctionDashboardPage from './pages/AuctionDashboardPage';
import HelpPage from './pages/HelpPage';
import BlogPage from './pages/BlogsPage/containers/BlogPage';
import BlogsPage from './pages/BlogsPage';
import TermsOfConditionsPage from './pages/TermsOfConditionsPage';
import HomePage from './pages/HomePage';
import SignUpPage from './pages/SignUpPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import LotPage from './pages/LotPage';
import Callback from './pages/Callback';
import PrivateComponent from './pages/PrivateComponent';
import CompareVehiclesPage from './pages/CompareVehiclesPage';
import ClassicSearchPage from './pages/ClassicSearchPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { StyledContainer } from './pages/style';
import ServicesPage from './pages/ServicesPage';
import DealerProfile from './pages/DealerProfile';
import NewAboutUsPage from './pages/NewAboutUsPage';
import Tracking from './pages/Tracking';
import Test360 from './pages/Test360';
import ElectricCars from './pages/ElectricCars';
import ElectricCarsSearch from './pages/ElectricCarsSearch';
import ElectricCarsDetaile from './pages/ElectricCarsDetaile';
import RegisterDealer from './pages/RegisterDealer';
import SellerPage from './pages/SellerPage';
import SellerProfile from './pages/SellerProfile';
import OperatedCompaniesPage from './pages/OperatedCompaniesPage';
import OperatedCompaniesDetailePage from './pages/OperatedCompaniesDetailePage';
import DealerReviewPage from './pages/DealerReviewPage';
import NotFoundPage from './pages/NotFoundPage';
import ContactPage from './pages/ContactPage';
import LogoutPage from './pages/LogOutPage';
import AppPage from './pages/AppPage';

const mainRoutes = [
    { path: "/findvehicles", component: FindVehiclesPage },
    { path: "/classicsearch", component: ClassicSearchPage },
    { path: "/test-360", component: Test360 },
    { path: "/auctioncalendar", component: AuctionCalendarPage, exact: true },
    { path: "/auctiondashboard", component: AuctionDashboardPage, exact: true },
    { path: "/help", component: HelpPage, exact: true },
    { path: "/aboutus", component: NewAboutUsPage, exact: true },
    { path: "/services", component: ServicesPage, exact: true },
    { path: "/dealer/:id", component: DealerProfile, exact: true },
    { path: "/sellers", component: SellerPage, exact: true },
    { path: "/seller-profile/:id", component: SellerProfile, exact: true },
    { path: "/blog", component: BlogsPage, exact: true },
    { path: "/blog/:id/:header", component: BlogPage, exact: true },
    { path: "/termsofconditions", component: TermsOfConditionsPage, exact: true },
    { path: "/termsofservice", component: TermsOfServicePage, exact: true },
    { path: "/privacypolicy", component: PrivacyPolicyPage, exact: true },
    { path: "/", component: HomePage, exact: true },
    { path: "/electric-cars", component: ElectricCars, exact: true },
    { path: "/electric-cars-search", component: ElectricCarsSearch, exact: true },
    { path: "/electric-cars-detaile/:id", component: ElectricCarsDetaile, exact: true },
    { path: "/signup", component: SignUpPage, exact: true },
    { path: "/contact", component: ContactPage, exact: true },
    { path: "/app", component: AppPage, exact: true },
    { path: "/404", component: NotFoundPage, exact: true },
    { path: "/logout", component: LogoutPage, exact: true },
    { path: "/forgotpassword", component: ForgotPasswordPage, exact: true },
    { path: "/callback", component: Callback, exact: true },
    { path: "/lot/:id/:info?", component: LotPage, exact: true },
    { path: "/tracking/:vin", component: Tracking },
    { path: "/register-dealer", component: RegisterDealer, exact: true },
    { path: "/review-dealer/:guid", component: DealerReviewPage, exact: true },
    {
      path: "/auctionsalelist",
      render: () => (
        <StyledContainer>
          <FindVehiclesPage />
        </StyledContainer>
      ),
      exact: true
    },
    { path: "/comparevehicles", component: CompareVehiclesPage, exact: true },
    { path: "/operated-by-liontrans", component: OperatedCompaniesPage, exact: true },
    { path: "/operated-by-liontrans/:id", component: OperatedCompaniesDetailePage, exact: true },
    { path: "/", component: PrivateComponent }
  ];

export default mainRoutes;
