import styled from 'styled-components';

export const ContactBannerWrapper = styled.div`
  background: #212121;
  padding: 0 3.75rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  @media(max-width: 1200px){
    padding: 7rem 0 4rem;
    margin: 0;
    background-color: transparent;
    overflow: hidden;
  }
  @media(max-width: 767px){
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
`;

export const ContactBannerContainer = styled.div`
  max-width: 90rem;
  margin: 0 auto;
  padding: 1.75rem 2.5rem;
  position: relative;
  @media(max-width: 1200px){
    padding: 1.75rem 1rem;
    background: #212121;
  }

`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  width: 7.75rem;
  height: fit-content;
  margin-right: 2.5rem;
  img {
    width: 100%;
    object-fit: contain;
  }
  @media(max-width: 991px){
    margin-right: 1rem;
  }
  @media(max-width: 767px){
    width: 5rem;
  } 
  @media(max-width: 580px){
    display: none;
  }
`;

export const InfoWrapper = styled.div`
  p {
    font-family: 'main';
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 1.5rem;
    color: #ffffff;
    margin: 0;
    span {
      color: #CE2424;
    }
  }
  @media(max-width: 991px){
    p{
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  margin-top: 1.5rem;
  a:not(:last-child){
    margin-right: 1.125rem;
  }
  @media(max-width: 991px){
    flex-direction: column;
    a:not(:last-child){
      gap: 0.25rem;
    }
    margin-top: 0.5rem;
  }
`;

export const Website = styled.a`
  font-family: 'main';
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #ffffff;
  @media(max-width: 767px){
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

export const Phone = styled.a`
  font-family: 'main';
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #ffffff;
  @media(max-width: 767px){
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

export const CarWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(0, -50%);
  img {
    width: 37rem;
    aspect-ratio: 2;
    object-fit: contain;
  }
  @media(max-width: 1440px){
    img {
      width: 28rem;
      aspect-ratio: 2;
    }
  }
  @media(max-width: 1200px){
    transform: translate(30%, -50%);
  }
  @media(max-width: 767px){
    transform: translate(45%, -50%);
  }
  @media(max-width: 580px){
    img{
      width: 22rem;
    }
  }
  @media(max-width: 375px){
    img{
      width: 15rem;
    }
  }
`;
