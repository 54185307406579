const api = {
  Login: 'Account/Login',
  Lgout: 'Account/Lgout',

  GetAllSliderImages: 'ContentManagement/GetAllSliderImages',
  LoadAllPopularVehicles: 'PopularVehicle/LoadAllPopularVehicles',
  GetAllFeaturedProducts: 'Featured/GetAllFeaturedProducts',
  CreateNewsubScriberEmail: 'subscriberemail/createnewsubscriberemail',

  // Auction
  GetAllAuctionsAddressesAsDictionary: 'Auction/GetAllAuctionsAdressesAsDictionary',
  GetAuctionDetailsForCalendar: 'Auction/GetAuctionDetailsForCalendar',

  // Lane
  GetAuctionDashboardLanesList: 'Lane/GetAuctionDashboardLanesList',
  GetCalendarDashboardLanesList: 'Lane/GetCalendarDashboardLanesList',
  GetAuctionDashboardLotsList: 'Lane/GetAuctionDashboardLotsList',

  // Product
  GetAuctionTypeStatisticWithId: 'Product/GetAuctionTypeStatisticWithId',
  GetTransitAndYardDefinition: 'Product/GetTransitAndYardDefinition',
  GetPublicOneLotPageData: 'Product/GetPublicOneLotPageData',
  GetSellerOneLotPageData: 'Product/GetSellerOneLotPageData',
  CreateNewProduct: 'Product/CreateNewProduct',

  GetLotsOnCheck: 'Product/GetLotsOnCheck',
  GetLotsOnSale: 'Product/GetLotsOnSale',
  GetBidApprovalItemsForSeller: 'BidStatusDashboard/GetBidApprovalItemsForSeller',
  GetSoldLots: 'Product/GetSoldLots',
  GetWaitAuctionLots: 'Product/GetWaitAuctionLots',
  GetProductOffersByProductOwnerId: 'Product/GetProductOffersByProductOwnerId',
  GetProductOffersByProductId: 'Product/GetProductOffersByUserId',
  UpdateAssignmentMaintenance: 'Product/UpdateAssignmentMaintenance',
  CreateNewLotNote: 'Product/CreateNewLotNote',
  GetClearForPickUpLots: 'Product/GetClearForPickUpLots',

  // Search
  GetProductsForComparePage: 'Search/GetProductsForComparePage',
  GetSuggestions: 'search/getsuggestions',
  Search: 'Search/Search',
  SearchLotsByLane: 'Search/SearchLotsByLane',
  GetUserWatchList: 'Search/GetUserWatchList',

  // Blog
  GetMainBlog: 'Blog/GetMainBlog',
  GetAllBlogs: 'Blog/GetAllBlogs',
  GetBlogById: 'Blog/GetBlogById',

  // Help
  GetAllFaqQuestions: 'ContentManagement/GetAllFaqQuestions',

  // User
  ForgotPassword: 'User/ForgotPassword',
  Register: 'User/Register',
  GetUserNotifications: 'UserNotification/GetUserNotifications',
  AddNewUser: 'UsersToContact/AddNewUser',

  // Dictionary
  GetCurrency: 'Currency/GetCurrency',
  GetProductGeneralDictionariesByLanguage: 'Dictionary/GetProductGeneralDictionariesByLanguage',
  GetMakeModelsByVehicleType: 'Dictionary/GetMakeModelsByVehicleType',
  GetTransportInfo: 'Dictionary/GetTransportInfo',

  // DashbordSettings
  GetSearchDashboardFilter: 'DashbordSettings/GetSearchDashboardFilter',
  DeleteDashboardFilter: 'DashbordSettings/DeleteDashboardFilter',
  CreateNewSearchDashboardFilter: 'DashbordSettings/CreateNewSearchDashboardFilter',

  // Profile
  GetUserLanguageDictionary: 'PersonalAccount/GetUserLanguageDictionary',
  GetAccountSettings: 'PersonalAccount/GetAccountSettings',
  GetBidderList: 'PersonalAccount/GetBidderList',
  GetUserContactInfo: 'PersonalAccount/GetUserContactInfo',
  ChangeLanguage: 'PersonalAccount/ChangeLanguage',
  ChangePassword: 'PersonalAccount/ChangePassword',
  EditUserContactInfo: 'PersonalAccount/EditUserContactInfo',
  AddNewLicenseFile: 'PersonalAccount/AddNewLicenseFile',
  DeleteUserLicenseFile: "PersonalAccount/DeleteUserLicenseFile",

  // Payments
  GetUserPaymentsDueList: 'UserPayment/GetUserPaymentsDueList',
  GetUserHistoryPaymentsList: 'UserPayment/GetUserHistoryPaymentsList',
  GetUsersPaymentsListForUser: 'UserPayment/GetUsersPaymentsListForUser',
  GetPayments: 'UserPayment/GetPayments',
  GetUserDepositsList: 'UserPayment/GetUserDepositsList',
  GetCurrentUserBalanceAmount: 'UserPayment/GetCurrentUserBalanceAmount',
  CreateMoneyWithdrawalPaymentAsUser: 'UserPayment/CreateMoneyWithdrawalPaymentAsUser',
  GetDataForProductInvoices: 'UserPayment/GetDataForProductInvoices',
  GetDataForDepositInvoices: 'UserPayment/GetDataForDepositInvoices',
  CreateDepositPayment: 'UserPayment/CreateDepositPayment',
  GetPaymentOrderUrl: 'UserPayment/GetPaymentOrderUrl',
  CreateVehicleCheckServiceOrder: 'UserPayment/CreateVehicleCheckServiceOrder',
  CheckUserServiceRequests: 'UserPayment/CheckUserServiceRequests',

  GetFineTypes: 'FineAndFeeSettings/GetFineTypes',
  GetFeesByRange: 'FineAndFeeSettings/GetFeesByRange',
  GetSellerFee: 'FineAndFeeSettings/GetSellerFee',
  GetFormsFiles: 'FormsFile/GetFormsFiles',
  DownloadFormFile: 'FormsFile/DownloadFormFile',

  // Bids
  GetBidsByStatusForUser: 'BidStatusDashboard/GetBidsByStatusForUser',
  BuyerStayOnPreviousBid: 'Bargain/BuyerStayOnPreviousBid',
  BuyerNewBid: 'Bargain/BuyerNewBid',
  BuyerApprove: 'Bargain/BuyerApprove',
  SellerNewPrice: 'Bargain/SellerNewPrice',
  SellerDecline: 'Bargain/SellerDecline',
  SellerApprove: 'Bargain/SellerApprove',

  MakePreliminaryBid: 'PreliminaryBid/MakePreliminaryBid',
  BuyNow: 'PreliminaryBid/BuyNow',
  MakeOffer: 'PreliminaryBid/MakeOffer',
  CancelOffer: 'PreliminaryBid/CancelOffer',
  ApproveOffer: 'PreliminaryBid/ApproveOffer',

  GetAllPrices: 'Calculator/GetAllPrices',

  // WatchList
  DeleteProduct: 'UserWatchProduct/DeleteProduct',
  AddProduct: 'UserWatchProduct/AddProduct',
  GetUserWatchListProductsId: 'UserWatchProduct/GetUserWatchListProductsId',
  SearchByVinNumber: "Search/SearchByVinNumber",

  CreateSearchQueryByVinCode: "Search/CreateSearchQueryByVinCode",
  ExecuteSearchQueryByVinCode: "Search/ExecuteSearchQueryByVinCode"

};

export default api;
