import React, {useCallback} from 'react'
import { OddometerTogglerWrapper } from './style'
import { ReactSVG } from 'react-svg'
import { useSelector, useDispatch } from 'react-redux';
import { changeOdometrIsKm, odometrIsKmSelector } from '../../../store/slices/initSlice';
import { useCookies } from 'react-cookie';

const OddometerToggler = () => {
    const dispatch = useDispatch();
    const odometrIsKm = useSelector(odometrIsKmSelector);
    const [cookies, setCookie] = useCookies(['odometrIsKm']);

    const handleChangeOdometer = useCallback(
        (val) => {
          setCookie('odometrIsKm', !odometrIsKm, { path: '/' });
          dispatch(changeOdometrIsKm(!odometrIsKm));
        },
        [dispatch, setCookie, odometrIsKm],
    );


  return (
    <OddometerTogglerWrapper>
        <span>{odometrIsKm ? "Km" : "Mi"}</span>
        <div className="button-wrapper">
            <button className='top' onClick={handleChangeOdometer}>
                <ReactSVG src='/icons/arrow-left.svg' className='svg-wrapper'/>
            </button>
            <button className='bottom' onClick={handleChangeOdometer}>
                <ReactSVG src='/icons/arrow-left.svg' className='svg-wrapper'/>
            </button>
        </div>
    </OddometerTogglerWrapper>
  )
}

export default OddometerToggler