import React, { FC, ForwardRefRenderFunction, forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router-dom';
import { findVehiclesSelector, isLoadingSelector } from '../../../store/slices/findVehiclesSlice';
import FeaturedProductCard from '../../../shared/components/FeaturedProductCard';
import { CardListWrapper, ClassicSerchAdvertising } from '../style';
import { classicSearchBanners, permanentBanners } from '../../../utils/constants';
import NewCarsCard, { NewCarsCardLoadingSkeleton } from '../../HomePage/NewCarsCard';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import VehicleSearchCard, { VehicleSearchCardSkeleton } from '../../../shared/components/VehicleSearchCard';
interface ResultLotsListProps {}

const ResultLotsList: ForwardRefRenderFunction<HTMLDivElement, ResultLotsListProps> = (props, ref) => {
  const { lg } = useBreakpoint()
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);

  const [ads, setAds] = useState<
  {
    id: number;
    isDeleted: boolean;
    languageKeyName: string;
    title: string;
    body: string;
  }[]
  >([]);

  const findVehicles = useSelector(findVehiclesSelector);
  const isLoading = useSelector(isLoadingSelector);

  const getParamValue = useCallback((field: string) => params.get(field)?.replace(/%2C/g, ',') || undefined, [params]);

  useEffect(() => {
    const pageItemsCount = findVehicles.length;
    if (!isLoading && pageItemsCount) {
      const page = getParamValue('page');
      const adsPerPage = pageItemsCount / 5;
      const Page = page ? +page : 1;
      const adsCount = adsPerPage * Page;
      const neededAds = classicSearchBanners.slice(adsCount - adsPerPage, adsCount);
      setAds(neededAds);
    }
  }, [findVehicles, getParamValue, isLoading]);

  if (isLoading && !findVehicles.length) {
    return (
      <CardListWrapper>
        {[...Array(4).keys()].map((skeleton) => (
          lg ? <NewCarsCardLoadingSkeleton /> : <VehicleSearchCardSkeleton />
        ))}
      </CardListWrapper>
    );
  }

  // if (!isLoading && !findVehicles.length) {
  //   return (
  //     <Row gutter={20} justify="center">
  //       <Col>{ReactHtmlParser(permanentBanners.find((item) => item.title === 'searchClaNotFoundLot')?.body || '')}</Col>
  //     </Row>
  //   );
  // }

  return (
    <CardListWrapper ref={ref}>
      {lg 
      ? <>
        {findVehicles.map((product, index) => (
          <NewCarsCard product={product} isLoading={isLoading} key={product.id}/>
        ))}
      </> 
      : <>
        {findVehicles.map((vehicle) => (
          <VehicleSearchCard key={vehicle.id} vehicle={vehicle}/>
        ))}
      </>}
    </CardListWrapper>
  );
};

export default forwardRef(ResultLotsList);
