import { Row, Space, Checkbox, Button } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../core/api';
import { axiosInstance } from '../../core/axios';
import { userSelector, toggleModalAuth } from '../../store/slices/authSlice';
import { createVehicleCheckServiceOrder } from '../../store/slices/paymentsSlice';
import { ScCard, ServiceOrderButton } from './style';
import LotDescriptionCard, { LotCardRow } from '../../shared/components/LotDescriptionCard';
import { getUserContactInfo, userContactInfoSelector } from '../../store/slices/profileSlice';

interface Props {
  productId: string;
}

const ServiceOrderCard: FC<Props> = ({ productId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [electronicReportRequested, setelectronicReportRequested] = useState({
    value: false,
    disabled: false,
  });
  const [photoReportRequested, setphotoReportRequested] = useState({
    value: false,
    disabled: false,
  });
  const [videoReportRequested, setvideoReportRequested] = useState({
    value: false,
    disabled: false,
  });

  const user = useSelector(userSelector);


  const userContactInfo = useSelector(userContactInfoSelector);

  useEffect(() => {
    user && dispatch(getUserContactInfo());
  }, [dispatch]);

  useEffect(() => {
    const checkUserServiceRequests = async () => {
      const res = await axiosInstance.get(api.CheckUserServiceRequests, {
        params: { productId },
      });
      setelectronicReportRequested({
        value: res.data.electronicReportRequested,
        disabled: res.data.electronicReportRequested,
      });
      setphotoReportRequested({
        value: res.data.photoReportRequested,
        disabled: res.data.photoReportRequested,
      });
      setvideoReportRequested({
        value: res.data.videoReportRequested,
        disabled: res.data.videoReportRequested,
      });
    };

    user && checkUserServiceRequests();
  }, [productId, user]);

  const handleOrder = useCallback(() => {
    if (!userContactInfo) return dispatch(toggleModalAuth(true));
    if (user?.id_token) {
      dispatch(createVehicleCheckServiceOrder(productId, electronicReportRequested.value, photoReportRequested.value, videoReportRequested.value, userContactInfo?.phoneNumber, userContactInfo?.firstName, userContactInfo?.lastName));
    } else {
      dispatch(toggleModalAuth(true));
    }
  }, [user?.id_token, dispatch, productId, electronicReportRequested, photoReportRequested, videoReportRequested]);

  return (
    <LotDescriptionCard 
        title={`${t('lot_service_order')}`}
    >
      <>
        <div className="checkbox-wrapper">
            <Checkbox
              checked={photoReportRequested.value}
              onChange={({ target }) =>
                setphotoReportRequested({
                  value: target.checked,
                  disabled: false,
                })
              }
              disabled={photoReportRequested.disabled}
            >
              {t('lot_photo_report')}
            </Checkbox>

            <Checkbox
              checked={videoReportRequested.value}
              onChange={({ target }) =>
                setvideoReportRequested({
                  value: target.checked,
                  disabled: false,
                })
              }
              disabled={videoReportRequested.disabled}
            >
              {t('lot_video_report')}
            </Checkbox>

            <Checkbox
              checked={electronicReportRequested.value}
              onChange={({ target }) =>
                setelectronicReportRequested({
                  value: target.checked,
                  disabled: false,
                })
              }
              disabled={electronicReportRequested.disabled}
            >
              {t('lot_electronic_diagnostic')}
            </Checkbox>
        </div>
        <ServiceOrderButton
          type="primary"
          onClick={handleOrder}
          className='service-order-submit'
          disabled={
            !(
              (photoReportRequested.value && !photoReportRequested.disabled) ||
              (videoReportRequested.value && !videoReportRequested.disabled) ||
              (electronicReportRequested.value && !electronicReportRequested.disabled)
            )
          }
        >
        {t('lot_service_order_btn')}
        </ServiceOrderButton>
      </>
    </LotDescriptionCard>
  );
};

export default memo(ServiceOrderCard);
