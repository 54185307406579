import { LinkProps, Link } from 'react-router-dom';
import Slider from 'react-slick';
import styled, { css, keyframes } from 'styled-components';

import { Spin } from 'antd';

const shine = keyframes`
  to {
    background-position-x: -200%;
  }
`

const loadingCss = css`
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s ${shine} linear infinite;
`

export const NewsCarWrapper = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #c6c6c6;
`;

export const ImageWrapper = styled(Link)`
  display: flex;
  width: 100%;
  position: relative;
  aspect-ratio: 1.07;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1.07;
  }
  > span{
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    background: rgba(255, 255, 255, 0.9);
    padding: 0.125rem;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #1B153D;
    border-radius: 0.25rem;
  }
  .slick-slider{
      padding: 0 !important;
    }
`;
    

export const ImageWrapperSkeleton = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 1.07;
  ${loadingCss};
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @media(max-width: 1200px){
    padding: 0.75rem;
  }
`;

interface MainInfoWrapperProps {
  isLoading?: boolean
}

export const MainInfoWrapper = styled.div<MainInfoWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  height: 3rem;
  ${p => p.isLoading && loadingCss}
`;

export const TitleWrapper = styled.div``;

export const Title = styled.h3`
  font-family: 'main';
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #1b153d;
  margin: 0;
  @media(max-width: 1200px){
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

export const SubTitle = styled.span`
  font-family: 'main';
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #9095a6;
  margin: 0;
`;

export const CustomPrice = styled.div`
  flex-shrink: 0;
  white-space: nowrap;
  font-family: 'main';
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.125rem;
  letter-spacing: -0.24px;
  color: #d71a09;
  @media(max-width: 1200px){
    font-size: 1rem;
    line-height: 1rem;
  }
`;

interface FeaturesListProps {
  isLoading?: boolean
}

export const FeaturesList = styled.div<FeaturesListProps>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem 0.5rem;
  margin-bottom: 2rem;
  ${p => p.isLoading && css`
    min-height: 6rem;
    ${loadingCss}  
  `}
`;

export const FeaturesListItem = styled.div`
  color: #6e7281;
  display: flex;
  align-items: center;
  font-family: 'main';
  font-weight: 400;
  font-size: 0.75rem;
  height: 0.875rem;
  color: #6e7281;
  .icon-wrapper > div {
    display: flex;
    align-items: center;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`;

export const BuyBtnSkeleton = styled.div`
  background: #CE2424;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.875rem;
  ${loadingCss}
`

export const BuyBtn = styled(Link)`
  display: flex;
  justify-content: center;
  margin-top: auto;
  background: #CE2424;
  border-radius: 0.5rem;
  font-family: 'main';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  width: 100%;
  padding: 0.875rem;
  &:hover{
    color: #fff;
  }
  @media(max-width: 1200px){
    padding: 0.5rem;
  }
`;

export const ActionButtons = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  z-index: 9;
  button{
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid rgba(202, 202, 202, 1);
    &:not(:last-child){
      margin-right: 0.75rem;
    }
    div, svg{
      width: 1rem;
      height: 1rem;
    }

  }
`

export const ScSlider = styled(Slider)`
  position: relative;
  width: 100%;
  height: 100%;
  .slick-prev,
  .slick-next {
    z-index: 111;
  }
  .slick-arrow{
    background-color: #fff;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    &:before{
      color: #ce2424;
      opacity: 1;
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }
  .slick-prev {
    left: 0.5rem;
  }
  .slick-next {
    right: 0.5rem;
  }
  .slick-slide{
    width: 100%;
  }
  .slick-slide div {
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track {
    display: flex;
    justify-content: space-between;
    margin: auto;
  }
`;


export const ScSpin = styled(Spin)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface SliderButtonWrapperProps {
  isPrevArrow?: boolean;
}

export const SliderButtonWrapper = styled.button<SliderButtonWrapperProps>`
  background: #F9FAFB;
  border: 1px solid #D1D5DB;
  border-radius: 33px;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  background: rgba(255, 255, 255, 0.5);
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  transform: translate(0, -50%);
  padding: 0;
  .svg-wrapper{
    &, & > div{
      display: flex;
      align-items: center;
    }
    path{
      stroke: #fff;
    }
  }

  ${p => p.isPrevArrow ? css`
    position: absolute;
    top: 50%;
    left: 4px;
  ` : css`
    position: absolute;
    top: 50%;
    right: 4px;
    svg{
      transform: rotate(180deg);
    }
  `}
`