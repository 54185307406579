// import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { CriticalDates } from '../../interfaces/Product';
import { odometrIsKmSelector } from '../../store/slices/initSlice';
import { getOdoValue } from '../../utils/functions';
import { ScCard } from './style';
import LotDescriptionCard, { LotCardRow, LotCardText, LotCardTextVariant } from '../../shared/components/LotDescriptionCard';
import { isAuthenticatedSelector } from '../../store/slices/authSlice';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
interface Props {
  criticalDatesInfo: CriticalDates;
  lotNumber: string;
}

const LotCard: FC<Props> = ({ criticalDatesInfo, lotNumber }) => {
  const { t } = useTranslation();
  const odometrIsKm = useSelector(odometrIsKmSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  return (
    <LotDescriptionCard title={`Lot #${lotNumber}`}>
      <>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY} >{t('lot_customs')}</LotCardText>
          <LotCardText fw={500}>{criticalDatesInfo.clearancePassed ? t('lot_customs_cleared') : t('lot_customs_before')}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_odometer')}</LotCardText>
          <LotCardText fw={500}>{getOdoValue(criticalDatesInfo.odometer, odometrIsKm, criticalDatesInfo.odometerBrand)}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_highlights_information')}</LotCardText>
          <LotCardText fw={500}>{criticalDatesInfo.highlights}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_primary_damage')}</LotCardText>
          <LotCardText fw={500}>{criticalDatesInfo.primaryDamage}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_secondary_damage')}</LotCardText>
          <LotCardText fw={500}>{criticalDatesInfo.secondaryDamage}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY}>VIN</LotCardText>
          <LotCardText fw={500}>{criticalDatesInfo.vin}             {!isAuthenticated && (
              <Tooltip title={t("lot_vin_info")}>
                <InfoCircleOutlined style={{ color: '#1890ff' }} />
              </Tooltip>
            )}</LotCardText>
        </LotCardRow>
      </>
    </LotDescriptionCard>
  );
};

export default memo(LotCard);
