import { Pagination } from "antd";
import styled from "styled-components";

export const CustomPaginationStyle = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  .ant-select {
    .ant-select-selection-item {
      font-size: 14px!important;
    }
  }
  .ant-pagination-item-link{
    border: none;
  }
  .ant-pagination-item{
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    a{
      font-family: 'main';
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: #1B153D;
    }
  }
  .ant-pagination-item-active{
    border: 1px solid #CE2424;
    border-radius: 4px;
    a{
      color: #CE2424;
    }
  }
  @media(max-width: 767px){
    justify-content: center;
    padding-bottom: 2.5rem;
    flex-wrap: wrap;
    .ant-pagination-item{
      min-width: 1.875rem;
      height: 1.875rem;
      background-color: transparent;
      margin-right: 4px;
    }
    .ant-pagination-prev, .ant-pagination-next{
      min-width: 30px;
      height: 30px;
      line-height: 30px;
      .ant-pagination-item-link{
        background-color: transparent;
      }
    }
  }
`