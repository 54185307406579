import React from 'react'
import { MobileFilterHeaderWrapper } from './style'
import { ReactSVG } from 'react-svg'

interface MobileFilterHeaderProps {
  title?: string;
  onButtonHandle: () => void;
  icon?: any;
}

const MobileFilterHeader = ({onButtonHandle, title, icon}: MobileFilterHeaderProps) => {
  return (
    <MobileFilterHeaderWrapper>
      <span>{title || "Find Vehicle"}</span>
      <button onClick={onButtonHandle}>
        {icon || <ReactSVG src="/icons/filter.svg"/>}
      </button>
    </MobileFilterHeaderWrapper>
  )
}

export default MobileFilterHeader