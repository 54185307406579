import React from 'react'
import { ForDealerBodyList, ForDealerBodyName, ForDealerBodyWrapper } from './style'
import { ReactSVG } from 'react-svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { langPrefix } from '../../../../utils/functions'

const ForDealerBody = () => {
  const { t } = useTranslation()
  return (
    <ForDealerBodyWrapper>
        <ForDealerBodyList>
            <a href={"https://dealer.liontrans.com/"} target="_blank">
                <ReactSVG src="/icons/header/dashboard.svg" className='icon'/>
                <ForDealerBodyName>{t("dealer_accaunt")}</ForDealerBodyName>
            </a>
            <Link to={langPrefix("/register-dealer")}>
                <ReactSVG src="/icons/setting-3.svg" className='setting-icon'/>
                <ForDealerBodyName>{t("register_dealer")}</ForDealerBodyName>
            </Link>
        </ForDealerBodyList>
    </ForDealerBodyWrapper>
  )
}

export default ForDealerBody