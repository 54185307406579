import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaProps {
  title?: string;
  description?: string;
  prev?: any;
  next?: any;
}

const Meta: React.FC<MetaProps> = ({ title, description, prev, next }) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta name="title" content={title} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {prev && <link rel="prev" href={prev} />}
      {next && <link rel="next" href={next} />}
    </Helmet>
  );
};

export default Meta;
