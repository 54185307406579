import React, {useState} from 'react';
import { ReactSVG } from 'react-svg';
import {
  FooterContainer,
  FooterWrapper,
  Group,
  GroupItem,
  GroupList,
  GroupTitle,
  MapGroup,
  MapWrapper,
  SubscribeBtn,
  SubscribeForm,
  SubscribeInput,
  SubscribeTitle,
  SubscribeWrapper,
} from './style';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sendEmail } from '../../../store/slices/authSlice';
import { message } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { langPrefix } from '../../../utils/functions';


const NewFooter = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [objValue, setObjValue] = useState({
    email: ""
  })
  const [messageApi, contextHolder] = message.useMessage();


  const onSubmit = async (e: any) => {
    e.preventDefault()
    await dispatch(sendEmail(
      "subscire",
      objValue.email
    ))

    setObjValue({
      email: ""
    })
    messageApi.open({
      type: 'success',
      content: 'Email Sent Successfully',
      duration: 1,
    });
  }
  const { md } = useBreakpoint()
  return (
    <FooterWrapper>
      {contextHolder}
      <FooterContainer>
        <Group>
          <GroupList>
            <GroupItem to={langPrefix("/aboutus")}>{t("footer_nav_about")}</GroupItem>
            <GroupItem to={langPrefix("/findvehicles")}>{t("footer_nav_find_vehicles")}</GroupItem>
            <GroupItem to={langPrefix("/electric-cars")}>{t("footer_nav_electric")}</GroupItem>
            <GroupItem to={langPrefix("/services")}>{t("footer_nav_services")}</GroupItem>
            <GroupItem to={langPrefix("/privacypolicy")}>{t("nav_main_menu_privacy_policy")}</GroupItem>
            <GroupItem to={langPrefix("/contact")}>{t("nav_main_menu_contact")}</GroupItem>
          </GroupList>
        </Group>

        <Group>
          <GroupList>
            <GroupItem as={"span"}>
              <ReactSVG src="/icons/sms.svg" className="svg-wrapper" />
              info@lionauctions.com
            </GroupItem>
            {md ?
                  <GroupItem as="span">
                      <ReactSVG src="/icons/call.svg" className="svg-wrapper" />
                      (+995 32) 255 08 80
                  </GroupItem>
               :
                  <>
                  <GroupItem as="a" href="tel:+995322550880">
                      <ReactSVG src="/icons/call.svg" className="svg-wrapper" />
                      (+995 32) 255 08 80
                  </GroupItem>
                  </>
          }
            <GroupItem as={"a"} target='_blank' href="https://www.google.com/maps/place/41%C2%B045'44.6%22N+44%C2%B046'49.6%22E/@41.762381,44.778268,16z/data=!4m4!3m3!8m2!3d41.7623889!4d44.7804444?hl=ka&entry=ttu" rel="nofollow noopener noreferrer">
              <ReactSVG src="/icons/location.svg" className="svg-wrapper" />
              {t("43 str. A.Beliashvili,Georgia, Tbilisi")}
            </GroupItem>
          </GroupList>

          {/* <SubscribeWrapper>
            <SubscribeTitle>{t("Leave your email")}</SubscribeTitle>
            <SubscribeForm onSubmit={onSubmit}>
              <SubscribeInput type={'email'} placeholder={t("subscribe_placeholder")} value={objValue.email} onChange={(e) => setObjValue({...objValue, email: e.target.value})} />
              <SubscribeBtn>Go</SubscribeBtn>
            </SubscribeForm>
          </SubscribeWrapper> */}
        </Group>
        <MapGroup>
          <MapWrapper>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2976.0592340889802!2d44.77826821543744!3d41.762380679231526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDQ1JzQ0LjYiTiA0NMKwNDYnNDkuNiJF!5e0!3m2!1ska!2sge!4v1679483044699!5m2!1ska!2sge"
              width="100%"
              height="100%"
              loading="lazy"
            ></iframe>
          </MapWrapper>
        </MapGroup>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default NewFooter;
