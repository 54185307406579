import React, {useEffect, useState} from 'react'
import MainSlider from '../../shared/components/MainSlider'
import { AdvantagesSection, LeaveMessageWrapper, SellectCardsSection, SellerCardList, SellerWrapper, SelletContactWrapper, SliderWrapper } from './style'
import VehicleSection from '../../shared/components/VehicleSection'
import { useDispatch, useSelector } from 'react-redux'
import { featuredProductsLoadingSelector, featuredProductsSelector, getAllFeaturedProducts } from '../../store/slices/initSlice'
import { productGeneralDictionariesByLanguageSelector } from '../../store/slices/dictionarySlice'
import { ReactSVG } from 'react-svg'
import SellerCard from './SellerCard'
import LeaveMessage from '../../shared/components/LeaveMessage'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next'
import SellerMainSlider from '../../shared/components/SellersMainSlider'
import Meta from '../../shared/components/Meta'

const SellerPage = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const { md } = useBreakpoint();
    const [sellers, setSellers] = useState<any[]>([])

    
    useEffect(() => {
        fetch(`/sellers.json`).then(res => res.json()).then(r => setSellers(r))
    }, [])
    
    useEffect(() => {
      dispatch(getAllFeaturedProducts());
    }, [dispatch]);
  
    const featuredProducts = useSelector(featuredProductsSelector);
    const featuredProductsLoading = useSelector(featuredProductsLoadingSelector);
    const productGeneralDictionariesByLanguage =
      useSelector(productGeneralDictionariesByLanguageSelector);

      const settings = {
        dots: true,
        infinite: false,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const advantanges = [
        {
            img: "/img/sellers/icon-03.png",
            text: "seller_advantages_1"
        },
        {
            img: "/img/sellers/icon-02.png",
            text: "seller_advantages_2"
        },
        {
            img: "/img/sellers/icon-01.png",
            text: "seller_advantages_3"
        },
        {
            img: "/img/sellers/icon-04.png",
            text: "seller_advantages_4"
        },
        {
            img: "/img/sellers/icon-06.png",
            text: "seller_advantages_5"
        },
        {
            img: "/img/sellers/icon-05.png",
            text: "seller_advantages_6"
        },
    ]
  return (
    <><Meta
          title={t("meta_sellers_title")}
          description={t("meta_sellers_desc")} /><SellerWrapper>
              <SellerMainSlider />
              <VehicleSection vehicles={featuredProducts} title={t("seller_cars_title")} isLoading={featuredProductsLoading} />
              <AdvantagesSection>
                  <h2>{t("seller_advantages_title")}</h2>
                  <ul>
                      {advantanges.map((advantage, i) => <li key={i}>
                          <div className="img-wrapper">
                              <img src={advantage.img} alt="" />
                          </div>
                          <span>{t(advantage.text)}</span>
                      </li>)}
                  </ul>
              </AdvantagesSection>
              <SelletContactWrapper>
                  <SellectCardsSection>
                      <h2>{t("sellers_seller_title")}</h2>
                      {!md ? <Slider {...settings}>
                          {sellers.map((seller) => (
                              <SellerCard key={seller.id} seller={seller} />
                          ))}
                      </Slider> : <SellerCardList>
                          {sellers.map((seller) => (
                              <SellerCard key={seller.id} seller={seller} />
                          ))}
                      </SellerCardList>}
                  </SellectCardsSection>
                  <LeaveMessageWrapper>
                      <LeaveMessage />
                  </LeaveMessageWrapper>
              </SelletContactWrapper>
          </SellerWrapper></>
  )
}

export default SellerPage