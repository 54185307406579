import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import {
  ContactInformationWrapper,
  ContactItem,
  ContactList,
  ContactListGroup,
  ContactWrapper,
  FormButton,
  FormInput,
  FormInputsWrapper,
  FormLabel,
  FormLabelList,
  FormTextarea,
  FormTitle,
  FormWrapper,
  Title,
} from './style';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { sendEmail } from '../../../store/slices/authSlice';
import { message, Form, Modal } from 'antd';
import CustomPhoneInput, { CustomPhoneInputVariant } from '../CustomPhoneInput';
import { showNotification } from '../../../utils/notify';
const contactList = require('./contact.json');

interface contactListItem {
  mobile: string;
  location: string;
  email: string;
  map: string;
}

const Contact = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [activeLabelId, setactiveLabelId] = useState(0);
  const labels = [
    {
      id: 1,
      label: 'buying_car',
    },
    {
      id: 2,
      label: 'Auction',
    },
    {
      id: 3,
      label: 'electric_cars',
    },
    {
      id: 4,
      label: 'shipping',
    },
    {
      id: 5,
      label: 'other',
    },
  ];

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const requiredFields = {
      name: t('your_name'),
      email: t('your_email'),
      phone: t('reg_per_phone_number'),
      message: t('contact_text'),
    };

    for (const field in requiredFields) {
      if (!formValues[field]) {
        showNotification('error', t('placeholder_fill'), t(requiredFields[field]));
        return;
      }
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) { showNotification('error', t('mail_validation')); return; }

    await dispatch(
      sendEmail(
        `${formValues.email} ${t(labels.find((label) => label.id == activeLabelId)?.label || '')}`,
        `${formValues.name}
        phone: ${formValues.phone}
        ${formValues.message}
      `
      )
    );
    const modal = Modal.success({
      content: t('dealer_contact_email'),
    });

    setTimeout(() => {
      modal.destroy();
    }, 5000);

    setFormValues({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };
  return (
    <ContactWrapper>
      <ContactInformationWrapper>
        {contextHolder}
        <Title>{parse(t('contact_section_title'))}</Title>
        <ContactListGroup>
          {contactList.map((item: contactListItem, index: number) => (
            <ContactList key={index}>
              <ContactItem as={'a'} href={`mailto:${item.email}`}>
                <ReactSVG src="/icons/sms.svg" className="svg-wrapper" />
                {item.email}
              </ContactItem>
              <ContactItem>
                <ReactSVG src="/icons/call.svg" className="svg-wrapper" />
                {item.mobile}
              </ContactItem>
              <ContactItem as={'a'} href={item.map} target="_blank" rel="nofollow noopener noreferrer">
                <ReactSVG src="/icons/location.svg" className="svg-wrapper" />
                {t(item.location)}
              </ContactItem>
            </ContactList>
          ))}
        </ContactListGroup>
      </ContactInformationWrapper>
      <FormWrapper onSubmit={onSubmit}>
        {/* <FormTitle>{t('contact_section_form_title')}</FormTitle> */}
        {/* <FormLabelList>
          {labels.map((label) => (
            <FormLabel key={label.id} className={label.id == activeLabelId ? 'active' : ''} onClick={() => setactiveLabelId(label.id)}>
              {t(label.label)}
            </FormLabel>
          ))}
        </FormLabelList> */}

        <FormInputsWrapper>
        <div className="input-group">
          <FormInput
            type="text"
            placeholder={t('dealer_search_name')}
            value={formValues.name}
            onChange={(e: any) => setFormValues({ ...formValues, name: e.target.value })}
          />
          <FormInput
            type="email"
            placeholder={t('your_email')}
            value={formValues.email}
            onChange={(e: any) => setFormValues({ ...formValues, email: e.target.value })}
          />
        </div>
        <div className="input-group">
          <CustomPhoneInput
            onChange={(e: any) => setFormValues({ ...formValues, phone: e })}
            country={'ge'}
            value={formValues.phone}
            placeholder={t('reg_per_phone_number')}
            variant={CustomPhoneInputVariant.CONTACT}
          />
        </div>
        <FormTextarea
          placeholder={t('your_message')}
          value={formValues.message}
          onChange={(e) => setFormValues({ ...formValues, message: e.target.value })}
        ></FormTextarea>
        </FormInputsWrapper>

        <FormButton>{t('send_message')}</FormButton>
      </FormWrapper>
    </ContactWrapper>
  );
};

export default Contact;
