/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col, InputNumber, Button, Space, TablePaginationConfig } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  getCurrentUserBalanceAmount,
  currentUserBalanceAmountSelector,
  createMoneyWithdrawalPaymentAsUser,
  getUserPaymentsDueList,
  GetUsersPaymentsListForUser,
  usersPaymentsListForUserSelector,
  usersPaymentsListForUserTotalCountSelector,
  usersPaymentsListForUserIsLoadingSelector,
} from '../../store/slices/paymentsSlice';
import CustomTable from '../../shared/components/CustomTable';
import Price from '../../shared/components/Price';
import { FundsWrapper, HeaderActionGroup, HeaderTitle, HeaderTitleGroup, HeaderWarning, HeaderWrapper, MoneyWithdrawalCardsWrapper, MoneyWithdrawalWrapper } from './style';
import { ReactSVG } from 'react-svg';
import MoneyWithdravalCard from './MoneyWithdravalCard';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const { Title, Text } = Typography;

const MoneyWithdrawalPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { md } = useBreakpoint()
  const [money, setMoney] = useState<number | undefined>();

  useEffect(() => {
    dispatch(getCurrentUserBalanceAmount());
  }, [dispatch]);

  const currentUserBalanceAmount = useSelector(currentUserBalanceAmountSelector);
  const usersPaymentsListForUser = useSelector(usersPaymentsListForUserSelector);
  const usersPaymentsListForUserTotalCount = useSelector(usersPaymentsListForUserTotalCountSelector);
  const usersPaymentsListForUserIsLoading = useSelector(usersPaymentsListForUserIsLoadingSelector);

  const handleSendRequest = useCallback(() => {
    money && dispatch(createMoneyWithdrawalPaymentAsUser(money));
    setMoney(undefined);
  }, [dispatch, money]);

  useEffect(() => {
    dispatch(GetUsersPaymentsListForUser());
  }, [dispatch]);

  const columns = [
    {
      title: 'Payment#',
      dataIndex: 'paymentNumber',
      key: 'paymentNumber',
    },
    {
      title: 'Created Date',
      dataIndex: 'paymentIssued',
      key: 'paymentIssued',
      render: (paymentIssued: string) => (paymentIssued ? dayjs(paymentIssued).format('DD/MM/YYYY HH:mm A') : ''),
    },
    {
      title: 'Paid Date',
      dataIndex: 'paidDate',
      key: 'paidDate',
      render: (paidDate: string) => (paidDate ? dayjs(paidDate).format('DD/MM/YYYY HH:mm A') : ''),
    },
    {
      title: 'Summ',
      dataIndex: 'sum',
      key: 'sum',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const handleTableChange = useCallback(
    (pg: TablePaginationConfig, fltr: any, sort: any) => {
      dispatch(
        getUserPaymentsDueList(
          'paymentshistory',
          30,
          sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc',
          pg.current,
          pg.pageSize,
          sort.field,
          '',
        ),
      );
    },
    [dispatch],
  );

  return (
    <MoneyWithdrawalWrapper>
      <HeaderWrapper>
        
        <HeaderTitleGroup>
          <HeaderTitle>{t('nav_user_menu_payments_money_withdrawal')}</HeaderTitle>
          <HeaderWarning>
            <ReactSVG src="/icons/info-circle.svg" />
            {t('createAnEnquiryToWithdrawMoney')}
          </HeaderWarning>
        </HeaderTitleGroup>

        <HeaderActionGroup>
          <FundsWrapper>
            <span>{t('yourTotalAvailableFunds')}</span>
            <Price price={currentUserBalanceAmount} />
          </FundsWrapper>
          <input type="number" value={money} placeholder="0" onChange={(e) => setMoney(Number(e.target.value))} min={0} />
          <button onClick={handleSendRequest}>
            {md 
            ? (t('sendRequest'))
            : <ReactSVG src='/icons/send-2.svg' />}
          </button>
        </HeaderActionGroup>

      </HeaderWrapper>
      {md ? <CustomTable
        data={usersPaymentsListForUser.map((item) => ({
          ...item,
          key: item.id,
        }))}
        columns={columns}
        totalCount={usersPaymentsListForUserTotalCount}
        isLoading={usersPaymentsListForUserIsLoading}
        onChangeTable={handleTableChange}
        scroll={{ x: 'max-content' }}
      />

      : <MoneyWithdrawalCardsWrapper>
        {usersPaymentsListForUser.map((payment) => (
          <MoneyWithdravalCard payment={payment}/>
          
        ))}

      </MoneyWithdrawalCardsWrapper>}
    </MoneyWithdrawalWrapper>
  );
};

export default memo(MoneyWithdrawalPage);
