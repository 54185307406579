import React, { FC, memo, useEffect } from 'react';
import { Route, Switch, Router, Link, useLocation, Redirect, useHistory } from 'react-router-dom';
import { Layout, BackTop  } from 'antd';
import Header from './shared/components/NewHeader';
import 'antd/dist/antd.css';
import './style/custom-antd.css';
import 'car-makes-icons/dist/style.css';
import './App.css';
import { ScrollToTop, langPrefix } from './utils/functions';
import NewFooter from './shared/components/NewFooter';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { PhoneOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import mainRoutes from './mainRoutes';
import languageRoutes from './languageRoutes';
import CookieConsent from 'react-cookie-consent';
import Meta from './shared/components/Meta';

const { Content } = Layout;

const App: FC = () => {
  const [cookies] = useCookies(['language']);
  const { t } = useTranslation()
  const location = useLocation();
  const history = useHistory();
  document.documentElement.lang = i18n.language;


  useEffect(() => {
    const { pathname } = location;
    const head = document.head;

    head.querySelectorAll('link[rel="alternate"]').forEach((link) => link.remove());

    const currentLanguage = i18n.language;
    let path = pathname.length > 1 ? pathname.replace(new RegExp(`^/${currentLanguage}/`), '/') : '';


    const alternateLinks = [
      { hreflang: 'en', href: `https://liontrans.com/en${path}` },
      { hreflang: 'ka', href: `https://liontrans.com${path}` },
      { hreflang: 'ru', href: `https://liontrans.com/ru${path}` },
      { hreflang: 'x-default', href: `https://liontrans.com${path}` },
    ];

    alternateLinks.forEach(({ hreflang, href }) => {
      const linkElement = document.createElement('link');
      linkElement.rel = 'alternate';
      linkElement.hreflang = hreflang;
      linkElement.href = href;
      head.appendChild(linkElement);
    });

    return () => {
      head.querySelectorAll('link[rel="alternate"]').forEach((link) => link.remove());
    };
  }, [location, i18n.language]);

  function isOnlyFeaturedType(url: string | URL) {
    const params = new URL(url).searchParams;
    return params.has('featuredtype') && Array.from(params).length === 1;
  }

  useEffect(() => {
    // let newPathname = location.pathname;
    // while (newPathname.endsWith('/') && newPathname !== '/') {
    //   newPathname = newPathname.slice(0, -1);
    // }
    // if (newPathname !== location.pathname) {
    //   history.push(newPathname);
    // }

    let canonicalUrl = window.location.origin + window.location.pathname;
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    const featuredtypeValue = new URLSearchParams(window.location.search).get("featuredtype");

    if (featuredtypeValue != null) {
      const firstFeaturedType = featuredtypeValue.split(',')[0];
      canonicalUrl += '?featuredtype=' + encodeURIComponent(firstFeaturedType).replace(/%20/g, '+');
    }
    if (canonicalLink) {
      const updatedCanonicalUrl = canonicalUrl.endsWith('/') ? canonicalUrl.slice(0, -1) : canonicalUrl;
      canonicalLink.setAttribute('href', updatedCanonicalUrl);
    } else {
      const newCanonicalLink = document.createElement('link');
      newCanonicalLink.setAttribute('rel', 'canonical');
      newCanonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(newCanonicalLink);
    }
    const metaRobotsTag = document.querySelector('meta[name="robots"]');
    if (metaRobotsTag) {
      if (window.location.href.includes('?') && isOnlyFeaturedType(window.location.href) == false) {
        metaRobotsTag.setAttribute('content', 'follow, noindex');
      }
      else {
        metaRobotsTag.setAttribute('content', 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large');
      }
    }
    else {
      if (window.location.href.includes('?') && isOnlyFeaturedType(window.location.href) == false) {
        const newMetaRobotsTag = document.createElement('meta');
        newMetaRobotsTag.setAttribute('name', 'robots');
        newMetaRobotsTag.setAttribute('content', 'follow, noindex');
        document.head.appendChild(newMetaRobotsTag);
      }
      else
      {
        const newMetaRobotsTag = document.createElement('meta');
        newMetaRobotsTag.setAttribute('name', 'robots');
        newMetaRobotsTag.setAttribute('content', 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large');
        document.head.appendChild(newMetaRobotsTag);
      }
    }

    const ogurl = document.querySelector('meta[property="og:url"]');
    if (ogurl) { ogurl.setAttribute('content', window.location.href.endsWith('/') ? window.location.href.slice(0, -1) : window.location.href); }

  }, [location.pathname, window.location.href]);

  const LowerCaseRedirect = () => {
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
      const currentPath = window.location.pathname;
      const currentSearch = window.location.search;
      const decodedPath = decodeURIComponent(currentPath);
      const lowercasePath = decodedPath.replace(/[A-Z]/g, (char) => char.toLowerCase());
      const encodedLowercasePath = encodeURIComponent(lowercasePath).replace(/%2F/g, '/');
      const params = new URLSearchParams(currentSearch);
      const lowercaseParams = new URLSearchParams();
      params.forEach((value, key) => {
        const lowerKey = key.toLowerCase();
        const lowerValue = value.toLowerCase();
        lowercaseParams.append(lowerKey, lowerValue);
      });
      const lowercaseSearch = lowercaseParams.toString();
      const newUrl = `${encodedLowercasePath}${lowercaseSearch ? '?' + lowercaseSearch : ''}`;
      if (currentPath + currentSearch !== newUrl) {
        history.replace(newUrl);
      }
    }, [location, history]);
    return null;
  };
  return (
    <div className="App">
      <Meta title={t("meta_main_title")} description={t("meta_main_desc")} />
        {i18n.language === 'ka' && (
          <Helmet>
            <meta property="og:locale" content="ka_GE"></meta>
            <link rel="stylesheet" href={`ge.css?${new Date().getTime()}`} />
          </Helmet>
        )}
        {i18n.language === 'en' && (
          <Helmet>
            <meta property="og:locale" content="en_US"></meta>
            <link rel="stylesheet" href={`en.css?${new Date().getTime()}`} />
          </Helmet>
        )}
        {i18n.language === 'ru' && (
          <Helmet>
            <meta property="og:locale" content="ru_RU"></meta>
            <link rel="stylesheet" href={`en.css?${new Date().getTime()}`} />
          </Helmet>
        )}
      <Layout>
        <Router history={history}>
          <ScrollToTop />
          <Header />
          <Content>
            <Route component={LowerCaseRedirect} />
            <Switch>
            {languageRoutes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
            {mainRoutes.map((route: any) => (
              <Route key={route.path} path={route.path} component={route.component} exact={!!route.exact}/>
            ))}
            </Switch>
          </Content>
          <CookieConsent
            style={{
              background: 'rgb(0 0 0 / 65%)',
              width: '99%',
              margin: '0.5%',
              borderRadius: '4px',
            }}
          >
            We use cookies and share information about your site visit to personalize content and ads, provide social media features and analyze traffic using
            tools such as Google Analytics. We do not sell your personal information. You can view our privacy notice, cookie policy and how to exercise your
            rights by clicking the following link. <Link to={langPrefix("/privacypolicy")} style={{textDecoration: "underline"}}>{t('nav_main_menu_privacy_policy')}</Link>
          </CookieConsent>
          <BackTop />
          <NewFooter />
        </Router>
      </Layout>
      <a href='tel:+995322550880' className="floating-button">
        <PhoneOutlined />
      </a>
    </div>
  );
}
export default memo(App);
