import React, { FC, memo } from 'react';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface Props {
  total: number;
  children?: JSX.Element;
}

const TableFooter: FC<Props> = ({ total, children }) => {
  const { t } = useTranslation();

  return (
    <Row justify="space-between">
      <Col>
        <strong>{t('Totals')}:</strong> <Text>{total}</Text>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
};

export default memo(TableFooter);
