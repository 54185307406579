import React, { FC, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './gallery.css';
import { Spin } from 'antd';
import ImageMagnify from '../ImageMagnify';

interface Props {
  items: ReactImageGalleryItem[];
}

const StyledImageGallery: FC<Props> = ({ items }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const myRenderItem = (data: ReactImageGalleryItem) => <ImageMagnify img={data} setLoaded={setLoaded} />;

  const properties = {
    renderItem: myRenderItem,
    items,
    thumbnailPosition: 'right',
    lazyLoad: true,
    slideInterval: 2000,
  };
  return (
    <Spin spinning={!loaded} size="small">
      <ImageGallery {...properties} />
    </Spin>
  );
};

export default StyledImageGallery;
