/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import api from '../../core/api';
import { axiosInstance } from '../../core/axios';
import { showNotification } from '../../utils/notify';
import { Vehicle } from '../../interfaces/FindVehicles';

export interface InitState {
  compareIds: number[];
  compareItems: Vehicle[];
  compareItemsLoading: boolean;
}

export const initialState: InitState = {
  compareIds: [],
  compareItems: [],
  compareItemsLoading: false,
};

export const compareSlice = createSlice({
  name: 'compare',
  initialState,
  reducers: {
    updateCompareIds: (state, action) => {
      state.compareIds = action.payload;
    },
    compareListRequest: (state) => {
      state.compareItemsLoading = true;
    },
    compareListSuccess: (state, action) => {
      state.compareItems = action.payload;
      state.compareItemsLoading = false;
    },
    compareListFailure: (state) => {
      state.compareItemsLoading = false;
    },
  },
});

export const { updateCompareIds, compareListRequest, compareListSuccess, compareListFailure } = compareSlice.actions;

export const getCompareList = (): AppThunk => async (dispatch, state) => {
  try {
    dispatch(compareListRequest());
    const compareList = state().compare.compareIds.length
      ? state().compare.compareIds
      : localStorage
        .getItem('compare')
        ?.split(',')
        .map((item) => +item) || [];
    const params = new URLSearchParams(compareList.map((id: number) => ['ids', `${id}`]));
    const res = await axiosInstance.get(api.GetProductsForComparePage, {
      params,
    });
    dispatch(compareListSuccess(res.data));
  } catch (error: any) {
    dispatch(compareListFailure());
    showNotification('error', 'Select a minimum of 2 vehicles for comparison and a maximum of 5. Please try again.');
  }
};

export const addToCompare = (ids: (string | number)[] | undefined): AppThunk => async (dispatch) => {
  dispatch(updateCompareIds(ids || []));
  if (ids?.length) localStorage.setItem('compare', (ids || []).join());
  else localStorage.removeItem('compare');
};

export const delFromCompare = (id: number): AppThunk => async (dispatch, state) => {
  const list = [...state().compare.compareIds];
  const newList = list.filter((item) => item !== id);
  dispatch(updateCompareIds(newList));
  if (newList.length) localStorage.setItem('compare', newList.join());
  else localStorage.removeItem('compare');
};

// SELECTORS
export const compareIdsSelector = (state: RootState) => state.compare.compareIds;
export const compareItemsSelector = (state: RootState) => state.compare.compareItems;
export const compareItemsLoadingSelector = (state: RootState) => state.compare.compareItemsLoading;

export default compareSlice.reducer;
