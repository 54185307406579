import { Form, Input, Space, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import { AddVehicleItemToSend } from '../../interfaces/Product';
import { getUserContactInfo, userContactInfoSelector } from '../../store/slices/profileSlice';
import { FormInputsWrapper, NextButton } from './style';

interface Props {
  nextPrev: (n: number) => void;
  saveFormData: (step: string, values: AddVehicleItemToSend) => void;
  data: {
    owner: string;
    ownerMobilePhoneNumber: string;
  };
  form: any;
  dialCode: string;
  setDialCode: (code: string) => void
}

const OwnerInfromationForm: FC<Props> = ({ nextPrev, saveFormData, data, form,dialCode, setDialCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChangePhoneInput = (
    value: string,
    country: {
      dialCode: string;
    },
  ) => {
    setDialCode(country.dialCode);
  };

  const onFinish = useCallback(
    (values: AddVehicleItemToSend) => {
      saveFormData('ownerInformation', {
        ...values,
        ownerMobilePhoneNumber: values.ownerMobilePhoneNumber.replace(dialCode, ''),
        dialCode,
      });
      nextPrev(1);
    },
    [saveFormData, dialCode, nextPrev],
  );

  useEffect(() => {
    !data?.owner && dispatch(getUserContactInfo());
  }, [dispatch, data]);

  const userContactInfo = useSelector(userContactInfoSelector);

  useEffect(() => {
    // ${userContactInfo?.countryCode || '995'}
    // ${data?.ownerMobilePhoneNumber.replace(/\+/g, '') ||
    // userContactInfo?.phoneNumber.replace(/\+/g, '')}`
    form.setFieldsValue({
      owner: data?.owner || `${userContactInfo?.firstName} ${userContactInfo?.lastName}`,
      ownerMobilePhoneNumber: `995`,
    });
  }, [form, data, userContactInfo]);

  const handleGoBack = () => {
    nextPrev(-1);
  };

  return (
    <FormInputsWrapper>
      <Form.Item name="owner" rules={[{ required: true, message: 'Required' }]}>
        <Input placeholder={t('lot_owner')} />
      </Form.Item>
      <Form.Item name="ownerMobilePhoneNumber" rules={[{ required: true, message: 'Required' }]}>
        <PhoneInput onChange={handleChangePhoneInput} placeholder={t('lot_phone_number')}/>
      </Form.Item>
      
      {/* <Space>
        <Form.Item>
          <NextButton type="default" id="prevBtn" onClick={handleGoBack} icon={<ArrowLeftOutlined />}>
            {t('Back')}
          </NextButton>
        </Form.Item>
        <Form.Item shouldUpdate>
          <NextButton
            type="primary"
            id="nextBtn"
            htmlType="submit"
            disabled={
              !form.getFieldValue('owner') ||
              !form.getFieldValue('ownerMobilePhoneNumber') ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          >
            {t('Next')}
          </NextButton>
        </Form.Item>
      </Space> */}
    </FormInputsWrapper>
  );
};

export default memo(OwnerInfromationForm);
