/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { axiosInstance } from '../../core/axios';
import api from '../../core/api';
import { BlogItem } from '../../interfaces/Blog';
import { showNotification } from '../../utils/notify';
import i18n from '../../i18n';

export interface InitState {
  mainBlog: BlogItem[];
  mainBlogLoading: boolean;
  mainBlogError: string;
  allBlogs: BlogItem[];
  currentBlog: BlogItem | null;
  isLoading: boolean;
}

export const initialState: InitState = {
  mainBlog: [],
  mainBlogLoading: false,
  mainBlogError: '',
  allBlogs: [],
  currentBlog: null,
  isLoading: false,
};

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    mainBlogRequest: (state) => {
      state.mainBlog = [];
      state.mainBlogLoading = true;
    },
    mainBlogSuccess: (state, action) => {
      state.mainBlog = action.payload;
      state.mainBlogLoading = false;
    },
    mainBlogFailuare: (state, action) => {
      state.mainBlogError = action.payload;
      state.mainBlogLoading = false;
    },
    allBlogsSuccess: (state, action) => {
      state.isLoading = false;
      state.allBlogs = action.payload.sort((a: any,b: any) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime() );
    },
    currentBlogSuccess: (state, action) => {
      state.currentBlog = action.payload;
    },
    blogRequest: (state) => {
      state.isLoading = true;
    },
    blogFailuare: (state) => {
      state.isLoading = false;
    },
  },
});

export const { mainBlogSuccess, mainBlogRequest, mainBlogFailuare, allBlogsSuccess, currentBlogSuccess, blogRequest, blogFailuare } = blogSlice.actions;

export const getMainBlog = (languageKeyName: 'en' | 'ru' | 'ka'): AppThunk => async (dispatch) => {
  try {
    dispatch(mainBlogRequest());
    const res = await axiosInstance.get(api.GetMainBlog, {
      params: {
        languageKeyName,
      },
    });
    dispatch(mainBlogSuccess(res.data));
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
    dispatch(mainBlogFailuare(error.response.data.errorMessage));
  }
};

export const getAllBlogs = (languageKeyName: 'en' | 'ru' | 'ka'): AppThunk => async (dispatch) => {
  try {
    dispatch(blogRequest());
    const res = await axiosInstance.get(api.GetAllBlogs, {
      params: {
        languageKeyName,
      },
    });
    dispatch(allBlogsSuccess(res.data));
  } catch (error: any) {
    dispatch(blogFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getBlogById = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(blogRequest());
    const urlWithLangParam = `${api.GetBlogById}?lang=`+i18n.language;
    const res = await axiosInstance.get(urlWithLangParam, {
      params: {
        id,
      },
    });
    dispatch(currentBlogSuccess(res.data));
  } catch (error: any) {
    dispatch(blogFailuare());
    showNotification('error', error.response.data.errorMessage);
  }
};

// SELECTORS
export const mainBlogSelector = (state: RootState) => state.blog.mainBlog;
export const mainBlogLoadingSelector = (state: RootState) => state.blog.mainBlogLoading;
export const allBlogsSelector = (state: RootState) => state.blog.allBlogs;
export const currentBlogSelector = (state: RootState) => state.blog.currentBlog;
export const isLoadingSelector = (state: RootState) => state.blog.isLoading;

export default blogSlice.reducer;
