import styled from "styled-components";

export const SearchWrapper = styled.form`
  position: relative;
  max-width: 28.75rem;
  width: 100%;
  @media(max-width: 1400px){
    max-width: 25rem;
  }
  @media(max-width: 991px){
    max-width: 16rem;
  }
  @media(max-width: 767px){
    max-width: 100%;
    path{
        fill: #A6ABAD;
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 3rem;
  padding: 0.75rem 1.25rem;
  border: none;
  border: 1px solid rgba(245, 245, 245, 0.54);
  backdrop-filter: blur(2px);
  border-radius: 0.5rem;
  background: #ffffff;
  &::placeholder {
    font-family: 'main';
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #969595;
  }
  &:focus {
    outline: none;
  }
  @media(max-width: 991px){
    padding: 0.5rem 0.875rem;
    border-radius: 6px;
  }
  @media(max-width: 767px){
    border-radius: 0;
  }
`;

export const SearchIcon = styled.button`
  position: absolute;
  top: 50%;
  right: 1rem;
  font-size: 1.25rem;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
`;

export const AutenticationWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  .form-control {
    padding-left: 0.5rem;
  }
  .flag-dropdown {
    display: none;
  }
`