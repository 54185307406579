import React, {useState} from 'react'
import { AboutDepositButton, AboutDepositContent } from './style'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import Popup from './Popup'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const AboutDeposit = () => {
    const [isAboutModalVisible, setIsAboutModalVisible] = useState(false)
    const { t } = useTranslation()

    const { md } = useBreakpoint()
  return (
    <>
        <AboutDepositButton onClick={() => setIsAboutModalVisible(!isAboutModalVisible)}>
        {t('deposit_about')}
        </AboutDepositButton>
        {isAboutModalVisible && <Popup onClose={() => setIsAboutModalVisible(false)}>
            <AboutDepositContent>
                <h3>{md ? t('deposit_about_things') : t('deposit_about')}</h3>
                <ol>
                    <li>{t('deposit_text_1')}</li>
                    <li>{t('deposit_text_2')}</li>
                    <li>{t('deposit_text_3')}</li>
                    <li>{t('deposit_text_4')}</li>
                    <li>{t('deposit_text_5')}</li>
                </ol>
            </AboutDepositContent>
        </Popup>}
    </>
  )
}

export default AboutDeposit