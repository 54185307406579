import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import React, { FC, memo } from 'react';
import { ScTitle } from '../style';
import ContentWrapper from '../../shared/components/ContentWrapper';
import Meta from '../../shared/components/Meta';

const { Text } = Typography;

const PrivacyPolicyPage: FC = () => {
  const { t } = useTranslation();

  return (
    <><Meta
      title={t("meta_privpol_title")}
      description={t("meta_privpol_desc")} /><ContentWrapper styles={{ paddingBottom: '2.5rem' }}>
        <ScTitle level={1}>{t('privacyPolicyTitle')}</ScTitle>
        <Text>
          <p>{parse(t('privacyPolicyParagraph_1_1'))}</p>
          <p>{parse(t('privacyPolicyParagraph_1_2'))}</p>
          <p>{parse(t('privacyPolicyParagraph_1_3'))}</p>
          <p>{parse(t('privacyPolicyParagraph_1_4'))}</p>
          <ul>
            <li>{parse(t('privacyPolicyParagraph_1_5'))}</li>
            <li>{parse(t('privacyPolicyParagraph_1_6'))}</li>
          </ul>
          <p></p>
          <p>{parse(t('privacyPolicyParagraph_1_7'))}</p>
          <p>{parse(t('privacyPolicyParagraph_1_8'))}</p>
          <p>{parse(t('privacyPolicyParagraph_1_9'))}</p>
          <ul>
            <li>{parse(t('privacyPolicyParagraph_1_10'))}</li>
            <li>{parse(t('privacyPolicyParagraph_1_11'))}</li>
            <li>{parse(t('privacyPolicyParagraph_1_12'))}</li>
          </ul>
          <p>{parse(t('privacyPolicyParagraph_1_13'))}</p>

        </Text>
      </ContentWrapper></>
  );
};

export default memo(PrivacyPolicyPage);
