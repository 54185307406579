import React, { useState } from 'react'
import { InputNumber, Button } from 'antd';
import { CalculateFormFooter, CalculatorFormWrapper, InputGroupWraper, Title, Wrapper } from './style';
import { useTranslation } from 'react-i18next';
import Price from '../Price';

enum InputGroupType {
    PRICE,
    PERCENT,
    PRIMARY
}

interface InputGroupProps {
    label: string
    type: InputGroupType,
    value: number,
    setValue: (val: number) => void,
    disabled?: boolean
}

const InputGroup = ({ 
    label,
    type,
    value,
    setValue,
    disabled
}: InputGroupProps) => {
    const onInputChange = (value: any) => {
        setValue(value)
    }

    const getInput = () => {

        const props = {
            defaultValue: value,
            onChange: onInputChange,
            disabled: disabled
        }

        if(type == InputGroupType.PERCENT){
            return <InputNumber
                {...props}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value: any) => value!.replace('%', '')}
            />
        }else if(type == InputGroupType.PRICE){
            return <InputNumber
                {...props}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => value!.replace(/\$\s?|(,*)/g, '')}
            />   
        }else{
            return (
                <InputNumber
                    {...props}
                />
            )
        }
    }

    return (
        <InputGroupWraper>
        <label>{label}</label>
        {getInput()}
    </InputGroupWraper>
    )
} 

const UsedCarCalculator = () => {
    const [formValues, setFormValues] = useState({
        price: 10000,
        dowmpayment: 2000,
        //annualPayment: 2000,
        //financingAmount: 8000,
        terms: 36,
        percent: 24,
        pmt: 314
    })

    function calculatePMT(rate: number, nper: number, pv: number, fv: number, type: number) {
        type = type || 0;
        fv = fv || 0;
        var pmt = (rate * (pv * Math.pow(1 + rate, nper) + fv)) / ((Math.pow(1 + rate, nper) - 1) * (1 + rate * type));
        return -pmt;
    }

    const { t }= useTranslation()

    const onFormValuesChange = (key: string, val: number) => {
        const newFormValues: any = {
            ...formValues,
            [key]: val
        }

        const downPy = newFormValues.price - newFormValues.dowmpayment
        //const financingAmount = newFormValues.price - annualPayment
        const pmt = calculatePMT(newFormValues.percent/ 100 / 12, newFormValues.terms, downPy * -1,0,0) 

       // newFormValues.annualPayment = annualPayment,
        //newFormValues.financingAmount = financingAmount,
        newFormValues.pmt = Math.round(pmt),
        setFormValues(newFormValues)
    }

  return (
    <Wrapper>
      <Title>{t('calc_info')}</Title>
      <CalculatorFormWrapper onSubmit={(e) => e.preventDefault()}>
        <InputGroup label={t('price')} type={InputGroupType.PRICE} value={formValues.price} setValue={(val) => onFormValuesChange('price', val)} />
        <InputGroup
          label={t('calc_downpayment')}
          type={InputGroupType.PRICE}
          value={formValues.dowmpayment}
          setValue={(val) => onFormValuesChange('dowmpayment', val)}
        />
        {/* <InputGroup
          label={t('calc_annualpamynet')}
          type={InputGroupType.PRICE}
          value={formValues.annualPayment}
          key={formValues.annualPayment}
          setValue={(val) => onFormValuesChange('annualPayment', val)}
          disabled={true}
        />
        <InputGroup
          label={t('calc_famount')}
          type={InputGroupType.PRICE}
          value={formValues.financingAmount}
          key={formValues.financingAmount}
          setValue={(val) => onFormValuesChange('financingAmount', val)}
          disabled={true}
        /> */}
        <InputGroup label={t('calc_terms')} type={InputGroupType.PRIMARY} value={formValues.terms} setValue={(val) => onFormValuesChange('terms', val)} />
        {/* <InputGroup label={t('calc_percent')} type={InputGroupType.PERCENT} value={formValues.percent} setValue={(val) => onFormValuesChange('percent', val)} /> */}

        <Button type="primary" htmlType="submit">
          {t('calc_button')}{' '}
        </Button>
        <CalculateFormFooter>
          <span>{t('calc_pmt')}</span>
          <span>{` $ ${(formValues.pmt || 0).toFixed(2)}`}</span>
        </CalculateFormFooter>
      </CalculatorFormWrapper>
    </Wrapper>
  )
}

export default UsedCarCalculator