import { Table, Button } from 'antd';
import React, { FC, memo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getFormsFiles, formsFilesSelector, downloadPdf, formsFilesLoadingSelector } from '../../store/slices/profileSlice';
import { FormsFileItem } from '../../interfaces/Profile';
import TableFooter from '../../shared/components/TableFooter';
import api from '../../core/api';
import ProfilePage from '.';
import { useTranslation } from 'react-i18next';

const Forms: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleDownloadPdf = useCallback(
    (id: number, title: string) => () => {
      dispatch(downloadPdf(title, `${api.DownloadFormFile}?fileId=${id}`));
    },
    [dispatch],
  );

  const columns = [
    {
      title: t("user_title"),
      dataIndex: 'title',
      key: 'title',
      render: (title: string, form: FormsFileItem) => (
        <Button type="link" size="small" onClick={handleDownloadPdf(form.id, title)}>
          {title}
        </Button>
      ),
    },
    {
      title: t("user_description"),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t("user_doc_type"),
      dataIndex: 'fileExtention',
      key: 'fileExtention',
    },
    {
      title: t("user_revision_date"),
      dataIndex: 'revisionDate',
      key: 'revisionDate',
      render: (revisionDate: string) => dayjs(revisionDate).format('DD/MM/YYYY'),
    },
  ];

  useEffect(() => {
    dispatch(getFormsFiles());
  }, [dispatch]);

  const formsFiles = useSelector(formsFilesSelector);
  const formsFilesLoading = useSelector(formsFilesLoadingSelector);

  return (
    <ProfilePage>
      <Table
        dataSource={formsFiles.map((item) => ({ ...item, key: item.id }))}
        columns={columns}
        footer={() => <TableFooter total={formsFiles.length} />}
        loading={formsFilesLoading}
      />
    </ProfilePage>
  );
};

export default memo(Forms);
