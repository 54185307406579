import React, { useEffect, useState} from 'react';
import { Popover } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ButtonWrapper, UpperHeaderWrapper, MenuContainer, ForDealerButton, MobileWrapper, MenuIconButton, SettingButton, BorderButton, TrackingSearchWrapper } from './style';
import { useTranslation } from 'react-i18next';
import { getUser, isAuthenticatedSelector, userSelector } from '../../../../store/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserWatchListProductsId } from '../../../../store/slices/productSlice';
import UserProfile from './Profile';
import { ReactSVG } from 'react-svg';
import Settings from '../Settings';
import SecondaryNavigation from '../SecondaryNavigation';
import Logo from '../Logo';
import MobaileMenu from '../MobailMenu';
import SubHeader from '../SubHeader';
import ProfileMenu from './ProfileMenu';
import JoinButtons from './JoinButtons';
import ForDealerBody from '../ForDealerBody';
import TrackingSearch from '../TrackingSearch';

const UpperHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const user = useSelector(userSelector);
  const { md } = useBreakpoint();
  
  useEffect(() => {
    if (user) {
      dispatch(getUserWatchListProductsId());
    } else {
      const token = localStorage.getItem('lionAutoToken');
      token && dispatch(getUser());
    }
  }, [dispatch, user]);

  const profileMenu = (
      <ProfileMenu fullname={`${user?.profile.FirstName} ${user?.profile.LastName}`} />
  );

  const dealerBody = (
      <ForDealerBody />
  );

  const [isPrimaryMenuOpen, setIsPrimaryMenuOpen] = useState(false)
  const [isSettingMenuOpen, setIsSettingMenuOpen] = useState(false)
  const [isJoinMenuOpen, setIsJoinMenuOpen] = useState(false)
  const [isDealerPopupOpen, setIsDealerPopupOpen] = useState(false)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)

  const onSettingCloseClick = () => {
    setIsPrimaryMenuOpen(false)
    setIsSettingMenuOpen(false)
  }


  if(!md){
    return (
      <MobileWrapper>
        <MenuIconButton onClick={() => setIsPrimaryMenuOpen(true)}>
         <ReactSVG src="/icons/menu.svg" className='svg-wrapper'/>
        </MenuIconButton>
        
        <Logo />
      
        <TrackingSearchWrapper>
          <TrackingSearch />
        </TrackingSearchWrapper>
        
        {isAuthenticated 
        ? <>
          <BorderButton onClick={() => setIsDealerPopupOpen(true)}>{t("dealer")}</BorderButton>
          <Popover 
            content={profileMenu} 
            placement="bottom" 
            open={isProfileMenuOpen} 
            trigger={"click"}
            onOpenChange={(newOpen) => setIsProfileMenuOpen(newOpen)}
            >
            <MenuContainer>
              <UserProfile fullName={`${user?.profile.FirstName} ${user?.profile.LastName}`} />
            </MenuContainer>
          </Popover>
        </>
  
        : <>
          <BorderButton onClick={() => setIsJoinMenuOpen(true)}>{t("how_it_works_buy_join")}</BorderButton>
        </> }

        {/* <SettingButton onClick={() => setIsSettingMenuOpen(true)}>
          <ReactSVG src="/icons/setting-2.svg" className='svg-wrapper'/>
        </SettingButton> */}

        {isPrimaryMenuOpen && <MobaileMenu onCloseClick={() => setIsPrimaryMenuOpen(false)}>
          <>
            <SubHeader onFindVehicleMenuClose={() => setIsPrimaryMenuOpen(false)}>
              {/* <SettingButton onClick={() => setIsSettingMenuOpen(true)}>Settings</SettingButton> */}
              <Settings />
            </SubHeader>
            <SecondaryNavigation />
          </>
        </MobaileMenu>}

        {isSettingMenuOpen && <MobaileMenu 
          onPrevPageClick={() => setIsSettingMenuOpen(false)} 
          onCloseClick={onSettingCloseClick}>
          <Settings />
        </MobaileMenu>}

        {isJoinMenuOpen && <MobaileMenu
          onCloseClick={() => setIsJoinMenuOpen(false)}
        >
          <JoinButtons />
        </MobaileMenu>}

        {isAuthenticated && isDealerPopupOpen && <MobaileMenu
           onCloseClick={() => setIsDealerPopupOpen (false)}
        >
          <ForDealerBody />
        </MobaileMenu>}

      </MobileWrapper>
    )
  }else{
    return (
      <UpperHeaderWrapper>
        <Logo />
  
        <SecondaryNavigation />
        
        <Popover content={dealerBody} placement="bottom">
          <ForDealerButton as={"button"}>
            {t("for_dealers")}
            <ReactSVG src="/icons/arrow-up.svg" className={"arrow-icon"}/>
          </ForDealerButton>
        </Popover>
  
        <Settings />
  
        {isAuthenticated 
        ? <ButtonWrapper>
            {isAuthenticated && (
              <Popover content={profileMenu} placement="bottom">
                <MenuContainer>
                  <UserProfile fullName={`${user?.profile.FirstName} ${user?.profile.LastName}`} />
                </MenuContainer>
              </Popover>
            )}
        </ButtonWrapper>
  
        : <JoinButtons /> }
  
      </UpperHeaderWrapper>
    );
  }
};

export default UpperHeader;
