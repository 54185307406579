import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 4rem 1rem;
`

export const Title = styled.h1`
    color: #1B153D;
    font-family: "main";
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5rem;
    margin-bottom: 3.5rem;
    text-align: center;
    span{
        color: #CE2424;
    }
`
export const CompanyCardList = styled.div`
    max-width: 64.5rem;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem 1.5rem;
`