import { Row, Col, Skeleton, Image } from 'antd';
import React, { FC, memo } from 'react';
import { ImageWrapper } from './style';

const IndexSkeleton: FC = () => {
  const layout = {
    xs: 12,
  };

  return (
    <>
      <Row gutter={16}>
        <Col {...layout}>
          <Skeleton />
        </Col>
        <Col {...layout}>
          <Skeleton />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...layout}>
          <Skeleton />
        </Col>
        <Col {...layout}>
          <Skeleton />
        </Col>
      </Row>
      <Row gutter={16}>
        <Skeleton />
      </Row>
    </>
  );
};

export default memo(IndexSkeleton);
