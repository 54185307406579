import { Pagination } from 'antd';
import styled from 'styled-components';

export const ReviewWrapper = styled.div`
  margin-top: 3.125rem;
  
  @media(max-width: 767px){
    border-radius: 40px 40px 0px 0px;
    background: #FFF;
    box-shadow: 0px -3px 9px 0px rgba(0, 0, 0, 0.13);
    padding-top: 1.25rem;
    padding-inline: 1rem;
    margin-inline: -1.5rem;
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    &::after{
      content: "";
      position: absolute;
      top: 0.75rem;
      left: 50%;
      transform: translate(-50%, 0);
      width: 2.5rem;
      height: 0.25rem;
      border-radius: 20px;
      background: #F2F2F2;
    }
  }
`;

export const Title = styled.h1`
  font-family: 'main';
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #1b153d;
  @media(max-width: 767px){
    padding: 0 0.5rem;
  }
`;

export const ReviewForm = styled.form`
  position: relative;
  margin-top: 2rem;
  .review-from-head{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  input{
    background: #ffffff;
    border: 1px solid rgba(139, 138, 138, 0.72);
    box-shadow: inset -1px 2px 4px rgba(185, 185, 185, 0.25);
    border-radius: 0.5rem;  
    font-family: 'main';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0.5rem 1rem;  
    width: 100%;
    flex: 1;
    &::placeholder {
      color: #a5aaac;
    }
  }
  textarea {
    padding: 1rem 1.125rem 4rem;
    background: #ffffff;
    border: 1px solid rgba(139, 138, 138, 0.72);
    box-shadow: inset -1px 2px 4px rgba(185, 185, 185, 0.25);
    border-radius: 0.5rem;
    font-family: 'main';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #1b153d;
    width: 100%;
    aspect-ratio: 3.21;
    resize: none;
    &::placeholder {
      color: #a5aaac;
    }
  }
  @media(max-width: 767px){
    order: 3;
    border-radius: 6px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    padding: 1.25rem 1rem 1.75rem;
    margin-inline: -1rem;
    textarea{
      aspect-ratio: unset;
      height: 2.75rem;
      padding: 0.75rem;
      padding-right: 3rem;
    }
  }
`;

export const ReviewBtn = styled.button`
  font-family: 'main';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 4rem;
  background: #ce2424;
  border-radius: 0.5rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  .svg-wrapper {
    &,
    & > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  @media(max-width: 767px){
    width: 1.25rem;
    height: 1.25rem;
    padding: 0;
    background-color: transparent;
    bottom: 2.9rem;
    right: 1.85rem;
    span{
      display: none;
    }
    path{
      fill: #ce2424
    }
    .svg-wrapper {
      &,
      & > svg {
        width: 1.25rem;
      height: 1.25rem;
      }
    }
  }
`;

export const ReviewCardList = styled.div`
  margin-top: 2.125rem;
  display: grid;
  gap: 1rem;
  @media(max-width: 767px){
    order: 2;
    gap: 0.75rem;
    max-height: 350px;
    overflow-y: auto;
  }
`;

export const CustomPaginationWrapper = styled.div`
  margin-block: 2rem;
`