import React from 'react';
import { CarWrapper, ContactBannerContainer, ContactBannerWrapper, ContactInfo, ContentWrapper, InfoWrapper, LogoWrapper, Phone, Website } from './style';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const ContactUsBanner = () => {
  const { t } = useTranslation()
  return (
    <ContactBannerWrapper>
      <ContactBannerContainer>
        <ContentWrapper>
          <LogoWrapper>
            <img src="/img/lionauction-logo.webp" alt="lions logo" />
          </LogoWrapper>
          <InfoWrapper>
            <p>{parse(t("CONTACT US"))}</p>
            <ContactInfo>
              <Phone href="tel:995322550880">(+ 995 32) 255 08 80</Phone>
            </ContactInfo>
          </InfoWrapper>
        </ContentWrapper>
        <CarWrapper>
          <img src="/img/banners/contact-us-banner.webp" alt="contact-us-banner" />
        </CarWrapper>
      </ContactBannerContainer>
    </ContactBannerWrapper>
  );
};

export default ContactUsBanner;
