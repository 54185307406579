import { Row, Button, Modal, Menu, Dropdown } from 'antd';
import React, { FC, memo, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';
import { useCookies } from 'react-cookie';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CheckCircleOutlined, CloseCircleOutlined, CopyOutlined, HeartFilled, HeartOutlined, SyncOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { addProduct, deleteProduct, userWatchListSelector } from '../../store/slices/productSlice';
import { userSelector, toggleModalAuth } from '../../store/slices/authSlice';
import { addToCompare, compareIdsSelector, delFromCompare } from '../../store/slices/compareSlice';
import { showNotification } from '../../utils/notify';
import { LotActionsWrapper, SpaceForShare, CopyToClipboard, CustomActionButton, ScMenu } from './style';
import useCompare from '../../hooks/useCompare';

const steps = [
  {
    selector: '.first-lot-step',
    content: 'This is my first Step',
  },
  {
    selector: '.third-lot-step',
    content: 'This is my third Step',
  },
  {
    selector: '.fourth-lot-step',
    content: 'This is my fourth Step',
  },
];

const secondStep = {
  selector: '.second-lot-step',
  content: 'This is my second Step',
};

interface Props {
  lotId: number;
  description: string;
  isAbleBuyNow?: boolean;
  tour?: string;
}

const LotActions: FC<Props> = ({ lotId, description, isAbleBuyNow, tour }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const shareLink = window.location.href;
  const { sm } = useBreakpoint();

  const [cookies, setCookie] = useCookies(['lotTour']);
  const [isTourOpen, setIsTourOpen] = useState(!tour && cookies.lotTour === 'true');
  const [restoreTour, setRestoreTour] = useState(cookies.lotTour === 'true');

  useEffect(() => {
    if (cookies.lotTour === 'true' && !tour) {
      setIsTourOpen(true);
    }
  }, [cookies.lotTour, setCookie, tour]);

  const [openShareModal, setOpenShareModal] = useState(false);
  const [isFavorite, setFavorite] = useState(false);


  const user = useSelector(userSelector);
  const userWatchList = useSelector(userWatchListSelector);
  const compareIds = useSelector(compareIdsSelector);

  useEffect(() => {
    setFavorite(!!userWatchList.find((item) => item === +lotId));
  }, [userWatchList, compareIds, lotId]);

  const handleAddToWatchList = useCallback(() => {
    if (user?.id_token) {
      if (userWatchList.find((item) => item === +lotId)) {
        showNotification('success', t("lot_delwatchlist_notification"));
        dispatch(deleteProduct(lotId));
      } else {
        showNotification('success', t("lot_addwatchlist_notification"));
        dispatch(addProduct(lotId));
      }
    } else {
      dispatch(toggleModalAuth(true));
    }
  }, [dispatch, user, userWatchList, lotId]);

  const { handleAddToCompareList } = useCompare()

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(shareLink);
      showNotification('success', t("copy_notification"));
    } catch {
      showNotification('error', t("error_notificaton"));
    }
  };

  const handleScroll = () => {
    setOpenShareModal(false);
  };

  const handleCloseTour = useCallback(() => {
    setCookie('lotTour', 'false', { path: '/' });
    setIsTourOpen(false);
    setRestoreTour(false);
  }, [setCookie]);

  const handleTourOn = useCallback(() => {
    if (cookies.lotTour === 'true') {
      handleCloseTour();
    } else {
      setCookie('lotTour', 'true', { path: '/' });
      setIsTourOpen(true);
      setRestoreTour(true);
    }
  }, [cookies.lotTour, handleCloseTour, setCookie]);

  const getSteps = () => {
    if (isAbleBuyNow) steps.splice(1, 0, secondStep);
    return steps;
  };

  const disableBody = (target: HTMLDivElement) => disableBodyScroll(target);
  const enableBody = (target: HTMLDivElement) => {
    enableBodyScroll(target);
  };

  useEffect(() => {
    if (openShareModal && isTourOpen) setIsTourOpen(false);
    if (!openShareModal && !isTourOpen && restoreTour && !tour) setIsTourOpen(true);
    if(sm) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [openShareModal, isTourOpen, restoreTour, tour]);

  const ShareMenu = ({ shareLink, copyToClipboard }) => {
    const handleShareClick = (e: { stopPropagation: () => void; }) => {
      setOpenShareModal(false);
    };

    return (
      <ScMenu onClick={handleShareClick}>
        <Menu.Item key="facebook">
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`} target="_blank" rel="nofollow noopener noreferrer">
            <img src="/img/facebook-ico.png" alt="Facebook"/>
          </a>
        </Menu.Item>
        <Menu.Item key="twitter">
          <a href={`https://twitter.com/share?url=${shareLink}`} target="_blank" rel="nofollow noopener noreferrer">
            <img src="/img/twitter-ico.png" alt="Twitter"/>
          </a>
        </Menu.Item>
        <Menu.Item key="viber">
          <a href={`viber://forward?text=${shareLink}`} target="_blank" rel="nofollow noopener noreferrer">
            <img src="/img/viber-ico.png" alt="Viber"/>
          </a>
        </Menu.Item>
        <Menu.Item key="whatsapp">
          <a href={`whatsapp://send?text=${shareLink}`} target="_blank" rel="nofollow noopener noreferrer">
            <img src="/img/whatsapp-ico.png" alt="Whatsapp"/>
          </a>
        </Menu.Item>
        <Menu.Item key="copy" onClick={copyToClipboard}>
        <img src="/img/copy-ico.png" alt="Copy"/>
        </Menu.Item>
      </ScMenu>

    );
  };

  return (
    <LotActionsWrapper className="first-lot-step">
      {user?.id_token && <CustomActionButton onClick={handleAddToWatchList} icon={<HeartFilled />} isActive={isFavorite}>
        {sm && t('lot_favorites')}
      </CustomActionButton>}

      <CustomActionButton icon={<SyncOutlined />} onClick={() => handleAddToCompareList(lotId)} isActive={compareIds.some(id => id == lotId)}>
        {sm && t('lot_compare')}
      </CustomActionButton>

      <Dropdown
        overlay={<ShareMenu shareLink={shareLink} copyToClipboard={copyToClipboard} />}
        placement="bottomRight"
        visible={openShareModal}
        onVisibleChange={(visible) => setOpenShareModal(visible)}
        trigger={['click']}
      >
        <Button icon={<ShareAltOutlined />} >{t('lot_share')}</Button>
      </Dropdown>

      {/* <Button icon={<ShareAltOutlined />} onClick={toogleShareModal}>
        {sm && t('lot_share')}
      </Button> */}
      {/* {!tour && (
        <Button onClick={handleTourOn} icon={cookies.lotTour === 'true' ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>
          {t('help_tour')}
        </Button>
      )} */}
      {/* {!!openShareModal && (
        <Modal title={shareLink} visible={!!openShareModal} centered footer={null} onCancel={toogleShareModal}>
          <SpaceForShare>
            <FacebookShareButton url={shareLink}>
              <FacebookIcon size="64px" />
            </FacebookShareButton>
            <TwitterShareButton url={shareLink}>
              <TwitterIcon size="64px" />
            </TwitterShareButton>
            <ViberShareButton url={shareLink}>
              <ViberIcon size="64px" />
            </ViberShareButton>
            <WhatsappShareButton url={shareLink}>
              <WhatsappIcon size="64px" />
            </WhatsappShareButton>
            <CopyToClipboard type="default" icon={<CopyOutlined />} onClick={copyToClipboard} />
          </SpaceForShare>
        </Modal>
      )} */}
      <Tour steps={getSteps()} isOpen={isTourOpen} onRequestClose={handleCloseTour} onAfterOpen={disableBody} onBeforeClose={enableBody} />
    </LotActionsWrapper>
  );
};

export default memo(LotActions);
