import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  background: #1b252a;
  padding: 1.55rem 2.75rem;
  @media(max-width: 1200px){
    padding: 1.5rem 1rem;
  }

`;

export const FooterContainer = styled.div`
  max-width: 90rem;
  width: 100%;
  display: grid;
  grid-template-columns: 12rem 18.75rem minmax(auto, 35rem);
  gap: 9rem;
    margin-right: auto;
  margin-left: auto;
  @media(max-width: 1400px){
    gap: 4rem;
    justify-content: space-between;
  }
  @media(max-width: 1200px){
    gap: 2rem;
    justify-content: space-between;
  }
  @media(max-width: 991px){
    grid-template-columns: 1fr 1fr;
  }
  @media(max-width: 580px){
    grid-template-columns: 1fr;
  }
`;

export const Group = styled.div``;

export const MapGroup = styled.div`
  @media(max-width: 991px){
    grid-column: 1 / 3;
    aspect-ratio: 2;
  }
  @media(max-width: 580px){
    grid-column: unset;
  }
`

export const GroupTitle = styled.h1`
  font-family: 'main';
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #ffffff;
`;

export const GroupList = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
`;

export const GroupItem = styled(Link)`
  font-family: 'main';
  &:lang(ka){
    font-family: 'arial-caps';
  }
  font-weight: 300;
  font-size: 1rem;
  line-height: 1rem;
  color: #ffffff;
  display: flex;
  margin-bottom: 1.125rem;
  .svg-wrapper {
    margin-right: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      width: 1.125rem;
      height: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    path {
      fill: #ffffff;
    }
  }
`;

export const SubscribeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const SubscribeTitle = styled.h3`
  font-family: 'main';
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.175rem;
  color: #CE2424;
  margin-top: 5rem;
`;

export const SubscribeForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 4.5rem;
`;

export const SubscribeInput = styled.input`
  width: 100%;
  background: #ffffff;
  border-radius: 0.5rem 0 0 0.5rem;
  border: 1px solid #fff;
  padding: 1rem 1.125rem;
  &,
  &::placeholder {
    font-family: 'main';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #000000;
  }
  &:focus {
    outline: none;
  }
  @media(max-width: 580px){
    width: 100%;
  }
`;

export const SubscribeBtn = styled.button`
  background: #CE2424;
  border-radius: 0px 0.5rem 0.5rem 0px;
  font-family: 'main';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 1rem 1.75rem;
  color: #ffffff;
  border: none;
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 0.5rem;
  overflow: hidden;
  iframe {
    border: none;
  }
`;
