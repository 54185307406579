import React, { useEffect, useLayoutEffect, useState } from 'react'
import { CloseButton, MobailMenuBody, MobailMenuHeader, MobailMenuWrapper, PrevPageButton } from './style'
import { ReactSVG } from 'react-svg'
import { useLocation } from 'react-router';

interface MobaileMenuProps {
    children: JSX.Element;
    onCloseClick?: () => void;
    onPrevPageClick?: () => void;
}

const MobaileMenu = ({children, onCloseClick, onPrevPageClick}: MobaileMenuProps) => {
    const [isFirstLoad,setIsFirstLoad] = useState(true);
    const location = useLocation();

    useEffect(() => {
      document.body.style.overflow = "hidden"

      return () => {
        document.body.style.overflow = "initial"
      }
    }, [])

    useEffect(() => {
      if(isFirstLoad){
        setIsFirstLoad(false)
      }else{
        onCloseClick && onCloseClick()
      }
    }, [location])

  return (
    <MobailMenuWrapper>
    <MobailMenuHeader>
      {onPrevPageClick && <PrevPageButton onClick={onPrevPageClick}>
        <ReactSVG src='/icons/back-arrow.svg' className='svg-wrapper'/>
      </PrevPageButton>}
      <CloseButton onClick={onCloseClick}>
        <ReactSVG src='/icons/close.svg' className='svg-wrapper'/>
      </CloseButton>
    </MobailMenuHeader>
    <MobailMenuBody>
        {children}
    </MobailMenuBody>
  </MobailMenuWrapper>
  )
}

export default MobaileMenu