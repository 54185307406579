import React, { useCallback, useState } from 'react'
import { Button, ButtonVariant, ButtonWrapper } from './style'
import userManager from '../../../../utils/userManager';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../../../store/slices/authSlice';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Button as ANTDButton } from 'antd';
import { useHistory } from 'react-router-dom';
import { langPrefix } from '../../../../utils/functions';

const JoinButtons = () => {
  const history = useHistory();
  const { t } = useTranslation()
  const { md } = useBreakpoint()
  const [isLogginBtnLoading, setIsLogginBtnLoading] = useState(false)

  const handleSignIn = async () => {
    const currentLocation = history.location.pathname;
    localStorage.setItem('authFrom', currentLocation);
    setIsLogginBtnLoading(true)
    await userManager.signinRedirect();
  }

  return (
    <ButtonWrapper>
      <Button as={ANTDButton} onClick={handleSignIn} variant={ButtonVariant.SECONDARY} loading={isLogginBtnLoading}>{t('Sign_In')}</Button>
      <Button to={langPrefix("/signup")} variant={ButtonVariant.DANGER} >{t('Sign_Up')}</Button>

      {!md &&
      <>
        <Button as="a" href={"https://dealer.liontrans.com/"} target="_blank" variant={ButtonVariant.SECONDARY}>{t('Dealer Accaunt')}</Button>
        <Button to={langPrefix("/register-dealer")} variant={ButtonVariant.DANGER} >{t('Register Dealer')}</Button>
      </>}
    </ButtonWrapper>
  )
}

export default JoinButtons