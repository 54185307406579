import React from 'react'
import OddometerToggler from '../OddometerToggler'
import { OdometerWrapper } from './style'

const Odometer = ({value}: {value: string}) => {
  return (
    <OdometerWrapper>
        <OddometerToggler />
        <span>{value}</span>
    </OdometerWrapper>
  )
}

export default Odometer