import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import { PaginationWrapper } from "../../../pages/ClassicSearchPage/style"

export const FilterContainerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.2rem 2.3rem 1.7rem 2.2rem;
  .ant-pagination-options{
    display: none;
  }

  .clearbtn {
    background: #F7F7F7;
    border-radius: 8px;
    width: 2.5rem;
    height: 2.5rem;
  }

  .clearbtn .svg-wrapper path {
    stroke: #3e3e3e;
  }

  @media(max-width: 991px){
    padding: 1.25rem 1rem 1.5rem;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 1rem;
  }
`

export const FilterList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  padding: 0;
  margin: 0;
`

export const FilterItem = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 1rem;
  padding: 0.625rem 0.75rem;
  font-family: 'main';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #1B153D;
  background: rgba(202, 202, 202, 0.1);
  border: 1px solid #CACACA;
  border-radius: 100px;
  button{
    cursor: pointer;
  }
  .svg-wrapper{
    width: 0.5rem;
    height: 0.5rem;
    & > div{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    svg{
      width: 100%;
      height: 100%;
    }
    path{
      stroke: #1B153D;
      fill: #1B153D;
    }
  }
  &.moreButton {
    cursor: pointer;
    .svg-wrapper{
      width: 0.8rem;
      height: 0.8rem;
      background: #000; 
      border-radius: 10px;
      path{
        stroke: #fff;
        fill: #fff;
        stroke-width: 0.22rem;
      }
    }
  }
`

export const FilterActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  ${PaginationWrapper}{
    padding: 0;
    margin: 0;
    width: fit-content;
    ul{
      padding: 0;
    }

  }
  @media(max-width: 991px){
    width: 100%;
  }
  @media(max-width: 767px){
    flex-wrap: wrap; 
    ${PaginationWrapper}{
      flex-basis: 100%;
    }
  }
`

interface FilterActionButtonProps {
  isActive: boolean
}

export const FilterActionButton = styled(Link)<FilterActionButtonProps>`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F7F7F7;
  border-radius: 8px;
  margin-left: 0.25rem;
  ${p => p.isActive ? css`
    path{
      stroke: #3e3e3e;
    }
  ` : css`
    path{
      stroke: #8f8f8f;
    }
  `}
  @media(max-width: 991px){
    display: none;
  }
`

export const TotalCount = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #1B153D;
  flex-shrink: 0;
  margin-right: 0.75rem;
  @media(max-width: 991px){
    margin-right: auto;
  }
`