import React, { FC, useEffect, memo, useCallback, useMemo, MouseEvent } from 'react';
import { Button, Typography, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { StarFilled, EyeFilled, SyncOutlined, HeartFilled } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import CustomTable from '../../../shared/components/CustomTable';
import { findVehiclesSelector, totalCountSelector, isLoadingSelector } from '../../../store/slices/findVehiclesSlice';
import { addToCompare, compareIdsSelector, delFromCompare } from '../../../store/slices/compareSlice';
import DateForTable from '../../../shared/components/DateForTable';
import { ScCountdown } from '../../LotPage/SaleInformationCard';
import { changeOpenQuickView, getQuickViewLotData, addProduct, userWatchListSelector, deleteProduct } from '../../../store/slices/productSlice';
import { axiosInstance } from '../../../core/axios';
import { CalendarDashboardLane } from '../../../interfaces/Auction';
import { getAuctionDashboardLotsList, auctionPanelsSelector } from '../../../store/slices/auctionSlice';
import { showNotification } from '../../../utils/notify';
import { getLinkForLotPage, getOdoValue } from '../../../utils/functions';
import { userSelector, toggleModalAuth } from '../../../store/slices/authSlice';
import {ScImage, ActionBtns, CenterAlign } from '../style';
import { BlinkJoin } from '../../../shared/components/AuctionsTodayTable/style';
import { Vehicle } from '../../../interfaces/FindVehicles';
import LocationForTable from '../../../shared/components/LocationForTable';
import BadgeWithTooltip from '../../../shared/components/BadgeWithTooltip';
import api from '../../../core/api';
import { advancedSearchBanners, permanentBanners } from '../../../utils/constants';
import Price from '../../../shared/components/Price';
import { odometrIsKmSelector } from '../../../store/slices/initSlice';
import { ActionButtonWrapper, PriceCellWrapper } from '../style';
import OddometerToggler from '../../../shared/components/OddometerToggler';
import QuickOverview from '../../../shared/components/AdvanceSearch/QuickOverviewButton';
import ComperaButton from '../../../shared/components/AdvanceSearch/ComperaButton';
import WatchlistButton from '../../../shared/components/AdvanceSearch/WatchlistButton';
import ImageColumn from '../../../shared/components/AdvanceSearch/ImageColumn';
import Odometer from '../../../shared/components/AdvanceSearch/Odometer';
import ColorSquare from '../../../shared/components/AdvanceSearch/ColorSquare';
import AdvanceSearchButton from '../../../shared/components/AdvanceSearch/AdvanceSearchButton';
import VehicleNameColumn from '../../../shared/components/AdvanceSearch/VehicleNameColumn';
import VehicleInfoColumn from '../../../shared/components/AdvanceSearch/VehicleInfoColumn';
import { CellContentText, CellContentWrapper } from '../../../shared/components/AdvanceSearch/style';
import ConditionColumnWrapper from '../../../shared/components/AdvanceSearch/ConditionColumnWrapper';

const DEFAULT_PAGE_COUNT = 20;

const SearchTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);

  const findVehicles = useSelector(findVehiclesSelector);
  const totalCount = useSelector(totalCountSelector);
  const isLoading = useSelector(isLoadingSelector);
  const userWatchList = useSelector(userWatchListSelector);
  const user = useSelector(userSelector);
  const auctionPanels = useSelector(auctionPanelsSelector);
  const compareIds = useSelector(compareIdsSelector);
  const odometrIsKm = useSelector(odometrIsKmSelector);

  const getParamValue = useCallback((field: string) => params.get(field)?.replace(/%2C/g, ',') || undefined, [params]);

  const handleOpenLot = async (vehicle: Vehicle) => {
    if (vehicle.auctionIsRun) {
      try {
        const res = await axiosInstance.get(api.GetAuctionDashboardLanesList, {
          params: {
            timeOffset: new Date().getTimezoneOffset() / -60,
          },
        });
        const lane = res.data.today.find((item: CalendarDashboardLane) => item.auctionId === vehicle.auctionId);
        dispatch(getAuctionDashboardLotsList(lane.laneId, +Object.keys(auctionPanels)[0]));
        params.set('laneId', `${lane.laneId}`);
        history.push({
          pathname: '/auctiondashboard',
          search: params.toString(),
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        showNotification('error', error.response.data?.errorMessage);
      }
    } else {
      history.push(getLinkForLotPage(vehicle.lotNumber, vehicle.make, vehicle.model, vehicle.year));
    }
  };

  const handleOpenQuickView = useCallback(
    (lotNumber: number) => {
      dispatch(changeOpenQuickView(lotNumber));
      dispatch(getQuickViewLotData(lotNumber));
    },
    [dispatch],
  );

  const getTimeLeftInfo = (saleDate: string) => {
    if (!saleDate) return 'Futured';
    if (new Date(saleDate).getTime() - Date.now() <= 0) return 'Live';
    return (
      <>
        <DateForTable date={saleDate} />
        <ScCountdown title="" value={saleDate} format="DD[D] HH[H] mm[M] ss[S]" onFinish={() => {}} />
      </>
    );
  };

  const insertAfter = (referenceNode: Element, newNode: HTMLTableRowElement) => {
    referenceNode.parentNode?.insertBefore(newNode, referenceNode.nextSibling);
  };

  const removeElementsByClass = (className: string) => {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      // @ts-ignore: Object is possibly 'null'
      elements[0].parentNode.removeChild(elements[0]);
    }
  };

  const createElementFromHTML = (htmlString: string) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
  };

  useEffect(() => {
    removeElementsByClass('ads');
    const pageItemsCount = findVehicles.length;
    if (!isLoading && pageItemsCount) {
      const page = getParamValue('page');
      const adsPerPage = pageItemsCount / 5;
      const Page = page ? +page : 1;
      const adsCount = adsPerPage * Page;
      const neededAds = advancedSearchBanners.slice(adsCount - adsPerPage, adsCount);
      // neededAds.forEach((ads, index) => {
      //   const tr = document.createElement('tr');
      //   const td = document.createElement('td');
      //   tr.className = 'ads';
      //   const appendItem = createElementFromHTML(ads.body);
      //   if (appendItem) {
      //     td.appendChild(appendItem);
      //     td.setAttribute('colspan', '15');
      //     td.style.textAlign = 'center';
      //     tr.appendChild(td);
      //     const row = document.getElementsByClassName(`${(index + 1) * 5 - 1}`)[0];
      //     insertAfter(row, tr);
      //   }
      // });
    }
  }, [findVehicles, getParamValue, isLoading]);

  const columns = [
    {
      title: '',
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      render: (lotNumber: string, vehicle: Vehicle) => (
        <ActionButtonWrapper className="sixth-classic-search-step">
            <Tooltip title={t('lot_compare')}>
              <ComperaButton onClick={(e: MouseEvent<HTMLElement>) =>  e.preventDefault()} id={vehicle.id} />
            </Tooltip>
            <Tooltip title={t('lot_favorites')}>
              <WatchlistButton id={+lotNumber} />
            </Tooltip>
        </ActionButtonWrapper>
      ),
    },
    {
      title: t("table_col_image"),
      dataIndex: 'mainImage',
      key: 'mainImage',
      render: (mainImage: string, vehicle: Vehicle) => (
        <ImageColumn 
         to={getLinkForLotPage(vehicle.lotNumber, vehicle.make, vehicle.model, vehicle.year)}
         mainImage={'https://image.liontrans.com/insecure/w:80/h:60/f:webp/plain/'+mainImage}
        />
      ),
    },
    {
      title: t("table_col_vehicle_name"),
      dataIndex: 'VehicleName',
      key: 'VehicleName',
      render: (year: string,vehicle: Vehicle) => (
        <VehicleNameColumn vehicle={vehicle} />
      ),
    },
    {
      title: t("table_col_vehicle_info"),
      dataIndex: 'VehicleInfo',
      key: 'VehicleInfo',
      render: (color: string, vehicle: Vehicle ) => (
        <VehicleInfoColumn vehicle={vehicle} />
      ),
    },
    {
      title: t("lot_customs"),
      dataIndex: 'clearancePassed',
      key: 'clearancePassed',
      render: (clearancePassed: boolean) => (<>
        <CellContentText>{clearancePassed ? t("lot_customs_cleared") : t("lot_customs_before")}</CellContentText>
      </>),
    },
    {
      title: t("table_col_conditions"),
      dataIndex: 'conditions',
      key: 'conditions',
      render: (v:string, vehicle: Vehicle) => (
        <ConditionColumnWrapper vehicle={vehicle} />
      )
    },
    {
      title: t("table_col_location"),
      dataIndex: 'locationId',
      key: 'locationId',
      render: (locationId: number, vehicle: Vehicle) => <CellContentText fw={400}>
        <LocationForTable locationId={locationId} vehicle={vehicle} />
      </CellContentText>,
    },
    {
      title: t("price"),
      dataIndex: 'price',
      key: 'price',
      render: (currentBid: number, vehicle: Vehicle) => (
        <PriceCellWrapper>
          {vehicle.buyNowPrice && <Price price={vehicle.buyNowPrice} />}
          <AdvanceSearchButton onClick={() => handleOpenLot(vehicle)} text={t('lot_bid_now')} />
        </PriceCellWrapper>
      ),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pg: any, fltr: any, sort: any) => {
    pg.current !== 1 ? params.set('page', pg.current) : params.delete('page');
    pg.pageSize !== DEFAULT_PAGE_COUNT ? params.set('itemscount', pg.pageSize) : params.delete('itemscount');
    sort.field ? params.set('sortField', sort.field) : params.delete('sortField');
    sort.order ? params.set('sortOrder', (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) || 'Desc') : params.delete('sortOrder');

    history.push({
      pathname: history.location.pathname,
      search: params.toString(),
    });
  };

  const handleChangePagination = (page: number, pageSize?: number | undefined) => {
    page !== 1 ? params.set('page', page.toString()) : params.delete('page');
    pageSize !== DEFAULT_PAGE_COUNT && pageSize ? params.set('itemscount', pageSize.toString()) : params.delete('itemscount');

    history.push({
      pathname: history.location.pathname,
      search: params.toString(),
    });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };


  return (
    <CustomTable
      data={findVehicles.map((item, index) => ({
        ...item,
        key: item.id,
        index,
      }))}
      columns={columns}
      totalCount={totalCount}
      currentPage={getParamValue('page') ? +(getParamValue('page') as string) : 1}
      pageSize={getParamValue('itemscount') ? +(getParamValue('itemscount') as string) : DEFAULT_PAGE_COUNT}
      sortField={location.pathname.split('/')[1] === 'auctionsalelist' ? 'lotNumber' : ''}
      sortOrder={location.pathname.split('/')[1] === 'auctionsalelist' ? 'Asc' : 'Desc'}
      isLoading={isLoading}
      onChangeTable={handleTableChange}
      onPaginationChange={handleChangePagination}
      // locale={{
      //   emptyText: ReactHtmlParser(permanentBanners.find((item) => item.title === 'searchClaNotFoundLot')?.body || ''),
      // }}
      scroll={{ x: 'max-content' }}
      filter={true}
    />
  );
};

export default memo(SearchTable);
