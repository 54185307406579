import styled from "styled-components";

export const ElectricCarsWrapper = styled.div`
    max-width: 90rem;
    width: 100%;
      margin-right: auto;
  margin-left: auto;
    padding: 2.5rem 1rem 4.5rem;
`

export const ElectricCarsList = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    @media (max-width: 1200px){
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 991px){
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 767px){
        grid-template-columns: repeat(1, 1fr);
    }
`