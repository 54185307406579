import React, { useState } from 'react';
import { Button, Input, InputWrapper, Textarea, Title, Wrapper } from './style';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { showNotification } from '../../../utils/notify';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { sendEmail } from '../../../store/slices/authSlice';
import { message } from 'antd';
import CustomPhoneInput, { CustomPhoneInputVariant } from '../CustomPhoneInput';

interface ContactFormProps {
  toEmail?: string;
}

const ContactForm = ({ toEmail }: ContactFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [formValues, setFormValues] = useState({
    fullname: '',
    email: '',
    phone: '',
    text: '',
  });

  // sendEmail
  const onSubmit = async () => {
    const requiredFields = {
      fullname: t('dealer_search_name'),
      email: t('dealer_search_email'),
      phone: t('dealer_search_phone'),
      text: t('contact_text'),
    };

    for (const field in requiredFields) {
      if (!formValues[field]) {
        showNotification('error', t('placeholder_fill'), t(requiredFields[field]));
        return;
      }
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      showNotification('error', t('mail_validation'));
      return;
    }

    setFormValues({
      fullname: '',
      email: '',
      phone: '',
      text: '',
    });

    try {
      await dispatch(
        sendEmail(
          formValues.email,
          `
        fullname: ${formValues.fullname}
        email: ${formValues.email}
        phone: ${formValues.phone}
        text: ${formValues.text}
      `,
          toEmail
        )
      );
      showNotification('success', t('dealer_contact_email'), '', 'bottomRight');
      fbq('trackCustom', 'ButtonClick', { pixelID: '1244710206489447' });
      fbq('trackCustom', 'ButtonClick', { pixelID: '949900620254293' });
    } catch (error) {
      showNotification('success', t('dealer_contact_email_error'), '', 'bottomRight');
    }
  };

  return (
    <Wrapper>
      {contextHolder}
      <Title>{parse(t('CONTACT US'))}</Title>
      <InputWrapper>
        <div className="input-group">
          <Input
            type="text"
            placeholder={t('dealer_search_name')}
            value={formValues.fullname}
            onChange={(e) => setFormValues({ ...formValues, fullname: e.target.value })}
          />
          <Input
            type="email"
            placeholder={t('dealer_search_email')}
            value={formValues.email}
            onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
          />
        </div>
        <div className="input-group">
          <CustomPhoneInput
            value={formValues.phone}
            country={'ge'}
            placeholder={t('dealer_search_phone')}
            variant={CustomPhoneInputVariant.CONTACT}
            onChange={(e) => setFormValues({ ...formValues, phone: e })}
          />
        </div>
        <Textarea
          placeholder={t('dealer_search_type_text')}
          value={formValues.text}
          onChange={(e) => setFormValues({ ...formValues, text: e.target.value })}
        ></Textarea>
      </InputWrapper>
      <Button type="button" onClick={onSubmit}>
        <ReactSVG src="/icons/send.svg" className="svg-wrapper" />
        {t('dealer_search_submit')}
      </Button>
    </Wrapper>
  );
};

export default ContactForm;
