import React from 'react'
import { LogoWrapper } from './style'
import { langPrefix } from '../../../../utils/functions'

const Logo = () => {
  return (
    <LogoWrapper to={langPrefix('')}>
        <img alt="Lion Auto Auction" src="img/logo/lionauction_header.webp?v=1" />
    </LogoWrapper>
  )
}

export default Logo