import styled from 'styled-components';

export const SliderWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  & .slick-arrow {
    display: none !important;
  }
`;

export const SliderItemWrapper = styled.div`
  color: #fff;
  background: #050505;
  height: auto;
  max-height: calc(100vh - 25rem);
  min-height: 35rem;
  width: 100%;
  aspect-ratio: 2.7;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    right: 0; 
    top: 0;
    width: 100%;
    height: 100%;
    object-position: right;
    object-fit: contain;
    z-index: 1;
  }
  /* &::after{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.25)
  } */
  @media(max-width: 1200px){
    video{
      margin-top: -50px;
    }
  }
  @media(max-width: 991px){
    aspect-ratio: unset;
    margin-bottom: -10px;
    video{
      margin-top: 0px;
    }
  }
  @media(max-width: 767px){
    min-height: 40rem;
    max-height: max-content;
    padding-bottom: 5rem;
    img{
      width: auto;
      height: 60%;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }
`;

export const SliderItemContent = styled.div`
  max-width: 75rem;
  padding: 0 2.5rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 767px){
    position: relative;
    padding: 0 1.25rem;
    flex-direction: column;
    transform: translate(0, 0);
    top: 0;
    left: 0;
    text-align: center;
    max-width: 100%;
  }
`

export const SliderItemTitle = styled.h1`
  font-family: 'main';
  &:lang(ka){
    font-family: 'arial-caps';
  }
  font-weight: 700;
  font-size: 3.25rem;
  line-height: 4rem;
  color: #FFFFFF;
  span{
    color: #D71A09;
  }
  @media(max-width: 1440px){
    font-size: 2.5rem;
    line-height: 3rem;
  }
  @media(max-width: 767px){
    font-size: 2rem;
    margin-top: 50px;
    line-height: 2.5rem;
  }
` 

export const SliderItemDesc = styled.p`
  font-family: 'main';
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #FFFFFF;
  max-width: 36.5rem;
  @media(max-width: 1440px){
    font-size: 0.875rem;
    line-height: 1.25rem;
    max-width: 25rem;
  }
  @media(max-width: 767px){
    text-align: center;
    max-width: 100%;
  }
`

export const CalculatorWrapper = styled.div`
  max-width: 25.5625rem;
  width: 100%;
`