import styled from "styled-components";

export const OddometerTogglerWrapper = styled.div`
    padding: 0.375rem;
    width: 3.25rem;
    background: #E0E0E0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    span{
        font-family: 'main';
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 1.125rem;
        color: #1B153D;
    }
    .button-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: auto;
        button{
            width: 10px;
            height: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            &:nth-child(1) svg{
                transform: rotate(90deg);
            }
            &:nth-child(2) svg{
                transform: rotate(-90deg);
            }
        }
        .svg-wrapper{
            & > div{
                width: 10px;
                height: 10px;
            }
            svg{
                width: 100%;
                height: 100%;
            }
            path{
                stroke: #1B153D;
            }
        }
    }
`