import { Col, Row, Modal, Table, Space, Typography } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import dayjs from 'dayjs';
import { MyLotItem, Note, ServiceOrder, BidHistory, Documents } from '../../../interfaces/Product';
import DateForTable from '../DateForTable';
import Price from '../Price';

const { Text } = Typography;

interface Props {
  title: string;
  lot: MyLotItem;
  isOpen: boolean;
  onCancel: () => void;
}

const LotModal: FC<Props> = ({ title, lot, isOpen, onCancel }) => {
  const handleGetTableData = useCallback(() => {
    if (title === 'Notes') return lot.notes;
    if (title === 'Bid History') return lot.bidHistory;
    if (title === 'Documents') return lot.documents;
    if (title === 'Service Order') return lot.serviceOrder;
    return [];
  }, [title, lot]);

  const handleGetTableColumns = useCallback(() => {
    if (title === 'Notes')
      return [
        {
          title: 'Created Date',
          dataIndex: 'createdDate',
          key: 'createdDate',
          render: (createdDate: string) => (createdDate ? <DateForTable date={createdDate} /> : ''),
        },
        {
          title: 'Note Text',
          dataIndex: 'noteText',
          key: 'noteText',
        },
      ];
    if (title === 'Bid History')
      return [
        {
          title: 'Bid Date',
          dataIndex: 'bidDate',
          key: 'bidDate',
          render: (bidDate: string) => (bidDate ? <DateForTable date={bidDate} /> : ''),
        },
        {
          title: 'Bid Amount',
          dataIndex: 'bidAmount',
          key: 'bidAmount',
          render: (bidAmount: number) => <Price price={bidAmount} />,
        },
        {
          title: 'Bid Type',
          dataIndex: 'bidType',
          key: 'bidType',
        },
        {
          title: 'Lane',
          dataIndex: 'lane',
          key: 'lane',
        },
        {
          title: 'Auction',
          dataIndex: 'auction',
          key: 'auction',
        },
      ];
    if (title === 'Documents')
      return [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
      ];
    if (title === 'Service Order')
      return [
        {
          title: 'Service',
          dataIndex: 'service',
          key: 'service',
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: 'Requested Date',
          dataIndex: 'requestedDate',
          key: 'requestedDate',
          render: (requestedDate: string) => {
            if (requestedDate) {
              const date = dayjs(requestedDate).format('DD/MM/YYYY');
              const time = dayjs(requestedDate).format('HH:mm A');
              return (
                <Space direction="vertical">
                  <Text>{date}</Text>
                  <Text>{time}</Text>
                </Space>
              );
            }
            return '';
          },
        },
      ];
    return [];
  }, [title]);

  return (
    <Modal title={title} visible={!!isOpen} centered onCancel={onCancel} width="100%">
      <Row gutter={[20, 20]} justify="center">
        <Col xs={12}>
          <img width="50%" alt={lot?.mainImage} src={lot?.mainImage} />
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12}> Lot#</Col>
            <Col xs={12}>{lot.id}</Col>
          </Row>
          <Row>
            <Col xs={12}> Claim#</Col>
            <Col xs={12}>{lot.claimNumber}</Col>
          </Row>
          <Row>
            <Col xs={12}> Status</Col>
            <Col xs={12}>{lot.status}</Col>
          </Row>
          <Row>
            <Col xs={12}> Description</Col>
            <Col xs={12}>{lot.description}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Table dataSource={handleGetTableData() as (Note | ServiceOrder | BidHistory | Documents)[] | []} columns={handleGetTableColumns()} />
      </Row>
    </Modal>
  );
};
export default memo(LotModal);
