import React from 'react'
import { HistoryCardContent, HistoryCardDate, HistoryCardImgWrapper, HistoryCardTextWrapper, HistoryCardWrapper } from './style'

interface HistoryCardProps {
    item: {
        title: string;
        desc: string;
        year: string | number,
        image: string
    },
    reversed: boolean
}

const HistoryCard = ({reversed, item}: HistoryCardProps) => {
    return (
        <HistoryCardWrapper className={reversed ? "reversed" : ""}>
            <HistoryCardDate>{item.year}</HistoryCardDate>
            <HistoryCardContent>
                <HistoryCardImgWrapper>
                    <img src={item.image} alt="" />
                </HistoryCardImgWrapper>
                <HistoryCardTextWrapper>
                    <h2>{item.title}</h2>
                    <p>{item.desc}</p>
                </HistoryCardTextWrapper>
            </HistoryCardContent>
        </HistoryCardWrapper>
    )
}

export default HistoryCard