import styled, { css } from 'styled-components';
import { Divider, Row, Select, Menu, Button, Switch, Col, Typography, Badge } from 'antd';
import { Link } from 'react-router-dom';
import { White, Grey, Primary, LightGrey, DarkPrimary, LightShadow } from '../../shared-styles';

const { SubMenu } = Menu;
const { Text } = Typography;

export const FirstRowHeader = styled(Row)`
  color: ${LightGrey};
  padding: 0 50px;
  border-bottom: 1px solid ${DarkPrimary};
  @media (max-width: 1192px) {
    padding: 0 10px;
  }
`;

export const RightCol = styled(Col)`
  align-self: flex-end;
`;

export const SecondRowHeader = styled(Row)`
  align-items: center;
  line-height: 24px;
  width: 100%;
  .leftJustify {
    justify-content: flex-end;
  }
`;

export const HeaderMobile = styled(Row)`
  padding: 10px;
  @media (max-width: 320px) {
    padding: 4px;
  }
`;

export const StyledSelect = styled(Select)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    background: ${LightShadow};
    border: none;
    min-width: 96px;
    color: ${LightGrey};
  }
  & .ant-select-arrow {
    color: ${LightGrey};
  }
`;

export const StyledSelectFooter = styled(StyledSelect)`
  width: 100%;
  margin-bottom: 16px;
`;

export const StyledContactLink = styled.a`
  color: ${Primary};
  padding-right: 4px;
  font-size: 14px;
  @media (min-width: 576px) {
    color: ${LightGrey};
    font-size: 14px;
  }
`;

export const SettingsContactLink = styled(Button)`
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
  border: none;
  color: #afb7bf;
`;

export const SMedia = styled.a`
  color: ${Primary};
  padding-right: 4px;
  font-size: 20px;
`;

export const StyledDivivder = styled(Divider)`
  border-left: 1px solid ${LightGrey};
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${LightGrey};
  flex-wrap: wrap;
`;

export const BtnsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 64px;
  padding: 12px 0 12px 20px;
`;

export const StyledMenu = styled(MenuContainer)`
  padding: 10px 50px;
  background-color: ${Primary};
  @media (min-width: 1024px) {
    z-index: 1000;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
  }
  @media (max-width: 1192px) {
    padding: 0 10px;
  }
`;

export const SearchContainer = styled.div`
  max-width: 550px;
  width: 100%;
  height: 64px;
  padding: 12px 0 12px 28px;
`;

export const AccountMenuBtn = styled(Button)`
  width: 100%;
  text-align: left;
  color: ${Grey};
  &:hover {
    color: ${Grey};
  }
`;

export const AccountContainer = styled.div`
  line-height: 1rem;
  padding: 0 4px;
`;

export const AccountName = styled(AccountContainer)`
  line-height: 1rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0;
`;

const HoverMenuItem = css`
  &:hover:before {
    bottom: -3px;
    opacity: 1;
    visibility: visible;
  }
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -2px;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: ${White};
    transition: all 0.3s ease-in-out;
    bottom: -30px;
    opacity: 0;
    visibility: hidden;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  ${(p) => HoverMenuItem}
`;

export const StyledSubMenu = styled(SubMenu)`
  ${(p) => HoverMenuItem}
  direction: rtl;
  .anticon-caret-down {
    padding-left: 8px;
  }
`;

export const LiveAuctionTag = styled(Badge.Ribbon)`
  display: ${(p: { isAuctionLive: boolean }) => (p.isAuctionLive ? 'block' : 'none')};
  top: -8px;
  right: 8px !important;
  padding-left: 20px;
  .ant-ribbon-text:before {
    position: absolute;
    top: 6px;
    left: 6px;
    content: ' ';
    padding: 5px;
    background: ${Primary};
    animation: blinker 1s linear infinite;
    clip-path: circle(35%);
    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }
`;

export const MegaMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  max-width: 1100px;
  .videoImg {
    cursor: pointer;
  }
`;

export const WoNavad = styled.div`
  margin-left: 30px;
`;

export const VideoText = styled(Text)`
  padding: 0px;
  line-height: 16px;
`;

export const FindVehicleMenuLink = styled(Link)`
  line-height: 28px;
  color: ${Grey};
`;

export const FindVehicleMenuTitle = styled.h4`
  font-size: 1rem;
  line-height: 1.125em;
  margin-bottom: 0;
  margin: 0 0 8px;
  color: ${Primary};
  font-weight: 700;
  padding: 0 16px;
`;

export const RoleSwitch = styled(Switch)`
  margin-left: 12px;
`;
