import React from 'react';
import CarTypeCard from './CarTypeCard';
import { CarTypesList, Section, SectionTitle } from './style';
import { useTranslation } from 'react-i18next';
import { langPrefix } from '../../utils/functions';

const data = [
  {
    title: "car-types-electric",
    image: "/img/car-types/electric-cars.webp",
    link: "/electric-cars-search"
  },
  // {
  //   title: "car-types-transit",
  //   image: "/img/car-types/cars-in-transit.webp",
  //   link: null
  // },
  {
    title: "car-types-tserovani",
    image: "/img/car-types/tserovani-yard.webp",
    link: "/findvehicles?auctiontype=InYard"
  },
  {
    title: "car-types-usa",
    image: "/img/car-types/cars-from-usa.webp",
    link: "/findvehicles?auctiontype=InTransit"
  },
]

const CarTypesSection = () => {

  const { t } = useTranslation()

  return (
    <Section>
      <SectionTitle>{t("car-types")}</SectionTitle>
      <CarTypesList>
        {data.map((item) => (
          <CarTypeCard image={item.image} title={item.title} key={item.title} link={langPrefix(item.link)}/>
        ))}
      </CarTypesList>
    </Section>
  );
};

export default CarTypesSection;
