/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { axiosInstance } from '../../core/axios';
import api from '../../core/api';
import { SearchDashboardFilterItem } from '../../interfaces/Dashboard';
import { showNotification } from '../../utils/notify';

export interface InitState {
  searchDashboardFilter: SearchDashboardFilterItem[];
  searchDashboardFilterLoading: boolean;
}

export const initialState: InitState = {
  searchDashboardFilter: [],
  searchDashboardFilterLoading: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    searchDashboardFilterRequest: (state) => {
      state.searchDashboardFilterLoading = true;
    },
    searchDashboardFilterSuccess: (state, action) => {
      state.searchDashboardFilter = action.payload;
      state.searchDashboardFilterLoading = false;
    },
    searchDashboardFilterFailure: (state) => {
      state.searchDashboardFilterLoading = false;
    },
  },
});

export const { searchDashboardFilterRequest, searchDashboardFilterSuccess, searchDashboardFilterFailure } = dashboardSlice.actions;

export const getSearchDashboardFilter = (): AppThunk => async (dispatch, state) => {
  try {
    dispatch(searchDashboardFilterRequest());
    const res = await axiosInstance.get(api.GetSearchDashboardFilter);
    dispatch(searchDashboardFilterSuccess(res.data));
  } catch (error: any) {
    dispatch(searchDashboardFilterFailure());
    // state().auth.user && showNotification('error', error.response.data.errorMessage);
  }
};

export const deleteDashboardFilter = (id: number): AppThunk => async (dispatch, state) => {
  try {
    await axiosInstance.post(api.DeleteDashboardFilter, null, {
      params: {
        id,
      },
    });
    dispatch(searchDashboardFilterSuccess(state().dashboard.searchDashboardFilter.filter((item) => item.id !== id)));
    showNotification('success', 'Deleted');
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const createNewSearchDashboardFilter = (filter: string, templateName: string): AppThunk => async () => {
  try {
    await axiosInstance.post(api.CreateNewSearchDashboardFilter, {
      filter,
      templateName,
    });
    showNotification('success', 'Saved');
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

// SELECTORS
export const searchDashboardFilterSelector = (state: RootState) => state.dashboard.searchDashboardFilter;
export const searchDashboardFilterLoadingSelector = (state: RootState) => state.dashboard.searchDashboardFilterLoading;
export default dashboardSlice.reducer;
