import { createUserManager } from 'redux-oidc';
import Oidc from 'oidc-client';

export const settings = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: 'LionTransJsClientID',

  redirect_uri: `${process.env.REACT_APP_IDENTITY}/callback`,
  post_logout_redirect_uri: `${process.env.REACT_APP_IDENTITY}/logout`,

  automaticSilentRenew: true,
  loadUserInfo: true,
  scope: 'openid profile web_api identity_web_api auction_api',
  response_type: 'id_token token',

  silent_redirect_uri: `${process.env.REACT_APP_IDENTITY}/`,
  accessTokenExpiringNotificationTime: 10800,
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
};

const userManager = createUserManager(settings);

export default userManager;
