import React from 'react'
import { Container, Description, DescriptionSectionWrapper, HistoryWallWrapper } from './style'
import HistoryWall from '../../shared/components/HistoryWall'
import { useTranslation } from 'react-i18next'

const DescriptionSection = () => {
    const { t } = useTranslation();

  return (
    <DescriptionSectionWrapper>
        <Container>
            <Description>{t('About_us_desc_1')}</Description>

            <Description>{t('About_us_desc_2')}</Description>

            <Description>{t('About_us_desc_3')}</Description>

        </Container>

        <HistoryWallWrapper>
            <HistoryWall />
        </HistoryWallWrapper>

        <Container>
            <Description>{t('About_us_desc_4')}</Description>
            
            <Description>
                <b>{t('About_us_desc_5_Title')}</b>  {t('About_us_desc_5')}
            </Description>

            <Description>
              <b>{t('About_us_desc_6_Title')}</b>  {t('About_us_desc_6')}
            </Description>
        </Container>
     </DescriptionSectionWrapper>
  )
}

export default DescriptionSection