import React, { useState } from 'react';
import DealerSearch from './DealerSearch';
import PersonalSearch from './PersonalSearch';
import { Header, HeaderButton, HeaderButtonVariant, MainSearchWrapper, SearchWrapper } from './style';
import { useTranslation } from 'react-i18next';

const RenderSelectedSearch = [
  {
    id: 1,
    key: "main_search_vehicles",
    component: <PersonalSearch />
  },
  {
    id: 2,
    key: "main_search_dealer",
    component: <DealerSearch />
  }
]

const MainSearch = () => {
  const [selectedSearch, setSelectedSearch] = useState(1);
  const { t } = useTranslation()

  return (
    <MainSearchWrapper>
      <Header>
        {RenderSelectedSearch.map((item) => (
          <HeaderButton
            key={item.id}
            variant={selectedSearch !== item.id ? HeaderButtonVariant.ACTIVE : HeaderButtonVariant.PRIMARY}
            onClick={() => setSelectedSearch(item.id)}
          >
            {t(item.key)}
          </HeaderButton>
        ))}
      </Header>
      <SearchWrapper>{RenderSelectedSearch.find(el => el.id == selectedSearch)?.component}</SearchWrapper>
    </MainSearchWrapper>
  );
};

export default MainSearch;
