import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { SecondaryNavWrapper } from './style';
import { langPrefix } from '../../../utils/functions';

const SecondaryNavigation = () => {
  const { t } = useTranslation();
  
  return (
    <SecondaryNavWrapper>
        <Link to={langPrefix('/help')}>{t('nav_main_menu_help')}</Link>
        <Link to={langPrefix('/blog')}>{t('nav_main_menu_blog')}</Link>
    </SecondaryNavWrapper>
  )
}

export default SecondaryNavigation