import { Form, Row, Steps, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { FC, memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import VehicleInformationForm from './VehicleInformationForm';
import OwnerInfromationForm from './OwnerInfromationForm';
import BidInformationForm from './BidInformationForm';
import FinishAddVehicleView from './FinishAddVehicleView';
import { AddVehicleItemForm, AddVehicleItemFormData, AddVehicleItemToSend } from '../../interfaces/Product';
import { createNewProduct } from '../../store/slices/productSlice';
import { ScSteps, ScColSteps, AddVehicleWrapper, Container, ContainerTitle, AddVehiclePageTitle, SubmitButton } from './style';
import ContainerInfo from './ContainerInfo';
import PhotosForm from './PhotosForm';
import UploadFiles from './UploadFiles';
import { showNotification } from '../../utils/notify';
import i18n from '../../i18n';

const { Step } = Steps;

const AddVehiclePage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [dialCode, setDialCode] = useState('995');
  const [formData, setFormData] = useState<any>({});
  const [isDisabled, setIsDisabled] = useState(true);

  const nextPrev = useCallback(
    (n: number) => {
      setCurrentStep(currentStep + n);
      window.scrollTo(0, 0);
    },
    [currentStep],
  );

  const handleChangeStep = useCallback((current: number) => {
    setCurrentStep(current);
  }, []);

  const saveFormData = useCallback(
    (step: string, values: any) => {
      setFormData({
        ...formData,
        [step]: values,
      });
    },
    [formData],
  );

  const onFinish = useCallback(
    (values: any) => {
      const ownerInformation = {
        owner: values.owner,
        ownerMobilePhoneNumber: values.ownerMobilePhoneNumber.replace(dialCode, ''),
        dialCode,
      }

      const vehicleInfomation = {
        ...values,
        transmissionTypeId: values.transmission,
        engineTypeId: values.engine,
        fuelTypeId: values.fuel,
        driveTrainTypeId: values.drive,
        bodyStyleTypeId: values.bodyStyle,
        cylinderTypeId: values.cylinders,
      }

      const bidInformation = {
        hasBuyNow: true,
        productSellModel: 2,
        buyNowPrice: values.buyNowPrice,
        comments: values.comments,
      }
      if(dialCode == '995') {
        if(values.ownerMobilePhoneNumber.replace(dialCode, '').length !== 9) {
          return showNotification('error', i18n.t('invalid_phone_format'));
        }
      }
      const deletedKeys = ["owner", "ownerMobilePhoneNumber", "dialCode", "buyNowPrice", "comments"]

      deletedKeys.forEach(key => {
        delete vehicleInfomation[key]
      })

      setFormData({
        "vehicleInformation": vehicleInfomation,
        "ownerInformation": ownerInformation,
        "bidInformation": bidInformation,
        "images": formData.images,
      });

      handleCreateNewProduct({
        "vehicleInformation": vehicleInfomation,
        "ownerInformation": ownerInformation,
        "bidInformation": bidInformation,
        "images": formData.images,
      })
    },
    [saveFormData, nextPrev, dialCode],
  );


  const handleCreateNewProduct = useCallback((formData) => {
    const { transmission, engine, fuel, drive, bodyStyle, cylinders, ...vehicleInformation } = formData.vehicleInformation;

    dispatch(
      createNewProduct({
        ...vehicleInformation,
        ...formData.ownerInformation,
        ...formData.bidInformation,
        ...formData.images,
        transmissionTypeId: transmission,
        engineTypeId: engine,
        fuelTypeId: fuel,
        driveTrainTypeId: drive,
        bodyStyleTypeId: bodyStyle,
        cylinderTypeId: cylinders,
      }),
    );
  }, [dispatch, formData]);


  const onChange = useCallback(
    (value) => {
      if (value[0]?.name[0] === 'make') {
        form.setFieldsValue({
          model: undefined,
        });
      }

      if (
        form.isFieldsTouched(['vin', 'make', 'year', 'engine', 'fuel', 'drive', 'cylinders', 'transmission', 'bodyStyle'], true) &&
        !form.getFieldsError().filter(({ errors }) => errors.length).length
      ) {
        setIsDisabled(false);
      }
    },
    [form],
  );

  return (
    <AddVehicleWrapper>
      <Form
        form={form}
        name="vehicleInformation"
        onFinish={onFinish}
        scrollToFirstError
        onFieldsChange={onChange}
        initialValues={{
          vehicleType: 2,
          make: undefined,
        }}
      >
      <Container>
        <AddVehiclePageTitle>{t("addvehicle_title")}</AddVehiclePageTitle>

        <ContainerTitle>{t('lot_vehicle_information')}</ContainerTitle>
        <VehicleInformationForm nextPrev={nextPrev} saveFormData={saveFormData} data={formData.vehicleInformation} form={form}/>

        <ContainerTitle>{t('lot_owner_infromation')}</ContainerTitle>
        <OwnerInfromationForm nextPrev={nextPrev} saveFormData={saveFormData} data={formData.ownerInformation} form={form} dialCode={dialCode} setDialCode={setDialCode}/>

        {/* <BidInformationForm nextPrev={nextPrev} saveFormData={saveFormData} data={formData.bidInformation} /> */}
        <UploadFiles form={form} saveFormData={saveFormData} data={formData.images}/> 
        {/* <PhotosForm nextPrev={nextPrev} saveFormData={saveFormData} data={formData.photos} /> */}
        <Row>
          <SubmitButton type="primary" htmlType="submit">{t("Submit")}</SubmitButton>
        </Row>
      </Container>
      {/* <FinishAddVehicleView nextPrev={nextPrev} formData={formData} changeStep={handleChangeStep} createNewProduct={handleCreateNewProduct} /> */}
    </Form>
    </AddVehicleWrapper>
  );
};

export default memo(AddVehiclePage);
