import { Button, Form, Input, Row, Space, Typography } from 'antd';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { showNotification } from '../../utils/notify';
import { useTranslation } from 'react-i18next';
import { ForgotPassowrd, FormItem } from './style';
import { useCookies } from 'react-cookie';

const { Title, Text } = Typography;

const ForgotPasswordPage: FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [cookies] = useCookies(['language']);
  const { t } = useTranslation();

  const canonicalTag = document.querySelector('link[rel="canonical"]');
  if (canonicalTag) {
    canonicalTag.parentNode?.removeChild(canonicalTag);
  }

  const metaTag = document.querySelector('meta[name="robots"]');
  if (metaTag) {
    metaTag.setAttribute('content', 'follow, noindex, max-snippet:-1, max-video-preview:-1, max-image-preview:large');
  }

  const onFinish = async (values: { login: string }) => {
    try {
      await axios.post(`${process.env.REACT_APP_AUTHORITY}/api/User/ForgotPassword?login=${values.login}&language=${cookies.language}`);
      localStorage.setItem("needRedirect", 'true');
      showNotification('success', t('fw_mailtext'));
      history.push('/');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showNotification('error', error.response.data.errorMessage);
    }
  };

  const handleCancel = useCallback(() => {
    history.push('/signin');
  }, [history]);

  return (
    <Row gutter={[20, 20]} justify="center">
      <ForgotPassowrd>
        <Form form={form} name="forgotpassword" onFinish={onFinish} scrollToFirstError>
          <Space direction="vertical">
            <Title level={2}>{t('fw_title')}</Title>
            <Text>{t('fw_info')}</Text>
            <Form.Item name="login">
              <Input placeholder={t('fw_member')} />
            </Form.Item>
            <Row justify="end">
              <FormItem>
                <Button type="default" onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit">
                  {t('Submit')}
                </Button>
              </FormItem>
            </Row>
          </Space>
        </Form>
      </ForgotPassowrd>
    </Row>
  );
};

export default memo(ForgotPasswordPage);
