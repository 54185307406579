import React from 'react'
import { TransSectionWrapper, SectionContainer, SectionTitle, ServiceCardList, ServiceCardListTrans } from './style'
import ServiceCard, { ServiceCardVariant, ServiceModal } from './components/ServiceCard'
import { useTranslation } from 'react-i18next'
const services = require("./TransData.json")

const TransSection = () => {
  const {t} = useTranslation()

  return (
    <TransSectionWrapper>
        <SectionContainer>
            <SectionTitle>{t('services_liontrans_title')}</SectionTitle>
            <ServiceCardListTrans>
                {services.map( (service: ServiceModal, index: number) => (
                    <ServiceCard 
                      variant={ServiceCardVariant.DARK}
                      service={service} 
                      key={index}
                    />
                ))}
            </ServiceCardListTrans>
        </SectionContainer>
    </TransSectionWrapper>
  )
}

export default TransSection