import styled, { css } from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { CustomPhoneInputProps, CustomPhoneInputVariant } from '.';

export const StyledInput = styled(PhoneInput)<CustomPhoneInputProps>`
  ${(p) => p.variant === CustomPhoneInputVariant.PRIMARY && css``}

  ${(p) =>
    p.variant === CustomPhoneInputVariant.CONTACT &&
    css`
      height: 3rem;
      margin-bottom: 1.25rem;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      color: #1b153d;
      padding: 0.75rem 1rem;

      background: #ffffff;
      border: 1px solid #d1d5d6;
      box-shadow: 0px 4px 30px #efefef;
      border-radius: 8px;

      & input {
        border: none !important;
        outline: none !important;
        height: auto !important;
      }
      & .flag-dropdown {
        border: none;
        background-color: #fff;
      }
    `}

    ${(p) =>
    p.variant == CustomPhoneInputVariant.SECONDARY &&
    css`
      border: none;
      border-bottom: 2px solid #a2a2a2;
      font-family: 'main';
      &:lang(ka) {
        font-family: 'arial-caps';
      }
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #1b153d;
      transition: 0.2s;
      padding: 0.5rem 0;
      &::placeholder {
        font-family: 'main';
        &:lang(ka) {
          font-family: 'arial-caps';
        }
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        color: #a5aaac;
      }
      &:focus {
        border-color: #1b153d;
        outline: none;
      }
      &:not(:last-child) {
        margin-bottom: 2.5rem;
      }
      & input {
        border: none !important;
        outline: none;
      }
      & .flag-dropdown {
        border: none;
        background-color: #fff;
      }
      @media (max-width: 991px) {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
      @media (max-width: 767px) {
        font-size: 0.75rem;
        line-height: 1rem;
      }
    `}

    ${(p) =>
    p.variant === CustomPhoneInputVariant.TERTIARY &&
    css`
      border-radius: 0.25rem;
      border: 1px solid #a5aaac;
      background: #fff;
      height: 3rem;
      margin-bottom: 1.25rem;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      color: #1b153d;
      padding: 0.75rem 1rem;

      & input {
        border: none !important;
        outline: none !important;
        height: auto !important;
      }
      & .flag-dropdown {
        border: none;
        background-color: #fff;
      }
    `}
`;
