import React from 'react'
import { TerminalPotiSectionWrapper, SectionContainer, SectionTitle, ServiceCardList } from './style'
import ServiceCard, { ServiceCardVariant, ServiceModal } from './components/ServiceCard'
import { useTranslation } from 'react-i18next'
const services = require("./TerminalPoti.json")

const TerminalPotiSection = () => {
  const {t} = useTranslation()
  return (
    <TerminalPotiSectionWrapper>
        <SectionContainer>
            <SectionTitle>{t('services_teminal_poti_title')}</SectionTitle>
            <ServiceCardList>
                {services.map( (service: ServiceModal, index: number) => (
                    <ServiceCard variant={ServiceCardVariant.DARK_DESKTOP} service={service} key={index}/>
                ))}
            </ServiceCardList>
        </SectionContainer>
    </TerminalPotiSectionWrapper>
  )
}

export default TerminalPotiSection