import React from 'react'
import { SellerCardWrapper, SellerContent, SellerPhoto } from './style'
import { useTranslation } from 'react-i18next'
import { langPrefix } from '../../../utils/functions'

const SellerCard = ({seller}: any) => {
  const {t} = useTranslation()
  return (
    <SellerCardWrapper to={langPrefix(`/seller-profile/${seller.id}`)}>
        <SellerPhoto>
            <img src={seller.img} alt="" />
        </SellerPhoto>
        <SellerContent>
            <h3>{t(seller.name)}</h3>
            <span>{seller.phone}</span>
            <span>{seller.email}</span>
            <button>{t("Contact")}</button>
        </SellerContent>
    </SellerCardWrapper>
  )
}

export default SellerCard