import { Form, Input, Row, Button, Select, Space, Table, Col, Tag, Spin } from 'antd';
import React, { FC, memo, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import Lightbox from 'react-image-lightbox';
import { DeleteUserLicenseFile } from '../../store/slices/profileSlice';
import 'react-image-lightbox/style.css';

import {
  getBidderList,
  getUserLanguageDictionary,
  getAccountSettings,
  accountSettingsSelector,
  userLanguageDictionarySelector,
  changeLanguage,
  changePassword,
  addNewLicenseFile,
  uploadFileSelector,
} from '../../store/slices/profileSlice';
import TableFooter from '../../shared/components/TableFooter';
import { CardWrapper, CustTable } from './style';
import { ScTitle } from '../style';
import { abbrToLangMapping } from '../../config/language';
import { userLanguageSelector } from '../../store/slices/authSlice';
import ProfilePage from '.';
import { ReactSVG } from 'react-svg';

const layoutForm = {
  labelCol: { span: 7 },
  wrapperCol: { span: 19 },
};

const layout = {
  xs: 24,
  lg: 24,
};

const { Option } = Select;

const AccountSettings: FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['language']);

  useEffect(() => {
    dispatch(getBidderList());
    dispatch(getUserLanguageDictionary());
    dispatch(getAccountSettings());
  }, [dispatch]);

  const userLanguageDictionary = useSelector(userLanguageDictionarySelector);
  const accountSettings = useSelector(accountSettingsSelector);
  const uploadFile = useSelector(uploadFileSelector);
  const userLanguage = useSelector(userLanguageSelector);

  const [languageId, setLanguageId] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState<string[]>([]);
  const [data, setData] = useState(accountSettings?.userLicenseFiles || []);

  useEffect(() => {
    if (userLanguage) {
      const langId = abbrToLangMapping[userLanguage]?.id;
      setLanguageId(langId);
    }
  }, [userLanguage]);

  useEffect(() => {
    const langId = abbrToLangMapping[cookies.language].id;
    setLanguageId(langId);
  }, [cookies.language]);

  useEffect(() => {
    if (accountSettings?.userLicenseFiles) {
      const imgUrls = accountSettings.userLicenseFiles.map((item) => `data:image/png;base64,${item.imgToBase64}`);
      setImages(imgUrls);
      setData(accountSettings.userLicenseFiles);
    }
  }, [accountSettings]);

  const onFinish = useCallback(
    async (values: { currentPassword: string; newPassword: string }) => {
      dispatch(changePassword(values.currentPassword, values.newPassword));
    },
    [dispatch]
  );

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleSubmitChangeLanguage = useCallback(() => {
    languageId && dispatch(changeLanguage(languageId));
    const lang = Object.keys(abbrToLangMapping).find((key) => abbrToLangMapping[key].id === languageId);
    setCookie('language', lang, { path: '/' });
  }, [dispatch, languageId, setCookie]);

  const handleClearPassword = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleDelete = useCallback(
    async (licenseId: number) => {
      try {
        await dispatch(DeleteUserLicenseFile(licenseId));
        setData((prevData) => prevData.filter((item) => item.id !== licenseId));
      } catch (error: any) {
        console.error('Failed to delete license file', error);
      }
    },
    [dispatch]
  );

  return (
    <ProfilePage>
      <Form form={form} name="accountSettings" onFinish={onFinish} scrollToFirstError {...layoutForm}>
        <CardWrapper>
          {languageId ? (
            <Row justify="space-between">
              <Col {...layout}>
                <ScTitle level={4}>{t('nav_user_menu_account_account_settings')}</ScTitle>
                <Form.Item label={t('nav_user_menu_account_website_lang')} colon>
                  <Select onChange={setLanguageId} value={languageId}>
                    {userLanguageDictionary.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col {...layout} className="alignRight">
                <Button type="primary" onClick={handleSubmitChangeLanguage}>
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          ) : (
            <Row justify="center">
              <Col>
                <Spin />
              </Col>
            </Row>
          )}
        </CardWrapper>
        <CardWrapper>
          <Row justify="space-between">
            <Col {...layout}>
              <Form.Item
                label={t('user_current_password')}
                name="currentPassword"
                rules={[{ required: true, message: t('nav_user_menu_account_password_required') }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={t('user_new_password')}
                name="newPassword"
                rules={[{ required: true, message: t('nav_user_menu_account_password_required') }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={t('user_confirm_new_password')}
                name="confirmPassword"
                rules={[{ required: true, message: t('nav_user_menu_account_password_required') }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col {...layout} className="alignRight">
              <Space>
                <Button danger onClick={handleClearPassword}>
                  {t('Cancel')}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t('Save')}
                </Button>
              </Space>
            </Col>
          </Row>
        </CardWrapper>
        <Row>
          <ScTitle level={4}>{t('uploadLicense')}</ScTitle>
        </Row>
        <Form.Item valuePropName="fileList" getValueFromEvent={normFile} noStyle>
          <Col {...layout}>
            <CustTable
              loading={uploadFile}
              dataSource={data.map((item) => ({
                ...item,
                key: item.id,
              }))}
              footer={() => <TableFooter total={data.length || 0} />}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                },
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: 'Photo',
                  dataIndex: 'imgToBase64',
                  key: 'photo',
                  render: (imgToBase64: string | undefined, record:any , index:any, ) => (
                    <img
                      src={`data:image/png;base64,${imgToBase64}`}
                      alt="License"
                      style={{ width: 50, height: 50, cursor: 'pointer' }}
                      onClick={() => {
                        setIsOpen(true);
                        setPhotoIndex(index);
                      }}
                    />
                  ),
                },
                {
                  title: 'Upload Date',
                  dataIndex: 'creationTime',
                  key: 'creationTime',
                },
                {
                  title: 'Action',
                  dataIndex: 'id',
                  key: 'id',
                  render: (id: number) => (
                    <Button danger onClick={() => handleDelete(id)}>
                      <ReactSVG src='/icons/trash.svg' />
                    </Button>
                  ),
                },
              ]}
              pagination={{
                className: 'custom-pagination',
              }}
            />
          </Col>
          <Col {...layout}>
            <Dragger
              name="files"
              accept="image/png,image/jpeg,image/gif,application/pdf"
              beforeUpload={(file) =>
                new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    const fileToSend = {
                      id: null,
                      fileName: file.name,
                      originalName: file.name,
                      url: reader.result as string,
                    };
                    dispatch(addNewLicenseFile(fileToSend));
                  };
                })
              }
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('uploadLicense')}</p>
              <p className="ant-upload-hint">{t('uploadlicenseFileExt')}</p>
              <p className="ant-upload-hint">{t('uploadlicenseFileSize')}</p>
            </Dragger>
          </Col>
        </Form.Item>
      </Form>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </ProfilePage>
  );
};

export default memo(AccountSettings);
