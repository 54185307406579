import React from 'react'
import { CardContent, CardImageWrapper, CardTitleWrapper, CardWrapper } from './style'
import Price from '../../../shared/components/Price'
import { langPrefix } from '../../../utils/functions';

interface GalleryCardProps {
    item: any;
    onCardHandle?: () => void
}

const GalleryCard = ({item, onCardHandle}: GalleryCardProps) => {
  return (
    <CardWrapper to={langPrefix(`/electric-cars-detaile/${item.id}`)}>
        <CardContent>
            <CardTitleWrapper>
                <h3>{item?.title}</h3>
                <p>{item?.title}</p>
            </CardTitleWrapper>
            <Price price={item?.price} />
        </CardContent>
        <CardImageWrapper>
            <img src={item?.mainImage || ""} alt="" />
        </CardImageWrapper>
    </CardWrapper>
  )
}

export default GalleryCard