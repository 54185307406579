import styled from "styled-components";

export const DealerReviewWrapper = styled.div`
	max-width: 500px;
	width: 100%;
	margin: 2rem auto;
	padding: 0 1.5rem;
`

export const Title = styled.h1`
  font-family: 'main';
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #1b153d;
	margin: 0;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	span{
		font-size: 0.875rem;
		font-weight: 400;
		margin-left: 0.5rem;
		color: #9d9d9c;
	}
`;

export const ReviewForm = styled.form`
	margin-top: 1rem;
	.ant-rate{
		display: flex;
		justify-content: center;
	}
  textarea {
    padding: 1rem 1.125rem 4rem;
    background: #ffffff;
    border: 1px solid rgba(139, 138, 138, 0.72);
    box-shadow: inset -1px 2px 4px rgba(185, 185, 185, 0.25);
    border-radius: 0.5rem;
    font-family: 'main';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #1b153d;
    width: 100%;
		min-height: 10rem;
    aspect-ratio: 3.21;
    resize: none;
    &::placeholder {
      color: #a5aaac;
    }
  }
`

export const ReviewBtn = styled.button`
  font-family: 'main';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  display: flex;
  align-items: center;
	justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 4rem;
  background: #ce2424;
  border-radius: 0.5rem;
	width: 100%;
  .svg-wrapper {
    &,
    & > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`