import React, {useEffect} from 'react';
import { HeaderWrapper } from './style';
import SubHeader from './SubHeader';
import UpperHeader from './UpperHeader';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { changeOdometrIsKm } from '../../../store/slices/initSlice';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { getProductGeneralDictionariesByLanguage } from '../../../store/slices/dictionarySlice';
import { addToCompare, compareIdsSelector } from '../../../store/slices/compareSlice';
import { getDealers } from '../../../store/slices/dealerSlice';
import { Helmet } from 'react-helmet';

const Header = () => {
  const dispatch = useDispatch();
  const { md } = useBreakpoint();
  const [cookies, setCookie] = useCookies(['allTour', 'currency', 'language', 'branch', 'lotTour', 'searchTour', 'classicSearchTour', 'odometrIsKm']);
  const compareIds = useSelector(compareIdsSelector);

  useEffect(() => {
    cookies.allTour === undefined && setCookie('allTour', 'false', { path: '/' });
    cookies.lotTour === undefined && setCookie('lotTour', 'false', { path: '/' });
    cookies.searchTour === undefined && setCookie('searchTour', 'false', { path: '/' });
    cookies.classicSearchTour === undefined && setCookie('classicSearchTour', 'false', { path: '/' });
    cookies.language === undefined && setCookie('language', 'ka', { path: '/' });
    cookies.currency === undefined && setCookie('currency', 'usd', { path: '/' });
    cookies.odometrIsKm === undefined && setCookie('odometrIsKm', 'false', { path: '/' });
  }, [cookies, setCookie]);

  useEffect(() => {
    cookies.odometrIsKm !== undefined && dispatch(changeOdometrIsKm(cookies.odometrIsKm === 'true'));
  }, [cookies.odometrIsKm, dispatch]);
  
  useEffect(() => {
    dispatch(getProductGeneralDictionariesByLanguage(cookies.language));
  }, [cookies.language, dispatch]);

  useEffect(() => {
    const compareStr = localStorage.getItem('compare');
    if (!compareIds.length && compareStr) {
      dispatch(addToCompare(compareStr?.split(',').map((item) => +item) || []));
    }
  }, [dispatch, compareIds]);

  return (
    <>
      <HeaderWrapper>
        <UpperHeader />
        {md && <SubHeader />}
      </HeaderWrapper>
    </>
  );
};

export default Header;
