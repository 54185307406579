import React, { FC } from 'react';
import { DealerStarRateWrapper, DescriptionWrapper, RateByStarWrapper, ReviewCardWrapper, ReviewHead, Time, Title } from './style';
import { Rate } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { StarFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

const ReviewCard: FC<any> = ({review}) => {

  const { md} = useBreakpoint()

  return (
    <ReviewCardWrapper>
      <ReviewHead>
        {md && <RateByStarWrapper>
          <Rate allowHalf defaultValue={review.rate} />
        </RateByStarWrapper>}
        <Title>{review.reviewer}</Title>
        <Time>{dayjs(review.createDate).format('DD-MM-YYYY HH:mm:ss')}</Time>

        {!md && <DealerStarRateWrapper>
          {review.rate}
          <StarFilled />
        </DealerStarRateWrapper>}
      </ReviewHead>
      <DescriptionWrapper>
        <p>
          {review.comment}
        </p>
      </DescriptionWrapper>
    </ReviewCardWrapper>
  );
};

export default ReviewCard;
