import { Col, Row, Modal, Button, Space, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AuctionDetailsForCalendar, CalendarDashboardLane } from '../../../interfaces/Auction';
import { BlinkJoin } from '../AuctionsTodayTable/style';

const { Text } = Typography;

interface Props {
  auctionDetailsForCalendar: AuctionDetailsForCalendar;
  isOpen: boolean;
  auction?: CalendarDashboardLane;
  onCancel: () => void;
  onViewSaleList: () => void;
  handleOpenAuction?: () => void;
}

const AuctionDetailsModal: FC<Props> = ({ auctionDetailsForCalendar, isOpen, auction, onCancel, onViewSaleList, handleOpenAuction }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`${auctionDetailsForCalendar?.country} ${auctionDetailsForCalendar?.city}`}
      width="50%"
      visible={!!isOpen}
      centered
      onCancel={onCancel}
      footer={[
        auction && auction.isOnLiveRun && auction.laneId && (
          <Button type="primary" onClick={handleOpenAuction} size="large">
            <BlinkJoin>{t('auction_join_to_live')}</BlinkJoin>
          </Button>
        ),
        <Button key="back" onClick={onViewSaleList}>
          {t('auction_view_lots_list')}
        </Button>,
      ]}
    >
      <Row gutter={[20, 20]} justify="center">
        <Col xs={16}>
          <img width="100%" alt={auctionDetailsForCalendar?.mainImage} src={auctionDetailsForCalendar?.mainImage} />
        </Col>
        <Col xs={8}>
          <Space direction="vertical">
            <Text strong>{t('auction_city')}</Text>
            <Text>{auctionDetailsForCalendar?.city}</Text>
            <Text strong>{t('auction_country')}</Text>
            <Text>{auctionDetailsForCalendar?.country}</Text>
            <Text strong>{t('auction_address')}</Text>
            <Text>{auctionDetailsForCalendar?.address}</Text>
            <Text strong>{t('auction_phone_number')}</Text>
            <Text>{auctionDetailsForCalendar?.phoneNumber}</Text>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(AuctionDetailsModal);
