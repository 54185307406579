import styled from "styled-components"

export const MoneyWithdrawalWrapper = styled.div`
    padding: 2.5rem;
    @media(max-width: 1400px){
        padding: 1.5rem 1rem;
    }
`

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 1.5rem;
    @media(max-width: 991px){
        justify-content: flex-start;
        margin-bottom: 0.875rem;
        flex-direction: column;
    }
`

export const HeaderTitleGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    @media(max-width: 991px){
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    @media(max-width: 767px){
        display: none;
    }
`

export const HeaderTitle = styled.h3`
    font-family: 'main';
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: #080808;
    margin-bottom: 3rem;
` 

export const HeaderWarning = styled.div`
    font-family: 'main';
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #9095A6;
    display: flex;
    align-items: center;
    & > div{
        margin-right: 0.5rem;
    }
    path{
        stroke: #9095A6;
    }
`

export const HeaderActionGroup = styled.div`
    display: flex;
    align-items: center;
    input{
        max-width: 15rem;
        width: 100%;
        height: 2.5rem;
        background: #FFFFFF;
        border: 1px solid #A5AAAC;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
    }
    button{
        background: #CE2424;
        border-radius: 4px;
        font-family: 'main';
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #FFFFFF;
        padding: 0.75rem;
    }
    @media(max-width: 991px){
        width: 100%;
    }
    @media(max-width: 767px){
        input{
            max-width: 9rem;
            margin-right: 0.5rem;
        }
        button{
            width: 2.5rem;
            height: 2.5rem;
            flex-shrink: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            div, svg{
                width: 1.25rem;
                height: 1.25rem;
            }
        }
    }
`

export const FundsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.325rem;
    margin-right: 1.5rem;
    flex-shrink: 0;

    span:first-child{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: #9095A6;
    }
    span:last-child{
        font-family: 'main';
        font-weight: 500;
        font-size: 1rem;
        line-height: 0.875rem;
        color: #1B153D;
    }
    @media(max-width: 767px){
        margin-right: auto;
    }
`


export const MoneyWithdravalWrapper = styled.div`
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 0px 50px #E8E8E8;
    border-radius: 8px;
    padding: 1rem 0.75rem;
    ul{
        padding: 0;
    }
    li{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        display: flex;
        justify-content: space-between;
        span{
            color: #9095A6;
        }
        p{
            margin-left: 0.5rem;
            color: #1B153D;
            span{
                color: #1B153D;
            }
        }
    }
`

export const MoneyWithdrawalCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: auto;
    gap: 0.75rem; 
`