/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Row, Radio, Space, Col, TablePaginationConfig, Button, Typography, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { EyeFilled, HeartFilled } from '@ant-design/icons';
import {
  getBidsByStatusForUser,
  bidsByStatusListTotalCountSelector,
  bidsByStatusListSelector,
  bidsByStatusListIsLoadingSelector,
} from '../../store/slices/bidsSlice';
import CustomTable from '../../shared/components/CustomTable';
import { addProduct, changeOpenQuickView, deleteProduct, getQuickViewLotData, userWatchListSelector } from '../../store/slices/productSlice';
import { Vehicle } from '../../interfaces/FindVehicles';
import { toggleModalAuth, userSelector } from '../../store/slices/authSlice';
import { CenterAlign } from '../FindVehiclesPage/style';
import BadgeWithTooltip from '../../shared/components/BadgeWithTooltip';
import NoPreviewImage from '../../shared/components/NoPreviewImage';
import { getLinkForLotPage, getOdoValue } from '../../utils/functions';
import Price from '../../shared/components/Price';
import { odometrIsKmSelector } from '../../store/slices/initSlice';
import TablesMenuDrawer from '../../shared/components/TablesMenuDrawer';
import ColorSquare from '../../shared/components/AdvanceSearch/ColorSquare';
import useWatchList from '../../hooks/useWatchList';
import WatchlistButton from '../../shared/components/AdvanceSearch/WatchlistButton';

const { Text } = Typography;

const BuyerLotsPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [tableSettings, setTableSettings] = useState<{
    Page?: number;
    PageItemsCount?: number;
    SortField?: string;
    SortOrder: string;
  }>({
    Page: 1,
    PageItemsCount: 10,
    SortField: '',
    SortOrder: 'desc',
  });
  const [lotsType, setlotsType] = useState<'Won' | 'Lost'>('Won');

  useEffect(() => {
    dispatch(getBidsByStatusForUser(tableSettings.SortOrder, lotsType, tableSettings.Page, tableSettings.PageItemsCount, tableSettings.SortField));
  }, [dispatch, lotsType, tableSettings]);

  useEffect(() => {
    setlotsType(location.pathname === '/wonlots' ? 'Won' : 'Lost');
  }, [location]);

  const bidsByStatusList = useSelector(bidsByStatusListSelector);
  const bidsByStatusListTotalCount = useSelector(bidsByStatusListTotalCountSelector);
  const bidsByStatusListIsLoading = useSelector(bidsByStatusListIsLoadingSelector);
  const userWatchList = useSelector(userWatchListSelector);
  const user = useSelector(userSelector);
  const odometrIsKm = useSelector(odometrIsKmSelector);

  const handleOpenQuickView = useCallback(
    (id) => {
      dispatch(changeOpenQuickView(id));
      dispatch(getQuickViewLotData(id));
    },
    [dispatch],
  );

  const { handleChangeWatchList } = useWatchList()

  const columns = [
    {
      title: 'Action',
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      render: (lotNumber: string, vehicle: Vehicle) => (
        <Space direction="vertical" size="small">
          <Tooltip title="Add to favorite">
            <Button
              icon={<HeartFilled />}
              onClick={() => handleChangeWatchList(+lotNumber)}
              danger={userWatchList.includes(+lotNumber)}
              type={userWatchList.includes(+lotNumber) ? 'primary' : 'default'}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: t("table_col_image"),
      dataIndex: 'mainImage',
      key: 'mainImage',
      render: (mainImage: string, lot: Vehicle) => (
        <Link to={`../lot/${lot.lotNumber}`}>
          <NoPreviewImage imageSrc={mainImage} />
        </Link>
      ),
    },
    {
      title: 'Lot#',
      dataIndex: 'lotNumber',
      render: (lotNumber: string, vehicle: Vehicle) => (
        <CenterAlign>
          <Link to={getLinkForLotPage(lotNumber, vehicle.make, vehicle.model, vehicle.year)}>{lotNumber}</Link>
        </CenterAlign>
      ),
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      sorter: (a: Vehicle, b: Vehicle) => +a.year - +b.year,
      render: (year: string, vehicle: Vehicle) => (
        <CenterAlign>
          {year}
          {vehicle.badges.map((badge) => (
            <BadgeWithTooltip badge={badge} key={`${badge.name}_${vehicle.lotId}`} className="fifth-search-step" />
          ))}
        </CenterAlign>
      ),
    },
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: t("table_col_vin"),
      dataIndex: 'vin',
      key: 'vin',
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (color: string) => (
        <CenterAlign>
          <ColorSquare color={color?.toLowerCase()} />
          <Text>{color}</Text>
        </CenterAlign>
      ),
    },
    {
      title: 'Keys',
      dataIndex: 'keys',
      key: 'keys',
      render: (keys: string) => (keys ? 'Yes' : 'No'),
    },
    {
      title: t("table_col_location"),
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Odometr',
      dataIndex: 'odometer',
      key: 'odometer',

      sorter: (a: Vehicle, b: Vehicle) => +a.odometer - +b.odometer,
      render: (odometer: number, vehicle: Vehicle) => <CenterAlign>{getOdoValue(odometer, odometrIsKm, vehicle.odometerBrand)}</CenterAlign>,
    },
    {
      title: 'Damage',
      dataIndex: 'damage',
      key: 'damage',
    },
    {
      title: 'Current Bid',
      dataIndex: 'currentBid',
      key: 'currentBid',
      render: (currentBid: number) => <Price price={currentBid} />,
    },
    {
      title: 'Max Bid',
      dataIndex: 'maxBid',
      key: 'maxBid',
      render: (maxBid: number) => <Price price={maxBid} />,
    },
  ];

  const handleTableChange = useCallback(
    (pg: TablePaginationConfig, fltr: any, sort: any) => {
      setTableSettings({
        Page: pg.current,
        PageItemsCount: pg.pageSize,
        SortField: sort.field,
        SortOrder: sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc',
      });
      dispatch(
        getBidsByStatusForUser(
          sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc',
          lotsType,
          pg.current,
          pg.pageSize,
          sort.field,
        ),
      );
    },
    [dispatch, lotsType],
  );

  const handleRedirect = useCallback(
    ({ target }) => {
      history.push(target.value === 'Won' ? '/wonlots' : '/lostlots');
      setlotsType(target.value);
    },
    [history],
  );

  return (
    <Row>
      <TablesMenuDrawer>
        <Radio.Group onChange={handleRedirect} value={lotsType}>
          <Space direction="vertical">
            <Radio value="Won">{t('nav_user_menu_buyer_lots_won')}</Radio>
            <Radio value="Lost">{t('nav_user_menu_buyer_lots_lost')}</Radio>
          </Space>
        </Radio.Group>
      </TablesMenuDrawer>
      <Col xs={24} lg={20}>
        <CustomTable
          data={bidsByStatusList.map((item) => ({
            ...item,
            key: item.productId,
          }))}
          columns={columns}
          totalCount={bidsByStatusListTotalCount}
          isLoading={bidsByStatusListIsLoading}
          onChangeTable={handleTableChange}
          scroll={{ x: 'max-content' }}
        />
      </Col>
    </Row>
  );
};

export default memo(BuyerLotsPage);
