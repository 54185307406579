import React, { FC, memo, useEffect } from 'react';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { signinRedirectCallback } from '../../store/slices/authSlice';

const Callback: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const canonicalTag = document.querySelector('link[rel="canonical"]');
  if (canonicalTag) {
    canonicalTag.parentNode?.removeChild(canonicalTag);
  }

  const metaTag = document.querySelector('meta[name="robots"]');
  if (metaTag) {
    metaTag.setAttribute('content', 'follow, noindex, max-snippet:-1, max-video-preview:-1, max-image-preview:large');
  }

  useEffect(() => {
    dispatch(signinRedirectCallback());
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }, [dispatch, history]);

  return (
    <div style={styles.container}>
      <Spin size="large" />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50vh',
    padding: '20px',
  },
};

export default memo(Callback);
