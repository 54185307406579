import React, { FC, memo, useCallback, useState, useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Row, Button, Collapse, Radio, Space, Col, Modal, InputNumber, TablePaginationConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import LotsTable from '../../shared/components/LotsTable';
import FilterTree from '../../shared/components/FilterTree';
import { productGeneralDictionariesByLanguageSelector, makeModelsByVehicleTypeSelector, getMakeModelsByVehicleType } from '../../store/slices/dictionarySlice';
import { getDataForFilter, getPrice, langPrefix } from '../../utils/functions';
import { DictionaryItem, MakeModelsByVehicleType } from '../../interfaces/Dictionary';
import LotModal from '../../shared/components/LotModal';
import { MyLotItem } from '../../interfaces/Product';
import { sellerNewPrice, sellerDecline, sellerApprove, cancelOffer, approveOffer } from '../../store/slices/bidsSlice';
import { getLots, lotsListSelector, lotsListTotalCountSelector } from '../../store/slices/productSlice';
import DateForTable from '../../shared/components/DateForTable';
import { ScCountdown } from '../LotPage/SaleInformationCard';
import Price from '../../shared/components/Price';
import { currencySelector } from '../../store/slices/initSlice';
import { ScDrawer } from '../FindVehiclesPage/style';
import { ScButton } from '../../shared/components/AdvanceSearch/style';
import AdvanceSearchButton from '../../shared/components/AdvanceSearch/AdvanceSearchButton';
import { ReactSVG } from 'react-svg';
import MobileFilterHeader from '../../shared/components/MobileFilterHeader';
import LotMobileList from '../../shared/components/LotMobileList';

const { Panel } = Collapse;
const { confirm } = Modal;

const SellerLotsPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { lg, md } = useBreakpoint();

  const [Country, setCountry] = useState<string | undefined>();
  const [VehicleType, setVehicleType] = useState<string | undefined>();
  const [Make, setMake] = useState<string | undefined>();
  const [Model, setModel] = useState<string | undefined>();
  const [AssignmentType, setAssignmentType] = useState<string | undefined>();
  const [DaysInYardLessThan, setDaysInYardLessThan] = useState<string | undefined>();
  const [DaysInYardGreaterThan, setDaysInYardGreaterThan] = useState<string | undefined>();
  const [DaysInYard, setDaysInYard] = useState<string | undefined>();
  const [MakeModel, setMakeModel] = useState<string | undefined>();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [currentLot, setCurrentLot] = useState<MyLotItem>();

  const [openNewPriceModal, setOpenNewPriceModal] = useState<string | false>(false);
  const [newPrice, setNewPrice] = useState<number | undefined>();
  const pathParts = location.pathname.split('/');
  const lotsTypePath = location.pathname.includes('/en') || location.pathname.includes('/ru')
    ? pathParts[3]
    : pathParts[2];
  const [lotsType, setLotsType] = useState<string>(lotsTypePath);
  const productGeneralDictionariesByLanguage = useSelector(productGeneralDictionariesByLanguageSelector);
  const makeModelsByVehicleType = useSelector(makeModelsByVehicleTypeSelector);

  useEffect(() => {
    const vehicletype = productGeneralDictionariesByLanguage.vehicleTypesList?.find((item: DictionaryItem) => item.value === VehicleType);
    if (vehicletype) dispatch(getMakeModelsByVehicleType(vehicletype.id));
  }, [dispatch, VehicleType, productGeneralDictionariesByLanguage]);

  useEffect(() => {
    setLotsType(lotsTypePath);
  }, [location]);

  const handleRedirect = useCallback(
    ({ target }) => {
      history.push(target.value);
    },
    [history],
  );

  const handleUpdateLots = useCallback((field: string, value: string, update: (value?: string) => void) => {
    if (field === 'MakeModel') {
      setMakeModel(value);
      const array = value.split(',');
      const make = array
        .filter((item) => item.includes('mk_'))
        .map((item) => item.substring(3))
        .join(',');
      const model = array
        .filter((item) => item.includes('md_'))
        .map((item) => item.substring(3))
        .join(',');

      setMake(make);
      setModel(model);
    } else if (field === 'DaysInYard') {
      setDaysInYard(value);
      const array: string[] = value.split(',');
      setDaysInYardLessThan(array.indexOf('DaysInYardLessThan') > -1 ? '30' : undefined);
      setDaysInYardGreaterThan(array.indexOf('DaysInYardGreaterThan') > -1 ? '30' : undefined);
    } else {
      update(value || undefined);
    }
  }, []);

  const getMakeModelForFilter = (array: MakeModelsByVehicleType[]) =>
    array.map((make) => ({
      key: `mk_${make.make}`,
      title: make.make,
      children: make.modelGroups.map((modelGroup) => ({
        key: `gr_${modelGroup.modelGroup}`,
        title: modelGroup.modelGroup,
        children: modelGroup.models.map((model) => ({
          key: `md_${model}`,
          title: model,
        })),
      })),
    }));

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleOpenModal = useCallback(
    (modalTitle: string, lot: MyLotItem) => () => {
      setTitle(modalTitle);
      setCurrentLot(lot);
      setIsModalVisible(true);
    },
    [],
  );

  const handleCloseNewPriceModal = useCallback(() => {
    setOpenNewPriceModal(false);
  }, []);

  const [tableSettings, setTableSettings] = useState<{
    Page?: number;
    PageItemsCount?: number;
    SortField?: string;
    SortOrder: string;
  }>({
    Page: 1,
    PageItemsCount: 10,
    SortField: '',
    SortOrder: 'desc',
  });

  const handleSubmitNewPriceModal = useCallback(
    () => {
      confirm({
        title: <>{t('offer_newprice')} -{getPrice(currency, newPrice || 0)}</>,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          newPrice &&
            openNewPriceModal &&
            dispatch(
              sellerNewPrice(
                +newPrice,
                openNewPriceModal,
                tableSettings.SortOrder,
                lotsType,
                tableSettings.Page,
                tableSettings.PageItemsCount,
                tableSettings.SortField,
                DaysInYardLessThan,
                DaysInYardGreaterThan,
                Country,
                AssignmentType,
                VehicleType,
                Make,
                Model,
              ),
            );
          handleCloseNewPriceModal();
        },
        okText: t('Accept'),
        cancelText: t('Close'),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      newPrice,
      openNewPriceModal,
      dispatch,
      tableSettings,
      handleCloseNewPriceModal,
      DaysInYardLessThan,
      DaysInYardGreaterThan,
      Country,
      AssignmentType,
      VehicleType,
      Make,
      Model,
      lotsType,
    ],
  );

  useEffect(() => {
    dispatch(
      getLots(
        tableSettings.SortOrder,
        lotsType,
        tableSettings.Page,
        tableSettings.PageItemsCount,
        tableSettings.SortField as string,
        DaysInYardLessThan,
        DaysInYardGreaterThan,
        Country,
        AssignmentType,
        VehicleType,
        Make,
        Model,
      ),
    );
  }, [dispatch, tableSettings, lotsType, AssignmentType, Country, DaysInYardGreaterThan, DaysInYardLessThan, Make, Model, VehicleType]);

  const handleTableChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (pg: TablePaginationConfig, fltr: any, sort: any) => {
      setTableSettings({
        Page: pg.current,
        PageItemsCount: pg.pageSize,
        SortField: sort.field,
        SortOrder: sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc',
      });
      dispatch(
        getLots(
          tableSettings.SortOrder,
          lotsType,
          tableSettings.Page,
          tableSettings.PageItemsCount,
          tableSettings.SortField as string,
          DaysInYardLessThan,
          DaysInYardGreaterThan,
          Country,
          AssignmentType,
          VehicleType,
          Make,
          Model,
        ),
      );
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    [dispatch, tableSettings, lotsType, DaysInYardLessThan, DaysInYardGreaterThan, Country, AssignmentType, VehicleType, Make, Model],
  );

  const handleChangePagination = (page: number, pageSize?: number | undefined) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    
    setTableSettings({
      ...tableSettings,
      Page: page || 1,
      PageItemsCount: pageSize || 10,
    })
};

  const handleSetNewPrice = useCallback((value: number | string) => {
    setNewPrice(+value);
  }, []);

  const handleOpenNewPrice = useCallback(
    (lotNumber, currentBid) => () => {
      setOpenNewPriceModal(lotNumber);
      setNewPrice(currentBid);
    },
    [],
  );

  const handleOpenAccept = useCallback(
    (maxBid, lotNumber) => () => {
      confirm({
        title: <>{t('offer_accept')} -{getPrice(currency, maxBid || 0)}</>,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(
            sellerApprove(
              lotNumber,
              tableSettings.SortOrder,
              lotsType,
              tableSettings.Page,
              tableSettings.PageItemsCount,
              tableSettings.SortField,
              DaysInYardLessThan,
              DaysInYardGreaterThan,
              Country,
              AssignmentType,
              VehicleType,
              Make,
              Model,
            ),
          );
        },
        okText: t('Accept'),
        cancelText: t('Close'),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, tableSettings, lotsType, DaysInYardLessThan, DaysInYardGreaterThan, Country, AssignmentType, VehicleType, Make, Model],
  );

  const currency = useSelector(currencySelector);

  const handleOpenCancel = useCallback(
    (maxBid, lotNumber) => () => {
      confirm({
        title: <>{t('offer_decline')} -{getPrice(currency, maxBid)}</>,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(
            sellerDecline(
              lotNumber,
              tableSettings.SortOrder,
              lotsType,
              tableSettings.Page,
              tableSettings.PageItemsCount,
              tableSettings.SortField,
              DaysInYardLessThan,
              DaysInYardGreaterThan,
              Country,
              AssignmentType,
              VehicleType,
              Make,
              Model,
            ),
          );
        },
        okText: t('Cancel'),
        cancelText: t('Close'),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, tableSettings, lotsType, DaysInYardLessThan, DaysInYardGreaterThan, Country, AssignmentType, VehicleType, Make, Model],
  );

  const handleOpenCancelOffer = useCallback(
    (amount, lotNumber) => () => {
      confirm({
        title: <>{t('offer_decline')} -{getPrice(currency, amount)}</>,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(
            cancelOffer(
              lotNumber,
              tableSettings.SortOrder,
              lotsType,
              tableSettings.Page,
              tableSettings.PageItemsCount,
              tableSettings.SortField,
              DaysInYardLessThan,
              DaysInYardGreaterThan,
              Country,
              AssignmentType,
              VehicleType,
              Make,
              Model,
            ),
          );
        },
        okText: t('Cancel'),
        cancelText: t('Close'),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, tableSettings, lotsType, DaysInYardLessThan, DaysInYardGreaterThan, Country, AssignmentType, VehicleType, Make, Model],
  );

  const handleOpenApproveOffer = useCallback(
    (amount, lotNumber) => () => {
      confirm({
        title: <>{t('offer_accept')} -{getPrice(currency, amount || 0)}</>,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(
            approveOffer(
              lotNumber,
              tableSettings.SortOrder,
              lotsType,
              tableSettings.Page,
              tableSettings.PageItemsCount,
              tableSettings.SortField,
              DaysInYardLessThan,
              DaysInYardGreaterThan,
              Country,
              AssignmentType,
              VehicleType,
              Make,
              Model,
            ),
          );
        },
        okText: t('Accept'),
        cancelText: t('Close'),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, tableSettings, lotsType, DaysInYardLessThan, DaysInYardGreaterThan, Country, AssignmentType, VehicleType, Make, Model],
  );

  const getTimeLeftInfo = (saleDate: string) => {
    if (!saleDate) return t('lot_sale_information_futured');
    if (new Date(saleDate).getTime() - Date.now() <= 0) return t('lot_sale_information_live_auction');
    return (
      <>
        <DateForTable date={saleDate} />
        <ScCountdown title="" value={saleDate} format="DD[D] HH[H] mm[M] ss[S]" onFinish={() => {}} />
      </>
    );
  };

  const [visible, setvisible] = useState(false);

  const showDrawer = () => {
    setvisible(true);
  };

  const onClose = () => {
    setvisible(false);
  };

  const lotsList = useSelector(lotsListSelector);
  const lotsListTotalCount = useSelector(lotsListTotalCountSelector);
  const getMenu = () => (
    <Collapse defaultActiveKey={['type']}>
      <Panel header={t('nav_user_menu_seller_my_lots')} key="type">
        <Radio.Group onChange={handleRedirect} value={location.pathname}>
          <Space direction="vertical">
            <Radio value={langPrefix("/seller/lotsoncheck")}>{t('nav_user_menu_seller_my_lots_lots_on_check')}</Radio>
            <Radio value={langPrefix("/seller/onsalelist")}>{t('nav_user_menu_seller_my_lots_on_sale_list')}</Radio>
            {/* <Radio value="./bidapproval">{t('nav_user_menu_seller_my_lots_on_approval')}</Radio> */}
            {/* <Radio value="./soldlots">{t('nav_user_menu_seller_my_lots_sold_lots')}</Radio> */}
            {/* <Radio value="./waitauctionlots">{t('nav_user_menu_seller_my_lots_wait_auction')}</Radio> */}
            <Radio value={langPrefix("/seller/myoffers")}>{t('nav_user_menu_seller_my_lots_my_offers')}</Radio>
          </Space>
        </Radio.Group>
      </Panel>
      {/* <Panel header={t('auction_country')} key="Country">
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.countriesList || [])}
          onCheck={(val) => handleUpdateLots('Country', val, setCountry)}
          checkedKeys={Country?.split(',')}
          name="Country"
        />
      </Panel>
      <Panel header={t('lot_vehicle_type')} key="VehicleType">
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.vehicleTypesList || [])}
          onCheck={(val) => handleUpdateLots('VehicleType', val, setVehicleType)}
          checkedKeys={VehicleType?.split(',')}
          name="VehicleType"
        />
      </Panel>
      <Panel header={t('lot_make_model')} key="MakeModel">
        <FilterTree
          items={getMakeModelForFilter(makeModelsByVehicleType)}
          onCheck={(val) => handleUpdateLots('MakeModel', val, () => {})}
          checkedKeys={MakeModel?.split(',')}
          name="MakeModel"
        />
      </Panel>
      <Panel header={t('seller_on_sale_list_assignment_type')} key="AssignmentType">
        <FilterTree
          items={getDataForFilter(productGeneralDictionariesByLanguage.assignmentTypesList || [])}
          onCheck={(val) => handleUpdateLots('AssignmentType', val, setAssignmentType)}
          checkedKeys={AssignmentType?.split(',')}
          name="AssignmentType"
        />
      </Panel>
      <Panel header={t('seller_on_sale_list_days_in_yard')} key="DaysInYard">
        <FilterTree
          items={[
            {
              title: t('seller_on_sale_list_days_in_yard_less'),
              key: 'DaysInYardLessThan',
            },
            {
              title: t('seller_on_sale_list_days_in_yard_more'),
              key: 'DaysInYardGreaterThan',
            },
          ]}
          onCheck={(val) => handleUpdateLots('DaysInYard', val, setDaysInYard)}
          checkedKeys={DaysInYard?.split(',')}
          name="DaysInYard"
        />
      </Panel> */}
    </Collapse>
  );

  return (
    <Row>
      {!lg && (
        <Col xs={24}>
          <MobileFilterHeader 
            onButtonHandle={showDrawer}
          />
          {/* <Button type="primary" onClick={showDrawer}>
            Menu
          </Button> */}
        </Col>
      )}
      <Col xs={0} lg={4}>
        {!lg && (
          <ScDrawer placement="left" closable={false} onClose={onClose} visible={visible} style={{ padding: 0 }}>
            {getMenu()}
          </ScDrawer>
        )}
        {lg && getMenu()}
      </Col>
      <Col xs={24} lg={20}>
        <Switch>
          <Route exact path={langPrefix("/seller/lotsoncheck")}>
            {md 
              ? 
                <LotsTable
                  columns={[
                    {
                      title: t("lot_status"),
                      dataIndex: 'actionType',
                      key: 'actionType',
                    },
                  ]}
                  onTableChange={handleTableChange}
                />
              :
              <LotMobileList tableSettings={tableSettings} handleChangePagination={handleChangePagination} lotsList={lotsList} lotsListTotalCount={lotsListTotalCount}/>
              }
          </Route>
          <Route exact path={langPrefix("/seller/onsalelist")}>
            {md 
            ? 
              <LotsTable
                columns={[
                  // {
                  //   title: 'Primary Damage',
                  //   dataIndex: 'primaryDamage',
                  //   key: 'primaryDamage',
                  // },
                  // {
                  //   title: 'Secondary Damage',
                  //   dataIndex: 'secondaryDamage',
                  //   key: 'secondaryDamage',
                  // },
                  // {
                  //   title: 'Sale Date',
                  //   dataIndex: 'saleDate',
                  //   key: 'saleDate',
                  //   // render: (lot) => getTimeLeftInfo(lot ? lot : null),
                  //   render: (saleDate: string) => getTimeLeftInfo(saleDate),
                  // },
                  {
                    title: t("lot_status"),
                    dataIndex: 'status',
                    key: 'status',
                  },
                  // {
                  //   title: t("table_col_buyer_actions"),
                  //   render: (lot) => (
                  //     <Space direction="vertical">
                  //       <ScButton size="large" type="primary" onClick={handleOpenModal('Bid History', lot as MyLotItem)}>
                  //         {t('seller_on_sale_bid_history')}
                  //       </ScButton>
                  //       <ScButton size="large" type="primary" onClick={handleOpenModal('Notes', lot as MyLotItem)}>
                  //         {t('seller_on_sale_notes')}
                  //       </ScButton>
                  //       {/*
                  //         <ScButton size="small" onClick={handleOpenModal('Documents', lot as MyLotItem)}>Documents</ScButton>
                  //         <ScButton size="small" onClick={handleOpenModal('Service Order', lot as MyLotItem)}>Service Order</ScButton>
                  //       */}
                  //     </Space>
                  //   ),
                  // },
                ]}
                onTableChange={handleTableChange}
              />              
            :
              <LotMobileList statusKey={"status"} tableSettings={tableSettings} handleChangePagination={handleChangePagination} lotsList={lotsList} lotsListTotalCount={lotsListTotalCount}/>
            }

          </Route>
          <Route exact path={langPrefix("/seller/bidapproval")}>
            <LotsTable
              columns={[
                {
                  title: t("lot_status"),
                  dataIndex: 'status',
                  key: 'status',
                },
                {
                  title: t("table_col_buyer_offer"),
                  render: (lot) => <Price price={lot.currentBid} />,
                },
                {
                  title: 'Seller offer',
                  render: (lot) => <Price price={lot.sellerOffer} />,
                },
                {
                  title: t("table_col_buyer_actions"),
                  render: (lot) => (
                    <Space direction="vertical">
                      <Button size="large" type="primary" disabled={lot.status === 'OnBuyerApprove'} onClick={handleOpenAccept(lot.currentBid, lot.lotNumber)}>
                        Accept
                      </Button>
                      <Button
                        size="large"
                        type="primary"
                        disabled={lot.status === 'OnBuyerApprove'}
                        onClick={handleOpenNewPrice(lot.lotNumber, lot.currentBid)}
                      >
                        New Price
                      </Button>
                      <Button size="large" type="primary" disabled={lot.status === 'OnBuyerApprove'} onClick={handleOpenCancel(lot.currentBid, lot.lotNumber)}>
                        Cancel
                      </Button>
                    </Space>
                  ),
                },
              ]}
              onTableChange={handleTableChange}
            />
          </Route>
          <Route exact path={langPrefix("/seller/soldlots")}>
            <LotsTable
              columns={[
                {
                  title: t("lot_status"),
                  dataIndex: 'actionType',
                  key: 'actionType',
                },
                {
                  title: 'Sold Date',
                  dataIndex: 'soldDate',
                  key: 'soldDate',
                  render: (lot) => (lot ? dayjs(lot.soldDate).format('DD/MM/YYYY') : ''),
                },
                {
                  title: 'Sale Price',
                  render: (lot) => <Price price={lot.salePrice} />,
                },
                {
                  title: 'Fees',
                  render: (lot) => <Price price={lot.fees} />,
                },
              ]}
              onTableChange={handleTableChange}
            />
          </Route>
          <Route exact path={langPrefix("/seller/waitauctionlots")}>
            <LotsTable
              columns={[
                {
                  title: t("lot_status"),
                  dataIndex: 'status',
                  key: 'status',
                },
              ]}
              onTableChange={handleTableChange}
            />
          </Route>
          <Route exact path={langPrefix("/seller/myoffers")}>
          {md 
            ? 
              <LotsTable
                columns={[
                  // {
                  //   title: 'Current Bid',
                  //   render: (lot) => <Price price={lot.currentBid} />,
                  // },
                  {
                    title: t("table_col_buyer_offer"),
                    render: (lot) => <Price price={lot.amount} />,
                  },
                  {
                    title: t("buy_now"),
                    render: (lot) => <Price price={lot.buyNowPrice} />,
                  },
                  {
                    title: t("table_col_buyer_actions"),
                    render: (lot) => (
                      <Space direction="vertical">
                        <AdvanceSearchButton
                          onClick={handleOpenApproveOffer(lot.amount, lot.lotNumber)}
                          text={"Accept"}
                        />
                        <AdvanceSearchButton
                          onClick={handleOpenCancelOffer(lot.amount, lot.lotNumber)}
                          text={"Cancel"}
                        />
                      </Space>
                    ),
                  },
                ]}
                onTableChange={handleTableChange}
              />
            :
            <LotMobileList 
              statusKey={"lotNumber"} 
              tableSettings={tableSettings} 
              handleChangePagination={handleChangePagination}
              lotsList={lotsList} 
              lotsListTotalCount={lotsListTotalCount}
              footer={(lot: any) => (
                <Space direction="horizontal">
                  <AdvanceSearchButton
                    onClick={handleOpenApproveOffer(lot.amount, lot.lotNumber)}
                    text={"Accept"}
                  />
                  <AdvanceSearchButton
                    onClick={handleOpenCancelOffer(lot.amount, lot.lotNumber)}
                    text={"Cancel"}
                  />
                </Space>
              )}
            />
          }
          </Route>
        </Switch>
      </Col>
      {currentLot && <LotModal title={title} isOpen={!!isModalVisible} onCancel={handleCancel} lot={currentLot} />}
      {!!openNewPriceModal && (
        <Modal title="New Price" visible={!!openNewPriceModal} centered onCancel={handleCloseNewPriceModal} onOk={handleSubmitNewPriceModal}>
          <InputNumber
            defaultValue={newPrice}
            formatter={(value?: string | number) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={handleSetNewPrice}
          />
        </Modal>
      )}
    </Row>
  );
};

export default memo(SellerLotsPage);
