import styled, { css } from "styled-components";
import { LotCardBody, LotCardHeader, LotCardWrapper } from "../../shared/components/LotDescriptionCard";
import Slider from "react-slick";

export const ElectricCarsDetaileWrapper = styled.div`
    max-width: 90rem;
    width: 100%;
    padding: 3rem 1rem;
      margin-right: auto;
  margin-left: auto;
    display: grid;
    grid-template-columns: calc( 90rem - 27.75rem - 2.5rem ) 1fr;
    gap: 2.5rem;
    @media(max-width: 1440px){
        grid-template-columns: calc( 100% - 22.75rem - 2.5rem ) 22.75rem;
    }
    @media(max-width: 1200px){
        grid-template-columns: calc( 100% - 20.75rem - 2.5rem ) 20.75rem;
    }
    @media(max-width: 991px){
        grid-template-columns: 1fr;
        padding: 1.5rem 1rem;
    }
    @media(max-width: 767px){
        padding: 1rem;
        gap: 0;
    }
`

export const LeftSideWrapper = styled.div`
    @media(max-width: 991px){
        width: 100%;
        overflow: hidden;
    }
`

export const StickyContainer = styled.div`
    position: sticky;
    top: 210px;
    @media(max-width: 991px){
        position: relative;
        top: 0;
    }
`

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
        font-family: 'main';
        font-weight: 600;
        font-size: 1.75rem;
        line-height: 1.5rem;
        color: #CE2424;
    }
    @media(max-width: 991px){
        span{
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }
    @media(max-width: 767px){
        padding: 1rem;
        background-color: #fff;
        border: 1px solid #EAEAEA;
        border-bottom: none;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        span{
            font-size: 1rem;
            line-height: 0.875rem;
        }
    }
`

export const HeaderTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    h2{
        font-family: 'main';
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 1.5rem;
        color: #1B153D;
    }
    p{
        font-family: 'main';
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #1B153D;
    }
    @media(max-width: 991px){
        h2{
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
        p{
            font-size: 0.875rem;
            line-height: 1rem;
        }
    }
    @media(max-width: 767px){
        h2{
            font-size: 0.875rem;
            line-height: 0.875rem;
        }
        p{
            font-size: 0.625rem;
            line-height: 0.875rem;
        }
    }
`

export const DescriptionCardWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.25rem;
    @media(max-width: 767px){
        ${LotCardWrapper}:first-child{
            border: 1px solid #EAEAEA;
            border-top: none;
            border-radius: 0px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;

            ${LotCardHeader}{
                display: none;
            }
            ${LotCardBody}{
                display: block;
                padding-bottom: 1.5rem;
            }
        }
    }
`

export const SliderWrapper = styled.div`
    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right{
        display: none;
    }
    .image-gallery-slide-wrapper.image-gallery-thumbnails-left, .image-gallery-slide-wrapper.image-gallery-thumbnails-right{
        width: 100%;
        background: #f2f2f2;
    }
`

export const ImageWrapper = styled.div`
    width: fit-content;
    img{
        width: 100%;
    }
    @media(max-width: 767px){
        padding: 0 1rem;
        background-color: #fff;
        border: 1px solid #EAEAEA;
        border-bottom: none;
        border-top: none;
    }
`
interface SliderButtonWrapperProps {
    isNextArrow?: boolean;
    isPrevArrow?: boolean;
  }
  
  export const SliderButtonWrapper = styled.button<SliderButtonWrapperProps>`
    background: #F9FAFB;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 100px;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    transform: translate(0%, -50%);
    padding: 0;
    .svg-wrapper{
      &, & > div{
        display: flex;
        align-items: center;
      }
    }
    
    @media(max-width: 991px){
      display: none;
    }
  
    ${p => p.isPrevArrow ? css`
      position: absolute;
      top: 50%;
      left: -10px;
    ` : css`
        position: absolute;
        top: 50%;
        right: -10px;
        svg{
            transform: rotate(180deg);
        }
    `}
  `