import styled, { css } from "styled-components";
import { AlertVariants } from ".";

interface AlertWrapperProps {
    variant?: AlertVariants
}

export const AlertWrapper = styled.div<AlertWrapperProps>`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem 1rem;
    background: rgba(206, 36, 36, 0.1);
    border-radius: 4px;
    span{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #EF4343;
    }
    .svg-wrapper{
        width: 1.25rem;
        height: 1.25rem;
    }
    ${p => {
        switch(p.variant){
            case AlertVariants.GRAY:
                return css`
                    background: #F6F6F6;
                    path{
                        stroke: #5F6368;
                    }
                    span{
                        color: #5F6368;
                    }
                `
        }
    }}
`