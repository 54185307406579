import { Collapse, Descriptions, Row } from 'antd';
import React, { FC, memo } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { SellerOneLotPageData } from '../../../interfaces/Product';
import { getOdoValue } from '../../../utils/functions';
import { odometrIsKmSelector } from '../../../store/slices/initSlice';

const { Panel } = Collapse;
const { Item } = Descriptions;

interface Props {
  lotData: SellerOneLotPageData;
}

const Overview: FC<Props> = ({ lotData }) => {
  const { sellerInformation, criticalDates, vehicleInformation, auctionInformation, titleInformation, billingSummary, locationInformation } = lotData;

  const odometrIsKm = useSelector(odometrIsKmSelector);

  const getFormattedDate = (date?: string) => {
    if (date) return dayjs(date).format('DD/MM/YYYY');
    return '';
  };

  return (
    <div>
      {/* <Row justify="end">
        <a href={`mailto:admin@liontrans.com?subject=New images for Lot#${lotData.assignmentMaintenance.lotId}&body=Please find the attached`}>
          <Button type="primary">Email More Images</Button>
        </a>
      </Row> */}
      <Row>
        <Collapse>
          {/* <Panel header="Documents" key="2">
            {lotData.documents.map((item) => 'Doc Name?')}
            <Row justify="end">
              <a href={`mailto:admin@liontrans.com?subject=New documents for Lot#${lotData.assignmentMaintenance.lotId}&body=Please find the attached`}>
                <Button type="primary">Email More Docs</Button>
              </a>
            </Row>
          </Panel> */}
          <Panel header="Seller information" key="1">
            <Descriptions bordered size="small" column={1}>
              <Item label="Seller">{sellerInformation.seller}</Item>
              <Item label="Owner">{sellerInformation.owner}</Item>
              {/* <Item label="Policy Number">{sellerInformation.policyNumber}</Item>
              <Item label="Claim Number">{sellerInformation.claimNumber}</Item>
              <Item label="Adjuster">{sellerInformation.adjuster}</Item> */}
              <Item label="Owner Mobile Phone Number">{sellerInformation.ownerMobilePhoneNumber}</Item>
              {/* <Item label="Seller Loss Code">{sellerInformation.sellerLossCode}</Item>
              <Item label="Seller Reference #">
              {sellerInformation.sellerReference}</Item> */}
              <Item label="Auction">{getFormattedDate(criticalDates.auction)}</Item>
              {/* <Item label="Adjuster Phone #">{sellerInformation.adjusterPhone}</Item> */}
            </Descriptions>
          </Panel>
          <Panel header="Vehicle information" key="3">
            <Descriptions bordered size="small" column={1}>
              <Item label="Vehicle Type">{vehicleInformation.vehicleType}</Item>
              <Item label="Drivibility Rating">{vehicleInformation.drivabilityRating}</Item>
              <Item label="Description">{vehicleInformation.description}</Item>
              <Item label="Body Style">{vehicleInformation.bodyStyle}</Item>
              <Item label="Vin Plate">{vehicleInformation.vinPlate ? 'Yes' : 'No'}</Item>
              <Item label="VIN">{vehicleInformation.vin}</Item>
              <Item label="Engine Type">{vehicleInformation.engineType}</Item>
              <Item label="Transmission">{vehicleInformation.transmission}</Item>
              {/* <Item label="Number Of Plates">{vehicleInformation.numberOfPlates}</Item> */}
              <Item label="Drive">{vehicleInformation.drive}</Item>
              {/* <Item label="Personal Plates">{vehicleInformation.personalPlates ? 'Yes' : 'No'}</Item> */}
              <Item label="Cylinders">{vehicleInformation.cylinders}</Item>
              {/* <Item label="License Plate Number">{vehicleInformation.licensePlateNumber}</Item> */}
              <Item label="Fuel">{vehicleInformation.fuel}</Item>
              {/* <Item label="License Plate Expiration">{getFormattedDate(vehicleInformation.licensePlateExpiration)}</Item> */}
              <Item label="Color">{vehicleInformation.color}</Item>
              {/* <Item label="lossType">{vehicleInformation.lossType}</Item> */}
              <Item label="Keys">{vehicleInformation.keys ? 'Yes' : 'No'}</Item>
              <Item label="Primary Damage">{vehicleInformation.primaryDamage}</Item>
              <Item label="Secondary Damage">{vehicleInformation.secondaryDamage}</Item>
              <Item label="Customs">{vehicleInformation.clearancePassed ? 'Cleared' : 'Before'}</Item>
            </Descriptions>
          </Panel>
          <Panel header="Critical Dates" key="4">
            <Descriptions bordered size="small" column={1}>
              <Item label="Register Date">{getFormattedDate(criticalDates.lossDate)}</Item>
              {/* <Item label="Loss Date">{getFormattedDate(criticalDates.lossDate)}</Item>
              <Item label="First Notice Loss Date">{getFormattedDate(criticalDates.firstNoticeLossDate)}</Item>
              <Item label="Recovery Date">{getFormattedDate(criticalDates.recoveryDate)}</Item>
              <Item label="Cleared For Pickup">{getFormattedDate(criticalDates.clearedForPickup)}</Item>
              <Item label="Cleared For Charges">{getFormattedDate(criticalDates.clearedForCharges)}</Item>
              <Item label="Trip Confirmation">{getFormattedDate(criticalDates.tripConfirmation)}</Item>
              <Item label="Picked Up">{getFormattedDate(criticalDates.pickedUp)}</Item>
              <Item label="Pickup Hold Actione">{getFormattedDate(criticalDates.pickupHoldAction)}</Item>
              <Item label="Hold For Sale Action">{getFormattedDate(criticalDates.holdForSaleAction)}</Item>
              <Item label="First Title Notification">{getFormattedDate(criticalDates.firstTitleNotification)}</Item>
              <Item label="Original Title Received">{getFormattedDate(criticalDates.originalTitleReceived)}</Item>
              <Item label="Transferable Title Received">{getFormattedDate(criticalDates.transferableTitleReceived)}</Item>
              <Item label="Submitted To DMV">{getFormattedDate(criticalDates.submittedToDMV)}</Item>
              <Item label="Title Rejected">{getFormattedDate(criticalDates.titleRejected)}</Item>
              <Item label="Cert Received">{getFormattedDate(criticalDates.certReceived)}</Item>
              <Item label="Legal Sale Date">{getFormattedDate(criticalDates.legalSaleDate)}</Item>
              <Item label="Last Seller Invoice">{getFormattedDate(criticalDates.lastSellerInvoice)}</Item>
              <Item label="Seller Settlement">{getFormattedDate(criticalDates.sellerSettlement)}</Item>
              <Item label="Proceeds Cut">
              {getFormattedDate(criticalDates.proceedsCut)}</Item> */}
              <Item label="Auction">{getFormattedDate(criticalDates.auction)}</Item>
              <Item label="Buyer Invoice">{getFormattedDate(criticalDates.buyerInvoice)}</Item>
              <Item label="Buyer Final Paid">{getFormattedDate(criticalDates.buyerFinalPaid)}</Item>
              {/* <Item label="NICB Reported">{getFormattedDate(criticalDates.nicbReported)}</Item> */}
            </Descriptions>
          </Panel>
          <Panel header="Location information" key="8">
            <Descriptions bordered size="small" column={1}>
              <Item label="Location">{locationInformation.location}</Item>
              <Item label="Location Phone#">{locationInformation.locationPhone}</Item>
              <Item label="Lane">{locationInformation.rowLocation}</Item>
            </Descriptions>
          </Panel>
          <Panel header="Title information" key="6">
            <Descriptions bordered size="small" column={1}>
              {/* <Item label="Odometer Brand">{titleInformation.odometerBrand}</Item> */}
              <Item label="Location Phone#">{titleInformation.locationPhone}</Item>
              {/* <Item label="Original Title State">{titleInformation.originalTitleState}</Item>
              <Item label="Title Processing Location">{titleInformation
                .titleProcessingLocation}
              </Item>
              <Item label="Sale Title Number">{titleInformation.saleTitleNumber}</Item>
              <Item label="Original Type Order">{titleInformation.originalTitleType}</Item>
              <Item label="Sale Title State">
              {titleInformation.saleTitleState}</Item> */}
              <Item label="Odometer">{getOdoValue(+titleInformation.odometer, odometrIsKm, titleInformation.odometerBrand)}</Item>
              {/* <Item label="Sale Title Type">{titleInformation.saleTitleType}</Item> */}
            </Descriptions>
          </Panel>
          <Panel header="Auction information" key="5">
            <Descriptions bordered size="small" column={1}>
              {/* <Item label="Item #">{auctionInformation.itemNumber}</Item>
              <Item label="Repair Cost">{auctionInformation.repairCost}</Item>
              <Item label="Cert Number">
              {auctionInformation.certNumber}</Item> */}
              <Item label="Reserve min. Price">{auctionInformation.reserveAmount}</Item>
              {/* <Item label="Sale Platform">{auctionInformation.salePlatform}</Item> */}
              <Item label="Reserve">{auctionInformation.reserve}</Item>
              <Item label="Sale Name">{auctionInformation.saleName}</Item>
              <Item label="High Bid Amount">{auctionInformation.highBidAmount}</Item>
              {/* <Item label="ACV">{auctionInformation.acv}</Item> */}
            </Descriptions>
          </Panel>
          <Panel header="Billing summary" key="7">
            <Descriptions bordered size="small" column={1}>
              <Item label="Sale Amount">{billingSummary.saleAmount}</Item>
              <Item label="First Roll">{billingSummary.firstRoll}</Item>
              <Item label="Re roll">{billingSummary.reRoll}</Item>
            </Descriptions>
          </Panel>
        </Collapse>
      </Row>
    </div>
  );
};

export default memo(Overview);
