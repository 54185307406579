import React from 'react'
import { BottomGroupWrapper, HistoryWallBanner, HistoryWallWrapper, TopGroupWrapper } from './style'
import HistoryCard from './HistoryCard'
import { useTranslation } from 'react-i18next'

const HistoryWall = () => {
    const { t } = useTranslation();

    const topData = [
        {
            title: t('timeline_trans_title'),
            desc: t('timeline_trans_desc'),
            year: "2014",
            image: "/img/about_us/timeline/Lion Trans - საწყობები.jpg"
        },
        {
            title: t('timeline_tserovani_title'),
            desc: t('timeline_tserovani_desc'),
            year: "2019",
            image: "/img/about_us/timeline/Tserovani Yard.jpg"
        },
    ]

    const bottomData = [
        {
            title: t('timeline_autopark_title'),
            desc: t('timeline_autopark_desc'),
            year: "2008",
            image: "/img/about_us/timeline/პირველი ავტოპარკი ნიუჯერსიში.jpg"
        },
        {
            title: t('timeline_offices_title'),
            desc: t('timeline_offices_desc'),
            year: "2017",
            image: "/img/about_us/timeline/ოფისები.jpg"
        },
        {
            title: t('timeline_terminal_title'),
            desc: t('timeline_terminal_desc'),
            year: "2022",
            image: "/img/about_us/timeline/Lion Terminal Poti.jpg"
        },
    ]
  return (
    <HistoryWallWrapper>
        <TopGroupWrapper>
            {topData.map((item,index) => (
                <HistoryCard key={index} reversed={true} item={item}/>
            ))}
        </TopGroupWrapper>
        <HistoryWallBanner>
            <img src="/img/banners/history-wall-bg.webp" alt="" />
        </HistoryWallBanner>
        <BottomGroupWrapper>
            {bottomData.map((item,index) => (
                <HistoryCard key={index} reversed={false} item={item}/>
            ))}
        </BottomGroupWrapper>
    </HistoryWallWrapper>
  )
}

export default HistoryWall