import React, { FC, memo, useCallback, useState } from 'react';
import { Col, Row, Typography, Image, Space, Spin } from 'antd';
import carShiftPattern from '@iconify-icons/mdi/car-shift-pattern';
import gasStation from '@iconify-icons/mdi/gas-station';
import roundFormatPaint from '@iconify-icons/ic/round-format-paint';
import engineIcon from '@iconify-icons/mdi/engine';
import baselineSpeed from '@iconify-icons/ic/baseline-speed';
import baselineBookmarkAdded from '@iconify-icons/ic/baseline-bookmark-added';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkForLotPage, getOdoValue } from '../../../utils/functions';
import { changeOpenQuickView, getQuickViewLotData } from '../../../store/slices/productSlice';
import { lotDataSelector } from '../../../store/slices/auctionSlice';
import NextLotBidSection from './NextLotBidSection';
import { NextLotContainer, ImageWrapper, BadgeWithTooltipNextLot } from './style';
import { LotTag } from '../FeaturedProductCard/style';
import Caption from './Caption';
import { Accent, Primary } from '../../shared-styles';
import { odometrIsKmSelector } from '../../../store/slices/initSlice';

const { Text } = Typography;

interface Props {
  lotId: number;
  laneId: number;
  idx: number;
  makeBid: (value: number, lotId: number, method: string) => void;
}

const NextLot: FC<Props> = ({ lotId, idx, makeBid, laneId }) => {
  const dispatch = useDispatch();

  const lotData = useSelector(lotDataSelector(laneId, lotId));
  const odometrIsKm = useSelector(odometrIsKmSelector);

  const getNextTime = useCallback(() => {
    if (idx === 0) return 'next';
    return `${idx * 3}m`;
  }, [idx]);

  const handleOpenQuickView = useCallback(() => {
    dispatch(changeOpenQuickView(lotId));
    dispatch(getQuickViewLotData(lotId));
  }, [dispatch, lotId]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleSetLoaded = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  };

  if (!lotData) return null;

  return (
    <NextLotContainer index={idx}>
      <Row gutter={8}>
        <ImageWrapper xs={6}>
          <Spin spinning={!loaded} size="small">
            <LotTag color={Accent}>
              <a
                href={getLinkForLotPage(lotData.lotNumber, lotData.make, lotData.model, lotData.year)}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="lot-number-link"
              >
                # {lotData.lotNumber}
              </a>
            </LotTag>
            <Image
              width="100%"
              src={lotData.mainImage || 'error'}
              fallback="img/noImg.jpg"
              alt="Lot image"
              onClick={handleOpenQuickView}
              preview={false}
              onLoad={handleSetLoaded}
            />
            <LotTag color={Primary}>{getNextTime()}</LotTag>
          </Spin>
        </ImageWrapper>
        <Col xs={18}>
          <Space direction="vertical" size="middle">
            <Row justify="space-between">
              <Col>
                <Text strong>
                  {lotData.year} {lotData.make} {lotData.model}
                </Text>
              </Col>
              <Col>
                {lotData.badges.map((badge) => (
                  <BadgeWithTooltipNextLot badge={badge} key={`${badge.name}_${lotId}`} />
                ))}
              </Col>
              <Col xs={24}>
                <Text>
                  {lotData.primaryDamage} / {lotData.secondaryDamage}
                </Text>
              </Col>
            </Row>
            <Row justify="space-between" className="font12" gutter={[8, 8]}>
              <Caption icon={carShiftPattern} value={lotData.transmission} />
              <Caption icon={gasStation} value={lotData.fuelType} />
              <Caption icon={roundFormatPaint} value={lotData.color} />
              <Caption icon={engineIcon} value={lotData.engineType} />
              <Caption icon={baselineBookmarkAdded} value={lotData.clearancePassed ? 'Cleared' : 'Before'} />
              <Caption icon={baselineSpeed} value={getOdoValue(+lotData.odometer, odometrIsKm, lotData.odometerBrand)} />
            </Row>
          </Space>
        </Col>
      </Row>

      <NextLotBidSection lotId={lotId} laneId={laneId} makeBid={makeBid} startingBid={+lotData.startingBid} />
    </NextLotContainer>
  );
};
export default memo(NextLot);
