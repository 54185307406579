import React, { useEffect, useState } from 'react';
import { Rate } from 'antd';
import {
  ContactInformationItem,
  ContactInformationWrapper,
  SellerImageWrapper,
  SellerInfoWrapper,
  SellerStarRateWrapper,
  SellerWrapper,
  Head,
  InformationWrapper,
  RateByStarWrapper,
  SkillItem,
  SkillList,
  Title,
  VideoWrapper,
  LeaveMessageWrapper,
  TabWrapper,
  Tab,
} from './style';
import { ReactSVG } from 'react-svg';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { StarFilled } from '@ant-design/icons';
import LeaveMessage from '../../shared/components/LeaveMessage';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReviewSection from '../../shared/components/ReviewSection';
import { addSaleExpertReview, getSaleExpertRate, getSaleExpertReviews } from '../../store/slices/dealerSlice';
import { useDispatch } from 'react-redux';

const SellerProfileImage = ({src, alt}: {src?: string, alt: string}) => {
  return (
    <SellerImageWrapper>
      {src 
      ? <img src={src} alt={alt} /> 
      : <img src={`/img/person.png`} alt={alt} />}
    </SellerImageWrapper>
  )
}


const SellerProfile = () => {
  const { md } = useBreakpoint()
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [seller, setSeller] = useState<any>(null)
  const [activeTab, setActiveTab] = useState(1)

  const [reviews, setReviews] = useState([])
  const [rate, setRate] = useState(0)
  
  const getSellerInfo = async () => {
    const sellerRate: any = await dispatch(getSaleExpertRate(id))
    const sellerReviews: any = await dispatch(getSaleExpertReviews(id))

    setReviews(sellerReviews)
    setRate(sellerRate)

  }

  const onAddReview = async (values: any) => {
    const data = {
      saleExpertId: id,
      ...values,
    }
    await dispatch(addSaleExpertReview(data))
    getSellerInfo()
  }

  useEffect(() => {
    fetch(`/sellers.json`).then(res => res.json())
    .then(r => setSeller(r.find((s: any) => s.id == id)))

    getSellerInfo()
  }, [id])
  

  return (
    <SellerWrapper>
        {!md ? <SellerProfileImage src={seller?.img} alt={ t(seller?.name) } /> : false} 
          <Head>
            <Title>{ t(seller?.name) }</Title>
              {md 
              ? <RateByStarWrapper>
                <Rate defaultValue={rate} disabled key={rate}/> 
              </RateByStarWrapper>
              : <SellerStarRateWrapper>
                {rate}
                <StarFilled />
              </SellerStarRateWrapper>}
          </Head>
          <SellerInfoWrapper>
            {md && <SellerProfileImage src={seller?.img} alt={ t(seller?.name) } />}

            <InformationWrapper>
              <p>{t(seller?.desc)}</p>
              <ContactInformationWrapper>
                <ContactInformationItem>
                  <ReactSVG src="/icons/sms-stroke.svg" className="svg-wrapper" />
                  { t(seller?.email) }
                </ContactInformationItem>
                <ContactInformationItem>
                  <ReactSVG src="/icons/call-stroke.svg" className="svg-wrapper" />
                  { t(seller?.phone) }
                </ContactInformationItem>
              </ContactInformationWrapper>
              {/* <SkillList>
                <SkillItem>Skill 1</SkillItem>
                <SkillItem>Skill 1</SkillItem>
                <SkillItem>Skill 1</SkillItem>
              </SkillList> */}
            </InformationWrapper>
          </SellerInfoWrapper>
          {/* {Seller?.videoURL && <VideoWrapper>
            <iframe width="100%" height="100%" src={Seller?.videoURL} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
          </VideoWrapper>} */}
          <TabWrapper>
            <Tab className={activeTab == 1 ? "active" : ""} onClick={() => setActiveTab(1)}>{t("seller_contact_expert")}</Tab>
            <Tab className={activeTab == 2 ? "active" : ""} onClick={() => setActiveTab(2)}>{t("seller_contact_review")}</Tab>
          </TabWrapper>
          {activeTab == 1 && <LeaveMessageWrapper>
            <LeaveMessage toEmail={seller?.email} />
          </LeaveMessageWrapper>}
          {activeTab == 2 && <ReviewSection reviews={reviews} onAddReview={onAddReview}/>}
    </SellerWrapper>
  );
};

export default SellerProfile;
