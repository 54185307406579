import React, { FC, memo, useCallback } from 'react';
import { Button, Row, TablePaginationConfig, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EyeFilled } from '@ant-design/icons';
import {
  changeOpenQuickView,
  getQuickViewLotData,
  lotsListLoadingSelector,
  lotsListSelector,
  lotsListTotalCountSelector,
} from '../../../store/slices/productSlice';
import { MyLotItem } from '../../../interfaces/Product';
import NoPreviewImage from '../NoPreviewImage';
import LocationForTable from '../LocationForTable';
import CustomTable from '../CustomTable';
import { Vehicle } from '../../../interfaces/FindVehicles';
import BadgeWithTooltip from '../BadgeWithTooltip';
import { CenterAlign } from '../../../pages/FindVehiclesPage/style';
import { getLinkForLotPage, getOdoValue } from '../../../utils/functions';
import { odometrIsKmSelector } from '../../../store/slices/initSlice';
import ColorSquare from '../AdvanceSearch/ColorSquare';
import QuickOverview from '../AdvanceSearch/QuickOverviewButton';
import ImageColumn from '../AdvanceSearch/ImageColumn';
import Odometer from '../AdvanceSearch/Odometer';
import VehicleNameColumn from '../AdvanceSearch/VehicleNameColumn';
import VehicleInfoColumn from '../AdvanceSearch/VehicleInfoColumn';
import ConditionColumnWrapper from '../AdvanceSearch/ConditionColumnWrapper';
import { CellContentText, CellContentWrapper } from '../AdvanceSearch/style';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
interface Props {
  columns: {
    title: string;
    dataIndex?: string;
    key?: string;
    render?: (lot: MyLotItem) => JSX.Element | string;
  }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTableChange: (pg: TablePaginationConfig, fltr: any, sort: any) => void;
}

const LotsTable: FC<Props> = ({ columns, onTableChange }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation()

  const lotsList = useSelector(lotsListSelector);
  const lotsListTotalCount = useSelector(lotsListTotalCountSelector);
  const lotsListLoading = useSelector(lotsListLoadingSelector);
  const odometrIsKm = useSelector(odometrIsKmSelector);

  const handleOpenQuickView = useCallback(
    (id) => {
      dispatch(changeOpenQuickView(id));
      dispatch(getQuickViewLotData(id));
    },
    [dispatch],
  );

  const currentColumns = [
    {
      title: t("table_col_image"),
      dataIndex: 'mainImage',
      key: 'mainImage',
      render: (mainImage: string, lot: MyLotItem) => (
        <ImageColumn to={`/lot/${lot.lotNumber}`} mainImage={mainImage} />
      ),
    },
    {
      title: t("table_col_vehicle_name"),
      dataIndex: 'VehicleName',
      key: 'VehicleName',
      render: (year: string,vehicle: Vehicle) => (
        <VehicleNameColumn vehicle={vehicle} />
      ),
    },
    {
      title: t("table_col_vehicle_info"),
      dataIndex: 'VehicleInfo',
      key: 'VehicleInfo',
      render: (color: string, vehicle: Vehicle ) => (
        <VehicleInfoColumn vehicle={vehicle} />
      ),
    },
    {
      title: t("table_col_vin"),
      dataIndex: 'vin',
      key: 'vin',
    },
    // {
    //   title: 'Color',
    //   dataIndex: 'color',
    //   key: 'color',
    //   render: (color: string) => (
    //     <CenterAlign>
    //       <ColorSquare color={color?.toLowerCase()} />
    //       <Text>{color}</Text>
    //     </CenterAlign>
    //   ),
    // },
    {
      title: t("table_col_conditions"),
      dataIndex: 'conditions',
      key: 'conditions',
      render: (v:string, vehicle: Vehicle) => (
        <CellContentWrapper>
          {
              vehicle.primaryDamage != t("lot_defect_type_New") 
              ? <CellContentText fw={400}>{t("lot_primary_damage")}: {vehicle.primaryDamage}</CellContentText>
              : <CellContentText fw={400}>{vehicle.primaryDamage}</CellContentText>
          }
          {
              vehicle.secondaryDamage 
              && vehicle.secondaryDamage != t("lot_defect_type_New")
              && <CellContentText fw={400}>{t("lot_secondary_damage")}: {vehicle.secondaryDamage}</CellContentText>
          }
          <CellContentText fw={400}>{vehicle.keys ? t("lot_haskeys") : t("lot_hasnotkeys")}</CellContentText>
        </CellContentWrapper>
      )
    },
    {
      title: t("table_col_location"),
      dataIndex: 'locationId',
      key: 'locationId',
      render: (locationId: number, vehicle: MyLotItem) => <LocationForTable locationId={locationId} vehicle={vehicle} />,
    },
    // {
    //   title: 'Odometr',
    //   dataIndex: 'odometer',
    //   key: 'odometer',

    //   sorter: (a: Vehicle, b: Vehicle) => +a.odometer - +b.odometer,
    //   render: (odometer: number, vehicle: Vehicle) => <Odometer value={getOdoValue(odometer, odometrIsKm, vehicle.odometerBrand)} />
    // },
    ...columns,
  ];
  return (
    <Row justify="center">
      {lotsList && (
        <CustomTable
          data={lotsList.map((item) => ({ ...item, key: item.id }))}
          columns={currentColumns}
          totalCount={lotsListTotalCount}
          isLoading={lotsListLoading}
          onChangeTable={onTableChange}
          scroll={{ x: 'max-content' }}
        />
      )}
    </Row>
  );
};

export default memo(LotsTable);
