/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { axiosInstance } from '../../core/axios';
import api from '../../core/api';
import { PopularVehicle, SliderImage } from '../../interfaces/Home';
import { Currency } from '../../interfaces/Dictionary';
import { Vehicle } from '../../interfaces/FindVehicles';
import { showNotification } from '../../utils/notify';

export interface InitState {
  sliderImages: SliderImage[];
  sliderImagesError: string;
  isSliderImagesLoading: boolean;

  currency: Currency;
  currencyError: string;
  isCurrencyLoading: boolean;

  popularVehicles: PopularVehicle[];
  popularVehiclesError: string;
  isPopularVehiclesLoading: boolean;

  featuredProducts: Vehicle[];
  featuredProductsError: string;
  isFeaturedProductsLoading: boolean;
  openSavedSearch: boolean;
  odometrIsKm: boolean;
  isVincodeSearchAutorized: boolean;
}

export const initialState: InitState = {
  sliderImages: [],
  sliderImagesError: '',
  isSliderImagesLoading: false,

  currency: {
    type: 'usd',
    value: 0,
  },
  currencyError: '',
  isCurrencyLoading: false,

  popularVehicles: [],
  popularVehiclesError: '',
  isPopularVehiclesLoading: false,

  featuredProducts: [],
  featuredProductsError: '',
  isFeaturedProductsLoading: false,

  openSavedSearch: false,
  odometrIsKm: false,
  isVincodeSearchAutorized: Intl.DateTimeFormat().resolvedOptions().timeZone != "Asia/Tbilisi" || localStorage.getItem("isVincodeSearchAutorized") == "true",
};

export const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    currencyRequest: (state) => {
      state.isCurrencyLoading = true;
    },
    currencySuccess: (state, action) => {
      state.isCurrencyLoading = false;
      state.currency.value = action.payload.value;
    },
    currencyFailure: (state, action: PayloadAction<string>) => {
      state.isCurrencyLoading = false;
      state.currencyError = action.payload;
    },
    sliderImagesRequest: (state) => {
      state.isSliderImagesLoading = true;
    },
    sliderImagesSuccess: (state, action) => {
      state.isSliderImagesLoading = false;
      state.sliderImages = action.payload;
    },
    sliderImagesFailure: (state, action: PayloadAction<string>) => {
      state.isSliderImagesLoading = false;
      state.sliderImagesError = action.payload;
    },
    popularVehiclesRequest: (state) => {
      state.isPopularVehiclesLoading = true;
    },
    popularVehiclesSuccess: (state, action) => {
      state.isPopularVehiclesLoading = false;
      state.popularVehicles = action.payload;
    },
    popularVehiclesFailure: (state, action: PayloadAction<string>) => {
      state.isPopularVehiclesLoading = false;
      state.popularVehiclesError = action.payload;
    },
    featuredProductsRequest: (state) => {
      state.isFeaturedProductsLoading = true;
    },
    featuredProductsSuccess: (state, action) => {
      state.isFeaturedProductsLoading = false;
      state.featuredProducts = action.payload;
    },
    featuredProductsFailure: (state, action: PayloadAction<string>) => {
      state.isFeaturedProductsLoading = false;
      state.featuredProductsError = action.payload;
    },
    setCurrencyType: (state, action) => {
      state.currency.type = action.payload;
    },
    changeOpenSavedSearch: (state, action) => {
      state.openSavedSearch = action.payload;
    },
    changeOdometrIsKm: (state, action) => {
      state.odometrIsKm = action.payload;
    },
    changeIsVincodeSearchAutorized: (state, action) => {
      state.isVincodeSearchAutorized = action.payload
    }
  },
});

export const {
  currencyRequest,
  currencySuccess,
  currencyFailure,
  sliderImagesRequest,
  sliderImagesSuccess,
  sliderImagesFailure,
  popularVehiclesRequest,
  popularVehiclesSuccess,
  popularVehiclesFailure,
  featuredProductsRequest,
  featuredProductsSuccess,
  featuredProductsFailure,
  setCurrencyType,
  changeOpenSavedSearch,
  changeOdometrIsKm,
  changeIsVincodeSearchAutorized
} = initSlice.actions;

export const changeCurrencyType = (val: 'usd' | 'gel'): AppThunk => async (dispatch) => {
  dispatch(setCurrencyType(val));
};

export const getCurrency = (): AppThunk => async (dispatch) => {
  dispatch(currencyRequest());
  try {
    const res = await axiosInstance.get(api.GetCurrency);
    dispatch(currencySuccess(res.data));
  } catch (error: any) {
    dispatch(currencyFailure(error.message));
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getAllSliderImages = (language: 'en' | 'ru' | 'ka'): AppThunk => async (dispatch) => {
  dispatch(sliderImagesRequest());
  try {
    const res = await axiosInstance.get(api.GetAllSliderImages, {
      params: {
        language,
      },
    });
    dispatch(sliderImagesSuccess(res.data));
  } catch (error: any) {
    dispatch(sliderImagesFailure(error.message));
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getAllPopularVehicles = (): AppThunk => async (dispatch) => {
  dispatch(popularVehiclesRequest());
  try {
    const res = await axiosInstance.get(api.LoadAllPopularVehicles);
    dispatch(popularVehiclesSuccess(res.data));
  } catch (error: any) {
    dispatch(popularVehiclesFailure(error.message));
    showNotification('error', error.response.data.errorMessage);
  }
};

export const getAllFeaturedProducts = (): AppThunk => async (dispatch) => {
  dispatch(featuredProductsRequest());
  try {
    const res = await axiosInstance.get(api.GetAllFeaturedProducts);
    dispatch(featuredProductsSuccess(res.data));
  } catch (error: any) {
    dispatch(featuredProductsFailure(error.message));
    showNotification('error', error.response.data.errorMessage);
  }
};

// SELECTORS
export const currencySelector = (state: RootState) => state.init.currency;
export const openSavedSearchSelector = (state: RootState) => state.init.openSavedSearch;
export const sliderImagesSelector = (state: RootState) => state.init.sliderImages;
export const isSliderImagesLoadingSelector = (state: RootState) => state.init.isSliderImagesLoading;
export const popularVehiclesSelector = (state: RootState) => state.init.popularVehicles;
export const isPopularVehiclesLoadingSelector = (state: RootState) => state.init.isPopularVehiclesLoading;
export const featuredProductsSelector = (state: RootState) => state.init.featuredProducts;
export const featuredProductsLoadingSelector = (state: RootState) => state.init.isFeaturedProductsLoading;
export const odometrIsKmSelector = (state: RootState) => state.init.odometrIsKm;
export const isVincodeSearchAutorizedSelector = (state: RootState) => state.init.isVincodeSearchAutorized;

export default initSlice.reducer;
