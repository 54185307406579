import { Option } from 'antd/lib/mentions';
import React, { useEffect, useRef } from 'react';
import { getCurrency, changeCurrencyType, changeOdometrIsKm } from '../../../../store/slices/initSlice';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { StyledSelect } from './style';
import { SelectValue } from 'antd/lib/select';

const SelectCurrency = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(['currency']);
  const selectRef = useRef<any>(null);

  const handleChangeCurrency = (value: SelectValue) => {
    dispatch(getCurrency());
    dispatch(changeCurrencyType(value as 'usd' | 'gel'));
    setCookie('currency', value, { path: '/' });
  };

  const handleScroll = () => {
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  useEffect(() => {
    dispatch(changeCurrencyType(cookies.currency));
    dispatch(getCurrency());
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [cookies.currency, dispatch]);

  return (
    <StyledSelect ref={selectRef} defaultValue={cookies.currency || 'usd'} key={cookies.currency} onChange={handleChangeCurrency}>
      <Option value="usd">$</Option>
      <Option value="gel">₾</Option>
    </StyledSelect>
  );
};

export default SelectCurrency;
