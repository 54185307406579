import React, { useCallback } from 'react'
import { LotCardsList } from './style'
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenQuickView, getQuickViewLotData, lotsListLoadingSelector, lotsListSelector, lotsListTotalCountSelector } from '../../../store/slices/productSlice';
import { odometrIsKmSelector } from '../../../store/slices/initSlice';
import LotCard from './LotCard';
import { PaginationWrapper } from '../CustomTable/style';
import CustomPagination from '../CustomPagination';

interface LotMobileListProps {
    statusKey?: string;
    tableSettings: any;
    handleChangePagination: any;
    footer?: any;
    lotsList: any[];
    lotsListTotalCount: number
}

const LotMobileList = ({tableSettings, statusKey, handleChangePagination,footer, lotsListTotalCount, lotsList}: LotMobileListProps) => {

    const paginationProps = {
        showSizeChanger: true,
        total: lotsListTotalCount,
        current: tableSettings.Page,
        pageSize:  tableSettings.PageItemsCount,
    }
    

  return (
    <>
        <LotCardsList>
            {lotsList.map((vehicle: any) => (
            <LotCard 
                vehicle={vehicle} 
                key={vehicle.id} 
                status={statusKey && vehicle[statusKey]} 
                footer={footer}    
            />
            ))}
        </LotCardsList>
        <PaginationWrapper>
            <CustomPagination {...paginationProps} onChange={handleChangePagination}/>
        </PaginationWrapper>
    </>
  )
}

export default LotMobileList