import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { Vehicle } from '../interfaces/FindVehicles/index';
import api from '../core/api';
import { BidByStatusInformation, MyLotItem } from '../interfaces/Product';
import { Payments } from '../interfaces/Payments';
import { Currency, DictionaryItem } from '../interfaces/Dictionary';

export const getTimeZone = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs.tz.guess();
};

export const getYearPeriod = () => {
  const years: { key: string; title: string }[] = [];
  const nowYear = new Date().getFullYear();
  for (let i = nowYear - 100; i <= nowYear; i++) years.push({ key: `${i}`, title: `${i}` });
  return years.sort((a, b) => +b.key - +a.key);
};

export const getYearRange = (from: number, to: number) => {
  const years: number[] = [];
  for (let i = from; i <= to; i++) years.push(i);
  return years;
};

export const getDataForFilter = (array: { id: number; value: string; lanValue: string }[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getItems = (result: any, object: any) => {
    if (object.value) {
      const title = object.lanValue || object.value.toLowerCase();
      result.push({ title, key: object.value.toLowerCase() });
      return result;
    }
    if (Array.isArray(object.nodes)) {
      const nodes = object.nodes.reduce(getItems, []);
      if (nodes.length) result.push({ ...object, nodes });
    }
    return result;
  };
  return array.reduce(getItems, []);
};


export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const getApiForLots = (type: string) => {
  let url = '';
  switch (type) {
    case 'onsalelist':
      url = api.GetLotsOnSale;
      break;
    case 'bidapproval':
      url = api.GetBidApprovalItemsForSeller;
      break;
    case 'soldlots':
      url = api.GetSoldLots;
      break;
    case 'waitauctionlots':
      url = api.GetWaitAuctionLots;
      break;
    case 'myoffers':
      url = api.GetProductOffersByProductOwnerId;
      break;
    default:
      url = api.GetLotsOnCheck;
  }
  return url;
};

export const getLocation = (id: number, vehicle: Vehicle | BidByStatusInformation | MyLotItem | Payments) => {
  const location = vehicle.location ? `(${vehicle.location || ''})` : '';
  switch (id) {
    case 2:
      return { name: vehicle.yardLocationName, locationType: location };
    case 3:
      return { name: vehicle.outsideLocation, locationType: location };
    default:
      return {
        name: vehicle.transitDefinitionPortName,
        locationType: location,
      };
  }
};

export const checkIsLive = (time: string) => {
  const date = new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const newDate = new Date().setHours(hours, minutes, seconds);
  return new Date(newDate).getTime() - Date.now() <= 3600000;
};

export const getLinkForLotPage = (id: string, make: string, model: string, year: number) => {
  const pathLang = location.pathname.split('/')[1];
  const lang = (pathLang === 'en' || pathLang === 'ru') ? '/'+pathLang : '';
  return `${lang}/lot/${id}/${make.replace(/\s/g, '-')}-${model.replace(/\s/g, '-')}-${year}`;
};

export const getBidStep = (bid: number) => {
  if (bid >= 0 && bid < 100) {
    return 10;
  }
  if (bid >= 100 && bid < 1000) {
    return 25;
  }
  if (bid >= 1000 && bid < 5000) {
    return 50;
  }
  if (bid >= 5000 && bid < 10000) {
    return 100;
  }
  if (bid >= 10000 && bid < 50000) {
    return 250;
  }
  if (bid >= 50000) {
    return 500;
  }
  return 0;
};

export const getBodyStyles = (
  aStyleList: {
    automobileType: DictionaryItem;
    bodyStyleTypes: DictionaryItem[];
  }[],
) => {
  const treeData = aStyleList?.map((style) => ({
    title: style.automobileType.lanValue,
    value: style.automobileType.value.toLowerCase(),
    key: style.automobileType.value.toLowerCase(),
    checkable: true,
    selectable: false,
    children: style.bodyStyleTypes.map((type) => ({
      title: type.lanValue,
      value: `${style.automobileType.value.toLowerCase()}_${type.value.toLowerCase()}`,
      key: `${style.automobileType.value.toLowerCase()}_${type.value.toLowerCase()}`,
    })),
  }));
  return treeData;
};

export const getLocations = (locations: any[]) => {
  if (!Array.isArray(locations)) {
    return [];
  }

  const treeData = locations.map((countryItem, index) => {
    if (!countryItem || !countryItem.country || !Array.isArray(countryItem.models)) {
      return null;
    }

    return {
      title: countryItem.country,
      value: countryItem.country.toLowerCase(),
      key: countryItem.country.toLowerCase(),
      checkable: true,
      selectable: false,
      children: countryItem.models.map((location: { objectName: any; objectId: any; }) => ({
        title: location.objectName,
        value: `${countryItem.country.toLowerCase()} (${location.objectName.toLowerCase()})`,
        key: `${countryItem.country.toLowerCase()} (${location.objectName.toLowerCase()})`,
      })),
    };
  }).filter(Boolean);

  return treeData;
};



export const getDamages = (
  damages: {
    damageTypes: DictionaryItem[];
    defectStyle: DictionaryItem;
  }[],
) => {
  const treeData = damages?.map((typeItem) => ({
    title: typeItem.defectStyle.lanValue,
    value: typeItem.defectStyle.value.toLowerCase(),
    key: typeItem.defectStyle.value.toLowerCase(),
    checkable: true,
    selectable: false,
    children: typeItem.damageTypes.map((type) => ({
      title: type.lanValue,
      value: `${typeItem.defectStyle.value}_${type.value}`.toLowerCase(),
      key: `${typeItem.defectStyle.value}_${type.value}`.toLowerCase(),
    })),
  }));
  return treeData;
};

export const useScrollHandle = () => {
  const [currentScroll, setCurrentScroll] = useState<number>();
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const handleScroll = (): void => {
      setCurrentScroll(window.pageYOffset || document.documentElement.scrollTop);
    };
    const scrollListener = (): void => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => handleScroll(), 150);
    };
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return currentScroll;
};

export const disableAutocomplete = () => {
  const searchers = document.getElementsByClassName('ant-select-selection-search-input') as HTMLCollectionOf<Element>;
  for (const element of searchers) {
    element.setAttribute('autocomplete', 'new-password');
  }
};

export const getPrice = (currency: Currency, price: number) =>
  currency.type === 'usd' ? ` $ ${price || 0}` : ` GEL ${((price || 0) * currency.value).toFixed(2)}`;

export const getOdoValue = (odometer: number, isKm: boolean, brand: string) =>
  `${isKm ? +((odometer || 0) * 1.60934).toFixed() : odometer || 0} (${isKm ? 'km' : 'mi'}) ${brand || ''}`;

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const langPrefix = (href: string) => {
  const path = location.pathname.split('/')[1];
  const pathLang = (path === 'en' || path === 'ru') ? path : '';
  const languageSlug = pathLang ? `/${pathLang}` : '';
  return `${languageSlug}${href}`;
};

export function getBase64(img: any) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
      resolve(reader.result);
      });
      reader.readAsDataURL(img);
  });
}