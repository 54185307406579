import React from 'react'
import {  AlertWrapper } from './style'
import { ReactSVG } from 'react-svg'

export enum AlertTypes {
    INFO,
}

export enum AlertVariants {
    GRAY,
}

interface AlertProps {
    type: AlertTypes,
    text: string,
    variant?: AlertVariants
}

const Alert = ({text, variant}: AlertProps) => {
  return (
    <AlertWrapper variant={variant}>
        <ReactSVG src='/icons/info-circle.svg' className='svg-wrapper' />
        <span>{text}</span>
    </AlertWrapper>
  )
}

export default Alert