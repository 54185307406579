import styled from 'styled-components';

export const CollapsePrice = styled.div`
  display: block;
  padding-bottom: 30px;
    .ant-tooltip-inner {
        background-color: #0000;
        color: #000;
        box-shadow: unset;
    }
    .ant-tooltip-arrow {
        display: none;
    }
`;
