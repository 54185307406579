import React, { useEffect } from 'react'
import { CompanyCardList, Title, Wrapper } from './style'
import CompanyCard from './CompanyCard'
import { useDispatch, useSelector } from 'react-redux'
import { dealerListSelector, getDealers } from '../../store/slices/dealerSlice'

const OperatedCompaniesPage = () => {
  const dispatch = useDispatch()
  const dealerList = useSelector(dealerListSelector)

  useEffect(() => {
    dispatch(getDealers(true))
  }, [dispatch])

  return (
    <Wrapper>
        <Title>Operated by <span>Lion Trans</span></Title>
        <CompanyCardList>
          {dealerList.map(company => <CompanyCard key={company.dealerId} company={company} />)}
        </CompanyCardList>
    </Wrapper>
  )
}

export default OperatedCompaniesPage