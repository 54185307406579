import styled from 'styled-components';

export const ContactFormWrapper = styled.div`
  display: block;
  position: absolute;
  z-index: 1;
  right: 5%;
  top: 5%;
  background: #ffffff;
  box-shadow: 0px 0px 30px #efefef;
  border-radius: 10px;
  padding: 15px 30px 25px 30px;

  @media (max-width: 958px) {
    position: relative;
    margin: auto;
    top: 10px;
    right: 0;
    width: 90%;
    margin-bottom: -20px;
  }
`;

export const BannerWrapper = styled.div`
  height: auto;
  max-height: calc(100vh - 25rem);
  min-height: 35rem;
  width: 100%;
  aspect-ratio: 2.7;
  overflow: hidden;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 37, 42, 0.46);
  }
  @media (max-width: 767px) {
    height: 6.25rem;
  }
`;

export const Container = styled.div`
  max-width: 63.5rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.625rem;
`;

export const PageTitle = styled.h1`
  font-family: 'main';
  &:lang(ka) {
    font-family: 'arial-caps';
  }
  position: absolute;
  z-index: 1;
  font-size: 2rem;
  left: 3%;
  top: 3.3%;
  color: #ce2424;
  user-select: none;
  @media (max-width: 767px) {
    left: 0;
    top: 0;
    text-align: center;
    position: relative;
    font-size: 2rem;
    padding-top: 1rem;
    color: #ce2424;
  }
`;

export const DescriptionSectionWrapper = styled.section`
  margin-top: 3.25rem;
`;

export const Description = styled.p`
  font-family: 'main';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #1b153d;
  margin-bottom: 1.5rem;
  b {
    color: #ce2424;
  }
  @media (max-width: 767px) {
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
  }
`;

export const HistoryWallWrapper = styled.div`
  margin: 5rem 0 6rem;
  @media (max-width: 767px) {
    margin: 1.5rem 0rem;
  }
`;

export const MapWrapper = styled.div`
  max-width: 100rem;
  margin: 0 auto;
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
  position: relative;
  .svg-wrapper {
    width: 1220px;
    height: 720px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    /* height: 375px; */
    & > svg {
      width: 1220px;
      height: 850px;
    }
  }
`;

interface PinWrapperProps {
  left: number;
  top: number;
}

export const PinWrapper = styled.div<PinWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  position: absolute;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
  span {
    border: 1px solid rgba(226, 225, 228, 1);
    background-color: #fff;
    color: #000;
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: main;
    font-weight: 600;
    padding: 0.125rem 0.325rem;
    border-radius: 0.5rem;
    position: relative;
    z-index: 8;
    transition: 0.2s;
  }
  .pin-icon {
    div,
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &:hover {
    z-index: 9;
    span {
      background-color: #ce2424;
      color: #fff;
      border-color: #ce2424;
      cursor: pointer;
    }
  }
`;

export const CountryPopup = styled.div`
  padding: 1.5rem 1.875rem;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 0px 50px #e8e8e8;
  border-radius: 8px;
  min-width: 15rem;
  position: absolute;
  left: 2rem;
  top: 5%;
  z-index: 9;
  max-height: 450px;
  overflow-y: auto;

  .close {
    position: absolute;
    right: 10%;
    top: 10%;
    cursor: pointer;
  }
  h2 {
    font-family: 'main';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: #ce2424;
    &::after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background: #ce2424;
      border-radius: 4px;
      margin-top: 0.5rem;
    }
  }
  nav {
    display: flex;
    flex-direction: column;
  }
  a {
    font-family: 'main';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.875rem;
    color: #1b153d;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    span {
      padding-left: 1rem;
      font-size: 0.75rem;
      line-height: 0.875rem;
      max-width: 300px;
    }
  }
  @media (max-width: 991px) {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 0px 50px #e8e8e8;
    border-radius: 8px;
    padding: 0.75rem;
    width: 28%;
    min-width: auto;
    z-index: 1;
    h2 {
      font-size: 1rem;
      line-height: 1.875rem;
      color: #e42346;
    }
    a {
      font-size: 0.875rem;
      line-height: 1.875rem;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
  @media (max-width: 958px) {
    top: 6%;
    width: 80%;
  }
`;

export const CityMapWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 180px);
  min-height: 600px;
  img[alt='Google'] {
    display: none;
  }
  .gmnoprint {
    display: none;
  }
  .gm-style-moc {
    display: none;
  }
`;

export const MapContainer = styled.div`
  img[alt='Google'] {
    display: none;
  }
  .gmnoprint {
    display: none;
  }
  .gm-style-moc {
    display: none;
  }
  width: 100%;
  height: calc(100vh - 180px);
  min-height: 600px;
`;

export const TeamTitle = styled.h1`
  font-family: 'main';
  &:lang(ka) {
    font-family: 'arial-caps';
  }
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 3.75rem;
  text-align: center;
  color: #1b153d;
  margin-bottom: 3rem;
  @media (max-width: 580px) {
    font-size: 1.325rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
  }
`;

export const TeamSliderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem;
  }
`;
export const ContactWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 730px;
  background-color: #efefef;
`;

export const ContactInfoWrapper = styled.div`
  position: absolute;
  width: 94%;
  min-height: 160px;
  background-color: #fff;
  padding: 20px 100px 20px 100px;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1387px) {
    padding-left: 30px;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ContactInfoMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3vw;
  flex-wrap: wrap;

  .location {
    margin-right: 25px;

    .icon {
      margin-right: 10px;
    }

    h4 {
      color: #ce2424;
      font-size: 20px;
      font-weight: 800;
    }

    p {
      color: #141719;
      font-size: 12px;
      svg {
        margin: 0 9px -3px 0;
      }
    }
  }

  @media (max-width: 958px) {
    width: 100%;
    padding-left: 20px;
    gap: 1rem;
    flex-direction: column;
    place-content: flex-start;
    align-items: baseline;
    .location {
      margin-right: 0;
    }
  }
`;

export const ContactInfoSocial = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3vw;
  flex-wrap: wrap;
  position: relative;
  @media (max-width: 1520px) {
    gap: 2vw!important;
  }
  &::before {
    content: '';
    height: 60px;
    width: 1px;
    position: absolute;
    left: -100px;
    background: #d8d8d8;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: 1490px) and (min-width: 1387px) {
    &::before {
      left: calc(-9px - ((100vw - 1387px) / ((1490 - 1264) * 2) * (100 - (-100))));
    }
  }

  @media (max-width: 1387px) {
    &::before {
      height: 1px;
      width: 100%;
      position: absolute;
      background: #d8d8d8;
      top: 0;
      left: 0;
      margin-top: -7px;
      transform: translateY(0);
    }
  }
  .social {
    display: flex;
    align-items: center;

    p {
      margin: 0 0 0 5px;
    }

    a {
      margin: 0 0 0 5px;
      color: #141719;
    }
  }

  @media (max-width: 958px) {
    width: 100%;
    padding-left: 20px;
    flex-direction: column;
    place-content: flex-start;
    align-items: baseline;
    .social {
      margin-right: 0;
    }
  }
`;
