import { Col } from "antd";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const CardWrapper = styled.div`
    border: 1px solid #EAEAEA;
    border-radius: 0.5rem;
`

interface CardHeaderProps {
  buttonDisplay?: boolean
}

export const CardHeader = styled.div<CardHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F6F6F6;
  border: 1px solid #EAEAEA;
  padding: 1.5rem 1.25rem;
  span{
    font-family: 'main';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.25rem;
    text-transform: capitalize;
    color: #1B153D;
  }
  button{
    display: none;
    div, svg{
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    path{
      stroke: #1B153D;
    }
  }
  @media(max-width: 767px){
    padding: 1rem;
    span{
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
    button{
      display: block;
    }
  }
`

interface CardBodyProps {
  isVisible: boolean;
}

export const CardBody = styled.div<CardBodyProps>`
  padding: 0.25rem 1rem 1.75rem;
  @media(max-width: 767px){
    display: ${p => p.isVisible ? "block": "none"};
  }
`

interface RowProps {
    direction?: string;
    justify?: string;
}

export const Row = styled.div<RowProps>`
    display: flex;
    justify-content: ${p => p.justify || "space-between"};
    border-bottom: 1px solid #EAEAEA;
    padding: 1.25rem 0;
    &:last-child{
        border-bottom: unset;
        padding-bottom: 0;
    }
    @media(max-width: 767px){
      padding: 1rem 0;
    }
`

export enum TextVariant {
    DANGER,
    GRAY
}

interface TextProps {
    variant?: TextVariant;
    fw?: number
}

export const CustCol = styled(Col)`
    font-size: 0.90rem!important;
`
export const Text = styled.span<TextProps>`
    font-family: 'main';
    font-weight: ${p => p.fw || 400};
    font-size: 0.90rem;
    line-height: 0.875rem;
    color: #1B153D;
    ${p => p.variant == TextVariant.DANGER && css`
        color: #CE2424;
    `}

    ${p => p.variant == TextVariant.GRAY && css`
      color: #9095A6;
    `}
` 

export const LinkText = styled(Link)`
    font-family: 'main';
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-decoration-line: underline;
    color: #CE2424;
`