import { Link } from "react-router-dom";
import styled from "styled-components";

export const LogoWrapper = styled(Link)`
  width: 3.5rem;
  height: fit-content;
  flex-shrink: 0;
  img {
    width: 100%;
    object-fit: contain;
  }
  @media(max-width: 767px){
    width: 2.625rem;
    margin-right: auto;
  }
`;