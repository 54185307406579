import React, { FC, useEffect, useMemo, useState } from 'react';
import { Pagination } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import CustomPagination from '../../../shared/components/CustomPagination';
import { PaginationWrapper } from '../style';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Meta from '../../../shared/components/Meta';

interface SearchPaginationProps {
  total: number;
}

const SearchPagination: FC<SearchPaginationProps> = ({ total }) => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const { md } = useBreakpoint();
  const DEFAULT_PAGE_COUNT = 20;
  const totalCount = total;

  const [tableSettings, setTableSettings] = useState<{
    Page: number | string;
    PageItemsCount: number | string;
    SortField: string;
    SortOrder: string;
  }>({
    Page: Number(params.get("page")) || 1,
    PageItemsCount: Number(params.get("itemscount")) || DEFAULT_PAGE_COUNT,
    SortField: '',
    SortOrder: 'Desc',
  });

  const handleChangePagination = (page: number, pageSize?: number | undefined) => {
    page !== 1 ? params.set('page', page.toString()) : params.delete('page');
    pageSize !== DEFAULT_PAGE_COUNT && pageSize ? params.set('itemscount', pageSize.toString()) : params.delete('itemscount');

    history.push({
      pathname: history.location.pathname,
      search: params.toString(),
    });

    setTableSettings({
      ...tableSettings,
      Page: page || 1,
      PageItemsCount: pageSize || DEFAULT_PAGE_COUNT,
    });

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    setTableSettings({
      ...tableSettings,
      Page: Number(params.get("page")) || 1,
      PageItemsCount: Number(params.get("itemscount")) || DEFAULT_PAGE_COUNT,
    });
  }, [location.search]);

  useEffect(() => {
    if (md === false) {
      params.set('itemscount', "10");

      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      });
    }
  }, [md]);

  const paginationProps = {
    showSizeChanger: true,
    total: totalCount,
    current: tableSettings.Page,
    pageSize: tableSettings.PageItemsCount,
  };

  const currentPage = tableSettings.Page;
  const prevPage = Number(currentPage) - 1 > 0 ? (Number(currentPage) - 1).toString() : null;
  let nextPage = (Number(currentPage) + 1).toString();
  const maxPage = Math.ceil(Number(totalCount) / Number(tableSettings.PageItemsCount));
  if (Number(nextPage) > maxPage) { nextPage = ''; }

  return (
    <>
      <Meta prev={prevPage} next={nextPage} />
      <PaginationWrapper>
        <CustomPagination {...paginationProps} onChange={handleChangePagination} key={DEFAULT_PAGE_COUNT} />
      </PaginationWrapper>
    </>
  );
};

export default SearchPagination;
