import React, { FC, memo } from 'react';
import { FileTextOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { ImgContainer } from './style';
import { ReactSVG } from 'react-svg';

interface Props {
  image: string | null;
  height: string;
  borderRadius?: boolean;
  children?: JSX.Element;
  action?: () => void;
  isVideo?: boolean;
  className?: string
}

const BlogImage: FC<Props> = ({ children, action, image, isVideo, height, borderRadius, className }) => (
  <>
    {image && (
      <ImgContainer 
        url={image} 
        height={height} 
        borderRadius={borderRadius} 
        className={className || ""}
      >
        {isVideo && (
          <div onClick={action}>
            <img
              src='/img/video icon-01.png' 
              className={`svg-wrapper ${(height === '500px') ? "large" : "small"}`}
            />
            {children}
          </div>
        )}
      </ImgContainer>
    )}
  </>
);

export default memo(BlogImage);
