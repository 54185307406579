import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { allBlogsSelector, getAllBlogs, isLoadingSelector } from '../../store/slices/blogSlice';
import BlogCard, { BlogCardLoadingSkeleton } from '../../shared/components/Blog/BlogCard';
import { ScTitle } from '../style';
import ContentWrapper from '../../shared/components/ContentWrapper';
import { StyledCard } from '../../shared/components/Blog/style';
import { BlogList, BlogPageWrapper, BlogTitle } from './style';
import { PageTitle } from '../../shared/sharedComponent';
import { WebsiteSchema } from '../../shared/components/Schema';
import Meta from '../../shared/components/Meta';

const BlogsPage: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies] = useCookies(['language']);

  useEffect(() => {
    dispatch(getAllBlogs(cookies.language));
  }, [cookies.language, dispatch]);

  const allBlogs = useSelector(allBlogsSelector);
  const isLoading = useSelector(isLoadingSelector);

  return (
    <><Meta
    title={t("meta_blog_title")}
    description={t("meta_blog_desc")} />
    <WebsiteSchema />
    <BlogPageWrapper>
      <PageTitle>{t("blog_page_title")}</PageTitle>

      <BlogList>
        {isLoading && Array(4).fill("").map((_, i) => (
          <BlogCardLoadingSkeleton key={i} />
        ))}

        {allBlogs.map((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </BlogList>
    </BlogPageWrapper>
    </>)
};

export default memo(BlogsPage);
