import React from 'react'
import { BannerContent, BannerDesc, BannerImage, BannerItem, BannerLink, BannerTitle, BannerWrapper } from './style'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { langPrefix } from '../../../utils/functions';

const MainBanner = () => {
  const { lg } = useBreakpoint()
  const { t } = useTranslation()

  return (
    <BannerWrapper>
        <BannerItem>
            <BannerContent>
                {/* <BannerTitle><span>TESLA</span> <b>-</b> ELECTRIC CARS </BannerTitle> */}
                <BannerTitle>{t("nav_main_menu_sell_vehicle_electric")}</BannerTitle>
                <BannerDesc>
                    {t("electric_banner_description")}
                </BannerDesc>
                <BannerLink to={langPrefix("/electric-cars-search")}>{t("Discover More")}</BannerLink>
            </BannerContent>
            {lg 
            ? <BannerImage src='/img/electric-cars-banner.png' />
            : <BannerImage src='/img/electic-cars-mobile-banner.png' />}
        </BannerItem>
    </BannerWrapper>
  )
}

export default MainBanner