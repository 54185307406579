import React, { useState } from 'react';
import Map from './Map';
import CityMap from './CityMap';
import ContactInfo from './ContactInfo';
import { ContactFormWrapper, ContactWrapper, PageTitle } from './style';
import ContactForm from '../../shared/components/ContactForm';
import { ContactPageSchema } from '../../shared/components/Schema';

import i18n from '../../i18n';
import Meta from '../../shared/components/Meta';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const ContactPage = () => {
  const [isSitiSelected, setIsSitiSelected] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const { md } = useBreakpoint();
  const onCityHandle = (office: any) => {
    setSelectedOffice(office);
    setIsSitiSelected(true);
  };

  const onPrevBtnHandle = () => {
    setSelectedOffice(null);
    setIsSitiSelected(false);
  };

  return (
    <>
      <Meta
        title={i18n.t("meta_contact_title")}
        description={i18n.t("meta_contact_desc")}
      />
    <ContactPageSchema />
      <ContactWrapper>
        {isSitiSelected ? (
          selectedOffice && <CityMap onPrevBtnHandle={onPrevBtnHandle} country={selectedOffice} />
        ) : (
          <>
            <PageTitle>{i18n.t("Contact")}</PageTitle>
            <ContactFormWrapper>
              <ContactForm />
            </ContactFormWrapper>
            <Map onCityHandle={onCityHandle} />
          </>
        )}
        <ContactInfo />
      </ContactWrapper>
    </>
  );
};

export default ContactPage;
