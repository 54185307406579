import React, { FC, useEffect, memo, useCallback, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useCookies } from 'react-cookie';
import { productGeneralDictionariesByLanguageSelector, getMakeModelsByVehicleType } from '../../store/slices/dictionarySlice';
import { getFindVehicles, totalCountSelector } from '../../store/slices/findVehiclesSlice';
import BannerContainer from './containers/BannerContainer';
import ResultContainer from './containers/ResultContainer';
import FilterContainer from './containers/FilterContainer';
import { getTransitAndYardDefinition, transitAndYardDefinitionSelector } from '../../store/slices/productSlice';
import SearchFilterContainer from '../../shared/components/SearchFilterContainer';
import PopularFilters from './components/PopularFilters';
import BannerFindForm from './components/BannerFindForm';
import AdditionalBannerFindForm from './components/AdditionalBannerFindForm';
import { ActionButtonWrapper, AdditionalBannerFindFormWrapper, AdditionalBannerTitle, BannerFindFormContainer, ClassicSearchPageWrapper, MobileFilterWrapper, PopularFiltersWrapper, SubmitButton, ToggleAdditionalFilter } from './style';
import { ReactSVG } from 'react-svg';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import MobileFilter from './components/MobileFilter';
import MobileFilterHeader from '../../shared/components/MobileFilterHeader';
import { render } from 'react-dom';
import Meta from '../../shared/components/Meta';
import i18n from '../../i18n';

const steps = [
  {
    selector: '.first-classic-search-step',
    content: 'This is my first Step',
  },
  {
    selector: '.second-classic-search-step',
    content: 'This is my second Step',
  },
  {
    selector: '.third-classic-search-step',
    content: 'This is my third Step',
  },
  {
    selector: '.fourth-classic-search-step',
    content: 'This is my fourth Step',
  },
  {
    selector: '.fifth-classic-search-step',
    content: 'This is my fifth Step',
  },
  {
    selector: '.sixth-classic-search-step',
    content: 'This is my sixth Step',
  },
  {
    selector: '.seventh-classic-search-step',
    content: 'This is my seventh Step',
  },
  {
    selector: '.eighth-classic-search-step',
    content: 'This is my eighth Step',
  },
];

const ClassicSearchPage: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const [cookies, setCookie] = useCookies(['classicSearchTour']);
  const [isTourOpen, setIsTourOpen] = useState(cookies.classicSearchTour === 'true');
  const totalCount = useSelector(totalCountSelector);
  const [dictionaryForTransitAndYardDefinition, setDictionaryForTransitAndYardDefinition] = useState<{ id: string; value: string }[]>([]);

  const productGeneralDictionariesByLanguage = useSelector(productGeneralDictionariesByLanguageSelector);
  const transitAndYardDefinition = useSelector(transitAndYardDefinitionSelector);

  useEffect(() => {
    dispatch(getTransitAndYardDefinition());
  }, [dispatch]);

  const getParamValue = useCallback((field: string) => params.get(field)?.replace(/%2C/g, ',') || undefined, [params]);

  // Update Makes according vehicletype
  useEffect(() => {
    const vehicletype = productGeneralDictionariesByLanguage.vehicleTypesList?.find((item) => item.value === getParamValue('vehicletype'));
    if (vehicletype) dispatch(getMakeModelsByVehicleType(vehicletype.id));
  }, [dispatch, getParamValue, productGeneralDictionariesByLanguage]);

  useEffect(() => {
    setDictionaryForTransitAndYardDefinition(
      transitAndYardDefinition.map((item) => ({
        id: `${item.objectId}_${item.locationType}`,
        value: `${item.countryName} (${item.objectName})`,
      })),
    );
  }, [transitAndYardDefinition]);

  const getModelForRequest = useCallback(() => {
    const vehiclemodel = getParamValue('vehiclemodel')?.split(',');
    const models: string[] = [];

    vehiclemodel?.map((item) => {
      const data = item.split('/');
      const seria = data[1]?.replace(/_/g, ' ');
      const model = data[2]?.replace(/_/g, ' ');

      seria && models.push(seria);
      model && models.push(model);
      return null;
    });
    const d = new Set(models);
    return d.size ? [...d].join(',') : undefined;
  }, [getParamValue]);

  // const parseProps = (val?: string) => {
  //   if (val) {
  //     const newVal = val.split(',').map((item) => {
  //       const style = item.split('_');
  //       if (style.length > 1) return style[1];
  //       return style[0];
  //     });
  //     return newVal.join();
  //   }
  //   return undefined;
  // };

  const parsePropsDamageStyle = (val?: string) => {
    const Defect: string[] = [];
    const DamageStyle: string[] = [];
    if (val) {
      val.split(',').forEach((item) => {
        const style = item.split('_');
        if (style.length > 1) {
          DamageStyle.push(style[1]);
        } else {
          !Defect.includes(style[0]) && Defect.push(style[0]);
        }
      });
      return {
        Defect: Defect.join(),
        DamageStyle: DamageStyle.join(),
      };
    }
    return undefined;
  };

  const parsePropsBodyStyle = (val?: string) => {
    const AutomobileType: string[] = [];
    const BodyStyle: string[] = [];
    if (val) {
      val.split(',').forEach((item) => {
        const style = item.split('_');
        if (style.length > 1) {
          BodyStyle.push(style[1]);
        } else {
          !AutomobileType.includes(style[0]) && AutomobileType.push(style[0]);
        }
      });
      return {
        //AutomobileType: AutomobileType.join(),
        BodyStyle: BodyStyle.join() || AutomobileType.join(),
      };
    }
    return undefined;
  };

  function findObjectIdsByNames(names: string): any {
    if (!names) return;
    const modelNames = names.split(',').map(name => {
      return name.split('(')[1]?.trim().slice(0, -1).toLowerCase();
    });
    const objectIds: number[] = [];
    modelNames.forEach(modelName => {
      const foundCountry = transitAndYardDefinition.find(country =>
        country.models.some((model: { objectName: string; }) => model.objectName.toLowerCase() === modelName)
      );
      if (foundCountry) {
        const foundModel = foundCountry.models.find((model: { objectName: string; }) => model.objectName.toLowerCase() === modelName);
        if (foundModel) {
          objectIds.push(foundModel.objectId);
        }
      }
    });
    return objectIds.join(',');
  }
  // Update Search result
  const updateFindVehicles = useCallback(
    () => {
      const Page = params.get('page');
      const PageItemCount = params.get('itemscount');
      const SortField = params.get('sortField');
      const SortOrder = params.get('sortOrder');
      const searchString = location.pathname.includes('/en') || location.pathname.includes('/ru') ? null : location.pathname.split('/')[2];
      const carLocationIds = findObjectIdsByNames(getParamValue('carlocation') || "");
      dispatch(
        getFindVehicles(
          false, // isWatchList
          Page ? +Page : 1, // Page
          PageItemCount ? +PageItemCount : 20, // PageItemsCount
          SortField || 'lotNumber', // SortField
          SortOrder || 'Desc', // SortOrder
          searchString, // searchString
          (getParamValue('newlyaddedlots') && (getParamValue('newlyaddedlots') === 'Last 24 Hours' ? 1 : 7)) || undefined, // DaysFromCreateLessThanOrEqual
          getParamValue('featuredtype'), // FeaturedType
          carLocationIds, // YardLocationId
          getParamValue('vehiclemake')?.replace(/_/g, ' '), // Make
          getModelForRequest(), // Model
          getParamValue('color'), // Color
          getParamValue('vehicleyear'), // Year
          getParamValue('yearfrom'), // Year from
          getParamValue('yearto'), // Year to
          getParamValue('auctiontype'), // auctiontype
          getParamValue('vehicletype'), // VehicleType
          getParamValue('auctionsaddress'), // Location
          getParamValue('vehicleodometrfrom'), // OdometerGreaterThanOrEqual
          getParamValue('vehicleodometrto'), // OdometerLessThan
          getParamValue('vehiclepricefrom'), // Price From
          getParamValue('vehiclepriceto'), // Price To
          parsePropsDamageStyle(getParamValue('defect'))?.DamageStyle, // DamageStyle
          parsePropsBodyStyle(getParamValue('bodystyle'))?.BodyStyle, // BodyStyle
          getParamValue('fueltype'), // FuelType
          getParamValue('enginetype'), // EngineType
          getParamValue('transmission'), // Transmission
          getParamValue('drivetrain'), // DriveTrain
          getParamValue('cylinder'), // Cylinder
          getParamValue('source'), // Source
          getParamValue('laneids'), // LaneIds
          getParamValue('customs'), // Customs
          parsePropsDamageStyle(getParamValue('defect'))?.Defect, // Defect
          parsePropsBodyStyle(getParamValue('bodystyle'))?.AutomobileType, // AutomobileType
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, params, dispatch, getParamValue, getModelForRequest, transitAndYardDefinition],
  );

  useEffect(() => {
    updateFindVehicles();
  }, [params, transitAndYardDefinition, updateFindVehicles]);

  const handleCloseTour = useCallback(() => {
    setCookie('classicSearchTour', 'false', { path: '/' });
    setIsTourOpen(false);
  }, [setCookie]);

  const handleTourOn = useCallback(() => {
    if (cookies.classicSearchTour === 'true') {
      handleCloseTour();
    } else {
      setCookie('classicSearchTour', 'true', { path: '/' });
      setIsTourOpen(true);
    }
  }, [cookies.classicSearchTour, handleCloseTour, setCookie]);

  const [isOpenAdditionalFilter, setIsOpenAdditionalFilter] = useState(false);

  const handleToggleAdditionalFilter = useCallback(() => {
    setIsOpenAdditionalFilter((prev) => !prev);
  }, []);

  const { lg } = useBreakpoint();
  const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false)
  const resRef = useRef<HTMLDivElement>(null)
  const executeScroll = () => {
    if (resRef.current !== null) {
      const element = resRef.current;
      element.style.transition = 'scroll-margin-top 0.5s ease-in-out';
      element.style.scrollMarginTop = '200px';
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  };

  return (
    <><Meta
      title={i18n.t("meta_carsearch_title")}
      description={i18n.t("meta_carsearch_desc")} />
      <ClassicSearchPageWrapper>
        {lg && <BannerFindForm
          isOpenAdditionalFilter={isOpenAdditionalFilter}
          onToggleAdvanced={handleToggleAdditionalFilter}
          dictionaryForTransitAndYardDefinition={transitAndYardDefinition}
          onSubmit={() => { updateFindVehicles(); executeScroll(); } } />}
        {!lg && <>
          <MobileFilterHeader
            title='Find Vehicle'
            onButtonHandle={() => setIsMobileFilterVisible(true)} />

          {isMobileFilterVisible && <MobileFilter onClose={() => setIsMobileFilterVisible(false)}>
            <BannerFindForm
              isOpenAdditionalFilter={isOpenAdditionalFilter}
              onToggleAdvanced={handleToggleAdditionalFilter}
              dictionaryForTransitAndYardDefinition={transitAndYardDefinition}
              onSubmit={() => setIsMobileFilterVisible(false)} />
          </MobileFilter>}
        </>}



        <PopularFiltersWrapper>
          <PopularFilters />
        </PopularFiltersWrapper>

        <SearchFilterContainer total={totalCount} filter={true} />

        <ResultContainer total={totalCount} ref={resRef} />
      </ClassicSearchPageWrapper></>
  );
};

export default memo(ClassicSearchPage);