import React, { useState } from 'react'
import { GalleryAlertList, GalleryItem, GalleryList, GalleryWrapper, ModalGalleryWrapper, ModalHeader, ModalImage } from './style'
import { Modal, Button } from 'antd'
import Alert, { AlertTypes, AlertVariants } from '../../shared/components/Alert'
import StyledImageGallery from '../../shared/components/StyledImageGallery'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ScSlider } from '../../shared/components/PopularVehiclesSlider/style'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactSVG } from 'react-svg'
import styled, { css } from 'styled-components'
import JSZip from 'jszip'
import { axiosInstance } from '../../core/axios'
import { currentVehicleByVinSelector } from '../../store/slices/findVehiclesSlice'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface GalleryItem {
    fileExt: string
    fileName: string
    id: number
    mime: string
}

interface GalleryProps {
    photos: GalleryItem[]
    currentVehicleByVin: any
}


export const SliderButtonWrapper = styled.button<any>`
  background: #F9FAFB;
  border: 1px solid #D1D5DB;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 100px;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  transform: translate(0%, -50%);
  padding: 0;
  .svg-wrapper{
    &, & > div{
      display: flex;
      align-items: center;
    }
  }
  
  @media(max-width: 991px){
    display: none;
  }

  ${p => p.isNextArrow && css`
    position: absolute;
    top: 50%;
    right: -10px;
    svg{
      transform: rotate(180deg);
    }
  `}

  ${p => p.isPrevArrow && css`
    position: absolute;
    top: 50%;
    left: -10px;
  `}
`

function NextArrow(props: any) {
  const { isNextArrow, onClick } = props;
  return (
    <SliderButtonWrapper onClick={onClick} isNextArrow={isNextArrow}>
      <ReactSVG src='/icons/arrow-left.svg' className='svg-wrapper'/>
    </SliderButtonWrapper>
  );
}

function PrevArrow(props: any) {
  const { isPrevArrow, onClick } = props;
  return (
    <SliderButtonWrapper onClick={onClick} isPrevArrow={isPrevArrow}>
      <ReactSVG src='/icons/arrow-left.svg' className='svg-wrapper'/>
    </SliderButtonWrapper>
  );
}

const CDN_URL = "https://cdn.liontrans.com/"

const Gallery = ({photos, currentVehicleByVin}: GalleryProps) => {
    const [isTrackingGalleryModalVisible, setIsTrackingGalleryModalVisible] = useState(false)
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState("")
    const { lg } = useBreakpoint()
    const [isDownloadLoading, seDownloadtisLoading] = useState(false)
    const { t } = useTranslation()

    const closeTrackingGalleryPopup = () => {
        setSelectedPhotoUrl("")
        setIsTrackingGalleryModalVisible(false)
    }

    const openTrackingGalleryPopup = (fileName: string) => {
        setSelectedPhotoUrl(fileName)
        setIsTrackingGalleryModalVisible(true)
    }

    const getImagesForGallery = (photos: GalleryItem[]) => {
        const images = photos.map((photo) => ({
            original: `${CDN_URL}${photo.fileName}`,
            thumbnail: `${CDN_URL}${photo.fileName}`,
        }));
        return images || [];
    }
    const settings = {
        dots: false,
        infinite: false,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow isNextArrow={true} />,
        prevArrow: <PrevArrow isPrevArrow={true} />,
    };

    const containerAlert = (currentVehicleByVin.containerNumber?.startsWith('L-') || currentVehicleByVin.containerNumber === null) || currentVehicleByVin.containerNumber === '';
    const onDonwloadAllPhotos = async () => {
        seDownloadtisLoading(true)

        const a = document.createElement("a")
        a.download = `${currentVehicleByVin.id}.zip`
        a.href = process.env.REACT_APP_API_URL + "/api/" + `Search/DownloadPhotos?id=${currentVehicleByVin.id}`
        a.style.display = "none"

        document.body.appendChild(a)
        a.click()
        a.remove()

        seDownloadtisLoading(false)
    }

  return (
    <GalleryWrapper>
        {lg 
        ? <GalleryList>
            {photos.map((photo) => (
                <GalleryItem key={photo.id} onClick={() => openTrackingGalleryPopup(photo.fileName)}>
                    <img src={`${CDN_URL}${photo.fileName}`} />
                </GalleryItem>
            ))}
        </GalleryList>
        : <GalleryList>
         {<StyledImageGallery items={getImagesForGallery(photos)} />}
        </GalleryList>}

        <>
          {!containerAlert &&
            <GalleryAlertList>
              <Alert type={AlertTypes.INFO} variant={AlertVariants.GRAY} text={t("track_container_info")} />
            </GalleryAlertList>
          }
        </>
        {isTrackingGalleryModalVisible && <Modal
            visible={isTrackingGalleryModalVisible}
            onCancel={closeTrackingGalleryPopup}
            width="50%"
            footer={null}
        >
            <ModalHeader>
                <Button type="primary" onClick={onDonwloadAllPhotos} loading={isDownloadLoading}>
                    Download All
                </Button>
            </ModalHeader>
            <Slider {...settings}>
                <ModalGalleryWrapper>
                    <img src={`${CDN_URL}${selectedPhotoUrl}`}/>
                </ModalGalleryWrapper>

                {photos
                .filter(photo => photo.fileName != selectedPhotoUrl)
                .map((photo) => (
                    <ModalGalleryWrapper key={photo.id}>
                        <img src={`${CDN_URL}${photo.fileName}`}/>
                    </ModalGalleryWrapper>
                ))}
            </Slider>
        </Modal>}
</GalleryWrapper>
  )
}

export default Gallery