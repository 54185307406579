import { Button, Card, Tag } from 'antd';
import styled, { css, keyframes } from 'styled-components';
import Slider from 'react-slick';
import { Grey, Primary, LightGreyBackground, Shadow, BorderGrey } from '../../shared-styles';

export const StyledCard = styled(Card)`
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${BorderGrey};
  .ant-card-cover {
    position: relative;
    img {
      border-radius: 4px;
    }
  }
  /* min-height: 100%; */
  .ant-card-body {
    padding: 1.25rem 1rem 1rem;
    height: 200px;
  }
  .ant-card-meta,
  .ant-card-meta-detail,
  .ant-card-meta-description {
    font-family: 'main';
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #6E7281;
  }
  .ant-card-meta-title {
    white-space: break-spaces;
    font-family: 'main';
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1B153D;
    margin-bottom: 1rem!important;
  }
  .ant-card-actions{
    border: none;
    padding: 0 1.25rem;
  }
  @media(max-width: 580px){
    display: grid;
    grid-template-columns: 7.5rem 1fr;
    column-gap: 0.75rem;
    border: none;
    border-radius: 0;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid ${BorderGrey};

    .ant-card-cover{
      grid-row: 1 / 3;
    }
    .ant-card-meta-title{
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1.25rem;
      margin: 0;
    }
    .ant-card-body{
      height: 40px;
      padding: 0;
    }
    .ant-card-meta-description{
      //display: none;
    }
    .ant-card-actions{
      position: relative;
      top: 78%;
      padding: 0;
      li{
        margin: 0;
      }
    }
  }
`;

export const ScSlider = styled(Slider)`
  .slick-track {
    margin: auto;
  }
`;

export const ScTag = styled(Tag)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  width: fit-content;
`;

export const ImgContainer = styled.div<{
  url: string | null;
  height: string;
  borderRadius?: boolean;
}>`
  border-radius: ${(props) => (props.borderRadius ? '4px' : '0px')};
  background-image: ${(props) => `url(${props.url})`};
  height: ${(props) => props.height};
  background-size: cover;
  background-position: center;
  & > div {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: ${Shadow};
    cursor: pointer;
    .svg-wrapper{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      padding: 0;
    }
    img{
      object-fit: contain
    }
    .large{
      height: 5rem;
    }
    .small{
      height: 3rem;
    }
  }
  &.electric-cars-banner{
    height: 600px !important;
    @media(max-width: 1400px){
      height: 500px !important;
    }
    @media(max-width: 1100px){
      height: 350px !important;
    }
    @media(max-width: 767px){
      height: 600px !important;
      background-position: top;
    }
  }
  @media(max-width: 580px){
    .svg-wrapper{
      top: 40% !important;
      opacity: 0.75;
    }
    .ant-col.ant-col-xs-20{
      max-width: 100%;
      flex: 0 0 100%;
      padding: 0 1rem; 
    }
    .back-btn{
      
    }
    .header{
      font-size: 1.25rem;
      line-height: 2rem;
    }
    .calendar{

    }
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: 'main';
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #1B153D;
  div, svg{
    margin-right: 0.5rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  @media(max-width: 580px){
    gap: 0.25rem;
    font-size: 0.625rem;
    line-height: 1.25rem;
    color: #6E7281;
    div, svg{
      width: 1rem;
      height: 1rem;
    }
    path{
      stroke: #6E7281;
    }
  }
`

export const ReadMoreBtn = styled.button`
  font-family: 'main';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-decoration-line: underline;
  color: #1B153D;
  @media(max-width: 580px){
    display: none;
  }
`

const shine = keyframes`
  to {
    background-position-x: -200%;
  }
`

const loadingCss = css`
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s ${shine} linear infinite;
`

export const BlogLoaderCard = styled(Card)`
  ${loadingCss};
  .action-loader{
    height: 1.5rem;
    margin: 1rem;
    ${loadingCss};
  }
`;

export const BlogImageLoader = styled.div<{
  height: string;
}>`
  height: ${(props) => props.height};
  ${loadingCss};
`

