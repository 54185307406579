import { Row, Col } from 'antd';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Features } from '../../interfaces/Product';
import { ScCard } from './style';
import LotDescriptionCard, { LotCardRow, LotCardText, LotCardTextVariant } from '../../shared/components/LotDescriptionCard';

interface Props {
  featuresInfo: Features;
}

const FeaturesCard: FC<Props> = ({ featuresInfo }) => {
  const { t } = useTranslation();

  return (
    <LotDescriptionCard
        title={`${t('lot_features')}`}
      >
      <>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY} >{t('lot_body_style')}</LotCardText>
          <LotCardText fw={500}>{featuresInfo.bodyStyle}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY} >{t('lot_color')}</LotCardText>
          <LotCardText fw={500}>{featuresInfo.color}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY} >{t('lot_keys')}</LotCardText>
          <LotCardText fw={500}>{featuresInfo.keys ? 'Yes' : 'No'}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY} >{t('lot_transmission')}</LotCardText>
          <LotCardText fw={500}>{featuresInfo.transmission}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY} >{t('lot_fuel_type')}</LotCardText>
          <LotCardText fw={500}>{featuresInfo.fuel}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY} >{t('lot_cylinders')}</LotCardText>
          <LotCardText fw={500}>{featuresInfo.cylinders}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY} >{t('lot_drive')}</LotCardText>
          <LotCardText fw={500}>{featuresInfo.drive}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY} >{t('lot_engine_type')}</LotCardText>
          <LotCardText fw={500}>{featuresInfo.engineType}</LotCardText>
        </LotCardRow>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY} >{t('lot_notes')}</LotCardText>
          <LotCardText fw={500}>{featuresInfo.notes}</LotCardText>
        </LotCardRow>
      </>
    </LotDescriptionCard>
  );
};

export default memo(FeaturesCard);
