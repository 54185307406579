import React, { useEffect, useState } from 'react'
import { GalleryCardList, GalleryCardsWrapper, GalleryMainImage, GallerySectionWrapper, ShowMoreButton } from './style'
import GalleryCard from '../GalleryCard'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { langPrefix } from '../../../utils/functions'

const getGalleryCars = async (lang: string) => {
    const galleryCarIds = [1,2,5,12, 15]
    const promises: any = []
    galleryCarIds.forEach(id => {
        const promise = fetch(`/electric-cars/${lang}/${id}.json`)
        promises.push(promise)
    })

    const results = await Promise.all(promises).then(results => Promise.all(results.map(r => r.json())) )
    return results
}

const GallerySection = () => {
    const [mainVehicleId, setmainVehicleId] = useState<null | number>(null)
    const [vehicles, setVehicles] = useState<any[]>([])
    const [cookies] = useCookies(['language']);

    useEffect(() => {
        getGalleryCars(cookies.language).then((resp) => {
            setVehicles(resp)
            !mainVehicleId && setmainVehicleId(resp[0].id)
        })
    }, [cookies.language])

    const onCardHandle = (id: number) => {
        setmainVehicleId(id)
    }

    const {t} = useTranslation()

  return (
    <GallerySectionWrapper>
        <GalleryCardsWrapper>
            {mainVehicleId && <GalleryMainImage>
                <GalleryCard item={vehicles.find(item => item.id == mainVehicleId)}/>
            </GalleryMainImage>}
            <GalleryCardList>
                {vehicles
                .filter((item: any) => item.id != mainVehicleId)
                .map((item: any) => (
                    <GalleryCard key={item.id} item={item} onCardHandle={() => onCardHandle(item.id)}/>
                ))}
            </GalleryCardList>
        </GalleryCardsWrapper>
        <ShowMoreButton to={langPrefix("/electric-cars-search")}>{t("Read_more")}</ShowMoreButton>
    </GallerySectionWrapper>
  )
}

export default GallerySection