import { Link } from "react-router-dom";
import styled from "styled-components";

export const SellerCardWrapper = styled(Link)`
  padding: 0.75rem;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 0.5rem;
  display: flex;
  @media(max-width: 767px){
    padding: 0.5rem;
  }
`

export const SellerPhoto = styled.div`
  width: 8rem;
  aspect-ratio: 1;
  flex-shrink: 0;
  margin-right: 1.125rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    overflow: hidden;
  }
  @media(max-width: 767px){
    width: 6rem;
    height: 6rem;
    margin-right: 1.125rem;
  }
`

export const SellerContent = styled.div`
    display: flex;
    flex-direction: column;
    h3{
        color: #1B153D;
        font-family: main;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.375rem;
        margin-bottom: 0.75rem;
    }
    span{
        color: #6E7281;
        font-family: main;
        font-size: 0.75rem;
        font-weight: 400;
        word-break: break-word;
    }
    button{
        color: #CE2424;
        font-family: main;
        font-size: 0.75rem;
        font-weight: 500;
        padding: 0.675rem 1.5rem;
        border-radius: 0.5rem;
        border: 1px solid #CE2424;
        margin-top: 0.75rem;
    }
`