import React, { useMemo, useState } from 'react'
import { MobileSearchWrapper, PaginationWrapper } from '../style'
import { useSelector } from 'react-redux';
import { findVehiclesSelector, isLoadingSelector, totalCountSelector } from '../../../store/slices/findVehiclesSlice';
import CustomPagination from '../../../shared/components/CustomPagination';
import { useHistory, useLocation } from 'react-router';
import VehicleSearchCard, { VehicleSearchCardSkeleton } from '../../../shared/components/VehicleSearchCard';

const DEFAULT_PAGE_COUNT = 20;

const MobailSearch = () => {
    const location = useLocation();
    const history = useHistory();
    const params = useMemo(() => new URLSearchParams(location.search), [location]);
    const findVehicles = useSelector(findVehiclesSelector);
    const totalCount = useSelector(totalCountSelector);

    const [tableSettings, setTableSettings] = useState<{
      Page: number | string;
      PageItemsCount: number | string;
      SortField: string;
      SortOrder: string;
    }>({
      Page: Number(params.get("page")) || 1,
      PageItemsCount: Number(params.get("itemscount")) || 10,
      SortField: '',
      SortOrder: 'Desc',
    });

    const handleChangePagination = (page: number, pageSize?: number | undefined) => {
        page !== 1 ? params.set('page', page.toString()) : params.delete('page');
        pageSize !== DEFAULT_PAGE_COUNT && pageSize ? params.set('itemscount', pageSize.toString()) : params.delete('itemscount');
    
        history.push({
          pathname: history.location.pathname,
          search: params.toString(),
        });

        setTableSettings({
          ...tableSettings,
          Page: page || 1,
          PageItemsCount: pageSize || 10,
        })

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    
    const paginationProps = {
        showSizeChanger: true,
        total: totalCount,
        current: tableSettings.Page,
        pageSize: tableSettings.PageItemsCount,
    }

    const isLoading = useSelector(isLoadingSelector);

  return (
    <MobileSearchWrapper>
        {isLoading && Array(6).fill("").map((_ ,index) => (
          <VehicleSearchCardSkeleton key={index}/>
        ))}

        {findVehicles.map((vehicle) => (
          <VehicleSearchCard key={vehicle.id} vehicle={vehicle}/>
        ))}

        <PaginationWrapper>
            <CustomPagination {...paginationProps} onChange={handleChangePagination}/>
        </PaginationWrapper>
    </MobileSearchWrapper>
  )
}

export default MobailSearch