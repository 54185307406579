import styled, { css } from 'styled-components';
import { Col, Typography, Divider, Button, Card, Row, Spin } from 'antd';
import BadgeWithTooltip from '../BadgeWithTooltip';
import { White, Primary, LightGreyBackground, Grey, Shadow as ShadowColor } from '../../shared-styles';

const { Paragraph } = Typography;

export const Shadow = styled.div`
  position: relative;
  overflow: hidden;
  .circleShade {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${ShadowColor};
  }
  .circleShade:before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    max-width: 120px;
    max-height: 120px;
    top: -30px;
    right: -30px;
    background: ${White};
    border-radius: 50%;
    animation: colorChange 1s linear;
  }

  @keyframes colorChange {
    from {
      transform: scale(10, 10);
    }
    to {
      transform: scale(1, 1);
    }
  }
`;

export const NextLotContainer = styled.div<{ index: number }>`
  background: ${(p) => p.index % 2 === 1 && LightGreyBackground};
  margin: 0px 10px 0px 10px;
  padding: 10px 0px 0px 0px;
  cursor: pointer;
  border-radius: 4px;
  .font12 {
    font-size: 12px;
    div {
      align-self: flex-end;
    }
  }
`;

export const BadgeWithTooltipNextLot = styled(BadgeWithTooltip)`
  margin: 0;
`;

export const ScParagraph = styled(Paragraph)`
  margin: 0 !important;
`;

export const ScDivider = styled(Divider)`
  margin: 10px 0;
`;

export const CaptionItem = styled.div`
  display: flex;
  align-items: center;
  color: ${Grey};
  svg {
    margin-right: 4px;
  }
`;

export const ScTable = styled.div`
  .ant-row {
    padding: 10px;
    :nth-child(2n-1) {
      background: ${LightGreyBackground};
    }
  }
`;

export const ButtonFWidth = styled(Button)`
  width: 100%;
`;

export const SkeletonWrapper = styled(Row)`
  padding: 20px;
  .ant-spin-dot {
    width: 100px;
  }
  .ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
    margin: -50px;
  }
`;

export const ImageWrapper = styled(Col)`
  position: relative;
  span {
    font-size: 10px;
  }
  span:not(:first-child) {
    color: ${White};
    top: 0;
    right: 0;
  }
  span:first-child {
    top: 0;
    left: 0;
    a {
      color: ${Primary};
    }
  }
`;

export const CircleTimerStatus = styled.div`
  font-size: 10px;
  text-align: center;
`;

export const BidWrapper = styled(Row)`
  padding: 0px 0px 10px 4px;
`;

export const SCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

export const SpinTimeCountdown = styled(Spin)`
  .ant-spin-dot {
    width: 100%;
    left: 0 !important;
    margin: 0 !important;
    margin-top: -20px !important;
    .ant-statistic-content-value {
      font-size: 24px;
    }
  }
`;

export const Flag = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background-image: url('img/flags.png');
  background-repeat: no-repeat;
  background-position: 100px 0;
  transform: scale(0.8);
  transform-origin: right center;
  ${(p: { Country: string }) =>
    p.Country === 'home' &&
    css`
      background-image: none;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'worldcup' &&
    css`
      background-position: -287px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'international' &&
    css`
      background-position: -239px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'uefachampionsleague' &&
    css`
      background-position: -47px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'uefaeuropaleague' &&
    css`
      background-position: -336px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'copaamericacnt' &&
    css`
      background-position: -264px -72px;
      width: 24px;
      height: 24px;
      margin-left: 10px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'wta' &&
    css`
      background-position: -1px -405px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'atp' &&
    css`
      background-position: -25px -405px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'itf' &&
    css`
      background-position: -50px -405px;
      transform: scale(0.8);
    `}
  ${(p: { Country: string }) =>
    p.Country === 'challenger' &&
    css`
      background-position: -74px -405px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'asean' &&
    css`
      background-position: -0px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'afghanistan' &&
    css`
      background-position: -24px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'africa' &&
    css`
      background-position: -48px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'african-union-oas' &&
    css`
      background-position: -72px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'albania' &&
    css`
      background-position: -96px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'algeria' &&
    css`
      background-position: -120px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'america' &&
    css`
      background-position: -144px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'american-samoa' &&
    css`
      background-position: -168px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'andorra' &&
    css`
      background-position: -192px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'angola' &&
    css`
      background-position: -216px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'anguilla' &&
    css`
      background-position: -240px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'antarctica' &&
    css`
      background-position: -264px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'antiguaandbarbuda' &&
    css`
      background-position: -288px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'arab-league' &&
    css`
      background-position: -312px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'argentina' &&
    css`
      background-position: -336px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'armenia' &&
    css`
      background-position: -360px -0px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'aruba' &&
    css`
      background-position: -0px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'asia' &&
    css`
      background-position: -24px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'australia' &&
    css`
      background-position: -48px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'austria' &&
    css`
      background-position: -72px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'azerbaijan' &&
    css`
      background-position: -96px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'bahamas' &&
    css`
      background-position: -120px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'bahrain' &&
    css`
      background-position: -144px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'bangladesh' &&
    css`
      background-position: -168px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'barbados' &&
    css`
      background-position: -192px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'belarus' &&
    css`
      background-position: -216px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'belgium' &&
    css`
      background-position: -240px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'belize' &&
    css`
      background-position: -264px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'benin' &&
    css`
      background-position: -288px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'bermuda' &&
    css`
      background-position: -312px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'bhutan' &&
    css`
      background-position: -336px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'bolivia' &&
    css`
      background-position: -360px -24px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'bosnia & herzegovina' &&
    css`
      background-position: -0px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'bosniaandherzegovina' &&
    css`
      background-position: -24px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'botswana' &&
    css`
      background-position: -48px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'brazil' &&
    css`
      background-position: -72px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'brunei' &&
    css`
      background-position: -96px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'bulgaria' &&
    css`
      background-position: -120px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'burkina-faso' &&
    css`
      background-position: -144px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'burundi' &&
    css`
      background-position: -168px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'caricom' &&
    css`
      background-position: -192px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'cis' &&
    css`
      background-position: -216px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'cambodia' &&
    css`
      background-position: -240px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'cambodja' &&
    css`
      background-position: -264px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'cameroon' &&
    css`
      background-position: -288px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'canada' &&
    css`
      background-position: -312px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'cape-verde' &&
    css`
      background-position: -336px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'cayman-islands' &&
    css`
      background-position: -360px -48px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'central-african-republic' &&
    css`
      background-position: -0px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'chad' &&
    css`
      background-position: -24px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'championsleague' &&
    css`
      background-position: -48px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'chile' &&
    css`
      background-position: -72px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'china' &&
    css`
      background-position: -96px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'colombia' &&
    css`
      background-position: -120px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'commonwealth' &&
    css`
      background-position: -144px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'comoros' &&
    css`
      background-position: -168px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'congo-brazzaville' &&
    css`
      background-position: -192px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'congo-kinshasa' &&
    css`
      background-position: -216px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'cook-islands' &&
    css`
      background-position: -240px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'copaamerica' &&
    css`
      background-position: -264px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'costarica' &&
    css`
      background-position: -288px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'cote-divoire' &&
    css`
      background-position: -312px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'croatia' &&
    css`
      background-position: -336px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'cuba' &&
    css`
      background-position: -360px -72px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'cyprus' &&
    css`
      background-position: -0px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'czechrepublic' &&
    css`
      background-position: -24px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'denmark' &&
    css`
      background-position: -48px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'djibouti' &&
    css`
      background-position: -72px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'dominica' &&
    css`
      background-position: -96px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'dominicanrepublic' &&
    css`
      background-position: -120px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'ecuador' &&
    css`
      background-position: -144px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'egypt' &&
    css`
      background-position: -168px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'elsalvador' &&
    css`
      background-position: -192px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'england' &&
    css`
      background-position: -216px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'equatorial-guinea' &&
    css`
      background-position: -240px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'eritrea' &&
    css`
      background-position: -264px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'estonia' &&
    css`
      background-position: -288px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'ethiopia' &&
    css`
      background-position: -312px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'europaleague' &&
    css`
      background-position: -336px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'europe' &&
    css`
      background-position: -360px -96px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'faroeislands' &&
    css`
      background-position: -24px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'fiji' &&
    css`
      background-position: -48px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'finland' &&
    css`
      background-position: -72px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'france' &&
    css`
      background-position: -96px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'gabon' &&
    css`
      background-position: -120px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'gambia' &&
    css`
      background-position: -144px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'georgia' &&
    css`
      background-position: -168px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'germany' &&
    css`
      background-position: -192px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'ghana' &&
    css`
      background-position: -216px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'gibraltar' &&
    css`
      background-position: -240px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'greatbritain' &&
    css`
      background-position: -264px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'greece' &&
    css`
      background-position: -288px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'greenland' &&
    css`
      background-position: -312px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'grenada' &&
    css`
      background-position: -336px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'guadeloupe' &&
    css`
      background-position: -360px -120px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'guam' &&
    css`
      background-position: -0px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'guatemala' &&
    css`
      background-position: -24px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'guernsey' &&
    css`
      background-position: -48px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'guinea-bissau' &&
    css`
      background-position: -72px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'guinea' &&
    css`
      background-position: -96px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'guyana' &&
    css`
      background-position: -120px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'haiti' &&
    css`
      background-position: -144px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'honduras' &&
    css`
      background-position: -168px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'hongkong' &&
    css`
      background-position: -192px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'hungary' &&
    css`
      background-position: -216px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'iceland' &&
    css`
      background-position: -240px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'india' &&
    css`
      background-position: -264px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'indonesia' &&
    css`
      background-position: -288px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'iran' &&
    css`
      background-position: -336px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'iraq' &&
    css`
      background-position: -360px -144px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'ireland' &&
    css`
      background-position: -0px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'islamic-conference' &&
    css`
      background-position: -24px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'isle-of-man' &&
    css`
      background-position: -48px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'israel' &&
    css`
      background-position: -72px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'italy' &&
    css`
      background-position: -96px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'jamaica' &&
    css`
      background-position: -120px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'jersey' &&
    css`
      background-position: -144px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'jordan' &&
    css`
      background-position: -168px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'kazakhstan' &&
    css`
      background-position: -192px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'kenya' &&
    css`
      background-position: -216px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'kiribati' &&
    css`
      background-position: -240px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'korea-south' &&
    css`
      background-position: -264px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'koreasouth' &&
    css`
      background-position: -288px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'kosovo' &&
    css`
      background-position: -312px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'kuwait' &&
    css`
      background-position: -336px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'kyrgyzstan' &&
    css`
      background-position: -360px -168px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'laos' &&
    css`
      background-position: -0px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'latvia' &&
    css`
      background-position: -24px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'lebanon' &&
    css`
      background-position: -48px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'lesotho' &&
    css`
      background-position: -72px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'liberia' &&
    css`
      background-position: -96px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'libya' &&
    css`
      background-position: -120px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'liechtenstein' &&
    css`
      background-position: -144px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'lithuania' &&
    css`
      background-position: -168px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'luxembourg' &&
    css`
      background-position: -192px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'macao' &&
    css`
      background-position: -216px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'macedonia' &&
    css`
      background-position: -240px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'madagascar' &&
    css`
      background-position: -264px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'malawi' &&
    css`
      background-position: -288px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'malaysia' &&
    css`
      background-position: -312px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'maldives' &&
    css`
      background-position: -336px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'mali' &&
    css`
      background-position: -360px -192px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'malta' &&
    css`
      background-position: -0px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'marshall-islands' &&
    css`
      background-position: -24px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'martinique' &&
    css`
      background-position: -48px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'mauritania' &&
    css`
      background-position: -72px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'mauritius' &&
    css`
      background-position: -96px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'mexico' &&
    css`
      background-position: -120px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'micronesia' &&
    css`
      background-position: -144px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'moldova' &&
    css`
      background-position: -168px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'monaco' &&
    css`
      background-position: -192px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'mongolia' &&
    css`
      background-position: -216px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'montenegro' &&
    css`
      background-position: -240px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'montserrat' &&
    css`
      background-position: -264px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'morocco' &&
    css`
      background-position: -288px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'mozambique' &&
    css`
      background-position: -312px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'myanmar' &&
    css`
      background-position: -336px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'nato' &&
    css`
      background-position: -360px -216px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'namibia' &&
    css`
      background-position: -0px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'nauru' &&
    css`
      background-position: -24px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'nepal' &&
    css`
      background-position: -48px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'netherlands-antilles' &&
    css`
      background-position: -72px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'netherlands' &&
    css`
      background-position: -96px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'holland' &&
    css`
      background-position: -96px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'newcaledonia' &&
    css`
      background-position: -120px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'newzealand' &&
    css`
      background-position: -144px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'nicaragua' &&
    css`
      background-position: -168px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'niger' &&
    css`
      background-position: -192px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'nigeria' &&
    css`
      background-position: -216px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'north-korea' &&
    css`
      background-position: -240px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'northamerica' &&
    css`
      background-position: -264px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'northern-cyprus' &&
    css`
      background-position: -288px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'northernireland' &&
    css`
      background-position: -312px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'norway' &&
    css`
      background-position: -336px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'opec' &&
    css`
      background-position: -360px -240px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'olimpic-movement' &&
    css`
      background-position: -0px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'olympicgames' &&
    css`
      background-position: -24px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'oman' &&
    css`
      background-position: -48px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'pakistan' &&
    css`
      background-position: -72px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'palau' &&
    css`
      background-position: -96px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'palestine' &&
    css`
      background-position: -120px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'panama' &&
    css`
      background-position: -144px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'papua-new-guinea' &&
    css`
      background-position: -168px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'paraguay' &&
    css`
      background-position: -192px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'peru' &&
    css`
      background-position: -216px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'philippines' &&
    css`
      background-position: -240px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'poland' &&
    css`
      background-position: -264px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'portugal' &&
    css`
      background-position: -288px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'puertorico' &&
    css`
      background-position: -312px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'qatar' &&
    css`
      background-position: -336px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'red-cross' &&
    css`
      background-position: -360px -264px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'republicofireland' &&
    css`
      background-position: -0px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'reunion' &&
    css`
      background-position: -24px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'romania' &&
    css`
      background-position: -48px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'russia' &&
    css`
      background-position: -72px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'rwanda' &&
    css`
      background-position: -96px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'saint-lucia' &&
    css`
      background-position: -120px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'samoa' &&
    css`
      background-position: -144px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'sanmarino' &&
    css`
      background-position: -168px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'sao-tome-principe' &&
    css`
      background-position: -192px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'saudiarabia' &&
    css`
      background-position: -216px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'scotland' &&
    css`
      background-position: -240px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'senegal' &&
    css`
      background-position: -264px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'serbia' &&
    css`
      background-position: -288px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'seyshelles' &&
    css`
      background-position: -312px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'sierra-leone' &&
    css`
      background-position: -336px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'singapore' &&
    css`
      background-position: -360px -288px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'slovakia' &&
    css`
      background-position: -0px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'slovenia' &&
    css`
      background-position: -24px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'solomon-islands' &&
    css`
      background-position: -48px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'somalia' &&
    css`
      background-position: -72px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'somaliland' &&
    css`
      background-position: -96px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'southafrica' &&
    css`
      background-position: -120px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'southkorea-copy' &&
    css`
      background-position: -144px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'southkorea' &&
    css`
      background-position: -168px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'spain' &&
    css`
      background-position: -192px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'sri-lanka' &&
    css`
      background-position: -216px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'st-kitts-nevis' &&
    css`
      background-position: -240px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'st-vincent-the-grenadines' &&
    css`
      background-position: -264px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'sudan' &&
    css`
      background-position: -288px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'suriname' &&
    css`
      background-position: -312px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'swaziland' &&
    css`
      background-position: -336px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'sweden' &&
    css`
      background-position: -360px -312px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'switzerland' &&
    css`
      background-position: -0px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'syria' &&
    css`
      background-position: -24px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'tahiti-french-polinesia' &&
    css`
      background-position: -48px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'taiwan' &&
    css`
      background-position: -72px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'tajikistan' &&
    css`
      background-position: -96px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'tanzania' &&
    css`
      background-position: -120px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'thailand' &&
    css`
      background-position: -144px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'timor-leste' &&
    css`
      background-position: -168px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'togo' &&
    css`
      background-position: -192px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'tonga' &&
    css`
      background-position: -216px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'trinidadtobago' &&
    css`
      background-position: -240px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'trinidadandtobago' &&
    css`
      background-position: -264px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'tunisia' &&
    css`
      background-position: -288px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'turkey' &&
    css`
      background-position: -312px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'turkmenistan' &&
    css`
      background-position: -336px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'turks-and-caicos-islands' &&
    css`
      background-position: -360px -336px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'tuvalu' &&
    css`
      background-position: -0px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'uae' &&
    css`
      background-position: -24px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'uk' &&
    css`
      background-position: -48px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'usa' &&
    css`
      background-position: -72px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'united-states' &&
    css`
      background-position: -72px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'uganda' &&
    css`
      background-position: -96px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'ukraine' &&
    css`
      background-position: -120px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'united-nations' &&
    css`
      background-position: -144px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'unitedarabemirates' &&
    css`
      background-position: -168px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'unitedkingdom' &&
    css`
      background-position: -192px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'uruguay' &&
    css`
      background-position: -216px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'uzbekistan' &&
    css`
      background-position: -240px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'vtb' &&
    css`
      background-position: -264px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'vanuatu' &&
    css`
      background-position: -288px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'vatican-city' &&
    css`
      background-position: -312px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'venezuela' &&
    css`
      background-position: -336px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'vietnam' &&
    css`
      background-position: -360px -360px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'virgin-islands-british' &&
    css`
      background-position: -0px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'virgin-islands-us' &&
    css`
      background-position: -24px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'wales' &&
    css`
      background-position: -48px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'western-sahara' &&
    css`
      background-position: -72px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'yemen' &&
    css`
      background-position: -120px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'zambia' &&
    css`
      background-position: -144px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'zimbabwe' &&
    css`
      background-position: -168px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'japan' &&
    css`
      background-position: -192px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'rolandgarros' &&
    css`
      background-position: -216px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'undefined' &&
    css`
      background-position: -240px -384px;
    `}
  ${(p: { Country: string }) =>
    p.Country === 'wimbledon' &&
    css`
      background-position: -264px -384px;
    `}
`;
