import { Button as ScButton, Switch } from 'antd';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const UpperHeaderWrapper = styled.div`
  background-color: #fff;
  padding: 1rem 2.5rem;
  display: flex;
  @media(max-width: 1400px){
    padding: 1rem;
  }
  @media(max-width: 767px){
    flex-direction: column
  }
`;


export const ButtonWrapper = styled.div`
  display: flex;
  @media(max-width: 767px){
    flex-direction: column;
    margin: 0 -0.5rem;
  }
`;

export enum ButtonVariant {
  DARK,
  SECONDARY,
  DANGER,
}

interface ButtonProps {
  variant?: ButtonVariant;
}

export const Button = styled(Link)<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.875rem 1.5rem;
  margin: 0 0.5rem;
  min-width: 8.125rem;
  min-height: 3rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;

  font-family: 'main';
  &:lang(ka){
    font-family: 'arial-caps';
  }

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ffffff;

  @media(max-width: 991px){
    padding: 0.75rem 1.5rem;
    min-width: 6rem;
  }

  @media(max-width: 767px){
    padding: 0.75rem 1.5rem;
    min-width: 6rem;
    width: 100%;
    margin: 0.5rem 0rem;
  }

  ${(p) => {
    switch (p.variant) {
      case ButtonVariant.SECONDARY:
        return css`
          border: 1.5px solid #000000;
          background: #FFFFFF;
          color: #1B153D;
        `;
      case ButtonVariant.DANGER:
        return css`
          background: #CE2424;
          &:hover{
            color: #fff;
          }
        `;
      default:
        return css`
          background: #212121;
        `;
    }
  }};
`;

export const ForDealerButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem 1rem;
  .arrow-icon{  
    margin-left: 1rem;
    &, & div{
      width: 1rem;
      height: 1rem;
    }
  }
  svg{
    transform: rotate(180deg);
  }
  path{
    stroke: #fff
  }
  @media(max-width: 991px){
    margin-left: 1rem;
  }
  @media(max-width: 767px){
    display: none;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1b153d;
  flex-wrap: wrap;
`;
export const AccountContainer = styled.div`
  line-height: 1rem;
  padding: 0 4px;
`;

export const AccountName = styled(AccountContainer)`
  line-height: 1rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0;
`;

export const AccountMenuBtn = styled(ScButton)`
  width: 100%;
  text-align: left;
  color: #1b153d;
  &:hover {
    color: #1b153d;
  }
`;

export const ProfileWrapper = styled.div`
  position: relative;
  @media( max-width: 767px){
    margin-left: 0.875rem;
  }
`

export const ProfileHead = styled.button`
  border: 1px solid rgba(41, 45, 50, 0.45);
  border-radius: 0.75rem;
  padding: 0.625rem ;
  display: flex;
  align-items: center;
  .svg-wrapper{
    transition: 0.1s;
    transform: rotate(180deg);
    &, & div{
      width: 1rem;
      height: 1rem;
    }
    &.upsidedown{
      transform: rotate(180deg);
    }
  }
  
  @media( max-width: 767px){
    width: 2.625rem;
    height: 2.625rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: rgba(151, 151, 151, 0.45);
    border-radius: 50%;
    .svg-wrapper{
      display: none;
      path{
        stroke: #fff;
      }
    }
  }
`

export const ProfileImage = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #CE2424;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
  text-transform: uppercase;
  color: #fff;
  @media( max-width: 767px){
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0;
  }
`

export const ProfileName = styled.span`
  font-family: 'main';
  &:lang(ka){
    font-family: "arial-caps"
  }
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #292D32;
  margin-right: 1rem;
  @media( max-width: 767px){
    display: none;
  }
`

export const ProfileBody = styled.div`
  width: 200px;
  border-radius: 0.75rem;
  top: 100%;
  z-index: 1;
`

export const ProfileList = styled.ul`
  display: flex; 
  flex-direction: column;
  list-style-type: none;
  padding: 1rem 0 2rem;
  max-height: 80vh;
  overflow-y: auto;
`

export enum ProfileItemHeadVariant {
  LOGOUT
}

interface ProfileItemHeadProps {
  variant?: ProfileItemHeadVariant
}

export const ProfileItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
`

export const ProfileItemHead = styled(Link)<ProfileItemHeadProps>`
  border: 1px solid rgba(151, 151, 151, 0.13);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  max-width: 100%;
  width: calc( 100% - 1.5rem );
    margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  .profile-icon{
    &, & div{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .setting-icon{
    &, & div, svg{
      width: 1rem;
      height: 1rem;
    }
    path{
      fill: #CE2424;
    }
  }
  .arrow-icon{
    margin-left: auto;
    transition: 0.1s;
    &, & div{
      width: 1rem;
      height: 1rem;
    }
    &.upsidedown{
      transform: rotate(180deg);
    }
  }
  ${p => p.variant == ProfileItemHeadVariant.LOGOUT && css`
    background: rgba(218, 85, 85, 0.15);
    border: 1px solid #DA5555;
  `}
`


export const ProfileItemBody = styled.div`
  margin-top: 0.75rem;
  overflow: hidden;
  ul{
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none;
    width: fit-content;
      margin-right: auto;
  margin-left: auto;
  }
  li{
    transition: 0.2s;
    list-style: none;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      left: -50vw;
      top: 0;
      display: none;
      width: 100vw;
      height: 100%; 
      background: rgba(206, 36, 36, 0.1);
      cursor: pointer;
    }
    a{
      min-width: 100px;
      display: block;
      padding: 0.25rem 0;
    }
    &:hover {
     display: block;
     &:after{
      display: block;
     }
    }
  }
  span, a{
    /* margin-left: 25%; */
    font-family: 'main';
    &:lang(ka){
      font-family: "arial-caps"
    }
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #292D32;
    position: relative;
    z-index: 1;
  }
`

export const ProfileItemName = styled.span`
font-family: 'main';
margin-left: 0.625rem;  
&:lang(ka){
  font-family: "arial-caps"
}
font-weight: 500;
font-size: 0.875rem;
color: #292D32;
  @media(max-width: 767px){
    font-size: 0.75rem;
  }
`

export const RoleSwitch = styled(Switch)`
  max-width: 15rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0.75rem;
`

export const MobileWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: #050505;
  padding: 0.875rem 1.25rem;
`

export const MenuIconButton = styled.button`
  margin-right: 1rem;
  .svg-wrapper{
    &, & > div{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`

export const BorderButton = styled.button`
  background: #050505;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  font-family: 'main';
  &:lang(ka){
    font-family: "arial-caps"
  }
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #FFFFFF;
  padding: 0.5rem 1.5rem;
  margin-left: auto;
  margin-left: 0.5rem;
`

export const SettingButton = styled.button`
    font-family: 'main';
    &:lang(ka){
      font-family: "arial-caps"
    }
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #FFFFFF;
    &::after{
        content: "";
        display: block;
        height: 1px;
        width: 10rem;
        background-color: #333333;
        margin-top: 1.875rem;
    }
`

export const ProfileBodyName = styled.div`
  color: #292D32;
  font-size: 0.75rem;
  font-family: main;
  font-weight: 600;
  margin-left: 0.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
`

export const TrackingSearchWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
`