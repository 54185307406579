import styled from 'styled-components';

export const ReviewCardWrapper = styled.div`
  padding: 1.5rem 1.125rem 1.125rem;
  border: 1px solid #ededed;
  border-radius: 0.5rem;
  @media(max-width: 767px){
    padding: 1rem 0.75rem;
    border-radius: 8px;
  border: 1px solid #EDEDED;
  background: #FFF;
  }
`;

export const ReviewHead = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  @media(max-width: 767px){
    margin-bottom: 1rem;
    gap: 0;
  }
`;

export const Title = styled.h3`
  font-family: 'main';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #1b153d;
  margin: 0;
  
  @media(max-width: 767px){
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;
  }
`;

export const RateByStarWrapper = styled.div``;

export const Time = styled.span`
  font-family: 'main';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #92949a;
  margin-left: auto;
  @media(max-width: 767px){
    font-size: 0.625rem;
    line-height: 1.375rem;
    margin-left: 0.5rem;
  }
`;

export const DescriptionWrapper = styled.div`
  p {
    font-family: 'main';
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #1b153d;
  }
`;

export const DealerStarRateWrapper = styled.div`
  font-family: 'main';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #1B153D;
  display: flex;
  align-items: center;
  gap: 0.325rem;
  margin-left: auto;
  path{
    stroke: #FDC500;
    fill: #FDC500;
  }
`