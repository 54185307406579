import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import React, { FC, memo } from 'react';
import { ScTitle } from '../style';
import ContentWrapper from '../../shared/components/ContentWrapper';

const { Text } = Typography;

const TermsOfConditionsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <ScTitle level={1}>{parse(t('termsAndConditionsTitle'))}</ScTitle>
      <ScTitle level={5}>
        {t('termsAndConditionsUpdate')}: {t('termsAndConditionsUpdateDate')}
      </ScTitle>
      <Text>
        <p>{parse(t('termsAndConditionsParagraph_1_1'))}</p>
        <p>{parse(t('termsAndConditionsParagraph_1_2'))}</p>
        <p>{parse(t('termsAndConditionsParagraph_1_3'))}</p>
        <p>{parse(t('termsAndConditionsParagraph_1_4'))}</p>
        <ul>
          <li>{parse(t('termsAndConditionsParagraph_1_4_step_1'))}</li>
          <li>{parse(t('termsAndConditionsParagraph_1_4_step_2'))}</li>
        </ul>
        <p>{parse(t('termsAndConditionsParagraph_1_5'))}</p>
        <ul>
          <li>{parse(t('termsAndConditionsParagraph_1_5_li_1'))}</li>
          <li>{parse(t('termsAndConditionsParagraph_1_5_li_2'))}</li>
          <li>{parse(t('termsAndConditionsParagraph_1_5_li_3'))}</li>
          <li>{parse(t('termsAndConditionsParagraph_1_5_li_4'))}</li>
          <li>{parse(t('termsAndConditionsParagraph_1_5_li_5'))}</li>
        </ul>
        <ol>
          <li>
            {parse(t('termsAndConditionsTitle_2'))}
            <ol>
              <li>
                <p>{parse(t('termsAndConditionsParagraph_2_li_1_1'))}</p>
                <p>{parse(t('termsAndConditionsParagraph_2_li_1_2'))}</p>
                <p>{parse(t('termsAndConditionsParagraph_2_li_1_3'))}</p>
                <p>{parse(t('termsAndConditionsParagraph_2_li_1_4'))}</p>
              </li>
              <li>{parse(t('termsAndConditionsParagraph_2_li_2'))}</li>
              <li>{parse(t('termsAndConditionsParagraph_2_li_3'))}</li>
              <li>
                <p>{parse(t('termsAndConditionsParagraph_2_li_4_1'))}</p>
                <p>{parse(t('termsAndConditionsParagraph_2_li_4_2'))}</p>
                <ul>
                  <li>{parse(t('termsAndConditionsParagraph_2_li_4_3'))}</li>
                  <li>{parse(t('termsAndConditionsParagraph_2_li_4_4'))}</li>
                  <li>{parse(t('termsAndConditionsParagraph_2_li_4_5'))}</li>
                </ul>
                <p>{parse(t('termsAndConditionsParagraph_2_li_4_6'))}</p>
              </li>
            </ol>
          </li>
          <li>
            {parse(t('termsAndConditionsTitle_3'))}
            <ol>
              <li>{parse(t('termsAndConditionsParagraph_3_li_1'))}</li>
              <li>{parse(t('termsAndConditionsParagraph_3_li_2'))}</li>
              <li>
                <p>{parse(t('termsAndConditionsParagraph_3_li_3_1'))}</p>
                <p>{parse(t('termsAndConditionsParagraph_3_li_3_2'))}</p>
              </li>
              <li>
                <p>{parse(t('termsAndConditionsParagraph_3_li_4_1'))}</p>
                <ol>
                  <li>{parse(t('termsAndConditionsParagraph_3_li_4_2'))}</li>
                  <li>{parse(t('termsAndConditionsParagraph_3_li_4_3'))}</li>
                </ol>
              </li>
              <li>{parse(t('termsAndConditionsParagraph_3_li_5'))}</li>
              <li>{parse(t('termsAndConditionsParagraph_3_li_6'))}</li>
              <li>{parse(t('termsAndConditionsParagraph_3_li_7'))}</li>
            </ol>
          </li>
          <li>
            {parse(t('termsAndConditionsParagraph_4'))}
            <ol>
              <li>
                <p>{parse(t('termsAndConditionsParagraph_4_li_1'))}</p>
                <ol>
                  <li>{parse(t('termsAndConditionsParagraph_4_li_1_1'))}</li>
                  <li>{parse(t('termsAndConditionsParagraph_4_li_1_2'))}</li>
                </ol>
              </li>
              <li>{parse(t('termsAndConditionsParagraph_4_li_2'))}</li>
              <li>{parse(t('termsAndConditionsParagraph_4_li_3'))}</li>
              <li>{parse(t('termsAndConditionsParagraph_4_li_4'))}</li>
              <li>{parse(t('termsAndConditionsParagraph_4_li_5'))}</li>
              <li>{parse(t('termsAndConditionsParagraph_4_li_6'))}</li>
            </ol>
          </li>
          <li>
            {parse(t('termsAndConditionsParagraph_5'))}
            <ol>
              <li>{parse(t('termsAndConditionsParagraph_5_li_1'))}</li>
              <li>
                <p>{parse(t('termsAndConditionsParagraph_5_li_2'))}</p>
                <ol>
                  <li>{parse(t('termsAndConditionsParagraph_5_li_2_1'))}</li>
                </ol>
              </li>
              <li>{parse(t('termsAndConditionsParagraph_5_li_3'))}</li>
              <li>
                <p>{parse(t('termsAndConditionsParagraph_5_li_4'))}</p>
                <ol>
                  <li>
                    <p>{parse(t('termsAndConditionsParagraph_5_li_4_1_1'))}</p>
                    <p>{parse(t('termsAndConditionsParagraph_5_li_4_1_2'))}</p>
                  </li>
                  <li>{parse(t('termsAndConditionsParagraph_5_li_4_2'))}</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            {parse(t('termsAndConditionsParagraph_6'))}
            <ol>
              <li>
                <p>{parse(t('termsAndConditionsParagraph_6_li_1'))}</p>
                <ol>
                  <li>{parse(t('termsAndConditionsParagraph_5_li_1_1'))}</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </Text>
    </ContentWrapper>
  );
};

export default memo(TermsOfConditionsPage);
