import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  @media(max-width: 767px){
    padding-bottom: 3rem;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  }
`;

export const SecondaryNavWrapper = styled.nav`
  display: flex;
  align-items: center;
  margin-left: 5rem;
  margin-right: 3rem;
  a{
    font-family: 'main';
    &:lang(ka){
      font-family: 'arial-caps';
    }
    &:lang(ka){
      font-family: 'arial-caps';
    }
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #1b153d;
    &:not(:last-child){
      margin-right: 2rem;
    }
  }
  @media(max-width: 1400px){
    margin-inline: 1.5rem;
    a{
      &:not(:last-child){
        margin-right: 1rem;
      }
    }
  }
  @media(max-width: 991px){
    display: none;
  }
  @media(max-width: 767px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    a{
      font-family: 'main';
      &:lang(ka){
      font-family: 'arial-caps';
    }
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #FFFFFF;
      &:not(:last-child){
        margin: 0;
        margin-bottom: 1rem;
      }
    }
    &::before{
        content: "";
        display: block;
        height: 1px;
        width: 10rem;
        background-color: #333333;
        margin-bottom: 1.5rem;
    }
  }
`;

export const MenuWrapper = styled.div`
  width: 100%;
  padding: 3.75rem 2.5rem;
  background: #080808;
  position: absolute;
  top: calc( 100% - 0rem);
  left: 0;
  @media(max-width: 1200px){
    padding: 3.5rem 1rem 1.5rem
  }
  @media(max-width: 767px){
    padding-top: 0;
    position: static;
  }
`

export const MenuContainer = styled.div`
  display: grid;
  grid-template-columns: 6.5rem  fit-content(50%)  fit-content(50%);
  gap: 1.5rem;
  justify-content: space-between;
  @media(max-width: 767px){
    grid-template-columns: 1fr;
  }
`

export const MenuGoup = styled.div`
  span{
    font-family: 'main';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #63656B;
  }
  nav{
    margin-top: 2.5rem;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: column;
    gap: 1.25rem 5rem;
  }
  a{
    font-family: 'main';
    &:lang(ka){
      font-family: 'arial-caps';
    }
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    text-align: left;
  }
  @media(max-width: 1200px){
    gap:2rem;
  }
  @media(max-width: 991px){
    gap:1rem;
    nav{
        grid-template-rows: repeat(6, 1fr);
        grid-template-columns: repeat(2, 1fr);
    }
  }
  @media(max-width: 767px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5rem;
    span{
        text-align: center;
    }
    nav{
        all: unset;
        display: flex;
        flex-direction: column;
        gap: 1.75rem;
        justify-content: center;
        align-items: center;
    }
  }
`