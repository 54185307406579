import styled from 'styled-components';
import { Row, Space } from 'antd';
import { White } from '../../shared/shared-styles';

export const BlogBanner = styled(Row)`
  position: absolute;
  width: 100%;
  bottom: 0;
  color: ${White};
  font-weight: 700;
  font-size: 28px;
  padding-bottom: 40px;
  z-index: 1;
  button {
    padding: 0;
    font-size: 24px;
  }
`;

export const BlogContainer = styled(Row)`
  padding-top: 2.125rem;
  .blogCard {
    width: 22vw;
  }

  .blogWrap {
      padding: 2.4rem;
    }

  @media (max-width: 1200px) {
    .blogCard {
      width: 20vw;
    }
    .blogWrap {
      padding: 2.4rem;
    }
  }
  @media (max-width: 1024px) {
    .blogCard {
      width: 28vw;
    }
    .blogWrap {
      padding: 2.4rem;
    }
  }
  @media (max-width: 865px) {
    .blogCard {
      width: 45vw;
    }
    .blogWrap {
      padding: 0;
    }
  }

  @media (max-width: 600px) {
    .blogCard {
      width: 80vw;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .blogWrap {
      margin: 2rem;
    }
  }

  .slick-track {
    padding-bottom: 2.125rem;
  }
  .slick-prev:before {
    display: none;
  }
  .slick-arrow:before {
    display: none;
  }
`;

export const RecomendedListTitle = styled.div`
  padding-bottom: 3rem;
  padding-top: 2rem;
  font-weight: 700;
  font-size: 18px;

  span {
    font-size: 1.4rem;
    position: relative;
  }

  span:after {
    content: '';
    width: 100%;
    height: 0.5rem;
    background: #ce2424;
    border-radius: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-transform: translate(0, calc(100% - 0rem));
    -ms-transform: translate(0, calc(100% - 0rem));
    transform: translate(0, calc(100% - 0rem));
  }
`;

export const BlogContent = styled.div`
  display: flex;
  flex-direction: column;
  button {
    align-self: flex-start;
  }
  .ant-typography {
    display: block;
    position: relative;
    width: 70%;
    align-self: center;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    .ant-typography {
      width: 80%;
    }
  }
`;

export const DateContainer = styled(Space)`
  color: ${White};
  .ant-typography {
    color: ${White};
  }
  font-size: 18px;
`;

export const BlogPageWrapper = styled.section`
  max-width: 90rem;
  padding: 0 1rem;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5rem;
  @media (max-width: 580px) {
    padding: 0 1rem 2.5rem;
  }
`;

export const BlogTitle = styled.h1`
  font-family: 'main';
  font-weight: 800;
  font-size: 1.75rem;
  line-height: 1.5rem;
  color: #1b153d;
  margin-bottom: 2.5rem;
  @media (max-width: 580px) {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const BlogList = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem 1rem;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 580px) {
    gap: 0.75rem;
    grid-template-columns: repeat(1, 1fr);
  }
`;
