import React, { useEffect, useState } from 'react'
import { DescriptionCardWrapper, ElectricCarsDetaileWrapper, HeaderTitleWrapper, HeaderWrapper, ImageWrapper, LeftSideWrapper, SliderButtonWrapper, SliderWrapper, StickyContainer } from './style'
import LotCardDescription, { LotCardLink, LotCardRow, LotCardText, LotCardTextVariant } from '../../shared/components/LotDescriptionCard'
import Price from '../../shared/components/Price'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import MainCard from './LotCards/MainCard';
import VehicleBodyCard from './LotCards/VehicleBodyCard';
import VehicleEngineCard from './LotCards/VehicleEngineCard';
import LightingCard from './LotCards/LightingCard';
import WindowsMirrorsCard from './LotCards/WindowsMirrorsCard';
import DriverSupportCard from './LotCards/DriverSupportCard';
import ComfortCard from './LotCards/ComfortCard';
import SecurityCard from './LotCards/SecurityCard';
import SeatSteeringWheelCard from './LotCards/SeatSteeringWheelCard';
import MultimediaCard from './LotCards/MultimediaCard';
import IndexSkeleton from './IndexSkeleton';
import Slider from 'react-slick';
import { ReactSVG } from 'react-svg';
import StyledImageGallery from '../../shared/components/StyledImageGallery';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import ImageMagnify from '../../shared/components/ImageMagnify';
import LeasingCard from '../LotPage/LeasingCard';

const fetchElectricCar = async (id: string, lang: string) => {
    const res = await fetch(`/electric-cars/${lang}/${id}.json`)
    const json = await res.json()
    return json
}

function Arrow(props: any) {
    const { isPrevArrow, onClick } = props;
    return (
      <SliderButtonWrapper onClick={onClick} isPrevArrow={isPrevArrow}>
        <ReactSVG src='/icons/arrow-left.svg' className='svg-wrapper'/>
      </SliderButtonWrapper>
    );
}

const ElectricCarsDetaile = () => {
    const { t } = useTranslation()
    const [cookies] = useCookies(['language']);
    const [vehicle, setVehicle] = useState<any>(null)
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        setVehicle(null)
        fetchElectricCar(id, cookies.language)
        .then((r: any) => {
            setVehicle(r)
        })
    }, [cookies.language, id])
    
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <Arrow isPrevArrow={false} />,
        prevArrow: <Arrow isPrevArrow={true} />,
    };

    const getImages = () => {
        const images = vehicle?.imageUrls.map( (item: string) => ({
            original: item,
            thumbnail: item,
        }));
        return images || [];
    }
    const [loaded, setLoaded] = useState<boolean>(false);

    const myRenderItem = (data: ReactImageGalleryItem) => <ImageMagnify img={data} setLoaded={setLoaded} />;

    const properties = {
        renderItem: myRenderItem,
        items: getImages(),
        thumbnailPosition: 'right',
        lazyLoad: true,
      };

  return (
    <ElectricCarsDetaileWrapper>

        {vehicle ? <>
            <LeftSideWrapper>
                <StickyContainer>
                    <HeaderWrapper>
                        <HeaderTitleWrapper>
                            <h2>{vehicle?.title}</h2>
                            {/* <p>Model 3 Standard Range Plus Rear-Wheel Drive</p> */}
                        </HeaderTitleWrapper>
                        {vehicle?.price && <Price price={vehicle?.price} />}
                    </HeaderWrapper>
                    <SliderWrapper>
                        <ImageGallery {...properties} />
                        {/* <Slider {...settings}>
                            {vehicle?.imageUrls?.map((url: string) => (
                                <ImageWrapper key={url}>
                                    <img src={url} alt={vehicle?.title} />
                                </ImageWrapper>
                            ))}
                        </Slider> */}
                    </SliderWrapper>
                </StickyContainer>
            </LeftSideWrapper>

            <DescriptionCardWrapper>
                {vehicle?.main && <MainCard vehicle={vehicle} />}

                <LeasingCard lotNumber={vehicle.title} />

                {vehicle?.engine && <VehicleEngineCard vehicle={vehicle} />}
                
                {vehicle?.vehicleBody && <VehicleBodyCard vehicle={vehicle} />}

                {vehicle?.lighting && <LightingCard vehicle={vehicle} />}

                {vehicle?.windowsMirrors && <WindowsMirrorsCard vehicle={vehicle} />}

                {vehicle?.driverSupport && <DriverSupportCard vehicle={vehicle} />}

                {vehicle?.seatsSteeringWheel && <SeatSteeringWheelCard vehicle={vehicle} />}
                
                {vehicle?.comfort && <ComfortCard vehicle={vehicle} />}
                
                {vehicle?.security && <SecurityCard vehicle={vehicle} />}

                {vehicle?.multiMedia && <MultimediaCard vehicle={vehicle} />}

            </DescriptionCardWrapper>
        </>: <IndexSkeleton />}
    </ElectricCarsDetaileWrapper>
  )
}

export default ElectricCarsDetaile