import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

export const ContactWrapper = styled.section`
  padding: 6.125rem 2.5rem 0rem 2.5rem;
  max-width: 90rem;
    margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: auto 30rem;
  gap: 2rem;
  @media(max-width: 1200px){
    grid-template-columns: auto 25rem;
    gap: 1.5rem;
  }
  @media(max-width: 991px){
    padding: 3rem 1rem;
    grid-template-columns: 1fr;
  }
`;

export const ContactInformationWrapper = styled.div``;

export const Title = styled.h2`
  font-family: 'main';
  &:lang(ka){
    font-family: 'arial-caps';
  }
  font-weight: 700;
  font-size: 2rem;
  line-height: 3.75rem;
  color: #1b153d;
  margin-bottom: 3.7rem;
  max-width: 36rem;
  display: flex;
  flex-direction: column;
  @media(max-width: 991px){
    font-size: 2rem;
    line-height: 2.5rem;
      margin-right: auto;
  margin-left: auto;
    margin-bottom: 2rem;
  }
  @media(max-width: 580px){
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }
`;

export const ContactListGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem 1.5rem;
  @media(max-width: 991px){
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  @media(max-width: 767px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width: 480px){
    padding: 0 1rem;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem;
  }
`;

export const ContactList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactItem = styled.span`
  font-family: 'main';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #1b153d;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:not(:last-child){
    margin-bottom: 1.125rem;
  }
  .svg-wrapper {
    margin-right: 0.75rem;
    &,
    & > div {
      width: 1.125rem;
      height: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media(max-width: 991px){
    font-size: 0.875rem;
    line-height: 1.25rem;    
    .svg-wrapper {
      margin-right: 0.5rem;
    }
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 7.5rem;
  @media(max-width: 991px){
    padding-top: 2rem;
  }
`;

export const FormTitle = styled.span`
  font-family: 'main';
  &:lang(ka){
    font-family: 'arial-caps';
  }
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: #1b153d;
  margin-bottom: 1.875rem;
  @media(max-width: 991px){
    font-size: 1.125rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }
  @media(max-width: 580px){
    margin-bottom: 2.5rem;
  }
`;

export const FormLabelList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  @media(max-width: 991px){
    margin-bottom: 1.5rem;
  }
`;

export const FormLabel = styled.label`
  border: 1px solid #d6d6d6;
  border-radius: 0.5rem;
  font-family: 'main';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #1b153d;
  padding: 1rem 1.125rem;
  min-width: 8rem;
  transition: 0.2s;
  cursor: pointer;
  text-align: center;
  margin-right: 0.75rem;
  margin-bottom: 1.125rem;
  &:active,
  &.active {
    background: #CE2424;
    color: #fff;
  }
  @media(max-width: 1200px){
    min-width: 7rem;
  }
  @media(max-width: 991px){
    font-size: 0.75rem;
    line-height: 1rem;
    min-width: 6rem;
    padding: 0.75rem 1rem;
  }
  @media(max-width: 767px){
    font-size: 0.75rem;
    line-height: 1rem;
    min-width: fit-content;
    padding: 0.5rem 0.75rem;
    margin-right: 0.875rem;
    margin-bottom: 0.5rem;
  }
`;

export const FormInputsWrapper = styled.div`
  display: grid;
  gap: 10px;
  width: 100%;
  .input-group {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const FormInput = styled.input`
  height: 48px;
  width: 100%;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: #1b153d;
  padding: 0px 12px;

  background: #ffffff;
  border: 1px solid #d1d5d6;
  box-shadow: 0px 4px 30px #efefef;
  border-radius: 8px;

  &::placeholder {
    color: #566063;
    font-family: main;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;

export const FormTextarea = styled.textarea`
  border-radius: 0.25rem;
  border: 1px solid #a5aaac;
  background: #fff;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
  height: 11.5rem;
  width: 100%;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: #1b153d;
  padding: 0.75rem 1rem;
  resize: none;

  height: 100px;
  background: #ffffff;
  border: 1px solid #d1d5d6;
  box-shadow: 0px 4px 30px #efefef;
  border-radius: 8px;

  &::placeholder {
    color: #566063;
    font-family: main;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;

export const FormButton = styled.button`
  font-family: 'main';
  &:lang(ka){
    font-family: 'arial-caps';
  }
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  background: #CE2424;
  border-radius: 0.5rem;
  padding: 1rem 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width: 991px){
    margin-top: 3rem;
  }
  @media(max-width: 767px){
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
      margin-right: auto;
  margin-left: auto;
    margin-top: 1.5rem;
  }
`;
