import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const SearchContainer = styled.div`
  padding: 8px;
  input {
    display: block;
    width: 188px;
    margin-bottom: 8px;
  }
`;

export const SearchOutlinedIcon = styled(SearchOutlined)`
  color: ${(props) => props.color};
`;
