import styled from 'styled-components';
import { Primary } from '../../shared-styles';

export const BlinkJoin = styled.span`
  &:before {
    content: '';
    border-radius: 5px;
    padding: 10px;
    background: ${Primary};
    animation: blinker 1s linear infinite;
    clip-path: circle(15%);
    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }
`;
