import React, {useCallback, useState, useEffect} from 'react'
import { ProfileBody, ProfileBodyName, ProfileItem, ProfileItemBody, ProfileItemHead, ProfileItemHeadVariant, ProfileItemName, ProfileList, ProfileName, RoleSwitch } from './style'
import { ReactSVG } from 'react-svg'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { changeRole, logOut, userRoleSelector } from '../../../../store/slices/authSlice'
import { HeartFilled, SyncOutlined } from '@ant-design/icons'
import { langPrefix } from '../../../../utils/functions'

const ProfileMenu = ({fullname}: {fullname: string}) => {
    const userRole = useSelector(userRoleSelector);
    const [isBuyer, setIsBuyer] = useState<boolean>(userRole === 'buyer');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [isPaymentProfileOpen, setIsPaymentProfileOpen] = useState(false)
    const [isLotsOpen, setIsLotsOpen] = useState(false)
    const [isSettingsVisible, setIsSettingsVisible] = useState(false)

    const handleLogout = useCallback(() => {
        dispatch(logOut());
    }, [dispatch]);

    const handleSetUser = useCallback(
        (value) => {
        const role = value ? 'buyer' : 'seller';
        setIsBuyer(value);
        dispatch(changeRole(role));
        localStorage.setItem('userType', role);
        value && location.pathname === '/dashboardseller' && history.push('/dashboardbuyer');
        !value && location.pathname === '/dashboardbuyer' && history.push('/dashboardseller');
        },
        [dispatch, location, history],
    );
      
  return (
    <ProfileBody>
    <ProfileList>
      <RoleSwitch
        checked={isBuyer}
        checkedChildren={t('nav_user_menu_type_buyer')}
        unCheckedChildren={t('nav_user_menu_type_seller')}
        onChange={handleSetUser}
      />
      <ProfileBodyName>{fullname}</ProfileBodyName>
      
      <ProfileItem>
        <ProfileItemHead as="div" onClick={() => setIsPaymentProfileOpen(!isPaymentProfileOpen)}>
          <ReactSVG src="/icons/header/payments.svg" className='profile-icon'/>
          <ProfileItemName>{t('nav_user_menu_payments')}</ProfileItemName>
          <ReactSVG src="/icons/arrow-up.svg" className={`arrow-icon ${ isPaymentProfileOpen && "upsidedown"}`}/>
        </ProfileItemHead>
        {isPaymentProfileOpen && <ProfileItemBody>
          <ul>
            <li> <Link to={langPrefix('/paymentsdue')}>{t("nav_user_menu_payments_due")}</Link>  </li>
            <li> <Link to={langPrefix('/paymentshistory')}>{t("nav_user_menu_payments_history")}</Link>  </li>
            <li> <Link to={langPrefix('/deposits')}>{t("nav_user_menu_payments_deposits")}</Link> </li>
            <li> <Link to={langPrefix('/moneywithdrawal')}>{t("nav_user_menu_payments_money_withdrawal")}</Link> </li>
            {/* <li> <Link to={'/fees'}>{t("nav_user_menu_payments_fees")}</Link> </li> */}
          </ul>
        </ProfileItemBody>}
      </ProfileItem>

      {isBuyer ? <ProfileItem>
        <ProfileItemHead to={langPrefix('/buyer/bids/myoffers')}>
          <ReactSVG src="/icons/header/bids.svg" className='profile-icon'/>
          <ProfileItemName>{t('nav_user_menu_buyer_bids')}</ProfileItemName>
        </ProfileItemHead>
      </ProfileItem> :
        <ProfileItem>
          <ProfileItemHead as="div" onClick={() => setIsLotsOpen(!isLotsOpen)}>
            <ReactSVG src="/icons/header/bids.svg" className='profile-icon'/>
            <ProfileItemName>{t('nav_user_menu_seller_my_lots')}</ProfileItemName>
            <ReactSVG src="/icons/arrow-up.svg" className={`arrow-icon ${ isLotsOpen && "upsidedown"}`}/>
          </ProfileItemHead>
          {isLotsOpen && <ProfileItemBody>
            <ul>
              <li> <Link to={langPrefix('/seller/lotsoncheck')}>{t("nav_user_menu_seller_my_lots_lots_on_check")}</Link>  </li>
              <li> <Link to={langPrefix('/seller/onsalelist')}>{t("nav_user_menu_seller_my_lots_on_sale_list")}</Link>  </li>
              <li> <Link to={langPrefix('/seller/myoffers')}>{t("nav_user_menu_seller_my_lots_my_offers")}</Link>  </li>
            </ul>
          </ProfileItemBody>}
      </ProfileItem>
      }

      <ProfileItem>
        <ProfileItemHead to={langPrefix("/mywatchlist")}>
          <HeartFilled />
          <ProfileItemName>{t("nav_main_menu_favorites")}</ProfileItemName>
        </ProfileItemHead>
      </ProfileItem>

      <ProfileItem>
        <ProfileItemHead to={langPrefix("/comparevehicles")}>
          <SyncOutlined />
          <ProfileItemName>{t("nav_main_menu_compare")}</ProfileItemName>
        </ProfileItemHead>
      </ProfileItem>

      <ProfileItem>
        <ProfileItemHead as="div" onClick={() => setIsSettingsVisible(!isSettingsVisible)}>
          <ReactSVG src="/icons/setting-3.svg" className='setting-icon'/>
          <ProfileItemName>{t("nav_user_menu_account_menu")}</ProfileItemName>
          <ReactSVG src="/icons/arrow-up.svg" className={`arrow-icon ${ isSettingsVisible && "upsidedown"}`}/>
        </ProfileItemHead>
        {isSettingsVisible && <ProfileItemBody>
          <ul>
            <li> <Link to={langPrefix('/contactinformation')}>{t("nav_user_menu_account_contact_information")}</Link>  </li>
            <li> <Link to={langPrefix('/accountsettings')}>{t("nav_user_menu_account_account_settings")}</Link>  </li>
            <li> <Link to={langPrefix('/forms')}>{t("nav_user_menu_account_forms")}</Link> </li>
          </ul>
        </ProfileItemBody>}
      </ProfileItem>

      <ProfileItem>
        <ProfileItemHead variant={ProfileItemHeadVariant.LOGOUT} as="div" onClick={handleLogout}>
          <ReactSVG src="/icons/header/logout.svg" className='profile-icon'/>
          <ProfileItemName>{t("Logout")}</ProfileItemName>
        </ProfileItemHead>
      </ProfileItem>
    </ProfileList>
  </ProfileBody>
  )
}

export default ProfileMenu