import React, { useEffect, useState } from 'react'
import ReviewSection from '../../shared/components/ReviewSection'
import { DealerReviewWrapper, ReviewBtn, ReviewForm, Title } from './style'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next'
import { Rate } from 'antd'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { dealerListSelector, getDealers, getDealersById, getSuggestedReviewInfo, updateSuggestedReview } from '../../store/slices/dealerSlice'
import IsNotAvailablePage from '../IsNotAvailablePage'

const initialFormValues = {
  comment: "",
  rate: 0,
}

const DealerReviewPage = () => {
  const { t, i18n } = useTranslation()
  const { guid } = useParams<{ guid: string }>();
  const dispatch = useDispatch()
  const history = useHistory()
  
  const [formValues, setFormValues] = useState(initialFormValues)
  const [dealer, setDealer] = useState<any>(null)

  const onSubmit = (e: any) => {
    e.preventDefault()
    if(formValues.rate == 0) return alert("Rate With Stars is required")
    if(formValues.comment.length == 0) return alert("Comment is required")
    dispatch(updateSuggestedReview({
      oneTimeCode: guid,
      ...formValues,
    }))

    history.push({
      pathname: '/',
    });
  }

  useEffect(() => {
    async function getDealerInfo(){
      const resp: any = await dispatch(getSuggestedReviewInfo(guid))
      const dealer: any = await dispatch(getDealersById(resp.dealerId))
      setDealer(dealer.data)
    }

    getDealerInfo()
  }, [guid])


  if(dealer){
    return (
      <DealerReviewWrapper>
         <div className='review-from-head'>
             <Title>Write a review to <span>{i18n.language == "ka" ? dealer?.dealerNameGeo : dealer?.dealerName}</span></Title>
         </div>
         <ReviewForm onSubmit={onSubmit}>
           <Rate defaultValue={formValues.rate} key={formValues.rate} onChange={(star) => setFormValues({...formValues, rate: star})} />
           <textarea placeholder="Type text..." onChange={(e) => setFormValues({...formValues, comment: e.target.value})} value={formValues.comment}></textarea>
           <ReviewBtn>
             <ReactSVG src="/icons/send.svg" className="svg-wrapper" />
             <span>Send</span>
           </ReviewBtn>
         </ReviewForm>
      </DealerReviewWrapper>
     )
  }else{
    return <IsNotAvailablePage />
  }

}

export default DealerReviewPage