import styled from 'styled-components';
import { Card, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Primary, White, SuccessBgCl, ErrorBgCl, BorderGrey, BannerBgCl, ErrorCl } from '../../shared/shared-styles';
import { OdometerWrapper } from '../../shared/components/AdvanceSearch/style';

export const TableRow = styled.div`
  display: flex;
  gap: 16px;
  h3 {
    margin: 0;
  }
  button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
  }
  border-bottom: 1px solid ${BorderGrey};
  padding: 19px 0;
  align-items: center;
`;

export const TableRowCards = styled(TableRow)`
  align-items: end;
`;

export const TableRowExpand = styled(TableRow)`
  justify-content: flex-end;
`;

export const CompareCell = styled.div`
  padding: 0 50px;
  width: 200px;
`;

export const CompareBanner = styled(Row)`
  background: ${BannerBgCl};
  margin: 0 0 66px;
  padding: 1.5rem;
`;

export const BannerTitle = styled(Title)`
  border-left: 2px solid ${Primary};
  padding: 5px 20px;
  margin: 0 0 12px;
  color: ${Primary} !important;
  span {
    display: block;
    font-size: 16px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 0;
  button {
    border-radius: 0 4px 4px 0;
  }
`;

export const BannerTextContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`;

export const FullWidthContainer = styled.div`
  /* width: 100%; */
  padding: 0 50px;
  overflow-x: auto;
`;

export const ScCompareCard = styled(Card)`
  width: 100%;
  height: 100%;
  .ant-card-cover {
    position: relative;
    .btn-remove {
      opacity: 0;
      position: absolute;
      color: ${White};
      width: 90px;
      border-radius: 17px;
      background: ${ErrorCl};
      border: 2px solid ${White};
      top: 50%;
      right: 50%;
    }
    &:hover {
      .ant-image {
        filter: brightness(0.5);
      }
      .btn-remove {
        opacity: 1;
        animation: shimmy 0.7s;
        animation-fill-mode: forwards;
      }
    }
  }

  @keyframes shimmy {
    0% {
      opacity: 0;
      transform: translate(50%, 50%);
    }
    100% {
      opacity: 1;
      transform: translate(50%, -50%);
    }
  }
`;

export const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TableItem = styled.div`
  flex: 0 0 300px;
  background-color: ${(props: { isEqual: boolean }) => (props.isEqual ? SuccessBgCl : ErrorBgCl)};
`;

export const Cell = styled.div`
  flex: 0 0 300px;
`;

export const CompareContainer = styled.div`
  max-width: 90rem;
  width: 100%;
    margin-right: auto;
  margin-left: auto;
  padding: 0 1.5rem;
`

export const CompareCardList = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 1rem;
  padding-bottom: 10rem;
  @media(max-width: 1200px){
    grid-template-columns: repeat(3,1fr);
  }
  @media(max-width: 991px){
    grid-template-columns: repeat(2,1fr);
  }
  @media(max-width: 767px){
    grid-template-columns: repeat(1,1fr);
  }
`

export const CompareCardWrapper = styled.div`
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: #FFF;
  box-shadow: 0px 0px 50px 0px #DFDFDF;
  position: relative;
  button{
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 100px;
    background: #F6F6F6;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    div, svg{
      width: 1.125rem;
      height: 1.125rem;
    }
  }
  &:hover{
    button{
      display: flex;
    }
  }
  @media(max-width: 767px){
    button{
      display: flex;
    }
  }
`

export const CompareCardImage = styled.div`
  margin-bottom: 2rem;
  max-width: calc( 100% - 1.5rem );
  height: 8.6875rem;
    margin-right: auto;
  margin-left: auto;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
` 

export const CompareContent = styled.div`
  h3{
    color: #1B153D;
    font-size: 0.875rem;
    font-family: main;
    font-weight: 600;
    line-height: 0.875rem;
    text-align: center;
  }
  p{
    color: #BD362F;
    font-size: 0.75rem;
    font-family: main;
    font-weight: 500;
    line-height: 0.875rem;
    text-align: center;
  }
  ul{
    margin-top: 1.25rem;
    padding: 0;
    li{
      display: flex;
      justify-content: space-between;
      color: #9095A6;
      font-size: 0.75rem;
      font-family: main;
      line-height: 0.875rem;
      padding-block: 0.75rem;
      &:not(:last-child){
        border-bottom: 1px solid #EAEAEA;
      }
      strong{
        color: #1B153D;
        font-size: 0.75rem;
        font-family: main;
        font-weight: 500;
        line-height: 0.875rem;
      }
      ${OdometerWrapper}{
        flex-direction: row;
      }
    }
  }
`

export const CompareContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.75rem;
  h2{
    color: #080808;
    font-size: 1.5rem ;
    font-family: main;
    font-weight: 600;
    line-height: 1.5rem;
    margin: 0;
  }
  @media(max-width: 767px){
    h2{
      color: #1B153D;
      font-size: 1.125rem;
      font-family: main;
      font-weight: 600;
      line-height: 1.375rem;
    }
  }
`