import React from 'react'
import { CompanyCardWrapper, ContentWrapper, ImageWrapper } from './style'
import { Link } from 'react-router-dom'

interface CompanyCard {
  company: any
}

const CompanyCard = ({company}: CompanyCard) => {
  return (
    <CompanyCardWrapper>
        <ImageWrapper>
            {company.image 
            ? <img src={`${process.env.REACT_APP_DEALER_IMAGE}${company.image}`} /> 
            : <img src={`/img/person.png`} />}
        </ImageWrapper>
        <ContentWrapper>
            <h3>{company.dealerName}</h3>
            <p>{company.description}</p>
            <Link to={`/operated-by-liontrans/${company.dealerId}`}>Read More</Link>
        </ContentWrapper>
    </CompanyCardWrapper>
  )
}

export default CompanyCard