import React from 'react'
import { DepositCardFooter, DepositCardWrapper } from './style'
import { Deposits } from '../../interfaces/Payments'
import DateForTable from '../../shared/components/DateForTable'
import Price from '../../shared/components/Price'
import dayjs from 'dayjs';
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

interface DepositCardProps {
    deposit: Deposits;
    handleDownloadPdf: any;
    handleGetPayUrl: any
}

const DepositCard = ({deposit, handleDownloadPdf, handleGetPayUrl}: DepositCardProps) => {
    const createDate = dayjs(deposit.createdDate);
    const paidDate = dayjs(deposit.paidDate);

    const { t } = useTranslation()
  return (
    <DepositCardWrapper>
        <ul>
            <li>
                <span>Payment</span>
                <p>{deposit.paymentNumber}</p>
            </li>

            {deposit.createdDate && (<li>
                <span>Created Date</span>
                <p>{createDate.format('DD/MM/YYYY')}</p>
            </li>)}

            {deposit.paidDate && (<li>
                <span>Paid Date</span>
               <p>{paidDate?.format('HH:mm A')}</p>
            </li>)}

            <li>
                <span>Sum</span>
                <p> <Price price={deposit.sum} /> </p>
            </li>
            <li>
                <span>Status</span>
                <p>{deposit.status}</p>
            </li>
        </ul>
        <DepositCardFooter>
            <Button size="middle" type="primary" onClick={handleDownloadPdf(deposit.id, deposit.id)}>
                {t('downloadInvoice')}
            </Button>
          {!deposit.paidDate && (
            <Button size="middle" type="default" onClick={handleGetPayUrl(deposit.paymentNumber)}>
              {t('payNow')}
            </Button>
          )}
        </DepositCardFooter>
    </DepositCardWrapper>
  )
}

export default DepositCard