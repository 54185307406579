import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { CalculatorWrapper, SliderItemContent, SliderItemDesc, SliderItemTitle, SliderItemWrapper, SliderWrapper } from './style';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import UsedCarCalculator from '../UsedCarCalculator';

const SellerMainSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { t } = useTranslation()

  return (
    <SliderWrapper>
        <SliderItemWrapper>
          <SliderItemContent>
            <div>
              <SliderItemTitle>
                {parse(t("seller_banner_title"))}
              </SliderItemTitle>
              <SliderItemDesc>
                <p>{t('seller_banner_desc_p1')}</p>
                <p>{t('seller_banner_desc_p2')}</p>
                <p>{t('seller_banner_desc_p3')}</p>
              </SliderItemDesc>
            </div>
            <CalculatorWrapper>
              <UsedCarCalculator />
            </CalculatorWrapper>
          </SliderItemContent>
          <img src="/img/banners/slider-banner.webp" alt="slider image" />
        </SliderItemWrapper>
      {/* <Slider {...settings} > */}
        {/* <SliderItemWrapper>
        </SliderItemWrapper>
        <SliderItemWrapper>
          <img src="/img/banners/slider-banner.webp" alt="slider image" />
        </SliderItemWrapper> */}
      {/* </Slider> */}
    </SliderWrapper>
  );
};

export default SellerMainSlider;
