import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const SubHeaderWrapper = styled.div`
  padding: 0 2.5rem;
  background: #080808;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  @media(max-width: 1400px){
    padding: 0 1rem;
  }
  @media(max-width: 767px){
    background: #000000;
    flex-direction: column-reverse;
    gap: 3rem;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-shrink: 0;

  .nav-link {
    position: relative;
  }
  .nav-link .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: -4%;
    background-color: #000;
    padding-right: 12px;
    padding-left: 12px;
    z-index: 1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    opacity: 0;
    line-height: 1;

  a {
    height: 0;
    margin: 0;
  }
}

.nav-link:hover .dropdown-menu {
  display: block;
  opacity: 1;
}

  @media(max-width: 767px){
    flex-direction: column;
    align-items: center;
  }
`;

export enum NavLinkVariant {
  PRIMARY,
  ACTIVE,
}

interface NavLinkProps {
  variant?: NavLinkVariant
}

export const NavLink = styled(Link)<NavLinkProps>`
  font-family: 'main';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #C2C2C2;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;
  height: 3rem;
  padding: 1.5rem 0;
  box-sizing: content-box;
  margin-right: 1rem;
  &:lang(ka){
    font-size: 0.875rem;
    font-family: "arial-caps"
  }

  .svg-wrapper{
    margin-left: 0.75rem;
    transform: rotate(180deg)
    &, & > div{
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    path{
      stroke: #C2C2C2
    }
  }
  &:hover, &:active, &.active{
    color: #ffffff;
    path{
      stroke: #ffffff
    }
  }
  @media(max-width: 1200px){
    font-size: 0.875rem;
  }
  @media(max-width: 991px){
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    .svg-wrapper{
      margin-left: 0.25rem;
    }
  }

  @media(max-width: 767px){
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #FFFFFF;
    height: unset;
    box-sizing: border-box;
    padding: 0;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }

  ${p => p.variant == NavLinkVariant.ACTIVE && css`
    color: #fff;
    .svg-wrapper{
      transform: rotate(0deg)
    }
  `}
`;