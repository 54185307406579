import React, { MouseEvent, useState } from 'react';
import { BuyBtn, ContentWrapper, FeaturesList, FeaturesListItem, ImageWrapper, MainInfoWrapper, NewsCarWrapper, CustomPrice, SubTitle, Title, BuyBtnSkeleton, ImageWrapperSkeleton, ActionButtons, ScSlider, ScSpin, SliderButtonWrapper } from './style';
import { ReactSVG } from 'react-svg';
import { Vehicle } from '../../../interfaces/FindVehicles';
import { getLinkForLotPage, getOdoValue } from '../../../utils/functions';
import { odometrIsKmSelector } from '../../../store/slices/initSlice';
import { useSelector } from 'react-redux';
import Price from '../../../shared/components/Price';
import ComperaButton from '../../../shared/components/AdvanceSearch/ComperaButton';
import WatchlistButton from '../../../shared/components/AdvanceSearch/WatchlistButton';
import { useTranslation } from 'react-i18next';

interface NewCarsCardProps {
  product: Vehicle;
  isLoading?: boolean;
}

function Arrow(props: any) {
  const { isPrevArrow, onClick } = props;
  return (
    <SliderButtonWrapper onClick={onClick} isPrevArrow={isPrevArrow}>
      <ReactSVG src='/icons/arrow-left.svg' className='svg-wrapper'/>
    </SliderButtonWrapper>
  );
}


export const NewCarsCardLoadingSkeleton = () => {
  return <NewsCarWrapper>
    <ImageWrapperSkeleton />
    <ContentWrapper>
        <MainInfoWrapper isLoading={true} />
        <FeaturesList isLoading={true} />
        <BuyBtnSkeleton/>
      </ContentWrapper>
  </NewsCarWrapper>
}

const NewCarsCard = ({product, isLoading}: NewCarsCardProps) => {
  const odometrIsKm = useSelector(odometrIsKmSelector);

  const [imageLoaded, setImageLoaded] = useState(false);
  const [images, setImages] = useState([product.mainImage, ...product.imageUrls.filter(url => url != product.mainImage)]);
  const [imgChaned, setImgChanged] = useState(false);

  const handleLoaded = () => {
    setTimeout(() => setImageLoaded(true), 1000);
  };

  const handleUpdateSliderImgs = () => {
    setImageLoaded(false)
    setTimeout(() => setImageLoaded(true), 1000);
    return 
    if (imgChaned === false) {
      setImages([product.mainImage, ...product.imageUrls.filter(url => url != product.mainImage)]);
      setImgChanged(true);
      setImageLoaded(false);
    }
  };

  const { t } = useTranslation()

  const settings = {
    dots: false,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow isPrevArrow={false} />,
    prevArrow: <Arrow isPrevArrow={true} />,
  };

  if(isLoading){
    return <NewCarsCardLoadingSkeleton />
  }

  return (
    <NewsCarWrapper>
      <ImageWrapper to={getLinkForLotPage(
          product.lotNumber,
          product.make,
          product.model,
          product.year,
        )}
        >
          <ScSlider {...settings} beforeChange={handleUpdateSliderImgs}>
            {images.length && product.mainImage ? (
                images.map((item) => {
                  let convImage = 'https://image.liontrans.com/insecure/w:322/h:242/f:webp/plain/'+item;
                  return (
                  <ScSpin spinning={!imageLoaded} key={item} size="large">
                    <img src={convImage} height="100%" alt={product.description} onLoad={handleLoaded} />
                  </ScSpin>
                )})
              ) : (
                <ScSpin spinning={!imageLoaded} size="large">
                  <img src="img/noImg.jpg" height="100%" alt={product.description} onLoad={handleLoaded} />
                </ScSpin>
              )}
            {product.imageUrls.map((url) => {
              <div className='tamasfasf asf asfasnflasnf asf sfak f'>
                <img src={url} alt="" />
              </div>
            })}
          </ScSlider>
          <span>#{product.lotId || product.lotNumber}</span>
          <ActionButtons>
            <ComperaButton onClick={(e: MouseEvent<HTMLElement>) =>  e.preventDefault()} id={product.lotNumber} />
            <WatchlistButton onClick={(e: MouseEvent<HTMLElement>) =>  e.preventDefault()} id={+product.lotNumber} />
          </ActionButtons>
      </ImageWrapper>
      <ContentWrapper>
        <MainInfoWrapper>
          <div>
            <Title>{`${product.make} ${product.model} ${product.year}`}</Title>
            <SubTitle>{product.locationName}</SubTitle>
          </div>
          <CustomPrice>
            <Price price={product.buyNowPrice}/>
          </CustomPrice>
        </MainInfoWrapper>

        <FeaturesList>
          <FeaturesListItem>
            <ReactSVG src="/icons/color-rolling-brush.svg" className="icon-wrapper" />
            {product.color}
          </FeaturesListItem>
          <FeaturesListItem>
            <ReactSVG src="/icons/gas-load.svg" className="icon-wrapper" />
            {product.fuelType}
          </FeaturesListItem>
          <FeaturesListItem>
            <ReactSVG src="/icons/folder-check.svg" className="icon-wrapper" />
            {product.clearancePassed ? 'Cleared' : 'Before'}
          </FeaturesListItem>
          <FeaturesListItem>
            <ReactSVG src="/icons/car-dashboard-gear.svg" className="icon-wrapper" />
            {product.transmission}
          </FeaturesListItem>
          <FeaturesListItem>
            <ReactSVG src="/icons/oil-burning.svg" className="icon-wrapper" />
            {product.engineType}
          </FeaturesListItem>
          <FeaturesListItem>
            <ReactSVG src="/icons/gauge-dashboard.svg" className="icon-wrapper" />
            {getOdoValue(+product.odometer, odometrIsKm, product.odometerBrand)}
          </FeaturesListItem>
        </FeaturesList>

        <BuyBtn to={getLinkForLotPage(
          product.lotNumber,
          product.make,
          product.model,
          product.year,
        )}
        >{t("View Lot")}</BuyBtn>
      </ContentWrapper>
    </NewsCarWrapper>
  );
};

export default NewCarsCard;
