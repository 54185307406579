import React, { FC, memo, useState, useCallback } from 'react';
import { Button, Col, Row, Typography, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CompareBanner, BannerTitle, SearchContainer, BannerTextContainer } from '../style';
import { compareIdsSelector, addToCompare } from '../../../store/slices/compareSlice';
import { axiosInstance } from '../../../core/axios';
import { showNotification } from '../../../utils/notify';
import { useTranslation } from 'react-i18next';
import api from '../../../core/api';

const { Text } = Typography;

const CompareBannerContainer: FC = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState<number | undefined>();

  const compareIds = useSelector(compareIdsSelector);
  const { t } = useTranslation();

  const checkExist = async (currentIds: number[], lotId: number) => {
    try {
      await axiosInstance.get(api.GetPublicOneLotPageData, {
        params: {
          lotId,
        },
      });

      const compareList = [...currentIds];
      const index = compareList.indexOf(lotId);
      if (index < 0) {
        compareList.push(lotId);
        dispatch(addToCompare(compareList));
      }
      if (index >= 0) showNotification('error', t("compare_errortext_1"));
    } catch {
      showNotification('error', t("compare_errortext_2"));
    }
  };

  const handleAddButton = useCallback(
    () => {
      id && checkExist(compareIds, id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [compareIds, id],
  );

  return (
    <CompareBanner justify="center">
      <Col xs={24} lg={16}>
        <Row>
          <Col xs={24} lg={12}>
            <BannerTextContainer>
              <BannerTitle level={4}>
                <span>{t("compare_bannertext_1")}</span>
                {t("compare_bannertext_2")}
              </BannerTitle>
              <Text type="secondary">
              {t("compare_bannertext_3")}
              </Text>
              <SearchContainer>
                <InputNumber min={0} onChange={setId} placeholder={t("compare_input_placeholder")} />
                <Button type="primary" onClick={handleAddButton}>
                {t("compare_add_button")}
                </Button>
              </SearchContainer>
            </BannerTextContainer>
          </Col>
          <Col xs={0} lg={12}>
            <img alt="People on banner" src="img/compare-banner.png" />
          </Col>
        </Row>
      </Col>
    </CompareBanner>
  );
};

export default memo(CompareBannerContainer);
