import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from 'antd/lib/mentions';
import { SelectValue } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { isAuthenticatedSelector, setUserLanguage, userLanguageSelector } from '../../../../store/slices/authSlice';
import { getProductGeneralDictionariesByLanguage } from '../../../../store/slices/dictionarySlice';
import { StyledSelect } from './style';
import { useCookies } from 'react-cookie';
import { changeLanguage } from '../../../../store/slices/profileSlice';
import { abbrToLangMapping } from '../../../../config/language';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';

const SelectLanguage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(['language']);
  const userLanguage = useSelector(userLanguageSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const selectRef = useRef<any>(null);
  const history = useHistory();
  const location = useLocation();

  const handleChangeLanguage = (lng: SelectValue) => {
    i18n.changeLanguage(lng as 'en' | 'ru' | 'ka');
    setCookie('language', lng, { path: '/' });
    dispatch(getProductGeneralDictionariesByLanguage(lng as 'en' | 'ru' | 'ka'));
  
    const currentPathname = location.pathname.replace(/^\/(en|ru|ka)/, '');
    const newPath = lng === 'ka' ? currentPathname : `/${lng}${currentPathname}`;
    history.push(newPath);
  
    if (isAuthenticated) {
      const lang = abbrToLangMapping[lng as string].id;
      lang && dispatch(changeLanguage(lang));
    }
  };
  

  const handleScroll = () => {
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  useEffect(() => {
    const pathLang = location.pathname.split('/')[1];
    const storedLanguage = (pathLang === 'en' || pathLang === 'ru') ? pathLang : 'ka';
    let languageToSet = 'ka';
    if (['en', 'ru'].includes(pathLang)) {
      languageToSet = pathLang;
    } else {
      if (storedLanguage !== 'ka') {
        const newPath = location.pathname.startsWith('/') ? `/${storedLanguage}${location.pathname}` : `/${storedLanguage}`;
        history.push(newPath);
        return;
      }
    }
    setCookie('language', languageToSet, { path: '/' });
    dispatch(getProductGeneralDictionariesByLanguage(languageToSet));
    dispatch(setUserLanguage(languageToSet));
    i18n.changeLanguage(languageToSet);
    document.documentElement.lang = languageToSet;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [cookies.language, dispatch, history, i18n, location.pathname]);


  return (
    <StyledSelect ref={selectRef} value={userLanguage} onChange={handleChangeLanguage}>
      <Option value="ru"><img className="langIcon" src="/img/ru_flag.webp" alt="Russian Flag" /> {t('Russian')}</Option>
      <Option value="en"><img className="langIcon" src="/img/uk_flag.webp" alt="English Flag" /> {t('English')}</Option>
      <Option value="ka"><img className="langIcon" src="/img/ka_flag.webp" alt="Georgia Flag" /> {t('Georgian')}</Option>
    </StyledSelect>
  );
};

export default SelectLanguage;