/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Space, Button, Row, Col, TablePaginationConfig } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  getPayments,
  paymentsSelector,
  userDepositsListSelector,
  userDepositsListTotalCountSelector,
  userDepositsListIsLoadingSelector,
  getUserDepositsList,
  createDepositPayment,
  GetPaymentOrderUrl,
  downloadPdfBase64,
} from '../../store/slices/paymentsSlice';
import AddDepositModal from './AddDepositModal';
import DateForTable from '../../shared/components/DateForTable';
import CustomTable from '../../shared/components/CustomTable';
import Price from '../../shared/components/Price';
import { AboutDepositButton, AmountBody, AmountHeader, AmountWrapper, DepositCardList, DepositHeader, DepositWrapper, PaginationWrapper } from './style';
import { ReactSVG } from 'react-svg';
import AboutDeposit from './AboutDeposit';
import Amount from './Amount';
import DepositCard from './DepositCard';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import CustomPagination from '../../shared/components/CustomPagination';

const { Title, Text } = Typography;

const DepositsPage: FC = () => {
  const { t } = useTranslation();
  const { md } = useBreakpoint()
  const dispatch = useDispatch();
  const [tableSettings, setTableSettings] = useState<{
    Page?: number;
    PageItemsCount?: number;
    SortField?: string;
    SortOrder: string;
  }>({
    Page: 1,
    PageItemsCount: 10,
    SortField: '',
    SortOrder: 'desc',
  });
  const [openDepositModal, setOpenDepositModal] = useState<boolean>(false);

  const handleChangePagination = (page: number, pageSize?: number | undefined) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    
    setTableSettings({
      ...tableSettings,
      Page: page || 1,
      PageItemsCount: pageSize || 10,
    })
  };

  useEffect(() => {
    dispatch(getPayments());
    dispatch(getUserDepositsList(tableSettings.SortOrder, tableSettings.Page, tableSettings.PageItemsCount, tableSettings.SortField));
  }, [dispatch, tableSettings]);

  const payments = useSelector(paymentsSelector);
  const userDepositsList = useSelector(userDepositsListSelector);
  const userDepositsListTotalCount = useSelector(userDepositsListTotalCountSelector);
  const userDepositsListIsLoading = useSelector(userDepositsListIsLoadingSelector);

  const handleTableChange = useCallback(
    (pg: TablePaginationConfig, fltr: any, sort: any) => {
      setTableSettings({
        Page: pg.current,
        PageItemsCount: pg.pageSize,
        SortField: sort.field,
        SortOrder: sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc',
      });
      dispatch(
        getUserDepositsList(sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc', pg.current, pg.pageSize, sort.field),
      );
    },
    [dispatch],
  );
      
  const paginationProps = {
    showSizeChanger: true,
    total: userDepositsListTotalCount,
    current: tableSettings.Page,
    pageSize:  tableSettings.PageItemsCount,
}

  const handleGetPayUrl = useCallback(
    (id: string) => () => {
      dispatch(GetPaymentOrderUrl(id));
    },
    [dispatch],
  );

  const handleDownloadPdf = useCallback(
    (id: number, title: string) => () => {
      dispatch(downloadPdfBase64(title, `UserPayment/GetDataForDepositInvoices/?paymentsIdList=${id}`));
    },
    [dispatch],
  );

  const columns = [
    {
      title: t("deposit_table_payment"),
      dataIndex: 'paymentNumber',
      key: 'paymentNumber',
    },
    {
      title: t("deposit_table_createddate"),
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (createdDate: string) => (createdDate ? <DateForTable date={createdDate} /> : ''),
    },
    {
      title: t("deposit_table_paiddate"),
      dataIndex: 'paidDate',
      key: 'paidDate',
      render: (paidDate: string) => (paidDate ? <DateForTable date={paidDate} /> : ''),
    },
    {
      title: t("deposit_table_sum"),
      dataIndex: 'sum',
      key: 'sum',
      render: (sum: number) => <Price price={sum} />,
    },
    {
      title: t("deposit_table_status"),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return t(status);
      },
    },
    {
      title: t("deposit_table_action"),
      dataIndex: 'paymentNumber',
      key: 'paymentNumber',
      render: (paymentNumber: string, data: any) => (
        <Space>
          <Button size="middle" type="primary" onClick={handleDownloadPdf(data.id, data.id)}>
            {t('downloadInvoice')}
          </Button>
          {!data.paidDate && (
            <Button size="middle" type="default" onClick={handleGetPayUrl(paymentNumber)}>
              {t('payNow')}
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const handleAddDeposit = useCallback(() => {
    setOpenDepositModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenDepositModal(false);
  }, []);

  const handleSubmit = useCallback(
    (value) => {
      dispatch(createDepositPayment(value, tableSettings.SortOrder, tableSettings.Page, tableSettings.PageItemsCount, tableSettings.SortField));
    },
    [dispatch, tableSettings],
  );

  return (
    <DepositWrapper>
        {/* <Col xs={12} md={12} lg={6}>
          <Title level={5}>
            {t('deposit_current_deposit')}:
            <Price price={payments.depositAmount} />
          </Title>
          <Title level={5}>
            {t('deposit_max_bidding_power')}:
            <Price price={payments.maxBiddingPower} />
          </Title>
          <Title level={5}>
            {t('deposit_current_bidding_power')}:
            <Price price={payments.biddingPower} />
          </Title>
        </Col>
        <Col xs={6} md={6} lg={3}>
          <Space direction="vertical">
            <Title level={5}>
              {t('deposit_bonus_amount')}:
              <Price price={payments.bonusAmount} />
            </Title>
            <Title level={5}>
              {t('deposit_dealer_amount')}:
              <Price price={payments.balanceAmount} />
            </Title>
            <Title level={5}>
              {t('deposit_issued_amount')}:
              <Price price={payments.issuedAmount} />
            </Title>
          </Space>
        </Col>
        <Col xs={6} md={6} lg={3}>
          <Space direction="vertical">
            <Title level={5}>
              {t('user_package')}: {payments.membershipPackage}
            </Title>
            <Button type="primary" onClick={handleAddDeposit} icon={<PlusCircleOutlined />}>
              {t('deposit_add_deposit')}
            </Button>
          </Space>
        </Col>
        <Col xs={24} md={24} lg={12}>
          <Space direction="vertical">
            <Text strong>{t('deposit_text_title')}:</Text>
            <ol>
              <li>{t('deposit_text_1')}</li>
              <li>{t('deposit_text_2')}</li>
              <li>{t('deposit_text_3')}</li>
              <li>{t('deposit_text_4')}</li>
              <li>{t('deposit_text_5')}</li>
            </ol>
          </Space>
        </Col> */}
        <DepositHeader>
          <Amount payments={payments} />
          <AboutDeposit />
          <Button type="primary" onClick={handleAddDeposit} icon={<PlusCircleOutlined />}>
            {t('deposit_add_deposit')}
          </Button>
        </DepositHeader>
        {md 
          ? (<CustomTable
              data={userDepositsList.map((item) => ({ ...item, key: item.id }))}
              columns={columns}
              totalCount={userDepositsListTotalCount}
              isLoading={userDepositsListIsLoading}
              onChangeTable={handleTableChange}
              currentPage={paginationProps.current}
              pageSize={paginationProps.pageSize}
              onPaginationChange={handleChangePagination}
              scroll={{ x: 'max-content' }}
            />)
          : (
            <>
              <DepositCardList>
                {userDepositsList.map((deposit) => <DepositCard deposit={deposit} handleDownloadPdf={handleDownloadPdf} handleGetPayUrl={handleGetPayUrl}  key={deposit.id} />)}
              </DepositCardList>
              {userDepositsList.length > 0 && <PaginationWrapper>
                <CustomPagination {...paginationProps} onChange={handleChangePagination}/>
              </PaginationWrapper>}
            </>
            
        )}
        <AddDepositModal
          isOpen={openDepositModal}
          onCancel={handleClose}
          onSubmit={handleSubmit}
          biddingPower={payments.biddingPower}
          depositAmount={payments.depositAmount}
        />
    </DepositWrapper>
  );
};

export default memo(DepositsPage);
