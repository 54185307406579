import styled from 'styled-components';

export const DealerSearchWrapper = styled.div`
  padding: 2.5rem 1.125rem 1.125rem;
`;

export const SearchWrapper = styled.div`
  position: relative;
  max-width: 44.375rem;
  width: 100%;
  margin: 0 auto 2.5rem;
  @media(max-width: 767px){
    input {
      padding-right: 37px;
    }
    margin-bottom: 1.25rem;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0.75rem 1.25rem;
  padding-left: 2rem;
  border: none;
  border: 1px solid #a5aaac;
  backdrop-filter: blur(2px);
  border-radius: 0.5rem;
  background: #ffffff;
  &::placeholder {
    font-family: 'main';
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #969595;
  }
  &:focus {
    outline: none;
  }
  @media(max-width: 767px){
    padding: 0.5rem 0.875rem;
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 1rem;
  font-size: 1.25rem;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .svg-wrapper {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.125rem;
      height: 1.125rem;
    }
  }
`;


export const DealerList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.125rem;
  @media(max-width: 1200px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width: 991px){
    grid-template-columns: repeat(1, 1fr);
    max-height: calc(3 * 8rem);
    overflow-y: auto;
  }
`;

export const AlertInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;