import React from 'react'
import { PartnerCard, PartnerSliderWrapper, PartnerWrapper } from './style'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { ReactSVG } from 'react-svg';

function SampleNextArrow(props: any) {
    const { className, onClick } = props;
    return (
        <ReactSVG 
            src='/icons/arrow-left.svg'
            className={`partner-next-arrow`} 
            onClick={onClick}
        />
    );
  }
  
  function SamplePrevArrow(props: any) {
    const { className, onClick } = props;
    return (
        <ReactSVG 
            src='/icons/arrow-left.svg'
            className={`partner-prev-arrow`} 
            onClick={onClick}
        />
    );
  }

const Partners = () => {
    
  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 2,
    variableWidth: true,
    nextArrow: <SampleNextArrow onClick={() => console.log('clicked')}/>,
    prevArrow: <SamplePrevArrow onClick={() => console.log('clicked')}/>,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          variableWidth: false,
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
    ]
  };


  return (
    <PartnerWrapper>
        <PartnerSliderWrapper>
            <Slider {...settings}>
                {Array(6).fill("").map((_,index) => (
                    <PartnerCard key={index}>
                        <img src={`/img/about_us/partner-${index + 1}.png`} alt=''/>
                    </PartnerCard>
                ))}
            </Slider>
        </PartnerSliderWrapper>
    </PartnerWrapper>
  )
}

export default Partners