import styled from 'styled-components';

export const SliderWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  background: #16120f;
  & .slick-arrow {
    display: none !important;
  }
`;

export const SliderItemWrapper = styled.div`
  color: #fff;
  //cursor: pointer;
  width: 100%;
  overflow: hidden;
  position: relative;
  video{
    width: 100%;
    margin-top: -225px;
  }
  &.alliance{
    img{
      width: 100%;
      object-position: top;
    }
  }
  &.moige {
    img{
      width: 100%;
      object-position: top;
    }
  }
  @media(max-width: 1200px){
    video{
      margin-top: -50px;
    }
  }
  @media(max-width: 991px){
    aspect-ratio: unset;
    margin-bottom: -10px;
    video{
      margin-top: 0px;
    }
  }
  @media(max-width: 767px){
    img {
      max-height: 600px;
      object-fit: contain;
      min-height: 200px;
    }
  }
  @media(max-width: 580px){
    img{
      object-fit: cover;
    }
  }
`;

export const SliderItemContent = styled.div`
    max-width: 80vw;
    padding: 1rem 2.5rem 0rem 2.5rem;
    width: 40vw;
    position: absolute;
    top: 49%;
    right: 68%;
    -webkit-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    z-index: 2;
    text-align: right;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5), 0px 0px 20px rgba(255, 255, 255, 0.5);
    perspective: 1000px;
    transform-style: preserve-3d;
    border-radius: 20px;
    backdrop-filter: blur(10px);

  @media(max-width: 1440px){
    top: 45%;
    right: 68%;
    width: 40.8vw;
  }
  @media(max-width: 991px){
    top: 33%;
    right: 70%;
    width: 42vw;
  }
  @media(max-width: 767px){
    top: 27%;
    right: 50%;
    width: 49vw;
  }
  @media(max-width: 580px){
    top: 27%;
    right: 50%;
    width: 44dvh;
  }
`;

export const SliderItemTitle = styled.h3`
  font-family: 'main';
  &:lang(ka){
    font-family: 'arial-caps';
  }
  font-weight: 700;
  font-size: 5.9vw;
  line-height: 6vw;
  color: #F9EED7;
  margin-bottom: 0;
  span{
    color: #CC0505;
    display: block;
  }
  @media(max-width: 1440px){
    font-size: 4.8vw;
    line-height: 4.7vw;
  }
  @media(max-width: 767px){
    font-size: 2.8vw;
    line-height: 2.5rem;
  }
  @media(max-width: 580px){
    font-size: 4.8vw;
    line-height: 2.5rem;
  }
` 

export const SliderItemDesc = styled.p`
    font-family: 'main';
    &:lang(ka){
      font-family: 'arial-caps';
    }
    font-weight: 600;
    font-size: 3.8vw;
    line-height: 4.4vw;
    color: #F9EED7;
    max-width: 37.2vw;
    margin-bottom: 0.7vw;
    span{
      display: block;
      font-family: 'main';
      &:lang(ka){
        font-family: 'arial-caps';
      }
      font-weight: bold;
      font-size: 6vw;
      line-height: 6vw;
      letter-spacing: 0.4vw;
    }
    p{
      font-size: 5.2vw;
      line-height: 5.1vw;
      margin-bottom: 0;
    }
  @media(max-width: 1440px){
    font-size: 3.8vw;
    line-height: 4.4vw;
    max-width: 37.2vw;
  }
  @media(max-width: 991px){
    font-size: 3.5vw;
    line-height: 4.4vw;
    max-width: 37.2vw;
  }
  @media(max-width: 767px){
    max-width: 36vw;
    font-size: 3.6vw;
    display: block;
    line-height: 4.4vw;
  }
  @media(max-width: 580px){
    max-width: 70vw;
    font-size: 5.6vw;
    display: block;
    line-height: 6.7vw;
    margin-bottom: 5vw;
    p {
      font-size: 8vw;
      line-height: 10vw;
    }
    span {
      font-size: 8vw;
      line-height: 8vw;
    }
  }
`