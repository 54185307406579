import React, {useState, useMemo} from 'react';
import { Rate } from 'antd';
import { ReactSVG } from 'react-svg';
import ReviewCard from '../ReviewCard';
import { ReviewWrapper, ReviewBtn, ReviewCardList, ReviewForm, Title, CustomPaginationWrapper } from './style';
import CustomPagination from '../../../shared/components/CustomPagination';
import { useTranslation } from 'react-i18next';

const initialFormValues = {
  reviewer: "",
  comment: "",
  rate: 0,
}

const ReviewSection = ({reviews, onAddReview}: any) => {
  const sortedReviews = useMemo(() => reviews.sort((a: any,b : any) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime() ), [reviews]) 
  const { t } = useTranslation()
  
  const [formValues, setFormValues] = useState({
    reviewer: "",
    comment: "",
    rate: 0,
  })

  const onSubmit = (e: any) => {
    e.preventDefault()
    onAddReview(formValues)
    setFormValues(initialFormValues)
  }

  return (
    <ReviewWrapper>
      <Title>{t("seller_contact_write_review")}</Title>
      <ReviewForm onSubmit={onSubmit}>

        <div className='review-from-head'>
          <input type="text" placeholder={t("reg_per_first_name")} onChange={(e) => setFormValues({...formValues, reviewer: e.target.value})} value={formValues.reviewer}/>
          <Rate defaultValue={formValues.rate} key={formValues.rate} onChange={(star) => setFormValues({...formValues, rate: star})} />
        </div>

        <textarea placeholder={t("seller_contact_textarea")} onChange={(e) => setFormValues({...formValues, comment: e.target.value})} value={formValues.comment}></textarea>
        <ReviewBtn>
          <ReactSVG src="/icons/send.svg" className="svg-wrapper" />
          <span>Send</span>
        </ReviewBtn>
      </ReviewForm>
      <ReviewCardList>
        {sortedReviews
        .map((review: any, index: number) => <ReviewCard key={index} review={review}/>
        )}
      </ReviewCardList>
    </ReviewWrapper>
  );
};

export default ReviewSection;
