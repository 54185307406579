import React from 'react'
import { useTranslation } from 'react-i18next'
import LotCardDescription, { LotCardRow, LotCardText } from '../../../shared/components/LotDescriptionCard'

const MainCard = ({vehicle}: any) => {

  const { t } = useTranslation()

  return (
    <LotCardDescription  title={t("electric_description_main")}>
        <>
            <LotCardRow>
                <LotCardText>{t("battery_heating_cooling_system")}</LotCardText>
                <LotCardText>{vehicle?.main?.battaryHeating ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("range")}</LotCardText>
                <LotCardText>{vehicle?.main?.range}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("battery_capacity")}</LotCardText>
                <LotCardText>{vehicle?.main?.battaryCapacity}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("battery_type")}</LotCardText>
                <LotCardText>{vehicle?.main?.battaryType}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("wheel_drive")}</LotCardText>
                <LotCardText>{vehicle?.main?.wheelDrive}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("engine_power")}</LotCardText>
                <LotCardText>{vehicle?.main?.enginePower}</LotCardText>
            </LotCardRow>
        </>
    </LotCardDescription>
  )
}

export default MainCard