import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const Title = styled.h3`
    color: #1B153D;
    font-family: 'main';
    &:lang(ka){
        font-family: 'arial-caps';
    }
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 3.25rem;
    text-align: center;
    margin-bottom: 2rem;
    @media(max-width: 991px){
        font-size: 1.25rem;
        line-height: 2rem;
        margin-bottom: 1.5rem;
    }
`

export const Input = styled.input`
    border-radius: 0.25rem;
    border: 1px solid #A5AAAC;
    background: #FFF;
    height: 3rem;
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    color: #1B153D;
    padding: 0.75rem 1rem;
    &::placeholder{
        color: #566063;
        font-family: main;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem
    }
`

export const Textarea = styled.textarea`
    border-radius: 0.25rem;
    border: 1px solid #A5AAAC;
    background: #FFF;
    margin-top: 0.75rem;
    margin-bottom: 2rem;
    height: 11.5rem;
    width: 100%;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    color: #1B153D;
    padding: 0.75rem 1rem;
    resize: none;
    &::placeholder{
        color: #566063;
        font-family: main;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem
    }
`

export const Button = styled.button`
    width: 100%;
    height: 3rem;
    border-radius: 0.5rem;
    background: #CE2424;
    color: #FFF;
    text-align: center;
    font-family: main;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.375rem; 
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    .svg-wrapper{
        &, div, svg{
            height: 1.5rem;
            width: 1.5rem;
        }
    }
`