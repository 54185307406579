import React, { FC, useEffect, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CheckSquareFilled, DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ScFormItem, ScSelect, FormWrapper, ScTreeSelect, ClearButton, DropdownFields, DropdownOdo, ScSlider } from '../style';
import { allAuctionsAddressesAsDictionarySelector, getAllAuctionsAddressesAsDictionary } from '../../../store/slices/auctionSlice';
import { productGeneralDictionariesByLanguageSelector } from '../../../store/slices/dictionarySlice';
import { getBodyStyles } from '../../../utils/functions';
import { Dropdown } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import i18n from '../../../i18n';

const { SHOW_CHILD, SHOW_PARENT } = ScTreeSelect;
const AdditionalBannerFindForm: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const { lg } = useBreakpoint();

  useEffect(() => {
    dispatch(getAllAuctionsAddressesAsDictionary());
  }, [dispatch, i18n.language]);

  const productGeneralDictionariesByLanguage = useSelector(productGeneralDictionariesByLanguageSelector);
  const allAuctionsAddressesAsDictionary = useSelector(allAuctionsAddressesAsDictionarySelector);

  const changeOdometr = (event: [number, number]) => {
    const min = (+event[0] / 500000) * 100;
    const max = (event[1] / 500000) * 100;
    const point1 = `0 ${100 - min}%, `;
    const point2 = `100% ${100 - max}%, `;
    const point3 = '100% 100%, ';
    const point4 = '0 100% ';

    return `polygon(${point1}${point2}${point3}${point4})`;
  };

  const getOdoVal = useCallback(() => {
    const from = params.get('vehicleodometrfrom');
    const to = params.get('vehicleodometrto');
    return [from ? +from : 0, to ? +to : 500000];
  }, [params]);

  const [currentOdometr, setCurrentOdometr] = useState<[number, number]>(getOdoVal() as [number, number]);

  const [currentOdometrClip, setCurrentOdometrClip] = useState<string>(changeOdometr([0, 500000]));

  const handleChangeOdometr = (event: [number, number]) => {
    setCurrentOdometr(event);
    setCurrentOdometrClip(changeOdometr(event));

    const ChangeParam = new URLSearchParams(history.location.search);
    ChangeParam.set('vehicleodometrfrom', event[0].toString());
    ChangeParam.set('vehicleodometrto', event[1].toString());

    history.push({ search: ChangeParam.toString() });
  };

  useEffect(() => {
    const odoVal = getOdoVal() as [number, number];
    setCurrentOdometr(odoVal);
    setCurrentOdometrClip(changeOdometr(odoVal));
  }, [getOdoVal, params]);

  const handleChangeFilter = useCallback(
    (data: { [key: string]: string | string[] }) => {
      const keys = Object.keys(data);

      keys.forEach((field) => {
        let value: string | string[] | undefined = data[field];

        if (Array.isArray(value)) {
          value = value.join();
        }
        if (value) {
          params.set(field, value);
        } else {
          params.delete(field);
        }
      });

      params.set('page', '1');
      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      });
    },
    [history, params]
  );

  const handleClear = (event: any) => {
    event.stopPropagation();
    setIsHovered(false);
    const ChangeParam = new URLSearchParams(history.location.search);
    ChangeParam.delete('vehicleodometrfrom');
    ChangeParam.delete('vehicleodometrto');
    history.push({ search: ChangeParam.toString() });
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    const from = params.get('vehicleodometrfrom');
    const to = params.get('vehicleodometrto');
    if (from || to) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <FormWrapper
        name="search"
        layout="vertical"
        fields={[
          { name: ['bodystyle'], value: params.get('bodystyle')?.split(',') },
          { name: ['auctiontype'], value: params.get('auctiontype')?.split(',') },
          { name: ['transmission'], value: params.get('transmission')?.split(',') },
          { name: ['fueltype'], value: params.get('fueltype')?.split(',') },
          { name: ['color'], value: params.get('color')?.split(',') },
          { name: ['newlyaddedlots'], value: params.get('newlyaddedlots') },
          { name: ['drivetrain'], value: params.get('drivetrain')?.split(',') },
          { name: ['cylinder'], value: params.get('cylinder')?.split(',') },
          { name: ['enginetype'], value: params.get('enginetype')?.split(',') },
          { name: ['source'], value: params.get('source')?.split(',') },
          { name: ['auctionsaddress'], value: params.get('auctionsaddress')?.split(',') },
          { name: ['customs'], value: params.get('customs')?.split(',') },
          {
            name: ['vehicleodometer'],
            value: [params.get('vehicleodometrfrom'), params.get('vehicleodometrto')],
          },
          ,
        ]}
        onValuesChange={handleChangeFilter}
        scrollToFirstError
      >
        <ScFormItem name="bodystyle">
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('search_filter_bodyStyle')}
            showSearch
            treeData={getBodyStyles(productGeneralDictionariesByLanguage.automobileTypesList)}
            maxTagCount="responsive"
          />
        </ScFormItem>

        <ScFormItem name="auctiontype" noStyle>
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('search_filter_type')}
            showSearch
            treeData={productGeneralDictionariesByLanguage.auctionTypesList?.map((item) => ({
              id: item.id,
              value: item.value.toLowerCase(),
              title: item.lanValue.toLowerCase(),
            }))}
            maxTagCount="responsive"
          />
        </ScFormItem>

        <ScFormItem shouldUpdate={(prevValues: any, currentValues: any) => prevValues.vehicleodometer !== currentValues.vehicleodometer}>
          {({ getFieldValue }: any) => (
            <Dropdown
              trigger={['click']}
              overlay={
                <DropdownFields onClick={(e) => e.preventDefault()}>
                  <ScSlider
                    value={currentOdometr}
                    min={0}
                    max={500000}
                    step={25000}
                    range
                    currentClip={currentOdometrClip}
                    tooltip={{
                      open: true,
                      placement: 'bottom',
                      getPopupContainer: (triggerNode: { parentElement: any }) => triggerNode.parentElement || triggerNode,
                    }}
                    onChange={handleChangeOdometr}
                  />
                </DropdownFields>
              }
              overlayStyle={lg ? { zIndex: 5 } : undefined}
            >
              <DropdownOdo
                icon={isHovered ? <ClearButton onClick={(event: any) => handleClear(event)} /> : <DownOutlined />}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {getFieldValue('vehicleodometer') && getFieldValue('vehicleodometer')[0] !== null
                  ? getFieldValue('vehicleodometer').join(' - ')
                  : t('search_filter_odometer')}{' '}
              </DropdownOdo>
            </Dropdown>
          )}
        </ScFormItem>

        <ScFormItem name="customs">
          <ScSelect
            optionFilterProp="children"
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            showSearch
            maxTagCount="responsive"
            placeholder={t('search_filter_custom')}
            value={params.get('customs')}
          >
            {(productGeneralDictionariesByLanguage.customsTypesList || []).map((item) => (
              <ScSelect.Option key={item.id} value={item.value.toLowerCase()}>
                {item.lanValue}
              </ScSelect.Option>
            ))}
          </ScSelect>
        </ScFormItem>

        <ScFormItem name="transmission" noStyle>
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('lot_transmission')}
            showSearch
            treeData={productGeneralDictionariesByLanguage.transmissionTypesList?.map((item) => ({
              id: item.id,
              value: item.value.toLowerCase(),
              title: item.lanValue,
            }))}
            maxTagCount="responsive"
          />
        </ScFormItem>

        <ScFormItem name="fueltype" noStyle>
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('lot_fuel_type')}
            showSearch
            treeData={productGeneralDictionariesByLanguage.fuelTypesList?.map((item) => ({
              id: item.id,
              value: item.value.toLowerCase(),
              title: item.lanValue,
            }))}
            maxTagCount="responsive"
          />
        </ScFormItem>

        <ScFormItem name="color" noStyle>
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('lot_color')}
            showSearch
            treeData={productGeneralDictionariesByLanguage.colorsList?.map((item) => ({
              id: item.id,
              value: item.value.toLowerCase(),
              title: item.lanValue,
            }))}
            maxTagCount="responsive"
          />
        </ScFormItem>

        <ScFormItem name="newlyaddedlots">
          <ScSelect
            optionFilterProp="children"
            oshowArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            showSearch
            maxTagCount="responsive"
            placeholder={t('lot_newly_added_lots')}
            value={params.get('newlyaddedlots')}
          >
            <option value="1">{t('lot_newly_added_lots_24_hours')}</option>
            <option value="7">{t('lot_newly_added_lots_7_days')}</option>
          </ScSelect>
        </ScFormItem>

        <ScFormItem name="drivetrain" noStyle>
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('lot_drive')}
            showSearch
            treeData={productGeneralDictionariesByLanguage.driveTrainTypesList?.map((item) => ({
              id: item.id,
              value: item.value.toLowerCase(),
              title: item.lanValue,
            }))}
            maxTagCount="responsive"
          />
        </ScFormItem>

        <ScFormItem name="cylinder" noStyle>
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('lot_cylinders')}
            showSearch
            treeData={productGeneralDictionariesByLanguage.cylinderTypesList?.map((item) => ({
              id: item.id,
              value: item.value,
              title: item.value,
            }))}
            maxTagCount="responsive"
          />
        </ScFormItem>

        <ScFormItem name="enginetype" noStyle>
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('lot_engine_type')}
            showSearch
            treeData={productGeneralDictionariesByLanguage.engineTypesList?.map((item) => ({
              id: item.id,
              value: item.value.toLowerCase(),
              title: item.value,
            }))}
            maxTagCount="responsive"
          />
        </ScFormItem>

        {/* <ScFormItem name="source" noStyle>
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('lot_source')}
            showSearch
            treeData={productGeneralDictionariesByLanguage.sourceTypesList?.map((item) => ({
              id: item.id,
              value: item.value,
              title: item.lanValue,
            }))}
            maxTagCount="responsive"
          />
        </ScFormItem> */}

        <ScFormItem name="auctionsaddress" noStyle>
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('lot_auction')}
            showSearch
            treeData={allAuctionsAddressesAsDictionary?.map((item) => ({
              id: item.id,
              value: item.value.toLowerCase(),
              title: item.lanValue,
            }))}
            maxTagCount="responsive"
          />
        </ScFormItem>
      </FormWrapper>
    </>
  );
};

export default memo(AdditionalBannerFindForm);
