import React from 'react'
import { ElectricCarsWrapper } from './style'
import MainBanner from './MainBanner'
import GallerySection from './GallerySection'
import Contact from '../../shared/components/Contact'
import Meta from '../../shared/components/Meta'
import i18n from '../../i18n'

const ElectricCars = () => {
  return (
    <><Meta
      title={i18n.t("meta_electriccars_title")}
      description={i18n.t("meta_electriccars_desc")} /><ElectricCarsWrapper>
        <MainBanner />
        <GallerySection />
        <Contact />
      </ElectricCarsWrapper></>
  )
}

export default ElectricCars