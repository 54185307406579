import styled, { css } from "styled-components";

export const NewsCarSection = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 90rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  @media(max-width: 1200px){
    padding: 1.5rem 1rem;
  }
  @media(max-width: 991px){
    gap: 1rem;
    padding: 1rem 1rem 0;
  }
`

export const SectionTitle = styled.h2`
  font-family: 'main';
  &:lang(ka){
    font-family: 'arial-caps';
  }
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.125rem;
  text-align: left;
  color: #1b153d;
  margin-bottom: 3.5rem;
  @media(max-width: 1200px){
    margin-bottom: 2.5rem;
  }
  @media(max-width: 991px){
    margin-bottom: 1rem;
  }
  @media(max-width: 767px){
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

interface NewCarsListProps {
    isLoading: boolean
  }
  
  export const NewCarsList = styled.div<NewCarsListProps>`
  ${p => p.isLoading ? css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    @media(max-width: 991px){
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      overflow: hidden;
    }
  ` : css`
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
  `}
`;
  
  export const NewCarsCardWrapper = styled.div`
  padding: 0 0.5rem;
  max-width: 340px;
  @media(max-width: 991px){
    padding: 0 0.25rem;
  }
  @media(max-width: 767px){
    max-width: 100%;
  }
`
  
  interface SliderButtonWrapperProps {
    isNextArrow?: boolean;
    isPrevArrow?: boolean;
  }
  
  export const SliderButtonWrapper = styled.button<SliderButtonWrapperProps>`
    background: #F9FAFB;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 100px;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    transform: translate(0, -50%);
    padding: 0;
    .svg-wrapper{
      &, & > div{
        display: flex;
        align-items: center;
      }
    }
    
    @media(max-width: 991px){
      display: none;
    }
  
    ${p => p.isNextArrow && css`
      position: absolute;
      top: 50%;
      right: -10px;
      svg{
        transform: rotate(180deg);
      }
    `}
  
    ${p => p.isPrevArrow && css`
      position: absolute;
      top: 50%;
      left: -10px;
    `}
  `