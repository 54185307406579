import React, { FC, memo } from 'react';
import { Row, Col, Typography, Space } from 'antd';
import { Link } from 'react-router-dom';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { CalendarFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { BlogItem } from '../../../interfaces/Blog';
import BlogImage from '../../../shared/components/Blog/BlogImage';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Card } from 'antd';
const { Meta } = Card;

const { Text } = Typography;

interface Props {
  blogItem: BlogItem;
}
const RecomendedBlogCard: FC<Props> = ({ blogItem }) => {
  let blogDesc;
  if(blogItem.shortDescription.length > 180) { blogDesc = blogItem.shortDescription.substring(0, 180) + '...'; } else { blogDesc = blogItem.shortDescription; }
  return (
    <Link to={`../${blogItem.id}/${blogItem.header.replace(/\s/g, '-')}`}>
      <Card hoverable style={{ borderRadius: '16px' }} className='blogCard' cover={<img alt={blogItem.header} style={{ height: '16rem', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }} src={blogItem.imageUrl || ''} />}>
        <Meta title={blogItem.header} description={blogDesc} />
      </Card>
    </Link>
  );
};

export default memo(RecomendedBlogCard);
