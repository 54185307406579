import React, { useMemo } from 'react'
import { UploadFileIcon, UploadFileSize, UploadFileTitle, UploadFileWrapper } from './style'
import { ReactSVG } from 'react-svg';
import { Tooltip } from 'antd';

export interface FileType {
    Id: string,
    OriginalName: string,
    FileName: string,
    size?: number,
    Type: string,
    Url: string
}

export interface UploadedFileProps{
    file: FileType 
    onRemove: (id: string) => void
    makeMainImage: (id: string) => void
}

const UploadFile = ({file, onRemove, makeMainImage}: UploadedFileProps) => {
    function bytesToMegaBytes(bytes: number) {
        return bytes / (1024 * 1024);
    }

    return (
        <Tooltip title={'Make main image'}>
            <UploadFileWrapper onClick={() => makeMainImage(file.Id)}>
                {file.Type === "application/pdf" ? (
                    <UploadFileIcon>
                        <img src="/img/pdf.png" alt="PDF" />
                    </UploadFileIcon>
                ) : (
                    <UploadFileIcon>
                        <img src={file.Url} alt={file.FileName} />
                    </UploadFileIcon>
                )}
                <UploadFileTitle>{file.FileName}</UploadFileTitle>
                {file.size && <UploadFileSize>{bytesToMegaBytes(file.size).toFixed(2)} mb</UploadFileSize>}
                <button className="close-btn" onClick={() => onRemove(file.Id)}>
                    <ReactSVG src='/icons/close.svg' />
                </button>
            </UploadFileWrapper>
        </Tooltip>
    )
}

export default UploadFile