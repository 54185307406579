/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
import App from './App';
import { store } from './store/store';
import * as serviceWorker from './serviceWorker';
import './i18n';
import userManager from './utils/userManager';

const startApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <CookiesProvider>
          <Router>
            <App />
          </Router>
        </CookiesProvider>
      </OidcProvider>
    </Provider>,
    document.getElementById('root'),
  );
  serviceWorker.register();
};

startApp();
