import React, { FC, memo } from 'react';
import { Typography, Card, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CalendarFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Scrollbars from 'rc-scrollbars';
import { BlogItem } from '../../../interfaces/Blog';
import { ActionsWrapper, BlogImageLoader, BlogLoaderCard, CalendarWrapper, ReadMoreBtn, StyledCard } from './style';
import BlogImage from './BlogImage';
import { ReactSVG } from 'react-svg';
import { langPrefix } from '../../../utils/functions';

const { Text } = Typography;
const { Meta } = Card;

interface Props {
  blog: BlogItem;
}

export const BlogCardLoadingSkeleton = () => {
  return <BlogLoaderCard
  cover={<BlogImageLoader height="200px" />}
  actions={[<div className="action-loader"></div>
  ]}
>
  <div className='meta-loader'></div>
</BlogLoaderCard>
}

const BlogCard: FC<Props> = ({ blog }) => {
  const { t } = useTranslation();
  function str(str: string, max: number) {
    return str.length > max ? str.slice(0, max) + '...' : str;
  }
  return (
    <Link to={langPrefix(`/blog/${blog.id}/${blog.header.replace(/\s/g, '-')}`)}>
      <StyledCard
        hoverable
        cover={<BlogImage image={blog.imageUrl} isVideo={!!blog.videoUrl} height="200px" borderRadius />}
        actions={[
          <ActionsWrapper>
            <CalendarWrapper>
              <ReactSVG src='/icons/calendar.svg'/>
              {dayjs(blog.creationTime).format('DD/MM/YYYY')}
            </CalendarWrapper>
            <ReadMoreBtn>{t("Read_more")}</ReadMoreBtn>
          </ActionsWrapper>
        ]}
      >
          <Meta title={blog.header} description={str(blog.shortDescription, 100)} />
      </StyledCard>
    </Link>
  );
};

export default memo(BlogCard);
