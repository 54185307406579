import styled from 'styled-components';
import { Table } from 'antd';
import { White } from '../../shared-styles';

export const ScTable = styled(Table)`
  padding: 10px 0;
  background-color: ${White};
  border-radius: 4px;
  width: 100%;
  .ant-table-thead{
    .ant-table-cell{
      background: #F6F6F6;
      font-family: 'main';
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: #1B153D;
      &::before{
        height: 1.5rem;
        background-color: #E2E1E1;
      }
    }
  }
  .ant-table-tbody{
    .ant-table-cell{
      font-family: 'main';
      font-weight: 400;
      font-size: 0.75rem;
      color: #000000;
      background-color: #fff;
      border-color: #EFEEEE;
    }
  }
`;

export const PaginationWrapper = styled.div`
  padding: 0rem 2.5rem 0rem;
  background-color: #ffffff;
`