import React, { useState } from 'react'
import Banner from './Banner'
import { Container} from './style'
import DescriptionSection from './DescriptionSection'
import Map from './Map'
import CityMap from './CityMap'
import Team from './Team'
import Partners from './Partners'
import { useTranslation } from 'react-i18next'
import { PageTitle } from '../../shared/sharedComponent'
import { AboutPageSchema } from '../../shared/components/Schema'
import Meta from '../../shared/components/Meta'

const NewAboutUsPage = () => {
  const { t } = useTranslation();
  const [isSitiSelected, setIsSitiSelected] = useState(false)
  const [selectedOffice, setSelectedOffice] = useState(null)
  
  const onCityHandle = (office: any) => {
    setSelectedOffice(office)
    setIsSitiSelected(true)
  }

  const onPrevBtnHandle = () => {
    setSelectedOffice(null)
    setIsSitiSelected(false)
  }

  return (
    <>
      <Meta
        title={t("meta_aboutus_title")}
        description={t("meta_aboutus_desc")}
      />
        <AboutPageSchema/>
        <Banner />
        <Container>
            <PageTitle>{t(['about_us_title_header'])}</PageTitle>
        </Container>
        <DescriptionSection />
        {isSitiSelected 
        ? selectedOffice && <CityMap onPrevBtnHandle={onPrevBtnHandle} country={selectedOffice}/>
        : <Map onCityHandle={onCityHandle}/>}
        <Team />
        <Partners />
    </>
  )
}

export default NewAboutUsPage