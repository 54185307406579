import React from 'react'
import { useTranslation } from 'react-i18next'
import LotCardDescription, { LotCardRow, LotCardText } from '../../../shared/components/LotDescriptionCard'

const SeatSteeringWheelCard = ({vehicle}: any) => {

  const { t } = useTranslation()

  return (
    <LotCardDescription title={t("electric_description_seat_steering_wheel")}>
        <>
            <LotCardRow>
                <LotCardText>{t("steering_wheel_heating")}</LotCardText>
                <LotCardText>{vehicle?.seatsSteeringWheel?.heating}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("ventilated_seats")}</LotCardText>
                <LotCardText>{vehicle?.seatsSteeringWheel?.ventilatedSeats ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("seat_heating")}</LotCardText>
                <LotCardText>{vehicle?.seatsSteeringWheel?.seatHeating ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("multifunction_steering_wheel")}</LotCardText>
                <LotCardText>{vehicle?.seatsSteeringWheel?.multyfunctionSteeringWheel ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("car_seats_material")}</LotCardText>
                <LotCardText>{vehicle?.seatsSteeringWheel?.carSeatsMaterial}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("steering_wheel_adjustment")}</LotCardText>
                <LotCardText>{vehicle?.seatsSteeringWheel?.steeringWheelAdjustment ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("power_seat_adjustment")}</LotCardText>
                <LotCardText>{vehicle?.seatsSteeringWheel?.powerSeatAdjustment ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
            <LotCardRow>
                <LotCardText>{t("seat_position_memory")}</LotCardText>
                <LotCardText>{vehicle?.seatsSteeringWheel?.seatPositionMemory ? t("yes"): t("no")}</LotCardText>
            </LotCardRow>
        </>
    </LotCardDescription>
  )
}

export default SeatSteeringWheelCard