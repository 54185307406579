import React, { useState } from 'react'
import { ProfileBody, ProfileHead, ProfileImage, ProfileItem, ProfileItemBody, ProfileItemHead, ProfileItemName, ProfileItemHeadVariant, ProfileList, ProfileName, ProfileWrapper } from './style'
import { ReactSVG } from 'react-svg'

interface UserProfileProps {
  fullName: string
}

const UserProfile = ({fullName}: UserProfileProps) => {

  return (
    <ProfileWrapper>
      <ProfileHead>
        <ProfileImage>{fullName[0]}</ProfileImage>
        <ProfileName>{fullName}</ProfileName>
        <ReactSVG src="/icons/arrow-up.svg" className={"svg-wrapper"}/>
      </ProfileHead>
    </ProfileWrapper>
  )
}

export default UserProfile