import React, { FC, useEffect, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { ReactImageGalleryItem } from 'react-image-gallery';
import styled from 'styled-components';

interface Props {
  img: ReactImageGalleryItem;
  setLoaded: (status: boolean) => void;
}

export const ScReactImageMagnify = styled(ReactImageMagnify)<{ src: string }>`
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
    z-index: -1;
    background: ${(props) => `url(${props.src}) no-repeat center`};
    background-size: cover;
    filter: blur(4px);
  }
`;

const ImageMagnify: FC<Props> = ({ img, setLoaded }) => {
  const [currentWidth, setWidth] = useState(0);
  const [currentHeight, setHeight] = useState(0);

  useEffect(() => {
    const image = new Image();
    image.src = img.original;
    image.onload = (event) => {
      const loadedImage = event.currentTarget as HTMLImageElement;
      const { width, height } = loadedImage;
      setWidth(width);
      setHeight(height);
    };
  }, [img.original]);

  const handleSetLoaded = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  };

  return (
    <ScReactImageMagnify
      src={img.original}
      {...{
        smallImage: {
          alt: 'img',
          isFluidWidth: true,
          src: img.original,
          onLoad: handleSetLoaded,
        },
        largeImage: {
          src: img.original,
          width: currentWidth * 2,
          height: currentHeight * 2,
        },
        enlargedImagePosition: 'over',
      }}
    />
  );
};

export default ImageMagnify;
