import React, { FC, memo } from 'react';
import { Descriptions } from 'antd';
import { useSelector } from 'react-redux';
import { MainLotInfo } from '../../../interfaces/Product';
import { getOdoValue } from '../../../utils/functions';
import { odometrIsKmSelector } from '../../../store/slices/initSlice';

const { Item } = Descriptions;

interface Props {
  lotInfo: MainLotInfo;
}

const LotDisplay: FC<Props> = ({ lotInfo }) => {
  const {
    status,
    lotNumber,
    claimNumber,
    description,
    vin,
    primaryDamage,
    odometerBrand,
    odometer,
    drivabilityRating,
    keys,
    repairCost,
    acv,
    daysInYard,
    assignmentType,
    currency,
    hold,
    location,
  } = lotInfo;

  const odometrIsKm = useSelector(odometrIsKmSelector);

  return (
    <Descriptions title="" bordered size="small" column={1}>
      <Item label="Status">{status}</Item>
      <Item label="Lot#"> {lotNumber}</Item>
      <Item label="Claim Number">{claimNumber}</Item>
      <Item label="Description">{description}</Item>
      <Item label="VIN">{vin}</Item>
      <Item label="Primary Damage">{primaryDamage}</Item>
      <Item label="Drivibility Rating">{drivabilityRating}</Item>
      {/* <Item label="Odometer Brand">{odometerBrand}</Item> */}
      {/* <Item label="Odometer">{`${odometer || 0} mi`}</Item> */}
      <Item label="Odometer">{getOdoValue(+odometer, odometrIsKm, odometerBrand)}</Item>
      <Item label="Keys">{keys ? 'Yes' : 'No'}</Item>
      <Item label="ACV">{acv}</Item>
      <Item label="Repair Cost">{repairCost}</Item>
      <Item label="Days In Yard">{daysInYard}</Item>
      <Item label="Location">{location}</Item>
      <Item label="Assignment Type">{assignmentType}</Item>
      <Item label="Currency">{currency}</Item>
      <Item label="Hold">{hold}</Item>
    </Descriptions>
  );
};

export default memo(LotDisplay);
