import React from 'react'

import { TrackingContent } from './style'
import { useTranslation } from 'react-i18next'
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface TrackingInformationProps {
    vehicle: any
}

interface ShippingLineUrls {
    [key: string]: string;
}

const shippingLineUrls: ShippingLineUrls = {
    'ZIM': 'https://www.zim.com/tools/track-a-shipment',
    'MSC': 'https://www.msc.com/track-a-shipment',
    'Maersk': 'https://www.maersk.com/tracking',
    'CMA CGM': 'https://www.cma-cgm.com/ebusiness/tracking/search',
    'Evergreen': 'https://ct.shipmentlink.com/servlet/TDB1_CargoTracking.do',
    'HAPAG-LLOYD': 'https://www.hapag-lloyd.com/en/online-business/track/track-by-container-solution.html',
    'COSCO': 'https://elines.coscoshipping.com/ebusiness/cargoTracking',
    'Hyundai': 'https://www.hmm21.com/e-service/search/index.do',
    'Turkon': 'https://my.turkon.com/container-tracking',
    'ACL': 'https://www.aclcargo.com/track-cargo',
    'OOCL': 'https://www.oocl.com/eng/ourservices/eservices/cargotracking/Pages/cargotracking.aspx',
    'HAMBURG SUD': 'https://www.hamburgsud-line.com/liner/en/liner_services/ecommerce/track_trace/index.html',
    'YANG MING': 'https://www.yangming.com/e-service/track_trace/track_trace_cargo_tracking.aspx',
    'ONE': 'https://ecomm.one-line.com/one-ecom/manage-shipment/cargo-tracking',
    'K-Line': 'https://apps.klineglobalroro.com/tracking/search',
    'Arkas': 'https://webtracking.arkasline.com.tr/shipmenttracking',
};

const getShipTrack = (shippingLine: string, containerNumber: string): string | undefined => {
    const baseUrl = shippingLineUrls[shippingLine];
    if (baseUrl) {
        switch(shippingLine) {
            case 'ZIM':
                return `${baseUrl}?consnumber=${containerNumber}`;
            case 'MSC':
                return `${baseUrl}`;
            case 'Evergreen':
                return `${baseUrl}?search=${containerNumber}`;
            case 'Maersk':
                return `${baseUrl}/${containerNumber}`;
            case 'CMA CGM':
                return `${baseUrl}?keyword=${containerNumber}`;
            case 'HAPAG-LLOYD':
                return `${baseUrl}?container=${containerNumber}`;
            case 'COSCO':
                return `${baseUrl}`;
            case 'Hyundai':
                return `${baseUrl}?query=${containerNumber}`;
            case 'Turkon':
                return `${baseUrl}`;
            case 'ACL':
                return `${baseUrl}`;
            case 'OOCL':
                return `${baseUrl}`;
            case 'HAMBURG SUD':
                return `${baseUrl}`;
            case 'YANG MING':
                return `${baseUrl}`;
            case 'ONE':
                return `${baseUrl}`;
            case 'K-Line':
                return `${baseUrl}?q=${containerNumber}`;
            case 'Arkas':
                return `${baseUrl}`;
            default:
                return undefined;
        }
    }
    return undefined;
};

const TrackingInformation = ({vehicle}: TrackingInformationProps) => {
    const { t } = useTranslation()
    const trackingInfo = [
        {
            title: t("track_vin_code"),
            value: vehicle.vin,
        },
        {
            title: t("track_manufacture"),
            value: vehicle.manufacturer,
        },
        {
            title: t("track_model"),
            value: vehicle.model,
        },
        {
            title: t("track_year"),
            value: vehicle.year,
        },
        {
            title: t("track_containerNumber_title"),
            value: vehicle.title ? t("track_containerNumber_title_yes") : t("track_containerNumber_title_no"),
        },
        {
            title: t("track_containerNumber_vehstatus"),
            value: vehicle.containerNumber ? t("track_containerNumber_vehstatus_incontainer") : t("track_containerNumber_vehstatus_inportway"),
        },
        {
            title: t("track_whereComes"),
            value: vehicle.whereComes,
        },
        {
            title: t("track_containerLine"),
            value: vehicle.containerLine,
        },
        // {
        //     title: t("track_shipName"),
        //     value: vehicle.shipName,
        // },
        {
            title: t("track_containerNumber"),
            value: vehicle.containerNumber,
            link: vehicle.containerNumber?.startsWith("L-") ? null : getShipTrack(vehicle.containerLine, vehicle.containerNumber),
        },
        {
            title: t("track_containerEntryDate"),
            info: t("track_containerEntryDate_info"),
            value: vehicle.containerEntryDate,
        },
        {
            title: t("track_dateOpenContainer"),
            info: t("track_containerEntryDate_info"),
            value: vehicle.dateOpenContainer,
        },
    ]

  return (
    <TrackingContent>
        <h2>{t("track_detail_info")}</h2>
        <ul>
            {trackingInfo.map((item) => (
            <li key={item.title}>
                <span>{item.title} {item.info && <><br /><small>{item.info}</small></>}</span>
                <span>
                    {item.link ? (
                        <><strong><a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.value}</a></strong><Tooltip title={t("track_container_tooltip")}>
                                 <InfoCircleOutlined style={{ color: '#1890ff', marginLeft: '4px'  }} />
                            </Tooltip></>
                    ) : (
                        <strong>{item.value}</strong>
                    )}
                </span>
            </li>
            ))}
        </ul>
    </TrackingContent>
  )
}

export default TrackingInformation