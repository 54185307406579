import React, { FC, memo, useCallback, useState } from 'react';
import { Col, Row, Typography, Spin, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Countdown from 'antd/lib/statistic/Countdown';
import { currentLotActualSelector, currentLotSelector } from '../../../store/slices/auctionSlice';
import { Shadow, ImageWrapper, ScDivider, ScTable, SpinTimeCountdown } from './style';
import BadgeWithTooltip from '../BadgeWithTooltip';
import { getLinkForLotPage, getOdoValue } from '../../../utils/functions';
import Confetti from './Confetti';
import TimerBidSection from './TimerBidSection';
import SellModel from './SellModel';
import { LotTag } from '../FeaturedProductCard/style';
import { Accent } from '../../shared-styles';
import { odometrIsKmSelector } from '../../../store/slices/initSlice';
import StyledImageGallery from '../StyledImageGallery';

const { Text } = Typography;

interface Props {
  panelId: number;
  laneId: number;
  makeBid: (value: number, lotId: number, method: string) => void;
  currentLaneStatus: string;
  time: string | undefined;
}

const LotPanel: FC<Props> = ({ makeBid, panelId, laneId, currentLaneStatus, time }) => {
  const { t } = useTranslation();
  const currentLot = useSelector(currentLotSelector(laneId));
  const currentLotActual = useSelector(currentLotActualSelector(laneId));
  const odometrIsKm = useSelector(odometrIsKmSelector);

  const getImagesForGallery = useCallback(() => {
    if (currentLot) {
      const { mainImage, images } = currentLot;
      const imagesFiltered = images.filter((item) => item !== mainImage);

      const newImages = imagesFiltered?.map((item) => ({
        original: item,
        thumbnail: item,
      }));
      mainImage &&
        newImages?.unshift({
          original: mainImage,
          thumbnail: mainImage,
        });
      return newImages || [];
    }
    return [];
  }, [currentLot]);

  const [showShadow, setShowShadow] = useState(false);

  if (!currentLot) return <Spin size="large" />;

  return (
    <Spin
      spinning={
        currentLotActual.status !== 'CurrentLot' && currentLotActual.status !== 'OnSale' && !(currentLotActual.status === 'Sold' && currentLotActual.isWinning)
      }
      tip="Next vehicle is loading..."
    >
      <Row justify="center">
        <Confetti laneId={laneId} />
        <ImageWrapper xs={24} id="image">
          <LotTag color={Accent}>
            <a href={getLinkForLotPage(currentLot.lotNumber, currentLot.make, currentLot.model, currentLot.year)} target="_blank" rel="nofollow noopener noreferrer">
              # {currentLot.lotNumber}
            </a>
          </LotTag>
          <SpinTimeCountdown
            tip="Auction is loading..."
            spinning={currentLaneStatus === 'waiting'}
            size="large"
            indicator={
              time ? (
                <Countdown
                  title=""
                  value={new Date(new Date().setHours(new Date(time).getHours(), new Date(time).getMinutes(), new Date(time).getSeconds())).toISOString()}
                  format="HH[H] mm[M] ss[S]"
                />
              ) : undefined
            }
          >
            {currentLot.images.length ? (
              <StyledImageGallery items={getImagesForGallery()} />
            ) : (
              <Image width="100%" src="error" fallback="img/noImg.jpg" alt="No image" />
            )}
          </SpinTimeCountdown>
        </ImageWrapper>
      </Row>
      <Shadow>
        <div className={showShadow ? 'circleShade' : ''} />
        <Row justify="space-between">
          <Col>
            <Text strong>
              {currentLot.year} {currentLot.make} {currentLot.model}
            </Text>
          </Col>
          <Col>{currentLot.badges && currentLot.badges.map((item) => <BadgeWithTooltip badge={item} key={`${item.name}_${currentLot.id}`} />)}</Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <Text>
              {currentLot.primaryDamage} / {currentLot.secondaryDamage}
            </Text>
          </Col>
          <Col>
            <SellModel laneId={laneId} setShowShadow={setShowShadow} />
          </Col>
        </Row>
        <ScDivider />
        <Row align="middle">
          <TimerBidSection startingBid={+currentLot.startingBid} laneId={laneId} makeBid={makeBid} />
        </Row>
        <ScDivider />
        <ScTable>
          <Row>
            <Col xs={10}>{t('lot_status')}</Col>
            <Col xs={14}>
              <Text>{currentLot.status}</Text>
            </Col>
          </Row>
          <Row>
            <Col xs={10}>{t('lot_odometer')}</Col>
            <Col xs={14}>{getOdoValue(+currentLot.odometer, odometrIsKm, currentLot.odometerBrand)}</Col>
          </Row>
          <Row>
            <Col xs={10}>{t('lot_primary_damage')}</Col>
            <Col xs={14}>
              <Text>{currentLot.primaryDamage}</Text>
            </Col>
          </Row>
          <Row>
            <Col xs={10}>{t('lot_secondary_damage')}</Col>
            <Col xs={14}>
              <Text>{currentLot.secondaryDamage}</Text>
            </Col>
          </Row>
          <Row>
            <Col xs={10}>VIN</Col>
            <Col xs={14}>
              <Text>{currentLot.vin}</Text>
            </Col>
          </Row>
          <Row>
            <Col xs={10}>{t('lot_body_style')}</Col>
            <Col xs={14}>
              <Text>{currentLot.bodyStyle}</Text>
            </Col>
          </Row>
          <Row>
            <Col xs={10}>{t('lot_color')}</Col>
            <Col xs={14}>
              <Text>{currentLot.color}</Text>
            </Col>
          </Row>
          <Row>
            <Col xs={10}>{t('lot_engine_type')}</Col>
            <Col xs={14}>
              <Text>{currentLot.engineType}</Text>
            </Col>
          </Row>
          <Row>
            <Col xs={10}>{t('lot_cylinders')}</Col>
            <Col xs={14}>
              <Text>{currentLot.cylinder}</Text>
            </Col>
          </Row>
          <Row>
            <Col xs={10}>{t('lot_notes')}</Col>
            <Col xs={14}>
              <Text>{currentLot.notes}</Text>
            </Col>
          </Row>
        </ScTable>
      </Shadow>
    </Spin>
  );
};

export default memo(LotPanel);
