import React, { FC, memo, useState } from 'react';
import { CardBody, CardHeader, CardWrapper, LinkText, Row, Text, TextVariant } from './style';
import { ReactSVG } from 'react-svg';

interface LotDescriptionCardProps {
    children?: JSX.Element;
    title: string;
}

const LotDescriptionCard: FC<LotDescriptionCardProps> = ({ children, title }) => {
    const [isCardOpen, setIsCardOpen] = useState(false)

    const onButtonHandle = () => {
        setIsCardOpen(!isCardOpen)
    }

  return (
    <CardWrapper>
        <CardHeader buttonDisplay={isCardOpen}>
            <span>{title}</span>
            <button onClick={onButtonHandle}>
                {isCardOpen 
                ? <ReactSVG src="/icons/minus.svg" />
                : <ReactSVG src="/icons/add.svg" />}
            </button>
        </CardHeader>
        <CardBody isVisible={isCardOpen}>
            {children}
        </CardBody>
    </CardWrapper>
  );
};

export {
    LinkText as LotCardLink,
    Row as LotCardRow ,
    Text as LotCardText,
    TextVariant as LotCardTextVariant,
    CardWrapper as LotCardWrapper,
    CardHeader as LotCardHeader,
    CardBody as LotCardBody,
}

export default memo(LotDescriptionCard);
