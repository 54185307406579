import React, { FC, memo, useEffect } from 'react';
import LazyLoad, { forceCheck } from 'react-lazyload';
import Scrollbars from 'rc-scrollbars';
import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import NextLot from './NextLot';
import { nextLotsSelector } from '../../../store/slices/auctionSlice';
import { useScrollHandle } from '../../../utils/functions';

interface Props {
  currentLaneStatus: string;
  laneId: number;
  makeBid: (value: number, lotId: number, method: string) => void;
}

const NextLotsList: FC<Props> = ({ currentLaneStatus, laneId, makeBid }) => {
  const scroll = useScrollHandle();
  const nextLots = useSelector(nextLotsSelector(laneId));

  useEffect(() => {
    forceCheck();
  }, [nextLots, scroll]);

  return (
    <Scrollbars>
      {nextLots.map((item, idx) => (
        <LazyLoad height={140} offset={[-140, 0]} key={item} placeholder={<Skeleton active />} debounce={50} scrollContainer=".rc-scrollbars-view">
          <NextLot lotId={item} laneId={laneId} idx={idx} makeBid={makeBid} />
        </LazyLoad>
      ))}
    </Scrollbars>
  );
};
export default memo(NextLotsList);
