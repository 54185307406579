import styled from "styled-components";

export const TeamCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 2rem;
    @media(max-width: 1400px){
        margin: 0 1rem;
    }
`

export const ImageWrapper = styled.div`
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1.125rem;
    position: relative;
    padding-bottom: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
    }
    @media (max-width: 580px){
        width: 15rem;
        height: 15rem;
        padding-bottom: 0;
        img{
            position: static;
        }
    }
`

export const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    h3{
        font-family: 'main';
        &:lang(ka){
            font-family: 'arial-caps';
        }
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: #1B153D;
        margin-bottom: 0.75rem;
    }
    p{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #9095A6;
    }
    @media (max-width: 580px){
        h3{
            font-size: 1rem;
            margin-bottom: 0.25rem;
        }
        p{
            font-size: 0.75rem;
        }
    }
`