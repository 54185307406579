import React, { useEffect, useState } from 'react';
import { TrackButtonWrapper } from './style';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const TrackButton = () => {
  const { t } = useTranslation();
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [imageSrc, setimageSrc] = useState<string | null>(null);
  useEffect(() => {
    setVideoSrc(null);
    setimageSrc('/img/banners/track_' + i18n.language + '.webp');
  }, [i18n.language]);

  const handleClick = () => {
    setVideoSrc('https://liontrans.com/videos/track_' + i18n.language + '.mp4');
  };
  return (
    <TrackButtonWrapper>
      <h2>{t("track_info")}</h2>
      <div>
        <a href="https://www.marinetraffic.com/en/ais/home/centerx:19.1/centery:35.9/zoom:6" target="_blank" rel="nofollow noopener noreferrer">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9999 0C7.16618 0 3.23376 3.93251 3.23376 8.7664C3.23376 11.6865 5.91971 16.2951 7.07443 18.141C8.02442 19.6596 9.04425 21.1033 9.9459 22.2063C11.4123 24 11.8241 24 12 24C12.1688 24 12.564 24 14.0253 22.2057C14.9293 21.0957 15.9499 19.6523 16.8993 18.1416C18.0619 16.2912 20.7663 11.6735 20.7663 8.7664C20.7663 3.93251 16.8338 0 11.9999 0ZM12 15.8476C8.17594 15.8476 5.0648 12.7365 5.0648 8.91247C5.0648 5.08843 8.17594 1.97729 12 1.97729C15.824 1.97729 18.9352 5.08843 18.9352 8.91247C18.9352 12.7365 15.824 15.8476 12 15.8476Z"
              fill="white"
            />
            <path
              d="M17.0629 6.25142C17.0149 6.25142 16.9655 6.25835 16.9164 6.27193L16.3595 6.42521L15.7814 5.01677C15.6176 4.61773 15.1334 4.29297 14.702 4.29297H9.29029C8.85891 4.29297 8.37468 4.61764 8.21087 5.01668L7.63341 6.42336L7.08359 6.27193C7.03443 6.25844 6.9851 6.25142 6.93705 6.25142C6.67382 6.25142 6.48285 6.45246 6.48285 6.72945V7.05791C6.48285 7.38119 6.74598 7.64433 7.06927 7.64433H7.13228L7.03896 7.87152C6.88679 8.24238 6.76289 8.87001 6.76289 9.27081V12.0692C6.76289 12.3925 7.02603 12.6556 7.34931 12.6556H8.11533C8.43862 12.6556 8.70175 12.3925 8.70175 12.0692V11.3704H15.2908V12.0692C15.2908 12.3925 15.554 12.6556 15.8772 12.6556H16.643C16.9663 12.6556 17.2294 12.3925 17.2294 12.0692V9.27081C17.2294 8.87001 17.1055 8.24238 16.9533 7.87152L16.86 7.64433H16.9309C17.2542 7.64433 17.5173 7.38119 17.5173 7.05791V6.72945C17.5171 6.45246 17.3261 6.25142 17.0629 6.25142ZM8.05389 7.32576L8.92857 5.195C9.02651 4.95626 9.31782 4.76103 9.57588 4.76103H14.4165C14.6745 4.76103 14.9657 4.95635 15.0637 5.195L15.9384 7.32576C16.0364 7.5645 15.9054 7.75973 15.6475 7.75973H8.34493C8.08697 7.75973 7.95596 7.5645 8.05389 7.32576ZM10.0066 10.0207C10.0066 10.1496 9.90101 10.2552 9.77203 10.2552H8.11044C7.98136 10.2552 7.87585 10.1496 7.87585 10.0207V9.22322C7.87585 9.09424 7.98146 8.98864 8.11044 8.98864H9.77203C9.90101 8.98864 10.0066 9.09424 10.0066 9.22322V10.0207ZM16.1006 10.0207C16.1006 10.1496 15.995 10.2552 15.8661 10.2552H14.2047C14.0757 10.2552 13.9701 10.1496 13.9701 10.0207V9.22322C13.9701 9.09424 14.0757 8.98864 14.2047 8.98864H15.8661C15.995 8.98864 16.1006 9.09424 16.1006 9.22322V10.0207Z"
              fill="white"
            />
          </svg>
          {t("track_button")}
        </a>
      </div>

      <div className="imgwrap">
        {videoSrc ? (
          <video width="400" controls autoPlay>
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        ) : (
          <>
            <img src={imageSrc}/>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick} >
              <path
                d="M29.15 16.0004L9.33335 27.9504C8.16669 28.6504 6.66669 27.8171 6.66669 26.4504V13.1171C6.66669 7.30039 12.95 3.66705 18 6.56706L25.65 10.9671L29.1334 12.9671C30.2834 13.6504 30.3 15.3171 29.15 16.0004Z"
                fill="white"
              />
              <path
                d="M30.15 25.7667L23.4 29.6667L16.6667 33.5501C14.25 34.9334 11.5167 34.6501 9.53334 33.2501C8.56668 32.5834 8.68334 31.1001 9.70001 30.5001L30.8833 17.8001C31.8833 17.2001 33.2 17.7667 33.3833 18.9167C33.8 21.5001 32.7333 24.2834 30.15 25.7667Z"
                fill="white"
              />
            </svg>
          </>
        )}
      </div>
    </TrackButtonWrapper>
  );
};

export default TrackButton;
