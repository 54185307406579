import styled from "styled-components";
import { Select } from 'antd';

export const SelectsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 2.125rem;

  @media(max-width: 1400px){
    margin-right: 1.5rem;
    gap: 1rem;
  }
  @media(max-width: 991px){
    gap: 0.5rem;
    margin-right: 1rem;
  }
  @media(max-width: 767px){
    .currencyIcon {
      -webkit-filter: invert(1);
      filter: invert(1);
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
      color: #fff !important;
    }
    //width: 12.5rem;
    padding: 0 1rem;
    //flex-direction: column;
    gap: 1rem;
    margin: 0;
  }
`;

export const StyledSelect: any = styled(Select)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    padding: 0 0.75rem;
  }

  .ant-select-arrow {
    font-size: 0.7rem;
    color: #1b153d;
    right: 0;
  }
  .ant-select-selection-item{
    font-family: 'main';
    &:lang(ka){
      font-family: 'arial-caps';
    }
    &:lang(en){
      font-family: 'arial-caps';
    }
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 5px;
  }
  &.ant-select-single.ant-select-open .ant-select-selection-item {
    font-size: 0.875rem!important;
    font-family: 'arial-caps' !important;
    font-weight: 0 !important;
  }

  @media(max-width: 767px){
    width: 100%;
    .ant-select-arrow{
        width: 1.25rem;
        height: 1.25rem;
        right: 0.5rem;
        font-size: 0.5rem;
        color: #fff;
    }
      &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background: #0000;
        color: #fff;
        border: 1px solid #fff;
    }
    &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 18px;
  }
  }
`;
