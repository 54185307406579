import { Link } from "react-router-dom";
import styled from "styled-components";

export const BannerWrapper = styled.section`
    width: 100%;
`

export const BannerItem = styled.div`
    padding:  0 1rem;
    height: auto;
    max-height: calc(100vh - 25rem);
    min-height: 25rem;
    width: 100%;
    aspect-ratio: 2.7;
    overflow: hidden;
    position: relative;
    background-color: #020a10;
    &::after{
        content: "";
        display: block;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #000000 0%, #000000 36.81%, rgba(0, 0, 0, 0) 87.25%);
    }
    @media (max-width: 767px){
        padding-bottom: 2.25rem;
    }
`

export const BannerContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3.75rem;
    position: relative;
    z-index: 2;
    max-width: 90rem;
    margin: 0 auto;
`

export const BannerImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
    @media (max-width: 991px){
        object-position: right;
    }
    @media (max-width: 767px){
        object-fit: fill;
        height: 100%;
        left: unset;
        width: auto;
        right: 0;
    }
`

export const BannerTitle = styled.h1`
    font-family: 'main';
    &:lang(ka){
        font-family: 'arial-caps';
    }
    font-weight: 800;
    font-size: 3.25rem;
    line-height: 4rem;
    color: #fff;
    margin-bottom: 1.75rem;
    span{
        color: #CE2424;
    }
    @media (max-width: 767px){
        font-size: 1.75rem;
        line-height: 2.25rem;
        span{
            display: block;
        }
        b{
            display: none;
        }
    }
`

export const BannerDesc = styled.p`
    font-family: 'main';
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #FFFFFF;
    margin-bottom: 2.25rem;
    max-width: 36.5rem;
    @media (max-width: 767px){
        width: 60%;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
`

export const BannerLink = styled(Link)`
    font-family: 'main';
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    padding: 1rem 1.75rem;
    background: #CE2424;
    border-radius: 8px;
    &:hover{
        color: #FFFFFF;
    }
    @media (max-width: 767px){
        padding: 0.75rem 1rem;
        font-size: 0.75rem;
        line-height: 1.125rem;
        background: #CE2424;
        border-radius: 4px;
    }
`