import React from 'react';
import { CardTypeCardWrapper, TitleWrapper } from './style';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface CarTypeCardProps {
  image: string;
  title: string;
  link: string | null
}

const CarTypeCard = ({image, title, link}: CarTypeCardProps) => {
  const { t } = useTranslation()
  return (
    <CardTypeCardWrapper as={link ? Link : "div"} to={link}>
      <img src={image} alt={t(title)} />
      <TitleWrapper>
        <h3>{t(title)}</h3>
      </TitleWrapper>
    </CardTypeCardWrapper>
  );
};

export default CarTypeCard;
