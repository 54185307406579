import styled from 'styled-components';
import { Card, Tag, Typography, Row, Button, Spin } from 'antd';
import Slider from 'react-slick';
import { White, Grey, Primary, ErrorCl, SuccessCl, Yellow, Opacity50 } from '../../shared-styles';

const { Text } = Typography;

export const StyledCard = styled(Card)`
  width: 400px;
  margin: 10px;
  border-radius: 4px;
  .ant-card-cover {
    position: relative;
  }
  @media (max-width: 520px) {
    width: 300px;
  }
  .ant-card-body {
    padding: 14px;
  }
`;

export const LocationText = styled(Text)`
  font-size: 13px;
  color: ${Grey};
  margin: 0 0 5px;
`;

export const MakeText = styled(Text)`
  color: ${Primary};
  font: 700 1.125rem/1.3333333333em 'Cabin', sans-serif;
`;

export const DamageText = styled(Text)`
  color: ${Primary};
  font: 700 0.875rem/1.4285714286em 'Cabin', sans-serif;
`;

export const LotTag = styled(Tag)`
  position: absolute;
  width: unset;
  color: ${Primary};
  padding: 0 7px;
  font: 700 0.75rem/1.8333333333em 'Cabin', sans-serif;
  border-radius: 4px;
  margin: 10px;
  z-index: 1;
`;

export const SaleTag = styled(Tag)`
  position: absolute;
  width: unset;
  right: 0;
  padding: 0 7px;
  font: 700 0.75rem/1.8333333333em 'Cabin', sans-serif;
  border-radius: 4px;
  margin: 10px;
  z-index: 1;
`;

export const SaleDate = styled(Tag)`
  position: absolute;
  padding: 10px 0px;
  margin: 120px 0px;
  z-index: 1;
  text-align: center;
  font: 700 16px 'Cabin', sans-serif;
`;

export const ActionBtns = styled.div`
  position: absolute;
  width: unset;
  bottom: 0;
  right: 0;
  margin: 10px !important;
  button {
    color: ${Grey};
    border-radius: 4px;
    &:hover,
    :active,
    :focus {
      background: ${White};
      color: ${ErrorCl};
      border-color: ${White};
    }
  }
`;

export const ActionFooter = styled(Row)`
  padding: 0 14px;
  align-items: center;
  div {
    margin: 0 !important;
  }
`;

export const DamageRow = styled(Row)`
  div {
    margin: 0px 0 8px 0 !important;
  }
`;

export const ActionFooterText = styled.div`
  display: grid;
  justify-items: flex-start;
  .ant-typography {
    font-weight: 600;
    color: ${Primary};
  }
  .bidValue {
    color: ${SuccessCl};
  }
  .bidFirst {
    color: ${ErrorCl};
  }
`;

export const LiveButton = styled(Button)`
  background-color: ${SuccessCl};
  color: ${White};
  border: 0;
  &:hover,
  :active,
  :focus {
    background-color: ${SuccessCl};
    color: ${White};
    border: 0;
  }
`;

export const ScSlider = styled(Slider)`
  position: relative;
  .slick-prev,
  .slick-next {
    z-index: 111;
  }
  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 0px;
  }
  .slick-prev:before,
  .slick-next:before {
    color: ${Primary};
  }
  .slick-slide div {
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track {
    display: flex;
    justify-content: space-between;
    margin: auto;
  }
`;

export const ScSliderPhoto = styled(Slider)<{ loaded: boolean }>`
  position: relative;
  overflow: hidden;
  .slick-prev,
  .slick-next {
    z-index: 1;
    background: ${Opacity50};
    font-size: 30px;
    width: 50px;
    height: 50px;
    color: ${White};
    padding: 10px;
    &:before {
      content: '';
    }
  }
  .slick-prev {
    left: 0;
    border-right: 6px solid ${Yellow};
    border-radius: 0px 2px 2px 0px;
  }
  .slick-next {
    right: 0;
    border-left: 6px solid ${Yellow};
    border-radius: 2px 0px 0px 2px;
  }
  .slick-slide {
    height: 298px;
  }
  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
  .ant-spin-blur {
    opacity: 0;
  }
`;
export const BackImg = styled.div.attrs<{ src: string }>((props) => ({
  style: {
    backgroundImage: `url(${props.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
}))<{ src: string }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: '';
  z-index: -1;
  filter: blur(4px);
`;

export const ScImage = styled.div`
  height: 298px;
  img {
    margin: auto;
  }
`;

export const LoadingImg = styled.div`
  height: 298px;
  background-color: black;
`;

export const ScSpin = styled(Spin)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
