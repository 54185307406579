import React from 'react'
import { Bagdes, PaymentCardWrapper, LotCardContent, LotCardFooter, LotCardImage, LotCardWrapper, SearchContentBody, VinBagde } from './style'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getLinkForLotPage } from '../../../../utils/functions';
import BadgeWithTooltip from '../../BadgeWithTooltip';

interface LotCardProps {
    vehicle: any;
    status: string;
    footer: any
}

export const LotCardSkeleton = () => {
    return (
        <LotCardWrapper>
            <SearchContentBody as={"div"}>
                <LotCardImage isLoading={true}/>
                <LotCardContent isLoading={true} />
            </SearchContentBody>
        </LotCardWrapper>
    )
}

const LotCard = ({vehicle, status, footer}: LotCardProps) => {
    const { t } = useTranslation()
  return (
    <LotCardWrapper>
        <SearchContentBody to={getLinkForLotPage(
        vehicle.lotNumber,
        vehicle.make,
        vehicle.model,
        vehicle.year,
      )}>
            <LotCardImage>
                <ul>
                    <li>ID <strong>{vehicle.id}</strong></li>
                    {status && <li><strong>{status}</strong></li>}
                </ul>
                <img src={vehicle.mainImage} alt="" />
            </LotCardImage>
            <LotCardContent>
                <h3>{vehicle.make} {vehicle.model} {vehicle.year}</h3>
                <ul>
                    <li>
                        Keys:
                        <strong>{vehicle.keys ? " available" : " not available"}</strong>
                    </li>
                    {vehicle.primaryDamage != "New"  
                        ? <li>Primary: <strong>{vehicle.primaryDamage}</strong></li>
                        : <li>{vehicle.primaryDamage}</li>
                    }
                </ul>
                <Bagdes>
                    {vehicle?.badges.map((badge: any) => (
                        <BadgeWithTooltip badge={badge} key={`${badge.name}_${vehicle.lotId}`} className="fifth-search-step" />
                    ))}
                    <VinBagde>{vehicle.vin}</VinBagde>
                </Bagdes>
            </LotCardContent>
        </SearchContentBody>
        {footer && <LotCardFooter>
            {footer(vehicle)}
        </LotCardFooter>}
    </LotCardWrapper>
  )
}

export default LotCard