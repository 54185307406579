import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './i18n/en.json';
import ka from './i18n/ka.json';
import ru from './i18n/ru.json';
import { getCookie } from './utils/functions';


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      ka: {
        translation: ka,
      },
      ru: {
        translation: ru,
      },
    },
    lng: getCookie("language") || "ka",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
