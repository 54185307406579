import React, { ChangeEvent, DragEvent, useRef, useState } from 'react'
import { FileUploaderButton, FileUploaderContent, FileUploaderIcon, FileUploaderSubtitle, FileUploaderTitle, FileUploaderWrapper } from './style'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next'
interface FileUploaderProps {
    onUpload: (file: File) => void
}

const FileUploader = ({onUpload}: FileUploaderProps) => {
    const { t } = useTranslation();
    const [isDragged, setIsDragged] = useState(false)
    const fileUploader = useRef(null)


    const uploadFiles = (files: FileList) => {
        if(files.length > 40){
            alert("Too Many File")
            return
        }
        for(const file of files){
            onUpload(file)
        }
    }
    // UploadChangeParam<UploadFile<any>>
    const onDrop = (e:  DragEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragged(false)

        uploadFiles(e.dataTransfer.files)
    };

    const onChange = (e:  ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragged(false)
        if(e.target && e.target.files){
            uploadFiles(e.target.files)
        }
    };

  return (
    <FileUploaderWrapper isDragged={isDragged}>
        <FileUploaderContent>
            <FileUploaderIcon>
                <ReactSVG src='/icons/file-upload.svg' className='svg-wrapper'/>
            </FileUploaderIcon>
            <FileUploaderTitle>{t("addvehicle_upload_title")}</FileUploaderTitle>
            <FileUploaderSubtitle>{t("addvehicle_upload_subtitle")}</FileUploaderSubtitle>
            <FileUploaderButton>{t("addvehicle_upload_button")}</FileUploaderButton>
        </FileUploaderContent>
        <input 
            type="file" 
            multiple
            ref={fileUploader}
            onChange={onChange}
            onDrop={onDrop} 
            onDragEnter={() => setIsDragged(true)}
            onDragLeave={() => setIsDragged(false)}
        />
    </FileUploaderWrapper>
  )
}

export default FileUploader