import axios from 'axios';
import { logOut } from '../store/slices/authSlice';

const url = `${process.env.REACT_APP_API_URL}api/`;

const axiosInstance = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('lionAutoToken');

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      logOut();
    }
    return Promise.reject(error);
  },
);

export { axiosInstance };
