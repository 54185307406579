import React from 'react';
import { StyledInput } from './style';
import { PhoneInputProps } from 'react-phone-input-2';

export interface CustomPhoneInputProps extends PhoneInputProps {
  variant?: CustomPhoneInputVariant;
}

export enum CustomPhoneInputVariant {
  SECONDARY,
  PRIMARY,
  TERTIARY,
  CONTACT
}

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = ({ variant, ...rest}) => {
  
  return (
    <StyledInput
      {...rest}
      variant={variant}
    />
  );
};

export default CustomPhoneInput;
