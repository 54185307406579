/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, memo, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, TreeSelect, Dropdown, Button, Row, Col } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { getAllAuctionsAddressesAsDictionary } from '../../../store/slices/auctionSlice';
import {
  getMakeModelsByVehicleType,
  makeModelsByVehicleTypeSelector,
  productGeneralDictionariesByLanguageSelector,
} from '../../../store/slices/dictionarySlice';
import { MakeModelsByVehicleType } from '../../../interfaces/Dictionary';
import {
  ScFormItem,
  ScSelect,
  ScTreeSelect,
  DropdownFields,
  DropdownOdo,
  FormWrapper,
  AdditionalBannerTitle,
  AdditionalBannerFindFormWrapper,
  ActionButtonWrapper,
  SubmitButton,
  BannerFindFormContainer,
  ToggleAdditionalFilter,
  ClearButton,
  YearFilterItem,
  YearFilterWrapper,
} from '../style';
import { getDamages, getLocations } from '../../../utils/functions';
import { PopularVehicleBtn } from '../../../shared/components/MainBanner/style';
import { popularVehiclesSelector, getAllPopularVehicles } from '../../../store/slices/initSlice';
import AdditionalBannerFindForm from './AdditionalBannerFindForm';
import { ReactSVG } from 'react-svg';
import PriceSlider from '../../../shared/components/PriceSlider';
import i18n from '../../../i18n';

const { SHOW_CHILD, SHOW_PARENT } = TreeSelect;

const { Option } = Select;

interface Props {
  onToggleAdvanced: () => void;
  isOpenAdditionalFilter: boolean;
  dictionaryForTransitAndYardDefinition: any;
  onSubmit?: () => void;
}

const BannerFindForm: FC<Props> = ({ onToggleAdvanced, isOpenAdditionalFilter, dictionaryForTransitAndYardDefinition, onSubmit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const { lg } = useBreakpoint();

  useEffect(() => {
    dispatch(getMakeModelsByVehicleType(2));
    dispatch(getAllAuctionsAddressesAsDictionary());
    dispatch(getAllPopularVehicles());
    setYearToOptions(getYearPeriod());
  }, [dispatch, i18n.language]);

  const [modelList, setModelList] = useState<any[]>([]);
  const [popularMake, setPopularMake] = useState<string[]>([]);
  const [selectedMakes, setSelectedMakes] = useState<string[]>([]);
  const [modelOpenKeys, setmodelOpenKeys] = useState<any[]>([]);
  const [modelValueKey, setmodelValueKey] = useState<any[]>([]);

  const changeOdometr = (event: [number, number]) => {
    const min = (+event[0] / 500000) * 100;
    const max = (event[1] / 500000) * 100;
    const point1 = `0 ${100 - min}%, `;
    const point2 = `100% ${100 - max}%, `;
    const point3 = '100% 100%, ';
    const point4 = '0 100% ';

    return `polygon(${point1}${point2}${point3}${point4})`;
  };

  useEffect(() => {
    setmodelValueKey(params.get('vehiclemodel')?.split(',') || []);
  }, [params]);

  const getPriceVal = useCallback(() => {
    const from = params.get('vehiclepricefrom');
    const to = params.get('vehiclepriceto');
    return [from ? +from : 0, to ? +to : 50000];
  }, [params]);


  const [currentPrice, setCurrentPrice] = useState<[number, number]>(getPriceVal() as [number, number]);

  interface YearOption {
    key: string;
    title: string;
  }

  const getYearPeriod = (): YearOption[] => {
    const years: YearOption[] = [];
    const nowYear = new Date().getFullYear();
    for (let i = nowYear - 100; i <= nowYear; i++) {
      years.push({ key: `${i}`, title: `${i}` });
    }
    return years.sort((a, b) => +b.key - +a.key);
  };

  const [yearFrom, setYearFrom] = useState('');
  const [yearToOptions, setYearToOptions] = useState<YearOption[]>(getYearPeriod());

  const handleChange = (event: [number, number]) => {
    setCurrentPrice(event);
    const ChangeParam = new URLSearchParams(history.location.search);
    ChangeParam.set('vehiclepricefrom', event[0].toString());
    ChangeParam.set('vehiclepriceto', event[1].toString());

    history.push({ search: ChangeParam.toString() });
  };

  useEffect(() => {
    const priceVal = getPriceVal() as [number, number];
    setCurrentPrice(priceVal);
  }, [getPriceVal, params]);

  // const handleChangeOdometrToUrl = useCallback(() => {
  //   const from = `${currentOdometr[0]}`;
  //   const to = `${currentOdometr[1]}`;
  //   params.set('vehicleodometrfrom', from);
  //   params.set('vehicleodometrto', to);
  //   history.push({
  //     pathname: history.location.pathname,
  //     search: params.toString(),
  //   });
  // }, [currentOdometr, history, params]);

  const makeModelsByVehicleType = useSelector(makeModelsByVehicleTypeSelector);
  const productGeneralDictionariesByLanguage = useSelector(productGeneralDictionariesByLanguageSelector);
  const popularVehicles = useSelector(popularVehiclesSelector);

  const getModels = (make: MakeModelsByVehicleType) => {
    if (make.modelGroups && make.modelGroups.length) {
      return make.modelGroups.map((model: { modelGroup: string; models: string[] }) => ({
        title: model.modelGroup,
        value: `${make.make}/${model.modelGroup}`.replace(/\s/g, '_').toLowerCase(),
        key: `${make.make}/${model.modelGroup}`.replace(/\s/g, '_').toLowerCase(),
        children: model.models.map((item: string) => ({
          title: item,
          value: `${make.make}/${model.modelGroup}/${item}`.replace(/\s/g, '_').toLowerCase(),
          key: `${make.make}/${model.modelGroup}/${item}`.replace(/\s/g, '_').toLowerCase(),
        })),
      }));
    }
    return make.model.map((model: string) => ({
      title: model,
      value: `${make.make}/${model}`.replace(/\s/g, '_'),
      key: `${make.make}/${model}`.replace(/\s/g, '_'),
    }));
  };

  const PopularVehicleButton = ({
    onClick,
    icon,
    selected,
    children,
  }: {
    onClick: () => void;
    icon: React.ReactNode;
    selected: boolean;
    children: React.ReactNode;
  }) => {
    return (
      <PopularVehicleBtn selected={selected} onClick={onClick}>
        {icon} {children}
      </PopularVehicleBtn>
    );
  };

  const updateModelList = useCallback(
    (makes: string[]) => {
      const newList = makes.map((make: string) => makeModelsByVehicleType.find((item) => item.make.replace(/\s/g, '_').toLowerCase() === make)).filter((item) => item);

      const treeDataFiltered = (newList as MakeModelsByVehicleType[]).filter((make) => make.model.length || make.modelGroups.length);
      const treeData = treeDataFiltered.map((make: MakeModelsByVehicleType) => ({
        title: make.make,
        value: make.make.replace(/\s/g, '_').toLowerCase(),
        key: make.make.replace(/\s/g, '_').toLowerCase(),
        checkable: true,
        selectable: true,
        children: getModels(make),
      }));
      setModelList(treeData || []);
    },
    [makeModelsByVehicleType]
  );

  const handleChangeFilter = useCallback(
    (data: { [key: string]: string | string[] }) => {
      const field = Object.keys(data)[0];
      let value: string;

      if (field === 'vehiclemake') {
        updateModelList(data[field] as string[]);
      }
      if (field === 'defect' || field === 'bodystyle') {
        if (data[field].length) {
          params.set(field, (data[field] as string[]).filter((item) => item).join());
        } else {
          params.delete(field);
        }
      } else {
        if (Array.isArray(data[field])) {
          value = (data[field] as string[]).join();
        } else value = data[field] as string;
        if (value) params.set(field, value);
        else params.delete(field);
      }

      params.set('page', '1');
      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      });
    },
    [history, params, updateModelList]
  );

  const handleClear = (event: any) => {
    event.stopPropagation();
    setIsHovered(false);
    const ChangeParam = new URLSearchParams(history.location.search);
    ChangeParam.delete('vehiclepricefrom');
    ChangeParam.delete('vehiclepriceto');
    history.push({ search: ChangeParam.toString() });
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    const from = params.get('vehiclepricefrom');
    const to = params.get('vehiclepriceto');
    if (from || to) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const makes = params.get('vehiclemake')?.split(',');
    if (makes && makeModelsByVehicleType) {
      setSelectedMakes(makes);
      updateModelList && updateModelList(makes);
      setmodelOpenKeys([makes[0]]);
    } else {
      setSelectedMakes([]);
    }
  }, [makeModelsByVehicleType, params, updateModelList]);

  useEffect(() => {
    const unique = Array.from(new Set(popularVehicles.map((item) => item.make)));
    setPopularMake(unique);
  }, [popularVehicles]);

  const handleSetMake = (make: string) => {
    const makes = params.get('vehiclemake')?.split(',') || [];
    const index = makes.indexOf(make);
    let updatedMakes = [];
    if (index < 0) {
      makes.push(make);
      updatedMakes = [...selectedMakes, make];
    } else {
      makes.splice(index, 1);
      updatedMakes = selectedMakes.filter((item) => item !== make);
    }
    handleChangeFilter({ vehiclemake: makes });
    setSelectedMakes(updatedMakes);
  };

  const handleChangeValue = (value: any) => {
    const make = value.find((item: any) => !item.includes('/'));
    setmodelValueKey(value.filter((item: any) => item !== make));
    handleChangeFilter({
      vehiclemodel: value.filter((item: any) => item !== make),
    });
  };

  const handleYearFromChange = (value: any) => {
    setYearFrom(value);
    if (!value) {
      setYearToOptions(getYearPeriod());
      return;
    }
    const selectedYear = parseInt(value);
    const yearToOptions = getYearPeriod().filter((item) => parseInt(item.key) >= selectedYear);
    setYearToOptions(yearToOptions);
  };

  return (
    <BannerFindFormContainer>
      <FormWrapper
        name="search"
        layout="vertical"
        fields={[
          { name: ['defect'], value: params.get('defect')?.split(',') },
          { name: ['vehicletype'], value: params.get('vehicletype') },
          { name: ['vehiclemake'], value: params.get('vehiclemake')?.split(',') },
          { name: ['vehicleyear'], value: params.get('vehicleyear')?.split(',') },
          { name: ['yearfrom'], value: params.get('yearfrom')?.split(',') },
          { name: ['yearto'], value: params.get('yearto')?.split(',') },
          { name: ['auctionsaddress'], value: params.get('auctionsaddress')?.split(',') },
          { name: ['carlocation'], value: params.get('carlocation')?.split(',') },
          {
            name: ['vehicleprice'],
            value: [params.get('vehiclepricefrom'), params.get('vehiclepriceto')],
          },
        ]}
        form={form}
        onValuesChange={handleChangeFilter}
        scrollToFirstError
      >
        <ScFormItem name="defect">
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_PARENT}
            placeholder={t('search_filter_condition')}
            showSearch
            treeData={getDamages(productGeneralDictionariesByLanguage.defectStylesList)}
            maxTagCount="responsive"
          />
        </ScFormItem>

        <ScFormItem shouldUpdate name="vehiclemake">
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('search_filter_make')}
            showSearch
            treeData={makeModelsByVehicleType?.map((item) => ({
              id: item.make.replace(/\s/g, '_'),
              value: item.make.replace(/\s/g, '_').toLowerCase(),
              title: item.make,
            }))}
            maxTagCount="responsive"
            dropdownRender={(menu: any) => (
              <>
                {popularMake.map((item) => (
                  <PopularVehicleButton
                    onClick={() => handleSetMake(item)}
                    icon={<i className={`car-${item.toLowerCase()}`} />}
                    key={item}
                    selected={selectedMakes.includes(item)}
                    children={undefined}
                  />
                ))}
                {menu}
              </>
            )}
          />
        </ScFormItem>

        <ScFormItem shouldUpdate>
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('search_filter_serial_model')}
            showSearch
            treeData={modelList}
            maxTagCount="responsive"
            treeExpandedKeys={modelOpenKeys}
            onTreeExpand={setmodelOpenKeys}
            value={modelValueKey}
            onChange={handleChangeValue}
          />
        </ScFormItem>

        <YearFilterWrapper>
          <YearFilterItem>
            <ScFormItem name="yearfrom">
              <ScSelect
                showArrow
                allowClear
                dropdownStyle={lg && { zIndex: 5 }}
                showSearch
                maxTagCount="responsive"
                placeholder={t('search_filter_year_from')}
                onChange={handleYearFromChange}
                value={yearFrom}
              >
                {getYearPeriod().map((item) => (
                  <Option value={item.title} key={item.key}>
                    {item.title}
                  </Option>
                ))}
              </ScSelect>
            </ScFormItem>
          </YearFilterItem>

          <YearFilterItem>
            <ScFormItem name="yearto">
              <ScSelect
                showArrow
                allowClear
                dropdownStyle={lg && { zIndex: 5 }}
                showSearch
                maxTagCount="responsive"
                placeholder={t('search_filter_year_to')}
                options={yearToOptions.map((item) => ({ value: item.title, key: item.key }))}
              >
                {yearToOptions.map((item) => (
                  <Option value={item.title} key={item.key}>
                    {item.title}
                  </Option>
                ))}
              </ScSelect>
            </ScFormItem>
          </YearFilterItem>
        </YearFilterWrapper>

        <ScFormItem shouldUpdate={(prevValues: any, currentValues: any) => prevValues.vehicleprice !== currentValues.vehicleprice}>
          {({ getFieldValue }: any) => (
            <Dropdown
              trigger={['click']}
              overlay={
                <DropdownFields onClick={(e) => e.preventDefault()}>
                  <PriceSlider min={0} max={50000} value={currentPrice} onChange={handleChange} />
                </DropdownFields>
              }
              overlayStyle={lg ? { zIndex: 5 } : undefined}
            >
              <DropdownOdo
                icon={isHovered ? <ClearButton onClick={(event: any) => handleClear(event)} /> : <DownOutlined />}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {getFieldValue('vehicleprice') && getFieldValue('vehicleprice')[0] !== null ? getFieldValue('vehicleprice').join(' $ - ') + ' $' : t('search_filter_price')}{' '}
              </DropdownOdo>
            </Dropdown>
          )}
        </ScFormItem>

        <ScFormItem name="carlocation">
          <ScTreeSelect
            showArrow
            allowClear
            dropdownStyle={lg && { zIndex: 5 }}
            treeNodeFilterProp="title"
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={t('search_filter_location')}
            showSearch
            treeData={getLocations(dictionaryForTransitAndYardDefinition)}
            maxTagCount="responsive"
          />
        </ScFormItem>
      </FormWrapper>

      {isOpenAdditionalFilter && (
        <>
          <AdditionalBannerTitle>{t('search_additional')}</AdditionalBannerTitle>
          <AdditionalBannerFindFormWrapper>
            <AdditionalBannerFindForm />
          </AdditionalBannerFindFormWrapper>
        </>
      )}

      <ActionButtonWrapper>
        <ToggleAdditionalFilter onClick={onToggleAdvanced}>
          <ReactSVG src="/icons/arrow-up.svg" className={`svg-wrapper ${isOpenAdditionalFilter ? '' : 'upside-down'}`} />
          {isOpenAdditionalFilter ? t('Hide Additional filter') : t('Show Additional filter')}
        </ToggleAdditionalFilter>
        <SubmitButton onClick={onSubmit}>{t('Search')}</SubmitButton>
      </ActionButtonWrapper>
    </BannerFindFormContainer>
  );
};

export default memo(BannerFindForm);
