import React from 'react'
import {TeamSliderWrapper, TeamTitle, TeamWrapper } from './style'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import TeamCard, { Person } from './TeamCard';
import { useTranslation } from 'react-i18next';
const persons = require("./persons.json")


const Team = () => {
  const { t } = useTranslation()
      
  return (
    <TeamWrapper>
        <TeamTitle>{t('About_us_teams_title')}</TeamTitle>
        <TeamSliderWrapper>
                {persons.map((person: Person) => (
                    <TeamCard key={person.id} person={person}/>
                ))}
        </TeamSliderWrapper>
    </TeamWrapper>
  )
}

export default Team