import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Countdown from 'antd/lib/statistic/Countdown';
import styled from 'styled-components';
import { PublicOneLotPageData, SaleInformation } from '../../interfaces/Product';
import BidActionButton from './BidActionButton';
import LotDescriptionCard, { LotCardRow, LotCardText, LotCardTextVariant } from '../../shared/components/LotDescriptionCard';
import { isAuthenticatedSelector, setUserLanguage, userLanguageSelector } from '../../store/slices/authSlice';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  saleInfo: SaleInformation;
  isRun: boolean;
  ownerMobilePhoneNumber: string;
  data: PublicOneLotPageData;
  productId: number;
}

export const ScCountdown = styled(Countdown)`
  .ant-statistic-content {
    font-size: 16px;
    font-weight: 700;
  }
`;

const SaleInformationCard: FC<Props> = ({ saleInfo, isRun, ownerMobilePhoneNumber, data, productId }) => {
  const { t } = useTranslation();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const checkIsLive = (time: string) => {
    if (!isRun) return false;
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const newDate = new Date().setHours(hours, minutes, seconds);
    return new Date(newDate).getTime() - Date.now() <= 0;
  };

  const getTimeLeftInfo = () => {
    if (!saleInfo.laneItemGridRow) return t('lot_sale_information_futured');
    if (checkIsLive(saleInfo.saleDate)) return t('lot_sale_information_live_auction');
    return <ScCountdown title="" value={saleInfo.saleDate} format="DD[D] HH[H] mm[M] ss[S]" onFinish={() => {}} />;
  };

  const getAuctionTimeLeftInfo = () => {
    if (saleInfo.auctionDateTime == null) return t('lot_sale_information_timer_auction_end');
    return (
      <ScCountdown
        title=""
        value={saleInfo.saleDate}
        format="DD[D] HH[H] mm[M] ss[S]"
        onFinish={() => {
          window.location.reload();
        }}
      />
    );
  };

  return (
    <LotDescriptionCard title={`${t('lot_sale_information')}`}>
      <>
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_sale_information_seller_phone')}</LotCardText>
          <LotCardText fw={500}>
            {ownerMobilePhoneNumber}{' '}
            {!isAuthenticated && (
              <Tooltip title={t("lot_sale_information_seller_phone_info")}>
                <InfoCircleOutlined style={{ color: '#1890ff' }} />
              </Tooltip>
            )}
          </LotCardText>
        </LotCardRow>
        {/* <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_sale_information_auction_name')}</LotCardText>
          <LotCardText fw={500}>{saleInfo.auctionName || t('lot_sale_information_futured')}</LotCardText>
        </LotCardRow> */}
        {/* <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_sale_information_lane')}</LotCardText>
          <LotCardText fw={500}>{saleInfo.laneItemGridLotCardRow || t('lot_sale_information_futured')}</LotCardText>
        </Row> */}
        <LotCardRow justify="space-between">
          <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_sale_information_location')}</LotCardText>
          <LotCardText fw={500}>
            {saleInfo.locationType}: {saleInfo.locationName}
          </LotCardText>
        </LotCardRow>

        <BidActionButton data={data} productId={productId} />

        {/* {!checkIsLive(saleInfo.saleDate) && !saleInfo.auctionDateTime && (
          <LotCardRow justify="space-between">
            <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_sale_information_sale_date')}</LotCardText>
            <LotCardText fw={500}>{saleInfo.saleDate ? dayjs(saleInfo.saleDate).format('DD/MM/YYYY') : t('lot_sale_information_futured')}</LotCardText>
          </LotCardRow>
        )} */}
        {/* {saleInfo.saleDate && saleInfo.auctionDateTime == null && (
          <LotCardRow justify="space-between">
            <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_sale_information_live_auction')}</LotCardText>
            <LotCardText fw={500}>{getTimeLeftInfo()}</LotCardText>
          </LotCardRow>
        )}
        {saleInfo.auctionDateTime != null && (
          <LotCardRow justify="space-between">
            <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_sale_information_end_sale_date')}</LotCardText>
            <LotCardText fw={500}>{saleInfo.saleDate ? dayjs(saleInfo.saleDate).format('DD/MM/YYYY') : t('lot_sale_information_futured')}</LotCardText>
          </LotCardRow>
        )}
        {saleInfo.auctionDateTime != null && (
          <LotCardRow justify="space-between">
            <LotCardText variant={LotCardTextVariant.GRAY}>{t('lot_sale_information_timer_auction')}</LotCardText>
            <LotCardText fw={500}>{getAuctionTimeLeftInfo()}</LotCardText>
          </LotCardRow>
        )} */}
      </>
    </LotDescriptionCard>
  );
};
export default memo(SaleInformationCard);
