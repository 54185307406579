/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/lib/select';
import { contactCountries } from '../../utils/constants';
import { CityMapWrapper } from './style';

const { Option } = Select;

interface CityMapProps {
  onPrevBtnHandle: () => void;
  country: any;
}

const CityMap = ({ country, onPrevBtnHandle }: CityMapProps) => {
  const { t } = useTranslation();

  const [coordinate, setCoordinate] = useState<{
    lng: number;
    lat: number;
  } | null>({
    lat: country.lat,
    lng: country.lng,
  });

  const loadScript = (url: string, callback: any) => {
    const script: any = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };
  function createCenterControl(map: any) {
    const controlButton = document.createElement('button');

    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.borderRadius = '3px';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.color = 'rgb(25,25,25)';
    controlButton.style.cursor = 'pointer';
    controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlButton.style.fontSize = '16px';
    controlButton.style.lineHeight = '38px';
    controlButton.style.margin = '10px';
    controlButton.style.padding = '0 5px';
    controlButton.style.textAlign = 'center';
    controlButton.textContent = 'Back';
    controlButton.type = 'button';

    controlButton.addEventListener('click', () => {
      onPrevBtnHandle();
    });
    return controlButton;
  }

  const initMap = useCallback(
    (zoom = 10) => {
      const center = coordinate;
      const mapContainer = document.getElementById('map');
      if (mapContainer && center) {
        const map = new window.google.maps.Map(mapContainer, {
          zoom,
          center,
          mapTypeControl: false,
          mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
          draggableCursor: 'default',
        } as google.maps.MapOptions);
        if (center) {
          // eslint-disable-next-line no-new
          new window.google.maps.Marker({
            position: center,
            map,
          });
        }
        const centerControlDiv = document.createElement('div');
        // Create the control.
        const centerControl = createCenterControl(map);
        centerControlDiv.appendChild(centerControl);
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);
      }
    },
    [coordinate]
  );

  useEffect(() => {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=`+process.env.REACT_APP_GOOGLE_MAP_KEY+`&libraries=places,geometry`, () => {
      initMap();
    });
  }, [initMap]);

  return <CityMapWrapper id="map" />;
};

export default memo(CityMap);
