import styled from "styled-components";

export const MobailMenuWrapper = styled.div`
    background-color: #000;
    width: 100%;
    max-height: 100vh;
    height: 100dvh;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
`

export const MobailMenuHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 1.125rem 1rem 0; 
    .svg-wrapper{
        &, & > div{
            width: 1.5rem;
            height: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`

export const CloseButton = styled.button`
    margin-left: auto;
    path{
        stroke: #fff;
        fill: #fff;
    }
`

export const PrevPageButton = styled.button`
`

export const MobailMenuBody = styled.div`
    padding: 1.875rem 1rem 3rem;
    height:  calc( 100vh - 3rem);
    overflow-y: auto;
`