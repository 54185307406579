/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col, Button, TablePaginationConfig, Input, Select, Space, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { EyeFilled } from '@ant-design/icons';
import {
  getCurrentUserBalanceAmount,
  currentUserBalanceAmountSelector,
  userPaymentsDueListSelector,
  getUserPaymentsDueList,
  userPaymentsDueListTotalCountSelector,
  userPaymentsDueListIsLoadingSelector,
  downloadPdfBase64,
} from '../../store/slices/paymentsSlice';
import NoPreviewImage from '../../shared/components/NoPreviewImage';
import { Payments } from '../../interfaces/Payments';
import LocationForTable from '../../shared/components/LocationForTable';
import { getLinkForLotPage, getOdoValue } from '../../utils/functions';
import CustomTable from '../../shared/components/CustomTable';
import { changeOpenQuickView, getQuickViewLotData } from '../../store/slices/productSlice';
import { CenterAlign } from '../FindVehiclesPage/style';
import BadgeWithTooltip from '../../shared/components/BadgeWithTooltip';
import { Vehicle } from '../../interfaces/FindVehicles';
import Price from '../../shared/components/Price';
import api from '../../core/api';
import { odometrIsKmSelector } from '../../store/slices/initSlice';
import { FundsWrapper, HeaderActionGroup, HeaderTitle, HeaderTitleGroup, HeaderWarning, HeaderWrapper, PaymentCardsList, PaymentsWrapper } from './style';
import { ReactSVG } from 'react-svg';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import PaymentsCard from './PaymentsCard';
import ImageColumn from '../../shared/components/AdvanceSearch/ImageColumn';
import VehicleNameColumn from '../../shared/components/AdvanceSearch/VehicleNameColumn';
import VehicleInfoColumn from '../../shared/components/AdvanceSearch/VehicleInfoColumn';
import QuickOverview from '../../shared/components/AdvanceSearch/QuickOverviewButton';
import AdvanceSearchButton from '../../shared/components/AdvanceSearch/AdvanceSearchButton';

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;

const PaymentsPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { md } = useBreakpoint()
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const odometrIsKm = useSelector(odometrIsKmSelector);
  const [searchText, setSearchText] = useState('');
  const [type, setType] = useState<string>('');

  const getParamValue = useCallback((field: string) => params.get(field)?.replace(/%2C/g, ',') || undefined, [params]);

  const [period, setPeriod] = useState<number>(getParamValue('period') ? +(getParamValue('period') as string) : 30);

  const [tableSettings, setTableSettings] = useState<{
    Page?: number;
    PageItemsCount?: number;
    SortField?: string;
    SortOrder: string;
  }>({
    Page: getParamValue('page') ? +(getParamValue('page') as string) : 1,
    PageItemsCount: getParamValue('itemscount') ? +(getParamValue('itemscount') as string) : 10,
    SortField: '',
    SortOrder: 'desc',
  });

  const handleOpenQuickView = useCallback(
    (id) => {
      dispatch(changeOpenQuickView(id));
      dispatch(getQuickViewLotData(id));
    },
    [dispatch],
  );

  const handleDownloadPdf = useCallback(
    (id: number, title: string) => () => {
      dispatch(downloadPdfBase64(title, `${api.GetDataForProductInvoices}?paymentsIdList=${id}`));
    },
    [dispatch],
  );

  const columns = [
    {
      title: t("lot_sale_date"),
      render: (vehicle: Payments) => {
        if (type === 'paymentsdue') {
          return vehicle.saleDate ? dayjs(vehicle.saleDate).format('DD/MM/YYYY') : '';
        }
        return vehicle.issuedDate ? dayjs(vehicle.issuedDate).format('DD/MM/YYYY') : '';
      },
    },
    {
      title: t("table_col_image"),
      dataIndex: 'mainImage',
      key: 'mainImage',
      render: (mainImage: string, vehicle: Payments) => (
        <ImageColumn 
         to={getLinkForLotPage(vehicle.lotNumber, vehicle.make, vehicle.model, vehicle.year)}
         mainImage={mainImage}
        />
      ),
    },
    {
      title: t("table_col_vehicle_name"),
      dataIndex: 'VehicleName',
      key: 'VehicleName',
      render: (year: string,vehicle: Vehicle) => (
        <VehicleNameColumn vehicle={vehicle} />
      ),
    },
    {
      title: t("table_col_vehicle_info"),
      dataIndex: 'VehicleInfo',
      key: 'VehicleInfo',
      render: (color: string, vehicle: Vehicle ) => (
        <VehicleInfoColumn vehicle={vehicle} />
      ),
    },
    {
      title: 'Lot #',
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      render: (lotNumber: string, item: Payments) => (
        <Button size="small" type="link" onClick={() => history.push(getLinkForLotPage(lotNumber, item.make, item.model, item.year))}>
          {lotNumber}
        </Button>
      ),
      sorter: {
        compare: (a: any, b: any) => a.lotNumber - b.lotNumber,
      },
    },
    {
      title: t("table_col_vin"),
      dataIndex: 'vin',
      key: 'vin',
      sorter: {
        compare: (a: any, b: any) => a.vin - b.vin,
      },
    },
    {
      title: t("table_col_location"),
      dataIndex: 'locationId',
      key: 'locationId',
      sorter: {
        compare: (a: any, b: any) => a.locationId - b.locationId,
      },
      render: (locationId: number, vehicle: Payments) => <LocationForTable locationId={locationId} vehicle={vehicle} />,
    },
    type === 'paymentsdue'
      ? {
        title: t("lot_Bid"),
        dataIndex: 'bid',
        key: 'bid',
        render: (bid: number) => <Price price={bid} />,
      }
      : {},
    {
      title: t("lot_invoice"),
      dataIndex: 'invoice',
      key: 'invoice',
      render: (invoice: number) => <Price price={invoice} />,
    },
    location.pathname.split('/')[1] === 'paymentsdue'
      ? {
        title: t("lot_due_amount"),
        dataIndex: 'dueAmount',
        key: 'dueAmount',
        render: (dueAmount: number) => <Price price={dueAmount} />,
      }
      : {
        title: t("payment_table_leftloc"),
        dataIndex: 'leftLocation',
        key: 'leftLocation',
        render: (leftLocation: string) => dayjs(leftLocation).format('DD/MM/YYYY'),
        sorter: {
          compare: (a: any, b: any) => a.leftLocation - b.leftLocation,
        },
      },
    {
      title: t("payment_table_status"),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      sorter: {
        compare: (a: any, b: any) => a.paymentStatus - b.paymentStatus,
      },
    },
    // location.pathname.split('/')[1] === 'paymentsdue' ? {
    {
      title: t("deposit_table_action"),
      dataIndex: 'id',
      key: 'id',
      render: (id: number, data: any) => (
        <AdvanceSearchButton 
          onClick={handleDownloadPdf(data.id, data.id)} 
          text={t('downloadInvoice')} />
      ),
      // } : {},
    },
  ];

  useEffect(() => {
    setType(location.pathname.split('/')[1]);
  }, [location]);

  useEffect(() => {
    dispatch(getCurrentUserBalanceAmount());
    dispatch(getUserPaymentsDueList(location.pathname.split('/')[1], period, 'desc', 1, 10, '', ''));
  }, [dispatch, location, period]);

  const currentUserBalanceAmount = useSelector(currentUserBalanceAmountSelector);
  const userPaymentsDueList = useSelector(userPaymentsDueListSelector);
  const userPaymentsDueListTotalCount = useSelector(userPaymentsDueListTotalCountSelector);
  const userPaymentsDueListIsLoading = useSelector(userPaymentsDueListIsLoadingSelector);


  const handleTableChange = useCallback(
    (pg: TablePaginationConfig, fltr: any, sort: any) => {
      setTableSettings({
        Page: pg.current,
        PageItemsCount: pg.pageSize,
        SortField: sort.field,
        SortOrder: sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc',
      });
      dispatch(
        getUserPaymentsDueList(
          type,
          period,
          sort.order ? (sort.order.charAt(0).toUpperCase() + sort.order.slice(1)).slice(0, -3) : 'Desc',
          pg.current,
          pg.pageSize,
          sort.field,
          searchText,
        ),
      );
    },
    [dispatch, type, searchText, period],
  );

  const handleChangePeriod = useCallback(
    (value: number) => {
      setPeriod(value);
      dispatch(
        getUserPaymentsDueList(type, value, tableSettings.SortOrder, tableSettings.Page, tableSettings.PageItemsCount, tableSettings.SortField, searchText),
      );
      value !== 30 ? params.set('period', `${value}`) : params.delete('period');

      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      });
    },
    [dispatch, type, tableSettings, searchText, params, history],
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchText(value);
      dispatch(getUserPaymentsDueList(type, period, tableSettings.SortOrder, tableSettings.Page, tableSettings.PageItemsCount, tableSettings.SortField, value));
    },
    [dispatch, type, tableSettings, period],
  );

  return (
    <PaymentsWrapper>
      <HeaderWrapper>
        <HeaderTitleGroup>
          <HeaderTitle>{type === 'paymentsdue' ? t('nav_user_menu_payments_due') : t('nav_user_menu_payments_history')}</HeaderTitle>
          {/* <HeaderWarning>
            <ReactSVG src="/icons/info-circle.svg" />
            {t('user_fees_are_calculated')}
          </HeaderWarning> */}
        </HeaderTitleGroup>

        <HeaderActionGroup>
          <FundsWrapper>
            <span>{t('yourTotalAvailableFunds')}</span>
            <Price price={currentUserBalanceAmount} />
          </FundsWrapper>

          <Select defaultValue={period} onChange={handleChangePeriod}>
            <Option value={30}>{t("payment_last_30_days")}</Option>
            <Option value={60}>{t("payment_last_60_days")}</Option>
            <Option value={90}>{t("payment_last_90_days")}</Option>
          </Select>

          <Search placeholder={t("payment_search_input")} allowClear enterButton={t("Search")} onSearch={handleSearch} />
        </HeaderActionGroup>
      </HeaderWrapper>
      {md ? <CustomTable
        data={userPaymentsDueList.map((item) => ({ ...item, key: item.id }))}
        columns={columns}
        totalCount={userPaymentsDueListTotalCount}
        isLoading={userPaymentsDueListIsLoading}
        onChangeTable={handleTableChange}
        scroll={{ x: 'max-content' }}
      /> : <PaymentCardsList>
        {userPaymentsDueList.map((vehicle) => (
          <PaymentsCard vehicle={vehicle} key={vehicle.id} handleDownloadPdf={handleDownloadPdf}/>  
        ))}
      </PaymentCardsList>}
    </PaymentsWrapper>
  );
};

export default memo(PaymentsPage);
