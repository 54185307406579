import React, { FC, memo, useEffect, useState, useCallback } from 'react';
import { Row, Col, Card, Space, Typography, Divider, Select, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useLocation } from 'react-router';
import { SelectValue } from 'antd/lib/select';
import { Helmet } from 'react-helmet';
import {
  allPricesSelector,
  getAllPrices,
  getPublicOneLotPageData,
  publicOneLotPageDataSelector,
  publicOneLotPageDataLoadingSelector,
} from '../../store/slices/productSlice';
import LotCard from './LotCard';
import BidInformationCard from './BidInformationCard';
import FeaturesCard from './FeaturesCard';
import SaleInformationCard from './SaleInformationCard';
import ServiceOrderCard from './ServiceOrderCard';
import LeasingCard from './LeasingCard';
import BadgeWithTooltip from '../../shared/components/BadgeWithTooltip';
import LotActions from './LotActions';
import GalleryTabs from './GalleryTabs';
import IndexSkeleton from './IndexSkeleton';
import Price from '../../shared/components/Price';
import PriceTransportation from '../../shared/components/PriceTransportation';
import FeaturedProductCard from '../../shared/components/FeaturedProductCard';
import { ScSlider } from '../../shared/components/FeaturedProductCard/style';
import SectionTitle from '../../shared/components/SectionTitle';
import CalculatorCard from './Calculator';
import { CardsWrapper, Container, StyledHeaderWithBadge } from './style';
import { ProductSchema } from '../../shared/components/Schema';

const { Title } = Typography;
const { Option } = Select;

const LotPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const lotIdIndex = pathSegments.includes('en') || pathSegments.includes('ru') ? 3 : 2;
  const [lotId, setLotId] = useState<string>(pathSegments[lotIdIndex]);

  useEffect(() => {
    const lotIdIndex = pathSegments.includes('en') || pathSegments.includes('ru') ? 3 : 2;
    setLotId(pathSegments[lotIdIndex]);
  }, [location]);

  useEffect(() => {
    dispatch(getPublicOneLotPageData(+lotId));
    dispatch(getAllPrices());
  }, [dispatch, lotId]);

  const publicOneLotPageData = useSelector(publicOneLotPageDataSelector);
  const publicOneLotPageDataLoading = useSelector(publicOneLotPageDataLoadingSelector);
  const allPrices = useSelector(allPricesSelector);
  const layout = {
    xs: 24,
    md: 24,
    lg: 12,
  };

  useEffect(() => {

    if (publicOneLotPageData) {
      const ogtitle = document.querySelector('meta[property="og:title"]');
      if(ogtitle) { ogtitle.setAttribute('content', publicOneLotPageData.title); }
      const ogdescr = document.querySelector('meta[property="og:description"]');
      if(ogdescr) { ogdescr.setAttribute('content', publicOneLotPageData.criticalDates.primaryDamage +' '+ publicOneLotPageData.criticalDates.secondaryDamage +' '+ publicOneLotPageData.badges.map((item) => `${item.name} ${item.description}`)); }
      const ogimage = document.querySelector('meta[property="og:image"]');
      if(ogimage) { ogimage.setAttribute('content', publicOneLotPageData.mainImage); }
    }
  }, [location.pathname, publicOneLotPageData]);

  const getCount = useCallback(() => {
    const count = publicOneLotPageData?.similarVehicles.length || 0;
    return count < 5 ? count : 5;
  }, [publicOneLotPageData]);

  const handleSetCalculatorCountry = (value: SelectValue) => {
    value && setCalculatorCountry(+value);
  };

  if (publicOneLotPageDataLoading || publicOneLotPageData === null) return <IndexSkeleton />;

  return (
    <>
      <ProductSchema name={publicOneLotPageData?.title} description={publicOneLotPageData.badges.map(item => `${item.name} ${item.description}`).join(', ')} lot={location.pathname.split('/')[lotIdIndex]} image={publicOneLotPageData?.images[0]} url={'https://liontrans.com'+location.pathname} price={publicOneLotPageData?.bidInformation.buyNowPrice} currency={'USD'}/>
      <Container>
      <StyledHeaderWithBadge>
            <Title level={4}>{publicOneLotPageData?.title}</Title>
            {publicOneLotPageData?.badges.map((badge) => (
              <BadgeWithTooltip badge={badge} key={`${badge.name}_${location.pathname.split('/')[lotIdIndex]}`} />
            ))}
            <LotActions lotId={+lotId} description={publicOneLotPageData.title} isAbleBuyNow={publicOneLotPageData?.bidInformation.isAbleBuyNow} />
          </StyledHeaderWithBadge>
          </Container>
      <CardsWrapper>
        {/* <BidInformationCard data={publicOneLotPageData} productId={+location.pathname.split('/')[2]} /> */}
        <LotCard criticalDatesInfo={publicOneLotPageData?.criticalDates} lotNumber={location.pathname.split('/')[lotIdIndex]} />
        <SaleInformationCard
          saleInfo={publicOneLotPageData?.saleInformation}
          isRun={publicOneLotPageData.auctionIsRun}
          ownerMobilePhoneNumber={publicOneLotPageData.ownerMobilePhoneNumber.replace("+", "")}
          data={publicOneLotPageData} 
          productId={+location.pathname.split('/')[lotIdIndex]}
        />
        <FeaturesCard featuresInfo={publicOneLotPageData?.features} />
        <ServiceOrderCard productId={location.pathname.split('/')[lotIdIndex]} />
          
        {/* <CalculatorCard allPrices={allPrices} handleSetCalculatorCountry={handleSetCalculatorCountry} calculatorCountry={calculatorCountry}/> */}

        <LeasingCard lotNumber={location.pathname.split('/')[lotIdIndex]} />

        <Container>

          {publicOneLotPageData?.images.length || publicOneLotPageData?.productDamageImages.length ? (
              <GalleryTabs mainImages={publicOneLotPageData?.images || []} damageImages={publicOneLotPageData?.productDamageImages || []} />
            ) : (
              <Image width={200} src="error" fallback="img/noImg.jpg" alt="No image" />
            )}
        </Container>
      </CardsWrapper>

    </>
  );
};

export default memo(LotPage);
