import { Link } from "react-router-dom";
import styled from "styled-components";

export const CardWrapper = styled(Link)`
    padding: 1rem;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 0px 40px #FBFBFB;
    border-radius: 8px;
    @media (max-width: 767px){
        display: flex;
        flex-wrap: wrap;
        padding: 0.75rem;
    }
`

export const HeaderWrapper = styled.div`
    margin-bottom: 0.75rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 4.5rem;
    span{
        font-family: 'main';
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: #CE2424;
        margin-top: 0.125rem;
        white-space: nowrap;
    }
    
    @media (max-width: 767px){
        flex: 0 0 calc( 100% - 116px);
        order: 1;
        margin: 0;
        align-items: center;
        span{
            display: none;
        }
    }
`

export const TitleWrapper = styled.div`
    h3{
        font-family: 'main';
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: #1B153D;
        margin: 0;
    }
    p{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.5rem;
        color: #9095A6;
        margin: 0;
    }
    @media (max-width: 767px){
        h3{
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 0.875rem;
            color: #1B153D;

        }
        p{
            font-weight: 500;
            font-size: 0.625rem;
            line-height: 0.875rem;
            color: #1B153D;  
        }
    }
`

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    img{
        width: 100%;
    }
    @media (max-width: 767px){
        order: 0;
        width: 6.25rem;
        margin-right: 1rem;
        flex: 0 0 6.25rem;
    }
`

export const ContentWrapper = styled.div`
    ul{
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
    }
    li{
        color: #9095A6;
        font-size: 0.75rem;
        font-family: main;
        font-weight: 400;
        line-height: 0.875rem;
        padding-block: 0.75rem;
        display: flex;
        justify-content: space-between;
        color: #9095A6;
        &:not(:last-child) {
            border-bottom: 1px solid #EAEAEA;
        }
        strong{
            color: #1B153D;
            font-size: 0.75rem;
            font-family: main;
            font-weight: 500;
            line-height: 0.875rem;
        }
    }
    a{
        font-family: 'main';
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.125rem;
        text-align: center;
        color: #FFFFFF;
        padding: 0.75rem 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        background: #CE2424;
        border-radius: 4px;
        margin-top: 2rem;
    }
    & > span{
        display: none;
    }
    @media (max-width: 767px){
        order: 2;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        border-top: 1px solid #EAEAEA;
        padding-top: 0.75rem;
        
        ul{
            display: none;
        }
        a{
            width: fit-content;
            padding: 0.5rem 1rem;
            margin-top: 0;
        }
        & > span{
            flex-shrink: 0;
            display: block;
            font-family: 'main';
            font-weight: 600;
            font-size: 1rem;
            line-height: 0.875rem;
            color: #CE2424;
        }
    }
`

export const InfoWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    height: 4rem;
    margin-top: 1rem;
    margin: 0 0.5rem;
    div{
        font-family: 'main';
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #9095A6;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 0.5rem;
    }
    strong{
        font-family: 'main';
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #1B153D;
    }
    @media(max-width: 1200px){
        strong{
            font-size: 0.75rem;
            line-height: 1rem;
        }
    }
    @media (max-width: 767px){
        display: none;
    }
`