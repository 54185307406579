import React from 'react'
import { addProduct, deleteProduct, userWatchListSelector } from '../store/slices/productSlice';
import { toggleModalAuth, userSelector } from '../store/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const useWatchList = () => {
    const dispatch = useDispatch();

    const userWatchList = useSelector(userWatchListSelector);
    const user = useSelector(userSelector);

    const handleChangeWatchList = (lot: number) => {
        if (user?.id_token) {
            if (userWatchList.includes(lot)) {
                dispatch(deleteProduct(lot));
            } else {
                dispatch(addProduct(lot));
            }
        } else {
            dispatch(toggleModalAuth(true));
        }
    }
      
  return {
    handleChangeWatchList,
    userWatchList
  }
}

export default useWatchList