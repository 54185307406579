import styled from "styled-components";

export const CompanyCardWrapper = styled.div`
    padding: 1rem 1rem 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid #F5F5F5;
    background: #FFF;
    box-shadow: 0px 0px 38px 0px #E8E8E8;
`

export const ImageWrapper = styled.div`
    margin-bottom: 1.125rem;
    img{
        border-radius: 0.5rem;
        overflow: hidden;
        height: 11.5rem;
        width: 100%;
    }
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    h3{
        color: #1B153D;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.375rem;
        margin-bottom: 1rem;
    }
    p{
        color: #6E7281;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.375rem;
        margin-bottom: 2rem;
    }
    a{
        color: #FFF;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.375rem;
        border-radius: 0.5rem;
        background: #CE2424;
        width: 100%;
        padding: 0.675rem 1rem;
        width: 100%;
        text-align: center;
    }
`