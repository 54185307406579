import React, { FC, memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PointConfetti from 'react-confetti';
import { currentLotActualSelector } from '../../../store/slices/auctionSlice';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 200,
    height: 200,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: document.getElementById('image')?.offsetWidth || 200,
        height: document.getElementById('image')?.offsetHeight || 200,
      });
    }
    window.addEventListener('resize', handleResize);
    setTimeout(handleResize, 1000);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

interface Props {
  laneId: number;
}

const Confetti: FC<Props> = ({ laneId }) => {
  const size = useWindowSize();
  const currentLotActual = useSelector(currentLotActualSelector(laneId));

  if (currentLotActual && currentLotActual.status === 'Sold' && currentLotActual.isWinning) {
    return (
      <PointConfetti
        run={true}
        width={size.width}
        height={size.height}
        confettiSource={{
          w: 10,
          h: 10,
          x: size.width / 2,
          y: size.height / 2,
        }}
      />
    );
  }
  return null;
};

export default memo(Confetti);
