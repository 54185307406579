import styled, { css } from 'styled-components';
import { Link } from "react-router-dom";
import { Typography, Collapse, Button, Image, Drawer, Space } from 'antd';
import { CheckSquareFilled } from '@ant-design/icons';
import { White, Grey, ErrorCl, BorderGrey } from '../../shared/shared-styles';
const { Text } = Typography;

export const FindVehiclesWrapper = styled.div`
  display: grid;
  grid-template-columns: 15rem 1fr;
  position: relative;
  @media(max-width: 991px){
    grid-template-columns: 1fr;
  }
`

export const SidebarWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 2px 0px 4px #F1F1F1;
`

export const SearchContentWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  thead{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
  }
`

export const StyledCollapse = styled(Collapse)`
  background: #F7F7F7;
  height: 100%;
  .ant-collapse-header-text{
    display: flex;
    align-items: center;
  }
  .ant-collapse-content-box{
    padding: 1.5rem 1.25rem;
  }
  .ant-collapse-item{
    border: none;
    border-top: 1px solid #EFEEEE;
    .ant-collapse-header{
      padding: 1.25rem;
    }
  }
  .ant-collapse-arrow {
    padding-right: 5px!important;
    }
  @media (max-width: 991px) {
    height: auto;
    padding-bottom: 6rem;
    .ant-collapse-item{
      .ant-collapse-header{
        padding: 0.875rem 1rem;
      } 
    }
  }
`;

export const StyledSpace = styled(Space)`
  gap: 0rem !important;
  svg{
    margin-right: 0.75rem;
  }
  .ant-space-item{
    font-family: 'main';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    max-height: 1rem;
    color: #1B153D;
  }
`

export const ClearBtn = styled(Button)`
  padding-right: 20px;
  color: ${ErrorCl};
`;

export const CenterAlign = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .ant-tag,
  button {
    margin: 0;
  }
`;

export const ScImage = styled(Image)`
  border-radius: 4px;
`;

export const ScDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

export const ActionBtns = styled.div`
  button {
    color: ${Grey};
    border-radius: 4px;
    &:hover,
    :active,
    :focus {
      background: ${White};
      color: ${ErrorCl};
      border-color: ${White};
    }
  }
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button:not(:last-child){
    margin-bottom: 0.5rem;
  }
`


export const PriceCellWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    & > span{
        font-family: 'main';
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: #CE2424;
    }
    & > button{
        font-family: 'main';
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        padding: 0.5rem 1rem;
        color: #FFFFFF;
        background: #CE2424;
        border-radius: 4px;
        height: auto;
        margin-top: 0.5rem;
    }
`

export enum MobileFilterHeaderButtonVariant {
  CLOSE,
  PRIMARY
}

interface MobileFilterHeaderProps {
  buttonVariant?: MobileFilterHeaderButtonVariant
}

export const MobileFilterHeader = styled.div<MobileFilterHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 1rem 0;
  span{
    font-family: 'main';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1B153D;
  }
  button{
    width: 2.5rem;
    height: 2.5rem;
    background: #F0F0F0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    div, svg{
      width: 1rem;
      height: 1rem; 
    }
    ${p => p.buttonVariant == MobileFilterHeaderButtonVariant.CLOSE && css`
      background: none;
      path{
        fill: #1B153D;
        stroke: #1B153D;
      }
      div, svg{
        width: 1.5rem;
        height: 1.5rem; 
      }
    `}
  }
`

export const MobileFilterWrapper = styled.div`
  position: absolute;
  height: calc( 100dvh - 4rem);
  padding-bottom: 1rem;
  background: #fff;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  overflow-y: auto;
`

export const MobileSearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;
  padding: 0 1rem;
`

export const PaginationWrapper = styled.div`
  margin-top: 1.5rem;
`

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  .ant-picker {
    border-radius: 4px;
  }
`

export const YearFilterWrapper = styled.div`
  display: flex;
`

export const YearFilterItem = styled.div`
  flex: 1;
  margin-right: 0px;
  width: 50%;
  &:first-child {
    margin-right: 10px;
  }
`