import React, { FC, useState, useEffect } from 'react'
import FileUploader from './FileUploader'
import { UploadFilesList } from './FileUploader/style'
import UploadFile, { FileType } from './FileUploader/UploadFile'
import { UploadFilesWrapper } from './style'
import { Form } from 'antd';
import { AddVehicleItemToSend } from '../../interfaces/Product'

const type = 'DragableUploadList';

interface Props {
  saveFormData: (step: string, values: any) => void;
  data: {
    images: any;
  };
  form: any
}

const UploadFiles: FC<Props> = ({ form, saveFormData, data }) => {
    const [uploadedFiles, setUploadedFiles] = useState<FileType[]>([])

    const onUpload = async (file: File) => {
        const base64Img = await getBase64(file);
        if(typeof base64Img == "string"){
            const newFile = {
                Id: `upload-${new Date().getTime()}`,
                OriginalName: file.name,
                FileName: file.name,
                size: file.size,
                Type: file.type,
                Url : base64Img,
            };

            setUploadedFiles((prevArr) => [...prevArr, newFile] )
        }
    }
    


    const onRemove = (id: string) => {
        setUploadedFiles((prevArr) => {
            return prevArr.filter(el => el.Id != id)
        })
    }

    const makeMainImage = (id: string) => {
        setUploadedFiles((prevArr) => {
            return [...prevArr].sort((x: FileType) => x.Id == id ? -1 : 0 )
        })
    }

    function getBase64(img: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
            resolve(reader.result);
            });
            reader.readAsDataURL(img);
        });
    }

    useEffect(() => {
        saveFormData('images', {
            images: [...uploadedFiles],
        });
    }, [uploadedFiles])
  return (
    <UploadFilesWrapper>
        <FileUploader onUpload={onUpload}/>
        {uploadedFiles.length > 0 && <UploadFilesList>
            {uploadedFiles.map((file,index) => (
                <UploadFile key={index} file={file} onRemove={onRemove} makeMainImage={makeMainImage}/>
            ))}
        </UploadFilesList>}
    </UploadFilesWrapper>
  )
}

export default UploadFiles