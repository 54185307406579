/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo } from 'react';
import { TablePaginationConfig } from 'antd';
import { useSelector } from 'react-redux';
import { bidsByStatusListSelector, bidsByStatusListTotalCountSelector, bidsByStatusListIsLoadingSelector } from '../../../store/slices/bidsSlice';
import CustomTable from '../../../shared/components/CustomTable';

interface Props {
  tableChange: (pg: TablePaginationConfig, fltr: any, sort: any) => void;
  columns: any[];
}
const BidsPage: FC<Props> = ({ tableChange, columns }) => {
  const bidsByStatusList = useSelector(bidsByStatusListSelector);
  const bidsByStatusListTotalCount = useSelector(bidsByStatusListTotalCountSelector);
  const bidsByStatusListIsLoading = useSelector(bidsByStatusListIsLoadingSelector);

  return (
    <CustomTable
      data={bidsByStatusList.map((item) => ({ ...item, key: item.productId }))}
      columns={columns}
      totalCount={bidsByStatusListTotalCount}
      isLoading={bidsByStatusListIsLoading}
      onChangeTable={tableChange}
      scroll={{ x: 'max-content' }}
    />
  );
};

export default memo(BidsPage);
