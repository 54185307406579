import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';
import QRCode from 'qrcode.react';
const AppPage: FC = () => {

  const canonicalTag = document.querySelector('link[rel="canonical"]');
  if (canonicalTag) {
    canonicalTag.parentNode?.removeChild(canonicalTag);
  }

  const metaTag = document.querySelector('meta[name="robots"]');
  if (metaTag) {
    metaTag.setAttribute('content', 'follow, noindex, max-snippet:-1, max-video-preview:-1, max-image-preview:large');
  }
  const timestamp = new Date().getTime();
  const downloadLink = `https://liontrans.com/Dealer%20Tools%20release-1.1.8.apk?timestamp=${timestamp}`;

  return (
    <div className="application">
      <div className="app-header">
        <h1>Dealer Tools Application</h1>
        <div className="appimages">
          <img src="img/lionauction-logo.webp" />
          <QRCode value={downloadLink} />
        </div>
        <a className="download-button" href={downloadLink} rel="nofollow" download>
          <img src="img/download_apk.png" alt="" />
        </a>
      </div>
      <div className='appscreen'>
        <img src="img/mobile.png"></img>
      </div>
    </div>
  );
};

export default memo(AppPage);
