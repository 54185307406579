import React, { useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import { MobileFilterWrapper } from '../style'
import MobileFilterHeader from '../../../shared/components/MobileFilterHeader'

interface MobileFilterProps {
    children: JSX.Element,
    onClose: () => void
}

const MobileFilter = ({children, onClose}: MobileFilterProps) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflow = "hidden"

        return () => {
            document.body.style.overflow = "initial"
        }
    }, [])

  return (
    <MobileFilterWrapper>
      <MobileFilterHeader
        title='Filter'
        onButtonHandle={onClose}
        icon={<ReactSVG src="/icons/close.svg"/>}
      />
    {children}
  </MobileFilterWrapper>
  )
}

export default MobileFilter