import styled, { css } from "styled-components";

interface FileUploaderWrapperProps{
    isDragged: boolean;
}

export const FileUploaderWrapper = styled.div<FileUploaderWrapperProps>`
    background: #F9FAFA;
    border: 1px dashed #BCC1C2;
    border-radius: 6px;
    width: 100%;
    min-height: 238px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    position: relative;
    transition: 0.2s;
    input[type="file"]{
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
    }
    ${p => p.isDragged && css`
        background-color: #d4dddd;
    `}
`

export const FileUploaderContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const FileUploaderIcon = styled.div`
    margin-bottom: 1rem;
`

export const FileUploaderTitle = styled.p`
    font-family: 'main';
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    color: #15181A;
`

export const FileUploaderSubtitle = styled.p`
    font-family: 'main';
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
    color: #A6ABAD;
`

export const FileUploaderButton = styled.button`
    font-family: 'main';
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 1rem;
    color: #566063;
    padding: 0.5rem 1.25rem;
    border: 1px solid #BCC1C2;
    border-radius: 6px;
`

export const UploadFilesList = styled.div`
    margin-left: 3.5rem;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.5rem 3.125rem;
    @media (max-width: 991px){
        margin-left: 1rem;
    }
    @media (max-width: 767px){
        grid-template-columns: repeat(1,1fr);
        gap: 1rem;
    }
`

export const UploadFileWrapper = styled.div`
    padding: 1rem 1rem 1rem 1.25rem;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 0px 2.5rem #FBFBFB;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    position: relative;
    transition: 0.2s;
    cursor: pointer;
    .close-btn{
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        transform: translate(50%, -50%);
        width: 1.5rem;
        height: 1.5rem;
        background: #CE2424;
        border-radius: 50%;
        svg{
            width: 0.5rem;
            height: 0.5rem;
        }
        path{
            stroke: #fff;
            fill: #fff;
            opacity: 1;
        }
    }
    &:hover{
        background: #FFFFFF;
        border: 1px solid #F5F5F5;
        box-shadow: 0px 0px 50px #DFDFDF;
        border-radius: 8px;
        .close-btn{
            display: block;
        }
    }
`

export const UploadFileIcon = styled.div`
    width: 1.875rem;
    height: 1.875rem;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

export const UploadFileTitle = styled.span`
    font-family: 'main';
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #1B153D;
    margin-left: 1rem;
`

export const UploadFileSize = styled.span`
    display: flex;
    margin-left: auto;
    font-family: 'main';
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #A6ABAD;
`