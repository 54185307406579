import { Col, Row } from 'antd';
import React, { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { getSellerOneLotPageData, sellerOneLotPageDataSelector } from '../../store/slices/productSlice';
import LotDisplay from './containers/LotDisplay';
import LotImageGallery from './containers/LotImageGallery';
import MenuContainer from './containers/MenuContainer';

const RowWithMargin = styled(Row)`
  margin-top: 30px;
`;

const MyLotPage: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getSellerOneLotPageData(+location.pathname.split('/')[2]));
  }, [dispatch, location]);

  const sellerOneLotPageData = useSelector(sellerOneLotPageDataSelector);

  if (!sellerOneLotPageData) return null;

  return (
    <RowWithMargin justify="center" gutter={[20, 20]}>
      <Col xs={8}>
        <LotImageGallery
          images={sellerOneLotPageData.images}
          productDamageImages={sellerOneLotPageData.productDamageImages}
          title={sellerOneLotPageData.vehicleInformation.description}
        />
      </Col>
      <Col xs={8}>
        <LotDisplay lotInfo={sellerOneLotPageData.mainLotInfo} />
      </Col>
      <Col xs={8}>
        <MenuContainer sellerOneLotPageData={sellerOneLotPageData} />
      </Col>
    </RowWithMargin>
  );
};

export default memo(MyLotPage);
