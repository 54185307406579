import React, { FC, memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { compareItemsSelector, getCompareList, compareIdsSelector } from '../../../store/slices/compareSlice';
import { CompareCardList, CompareContainer, CompareContainerHeader, FullWidthContainer } from '../style';
import { Vehicle } from '../../../interfaces/FindVehicles';
import CardsContainer from './CardsContainer';
import TableContainer from './TableContainer';
import { checkIsLive, getOdoValue } from '../../../utils/functions';
import { ScCountdown } from '../../LotPage/SaleInformationCard';
import Price from '../../../shared/components/Price';
import { odometrIsKmSelector } from '../../../store/slices/initSlice';
import CompareCard from '../components/CompareCar';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';

const CompareResultContainer: FC = () => {
  const dispatch = useDispatch();

  const compareItems = useSelector(compareItemsSelector);
  const compareIds = useSelector(compareIdsSelector);
  const odometrIsKm = useSelector(odometrIsKmSelector);

  const [isAll, setIsShowAll] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    compareIds.length && !compareItems.length && dispatch(getCompareList());
  }, [compareIds, compareItems, dispatch]);

  useEffect(() => {
    dispatch(getCompareList());
  }, [compareIds, dispatch]);

  const getTimeLeftInfo = (item: Vehicle) => {
    if (!item.laneItemGridRow) return 'Futured';
    if (checkIsLive(item.saleDate)) return 'Live';
    return <ScCountdown title="" value={item.saleDate} format="DD[D] HH[H] mm[M] ss[S]" onFinish={() => {}} />;
  };
  const handleHideEqual = (val: boolean) => {
    setIsShowAll(val);
  };

  return (
    <CompareContainer>
      <CompareContainerHeader>
        <h2>{t("compare_realtime_text")}</h2>
        {/* <Switch checkedChildren="All" unCheckedChildren="Diff" defaultChecked onChange={handleHideEqual} /> */}
      </CompareContainerHeader>
      <CompareCardList>
        {compareItems.map((item) => (
          <CompareCard key={item.id} item={item}/>
        ))}
      </CompareCardList>
      {/* <CardsContainer vehicles={compareItems} onHideEqual={handleHideEqual} /> */}
      {/* <TableContainer
        icon="img/2.png"
        title="Lot information"
        isAll={isAll}
        fields={[
          ['Customs', ...compareItems.map((item) => (item.clearancePassed ? 'Cleared' : 'Before'))],
          ['Odometr', ...compareItems.map((item) => getOdoValue(item.odometer, odometrIsKm, item.odometerBrand))],
          ['Highlights', ...compareItems.map((item) => '')],
          ['Primary Damage', ...compareItems.map((item) => item.damage)],
          ['Secondary Damage', ...compareItems.map((item) => '')],
          ['VIN', ...compareItems.map((item) => item.vin)],
        ]}
      />
      <TableContainer
        icon="img/2.png"
        title="Bid information"
        isAll={isAll}
        fields={[
          [' Bid Status', ...compareItems.map((item) => '')],
          ['Current Bid', ...compareItems.map((item) => <Price key={item.id} price={item.currentBid} />)],
          ['Sale Status', ...compareItems.map((item) => '')],
        ]}
      />
      <TableContainer
        icon="img/2.png"
        title="Features"
        isAll={isAll}
        fields={[
          ['Body Style', ...compareItems.map((item) => item.bodyStyle)],
          ['Color', ...compareItems.map((item) => item.color)],
          ['Keys', ...compareItems.map((item) => '')],
          ['Transmission', ...compareItems.map((item) => item.transmission)],
          ['Fuel', ...compareItems.map((item) => item.fuelType)],
          ['Cylinder', ...compareItems.map((item) => item.cylinder)],
          ['Drive', ...compareItems.map((item) => item.driveTrain)],
          ['Engine Type', ...compareItems.map((item) => item.engineType)],
          ['Notes', ...compareItems.map((item) => '')],
        ]}
      />
      <TableContainer
        icon="img/2.png"
        title="Sale information"
        isAll={isAll}
        fields={[
          ['Sale name', ...compareItems.map((item) => '')],
          ['Lane', ...compareItems.map((item) => item.auctionLaneId)],
          ['Location', ...compareItems.map((item) => item.location || '')],
          ['Time Left', ...compareItems.map((item) => getTimeLeftInfo(item))],
          ['Sale date', ...compareItems.map((item) => (item.saleDate ? dayjs(item.saleDate).format('DD/MM/YYYY') : 'Futured'))],
          ['Futured', ...compareItems.map((item) => item.engineType)],
        ]}
      /> */}
    </CompareContainer>
  );
};

export default memo(CompareResultContainer);
