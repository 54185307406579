import styled, { css } from 'styled-components';
import { Link } from "react-router-dom";
import { Form, Typography } from 'antd';

export const ForgotPassowrd = styled.div`
  padding: 80px 0px;
`

export const FormItem = styled(Form.Item)`
  margin-left: 10px;
`