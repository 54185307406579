import React, {useEffect, useState} from 'react'
import { ElectricCarsList, ElectricCarsWrapper } from './style'
import ElectricCarsCard from './ElectricCarsCard'
import { useCookies } from 'react-cookie'
import { NewCarsCardLoadingSkeleton } from '../HomePage/NewCarsCard'

const getGalleryCars = async (lang: string) => {
  const galleryCarIds = Array.from({length: 15}, (_, i) => i + 1)
  const promises: any = []
  galleryCarIds.forEach(id => {
    const promise = fetch(`/electric-cars/${lang}/${id}.json`)
      .then(response => {
        if (!response.ok) {
          return null;
        }
        return response.json();
      })
      .catch(error => {
        return null;
      });
    promises.push(promise);
  })

  const results = await Promise.all(promises);
  // Filter out null results
  return results.filter(result => result !== null);
}


const ElectricCarsSearch = () => {
  const [vehicles, setVehicles] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [cookies] = useCookies(['language']);

  useEffect(() => {
    setIsLoading(true)
    getGalleryCars(cookies.language).then(res => {
      setVehicles(res)
      setIsLoading(false)
    })
  }, [cookies.language])
  

  return (
    <ElectricCarsWrapper>
        {isLoading ? <ElectricCarsList>
          {Array(4).fill("").map((_,index) => (
            <NewCarsCardLoadingSkeleton key={index}/>
          ))}
        </ElectricCarsList>
        : <ElectricCarsList>
          {vehicles.map( vehicle => (
            <ElectricCarsCard vehicle={vehicle} />
          ))}
        </ElectricCarsList>}
    </ElectricCarsWrapper>
  )
}

export default ElectricCarsSearch