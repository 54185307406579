import React, {MouseEvent} from 'react'
import { SearchCardContent, SearchCardFooter, SearchCardImage, SearchCardWrapper, SearchContentBody } from './style'
import { HeartOutlined, SyncOutlined } from '@ant-design/icons'
import { Vehicle } from '../../../interfaces/FindVehicles'
import { getLinkForLotPage, getLocation, getOdoValue } from '../../../utils/functions'
import { odometrIsKmSelector } from '../../../store/slices/initSlice'
import BadgeWithTooltip from '../../../shared/components/BadgeWithTooltip'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ComperaButton from '../AdvanceSearch/ComperaButton'
import WatchlistButton from '../AdvanceSearch/WatchlistButton'

interface VehicleSearchCardProps {
    vehicle: Vehicle
}

export const VehicleSearchCardSkeleton = () => {
    return (
        <SearchCardWrapper>
            <SearchContentBody as={"div"}>
                <SearchCardImage isLoading={true}/>
                <SearchCardContent isLoading={true} />
            </SearchContentBody>
        </SearchCardWrapper>
    )
}

const VehicleSearchCard = ({vehicle}: VehicleSearchCardProps) => {
    const { name, locationType } = getLocation(vehicle.locationId, vehicle);
    const odometrIsKm = useSelector(odometrIsKmSelector);

    const odometerValue = getOdoValue(vehicle.odometer, odometrIsKm, vehicle.odometerBrand).split(" ")[0]

    const { t } = useTranslation()
  return (
    <SearchCardWrapper>
        <SearchContentBody to={getLinkForLotPage(
        vehicle.lotNumber,
        vehicle.make,
        vehicle.model,
        vehicle.year,
      )}>
            <SearchCardImage>
                <span>#{vehicle.id}</span>
                <img src={'https://image.liontrans.com/insecure/w:322/h:242/f:webp/plain/'+vehicle.mainImage} alt="" />
            </SearchCardImage>
            <SearchCardContent>
                <h3>{vehicle.make} {vehicle.model} {vehicle.year}</h3>
                <h4>{name} {locationType}</h4>
                <p>{odometerValue} ({odometrIsKm ? "Km" : "Mi"})</p>
                {vehicle.badges.map((badge) => (
                    <BadgeWithTooltip badge={badge} key={`${badge.name}_${vehicle.lotId}`} className="fifth-search-step" />
                ))}
            </SearchCardContent>
        </SearchContentBody>
        <SearchCardFooter>
            <ul>
                <li>
                    {t("buy_now")}:
                    <span>{vehicle.buyNowPrice}</span>
                </li>
            </ul>
            <div>
                <ComperaButton onClick={(e: MouseEvent<HTMLElement>) =>  e.preventDefault()} id={vehicle.id} />

                <WatchlistButton onClick={(e: MouseEvent<HTMLElement>) =>  e.preventDefault()} id={+vehicle.lotNumber} />
            </div>
        </SearchCardFooter>
    </SearchCardWrapper>
  )
}

export default VehicleSearchCard