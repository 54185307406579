/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import { AppThunk, RootState } from '../store';
import userManager from '../../utils/userManager';
import history from '../../core/history';
import { showNotification } from '../../utils/notify';
import { axiosInstanceIdentity } from '../../core/axios-identity';
import api from '../../core/api';
import { Auth, RegisterData } from '../../interfaces/User';
import { clearUserWatchList } from './productSlice';
import { axiosInstance } from '../../core/axios';
import i18n from '../../i18n';
import { langPrefix } from '../../utils/functions';

export interface InitState {
  userLanguage: string;
  isAuthenticated: boolean;
  checkAuthError: string;
  user: Auth | null;
  openModalAuth: boolean;
  userRole: string;
}

export const initialState: InitState = {
  userLanguage: '',
  isAuthenticated: false,
  checkAuthError: '',
  user: null,
  openModalAuth: false,
  userRole: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserLanguage: (state, action) => {
      state.userLanguage = action.payload;
    },
    checkAuthRequest: (state) => {},
    checkAuthSuccess: (state) => {
      state.isAuthenticated = true;
    },
    checkAuthFailure: (state, action) => {
      state.checkAuthError = action.payload;
      state.isAuthenticated = false;
    },
    userAuthSuccess: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setOpenModalAuth: (state, action) => {
      state.openModalAuth = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
  },
});

export const { setUserLanguage, checkAuthRequest, checkAuthSuccess, checkAuthFailure, userAuthSuccess, setOpenModalAuth, setUserRole } = authSlice.actions;

export const getUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setUserRole(localStorage.getItem('userType')));
    const result = await userManager.getUser();
    if (result?.expires_at && result?.expires_at < Math.trunc(new Date().getTime() / 1000)) {
      dispatch(logOut());
    } else dispatch(userAuthSuccess(result));
  } catch (error: any) {
    dispatch(clearUserWatchList());
    showNotification('error', error.response.data.errorMessage);
  }
};

export const checkAuth = (): AppThunk => async (dispatch, state) => {
  dispatch(checkAuthRequest());
  const isToken = !!localStorage.getItem('lionAutoToken');
  isToken ? dispatch(checkAuthSuccess()) : dispatch(checkAuthFailure('Unauthorized'));
};

export const signinRedirectCallback = (): AppThunk => async (dispatch) => {
  try {
    const currentLocation = localStorage.getItem('authFrom');
    const needRedirect = localStorage.getItem('needRedirect');
    await userManager
      .signinRedirectCallback()
      .then((result) => {
        if (result.profile) {
          let lang = 'en';
          if (result.profile.Language) {
            if (result.profile.Language === 'Russian') {
              lang = 'ru';
            } else if (result.profile.Language === 'Georgian') {
              lang = 'ka';
            }
            // dispatch(setUserLanguage(lang));
            // const cookies = new Cookies();
            // cookies.set('language', lang);
          }

          dispatch(userAuthSuccess(result));
          dispatch(setUserRole('buyer'));
          localStorage.setItem('userType', 'buyer');
          localStorage.setItem('lionAutoToken', result.access_token);
          if(needRedirect == "true")
          {
            history.push('/accountsettings');
          }
          else {
            history.push(currentLocation || '/');
          }
        } else {
          history.push('/');
          showNotification('error', 'User not logged in');
          console.log("User not logged in #1");
        }
      })
      .catch((e) => {
        history.push('/');
        showNotification('error', 'User not logged in');
        console.log("User not logged in #2", e);
      });
  } catch (error: any) {
    history.push('/');
    showNotification('error', 'User not logged in');
    console.log("User not logged in #3");
  }
};

export const changeRole = (role: string): AppThunk => async (dispatch) => {
  dispatch(setUserRole(role));
};

export const toggleModalAuth = (val: boolean): AppThunk => async (dispatch) => {
  dispatch(setOpenModalAuth(val));
};

export const logOut = (): AppThunk => async (dispatch, state) => {
  try {
    await userManager.signoutRedirect({
      id_token_hint: state().auth.user?.id_token,
    });
    const isVincodeSearchAutorized = localStorage.getItem('isVincodeSearchAutorized') || 'false';
    localStorage.clear();
    localStorage.setItem('isVincodeSearchAutorized', isVincodeSearchAutorized);

    userManager
      .signoutRedirectCallback()
      .then(() => {
        userManager
          .removeUser()
          .then(() => {
            history.push(langPrefix('/'));
          })
          .catch((e) => {
            history.push(langPrefix('/'));
          });
      })
      .catch((e) => {
        history.push(langPrefix('/'));
      });
  } catch (error: any) {
    showNotification('error', error.response.data.errorMessage);
  }
};

export const register = (values: RegisterData): AppThunk => async (dispatch) => {
  try {
    const res = await axiosInstanceIdentity.post(api.Register, {
      ...values,
    });
    showNotification('success', i18n.t(res.data.successMessage));
    setTimeout(() => {
      window.location.href = langPrefix('/');
    }, 2000);
  } catch (error: any) {
    showNotification('error', i18n.t(error.response.data['errorMessage']));

    // const errors = Object.keys(error.response.data);

    // errors.forEach((element) => {
    //   showNotification('error', i18n.t(error.response.data[element]));
    // });
  }
};

export const sendEmail =  (subject: string, body: string, toEmail?: string, isHtml = false): AppThunk => async (dispatch) => {
  try{
    const res = await axiosInstance.post("Email/SendEmail", {
      "subject": subject,
      "body": body,
      "toEmail": toEmail,
      "isHtml": isHtml
    })
  } catch (err){
    console.error(err)
  }
}

// SELECTORS
export const isAuthenticatedSelector = (state: RootState) => state.auth.isAuthenticated;

export const userSelector = (state: RootState) => state.auth.user;

export const openModalAuthSelector = (state: RootState) => state.auth.openModalAuth;

export const userRoleSelector = (state: RootState) => state.auth.userRole;

export const userLanguageSelector = (state: RootState) => state.auth.userLanguage;

export const checkingUserRoleSelector = (state: RootState) => state.auth.user?.profile?.Roles?.split(', ');

export default authSlice.reducer;
