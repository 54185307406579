import React from 'react'
import { BannerWrapper } from './style'

const Banner = () => {
  return (
    <BannerWrapper>
        <img src="/img/banners/services.webp" alt="service page banner" />
    </BannerWrapper>
  )
}

export default Banner