import styled, { css } from 'styled-components';
import { Row, Form, Button, Select, TreeSelect, Slider, Col, Typography, Tabs, Spin } from 'antd';
import GallerySlider from 'react-slick';
import {
  Accent,
  ErrorCl,
  White,
  Grey,
  Primary,
  Yellow,
  LightBlue,
  DarkShadow,
  LightGrey,
  LightGreyBackground,
  firstSliderCl,
  thirdSliderCl,
  LightShadow,
  Shadow,
} from '../../shared-styles';

const { Title } = Typography;

export const GalleryBannerSlider = styled(GallerySlider)<{ loaded: boolean }>`
  height: 100%;
  @media (max-width: 992px) {
    padding-bottom: 10px;
  }
  .slick-list {
    height: inherit;
    .slick-track {
      height: inherit;
      .slick-slide {
        div {
          height: inherit;
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
    background: ${Shadow};
    font-size: 16px;
    width: 40px;
    height: 40px;
    color: ${White};
    padding: 8px;
    @media (min-width: 992px) {
      width: 80px;
      height: 80px;
      font-size: 30px;
      padding: 25px;
    }
    &:before {
      content: '';
    }
  }
  .slick-prev {
    left: 0;
    border-right: 6px solid ${Yellow};
    border-radius: 0px 2px 2px 0px;
  }
  .slick-next {
    right: 0;
    border-left: 6px solid ${Yellow};
    border-radius: 2px 0px 0px 2px;
  }
  .slick-dots {
    bottom: 10px;
    @media (min-width: 992px) {
      bottom: 30px;
    }
    li {
      margin: 0 6px;
      button {
        background: ${Shadow};
        width: 10px;
        height: 10px;
        @media (min-width: 992px) {
          width: 30px;
          height: 30px;
        }
        :before {
          content: none;
        }
      }
    }
    .slick-active {
      button {
        background: ${White};
        border: 1px solid ${Accent} !important;
      }
    }
  }
`;

export const StyledPaging = styled.button`
  border: 1px solid ${Grey} !important;
`;

export const StyledMainContainer = styled.div`
  padding: 0 50px;
  min-height: 600px;
  position: relative;
  z-index: 1;
  &:before,
  &:after {
    position: absolute;
    left: 0;
    min-height: 560px;
    top: 0;
    content: '';
    z-index: -1;
    width: 100%;
    background: ${ErrorCl};
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  }
  &:after {
    min-height: 480px;
    background: ${Primary};
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  }
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
  @media (max-width: 320px) {
    padding: 4px;
  }
`;

export const StyledBanner = styled(Row)`
  box-shadow: 0 10px 30px 0 ${LightShadow};
  min-height: 730px;
  .skeletonForm {
    padding: 40px;
    width: 100%;
  }
  .ant-skeleton {
    width: 100%;
    .ant-skeleton-image {
      width: inherit;
      min-height: 700px;
    }
  }
`;

export const ScFormWrapper = styled(Col)`
  background: ${White};
  border-radius: 5px;
`;

export const Wrapper360 = styled(Row)`
  cursor: pointer;
`;

export const StyledFormWrapper = styled.div`
  padding: 30px 30px 0;
  @media (max-width: 576px) {
    padding: 10px 10px 0;
  }
`;

export const SaveSearchWrapper = styled.div`
  font-weight: 500;
  color: ${Primary};
  background-color: ${LightGreyBackground};
  padding: 30px 30px;
  margin-top: 10px;
  @media (max-width: 576px) {
    padding: 10px 10px 0;
  }
`;

export const ScCol = styled(Col)`
  @media (min-width: 992px) {
    padding-left: 24px;
  }
  .ant-carousel {
    height: 100%;
    .slick-slider {
      height: 100%;
      .slick-dots {
        margin: 0;
      }
      .slick-list {
        height: 100%;
        .slick-track {
          height: 100%;
          .slick-slide {
            height: 100%;
            div {
              height: 100%;
            }
          }
        }
      }
    }
  }
`;

export const CarouselImg = styled.img`
  width: 100%;
  height: 100%;
  object-position: right bottom;
  object-fit: cover;
`;

export const ScImage = styled.div<{ loaded: boolean }>`
  height: 100%;
  img {
    margin: auto;
  }
`;

export const StyledForm = styled(Form)`
  .ant-form-item-label {
    text-align: left;
    label {
      font-weight: 700;
      color: ${Primary};
    }
  }
`;

export const PopularVehicleBtn = styled(Button)`
  margin: 8px 2px 8px 2px;
  width: 45px;
  ${({ selected }) => selected && `
    border-color: var(--primary);
  `}
`;

export const ScSelect = styled(Select)`
  .ant-select-selector {
    height: 50px !important;
    color: ${Grey} !important;
    border-radius: 4px !important;
  }
  .ant-select-selection-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ant-select-selection-item {
    align-self: center;
  }
  .ant-select-selection-placeholder {
    align-self: center;
  }
`;

export const ScTreeSelect = styled(TreeSelect)`
  width: 100%;
  .ant-select-selector {
    height: 50px !important;
    color: ${Grey} !important;
    border-radius: 4px !important;
  }
`;

export const ScFormItem = styled(Form.Item)`
  align-items: center;
  margin: 0;
  padding: 5px 0;
`;

export const ScFormItemOdo = styled(Form.Item)`
  align-items: center;
`;

export const FormItemBtns = styled.div`
  text-align: end;
  @media (max-width: 576px) {
    text-align: center;
  }
`;

export const SearchBtn = styled(Button)`
  height: 50px;
  text-transform: uppercase;
  color: ${Primary};
  font-weight: 700;
  margin-left: 30px;
  min-width: 160px;
  cursor: pointer;
  @media (max-width: 576px) {
    text-align: center;
    min-width: 140px;
    margin-left: 10px;
  }
`;

export const AdvancedBtn = styled(Button)`
  height: 50px;
  text-transform: uppercase;
  color: ${Grey};
  font-weight: 700;
  cursor: pointer;
  min-width: 160px;
  color: ${Grey};
  background: ${LightGrey};
  border-color: transparent;
`;

export const SavedsSearchBtn = styled(Button)`
  color: ${LightBlue};
  padding: 0;
  outline: none;
  margin-left: 5px;
`;

export const ScSlider = styled(Slider)`
  margin-bottom: 40px;
  .ant-tooltip-inner {
    background-color: transparent;
    color: ${Primary};
    box-shadow: none;
    padding: 0;
    &:after {
      content: ' mi';
    }
    @media (max-width: 576px) {
      padding: 0px 20px;
    }
  }
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-slider-handle {
    width: 20px;
    height: 20px;
    top: 20px;
    border-radius: 0 50% 50% 50%;
    transform: rotate(45deg) !important;
    margin-left: -10px;
  }
  .ant-slider-track {
    clip-path: ${(p: { currentClip: string }) => p.currentClip};
    background: linear-gradient(to right, ${firstSliderCl} 0%, ${Accent} 50%, ${thirdSliderCl} 100%);
    height: 20px;
  }
  .ant-tooltip-placement-bottom {
    padding: 0;
  }
  div:nth-child(7) > div > div {
    width: 100px!important;
  }
  div:nth-child(8) {
    .ant-tooltip {
      margin-left: -15px;
      ${(props) =>
    Array.isArray(props.value) &&
        props.value[1] - props.value[0] < 100000 &&
        css`
          top: -35px !important;
          height: 33px;
          padding: 5px;
          background-color: ${DarkShadow};
          border-radius: 4px;
          .ant-tooltip-inner {
            color: ${White};
          }
          .ant-tooltip-arrow {
            display: block;
            transform: rotate(180deg);
            top: 33px;
            left: 37%;
            .ant-tooltip-arrow-content {
              width: 10px;
              height: 10px;
            }
          }
        `}
    }
  }
  .ant-slider-rail {
    height: 20px;
    clip-path: polygon(0 95%, 100% 0, 100% 100%, 0 100%);
  }
`;

export const TitleSecond = styled.span`
  font-size: 16px;
`;

export const ScSpin = styled(Spin)`
  height: 100%;
  min-height: 730px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleMain = styled(Title)`
  display: grid;
  padding: 5px 10px;
  border-left: 2px solid ${Accent};
  margin: 0 0 10px !important;
`;

export const StyledTabs = styled(Tabs)<{ countitems: number }>`
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list {
    width: 90%;
    .ant-tabs-tab {
      width: ${(props) => `calc(100% / ${props.countitems})`};
      justify-content: center;
    }
  }
  .ant-tabs-nav-list > * {
    padding: 16px 10px !important;
    margin: 0 !important;
    @media (max-width: 576px) {
      padding: 10px 10px !important;
    }
    .ant-tabs-tab-btn {
      color: ${Grey} !important;
      font-weight: bold;
      font-size: 16px;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }
  .ant-tabs-nav-list > .ant-tabs-tab-active > .ant-tabs-tab-btn {
    color: ${Primary} !important;
  }
`;
