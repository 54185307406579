import { Spin, Tabs } from 'antd';
import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-image-gallery/styles/css/image-gallery.css';
import Icon from '@iconify/react';
import baselinePhotoCamera from '@iconify-icons/ic/baseline-photo-camera';
import roundWarning from '@iconify-icons/ic/round-warning';
import StyledImageGallery from '../../shared/components/StyledImageGallery';
import { GalleryLoading } from './style';

const { TabPane } = Tabs;

interface Props {
  mainImages: string[];
  damageImages: {
    id: number;
    url: string;
  }[];
}

const GalleryTabs: FC<Props> = ({ mainImages, damageImages }) => {
  const { t } = useTranslation();

  const getImagesForGallery = useCallback(
    (type: string) => {
      if (type === 'images') {
        const images = mainImages.map((item) => ({
          original: 'https://image.liontrans.com/insecure/w:1600/h:1200/f:webp/plain/'+item,
          thumbnail: 'https://image.liontrans.com/insecure/w:300/h:250/f:webp/plain/'+item,
        }));
        return images || [];
      }
      const damages = damageImages.map((item) => ({
        original: item.url,
        thumbnail: item.url,
      }));
      return damages || [];
    },
    [mainImages, damageImages],
  );

  const [changeTab, setchangeTab] = useState(false);

  const handleChangeTab = () => {
    setTimeout(() => setchangeTab(true), 500);
  };

  return (
    <Tabs tabPosition="bottom" onChange={handleChangeTab}>
      {!!mainImages.length && (
        <TabPane
          tab={
            <>
              {damageImages.length > 0 && (
                <>
                  <Icon inline icon={baselinePhotoCamera} /> {t('lot_photos_basic')}
                </>
              )}
            </>
          }
          key="1"
        >
          <StyledImageGallery items={getImagesForGallery('images')} />
        </TabPane>
      )}
      {!!damageImages.length && (
        <TabPane
          tab={
            <>
              <Icon inline icon={roundWarning} /> {t('lot_photos_damager')}
            </>
          }
          key="2"
        >
          {!changeTab && (
            <GalleryLoading>
              <Spin size="large" />
              <img src="img/noImg.jpg" alt="No data" width="100%" />
            </GalleryLoading>
          )}
          <StyledImageGallery items={getImagesForGallery('damages')} />
        </TabPane>
      )}
    </Tabs>
  );
};

export default memo(GalleryTabs);
