/* eslint-disable @typescript-eslint/indent */
import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { loadUser, reducer as oidcReducer } from 'redux-oidc';
import initReducer from './slices/initSlice';
import blogReducer from './slices/blogSlice';
import findVehiclesReducer from './slices/findVehiclesSlice';
import faqReducer from './slices/faqSlice';
import productReducer from './slices/productSlice';
import auctionReducer from './slices/auctionSlice';
import dashboardReducer from './slices/dashboardSlice';
import authReducer from './slices/authSlice';
import userManager from '../utils/userManager';
import profileReducer from './slices/profileSlice';
import dictionaryReducer from './slices/dictionarySlice';
import paymentsReducer from './slices/paymentsSlice';
import bidsReducer from './slices/bidsSlice';
import compareReducer from './slices/compareSlice';
import notifyReducer from './slices/notifySlice';
import lotReducer from './slices/lotSlice';
import dealerReducer from './slices/dealerSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    init: initReducer,
    blog: blogReducer,
    findVehicles: findVehiclesReducer,
    faq: faqReducer,
    product: productReducer,
    auction: auctionReducer,
    dashboard: dashboardReducer,
    oidc: oidcReducer,
    profile: profileReducer,
    dictionary: dictionaryReducer,
    payments: paymentsReducer,
    bids: bidsReducer,
    compare: compareReducer,
    notify: notifyReducer,
    lot: lotReducer,
    dealer: dealerReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

loadUser(store, userManager);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
