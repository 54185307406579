/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { showNotification } from '../../utils/notify';
import api from '../../core/api';
import { axiosInstance } from '../../core/axios';

interface Notify {
  creationTime: string;
  isAlreadyRead: boolean;
  message: string;
}

export interface InitState {
  notifyList: Notify[];
  notifyTotalCount: number;
  openNotify: boolean;
  notifyLoading: boolean;
  notifyError: string;
}

export const initialState: InitState = {
  notifyList: [],
  notifyTotalCount: 0,
  openNotify: false,
  notifyLoading: false,
  notifyError: '',
};

export const notifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    notifyRequest: (state) => {
      state.notifyLoading = true;
    },
    notifySuccess: (state, action) => {
      state.notifyList = action.payload.data;
      state.notifyTotalCount = action.payload.totalCount;
      state.notifyLoading = false;
    },
    notifyFailure: (state, action) => {
      state.notifyError = action.payload;
      state.notifyLoading = false;
    },
    changeOpenNotify: (state, action) => {
      state.openNotify = action.payload;
    },
  },
});

export const { notifyRequest, notifySuccess, notifyFailure, changeOpenNotify } = notifySlice.actions;

export const getNotifications = (Page: number, PageItemsCount: number): AppThunk => async (dispatch) => {
  try {
    dispatch(notifyRequest());
    const res = await axiosInstance.get(api.GetUserNotifications, {
      params: {
        Page,
        PageItemsCount,
      },
    });
    dispatch(notifySuccess(res.data));
  } catch (error: any) {
    dispatch(notifyFailure(error.response.data.errorMessage));
    showNotification('error', error.response.data.errorMessage);
  }
};

// SELECTORS
export const notifyLoadingSelector = (state: RootState) => state.notify.notifyLoading;

export const notifyTotalCountSelector = (state: RootState) => state.notify.notifyTotalCount;

export const notifyListSelector = (state: RootState) => state.notify.notifyList;

export const openNotifySelector = (state: RootState) => state.notify.openNotify;

export default notifySlice.reducer;
