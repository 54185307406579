import styled from "styled-components";
import { ReviewWrapper } from "../../shared/components/ReviewSection/style";

export const Title = styled.h1`
    color: #1B153D;
    font-size: 1.75rem;
    font-weight: 800;
    line-height: 1.5rem; 
    margin-bottom: 2rem;
    margin-top: 4rem;
    text-align: center;
    span{
        color: #CE2424;
    }
`

export const Wrapper = styled.div`
    border-radius: 0.5rem;
    border: 1px solid #F5F5F5;
    background: #FFF;
    box-shadow: 0px 0px 38px 0px #E8E8E8;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2.5rem 1.5rem;
    padding: 3.25rem;
    max-width: 85rem;
    width: 100%;
    margin: 0 auto 2.5rem;
`

export const ImageWrapper = styled.div`
    border-radius: 0.5rem;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%; 
    }
`

export const InfoWrapper = styled.div`
    border-radius: 0.5rem;
    border: 1px solid #EAEAEA;
    background: #FFF;
    box-shadow: 0px 0px 40px 0px #FBFBFB;
    padding: 1.75rem 1.25rem;
    h3{
        color: #CE2424;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.375rem;
    }
    ul{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 0;
        li{
            color: #9095A6;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 0.875rem; 
            display: flex;
            justify-content: space-between;
            gap: 0.5rem;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid #EAEAEA;
            span{
                color: #1B153D;
            }
        }
    }
`

export const ContentWrapper = styled.div`
    h4{
        color: #1B153D;
        font-size: 1.75rem;
        font-weight: 800;
        line-height: 1.5rem;
        margin-bottom: 1.75rem;
        span{
            color: #CE2424;
        }
    }
    p{
        color: #1B153D;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5rem; 
        margin-bottom: 1.5rem;
    }
`

export const WriteMessageWrapper = styled.div`
    ${ReviewWrapper}{
        form ,h2{
            display: none;
        }
        margin: 0;
    }
`