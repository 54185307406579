import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { AutenticationWrapper, SearchIcon, SearchInput, SearchWrapper } from './style';
import { SearchOutlined } from '@ant-design/icons';
import { Modal, Input, Button } from 'antd';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { CreateSearchQueryByVinCode, ExecuteSearchQueryByVinCode } from '../../../../store/slices/findVehiclesSlice';
import { useCookies } from 'react-cookie';
import { changeIsVincodeSearchAutorized, isVincodeSearchAutorizedSelector } from '../../../../store/slices/initSlice';
import { isAuthenticatedSelector, openModalAuthSelector, toggleModalAuth } from '../../../../store/slices/authSlice';
import { showNotification } from '../../../../utils/notify';
import i18n from '../../../../i18n';
import { langPrefix } from '../../../../utils/functions';
import AuthModal from '../../Modals/AuthModal';

const initialAutenticationState = {
  phone: "",
  firstName: "",
  lastName: "",
}

const TrackingSearch = () => {
    const {t } = useTranslation()
    const history = useHistory();
    const location = useLocation();
    const distpach = useDispatch();
    // const isVincodeSearchAutorized = useSelector(isVincodeSearchAutorizedSelector)
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const [isAutenticationOpen, setIsAutenticationOpen] = useState(false)
    const [isSmsSented, setisSmsSented] = useState(false)
    const openModalAuth = useSelector(openModalAuthSelector);

    const [autenticationValues, setAutenticationValues] = useState(initialAutenticationState)
    const [searchValue, setSearchValue] = useState("")
    const [otpValue, setOtpValue] = useState("")

    const [queryId, setQueryId] = useState(0)

    const onSearchSubmit = (e: any) => {
        e.preventDefault()
        if(searchValue.length < 6){
          showNotification('error', i18n.t('noti_symbol_req_6'));
        }else if(isAuthenticated){
          history.push({
              pathname: langPrefix(`/tracking/${searchValue}`),
          });
        }else{
          distpach(toggleModalAuth(true))
        }
    }

    const onSmsSendSubmit = async (e: any) => {
      e.preventDefault()
      if(autenticationValues.phone.length < 9){
        showNotification('error', i18n.t('dealer_number_validation'));
      }else{
        const res: any = await distpach(CreateSearchQueryByVinCode(searchValue, autenticationValues.phone, autenticationValues.firstName, autenticationValues.lastName))
        if(res.resultData.mustValidate){
          setisSmsSented(true)
          setQueryId(res.resultData.queryId)
        }else{
          history.push(langPrefix(`/tracking/${searchValue}?queryId=${res.resultData.queryId}`))
          onClose()
        }
      }
    }

    const onClose = () => {
      setAutenticationValues(initialAutenticationState)
      distpach(toggleModalAuth(false))
      setOtpValue("")
      setisSmsSented(false)
      setQueryId(0)
    }

    const onAutenticationSubmit = async () => {
      const res: any = await distpach(ExecuteSearchQueryByVinCode(queryId, otpValue))
      if(res.resultData.queryValidationResult == "ok"){
        // localStorage.setItem('isVincodeSearchAutorized', 'true')
        history.push(langPrefix(`/tracking/${searchValue}?otpCode=${otpValue}&queryId=${queryId}`))
        onClose()
      }else{
        showNotification('error', i18n.t('otp_error_notification'));
        setOtpValue("")
      }
    }

    const params = useMemo(() => new URLSearchParams(location.search), [location]);
    const getParamValue = useCallback((field: string) => params.get(field)?.replace(/%2C/g, ',') || undefined, [params]);

    useEffect(() => {
      if(!isAuthenticated && location.pathname.startsWith("/tracking/") && !getParamValue("queryId")){
        setSearchValue(location.pathname.split("/")[2])
        distpach(toggleModalAuth(true))
      }else{
        distpach(toggleModalAuth(false))
      }
    }, [isAuthenticated, location])

  return (
    <SearchWrapper onSubmit={(e) => onSearchSubmit(e)}>
      <SearchInput type="text" placeholder={t("subheader_search_placeholder")} value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
      <SearchIcon type='submit'>
        <SearchOutlined />
      </SearchIcon>
      {openModalAuth && <AuthModal visible={!!openModalAuth} />}
      </SearchWrapper>
  )
}

export default TrackingSearch