import styled from "styled-components";

export const RegisterDealerWrapper = styled.div`
    position: relative;
    min-height: 100dvh;
    width: 100%;
    padding: 3rem 2.5rem 1rem;
    @media(max-width: 991px){
        padding: 1.5rem 1rem;
    }
`

export const RegisterDealerBanner = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    object-fit: cover;
`

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    label{
        color: #000;
        font-size: 1rem;
        font-family: main;
        font-weight: 400;
        span{
            color: rgba(255, 67, 42, 1);
        }
    }
    @media(max-width: 991px){
        gap: 0.5rem;
        label{
            font-size: 0.75rem; 
        }
    }
`

export const FormWrapper = styled.div`
    border-radius: 1.5rem;
    background: #FFF;
    padding: 3rem 2.5rem;
    position: relative;
    max-width: 32rem;
    width: 100%;
    margin-left: auto;
    .ant-form-item{
        margin-bottom: 1.125rem;
    }
    h2{
        color: #000;
        font-size: 2rem;
        font-family: main;
        font-weight: 600;
        margin-bottom: 2rem;
    }
    @media(max-width: 991px){
          margin-right: auto;
  margin-left: auto;
        padding: 1.5rem 1rem;
        h2{
            font-size: 1.125rem;
            margin-bottom: 1rem;
        }
    }
`

export const SubmitButton = styled.button`
    width: 100%;
    border-radius: 0.5rem;
    background: #E42346;
    padding: 1.125rem 1.5rem;
    color: #FFF;
    font-size: 0.875rem;
    font-family: main;
    font-weight: 600;
    margin-top: 1.5rem;
    @media(max-width: 991px){
        margin-top: 0.5rem; 
        padding: 0.75rem 1rem;
    }
`