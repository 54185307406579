import React from 'react'
import { AutoAuctionSectionWrapper, SectionContainer, SectionTitle, ServiceCardList } from './style'
import ServiceCard, { ServiceModal } from './components/ServiceCard'
import { useTranslation } from 'react-i18next'
const services = require("./AuctionServicesData.json")

const AutoAuctionSection = () => {
  const { t } = useTranslation()

  return (
    <AutoAuctionSectionWrapper>
        <SectionContainer>
            <SectionTitle>{t('services_lionauction_title')}</SectionTitle>
            <ServiceCardList>
                {services.map( (service: ServiceModal, index: number) => (
                    <ServiceCard service={service} key={index}/>
                ))}
            </ServiceCardList>
        </SectionContainer>
    </AutoAuctionSectionWrapper>
  )
}

export default AutoAuctionSection