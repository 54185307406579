import React from 'react'
import { CellContentText, CellContentTextVariant, CellContentWrapper } from './style'
import { Vehicle } from '../../../interfaces/FindVehicles';
import BadgeWithTooltip from '../BadgeWithTooltip';
import { Link } from 'react-router-dom';
import { getLinkForLotPage } from '../../../utils/functions';

interface VehicleNameProps {
    vehicle: Vehicle    
}

const VehicleName = ({vehicle}: VehicleNameProps) => {
  return (
    <CellContentWrapper>
        <CellContentText>{vehicle.make} {vehicle.model} {vehicle.year}</CellContentText>
        <CellContentText>ID: <CellContentText variant={CellContentTextVariant.DANGER} as={Link} to={getLinkForLotPage(vehicle.lotNumber, vehicle.make, vehicle.model, vehicle.year)}>{vehicle.lotNumber}</CellContentText></CellContentText>
        {vehicle.badges.map((badge) => (
            <BadgeWithTooltip badge={badge} key={`${badge.name}_${vehicle.lotId}`} />
        ))}
    </CellContentWrapper>
  )
}

export default VehicleName