import styled from 'styled-components';
import { AlertWrapper } from '../../shared/components/Alert/style';

export const TrackingWrapper = styled.div`
  max-width: 90rem;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: 1fr 27.875rem;
  padding: 3.5rem 2.5rem;
  gap: 2.5rem;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 25rem;
    gap: 1.5rem;
    padding: 2.5rem 1rem;
  }
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    padding: 1rem;
    gap: 0;
  }
`;

export const GalleryWrapper = styled.div`
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    border-radius: 0.25rem 0.25rem 0 0;
    border: 1px solid #eaeaea;
    border-bottom: none;
    background: #fff;
    box-shadow: 0px 0px 40px 0px #fbfbfb;
    padding: 0.75rem;
  }
`;

export const GalleryList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.875rem;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const GalleryItem = styled.div`
  width: 100%;
  aspect-ratio: 1.33;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;

export const GalleryAlertList = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  ${AlertWrapper} {
    padding: 2rem 1rem;
  }
  @media (max-width: 767px) {
    ${AlertWrapper} {
      padding: 1rem;
    }
  }
`;

export const TrackingAside = styled.aside`
  .iframe {
    margin-top: 1rem;
    height: 350px;
  }
`;

export const TrackingContent = styled.div`
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 0px 40px #fbfbfb;
  border-radius: 8px;
  h2 {
    font-family: 'main';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 1.75rem 1.5rem;
    color: #1b153d;
    background: #f6f6f6;
    border: 1px solid #eaeaea;
    border-radius: 8px 8px 0px 0px;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding-top: 1.5rem;
    padding: 1.5rem 1.5rem 0;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 1.25rem;
    &:not(:last-child) {
      border-bottom: 1px solid #eaeaea;
    }
    span {
      font-family: 'main';
      font-size: 0.75rem;
      line-height: 0.875rem;
      font-weight: 400;
      color: #9095a6;
    }
    strong {
      font-weight: 500;
      color: #1b153d;
      a {
        color: #0a7cff;
        text-decoration: underline;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0;
    border: none;
    grid-template-columns: 1fr;
    border-radius: 0 0 0.25rem 0.25rem;
    border: 1px solid #eaeaea;
    border-top: none;
    background: #fff;
    box-shadow: 0px 0px 40px 0px #fbfbfb;
    padding: 0.75rem;
    h2 {
      display: none;
    }
    ul {
      padding: 0;
    }
  }
`;

export const SubscribeWrapper = styled.div`
  margin-top: 2rem;
  h3 {
    font-family: 'main';
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #292d32;
    margin-bottom: 1rem;
  }
  div {
    display: flex;
  }
  input {
    width: 100%;
    font-family: 'main';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #000000;
    background: #ffffff;
    border: 1px solid #c2c2c2;
    border-right: none;
    backdrop-filter: blur(2px);
    border-radius: 8px 0 0 8px;
    padding-left: 1.5rem;
  }
  button {
    font-family: 'main';
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding: 1rem 2.5rem;
    background: #ce2424;
    border-radius: 0px 8px 8px 0px;
  }
  @media (max-width: 767px) {
    input {
      padding-left: 1rem;
    }
    button {
      padding: 1rem 1.5rem;
    }
  }
`;

export const TrackButtonWrapper = styled.div`
  margin-top: 2rem;
  h2 {
    font-family: 'main';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #292d32;
    margin-bottom: 1rem;
  }
  div {
    display: flex;
  }
  svg {
    margin-bottom: -6px;
    margin-right: 7px;
  }
  a {
    font-family: 'main';
    width: 100%;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #ffffff;
    padding: 1.5rem 2.5rem;
    background: #ce2424;
    border-radius: 8px;
  }

  .imgwrap {
    display: grid;
    position: relative;
    width: 100%;
    margin-top: 1rem;
    justify-content: center;
    img {
        width: 100%;
        border-radius: 1rem;
        filter: grayscale(10%) brightness(60%);
    }
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
    }
    video {
        width: 100%;
        border-radius: 16px;
    }
  }

  @media (max-width: 767px) {
    button {
      padding: 1rem 1.5rem;
    }
  }
`;

export const ApiForSiteWrapper = styled.div`
  margin-top: 1.25rem;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 0px 40px #fbfbfb;
  border-radius: 8px;
  padding-bottom: 2.5rem;
  h3 {
    font-family: 'main';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 1.5rem 1.25rem;
    color: #1b153d;
    background: #f6f6f6;
    border: 1px solid #eaeaea;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 1rem;
  }
  code {
    margin: 1.25rem 0 0;
  }
  pre {
    font-family: 'main';
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #1b153d;
    margin: 0;
    padding-left: 1.25rem;
  }
  @media (max-width: 1200px) {
    pre {
      font-size: 0.675rem;
    }
  }
  @media (max-width: 767px) {
    h3 {
      font-size: 0.875rem;
      line-height: 1.375rem;
      padding: 1rem;
    }
    code {
      margin: 1rem 0 0;
    }
    pre {
      padding-left: 1rem;
      white-space: normal;
    }
  }
`;

export const AlertList = styled.div`
  margin-top: 1.5rem;
`;

export const ModalImage = styled.img``;

export const ModalGalleryWrapper = styled.div`
  width: 100%;
  margin-top: 1.25rem;
  position: relative;
  img {
    object-fit: contain;
    width: 100%;
    max-height: 60vh;
  }
`;

export const ModalHeader = styled.div`
  margin-top: -1rem;
`;

export const InfoWrapper = styled.div`

`;

export const ContactWrapper = styled.div`

`;

export const ContactListGroup = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem 1.5rem;
  @media(max-width: 991px){
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  @media(max-width: 767px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width: 480px){
    padding: 0 1rem;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem;
  }
`;

export const ContactList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactItem = styled.span`
  font-family: 'main';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #1b153d;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:not(:last-child){
    margin-bottom: 1.125rem;
  }
  .svg-wrapper {
    margin-right: 0.75rem;
    &,
    & > div {
      width: 1.125rem;
      height: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media(max-width: 991px){
    font-size: 0.875rem;
    line-height: 1.25rem;    
    .svg-wrapper {
      margin-right: 0.5rem;
    }
  }
`;