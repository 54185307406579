import { Button, Form, Input, Modal, Space, Table } from 'antd';
import React, { FC, memo, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useDispatch } from 'react-redux';
import { Note } from '../../../interfaces/Product';
import { createNewLotNote } from '../../../store/slices/productSlice';
import TableFooter from '../../../shared/components/TableFooter';
import { SearchContainer, SearchOutlinedIcon } from '../style';

const { TextArea } = Input;

interface Props {
  notes: Note[];
  lotId: number;
}

const Notes: FC<Props> = ({ notes, lotId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isOpenNewNoteModal, setIsOpenNewNoteModal] = useState<boolean>();
  const handleSearch = (confirm: () => void) => () => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => () => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <SearchContainer>
        <Input
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={handleSearch(confirm)}
        />
        <Space>
          <Button type="primary" onClick={handleSearch(confirm)} icon={<SearchOutlined />}>
            Search
          </Button>
          {clearFilters && <Button onClick={handleReset(clearFilters)}>Reset</Button>}
        </Space>
      </SearchContainer>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlinedIcon color={filtered ? 'red' : undefined} />,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFilter: (value: any, record: any) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
    render: (text: string) => (dataIndex === 'createdDate' ? (text && dayjs(text).format('DD/MM/YYYY HH:mm A')) || '' : text),
  });

  const handleToggleIsOpenNewNote = useCallback(() => {
    setIsOpenNewNoteModal(!isOpenNewNoteModal);
  }, [isOpenNewNoteModal]);

  const handleAddNote = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        dispatch(createNewLotNote(lotId, values.noteText));
        handleToggleIsOpenNewNote();
      })
      .catch((info) => {});
  }, [dispatch, form, handleToggleIsOpenNewNote, lotId]);

  return (
    <>
      <Button onClick={() => setIsOpenNewNoteModal(true)} type="primary">
        Add New Note
      </Button>
      <Table
        dataSource={notes.map((item) => ({ ...item, key: item.id }))}
        footer={() => <TableFooter total={notes.length} />}
        columns={[
          {
            title: 'Created Date',
            dataIndex: 'createdDate',
            ...getColumnSearchProps('createdDate'),
          },
          {
            title: 'Note Text',
            dataIndex: 'noteText',
            ...getColumnSearchProps('noteText'),
          },
          {
            title: '',
            dataIndex: 'isAuto',
            filters: [
              {
                text: 'Auto',
                value: 'auto',
              },
              {
                text: 'User',
                value: 'user',
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => (value === 'auto' ? record.isAuto : !record.isAuto),
          },
        ]}
      />
      {!!isOpenNewNoteModal && (
        <Modal
          title="Add New Note"
          visible={!!isOpenNewNoteModal}
          centered
          onCancel={handleToggleIsOpenNewNote}
          onOk={handleAddNote}
          okText="Add"
          cancelText="Close"
        >
          <Form form={form}>
            <Form.Item name="noteText" rules={[{ required: true, message: 'Required' }]}>
              <TextArea rows={8} />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default memo(Notes);
