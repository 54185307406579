import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import DealerCard from '../DealerCard';
import { DealerList, DealerSearchWrapper, SearchWrapper, SearchIcon, SearchInput, AlertInfo } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { dealerListSelector, getDealerDetailsById, getDealers } from '../../../../store/slices/dealerSlice';
import { useTranslation } from 'react-i18next';
import Alert, { AlertTypes, AlertVariants } from '../../../../shared/components/Alert';

export const hideDealers = [
  9288,
  9310,
  9612,
  9342
]

const DealerSearch = () => {
  const { t } = useTranslation()
  const dealerList = useSelector(dealerListSelector)
  const [filteredDealerList, setfilteredDealerList] = useState<any[]>([])
  const dispatch = useDispatch()

  const [dealerSearchValue, setDealerSearchValue] = useState("")

  const onDealerSearh = (val: string) => {
    setDealerSearchValue(val)
    const arr: any[] = []

    function checkInputIsDealer(str: string, value: string){
      return str.split(/\s+/).filter((word: string) => word.length > 0).every((str:string) => value.split(/\s+/).filter((word: string) => word.length > 0).some(s => s == str ))
    }

    dealerList.forEach(dealer => {
      if(hideDealers.some(id => dealer.dealerId == id )) return

      const value = val.trim().toLocaleLowerCase()
      const dealerName = dealer?.dealerName?.toLowerCase() || ""
      const dealerNameGeo = dealer?.dealerNameGeo?.toLocaleLowerCase() || "" 
      
      if( dealerName == value || dealerName == dealerNameGeo ){
        arr.push(dealer)
      }else if(dealerName.length > 0 && checkInputIsDealer(dealerName, value) ){
        arr.push(dealer)
      }else if(dealerNameGeo.length > 0 && checkInputIsDealer(dealerNameGeo, value)){
        arr.push(dealer)
      }else if(dealer.dealerId == val){
        arr.push(dealer)
      }
    })

    setfilteredDealerList(arr)
  }

  useEffect(() => {
    dispatch(getDealers())
  }, [dispatch])


  return (
    <DealerSearchWrapper>
      <SearchWrapper>
        <SearchInput type={'text'} placeholder={t("dealer_search_input_placeholder")} value={dealerSearchValue} onChange={(e) => onDealerSearh(e.target.value)} />
        <SearchIcon>
          <ReactSVG src="/icons/search-normal.svg" className="svg-wrapper" />
        </SearchIcon>
      </SearchWrapper>
{dealerSearchValue && filteredDealerList.length === 0 ? (
  <AlertInfo>
    <Alert type={AlertTypes.INFO} variant={AlertVariants.GRAY} text={t("dealer_search_not_found")} />
  </AlertInfo>
) : (
  <DealerList>
    {filteredDealerList.map((dealer) => (
      <DealerCard key={dealer.dealerName} dealer={dealer} />
    ))}
  </DealerList>
)}

    </DealerSearchWrapper>
  );
};

export default DealerSearch;